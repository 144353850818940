import { Stack, TextField } from '@mui/material';
import { ViewIcon } from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import { apiSlice } from 'app/store/api/apiSlice';
import { setBaseUrl } from 'app/store/configuration/configurationSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import { useState } from 'react';

export const BaseUrlInput: React.FC = () => {
	const stateBaseUrl = useAppSelector((state) => state.configuration.baseUrl);
	const [inputBaseUrl, setInputBaseUrl] = useState(stateBaseUrl);
	const dispatch = useAppDispatch();

	const handleBaseUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputBaseUrl(event.target.value);
	};

	const handleApply = () => {
		dispatch(setBaseUrl(inputBaseUrl));
		dispatch(apiSlice.util.resetApiState());
	};

	return (
		<Stack direction="row" gap={2} alignItems="center" width="100%">
			<TextField fullWidth label="Base URL" size="small" value={inputBaseUrl} onChange={handleBaseUrlChange} />
			<ViewIcon button icon="dns" onClick={handleApply} />
		</Stack>
	);
};
