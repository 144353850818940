import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import i18next from 'i18next';
import { lazyRetry } from 'src/utils/lazyRetry';

const ChangelogDoc = lazyRetry(() => import('./ChangelogDoc'));

const en = {
	CHANGELOG: 'Changelog',
	BREAKING_CHANGES: 'Breaking Changes',
	FIXED: 'Fixed',
	NEW: 'New',
	BREAKING: 'Breaking Changes',
	FIX: 'Fixed'
};
const pt = {
	CHANGELOG: 'Notas de versão',
	BREAKING_CHANGES: 'Alterações que quebram',
	FIXED: 'Corrigido',
	NEW: 'Novo',
	BREAKING: 'Alterações que quebram',
	FIX: 'Corrigido'
};
const sp = {
	CHANGELOG: 'Notas de versión',
	BREAKING_CHANGES: 'Breaking Changes',
	FIXED: 'Fixed',
	NEW: 'New',
	BREAKING: 'Breaking Changes',
	FIX: 'Fixed'
};

i18next.addResourceBundle('en', 'ChangelogCard', en);
i18next.addResourceBundle('sp', 'ChangelogCard', sp);
i18next.addResourceBundle('pt', 'ChangelogCard', pt);

const DocumentationConfig = {
	routes: [
		{
			path: 'changelog',
			element: (
				<LazyLoaderSuspense>
					<ChangelogDoc />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default DocumentationConfig;
