const locale = {
	VIEW_ALL: 'Ver todos',
	TITLE: 'Flotas',
	VEHICLE: 'Vehículo',
	VEHICLES: 'Vehículos',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	DATE: 'Fecha',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún elemento seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	ROWS_PER_PAGE: 'Elementos por página',
	COMPANY_REQUIRED: 'El campo empresa es obligatorio',
	NAME_REQUIRED: 'El campo nombre es obligatorio',
	NAME: 'Nombre',
	DESCRIPTION: 'Descripción',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar flota',
	DELETE_CONFIRMATION: '¿Estás seguro de que deseas eliminar',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Editando flota',
	EDIT_CONFIRMATION: '¿Estás seguro de que deseas editar esta flota?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Estás seguro de que deseas editar las flotas seleccionadas?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	ERROR_PERMISSION: 'No tienes los permisos necesarios. Por favor, contacta al administrador del sistema.',
	ERROR_GETTING_USERS: 'Ocurrió un error al cargar las flotas. Por favor, inténtalo nuevamente más tarde.',
	ERROR_RESPONSE_404: 'Error: Datos no encontrados. Por favor, contacta al administrador del sistema.',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor. Por favor, inténtalo nuevamente más tarde.',
	ERROR_NETWORK: 'Error de red. Por favor, verifica tu conexión e inténtalo nuevamente.',
	ADD_FLEET: 'Agregar flotas',
	CREATE_SUCCESS: 'Flota agregada exitosamente',
	CREATE_ERROR: 'Error al agregar flota',
	EDIT_SUCCESS: 'Flota actualizada exitosamente',
	EDIT_ERROR: 'Error al actualizar flota',
	ERROR_ADD_EMPTY: 'Completa todos los campos',
	DELETE_SUCCESS: 'Flota eliminada exitosamente',
	DELETE_ERROR: 'Error al eliminar flota',
	NO_DATA: 'No se encontraron elementos coincidentes',
	NO_RESULTS: 'No se encontraron resultados',
	NO_PERMISSION_list: 'No tienes permiso para ver la lista de flotas',
	NO_PERMISSION_new: 'No tienes permiso para agregar flotas',
	NO_PERMISSION_edit: 'No tienes permiso para editar flotas',
	NO_PERMISSION_view: 'No tienes permiso para ver flotas',
	NO_PERMISSION_delete: 'No tienes permiso para eliminar flotas',
	LOADING: 'Cargando',
	MORE_THAN: 'más que',
	NO_FLEET_FOUND: 'No se encontró ninguna flota',
	DELETE_MODAL_TITLE: 'Eliminar flota',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas eliminar esta flota?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro de que deseas eliminar {{n}} flotas?',
	EDIT_INFO: 'Editar flota',
	DELETE_INFO: 'Eliminar flota',
	DOWNLOAD_INFO: 'Descargar flotas seleccionadas',
	EDIT_MULTIPLE_INFO: 'Editar flotas seleccionadas',
	DELETE_MULTIPLE_INFO: 'Eliminar flotas seleccionadas',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar flotas',
	EDIT_MULTIPLE_SUCCESS: 'Flotas editadas exitosamente',
	EDIT_MULTIPLE_ERROR: 'Error al editar flotas',

	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',

	// Header

	DOWNLOAD_ALL: 'Descargar todo',

	VEHICLES_SELECTED: '{{n}} vehículos seleccionados'
};

export default locale;
