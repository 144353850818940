const locale = {
	TITLE: 'Sesiones',

	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',

	ON_DISCONNECT_ERROR: 'Error al desconectar sesión de WhatsApp',
	DISCONNECT: 'Desconectar',
	QR_CODE_INFO: 'Para iniciar sesión, escanea el código QR de arriba',

	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	ALREADY_IN_SESSION: 'Ya existe una sesión activa para las empresas: {{companies}}',

	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',

	DATE: 'Fecha',
	OF: 'de',

	ROWS_PER_PAGE: 'Elementos por página',

	COMPANY_REQUIRED: 'El campo empresa es obligatorio',
	NAME_REQUIRED: 'El campo nombre es obligatorio',
	DESCRIPTION_REQUIRED: 'El campo descripción es obligatorio',

	NAME: 'Nombre',
	DESCRIPTION: 'Descripción',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',

	ADD_SESSION: 'Agregar sesiones',
	CREATE_SUCCESS: 'Sesión agregada exitosamente',
	CREATE_ERROR: 'Error al agregar sesión',
	EDIT_SUCCESS: 'Sesión actualizada exitosamente',
	EDIT_ERROR: 'Error al actualizar sesión',
	DELETE_SUCCESS: 'Sesión eliminada exitosamente',
	DELETE_ERROR: 'Error al eliminar sesión',
	NO_SESSION_FOUND: 'No se encontró ninguna sesión',
	LOADING: 'Cargando',
	DELETE_MODAL_TITLE: 'Eliminar sesión',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas eliminar esta sesión?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro de que deseas eliminar {{n}} sesiones?',
	EDIT_INFO: 'Editar sesión',
	DELETE_INFO: 'Eliminar sesión',
	DOWNLOAD_INFO: 'Descargar sesiones seleccionadas',
	EDIT_MULTIPLE_INFO: 'Editar sesiones seleccionadas',
	DELETE_MULTIPLE_INFO: 'Eliminar sesiones seleccionadas',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar sesiones',
	EDIT_MULTIPLE_SUCCESS: 'Sesiones editadas exitosamente',
	EDIT_MULTIPLE_ERROR: 'Error al editar sesiones',
	PAGE_NOT_ALLOWED: 'No tienes permiso para ver esta página.'
};

export default locale;
