import { AbilityBuilder } from '@casl/ability';
import { Subjects } from 'app/permissions/types';

export const defineAdminAbility = (subject: Subjects, can: AbilityBuilder<any>['can']) => {
	can('edit', subject);
	can('create', subject);
	can('delete', subject);
	can('view', subject);
	can('list', subject);
	can('execute', subject);
};
