import _ from 'lodash';

export function objectToStringParams(object: object) {
	const nonEmptyParams = _.pickBy(object, (value) => {
		return value !== undefined && value !== null && value !== '';
	});

	const stringParams = _.reduce(nonEmptyParams, (acc, value, key) => `${acc}&${key}=${value}`, '');

	return stringParams;
}
