import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { PriorityHigh } from '@mui/icons-material';
import { Collapse, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { INotification } from 'app/store/api/notificationsSlice';
import { selectCurrentLanguage } from 'app/store/i18nSlice';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { enUS, es, ptBR } from 'date-fns/locale';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const locale = {
	en: enUS,
	es: es,
	pt: ptBR
};

interface INotificationCardProps {
	notification: INotification;
	onDismiss: () => void;
	onView: () => void;
}

function NotificationCard({ notification, onDismiss, onView }: INotificationCardProps) {
	const [deleting, setDeleting] = useState(false);
	const [descriptionOpened, setDescriptionOpened] = useState(false);
	const currLanguage = useSelector(selectCurrentLanguage).id;

	const handleOpenDescription = () => {
		setDescriptionOpened((prev) => !prev);
	};

	const handleView = () => {
		setDeleting(true);
		onView();
	};

	const handleDismiss = () => {
		setDeleting(true);
		onDismiss();
	};

	const [headerMessage, ...contentMessage] = notification.description && notification.description.split('\n');
	const isRead = notification?.read;

	return (
		<motion.div layout animate={deleting ? { x: 500 } : { x: 0 }}>
			<Card
				className="flex mb-16 items-center relative rounded-16 p-20 min-h-64 shadow space-x-8 transition-all cursor-pointer"
				elevation={0}
				onClick={handleOpenDescription}
				component={notification?.userouter ? NavLinkAdapter : 'div'}
				to={notification.link || ''}
				role={notification.link && 'button'}
			>
				<Box
					sx={{ backgroundColor: 'background.default' }}
					className="flex shrink-0 relative items-center justify-center w-48 h-48 mr-12 rounded-full self-start"
				>
					{!isRead && <PriorityHigh className="absolute -top-4 -left-4 animate-bounce" color="secondary" />}
					<FuseSvgIcon className="opacity-75">{notification?.icon}</FuseSvgIcon>
				</Box>

				<div className="flex flex-col">
					<div className="flex items-center w-[210px]">
						<Typography
							sx={(theme) => ({
								[theme.breakpoints.down('sm')]: {
									maxWidth: 120,
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap'
								}
							})}
							className="font-semibold line-clamp-2"
						>
							{notification?.title}
						</Typography>
					</div>
					<div className="flex items-center w-[210px]">
						<Typography className="my-8 text-sm" color="text.secondary">
							{headerMessage}
						</Typography>
					</div>
					<Collapse in={descriptionOpened} timeout="auto" className="text-sm ">
						{contentMessage.map((message, index) => (
							<div key={`${message}-${index}`} className="flex items-center w-[210px]">
								<Typography
									className="my-8 text-sm leading-none"
									color="text.secondary"
									key={`${message}-${index}`}
								>
									{message}
								</Typography>
							</div>
						))}
					</Collapse>
					{notification.time && (
						<Typography className="mt-8 text-sm leading-none " color="secondary">
							{formatDistanceToNow(new Date(notification.time), {
								addSuffix: true,
								locale: locale[currLanguage]
							})}
						</Typography>
					)}
				</div>
				<div className="absolute top-[18px] right-[20px]">
					{!isRead && (
						<IconButton
							className="p-0"
							size="small"
							onClick={(ev) => {
								handleView();
								ev.stopPropagation();
							}}
						>
							<FuseSvgIcon size={16} className="opacity-75">
								heroicons-outline:eye
							</FuseSvgIcon>
						</IconButton>
					)}

					<IconButton
						className="p-0 ml-4"
						size="small"
						onClick={(ev) => {
							handleDismiss();
							ev.stopPropagation();
						}}
					>
						<FuseSvgIcon size={16} className="opacity-75">
							heroicons-outline:trash
						</FuseSvgIcon>
					</IconButton>
				</div>
			</Card>
		</motion.div>
	);
}

export default NotificationCard;
