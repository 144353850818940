import { TTracker, TrackerSearchParams, TrackerStatus } from 'app/main/trackers/manage/types';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';
import { apiSlice } from './apiSlice';
import getStringQuery from './getStringQuery';
import { IApiPagination, TPaginatedQuery } from './types';

function getTrackerStringQuery(paginatedData: TrackerSearchParams) {
	const queryArgs = {
		deleted: paginatedData.deleted || null,
		status: paginatedData.status || null,
		type: paginatedData.type || null,
		simcard: paginatedData.simcard || null,
		vehicle: paginatedData.vehicle || null,
		company: paginatedData.company || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `&${query.toString()}`;
}

export interface IEditMultipleTrackerPayload {
	ids: string;
}

export type ITrackerPayload = {
	id?: string;
	did: number;
	name: string;
	notes?: string;
	company?: string;
	trackerType: string;
	vehicle?: string;
	status: TrackerStatus;
	simcard: string;
	canBeBlocked?: boolean;
};

export const trackersSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllTrackers: builder.query<IApiPagination<TTracker>, TPaginatedQuery>({
			query: (paginatedData: TPaginatedQuery) => ({
				url: getStringQuery(paginatedData, '/tracker') + getTrackerStringQuery(paginatedData)
			}),
			providesTags: ['Trackers']
		}),
		getAllTrackersWithPacket: builder.query<IApiPagination<TTracker>, TPaginatedQuery>({
			query: (paginatedData: TPaginatedQuery) => ({
				url: `${getStringQuery(paginatedData, '/tracker')}&withPackets=true`
			}),
			providesTags: ['Trackers']
		}),
		getTracker: builder.query<TTracker, string>({
			query: (id) => `/tracker/${id}?withPackets=true`,
			providesTags: (_, __, id) => [{ type: 'Trackers', id }]
		}),
		getDeletedTracker: builder.query<TTracker, string>({
			query: (id) => `/tracker/${id}/deleted`
		}),
		updateTracker: builder.mutation({
			query: (trackerData) => ({
				url: `/tracker/${trackerData.id}`,
				method: 'PUT',
				body: trackerData
			}),
			invalidatesTags: (_, __, data) => [
				{ type: 'Trackers', id: data._id },
				{ type: 'Simcards' },
				{ type: 'Vehicles' }
			]
		}),
		updateMultipleTracker: builder.mutation<string, IEditMultipleTrackerPayload>({
			query: (trackerData) => ({
				url: `/tracker/list?ids=${trackerData.ids}`,
				method: 'PUT',
				body: trackerData
			}),
			invalidatesTags: ['Trackers']
		}),
		createTracker: builder.mutation<string, ITrackerPayload>({
			query: (trackerData) => ({
				url: '/tracker',
				method: 'POST',
				body: trackerData
			}),
			invalidatesTags: ['Trackers', 'Simcards', 'Vehicles']
		}),
		deleteTrackers: builder.mutation({
			query: (ids?: string) => ({
				url: `/tracker/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Trackers', 'Simcards', 'Vehicles']
		}),
		restoreTracker: builder.mutation({
			query: (id?: string) => ({
				url: `/tracker/${id}/restore`,
				method: 'PUT'
			}),
			invalidatesTags: ['Trackers']
		}),
		getAllTrackersByCompanies: builder.query<IApiPagination<TTracker>, string[]>({
			query: (company) => ({
				url: '/tracker',
				method: 'GET',
				params: {
					limit: 0,
					...(company?.length > 0 && { company })
				}
			}),
			providesTags: ['TrackersByCompany']
		})
	})
});

export const {
	useGetAllTrackersWithPacketQuery,
	useLazyGetAllTrackersWithPacketQuery,
	useGetAllTrackersQuery,
	useGetTrackerQuery,
	useUpdateTrackerMutation,
	useCreateTrackerMutation,
	useDeleteTrackersMutation,
	useUpdateMultipleTrackerMutation,
	useRestoreTrackerMutation,
	useGetDeletedTrackerQuery,
	useGetAllTrackersByCompaniesQuery
} = trackersSlice;
