import { useMemo } from 'react';
import { LineLayer, SymbolLayer } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';

import { selectShowPositions, selectShowRoute, selectShowRouteByPosition } from 'app/store/map/routeToolbarSlice';

const routeLineLayer: LineLayer = {
	id: 'route-line',
	type: 'line',
	source: 'route',
	layout: {
		visibility: 'none'
	},
	paint: {
		'line-color': '#25276B',
		'line-width': 6
	},
	filter: ['all', ['==', '$type', 'LineString'], ['!has', 'isFromPoints']]
};

export const routePositionLayer: SymbolLayer = {
	id: 'route-direction',
	type: 'symbol',
	source: 'route',
	layout: {
		'icon-image': 'arrow_up_black',
		'icon-size': 0.8,
		'icon-rotate': ['get', 'BEARING'],
		'icon-allow-overlap': true,
		visibility: 'none'
	},

	filter: ['all', ['!has', 'isLast'], ['!has', 'isFirst'], ['==', '$type', 'Point']]
};

const routeByPosition: LineLayer = {
	id: 'route-by-position',
	type: 'line',
	source: 'route',
	paint: {
		'line-color': '#AD2C33',
		'line-width': 4
	},
	filter: ['has', 'isFromPoints'],
	layout: {
		visibility: 'none',
		'line-join': 'round',
		'line-cap': 'round'
	}
};
const routeStartStopLayer: SymbolLayer = {
	id: 'route-start-stop',
	type: 'symbol',
	source: 'route',
	layout: {
		visibility: 'visible',
		'icon-allow-overlap': true,
		'icon-image': ['case', ['has', 'isFirst'], 'pin_green', ['has', 'isLast'], 'pin_red', 'pin']
	},
	filter: ['any', ['has', 'isLast'], ['has', 'isFirst']]
};
export const useRouteLayers = () => {
	const isPositionVisible = useSelector(selectShowPositions);
	const isRouteByPositionVisible = useSelector(selectShowRouteByPosition);
	const isRouteVisible = useSelector(selectShowRoute);

	const controlledRouteStartStopLayer = useMemo(() => {
		const layer: SymbolLayer = {
			...routeStartStopLayer,
			layout: {
				...routeStartStopLayer.layout,
				visibility: isRouteVisible || isPositionVisible || isRouteByPositionVisible ? 'visible' : 'none'
			}
		};
		return layer;
	}, [isRouteVisible, isPositionVisible, isRouteByPositionVisible]);

	const controlledRoutePositionLayer = useMemo(() => {
		const layer: SymbolLayer = {
			...routePositionLayer,
			layout: {
				...routePositionLayer.layout,
				visibility: isPositionVisible ? 'visible' : 'none'
			}
		};

		return layer;
	}, [isPositionVisible]);

	const controlledRouteByPosition = useMemo(() => {
		const layer: LineLayer = {
			...routeByPosition,
			layout: {
				visibility: isRouteByPositionVisible ? 'visible' : 'none'
			}
		};
		return layer;
	}, [isRouteByPositionVisible]);

	const controlledRouteLine = useMemo(() => {
		const layer: LineLayer = {
			...routeLineLayer,
			layout: {
				visibility: isRouteVisible ? 'visible' : 'none'
			}
		};
		return layer;
	}, [isRouteVisible]);

	return {
		controlledRouteByPosition,
		controlledRouteStartStopLayer,
		controlledRouteLine,
		controlledRoutePositionLayer
	};
};
