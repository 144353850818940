export default {
	PARTIAL: 'Parcial',
	FULL: 'Total',
	NO_ACCESS: 'Sin acceso',
	START: 'Inicio',
	END: 'Fin',
	TO: 'hasta',
	ACCESS_TYPE: 'Tipo de acceso',
	ACTIVE: 'Activo',
	NAME: 'Nombre',
	DESCRIPTION: 'Descripción',
	COMPANY: 'Empresa',

	NAME_REQUIRED: 'Nombre es obligatorio',
	DESCRIPTION_REQUIRED: 'Descripción es obligatoria',
	COMPANY_REQUIRED: 'Empresa es obligatoria',

	MONDAY: 'Lunes',
	TUESDAY: 'Martes',
	WEDNESDAY: 'Miércoles',
	THURSDAY: 'Jueves',
	FRIDAY: 'Viernes',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',

	REMOVE_PERIOD: 'Eliminar período',
	ADD_PERIOD: 'Agregar otro período',

	SAVE: 'Guardar',
	CLEAR: 'Limpiar',
	DELETE: 'Eliminar',
	VINCULATE: 'Vincular',

	CONFIGURE_PERIODS: 'Configurar períodos',

	ACCESS_PERIOD_UPDATED_SUCCESS: 'Período de acceso actualizado exitosamente',
	ACCESS_PERIOD_CREATED_SUCCESS: 'Período de acceso creado exitosamente',

	ACCESS_PERIOD_UPDATED_ERROR: 'Error al actualizar período de acceso',
	ACCESS_PERIOD_CREATED_ERROR: 'Error al crear período de acceso',

	ACCESS_PERIOD_DELETED_SUCCESS: 'Período de acceso eliminado exitosamente',
	ACCESS_PERIOD_DELETED_ERROR: 'Error al eliminar período de acceso',

	DELETE_ACCESS_PERIOD_PRIMARY: '¿Estás seguro de que deseas eliminar el período de acceso?',
	DELETE_ACCESS_PERIOD_SECONDARY: 'Esta acción no se puede deshacer',
	DELETE_ACCESS_PERIOD_TITLE: 'Eliminar período de acceso',
	PERIOD_NOT_OVERLAP: 'Los períodos no pueden superponerse'
};
