import { useGetAddressByPositionQuery } from 'app/store/api/nominatimSlice';
import { INominatimResponse } from 'app/store/api/types';
import _ from 'lodash';

const getAddress = (data: INominatimResponse) => {
	const address: string | null = _.get(data, 'features[0].properties.display_name', null);
	if (!address) return '';
	const formatted = address.split(',').filter((item) => !item.match(/Região|Geográfica/gi));
	return formatted.join();
};

export default function useTrackerGeoLocation(coords: [number, number]) {
	const hasCoords = coords.every((v) => typeof v === 'number' && !isNaN(v) && v !== 0);
	const [lng, lat] = coords;

	const { address, isLoadingAddress, isFetchingAddress } = useGetAddressByPositionQuery(
		{ lng, lat },
		{
			selectFromResult: (res) => ({
				address: getAddress(res.data),
				isLoadingAddress: res.isLoading,
				isFetchingAddress: res.isFetching,
				...res
			}),
			skip: !hasCoords
		}
	);

	return {
		address,
		isLoadingAddress,
		hasCoords,
		isFetchingAddress
	};
}
