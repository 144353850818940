import { commonSpTranslates } from 'src/constants/common-translates';

const locale = {
	TITLE: 'Mapa',
	FILTERS: 'Filtros',
	COMPANIES: 'Compañías',
	VEHICLES: 'Vehículos',
	FLEETS: 'Flotas',
	STATES: 'Estados',
	SEARCH: 'Buscar...',
	ADVANCED_FILTERS: 'Filtros Avanzados',
	VEHICLE_TYPE: 'Tipo de Vehículo',
	IGNITION_ON: 'Encendido',
	SPEED: 'Velocidad',
	GREEN: 'Verde',
	RED: 'Rojo',
	YELLOW: 'Amarillo',
	PURPLE: 'Morado',
	GRAY: 'Gris',
	BOAT: 'Barco',
	CAR: 'Carro',
	BIKE: 'Bicicleta',
	TRUCK: 'Camión',
	MOTORBIKE: 'Moto',
	OTHERS: 'Otros',
	APPLY: 'Aplicar',
	SAVE: 'Guardar',
	NAME: 'Nombre',
	EQUALS: 'Igual',
	GREATER_THAN: 'Mayor que',
	LESS_THAN: 'Menor que',
	MY_FILTERS: 'Mis Filtros',
	SUCCESS_SAVE_FILTER: 'Filtro exitoso',
	ERROR_SAVE_FILTER: 'Filtro de ahorro de errores',
	SUCCESS_DELETE_FILTER: 'Filtro exitoso',
	ERROR_DELETE_FILTER: 'Error al eliminar el filtro',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	SELECTED_FLEETS: '{{n}} Flotas seleccionadas',
	SELECTED_STATES: '{{n}} Estados seleccionadas',
	SELECTED_VEHICLE_TYPES: '{{n}} Tipo de vehículo seleccionadas',
	SELECTED_VEHICLES: '{{n}} Vehículo seleccionadas',
	MAKE_DEFAULT: 'Establecer como filtro predeterminado',
	ON: 'En',
	OFF: 'Apagado',
	ON_OFF: 'En y apagado',
	SUCCESS_DEFAULT_FILTER: 'Filtro predeterminado establecido con éxito',
	SUCCESS_DEFAULT_FILTER_UNSET: 'Filtro predeterminado elimanado',
	ERROR_DEFAULT_FILTER: 'Error al establecer el filtro predeterminado',
	SUCCESS_APPLY_FILTER: 'Filtro establecido con éxito',
	HISTORY: 'Histórico',
	REAL_TIME: 'Tiempo Real',
	FROM: 'De:',
	TO: 'Hasta:',
	SELECT_RANGE: 'Seleccione un rango',
	NO_HISTORY: 'No Histórico',
	HISTORY_ERROR: 'Histórico Error',
	CANCEL: 'Cancel',
	DISTANCE: 'Distancia',
	DURATION: 'Duración',
	SHOW_POSITIONS: 'Mostrar posiciones:',
	SHOW_TRACKERS: 'Mostrar rastreadores:',
	IGNITION: 'Encendido',
	SATELLITE: 'Satélite',
	OPERATOR: 'Operatora',
	BATTERY: 'Voltaje',
	IDLE: 'Inactivo',
	MOVING: 'Moviente',
	NO_COMM: 'Sin comunicacion',
	WRONG_POSITION: 'Posicionamiento incorrecto',
	TOWED: 'Chiló',
	CONNECTED: 'Conectado',
	TRACKER: 'Rastreador:',
	DEVICE: 'Dispositivo:',
	DATE: 'Fecha:',
	TIME: 'Hora:',
	SIGNAL: 'Señal',
	IDLE_N: 'Inactivo <i>({{n}})</i>',
	MOVING_N: 'Moviente <i>({{n}})</i>',
	NO_COMM_N: 'Sin comunicacion <i>({{n}})</i>',
	WRONG_POSITION_N: 'Posicionamiento incorrecto <i>({{n}})</i>',
	TOWED_N: 'Chiló <i>({{n}})</i>',
	BLOCKED_QUANTITY: 'Bloqueado <i>({{n}})</i>',
	CLOSE: 'Cerrar',
	GEOFENCE_LOAD_ERROR: 'Error al obtener cercas virtuales',
	FAST_HISTORY: 'Historia rápida',
	ADVANCED_HISTORY: 'Historia avanzada',
	START_DATE_MUST_BE_BEFORE_END_DATE: 'La fecha de inicio debe ser anterior a la fecha de finalización',
	FOLLOW_VEHICLE: 'Seguir el vehículo',
	CANNOT_GET_TRIP: 'No se puede obtener datos de viaje',
	ROUTE_POINT_BY_POINT: 'Ruta punto a punto',
	ROUTERIZED_ROUTE: 'Ruta guionizada',
	EVENT_TYPE: 'Evento',
	GPS_TIME: 'Hora',
	ALTITUDE: 'Altitud',
	BEARING: 'Rodamiento',
	SATELLITES: 'Satélites',
	LATITUDE: 'Latitud',
	LONGITUDE: 'Longitud',
	ADDRESS_CITY: 'Ciudad',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_POSTCODE: 'Código postal',
	ADDRESS_ROAD: 'Carretera',
	ADDRESS_STATE: 'Estado',
	ADDRESS_SUBURB: 'Suburbio',
	HOURMETER: 'Contador de horas',
	MILEAGE: 'Kilometraje',
	YES: 'Si',
	NO: 'No',
	CLEAR: 'Limpiar',
	VIEW_TRACKER: 'Ver rastreador',
	VIEW_VEHICLE: 'Ver vehículo',
	ADDRESS: 'Dirección',
	STATUS: 'Status',
	UNAVAILABLE: 'Indisponible',
	LOCATION: 'Ubicación',
	VOLTAGE: 'Voltaje',
	LAST_POSITION: 'Ultima posicion',
	TRACKER_PROBLEM: 'Haga clic aquí para mostrar sus rastreadores defectuosos',
	CHANGE_MAP: 'Cambiar Mapa',
	WITHOUT_VEHICLE: 'Ningún vehículo',
	WITHOUT_VEHICLE_N: 'Ningún vehículo <i>({{n}})</i>',
	NO_FILTERS: 'No tienes filtros',
	BLOCK: 'Bloquear',
	UNBLOCK: 'Desbloquear',
	BLOCK_TRACKER: 'Bloquear rastreador',
	BLOCKED: 'Bloqueado',
	BLOCK_TRACKER_SEND_SUCCESS: 'Comando de bloqueo enviado con éxito, espere la confirmación.',
	BLOCK_TRACKER_SEND_ERROR: 'Error al enviar el comando de bloqueo.',
	UNBLOCK_TRACKER_SEND_ERROR: 'Error al enviar el comando de desbloqueo.',
	UNBLOCK_TRACKER_SEND_SUCCESS: 'Comando de desbloqueo enviado con éxito, espere la confirmación.',
	BLOCK_TRACKER_CONFIRMATION: 'Confirmar bloqueo',
	BLOCK_TRACKER_CONFIRMATION_TEXT: 'Estás a punto de bloquear temporalmente este vehículo.',
	BLOCK_TRACKER_CONFIRMATION_TEXT_WARNING: 'Para continuar, haz clic en "Confirmar" en el botón de abajo.',
	UNBLOCK_TRACKER_CONFIRMATION: 'Confirmar desbloqueo',
	UNBLOCK_TRACKER_CONFIRMATION_TEXT: 'Estás a punto de desbloquear temporalmente este vehículo.',
	UNBLOCK_TRACKER_CONFIRMATION_TEXT_WARNING: 'Para continuar, haz clic en "Confirmar" en el botón de abajo.',
	CONFIRM: 'Confirmar',
	ACTIONS: 'Acciones',
	TO_GOOGLE_MAPS: 'Ver en Google Maps',
	COPY_INFO: 'Copiar información',
	BLOCK_STATUS: 'Status do bloqueio',

	PENDING: 'Pendente',
	QUEUED: 'Agendando',
	'RE-QUEUED': 'Re-enfileirado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Erro',
	EXPIRED: 'Expirado',
	SCHEDULING: 'Agendando',

	BLOCK_COMMAND_WARNING:
		'Este vehículo tiene un comando de {{commandName}} pendiente enviado en: {{blockTime}}, ¿estás seguro de que deseas realizar una nueva solicitud?',
	WRONG_POSITION_POPUP_TEXT:
		'El rastreador tiene una posición incorrecta y no estará disponible en el mapa. Verifique si el rastreador se instaló correctamente o comuníquese con el soporte.',

	DRIVER: 'Conductor',
	GROUP_TRACKERS: 'Grupo de rastreadores',
	SHOW_TRACKERS_NAME: 'Mostrar nombre de rastreadores',
	SHOW_TRACKERS_GROUP_COUNT: 'Mostrar cantidad de rastreadores en el grupo',
	FILTER: 'Filtrar',
	SHOW_GEO_FENCE: 'Mostrar cerca virtual',

	ROUTERIZED_DISTANCE: 'Distancia enrutada',

	START: 'Inicio',
	END: 'Fin',

	SENT_AT: 'Enviado en',
	RECEIVED_AT: 'Recibido en',

	LAST_GPS_TIME: 'Última hora GPS',
	LAST_SERVER_TIME: 'Última hora del servidor',
	VIEW_ANALYTIC_REPORT: 'Ver informe analítico',

	SHUTDOWN: 'Desligado',
	EXTREMELY_LOW_POWER: 'Baixíssima',
	VERY_LOW_POWER: 'Muito baixa',
	LOW_BATTERY: 'Baixa',
	MEDIUM: 'Média',
	HIGH: 'Alta',
	VERY_HIGH: 'Muito alta',
	UNKNOWN: 'Desconhecido',

	PUBLIC_LINK: 'Link público',
	CREATE_PUBLIC_LINK: 'Crear Enlace Público',
	'24_HOURS': '24 Horas',
	'48_HOURS': '48 Horas',
	'72_HOURS': '72 Horas',

	CREATE: 'Crear',
	PUBLIC_LINK_ERROR: 'Error al crear el enlace público',
	PUBLIC_LINK_INFO:
		'Este enlace público permitirá el acceso temporal a la información del vehículo. Elija la duración de la validez del enlace a continuación. Después de la creación, será redirigido a la página del enlace público.',

	...commonSpTranslates
};

export default locale;
