import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store/index';

import type { ICompanyLogo } from 'app/types/company.types';
import type { TTrackerLayout } from 'app/main/map/types';
import type { FuseSettingsProps } from '@fuse/core/FuseSettings';

const possibleTrackerLayouts = ['primary', 'secondary', 'tertiary'];

export type TCompanySettingsSlice = {
	companyLogo: ICompanyLogo;
	settings?: FuseSettingsProps;
};

export const initialCompanySettings: TCompanySettingsSlice = {
	companyLogo: {
		appName: '',
		home: '/realtime',
		trackerLayout: 'primary',
		logoUrl: '',
		logoDark40x40: '',
		logoDark200x40: '',
		logoLight40x40: '',
		logoLight200x40: ''
	},
	settings: null
};

const companySettingsSlice = createSlice({
	name: 'companySettings',
	initialState: initialCompanySettings,
	reducers: {
		setCompanyLogo: (state, action: PayloadAction<ICompanyLogo>) => {
			state.companyLogo = action.payload;
		},
		setCompanyFuseSettings: (state, action: PayloadAction<FuseSettingsProps>) => {
			state.settings = action.payload;
		}
	}
});

export const { setCompanyFuseSettings, setCompanyLogo } = companySettingsSlice.actions;

export const selectCompanyFuseSettings = (state: RootState) => state.companySettings.settings;
export const selectCompanyLogo = (state: RootState) => state.companySettings.companyLogo;
export const selectCompanySettings = (state: RootState) => state.companySettings;

const selectCompanyTrackerLayout = (state: RootState) => state.companySettings.companyLogo.trackerLayout;

export const selectTrackerCardLayout = createSelector(selectCompanyTrackerLayout, (layout: TTrackerLayout) =>
	possibleTrackerLayouts.includes(layout) ? layout : 'primary'
);

export default companySettingsSlice.reducer;
