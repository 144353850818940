import { AvTimerOutlined, DeviceHubOutlined, SatelliteAltOutlined } from '@mui/icons-material';
import { TFunction } from 'react-i18next';
import formatBattery from 'src/utils/formatBattery';
import { GPSIcon, IGNITIONIcon, RXLEVIcon, getOdometerValue } from '../../helper';
import InfoBox from './InfoBox';
import InfoItem from './InfoItem';

interface TrackerInfoProps {
	t: TFunction;
	rxLev: number;
	satellites: number;
	gpsFixed: boolean;
	ignitionOn: boolean;
	battery: number;
	mileage: number;
	hourmeter?: number;
}

export default function TrackerInfo({
	t,
	rxLev,
	satellites,
	gpsFixed,
	ignitionOn,
	battery,
	mileage,
	hourmeter
}: TrackerInfoProps) {
	return (
		<InfoBox label={t('INFO_TRACKER')} icon={<DeviceHubOutlined color="secondary" />}>
			<InfoItem label={t('SATELLITES')} value={satellites} icon={<SatelliteAltOutlined fontSize="small" />} />
			<InfoItem label={t('RXLEV')} value={rxLev} icon={<RXLEVIcon RXLEV={rxLev} />} />
			<InfoItem label={t('BATTERY')} value={t(formatBattery(battery))} icon={undefined} />
			<InfoItem label={t('GPS_FIXED')} value="" icon={<GPSIcon GPS_FIXED={gpsFixed} />} />
			<InfoItem
				label={t('IGNITION_ON')}
				value={ignitionOn ? t('ON') : t('OFF')}
				icon={<IGNITIONIcon IGNITION_ON={ignitionOn} />}
			/>
			<InfoItem
				label={t('ODOMETER')}
				value={getOdometerValue(mileage)}
				icon={<AvTimerOutlined fontSize="small" />}
			/>
			{/* <InfoItem label={t('HOURMETER')} value={hourmeter} icon={<HourglassBottomOutlined fontSize="small" />} /> */}
		</InfoBox>
	);
}
