import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'tracker'>, MatchConditions>;

const LIST_TRACKER_PERMISSIONS = ['fleet.tracker.list'];

const VIEW_TRACKER_PERMISSIONS = ['fleet.tracker.view'];

const CREATE_TRACKER_PERMISSIONS = [
	'fleet.tracker.new',
	'admin.company.list',
	'fleet.vehicle.list',
	'fleet.simcard.list'
];

const UPDATE_TRACKER_PERMISSIONS = [
	'fleet.tracker.edit',
	'admin.company.list',
	'fleet.vehicle.list',
	'fleet.simcard.list'
];

const DELETE_TRACKER_PERMISSIONS = ['fleet.tracker.delete'];

export const defineTrackerAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_TRACKER_PERMISSIONS),
		create: getMissingPermissions(CREATE_TRACKER_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_TRACKER_PERMISSIONS),
		delete: getMissingPermissions(DELETE_TRACKER_PERMISSIONS),
		list: getMissingPermissions(LIST_TRACKER_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('tracker', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_TRACKER_PERMISSIONS)) can('delete', 'tracker');
	if (userHasPermission(CREATE_TRACKER_PERMISSIONS)) can('create', 'tracker');
	if (userHasPermission(UPDATE_TRACKER_PERMISSIONS)) can('edit', 'tracker');
	if (userHasPermission(VIEW_TRACKER_PERMISSIONS)) can('view', 'tracker');
	if (userHasPermission(LIST_TRACKER_PERMISSIONS)) can('list', 'tracker');

	return [build(), missingPermissions] as const;
};
