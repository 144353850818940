import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { selectCompanyLogo } from 'app/store/company/companySettingsSlice';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';
import UserMenu from '../../shared-components/UserMenu';
import _ from 'lodash';

function ToolbarLayout2(props) {
	const toolbarTheme = useSelector(selectToolbarTheme);
	const companySettings = useSelector(selectCompanyLogo);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx('flex relative z-20 shadow-md', props.className)}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
			>
				<Toolbar className=" w-full p-0 lg:px-24 min-h-48 md:min-h-64">
					<img
						className="w-auto h-40"
						src={_.get(companySettings, 'logoLight40x40', '/assets/images/logo/mar.png')}
						alt="Company Logo"
					/>
					<div className="flex flex-1" />
					<div className="flex items-center mr-24 px-8 h-full overflow-x-auto right-0">
						<LanguageSwitcher />
						<AdjustFontSize />
						<FullScreenToggle />
						<NotificationPanelToggleButton />
						<UserMenu />
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default ToolbarLayout2;
