import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/users/tableColumns';
import { TSidebarMode, TUser } from 'app/main/users/types';
import storageService from 'app/services/storage';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TUser[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	visibleColumns: string[];
};

export const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view',
	visibleColumns: storageService.get('UsersColumns') || defaultVisibleColumns
};

const usersCrudSlice = createSlice({
	name: 'usersCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TUser[]>) => {
			state.selectedRows = action.payload;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('UsersColumns', action.payload);
		},

		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			if (!action.payload) {
				state.selectedRows = [];
				state.sidebarMode = 'view';
				state.isSidebarOpen = false;
			} else {
				state.isSidebarOpen = true;
			}
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSelectedRows, setIsSidebarOpen, setSidebarMode, setVisibleColumns, resetSidebarState } =
	usersCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.usersCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.usersCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.usersCrud.selectedRows;
export const selectVisibleColumns = (state: RootState) => state.usersCrud.visibleColumns as string[];

export default usersCrudSlice.reducer;
