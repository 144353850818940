import { TuneOutlined } from '@mui/icons-material';
import { Badge, Box, IconButton, Paper } from '@mui/material';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import _ from 'lodash';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { openDialog } from 'app/store/fuse/dialogSlice';
import {
	selectSelectedAlert,
	selectToDoFilters,
	selectToDoGroup,
	setDraggedAlert,
	updateToDoGroup
} from 'app/store/monitoring';
import SkeletonColumn from './shared/SkeletonColumn';
import CardTitle from './shared/CardTitle';
import VirtualListItem from './items/VirtualListItem';
import MonitoringFilterDialog from './filters/MonitoringFilterDialog';
import ToDoFilters from './filters/ToDoFilters';
import { useKanbanData } from '../hooks';
import { TMonitoringGroup } from '../types';

const AVAILABLE_GROUPS: TMonitoringGroup[] = ['alarm', 'tracker', 'vehicle'];

export default function ToDoWrapper() {
	const { t } = useTranslation('monitoringPage');
	const dispatch = useDispatch();
	const { setNodeRef: setToDoRef } = useSortable({ id: 'toDoRef' });
	const selectedAlert = useSelector(selectSelectedAlert);
	const selectedFilters = useSelector(selectToDoFilters);
	const selectedGroup = useSelector(selectToDoGroup);
	const { alerts, loading, selected } = useKanbanData({
		selectedAlert,
		selectedFilters,
		selectedGroup,
		status: 'toDo'
	});

	const numberOfFiltersApplied = _.filter([selectedFilters.severity, selectedFilters.did])?.length;
	const alertsIds = selectedGroup ? [] : alerts.map((a) => a._id);

	const handleGroupSubmit = (group: TMonitoringGroup) => dispatch(updateToDoGroup(group));

	const onOpenFiltersModal = () => {
		dispatch(
			openDialog({
				children: (
					<MonitoringFilterDialog groups={AVAILABLE_GROUPS} onSubmitGroup={handleGroupSubmit} column="toDo">
						<ToDoFilters />
					</MonitoringFilterDialog>
				)
			})
		);
	};

	useEffect(() => {
		if (selected) {
			dispatch(setDraggedAlert(selected));
		}
	}, [dispatch, selected]);

	return (
		<SortableContext items={alertsIds} strategy={verticalListSortingStrategy} id="toDo">
			<Paper
				variant="outlined"
				sx={{
					padding: [1, 6],
					overflowY: 'auto',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					width: ['100%', '680px']
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
					<CardTitle title={<Trans t={t} i18nKey="OPENED_OCCURRENCES_N" values={{ n: alerts.length }} />} />
					<IconButton onClick={onOpenFiltersModal}>
						<Badge badgeContent={numberOfFiltersApplied} color="primary">
							<TuneOutlined />
						</Badge>
					</IconButton>
				</Box>
				<Box
					sx={{
						flex: 2,
						overflowY: 'auto'
					}}
				>
					{loading ? (
						<SkeletonColumn column="toDo" />
					) : (
						<Virtuoso
							style={{ width: '100%' }}
							data={alerts}
							itemContent={VirtualListItem}
							scrollerRef={setToDoRef}
						/>
					)}
				</Box>
			</Paper>
		</SortableContext>
	);
}
