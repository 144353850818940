import { Company } from './user.type';

export enum FuelTypes {
	GASOLINE = 'GASOLINE',
	ETHANOL = 'ETHANOL',
	DIESEL = 'DIESEL',
	CNG = 'CNG',
	BIODIESEL = 'BIODIESEL'
}

export interface FuelSupply {
	id: string;
	establishment?: {
		id: string;
		name: string;
		latitude: number;
		longitude: number;
		company?: { _id: string; name: string };
	};
	supplyDate: string;

	vehicle: { _id: string; name: string };

	odometer: number;
	price: number;

	fuelPrice: number;
	fuelVolume: number;
	fuelType: FuelTypes;
	company?: Company | string;
	createdAt: string;
	createdBy: { _id: string; name: string };
	uploads?: { id: string; url: string }[];
}
