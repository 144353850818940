import i18next from 'i18next';

import addTranslation from 'app/shared-components/CRUDs/Header/headerTranslation';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const UsersPage = lazyRetry(() => import('./Users'));

i18next.addResourceBundle('en', 'usersPage', en);
i18next.addResourceBundle('sp', 'usersPage', sp);
i18next.addResourceBundle('pt', 'usersPage', pt);
addTranslation();

export const USERS_PERMISSIONS = {
	edit: 'admin.user.edit',
	view: 'admin.user.view',
	list: 'admin.user.list',
	new: 'admin.user.new',
	delete: 'admin.user.delete',
	minimal: ['admin.company.list', 'admin.profile.list', 'admin.url.list']
};

const UsersConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.user.list'],
			path: 'admin/users',
			element: (
				<LazyLoaderSuspense>
					<UsersPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<UsersPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default UsersConfig;
