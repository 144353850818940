import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import storageService from 'app/services/storage';
import { useLoginUserMutation } from 'app/store/api/authSlice';
import { selectCompanyLogo } from 'app/store/company/companySettingsSlice';

import { useUserData } from 'app/services/hooks/useUserData';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch } from 'app/store/index';
import { IToken } from 'app/types/token.types';
import jwtDecode from 'jwt-decode';
import { getApiError } from 'src/utils/getApiError';

const defaultValues = {
	email: '',
	password: '',
	remember: true
};

type TPasswordEye = {
	showPassword: boolean;
	setShowPassword: (_arg: boolean) => void;
};

const PasswordEye = ({ showPassword, setShowPassword }: TPasswordEye) => (
	<InputAdornment className="bg-transparent" position="end">
		<IconButton color="secondary" onClick={() => setShowPassword(!showPassword)}>
			{showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
		</IconButton>
	</InputAdornment>
);

function SignInPage() {
	const { t } = useTranslation('SignInPage');
	const dispatch = useAppDispatch();
	const companyLogo = useSelector(selectCompanyLogo);
	const [loginUser, { isLoading: loginLoading }] = useLoginUserMutation();
	const { loading: loadingUser, setupUser } = useUserData();

	const [showPassword, setShowPassword] = useState(false);

	const schema = yup.object({
		email: yup.string().required(t('ERROR_EMAIL_OR_USERNAME_REQUIRED')),
		password: yup.string().required(t('ERROR_PASSWORD_REQUIRED'))
	});

	const {
		control,
		formState: { errors },
		handleSubmit
	} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const isLoading = loginLoading || loadingUser;

	async function onSubmit(data) {
		try {
			const response = await loginUser({ ...data, senderHost: window.location.host }).unwrap();
			const token = response.accessToken;
			if (!token) throw new Error('Token not found');
			const decodedToken = jwtDecode<IToken>(token);
			storageService.set('token', token);
			await setupUser(decodedToken.userId);
		} catch (err) {
			const apiError = getApiError(err, t);
			dispatch(
				showMessage({
					message: apiError || t('FAILED_TO_LOGIN'),
					variant: 'error'
				})
			);
		}
	}

	return (
		<div className="flex flex-col sm:flex-row items-center md:items-center sm:justify-center md:justify-start flex-1 min-w-0 max-h-full overflow-y-hidden">
			<Paper className="h-full items-center flex sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 pt-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-400 sm:w-400 mx-auto sm:mx-0">
					<img
						className="max-h-[200px] aspect-auto mx-auto"
						src={_.get(companyLogo, 'logoUrl', '/assets/images/logo/mar.png')}
						alt="logo"
					/>

					<form
						name="loginForm"
						noValidate
						className="flex flex-col justify-center w-full pt-[32px]"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Controller
							name="email"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									label={t('EMAIL_OR_USERNAME')}
									error={!!errors.email}
									helperText={_.get(errors, 'email.message', ' ')}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>

						<Controller
							name="password"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mt-[32px]"
									label={t('PASSWORD')}
									type={showPassword ? 'text' : 'password'}
									error={!!errors.password}
									helperText={_.get(errors, 'password.message', ' ')}
									variant="outlined"
									required
									fullWidth
									autoComplete="off"
									InputProps={{
										endAdornment: (
											<PasswordEye
												showPassword={showPassword}
												setShowPassword={setShowPassword}
											/>
										)
									}}
								/>
							)}
						/>

						<Typography className="text-center my-[32px]" color="textSecondary">
							{`${t('FORGOT_PASSWORD_1')} `}
							<Link className="font-medium" to="/recover-password">
								{t('FORGOT_PASSWORD_2')}
							</Link>
						</Typography>

						<LoadingButton
							loading={isLoading}
							variant="contained"
							color="secondary"
							className="w-full rounded-md h-[53px]"
							disabled={isLoading}
							type="submit"
							size="large"
						>
							{t('SIGN_IN')}
						</LoadingButton>
					</form>
				</div>
			</Paper>

			<Box
				className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 overflow-hidden md:w-1/2"
				sx={{ backgroundColor: 'primary.main' }}
			>
				<svg
					className="absolute inset-0 pointer-events-none"
					viewBox="0 0 960 540"
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMax slice"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Box
						component="g"
						sx={{ color: 'primary.light' }}
						className="opacity-20"
						fill="none"
						stroke="currentColor"
						strokeWidth="100"
					>
						<circle r="234" cx="196" cy="23" />
						<circle r="234" cx="790" cy="491" />
					</Box>
				</svg>
				<Box
					component="svg"
					className="absolute -top-64 -right-64 opacity-20"
					sx={{ color: 'primary.light' }}
					viewBox="0 0 220 192"
					width="220px"
					height="192px"
					fill="none"
				>
					<defs>
						<pattern
							id="837c3e70-6c3a-44e6-8854-cc48c737b659"
							x="0"
							y="0"
							width="20"
							height="20"
							patternUnits="userSpaceOnUse"
						>
							<rect x="0" y="0" width="4" height="4" fill="currentColor" />
						</pattern>
					</defs>
					<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
				</Box>
			</Box>
		</div>
	);
}

export default SignInPage;
