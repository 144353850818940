import dayjs from 'dayjs';
import _ from 'lodash';
import { MAX_DATE_RANGE } from 'src/constants';
import * as yup from 'yup';

const trackerSchema = yup.object({
	_id: yup.string().required(),
	name: yup.string().required(),
	did: yup.number().required()
});

const idAndNameSchema = yup.object({
	_id: yup.string().required(),
	name: yup.string().required()
});

const trackerEventSchema = yup.object({
	...idAndNameSchema.fields,
	eventCode: yup.string().required()
});

export const filterSchemaEventsAndHistory = yup.object({
	search: yup.string().optional(),

	company: yup.array().of(idAndNameSchema).min(1, 'COMPANIES_REQUIRED'),

	tracker: yup
		.array()
		.of(trackerSchema)
		.test('isAtLeastOneTracker', 'AT_LEAST_ONE_FILTER', function test() {
			const { tracker, vehicle, eventType } = this.parent;
			const allValues = _.concat(tracker, vehicle, eventType);
			return !_.every(allValues, _.isEmpty);
		}),
	vehicle: yup
		.array()
		.of(idAndNameSchema)
		.test('isAtLeastOneTracker', 'AT_LEAST_ONE_FILTER', function test() {
			const { tracker, vehicle, eventType } = this.parent;
			const allValues = _.concat(tracker, vehicle, eventType);
			return !_.every(allValues, _.isEmpty);
		}),
	eventType: yup
		.array()
		.of(trackerEventSchema)
		.test('isAtLeastOneTracker', 'AT_LEAST_ONE_FILTER', function test() {
			const { tracker, vehicle, eventType } = this.parent;
			const allValues = _.concat(tracker, vehicle, eventType);
			return !_.every(allValues, _.isEmpty);
		}),
	start: yup
		.mixed()
		.nullable()
		.required('START_REQUIRED')
		.test({
			name: 'start-end',
			message: 'END_DATE_SHALL_BE_GREATER',
			test(value) {
				const { end } = this.parent;
				if (!end) return true;
				return dayjs(value).isBefore(dayjs(end));
			}
		}).test('is-valid-range', 'THIRTY_DAY_ERROR', function (start) {
			const { end } = this.parent;
			if (start && end) {
				return dayjs(end).diff(dayjs(start), 'days') <= MAX_DATE_RANGE;
			}
			return true;
		}),
	end: yup
		.mixed()
		.nullable()
		.required('END_REQUIRED')
		.test({
			name: 'start-end',
			message: 'END_DATE_SHALL_BE_GREATER',
			test(value) {
				const { start } = this.parent;
				if (!start) return true;
				return dayjs(value).isAfter(dayjs(start));
			}
		})
});

export type TFormData = yup.InferType<typeof filterSchemaEventsAndHistory>;

export const defaultValues: TFormData = {
	search: '',
	tracker: [],
	vehicle: [],
	eventType: [],
	company: [],
	start: null,
	end: null
};
