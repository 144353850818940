import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Checkbox, List, ListItemText, MenuItem, Stack, Tab, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { TFunction } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { TColumnsNames } from 'app/main/users/types';
import AppButton from 'app/shared-components/Buttons/AppButton';
import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import { closeDialog } from 'app/store/fuse/dialogSlice';

function getSelectedOptions(newValue: string, current: string[]) {
	const currentIndex = current.indexOf(newValue);
	const newChecked = [...current];

	if (currentIndex === -1) {
		newChecked.push(newValue);
	} else {
		newChecked.splice(currentIndex, 1);
	}
	return newChecked;
}

type TFilterDialog = {
	searchKeys: TColumnsNames[];
	columns: TColumnsNames[];
	otherFilters?: { name: string; value: string }[];
	currentSelectedColumns: string[];
	currentSearchKey?: string;
	currentOtherFilters?: string[];
	handleApply: (_searchKey: string, _columns: string[], _otherFilters?: string[]) => void;
	t: TFunction;
	showSearchKey?: boolean;
};

export default function FilterDialog({
	searchKeys,
	columns,
	otherFilters = [],
	currentSelectedColumns,
	currentSearchKey = '',
	currentOtherFilters = [],
	handleApply,
	t,
	showSearchKey = true
}: TFilterDialog) {
	const dispatch = useDispatch();

	const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
	const [selectedSearchKey, setSelectedSearchKey] = useState<string[]>([]);
	const [selectedOtherFilters, setSelectedOtherFilters] = useState<string[]>([]);

	const [currentTab, setCurrentTab] = useState<'columns' | 'searchKeys' | 'others'>(
		showSearchKey ? 'searchKeys' : 'columns'
	);

	const hasOtherFilters = _.size(otherFilters) > 0;

	const handleChangeTab = (_event: React.SyntheticEvent, newValue: 'columns' | 'searchKeys' | 'others') => {
		setCurrentTab(newValue);
	};

	const handleCloseModal = () => {
		dispatch(closeDialog());
	};

	const handleClickSearchKey = (newValue: string) => {
		const nextItems = getSelectedOptions(newValue, selectedSearchKey);
		setSelectedSearchKey(nextItems);
	};

	const handleClickColumn = (newValue: string) => {
		const nextItems = getSelectedOptions(newValue, selectedColumns);
		if (nextItems.length === 0) return;
		setSelectedColumns(nextItems);
	};

	const handleClickOtherFilter = (newValue: string) => {
		const nextItems = getSelectedOptions(newValue, selectedOtherFilters);
		setSelectedOtherFilters(nextItems);
	};

	const handleApplyFilter = () => {
		handleApply(selectedSearchKey.join(), selectedColumns, selectedOtherFilters);
		handleCloseModal();
	};

	useEffect(() => {
		if (!currentSearchKey) return;
		setSelectedSearchKey(currentSearchKey.split(','));
	}, [currentSearchKey]);

	useEffect(() => {
		if (!currentSelectedColumns) return;
		setSelectedColumns(currentSelectedColumns);
	}, [currentSelectedColumns]);

	useEffect(() => {
		if (!currentOtherFilters.length) return;
		setSelectedOtherFilters(currentOtherFilters);
	}, [currentOtherFilters]);

	return (
		<DialogWrapper
			title={t('FILTERS', { ns: 'Header' })}
			onClose={handleCloseModal}
			content={
				<Stack
					direction="column"
					spacing={3}
					style={{ width: '360px', minHeight: '450px', justifyContent: 'space-between' }}
				>
					<Stack spacing={1} direction="column">
						<Typography
							sx={{
								maxWidth: 'auto',
								fontSize: '14px',
								fontWeight: 400
							}}
						>
							{currentTab === 'searchKeys'
								? t('SEARCH_KEYS_INFO', { ns: 'Header' })
								: t('COLUMNS_INFO', { ns: 'Header' })}
						</Typography>
						<TabContext value={currentTab}>
							<TabList
								variant="fullWidth"
								textColor="secondary"
								indicatorColor="secondary"
								onChange={handleChangeTab}
							>
								{showSearchKey && (
									<Tab
										label={t('SEARCH_KEYS', {
											ns: 'Header'
										})}
										value="searchKeys"
									/>
								)}
								<Tab
									label={t('COLUMNS', {
										ns: 'Header'
									})}
									value="columns"
								/>
								{hasOtherFilters ? (
									<Tab
										label={t('OTHERS', {
											ns: 'Header'
										})}
										value="others"
									/>
								) : undefined}
							</TabList>
							<TabPanel
								value="searchKeys"
								sx={{
									padding: '0px'
								}}
							>
								<List
									sx={{
										maxHeight: '300px',
										overflowY: 'auto'
									}}
								>
									{searchKeys.map((item) => (
										<MenuItem
											key={item.columnId}
											onClick={() => handleClickSearchKey(item.columnId)}
										>
											<Checkbox checked={selectedSearchKey.includes(item.columnId)} />
											<ListItemText
												disableTypography
												sx={{
													display: 'flex',
													alignItems: 'center'
												}}
												primary={item.name}
											/>
										</MenuItem>
									))}
								</List>
							</TabPanel>
							<TabPanel
								value="columns"
								sx={{
									padding: '0px'
								}}
							>
								<List sx={{ maxHeight: '300px', overflowY: 'auto' }}>
									{columns.map((item) => (
										<MenuItem key={item.columnId} onClick={() => handleClickColumn(item.columnId)}>
											<Checkbox checked={selectedColumns.includes(item.columnId)} />
											<ListItemText
												disableTypography
												sx={{
													display: 'flex',
													alignItems: 'center'
												}}
												primary={item.name}
											/>
										</MenuItem>
									))}
								</List>
							</TabPanel>
							{hasOtherFilters ? (
								<TabPanel value="others">
									<List sx={{ maxHeight: '300px', overflowY: 'auto' }}>
										{otherFilters.map((item) => (
											<MenuItem
												key={item.value}
												onClick={() => handleClickOtherFilter(item.value)}
											>
												<Checkbox checked={selectedOtherFilters.includes(item.value)} />
												<ListItemText
													disableTypography
													sx={{ display: 'flex', alignItems: 'center' }}
													primary={item.name}
												/>
											</MenuItem>
										))}
									</List>
								</TabPanel>
							) : undefined}
						</TabContext>
					</Stack>
					<AppButton variant="contained" color="secondary" fullWidth onClick={handleApplyFilter}>
						{t('APPLY', { ns: 'Header' })}
					</AppButton>
				</Stack>
			}
		/>
	);
}
