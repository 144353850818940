import { Stack, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import type { ReactNode } from 'react';

type SvgIconComponent = typeof SvgIcon;

type TActionItemProps = {
	text: string;
	icon: SvgIconComponent;
	children?: ReactNode;
	onClick: () => void;
};

export default function ActionItem({ text, icon: Icon, children, onClick }: TActionItemProps) {
	return (
		<Stack
			direction="row"
			spacing={1}
			alignItems="center"
			sx={{
				cursor: 'pointer',
				'&:hover': {
					textDecoration: 'underline'
				}
			}}
			onClick={onClick}
		>
			<Icon color="secondary" fontSize="large" />
			<Typography sx={{ fontWeight: 600, fontSize: '12px' }}>{text}</Typography>
			{children}
		</Stack>
	);
}
