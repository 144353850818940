import { Badge } from "@mui/material";
import { createDownloadLink, parseToCSV } from "app/contexts/DownloadContext/helper";
import TooltipIconButton from "app/shared-components/Buttons/TooltipIconButton";
import { showMessage } from "app/store/fuse/messageSlice";
import { RootState, useAppDispatch } from "app/store/index";
import { TFunction } from "i18next";
import _ from "lodash";
import { useSelector } from "react-redux";

interface DownloadSelectedProps {
    t: TFunction;
    selectSelectedRows: (state: RootState) => any[];
    fileName: string;
    visibleColumns: any;
}

export default function DownloadSelected ({ 
    t, 
    selectSelectedRows, 
    fileName, 
    visibleColumns 
}: DownloadSelectedProps) {
    const selectedRows = useSelector(selectSelectedRows);
    const hasRowsSelected = !_.isEmpty(selectedRows);
    const dispatch = useAppDispatch();

    const handleDownloadSelected = () => {
		try {
			const csv = parseToCSV({
				data: selectedRows,
				isFirstPage: true,
				csvHeaders: visibleColumns
			});
			createDownloadLink(csv, fileName);
		} catch {
			dispatch(
				showMessage({
					message: t('DOWNLOAD_ERROR'),
					variant: 'error'
				})
			);
		}
	};

    return (
        <Badge badgeContent={hasRowsSelected ? _.size(selectedRows) : undefined} color="secondary">
            <TooltipIconButton
                iconButtonProps={{ disabled: !hasRowsSelected }}
                label={t('DOWNLOAD_SELECTED')}
                icon="download"
                size="small"
                onClick={handleDownloadSelected}
            />
        </Badge>
    )
}