import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/ibutton/tableColumns';
import { TIbutton, TSidebarMode } from 'app/main/ibutton/types';
import storageService from 'app/services/storage';
import _ from 'lodash';
import { RootState } from '..';
import tableSchema from '../../main/ibutton/components/MassiveCreate/tableSchema';
import { IPayload } from '../api/ibuttonsSlice';

type TInitialState = {
	selectedRows: TIbutton[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	visibleColumns: string[];
	massiveCreateTableData: IPayload[];
	isMassiveUploading: boolean;
	isShowingInvalidRows: boolean;
	selectedMassiveCreateRows: IPayload[];
};

const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view',
	visibleColumns: storageService.get('ibuttonsVisibleColumns') || defaultVisibleColumns,
	massiveCreateTableData: [],
	isMassiveUploading: false,
	isShowingInvalidRows: false,
	selectedMassiveCreateRows: []
};

const ibuttonsCrudSlice = createSlice({
	name: 'ibuttonsCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TIbutton[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('ibuttonsVisibleColumns', action.payload);
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		},
		setMassiveCreateTableData: (state, action: PayloadAction<IPayload[]>) => {
			state.massiveCreateTableData = action.payload;
		},
		resetMassiveCreateTableData: (state) => {
			state.massiveCreateTableData = [];
			state.selectedMassiveCreateRows = [];
		},
		setIsShowingInvalidRows: (state, action: PayloadAction<boolean>) => {
			state.isShowingInvalidRows = action.payload;
		},
		setSelectedMassiveCreateRows: (state, action: PayloadAction<IPayload[]>) => {
			state.selectedMassiveCreateRows = action.payload;
		},
		removeRowsFromData: (state, action: PayloadAction<IPayload[]>) => {
			state.massiveCreateTableData = _.filter(
				state.massiveCreateTableData,
				(row) => !_.some(action.payload, (rowToRemove) => _.isEqual(row, rowToRemove))
			);

			state.selectedMassiveCreateRows = _.filter(
				state.selectedMassiveCreateRows,
				(row) => !_.some(action.payload, (rowToRemove) => _.isEqual(row, rowToRemove))
			);
		}
	}
});

export const {
	setIsSidebarOpen,
	setSidebarMode,
	setSelectedRows,
	setVisibleColumns,
	resetSidebarState,
	setMassiveCreateTableData,
	resetMassiveCreateTableData,
	setIsShowingInvalidRows,
	setSelectedMassiveCreateRows,
	removeRowsFromData
} = ibuttonsCrudSlice.actions;
export const selectIsSidebarOpen = (state: RootState) => state.ibuttonsCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.ibuttonsCrud.sidebarMode;
export const selectSelectedRows = (state: RootState) => state.ibuttonsCrud.selectedRows;
export const selectVisibleColumns = (state: RootState) => state.ibuttonsCrud.visibleColumns;

export const selectMassiveCreateTableData = createSelector(
	(state: RootState) => state.ibuttonsCrud.isShowingInvalidRows,
	(state: RootState) => state.ibuttonsCrud.massiveCreateTableData,
	(isShowingInvalidRows, massiveCreateTableData) => {
		if (isShowingInvalidRows) {
			return massiveCreateTableData.filter((row) => !tableSchema.isValidSync(row));
		} else {
			return massiveCreateTableData;
		}
	}
) as (_state: RootState) => IPayload[];

export const selectInvalidRowsCount = createSelector(
	(state: RootState) => state.ibuttonsCrud.massiveCreateTableData,
	(massiveCreateTableData) => {
		return massiveCreateTableData.filter((row) => !tableSchema.isValidSync(row)).length;
	}
);

export const selectSelectedMassiveCreateRows = (state: RootState) =>
	state.ibuttonsCrud.selectedMassiveCreateRows as IPayload[];

export const selectIsMassiveUploading = (state: RootState) => state.ibuttonsCrud.isMassiveUploading;

export const selectIsShowingInvalidRows = (state: RootState) => state.ibuttonsCrud.isShowingInvalidRows;

export default ibuttonsCrudSlice.reducer;
