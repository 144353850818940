const locale = {
	RECOVER_PASSWORD: 'Recuperar Contraseña',
	SEND: 'Enviar',
	RECOVER_PASSWORD_DESCRIPTION:
		'Para recuperar su acceso, necesitamos su correo electrónico para enviarle el enlace de recuperación. Si no tiene un correo electrónico, comuníquese con un administrador.',
	SEND_EMAIL: 'Enviar Correo',
	ERROR_EMAIL_INVALID: 'Debe ingresar un correo electrónico válido',
	BACK: 'Atrás',
	SUCCESS_SEND_EMAIL:
		'Se ha enviado un correo electrónico a su dirección de correo electrónico con instrucciones para restablecer su contraseña.',
	ERROR_SEND_EMAIL: 'Error al enviar el correo electrónico',
	ERROR_EMAIL_REQUIRED: 'Email es obligatorio.',
	ERROR_PASSWORD_REQUIRED: 'La contraseña es obligatoria',
	ERROR_PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
	ERROR_PASSWORD_MIN: 'La contraseña debe tener al menos 6 caracteres',
	PASSWORD: 'Contraseña',
	CONFIRM_PASSWORD: 'Confirmar Contraseña',
	FORGOT_PASSWORD: '¿Olvidó su contraseña?',
	SUCCESS_CHANGE_PASSWORD: 'Contraseña cambiada con éxito',
	ERROR_CHANGE_PASSWORD: 'Error al cambiar la contraseña'
};

export default locale;
