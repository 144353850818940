import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { IconButton, Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';

interface ITooltipIconButtonProps {
	icon: string;
	label: TooltipProps['title'];
	size?: 'small' | 'medium';
	tooltipPlacement?: TooltipProps['placement'];
	onClick: () => void;
	tooltipProps?: TooltipProps;
	iconButtonProps?: React.ComponentProps<typeof IconButton>;
}

const TooltipIconButton: FC<ITooltipIconButtonProps> = ({
	icon,
	label,
	size = 'small',
	tooltipPlacement = 'top',
	onClick,
	tooltipProps,
	iconButtonProps = {
		color: 'secondary'
	}
}) => (
	<Tooltip {...tooltipProps} title={label} placement={tooltipPlacement}>
		<span>
			<IconButton {...iconButtonProps} onClick={onClick} size={size}>
				<FuseSvgIcon>{`material-outline:${icon}`}</FuseSvgIcon>
			</IconButton>
		</span>
	</Tooltip>
);

export default TooltipIconButton;
