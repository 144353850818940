import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { FC } from 'react';
import { TCategory, TLesson } from '../../types/types';
import LessonCategory from './LessonCategory';

interface LessonInfoProps {
	lesson: TLesson;
	className: string;
}
const LessonInfo: FC<LessonInfoProps> = ({ lesson, className }) => {
	if (!lesson) {
		return null;
	}
	return (
		<div className={clsx('w-full', className)}>
			<div className="flex items-center justify-between mb-16">
				<div className="flex flex-wrap">
					{lesson?.category?.map((category: TCategory) => (
						<LessonCategory key={category?._id} categoryName={category?.name} />
					))}
				</div>

				{/* TODO COURSE PROGRESS */}
				{/* {lesson.progress.completed > 0 && (
          <FuseSvgIcon className="text-green-600" size={20}>
            heroicons-solid:badge-check
          </FuseSvgIcon>
        )} */}
			</div>

			<Typography className="text-16 font-medium">{lesson.title}</Typography>

			<Typography className="text-13 mt-2 line-clamp-2" color="text.secondary">
				{lesson.description}
			</Typography>

			<Divider className="w-48 my-24 border-1" light />

			<Typography className="flex items-center space-x-6 text-13" color="text.secondary">
				<FuseSvgIcon color="disabled" size={20}>
					heroicons-solid:clock
				</FuseSvgIcon>
			</Typography>
			<Typography className="flex items-center space-x-6 text-13 mt-8" color="text.secondary">
				{/* TODO IMPLEMENT COMPLETED*/}
				{/* <FuseSvgIcon color="disabled" size={20}>
					heroicons-solid:academic-cap
				</FuseSvgIcon> */}
				{/* <span className="whitespace-nowrap leading-none">
          {lesson.progress.completed === 1 && 'Completed once'}
          {lesson.progress.completed === 2 && 'Completed twice'}
          {lesson.progress.completed > 2 && `Completed ${lesson.progress.completed} times`}
          {lesson.progress.completed <= 0 && 'Never completed'}
        </span> */}
			</Typography>
		</div>
	);
};

export default LessonInfo;
