import { AccessTimeOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CardButton from 'app/shared-components/Buttons/CardButton';

export default function Header() {
	const { t } = useTranslation('monitoringPage');
	const navigate = useNavigate();

	const handlePush = () => navigate('/monitoring/done', { replace: false });

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: ['flex-start', 'center'],
				flexDirection: ['column', 'row'],
				paddingY: ['18px', '36px'],
				paddingX: ['20px', '40px'],
				gap: ['8px', '0px']
			}}
		>
			<Typography
				sx={{
					fontSize: '24px',
					fontWeight: 700
				}}
			>
				{t('MONITORING')}
			</Typography>
			<CardButton
				text={t('FINISHED_EVENTS')}
				icon={<AccessTimeOutlined color="secondary" />}
				onClick={handlePush}
			/>
		</Box>
	);
}
