import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { selectCurrentTab, setCurrentTab } from 'app/store/academy/academySlice';
import { useAppDispatch } from 'app/store/index';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CoursesTab from '../../courses/CoursesTab';
import Lessons from '../lessons/LessonsTab';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	tab: string;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const tabs = ['courses', 'lessons'];

export default function BasicTabs() {
	const [value, setValue] = React.useState(0);
	const { t } = useTranslation('academyPage');
	const dispatch = useAppDispatch();
	const currentTab = useSelector(selectCurrentTab);
	const navigate = useNavigate();

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		dispatch(setCurrentTab(tabs[newValue]));
		navigate(`/academy/${tabs[newValue]}`);
	};

	useEffect(() => {
		setValue(tabs.indexOf(currentTab));
	}, [currentTab]);

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
					<Tab label={t('COURSES')} {...a11yProps(0)} />
					<Tab label={t('LESSONS')} {...a11yProps(1)} />
				</Tabs>
			</Box>
			<CustomTabPanel value={value} index={0} tab="courses">
				<CoursesTab />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={1} tab="lessons">
				<Lessons />
			</CustomTabPanel>
		</Box>
	);
}
