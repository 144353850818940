const locale = {
	TITLE: 'Installation Records',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear Filters',
	CANCEL: 'Cancel',
	CONFIRM: 'Confirm',
	SELECTED_ITEMS: '{{n}} items selected',

	// Table
	ROWS_PER_PAGE: 'Items per page',
	OF: 'of',
	NAME: 'Name',
	BRAND: 'Brand',
	MODEL: 'Model',
	YEAR: 'Year',
	INSTALLATION_DATE: 'Installation Date',
	MAINTENANCE_DATE: 'Maintenance Date',
	NO_MAINTENANCE: 'No maintenance record',

	// Delete
	DELETE: 'Delete',
	DELETE_MULTIPLE_INFO: 'Delete installation records',
	DELETE_SUCCESS: 'Installation record deleted successfully!',
	DELETE_ERROR: 'Error deleting installation record.',
	DELETE_MODAL_TITLE: 'Delete Installation Record',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} installation records?',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this installation record?',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',

	// Download
	DOWNLOAD_INFO: 'Download installation records',
	DOWNLOAD_ERROR: 'Error downloading installation records.'
};

export default locale;
