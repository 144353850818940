import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';

const PanelBuilder = lazyRetry(() => import('./PanelBuilder'));

export const DASHBOARD_PERMISSIONS = {
	edit: 'admin.dashboard.edit',
	view: 'admin.dashboard.view',
	list: 'admin.dashboard.list',
	new: 'admin.dashboard.new',
	delete: 'admin.dashboard.delete',
	minimal: []
};

const PanelConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			// path: '/panel/:id/*',
			path: '/panel',
			element: (
				<LazyLoaderSuspense>
					<PanelBuilder />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default PanelConfig;
