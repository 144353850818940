
const locale = {
  TITLE: "Monitoring Events",
  ADD: "Add",
  YES: "Yes",
  NO: "No",
  SEARCH: "Search",
  CLEAR_SEARCH: "Clear filters",
  EDIT: "Edit",
  VIEW: "View",
  SAVE: "Save",
  DELETE: "Delete",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  DATE: "Date",
  OF: "of",
  
  SELECTED_ITEMS: "Selected items",
  NO_ITEM_SELECTED: "No item selected",
  EXPORT_SELECTED: "Export selected items",
  DELETE_SELECTED: "Delete selected items",
  EDIT_SELECTED: "Edit selected items",
  ROWS_PER_PAGE: "Items per page",
  
  NAME_REQUIRED: "The name field is required",
  PARSERS_REQUIRED: "The parsers field is required",
  EVENT_CODE_REQUIRED: "The event code field is required",
  
  NO_EVENT_FOUND: "No event found",
  
  NAME: "Name",
  UPDATED_AT: "Updated at",
  CREATED_AT: "Created at",
  CREATED_BY: "Created by",
  PARSERS: "Parsers",
  PARSER: "Parser",
  ADD_EVENT: "Add event",
  EVENT_CODE: "Event code",
  ICON: "Icon",
  N_PARSERS: "{{n}} Parsers",
  DESCRIPTION: "Description",
  
  CREATE_SUCCESS: "Event successfully added",
  CREATE_ERROR: "Error adding event",
  EDIT_SUCCESS: "Event successfully updated",
  EDIT_ERROR: "Error updating event",
  ERROR_ADD_EMPTY: "Fill in all fields",
  DELETE_SUCCESS: "Event successfully deleted",
  DELETE_ERROR: "Error deleting event",
  MORE_THAN: "more than",
  
  DELETE_MODAL_TITLE: "Delete event",
  DELETE_MODAL_DESCRIPTION: "Are you sure you want to delete this event?",
  DELETE_MODAL_WARNING: "This action cannot be undone.",
  DELETE_MODAL_MULTIPLE_DESCRIPTION: "Are you sure you want to delete {{n}} events?",
  
  EDIT_INFO: 'Edit event',
  DELETE_INFO: 'Delete event',
  DOWNLOAD_INFO: 'Download selected events',
  EDIT_MULTIPLE_INFO: 'Edit selected events',
  DELETE_MULTIPLE_INFO: 'Delete selected events',
  
  SELECTED_ITENS: '{{n}} items selected',
  DOWNLOAD_ERROR: 'Error downloading events',
  
  EDIT_MULTIPLE_SUCCESS: "Events successfully edited",
  EDIT_MULTIPLE_ERROR: "Error editing events",
  
  PAGE_NOT_ALLOWED: "You do not have permission to view this screen.",
  MINIMAL_START: "Request the Administrator to add the following permissions to your profile:",
  MINIMAL_END: "Then log out and log back into the system."
  };

export default locale;
