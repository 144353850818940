import i18next from 'i18next';

const pt = {
	DOWNLOAD_MESSAGE_1: 'O download de arquivos pode demorar alguns minutos.',
	DOWNLOAD_MESSAGE_2:
		'Durante este tempo, você não poderá realizar a operação de download novamente, deseja continuar?',
	CONTINUE: 'Continuar',
	CANCEL: 'Cancelar'
};

const sp = {
	DOWNLOAD_MESSAGE_1: 'La descarga de archivos puede tardar unos minutos.',
	DOWNLOAD_MESSAGE_2: 'Durante este tiempo, no podrá realizar la operación de descarga nuevamente, ¿desea continuar?',
	CONTINUE: 'Continuar',
	CANCEL: 'Cancelar'
};

const en = {
	DOWNLOAD_MESSAGE_1: 'File download may take a few minutes.',
	DOWNLOAD_MESSAGE_2:
		'During this time, you will not be able to perform the download operation again, do you want to continue?',
	CONTINUE: 'Continue',
	CANCEL: 'Cancel'
};

export const locale = () => {
	i18next.addResources('pt', 'downloadWarning', pt);
	i18next.addResources('sp', 'downloadWarning', sp);
	i18next.addResources('en', 'downloadWarning', en);
};
