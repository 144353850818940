import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import StatusChip from 'app/main/commands/historyCommands/components/StatusChip';
import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { BlockIcon } from './components/BlockIcon';
import { TTableColumn } from './types';

const columnMinWidth = '230px';

type TColumnsNames = { name: string; columnId: string };

export const defaultVisibleColumns = [
	'tracker.name',
	'trackerType.name',
	'scheduledCommandHistory.history.status',
	'updatedAt',
	'createdAt',
	'createdBy.name'
];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ columnId: 'tracker.name', name: t('TRACKER') },
	{ columnId: 'trackerType.name', name: t('TRACKER_TYPE') },
	{ columnId: 'scheduledCommandHistory.history.status', name: t('STATUS') },
	{ columnId: 'updatedAt', name: t('UPDATED_AT') },
	{ columnId: 'createdAt', name: t('CREATED_AT') },
	{ columnId: 'createdBy.name', name: t('SENDED_BY') }
];

export const getSearchKeysNames = getColumnsNames;

const isOmmited = (columnId: string, allColumns: string[]) => (allColumns ? !allColumns.includes(columnId) : false);

export const getTableColumns = (t: TFunction, visibleColumns: string[]): TTableColumn[] => [
	{
		id: 'tracker.name',
		name: <ColumnName name={t('TRACKER')} icon="device_hub" />,
		cell: (row) => {
			const trackerName = _.get(row, 'tracker.name', '');
			const commandName = _.get(row, 'scheduledCommand.scheduledCommandName', '');
			const isBlockCommand = commandName === 'Bloqueio';

			return (
				<Stack direction="row" alignItems="center" gap={2}>
					<BlockIcon isBlockCommand={isBlockCommand} />
					{trackerName}
				</Stack>
			);
		},
		omit: isOmmited('tracker.name', visibleColumns),
		sortable: false,
		minWidth: columnMinWidth
	},
	{
		id: 'trackerType.name',
		name: <ColumnName name={t('TRACKER_TYPE')} icon="dns" />,
		selector: (row) => _.get(row, 'trackerType.name', ''),
		omit: isOmmited('trackerType.name', visibleColumns),
		sortable: false,
		minWidth: columnMinWidth
	},
	{
		id: 'scheduledCommandHistory.history.status',
		name: <ColumnName name={t('STATUS')} icon="check" />,
		cell: (row) => {
			const history = _.get(row, 'scheduledCommandHistory.history', []);
			const lastStatus = _.last(history);
			const status = _.get(lastStatus, 'status', '');
			return <StatusChip status={status} t={t} />;
		},
		omit: isOmmited('scheduledCommandHistory.history.status', visibleColumns),
		sortable: false,
		minWidth: columnMinWidth
	},
	{
		id: 'updatedAt',
		name: (
			<Stack direction="row" alignItems="center" sx={{ whiteSpace: 'nowrap' }} gap={1}>
				<ColumnName name={t('UPDATED_AT')} icon="timeline" />
				<Tooltip title={t('LAST_STATUS_UPDATED')} arrow>
					<InfoOutlined sx={{ color: 'grey.500', fontSize: '18px' }} />
				</Tooltip>
			</Stack>
		),
		selector: (row) => {
			const updatedAt = _.get(row, 'updatedAt', '');
			return updatedAt ? dayjs(updatedAt).format('DD/MM/YYYY HH:mm:ss') : '';
		},
		omit: isOmmited('updatedAt', visibleColumns),
		sortable: true,
		minWidth: columnMinWidth
	},
	{
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="date_range" />,
		selector: (row) => {
			const createdAt = _.get(row, 'createdAt', '');
			return createdAt ? dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss') : '';
		},
		omit: isOmmited('createdAt', visibleColumns),
		sortable: true,
		minWidth: columnMinWidth
	},
	{
		id: 'createdBy.name',
		name: <ColumnName name={t('SENDED_BY')} icon="person" />,
		selector: (row) => _.get(row, 'createdBy.name', ''),
		omit: isOmmited('createdBy.name', visibleColumns),
		sortable: false,
		minWidth: columnMinWidth
	}
];
