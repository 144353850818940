import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const FleetPage = lazyRetry(() => import('./Fleet'));

i18next.addResourceBundle('en', 'fleetsPage', en);
i18next.addResourceBundle('sp', 'fleetsPage', sp);
i18next.addResourceBundle('pt', 'fleetsPage', pt);

export const FLEET_PERMISSIONS = {
	edit: 'fleet.fleet.edit',
	view: 'fleet.fleet.view',
	list: 'fleet.fleet.list',
	new: 'fleet.fleet.new',
	delete: 'fleet.fleet.delete',
	minimal: ['admin.company.list']
};

const FleetsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.fleet.list'],
			path: 'fleet/fleets',
			element: (
				<LazyLoaderSuspense>
					<FleetPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<FleetPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default FleetsConfig;
