import { MapboxStyle } from 'react-map-gl/dist/esm/types';

export const tripMapStyle: MapboxStyle = {
	version: 8,
	sources: {
		osm: {
			type: 'raster',
			tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
			tileSize: 256,
			maxzoom: 19
		},
		hybrid: {
			type: 'raster',
			url: `https://api.maptiler.com/maps/hybrid/tiles.json?key=${import.meta.env.VITE_MAPTILER_KEY}`
		}
	},
	layers: [
		{
			id: 'osm',
			type: 'raster',
			source: 'osm' // This must match the source key above
		}
	],
	glyphs: 'https://cdn.jsdelivr.net/gh/marine-br/front-map-fonts/fonts/{fontstack}/{range}.pbf',
	sprite: 'https://cdn.jsdelivr.net/gh/marine-br/front-map-sprites@37dea3f8a97fc59ee7ef47760f1bc86ce9b66824/sprite'
};
