const sp = {
	TITLE: 'Horarios permitidos de acceso',
	PARTIAL: 'Parcial',
	FULL: 'Total',
	NO_ACCESS: 'Sin acceso',
	START: 'Inicio',
	END: 'Fin',
	TO: 'hasta',
	ACCESS_TYPE: 'Tipo de acceso',
	ACTIVE: 'Activo',

	MONDAY: 'Lunes',
	TUESDAY: 'Martes',
	WEDNESDAY: 'Miércoles',
	THURSDAY: 'Jueves',
	FRIDAY: 'Viernes',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',

	REMOVE_PERIOD: 'Eliminar período',
	ADD_PERIOD: 'Agregar otro período',

	SAVE: 'Guardar',
	CANCEL: 'Cancelar',

	CONFIGURE_PERIODS: 'Configurar períodos',

	FULL_ACCESS: 'El usuario tendrá acceso total al sistema durante este día',
	PARTIAL_ACCESS: 'El usuario tendrá acceso en horarios específicos durante este día',
	NONE_ACCESS: 'El usuario no tendrá acceso al sistema durante este día',
	VIEW_INFO: 'Ayuda',
	START_DATE_MUST_BE_GREATER_THAN_END_DATE: 'La fecha de inicio debe ser mayor que la fecha de finalización',
	PERIOD_NOT_OVERLAP: 'Los períodos no pueden superponerse'
};

export default sp;
