import { Stack } from '@mui/material';
import i18next from 'i18next';
import { BaseUrlInput } from './BaseUrlInput';

i18next.addResourceBundle('en', 'development', {
	DEVELOPMENT: 'Development'
});

i18next.addResourceBundle('pt', 'development', {
	DEVELOPMENT: 'Desenvolvimento'
});

i18next.addResourceBundle('sp', 'development', {
	DEVELOPMENT: 'Desarrollo'
});

export const DevelopmentModal: React.FC = () => {
	return (
		<Stack minWidth={480} minHeight={320} p={3}>
			<BaseUrlInput />
		</Stack>
	);
};
