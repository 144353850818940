import { DialogContent, Stack, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';

import AppButton from './Buttons/AppButton';

interface IDeleteDialog {
	onClose: () => void;
	onConfirm: () => void;
	t: TFunction;
	loading: boolean;
	targetIds?: string[];
}

export default function DeleteDialog({ onClose, onConfirm, loading = false, t, targetIds = [] }: IDeleteDialog) {
	return (
		<DialogContent>
			<Typography variant="h6" className="font-bold">
				{t('DELETE_MODAL_TITLE')}
			</Typography>
			<Typography variant="body2" className="mt-[10px]">
				{targetIds.length > 1
					? t('DELETE_MODAL_MULTIPLE_DESCRIPTION', { n: targetIds?.length })
					: t('DELETE_MODAL_DESCRIPTION')}
			</Typography>
			<Typography color="error" variant="body2">
				{t('DELETE_MODAL_WARNING')}
			</Typography>
			<Stack direction="row" spacing={1} className="mt-[20px]" alignItems="center" justifyContent="center">
				<AppButton variant="outlined" color="error" onClick={onConfirm} loading={loading} fullWidth>
					{t('CONFIRM')}
				</AppButton>
				<AppButton variant="contained" color="secondary" onClick={onClose} fullWidth>
					{t('CANCEL')}
				</AppButton>
			</Stack>
		</DialogContent>
	);
}
