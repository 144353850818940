const locale = {
	TRACKER: 'Rastreador',
	VEHICLE: 'Veículo',
	TITLE: 'Histórico de notificações',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfis',
	PROFILE: 'Perfil',
	USERS: 'Usuários',
	USER: 'Usuário',
	DATE: 'Data',
	RULES: 'Regras',
	OF: 'de',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	REGISTRED_RULES: '{{n}} Regras cadastradas',
	NAME: 'Nome',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	LOADING: 'Carregando',
	CONFIRM: 'Confirmar',
	DOWNLOAD_INFO: 'Baixar identificadores selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	NO_HISTORY_FOUND: 'Nenhum histórico encontrado',
	ROWS_PER_PAGE: 'Itens por página',
	TRACKER_MESSAGE: 'Mensagem do rastreador',
	VIEW_RULER: 'Visualizar regra',
	EVENT_TYPE: 'Tipo de evento',
	TRACKER_ID: 'ID do rastreador',
	IGNITION: 'Ignição',
	ODOMETER: 'Odômetro',
	GPS_STATUS: 'Status do GPS',
	FIXED: 'FIXADO',
	UNFIXED: 'NÃO FIXADO',
	ADDRESS: 'Endereço',
	DATE_TIME: 'Data e hora',
	DELETED_RULE: 'Regra excluída',
	SHOW_DELETED: 'Mostrar regras excluídas',
	AGGREGATED_DATA: 'Dados agregados',
	AGGREGATED_DATA_DESCRIPTION:
		'Esta regra foi atingida {{counter}} vezes durante {{totalTime}} entre {{start}} e {{end}}',
	AGGREGATED_DATA_INFO: 'Acompanho os dados agregados da regra abaixo',

	QUANTITY: 'Quantidade',
	START: 'Início',
	END: 'Fim',
	TOTAL_TIME: 'Duração',
	AGGREGATE: 'Duração da regra',
	PACKETS: 'Pacotes',

	// Download

	DOWNLOAD_ALL: 'Baixar todos',

	// Common

	SPEED: 'Velocidade'
};

export default locale;
