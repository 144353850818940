import {
	Battery20Outlined,
	Battery30Outlined,
	Battery50Outlined,
	Battery80Outlined,
	BatteryAlertOutlined,
	BatteryFullOutlined,
	BatteryUnknownOutlined
} from '@mui/icons-material';
import { BatteryStatus } from 'app/types/battery-status.types';
import { ReactNode } from 'react';

interface BatteryIconProps {
	batteryStatus: BatteryStatus;
}

export const BatteryIcon: React.FC<BatteryIconProps> = ({ batteryStatus }) => {
	const icons: Record<BatteryStatus, ReactNode> = {
		SHUTDOWN: <BatteryAlertOutlined color="error" />,
		EXTREMELY_LOW_POWER: <Battery20Outlined color="error" />,
		VERY_LOW_POWER: <Battery20Outlined color="warning" />,
		LOW_BATTERY: <Battery30Outlined color="warning" />,
		MEDIUM: <Battery50Outlined color="warning" />,
		HIGH: <Battery80Outlined color="success" />,
		VERY_HIGH: <BatteryFullOutlined color="success" />,
		UNKNOWN: <BatteryUnknownOutlined color="disabled" />
	};

	return icons[batteryStatus] as ReactNode;
};
