import { ReactNode } from 'react';

interface Props {
	innerScroll?: boolean;
	children?: ReactNode;
}

function FusePageSimpleSidebarContent(props: Props) {
	const { innerScroll, children } = props;

	if (!children) {
		return null;
	}

	return <div className="FusePageSimple-sidebarContent">{children}</div>;
}

export default FusePageSimpleSidebarContent;
