import { InfoOutlined, ShareLocationOutlined } from '@mui/icons-material';
import { Button, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ActionBarBtn from 'app/shared-components/Buttons/MiniButton';
import { openDialog } from 'app/store/fuse/dialogSlice';
import AlarmDetailDialog from '../shared/AlarmDetailDialog';
import CloseButton from '../shared/CloseButton';

import usePermission from 'app/services/hooks/usePermission';
import { MONITORING_PERMISSIONS } from '../../MonitoringConfig';
import type { TMonitoringAlert } from '../../types';

type TMobileActions = {
	handleRealtime: () => void;
	handleFinalizeDialog: () => Dispatch<AnyAction>;
	handleGoOn: () => Promise<void>;
	data: TMonitoringAlert;
};

const DetailButton = ({ data }) => {
	const dispatch = useDispatch();

	const handleClick = () =>
		dispatch(
			openDialog({
				children: <AlarmDetailDialog data={data} />
			})
		);
	return (
		<IconButton onClick={handleClick} size="small">
			<InfoOutlined />
		</IconButton>
	);
};

export default function MobileActions({ data, handleFinalizeDialog, handleGoOn, handleRealtime }: TMobileActions) {
	const theme = useTheme();
	const { t } = useTranslation('monitoringPage');
	const isDarkMode = theme.palette.mode === 'dark';

	const isToDo = data.status === 'toDo';
	const permissions = usePermission({ pagePermissions: MONITORING_PERMISSIONS });

	return (
		<Stack direction="row" spacing={1} width="100%" justifyContent="space-between">
			{permissions.hasEdit && <CloseButton isDarkMode={isDarkMode} handleClick={handleFinalizeDialog} t={t} />}
			<ActionBarBtn size="medium" handleClick={handleRealtime}>
				<Tooltip title={t('GO_REALTIME')}>
					<ShareLocationOutlined />
				</Tooltip>
			</ActionBarBtn>
			{isToDo
				? permissions.hasEdit && (
						<Button
							size="small"
							color="secondary"
							variant="contained"
							sx={{
								borderRadius: '8px'
							}}
							onClick={handleGoOn}
						>
							{t('GO_ON')}
						</Button>
				  )
				: permissions.hasView && <DetailButton data={data} />}
		</Stack>
	);
}
