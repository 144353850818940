import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Drawer, Pagination } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import {
	useDeleteNotificationMutation,
	useGetNotificationsQuery,
	useMarkAllAsReadMutation,
	useMarkAsReadMutation
} from 'app/store/api/notificationsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch } from 'app/store/index';
import { AnimatePresence, motion } from 'framer-motion';
import i18next from 'i18next';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NotificationCard from './NotificationCard';
import NotificationEmpty from './NotificationEmpty';
import NotificationPannelHeader from './NotificationPannelHeader/NotificationPannelHeader';
import NotificationPannelSkeleton from './NotificationPannelSkeleton';
import { closeNotificationPanel, selectNotificationPanelState } from './store/stateSlice';

const NotificationDrawer = styled(Drawer)(({ theme }) => ({
	flexShrink: 0,
	'& .MuiDrawer-paper': {
		backgroundColor: theme.palette.background.default,
		width: 455,
		boxSizing: 'border-box',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
}));

const locale = {
	en: {
		OF: 'of',
		NOTIFICATIONS: 'Notifications',
		MARK_ALL_AS_READ: 'Mark all as read',
		DELETE_NOTIFICATION_ERROR: 'Error deleting notification',
		MARK_AS_READ_ERROR: 'Error marking notification as read',
		MARK_ALL_AS_READ_ERROR: 'Error marking all notifications as read',
		NO_NOTIFICATIONS: 'No notifications'
	},
	pt: {
		NOTIFICATIONS: 'Notificações',
		OF: 'de',
		MARK_ALL_AS_READ: 'Marcar todas como lidas',
		DELETE_NOTIFICATION_ERROR: 'Erro ao excluir notificação',
		MARK_AS_READ_ERROR: 'Erro ao marcar notificação como lida',
		MARK_ALL_AS_READ_ERROR: 'Erro ao marcar todas as notificações como lidas',
		NO_NOTIFICATIONS: 'Não há notificações'
	},
	sp: {
		NOTIFICATIONS: 'Notificaciones',
		OF: 'de',
		MARK_ALL_AS_READ: 'Marcar todas como leídas',
		DELETE_NOTIFICATION_ERROR: 'Error al eliminar la notificación',
		MARK_AS_READ_ERROR: 'Error al marcar la notificación como leída',
		MARK_ALL_AS_READ_ERROR: 'Error al marcar todas las notificaciones como leídas',
		NO_NOTIFICATIONS: 'No hay notificaciones'
	}
};

i18next.addResourceBundle('en', 'translation', locale.en, true, true);
i18next.addResourceBundle('pt', 'translation', locale.pt, true, true);
i18next.addResourceBundle('sp', 'translation', locale.sp, true, true);

export const NOTIFICATION_PERMISSIONS = {
	list: 'admin.notification.list',
	edit: '',
	view: '',
	new: '',
	delete: '',
	minimal: ['']
};

export default function NotificationPanel() {
	const dispatch = useAppDispatch();
	const state = useSelector(selectNotificationPanelState);
	const { t } = useTranslation('translation');
	const [markAsRead] = useMarkAsReadMutation();
	const [markAllAsRead, { isLoading: isMarkAllAsReadLoading }] = useMarkAllAsReadMutation();
	const [deleteNotification] = useDeleteNotificationMutation();
	const [page, setPage] = useState(1);

	const { notifications, totalDocs, totalPages, isLoading } = useGetNotificationsQuery(
		{ page },
		{
			selectFromResult: ({ data, ...res }) => ({
				notifications: data?.docs ?? [],
				totalDocs: data?.totalDocs ?? 0,
				totalPages: data?.totalPages ?? 0,
				...res
			})
		}
	);

	const handleDismiss = async (id: string) => {
		try {
			await deleteNotification(id).unwrap();
		} catch {
			dispatch(
				showMessage({
					message: t('DELETE_NOTIFICATION_ERROR'),
					variant: 'error'
				})
			);
		}
	};

	const handleView = async (id: string) => {
		try {
			await markAsRead(id).unwrap();
		} catch {
			dispatch(
				showMessage({
					message: t('MARK_AS_READ_ERROR'),
					variant: 'error'
				})
			);
		}
	};

	const handleMarkAllAsRead = async () => {
		try {
			await markAllAsRead().unwrap();
		} catch {
			dispatch(
				showMessage({
					message: t('MARK_ALL_AS_READ_ERROR'),
					variant: 'error'
				})
			);
		}
	};

	const handleClosePanel = () => {
		dispatch(closeNotificationPanel());
	};

	const handleChangePage = (_, page: number) => setPage(page);
	const hasNotifications = !_.isEmpty(notifications);

	const notificationCards = _.map(notifications, (notification) => (
		<motion.div key={notification._id} layout>
			<NotificationCard
				notification={notification}
				onDismiss={() => handleDismiss(notification?._id)}
				onView={() => handleView(notification?._id)}
			/>
		</motion.div>
	));

	const notificationList = hasNotifications ? (
		<AnimatePresence>
			<FuseScrollbars className="p-16">
				<div className="flex flex-col">{notificationCards}</div>
				<Pagination
					className="pb-32"
					siblingCount={0}
					boundaryCount={1}
					page={page}
					disabled={isLoading}
					count={totalPages}
					color="secondary"
					onChange={handleChangePage}
				/>
			</FuseScrollbars>
		</AnimatePresence>
	) : (
		<NotificationEmpty t={t} />
	);

	const notificationPannel = (
		<>
			<NotificationPannelHeader
				isLoading={isMarkAllAsReadLoading}
				onMarkAllAsRead={handleMarkAllAsRead}
				t={t}
				title={t('NOTIFICATIONS')}
				totalDocs={totalDocs}
				closeButton={
					<IconButton className="m-4 absolute top-0 right-0 z-999" onClick={handleClosePanel} size="large">
						<FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
					</IconButton>
				}
			/>
			{notificationList}
		</>
	);

	const loading = _.some([isLoading]);

	return (
		<NotificationDrawer open={state} anchor="right" onClose={handleClosePanel}>
			{loading ? <NotificationPannelSkeleton /> : notificationPannel}
		</NotificationDrawer>
	);
}
