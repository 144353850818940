import usePermission from 'app/services/hooks/usePermission';
import { memo } from 'react';
import NotificationPanel, {
	NOTIFICATION_PERMISSIONS
} from '../../shared-components/notificationPanel/NotificationPanel';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';

function RightSideLayout1(props: any) {
	const permissions = usePermission({ pagePermissions: NOTIFICATION_PERMISSIONS });

	return (
		<>
			<QuickPanel />
			{permissions.hasList && <NotificationPanel />}
		</>
	);
}

export default memo(RightSideLayout1);
