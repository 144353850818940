import { Flag } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { ALARM_COLOR } from 'app/main/monitoring/helpers';
import { TMonitoringAlert } from 'app/main/monitoring/types';

const SvgSeverityDecorator = ({ severity = 0 }: { severity: TMonitoringAlert['severity'] | 0 }) => (
	<Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		<Flag sx={{ color: !severity ? '#dedede' : ALARM_COLOR[severity], fSize: 24 }} />
	</Box>
);

type TDecoratedNameWithSeverityProps = {
	severity: TMonitoringAlert['severity'] | 0;
	name: string;
};

export default function DecoratedNameWithSeverity({ severity, name }: TDecoratedNameWithSeverityProps) {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<Flag component={SvgSeverityDecorator} severity={severity} />
			<Typography variant="body2" color="textSecondary" textOverflow="ellipsis">
				{name}
			</Typography>
		</Stack>
	);
}
