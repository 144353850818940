import { THIRTY_ONE_DAY_ERROR_MESSAGE_SP } from 'src/constants';

const locale = {
	// Header
	DOWNLOAD_ERROR: 'Error al descargar el archivo, por favor inténtelo de nuevo.',
	DOWNLOAD_ALL: 'Descargar Todos',
	DOWNLOAD_SELECTED: 'Descargar Seleccionados',
	SHOW_FILTERS: 'Mostrar filtros',
	RULER_COMMAND_NAME: 'Nombre del Comando de Regla',
	CONFIG_TABLE: 'Configurar tabla',
	THIRTY_DAY_ERROR: THIRTY_ONE_DAY_ERROR_MESSAGE_SP,

	// Columnas

	RULE_NAME: 'Nombre de regla',
	SCHEDULED_COMMAND_NAME: 'Nombre del Comando Programado',
	NEXT_EXECUTION: 'Próxima Ejecución',
	TARGET_COMPANIES: 'Empresas Destino',
	TARGET_FLEETS: 'Flotas Destino',
	TARGET_VEHICLES: 'Vehículos Destino',
	TARGET_TRACKERS: 'Rastreadores Destino',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	UPDATED_AT: 'Actualizado en',
	STATUS: 'Estado',
	SCHEDULED_AT: 'Programado en',
	EXECUTED_AT: 'Ejecutado en',

	// Tabla

	ROWS_PER_PAGE: 'Elementos por página',
	OF: 'de',

	// Permisos

	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',

	// Encabezado

	SCHEDULE: 'Programar',
	TITLE: 'Comandos Programados',

	// Estado

	PENDING: 'Pendiente',
	QUEUED: 'En Cola',
	'RE-QUEUED': 'Reencolado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Error',
	'EXPIRED:': 'Expirado',

	// Común

	SCHEDULED_FOR: 'Programado para',
	NOT_SCHEDULED: 'Inmediatamente',
	SCHEDULED_COMMAND: 'Comando Programado',
	EDIT: 'Editar',
	DELETE: 'Eliminar',
	SUMMARY: 'Resumen',
	COMPANY: 'Empresa',
	FLEET: 'Flota',
	VEHICLE: 'Vehículo',
	TRACKER: 'Rastreador',
	SEND_TO: 'Enviar a',
	CLICK_HERE_TO_EXPAND: 'Haz clic aquí para expandir',
	SUMMARY_ERROR: 'Error al cargar el resumen',

	//Filtros
	SELECT_FILTERS: 'Seleccionar filtros',
	FILTROS: 'Filtros',
	CREATED_BY_ME: 'Programado por mí',
	CLEAR_FILTERS: 'Borrar filtros',
	FROM_STATUS: 'Del Estado',
	TO_STATUS: 'Al estado',
	START_DATE: 'Fecha de inicio',
	END_DATE: 'Fecha de finalización',
	RULES: 'Reglas',
	RULES_SELECTED: '{{n}} reglas selecionadas',
	SEARCH: 'Buscar',

	SCHEDULED_HISTORY: 'Historial de comandos programados',
	RULER_HISTORY: 'Historial de comandos de reglas',
	SCHEDULED: 'Programado',
	IMMEDIATE: 'Inmediato',
	TYPE: 'Tipo'
};

export default locale;
