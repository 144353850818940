import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface iCRUDProps {
	bannerUrl?: string;
	avatarUrl?: string;
	name?: string;
	email?: string;
	phone?: string;
	_id?: string;
	code?: string;
	icon?: string;
	description?: string;
	createdAt?: string;
	createdBy?: {
		_id?: string;
		name?: string;
	};
	iccid?: string;
	url?: string;
	active?: boolean;
	company?: string[];
	permissions?: string[];
	updatedBy?: string;
}

const initialState = {
	row: {},
	mode: 'view',
	error: null
};

export const sideBarSlice = createSlice({
	name: 'sideBar',
	initialState,
	reducers: {
		setRow: (state, action: PayloadAction<any>) => {
			state.row = action.payload;
		},
		setMode: (state, action: PayloadAction<'edit' | 'create' | 'view' | 'edit_multiple' | 'actions_page'>) => {
			state.mode = action.payload;
		},
		setError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		}
	}
});

export const { setRow, setMode, setError } = sideBarSlice.actions;

export const selectRow = (state: any) => state.sideBar.row;
export const selectSidebarMode = (state: any) => state.sideBar.mode;
export const selectError = (state: any) => state.sideBar.error;

export default sideBarSlice.reducer;
