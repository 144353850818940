import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const MonitoringDone = lazyRetry(() => import('./MonitoringDone'));

i18next.addResourceBundle('en', 'monitoringDonePage', en);
i18next.addResourceBundle('sp', 'monitoringDonePage', sp);
i18next.addResourceBundle('pt', 'monitoringDonePage', pt);

export const MONITORING_PERMISSIONS = {
	edit: 'monitoring.alerts.edit',
	view: 'monitoring.alerts.view',
	list: 'monitoring.alerts.list',
	new: 'monitoring.alerts.new',
	delete: 'monitoring.alerts.delete',
	minimal: ['reports.trips.view']
};

const PermissionsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['monitoring.alerts.list'],
			path: 'monitoring/done',
			element: (
				<LazyLoaderSuspense>
					<MonitoringDone />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<MonitoringDone />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default PermissionsConfig;
