import axios from 'axios';
import storageService from '../storage';

export const api = axios.create({
	baseURL: import.meta.env.VITE_API_URL
});
api.interceptors.request.use(
	(config) => {
		const token = storageService.get('token');

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(new Error(error))
);
