import {
	DragIndicatorOutlined,
	NotificationsActiveOutlined,
	PersonOutline,
	ShareLocationOutlined,
	WarningAmber
} from '@mui/icons-material';
import { Box, Button, Stack, SvgIcon, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { DateIcon } from 'app/main/map/components/tracker-card-layouts/ControlledIcons';
import usePermission from 'app/services/hooks/usePermission';
import ActionBarBtn from 'app/shared-components/Buttons/MiniButton';
import { ALARM_COLOR } from '../../helpers';
import { MONITORING_PERMISSIONS } from '../../MonitoringConfig';
import { TMonitoringAlert } from '../../types';
import CardGrid from '../shared/CardGrid';
import CloseButton from '../shared/CloseButton';

type TSingleItemPresentational = { data: TMonitoringAlert };

export default function SingleItemPresentational({ data }: TSingleItemPresentational) {
	const theme = useTheme();
	const { t } = useTranslation('monitoringPage');

	const isDarkMode = theme.palette.mode === 'dark';
	const mainTextColor = isDarkMode ? '#FFF' : '#000';
	const normalTextColor = isDarkMode ? '#9FA5A1' : '#79747E';
	const permissions = usePermission({ pagePermissions: MONITORING_PERMISSIONS });
	return (
		<div className="pb-10 m-0">
			<Box
				sx={{
					paddingX: '16px',
					paddingY: '14px',
					width: ['100%', '520px'],
					display: 'flex',
					justifyContent: 'space-between',
					background: isDarkMode ? '#323C4D' : '#fff',
					borderRadius: '12px',
					border: '1px solid #E0E0E0',
					margin: 0,
					cursor: data ? 'grabbing' : 'grab'
				}}
			>
				<CardGrid
					firstCol={
						<Box
							sx={{
								background: _.get(ALARM_COLOR, data.severity, ALARM_COLOR[2]),
								borderRadius: 1,
								padding: '4px',
								flex: 1
							}}
						>
							<NotificationsActiveOutlined htmlColor="#FFF" />
						</Box>
					}
					secondCol={
						<>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									color: mainTextColor,
									width: '180px'
								}}
								noWrap
							>
								{_.get(data, 'trackerMessage.vehicle.name', t('NOT_FOUND'))}
							</Typography>
							<Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
								<SvgIcon htmlColor={normalTextColor} fontSize="inherit" viewBox="0 0 14 14">
									<path d="M7.526 4.134a2.1 2.1 0 1 0-.875.037v2.296L3.312 9.805a1.01 1.01 0 0 0-.112-.006H1a1 1 0 0 0-1 1v2.2a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-2.2a.996.996 0 0 0-.138-.507l3.065-3.065 3.116 2.74a.999.999 0 0 0-.444.832v2.2a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-2.2a1 1 0 0 0-1-1h-1.622L7.526 6.413v-2.28Z" />
								</SvgIcon>
								<Typography
									sx={{
										fontWeight: 600,
										fontSize: '12px',
										color: normalTextColor
									}}
								>
									{_.get(data, 'trackerMessage.tracker.did', t('NOT_FOUND'))}
								</Typography>
							</Stack>
							{data.status === 'toDo' ? undefined : (
								<Stack direction="row" alignItems="center" justifyContent="start" spacing={1}>
									<PersonOutline htmlColor={normalTextColor} fontSize="small" />
									<Stack direction="column">
										<Typography
											sx={{
												fontSize: '8px',
												color: normalTextColor
											}}
										>
											{t('OPERATED_BY')}
										</Typography>
										<Typography
											sx={{
												fontWeight: 600,
												fontSize: '12px',
												color: mainTextColor
											}}
										>
											{_.get(data, 'user.name', '')}
										</Typography>
									</Stack>
								</Stack>
							)}
						</>
					}
				/>
				<CardGrid
					isReverse
					firstCol={
						<>
							<ActionBarBtn size="medium" handleClick={undefined}>
								<ShareLocationOutlined />
							</ActionBarBtn>
							{permissions.hasEdit && (
								<CloseButton isDarkMode={isDarkMode} handleClick={undefined} t={t} />
							)}
						</>
					}
					secondCol={
						<>
							<Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
								<Typography
									sx={{
										fontWeight: 500,
										fontSize: '12px',
										color: normalTextColor
									}}
								>
									{typeof data.createdAt === 'string'
										? dayjs(data.createdAt).format('DD/MM/YYYY - HH:mm:ss')
										: ''}
								</Typography>
								<DateIcon isDarkMode={isDarkMode} />
							</Stack>
							<Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
								<Typography
									sx={{
										fontWeight: 600,
										fontSize: '14px',
										color: mainTextColor
									}}
								>
									{t(_.get(data, 'trackerMessage.EVENT_TYPE', ''))}
								</Typography>
								<WarningAmber fontSize="small" />
							</Stack>
							{data.status === 'toDo' && permissions.hasEdit && (
								<Button
									size="small"
									color="secondary"
									variant="contained"
									sx={{
										borderRadius: '8px'
									}}
									onClick={undefined}
								>
									{t('GO_ON')}
								</Button>
							) }
						</>
					}
				/>
				<Box>
					<DragIndicatorOutlined />
				</Box>
			</Box>
		</div>
	);
}
