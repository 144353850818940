import { ArrowBackOutlined, TimelineOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PrimaryOptionButton from 'app/shared-components/CRUDs/Sidebar/PrimaryOptionButton';
import ViewItem from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import { setSideBarOpen } from 'app/store/reports/historySlice';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAddress, goToStreetView } from '../../helper';
import { THistoryPosition } from '../../types';
import TrackerInfo from './TrackerInfo';
import ViewCoordinates from './ViewCoordinates';

interface SidebarProps {
	history: THistoryPosition;
}

export default function Sidebar({ history }: SidebarProps) {
	const { t } = useTranslation('historyPage');

	const dispatch = useDispatch();
	const { search } = useLocation();
	const navigate = useNavigate();

	const handleClose = () => {
		dispatch(setSideBarOpen(false));
		navigate(`/reports/analytic${search}`, { replace: true });
	};

	const handleStreetView = () => {
		const { LATITUDE, LONGITUDE } = history;
		goToStreetView(LATITUDE, LONGITUDE);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				overflowY: 'auto',
				flex: 1
			}}
		>
			<Box
				sx={{
					height: '54px',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: 1,
					marginBottom: 3,
					background: (theme) => theme.palette.secondary.main
				}}
			>
				<IconButton color="primary" onClick={handleClose} size="large">
					<ArrowBackOutlined />
				</IconButton>
			</Box>
			<Stack direction="column" spacing={3} paddingX={3} paddingBottom={3}>
				<Stack direction="row" alignItems="center" spacing={2}>
					<TimelineOutlined color="secondary" />
					<Typography variant="h6" className="font-bold">
						{t('DETAILS')}
					</Typography>
				</Stack>

				<PrimaryOptionButton
					onClick={handleStreetView}
					text="Google Maps"
					icon="streetview"
					iconColor="primary"
				/>

				<ViewItem
					icon="device_hub"
					label={t('TRACKER')}
					value={`${history?.tracker?.name} (${history?.DID})`}
				/>

				<ViewItem icon="directions_car" label={t('VEHICLE')} value={history?.vehicle?.plate} />

				<ViewItem icon="people" label={t('DRIVER')} value={_.get(history, 'SERIAL_CODE', '')} />

				<ViewItem
					icon="location_on"
					label={t('ADDRESS')}
					value={getAddress({
						ADDRESS_ROAD: history?.ADDRESS_ROAD,
						ADDRESS_SUBURB: history?.ADDRESS_SUBURB,
						ADDRESS_STATE: history?.ADDRESS_STATE,
						ADDRESS_COUNTRY: history?.ADDRESS_COUNTRY,
						ADDRESS_POSTCODE: history?.ADDRESS_POSTCODE
					})}
				/>
				<ViewItem icon="speed" label={t('SPEED')} value={`${history?.SPEED} km/h`} />

				<ViewCoordinates alt={history?.ALTITUDE || 0} lat={history?.LATITUDE || 0} lng={history?.LONGITUDE || 0} t={t} />

				<TrackerInfo
					t={t}
					rxLev={history?.RXLEV}
					satellites={history?.SATELLITES}
					gpsFixed={history?.GPS_FIXED}
					ignitionOn={history?.IGNITION_ON}
					battery={history?.VEHICLE_VOLTAGE}
					mileage={history?.MILEAGE}
				/>
			</Stack>
		</Box>
	);
}
