import { SnackbarVariant } from '@fuse/core/FuseMessage/FuseMessage';
import storageService from 'app/services/storage';
import { showMessage } from 'app/store/fuse/messageSlice';
import { UserProps, initialState } from 'app/store/user/userSlice';
import { IToken } from 'app/types/token.types';
import { IUserMeResponse } from 'app/types/user.type';
import { TSetFaviconAndTitle } from 'app/types/utils.types';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';

interface IState {
	isLoading: boolean;
	totalItens: number | undefined;
	totalPages: number | undefined;
	currentPage: number;
	done: boolean;
}

export function useOnScreen(ref: MutableRefObject<HTMLDivElement>, state: IState, getData?: any) {
	const [isIntersecting, setIntersecting] = useState(false);

	const observer = useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [ref]);

	useEffect(() => {
		observer.observe(ref.current);
		return () => observer.disconnect();
	}, []);

	if (state.done) {
		return false;
	}

	if (isIntersecting && !state.isLoading) {
		getData();
	}
	return isIntersecting;
}

export function showPopUpMsg(message: string, type: SnackbarVariant) {
	return showMessage({
		message,
		autoHideDuration: 3000,
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'center'
		},
		variant: type
	});
}

export function setFaviconAndTitle({ fav, title }: TSetFaviconAndTitle): void {
	const favicon = document.getElementById('favicon');
	favicon.setAttribute('href', fav);
	document.title = title;
}

export const isTokenValid = (): boolean => {
	const token = storageService.get('token');
	if (!token) return false;
	const decodedToken = jwtDecode<IToken>(token);
	return decodedToken.exp >= Date.now() / 1000;
};

export const parseUserData = (user: IUserMeResponse): UserProps => ({
	permissions: _.get(user, 'profile.permissions', []),
	company: _.get(user, 'company'),
	defaultFilter: _.get(user, 'realtimeDefaultFilterId._id'),
	data: {
		userId: _.get(user, '_id'),
		displayName: _.get(user, 'name'),
		email: _.get(user, 'email'),
		photoURL: _.get(user, 'avatarUrl')
	},
	isLoading: false,
	preferences: _.get(user, 'preferences', initialState.preferences)
});
