import {
	DirectionsCarFilledOutlined,
	GpsFixedOutlined,
	InfoOutlined,
	LockOutlined,
	RouteOutlined,
	ShareLocationOutlined,
	Wifi
} from '@mui/icons-material';
import { CircularProgress, Stack, Theme, useMediaQuery } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import { useGetLastTripMutation } from 'app/store/api/tripsSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { setSelectedTrackerId, setTrackerToWatch, setTrackersOpen } from 'app/store/map/mapSlice';
import { openMapToolbar } from 'app/store/map/mapToolbarSlice';
import { getTrackerDisplayName } from '../mapHelpers';
import { TTracker } from '../types';
import ActionItem from './CardActionItem';
import HistoryDateRange from './HistoryDateRange';

import TrackerSettings from './tracker-settings/TrackerSettings';

interface ICardsActionModal {
	tracker: TTracker;
}

const tripsRoute = '/reports/trips/advanced';
const trackerRoute = '/fleet/trackers';
const vehicleRoute = '/fleet/vehicle';

export default function CardActionModal({ tracker }: ICardsActionModal) {
	const { t } = useTranslation('mapPage');
	const dispatch = useDispatch();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [getLastTrip, { isLoading: isGettingLastTrip }] = useGetLastTripMutation();

	const displayName = getTrackerDisplayName(tracker);
	const trackerId = _.get(tracker, '_id', false);
	const trackerDid = _.get(tracker, 'did');
	const vehicleId = _.get(tracker, 'vehicle._id', false);
	const hasHst = Boolean(trackerId) && Boolean(vehicleId);
	const canBeBlocked = _.get(tracker, 'canBeBlocked', false);

	const handleViewTracker = () => window.open(`${trackerRoute}/${trackerId}`, '_blank');
	const handleViewVehicle = () => window.open(`${vehicleRoute}/${vehicleId}`, '_blank');

	const handleCloseModal = () => dispatch(closeDialog());
	const cannotGetTrip = () => dispatch(showMessage({ message: t('CANNOT_GET_TRIP'), variant: 'error' }));

	const handleHistory = async () => {
		if (!hasHst) return;
		handleCloseModal();
		if (typeof trackerId === 'string' && typeof vehicleId === 'string') {
			dispatch(
				openDialog({
					children: <HistoryDateRange trackerId={trackerId} vehicleId={vehicleId} name={tracker.name} />
				})
			);
		}
	};
	const handleViewTrackerSettings = () => {
		dispatch(
			openDialog({
				children: <TrackerSettings trackerDID={trackerDid} />
			})
		);
	};
	const handleAdvancedHistory = async () => {
		if (typeof trackerId === 'string' && typeof vehicleId === 'string') {
			getLastTrip({ did: trackerDid, byVehicle: vehicleId })
				.unwrap()
				.then(({ docs: data }) => {
					if (data.length > 0) {
						window.open(`${tripsRoute}/${data[0]._id}`, '_blank');
					} else cannotGetTrip();
				})
				.catch(cannotGetTrip)
				.finally(handleCloseModal);
		}
	};

	const clearWatch = () => {
		dispatch(setTrackerToWatch(null));
	};

	const handleWatch = () => {
		handleCloseModal();
		dispatch(setTrackerToWatch(tracker._id));
		dispatch(setSelectedTrackerId(tracker._id));
		dispatch(
			openMapToolbar({
				onClose: clearWatch
			})
		);
		if (isMobile) {
			dispatch(setTrackersOpen(false));
		}
	};

	const handleBlockVehicle = () => {
		handleWatch();
	};

	return (
		<DialogWrapper
			title={displayName}
			onClose={handleCloseModal}
			content={
				<Stack direction="column" spacing={3} width="100%">
					{trackerId ? (
						<ActionItem text={t('VIEW_TRACKER')} icon={Wifi} onClick={handleViewTracker} />
					) : undefined}

					{vehicleId ? (
						<ActionItem
							text={t('VIEW_VEHICLE')}
							icon={DirectionsCarFilledOutlined}
							onClick={handleViewVehicle}
						/>
					) : undefined}

					{trackerDid && canBeBlocked && (
						<ActionItem text={t('BLOCK_TRACKER')} icon={LockOutlined} onClick={handleBlockVehicle} />
					)}

					{hasHst ? (
						<>
							<ActionItem text={t('FAST_HISTORY')} icon={RouteOutlined} onClick={handleHistory} />

							<ActionItem
								text={t('ADVANCED_HISTORY')}
								icon={ShareLocationOutlined}
								onClick={handleAdvancedHistory}
							>
								{isGettingLastTrip ? <CircularProgress color="secondary" size={20} /> : undefined}
							</ActionItem>
						</>
					) : undefined}

					{vehicleId ? (
						<ActionItem text={t('FOLLOW_VEHICLE')} icon={GpsFixedOutlined} onClick={handleWatch} />
					) : undefined}

					<ActionItem text={t('TRACKER_SETTINGS')} icon={InfoOutlined} onClick={handleViewTrackerSettings} />
				</Stack>
			}
		/>
	);
}
