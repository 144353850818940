const sp = {
	NAME: 'Nombre',
	DESCRIPTION: 'Descripción',
	SELECTED_TAGS: '{{limit}} etiquetas seleccionadas',
	TAGS: 'Etiquetas',
	SELECTED_COMPANIES: '{{limit}} empresas seleccionadas',
	COMPANIES: 'Empresas',
	NAME_REQUIRED: 'Nombre es obligatorio',
	DESCRIPTION_REQUIRED: 'Descripción es obligatoria',
	TAGS_REQUIRED: 'Las etiquetas son obligatorias',
	COMPANIES_REQUIRED: 'Las empresas son obligatorias',
	EDIT: 'Editar',
	DELETE: 'Eliminar',
	CREATE: 'Crear',
	DETAILS: 'Detalles',
	GEO_FENCES: 'Cercas Virtuales',
	CLEAR: 'Limpiar',
	SAVE: 'Guardar',
	VIEW: 'Ver',
	SEARCH_ADDRESS: 'Buscar dirección',
	DRAW_POLYGON: 'Dibujar polígono',
	DELETE_POINT: 'Eliminar punto o cerca seleccionada',
	SAVE_GEO_FENCE: 'Guardar cerca virtual',
	SUCCESS_SAVING_GEO_FENCE: 'Cerca virtual guardada con éxito',
	ERROR_SAVING_GEO_FENCE: 'Error al guardar cerca virtual',
	ADD: 'Agregar',
	LOADING: 'Cargando...',
	CREATED_BY: 'Creado por',
	UPDATED_BY: 'Actualizado por',
	CREATED_AT: 'Creado en',
	UPDATED_AT: 'Actualizado en',
	NO_OPTIONS: 'No se encontraron opciones',
	GEOFENCE_DELETED_SUCCESSFULLY: 'Cerca virtual eliminada con éxito',
	ERROR_DELETING_GEOFENCE: 'Error al eliminar cerca virtual',
	DELETE_GEOFENCE: 'Eliminar cerca virtual',
	DELETE_GEOFENCE_PRIMARY: '¿Estás seguro de que quieres eliminar esta cerca virtual?',
	DELETE_GEOFENCE_SECONDARY: 'Esta acción no se puede deshacer.',
	DRAW_POLYGON_FIRST: 'Dibuje el polígono primero',
	SELECT_RANGE: 'Seleccionar un rango',
	SELECT_VEHICLE: 'Seleccionar un vehículo',
	BUILD_GEOFENCE: 'Crear cerca virtual',
	VEHICLE: 'Vehículo',
	START_DATE: 'Inicio',
	END_DATE: 'Fin',
	SELECT_RADIUS: 'Seleccionar el radio en metros desde el punto central',
	CREATE_GEOFENCE_DESCRIPTION:
		'Puede crear una cerca virtual basada en el historial de posiciones de un vehículo a partir de un rango de fechas y un radio en metros.',
	ERROR_CREATING_GEOFENCE: 'Error al crear cerca virtual',
	NO_POSITIONS_FOUND: 'No hay suficientes posiciones para crear una cerca virtual.',
	ERROR_EMPTY_GEOJSON: 'Error al guardar cerca virtual. El polígono no puede estar vacío.',
	CREATE_TRIP_GEO_FENCE: 'Crear cerca virtual basada en viaje'
};
export default sp;
