import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import { CourseType } from '../types/types';
interface CourseInfoProps {
  course: CourseType;
  className: string;
}
function CourseProgress({ course, className }) {
  return (
    // TODO IMPLEMENT COURSE PROGRESS
    <LinearProgress
      className={clsx('w-full h-2', className)}
      variant="determinate"
      // value={(course.progress.currentStep * 100) / course.totalSteps}
      color="secondary"
    />
  );
}

export default CourseProgress;
