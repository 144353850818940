import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'ruler'>, MatchConditions>;

const LIST_RULER_PERMISSIONS = ['admin.ruler.list'];

const VIEW_RULER_PERMISSIONS = ['admin.ruler.view', 'admin.tracker-command.view'];

const CREATE_RULER_PERMISSIONS = [
	'admin.ruler.new',
	'admin.company.list',
	'admin.contact.list',
	'fleet.fleet.list',
	'fleet.vehicle.list',
	'admin.tracker-command.view',
	'admin.tracker-command.list',
	'geofence.list'
];

const UPDATE_RULER_PERMISSIONS = [
	'admin.ruler.edit',
	'admin.company.list',
	'admin.contact.list',
	'fleet.fleet.list',
	'fleet.vehicle.list',
	'admin.tracker-command.view',
	'admin.tracker-command.list',
	'geofence.list'
];

const DELETE_RULER_PERMISSIONS = ['admin.ruler.delete'];

export const defineRulerNotificationAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_RULER_PERMISSIONS),
		create: getMissingPermissions(CREATE_RULER_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_RULER_PERMISSIONS),
		delete: getMissingPermissions(DELETE_RULER_PERMISSIONS),
		list: getMissingPermissions(LIST_RULER_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('ruler', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_RULER_PERMISSIONS)) can('delete', 'ruler');
	if (userHasPermission(CREATE_RULER_PERMISSIONS)) can('create', 'ruler');
	if (userHasPermission(UPDATE_RULER_PERMISSIONS)) can('edit', 'ruler');
	if (userHasPermission(VIEW_RULER_PERMISSIONS)) can('view', 'ruler');
	if (userHasPermission(LIST_RULER_PERMISSIONS)) can('list', 'ruler');

	return [build(), missingPermissions] as const;
};
