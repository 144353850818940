import FusePageSimple from '@fuse/core/FusePageSimple';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import '@vidstack/react/player/styles/default/layouts/video.css';
import '@vidstack/react/player/styles/default/theme.css';

import { useGetLessonQuery } from 'app/store/api/lessonsSlice';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { Link, useParams } from 'react-router-dom';
import { TLesson } from '../../../types/types';

export default function Lesson() {
	const routeParams = useParams();
	const { lessonId } = routeParams;
	const lesson: TLesson = useGetLessonQuery(lessonId).data;

	const theme = useTheme();

	const { t } = useTranslation('academyPage');
	const pageLayout = useRef(null);

	if (!lesson) {
		return null;
	}
	return (
		<FusePageSimple
			content={
				<div className="w-full">
					<Button
						to="/academy/lessons"
						component={Link}
						className="mb-24"
						color="secondary"
						variant="text"
						startIcon={
							<FuseSvgIcon size={20}>
								{theme.direction === 'ltr'
									? 'heroicons-outline:arrow-sm-left'
									: 'heroicons-outline:arrow-sm-right'}
							</FuseSvgIcon>
						}
					>
						{t('BACK_TO_LESSONS')}
					</Button>

					<div className="flex justify-center p-16 pb-64 sm:p-24 sm:pb-64 md:p-48 md:pb-64">
						<Paper className="w-full max-w-lg mx-auto lg:mt-16 p-24 sm:p-40 rounded-16 shadow overflow-hidden">
							<ReactPlayer url={lesson.url} controls width={'auto'} height={'auto'} />
							<div className="flex flex-col  mx-auto w-full mt-10">
								<Typography color="inherit" className="text-18 font-semibold mt-">
									{lesson.title}
								</Typography>

								<Typography color="textSecondary" className="text-14 mt-8">
									{t('DESCRIPTION')}:
									<br />
									{lesson.description}
								</Typography>
							</div>

							<div className="prose prose-sm dark:prose-invert w-full max-w-full" dir={theme.direction} />
						</Paper>
					</div>
					<Hidden lgUp>
						<Paper
							className="flex sticky top-0 z-10 items-center w-full px-16 py-8 border-b-1 shadow-0"
							square
						>
							<IconButton to="/apps/academy/lessons" component={Link} className="">
								<FuseSvgIcon>
									{theme.direction === 'ltr'
										? 'heroicons-outline:arrow-sm-left'
										: 'heroicons-outline:arrow-sm-right'}
								</FuseSvgIcon>
							</IconButton>

							<Typography className="text-13 font-medium tracking-tight mx-10">{lesson.title}</Typography>
						</Paper>
					</Hidden>

					<Hidden lgUp>
						<Box
							sx={{ backgroundColor: 'background.paper' }}
							className="flex sticky bottom-0 z-10 items-center w-full p-16 border-t-1"
						>
							<IconButton aria-label="open left sidebar" size="large">
								<FuseSvgIcon>heroicons-outline:view-list</FuseSvgIcon>
							</IconButton>

							<IconButton>
								<FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>
							</IconButton>

							<IconButton>
								<FuseSvgIcon>heroicons-outline:arrow-narrow-right</FuseSvgIcon>
							</IconButton>
						</Box>
					</Hidden>
				</div>
			}
			scroll="content"
			ref={pageLayout}
		/>
	);
}
