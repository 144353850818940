const locale = {
	FAILED_TO_LOGIN: 'Falha ao fazer login, e-mail ou senha inválidos',
	NOT_UNDER_ALLOWED_DOMAIN: 'Falha ao fazer login, domínio não permitido',
	LOGIN_SUCESS: 'Login bem sucedido',
	FORGOT_PASSWORD_1: 'Esqueceu a senha?',
	FORGOT_PASSWORD_2: 'Clique aqui',
	SIGN_IN: 'Entrar',
	ERROR_EMAIL_INVALID: 'Você deve digitar um e-mail válido',
	SIGN_IN_WITH_EMAIL_AND_PASSWORD: 'Iniciar sesión con nombre y contraseña',
	SIGN_IN_WITH_EMAIL_AND_NAME: 'Sign in with name and password',
	ERROR_EMAIL_REQUIRED: 'Você deve digitar um e-mail',
	ERROR_PASSWORD_REQUIRED: 'Você deve digitar uma senha',
	ERROR_PASSWORD_MIN: 'A senha deve ter pelo menos 6 caracteres',
	ERROR_EMAIL_OR_USERNAME_REQUIRED: 'O campo "Email ou Nome de Usuário" é obrigatório',
	EMAIL_OR_USERNAME: 'Email ou Nome de Usuário',
	PASSWORD: 'Contraseña'
};

export default locale;
