import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TAllSchemas } from 'app/main/maintenance-manager/maintenance/components/Form/schemas';
import { RootState } from '..';

import type { TMaintenance, TMaintenanceSelectedTab } from 'app/main/maintenance-manager/types';

export type TModalMode = 'edit' | 'new' | 'view' | 'execute';

export type TInitialState = {
	selectedRow: TMaintenance;
	modalMode: TModalMode;
	selectedTab: TMaintenanceSelectedTab;
	form: {
		data: TAllSchemas;
	};
};

const initialState: TInitialState = {
	selectedRow: null,
	modalMode: 'new',
	selectedTab: 'TO-DO',
	form: {
		data: null
	}
};

const maintenanceCrudSlice = createSlice({
	name: 'maintenanceCrud',
	initialState,
	reducers: {
		setSelectedRow: (state, action: PayloadAction<TMaintenance>) => {
			state.selectedRow = action.payload;
		},
		setFormData: (state, action) => {
			state.form.data = action.payload.data;
		},
		setModalMode: (state, action: PayloadAction<TModalMode>) => {
			state.modalMode = action.payload;
		},

		clearForm: (state) => {
			state.form.data = initialState.form.data;
		},
		setSelectedTab: (state, action: PayloadAction<TMaintenanceSelectedTab>) => {
			state.selectedTab = action.payload;
		}
	}
});

export const { setSelectedRow, setFormData, clearForm, setModalMode, setSelectedTab } = maintenanceCrudSlice.actions;

export const selectSelectedRow = (state: RootState) => state.maintenance.selectedRow;
export const selectModalMode = (state: RootState) => state.maintenance.modalMode;
export const selectSelectedTab = (state: RootState) => state.maintenance.selectedTab;
export const selectMaintentanceFormData = (state: RootState) => state.maintenance.form.data;

export default maintenanceCrudSlice.reducer;
