import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { mask } from 'src/utils/CpfCnpjMask';
import { isColumnOmitted } from '../users/tableColumns';
import { formatCNH } from './helper';
import { TColumn, TColumnsNames } from './types';

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('EMAIL'), columnId: 'email' },
	{ name: t('PHONE'), columnId: 'phone' },
	{ name: t('MOBILE'), columnId: 'mobile' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('CNH'), columnId: 'cnh' },
	{ name: t('CNH_TYPE'), columnId: 'cnhType' },
	{ name: t('CNH_DUE_DATE'), columnId: 'cnhDueDate' },
	{ name: t('CPF'), columnId: 'cpf' },
	{ name: t('RG'), columnId: 'rg' },
	{ name: t('RG_TYPE'), columnId: 'rgType' },
	{ name: t('RG_CREATED_DATE'), columnId: 'rgCreatedDate' },
	{ name: t('BIRTHDAY'), columnId: 'birthday' },
	{ name: t('NOTES'), columnId: 'notes' },
	{ name: t('USER'), columnId: 'user.name' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('EMAIL'), columnId: 'email' },
	{ name: t('PHONE'), columnId: 'phone' },
	{ name: t('MOBILE'), columnId: 'mobile' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('CNH'), columnId: 'cnh' },
	{ name: t('CNH_TYPE'), columnId: 'cnhType' },
	{ name: t('CPF'), columnId: 'cpf' },
	{ name: t('RG'), columnId: 'rg' },
	{ name: t('RG_TYPE'), columnId: 'rgType' },
	{ name: t('NOTES'), columnId: 'notes' }
];

export const defaultVisibleColumns = [
	'name',
	'email',
	'phone',
	'mobile',
	'cnh',
	'cnhType',
	'cnhDueDate',
	'cpf',
	'rg',
	'rgType',
	'rgCreatedDate',
	'birthday',
	'notes',
	'user.name',
	'company.name',
	'createdAt',
	'createdBy.name',
	'address'
];

export const columnsSearchKeys = [
	'_id',
	'name',
	'email',
	'phone',
	'mobile',
	'cnh',
	'cnhType',
	'cpf',
	'rg',
	'rgType',
	'rgCreatedDate',
	'notes',
	'createdBy.name',
	'address'
];

export const columnSortKeys = [
	'_id',
	'name',
	'email',
	'phone',
	'mobile',
	'cnh',
	'cnhType',
	'cnhDueDate',
	'cpf',
	'rg',
	'rgType',
	'rgCreatedDate',
	'birthday',
	'notes',
	'createdAt',
	'address'
];

export const getColumns = (t: TFunction, filteredColumns: string[]): TColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row?._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:person" />,
		selector: (row) => row?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'email',
		name: <ColumnName name={t('EMAIL')} icon="material-outline:email" />,
		selector: (row) => row?.email,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('email', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'phone',
		name: <ColumnName name={t('PHONE')} icon="material-outline:phone" />,
		selector: (row) => row?.phone && formatPhoneNumberIntl(row.phoneCountryCode + row.phone),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('phone', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'mobile',
		name: <ColumnName name={t('MOBILE')} icon="material-outline:phone_android" />,
		selector: (row) => row?.mobile && formatPhoneNumberIntl(row.mobileCountryCode + row.mobile),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('mobile', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'cnh',
		name: <ColumnName name={t('CNH')} icon="material-outline:drive_file_rename_outline" />,
		selector: (row) => row?.cnh && formatCNH(row.cnh),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('cnh', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'cnhType',
		name: <ColumnName name={t('CNH_TYPE')} icon="material-outline:drive_file_rename_outline" />,
		selector: (row) => row?.cnhType,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('cnhType', filteredColumns)
	},
	{
		minWidth: '300px',
		id: 'cnhDueDate',
		name: <ColumnName name={t('CNH_DUE_DATE')} icon="material-outline:date_range" />,
		selector: (row) => row?.cnhDueDate && dayjs(row.cnhDueDate).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('cnhDueDate', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'cpf',
		name: <ColumnName name={t('CPF')} icon="material-outline:drive_file_rename_outline" />,
		selector: (row) => row?.cpf && mask(row.cpf).value,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('cpf', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'rg',
		name: <ColumnName name={t('RG')} icon="material-outline:drive_file_rename_outline" />,
		selector: (row) => row?.rg,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('rg', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'rgType',
		name: <ColumnName name={t('RG_TYPE')} icon="material-outline:drive_file_rename_outline" />,
		selector: (row) => row?.rgType,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('rgType', filteredColumns)
	},
	{
		minWidth: '300px',
		id: 'rgCreatedDate',
		name: <ColumnName name={t('RG_CREATED_DATE')} icon="material-outline:date_range" />,
		selector: (row) => row?.rgCreatedDate && dayjs(row.rgCreatedDate).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('rgCreatedDate', filteredColumns)
	},
	{
		minWidth: '300px',
		id: 'birthday',
		name: <ColumnName name={t('BIRTHDAY')} icon="material-outline:date_range" />,
		selector: (row) => row?.birthday && dayjs(row.birthday).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('birthday', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'address',
		name: <ColumnName name={t('ADDRESS')} icon="material-outline:location_on" />,
		selector: (row) => row?.address,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('address', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'notes',
		name: <ColumnName name={t('NOTES')} icon="material-outline:notes" />,
		selector: (row) => row?.notes,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('notes', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'user.name',
		name: <ColumnName name={t('USER')} icon="material-outline:person" />,
		selector: (row) => row?.user?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('user.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row?.company?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:date_range" />,
		selector: (row) => row?.createdAt && dayjs(row.createdAt).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row) => row?.createdBy?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	}
];
