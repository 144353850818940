import { DeleteOutline, PushPin, PushPinOutlined } from '@mui/icons-material';
import {
	Checkbox,
	CircularProgress,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Tooltip
} from '@mui/material';

import { IRealtimeSettings } from 'app/store/api/types';
import { useUpdateUserFilterMutation } from 'app/store/api/userSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import {
	initialState,
	selectSelectedUserFilter,
	selectUserDefaultFilter,
	setAllFilters,
	setIsDefaultFilter,
	setSelectedUserFilter
} from 'app/store/map/advanceFilterSlice';
import { selectUser, updateUserDefaultFilter } from 'app/store/user/userSlice';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export interface IMyFilterItem {
	item: IRealtimeSettings;
	isDeleteLoading: boolean;
	handleClick: (_id: string) => void;
	handleDelete: (_id: string) => void;
}
export default function MyFilterItem({ item, isDeleteLoading, handleClick, handleDelete }: IMyFilterItem) {
	const labelId = `checkbox-list-label-${item._id}`;
	const isDefault = item._id === useAppSelector(selectUserDefaultFilter);
	const selectedUserFilter = useAppSelector(selectSelectedUserFilter);
	const currentUser = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	const [updateUserFilter] = useUpdateUserFilterMutation();
	const { t } = useTranslation('mapPage');
	const [, setSearchParams] = useSearchParams();

	const handleMakeDefault = async (id: string) => {
		const defaulFilterId = isDefault ? null : id;
		const data = {
			realtimeDefaultFilterId: defaulFilterId,
			id: currentUser.data.userId
		};

		try {
			dispatch(setIsDefaultFilter(defaulFilterId));
			if (isDefault) {
				dispatch(setSelectedUserFilter(''));
				dispatch(setAllFilters(initialState.selectedFilters));
				setSearchParams('');
			} else {
				dispatch(setSelectedUserFilter(item._id));
				setSearchParams(`?filterId=${item._id}`);
			}

			await updateUserFilter(data).unwrap();
			dispatch(updateUserDefaultFilter(data.realtimeDefaultFilterId));
			dispatch(
				showMessage({
					message: isDefault ? t('SUCCESS_DEFAULT_FILTER_UNSET') : t('SUCCESS_DEFAULT_FILTER'),
					variant: 'success'
				})
			);
		} catch {
			dispatch(
				showMessage({
					message: t('ERROR_DEFAULT_FILTER'),
					variant: 'error'
				})
			);
		}
	};
	return (
		<ListItem
			secondaryAction={
				<>
					<Tooltip title={t('MAKE_DEFAULT')} placement="bottom">
						<IconButton size="small" onClick={() => handleMakeDefault(item._id)} disabled={isDeleteLoading}>
							{isDefault ? <PushPin /> : <PushPinOutlined />}
						</IconButton>
					</Tooltip>

					<IconButton size="small" onClick={() => handleDelete(item._id)} disabled={isDeleteLoading}>
						{isDeleteLoading ? <CircularProgress size={20} /> : <DeleteOutline />}
					</IconButton>
				</>
			}
		>
			<ListItemButton onClick={() => handleClick(item._id)} dense>
				<ListItemIcon>
					<Checkbox
						edge="start"
						checked={item._id === selectedUserFilter}
						tabIndex={-1}
						disableRipple
						inputProps={{ 'aria-labelledby': labelId }}
					/>
				</ListItemIcon>
				<ListItemText primary={item.name} id={labelId} />
			</ListItemButton>
		</ListItem>
	);
}
