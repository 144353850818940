import { TokenOutlined } from '@mui/icons-material';
import { Box, Typography, alpha } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const IntegrateTitle: React.FC = () => {
	const { t } = useTranslation('integrationsPage');

	return (
		<Fragment>
			<Box
				sx={(theme) => ({
					backgroundColor: alpha(theme.palette.secondary.main, 0.12),
					p: 1,
					borderRadius: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: 56,
					height: 56
				})}
			>
				<TokenOutlined color="secondary" sx={{ fontSize: 28 }} />
			</Box>
			<Typography className="text-28" fontWeight={700}>
				{t('SYNC_PLATAFORM', { n: 'Hinova' })}
			</Typography>
		</Fragment>
	);
};
