import { WarningOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { TFunction } from 'react-i18next';

interface IStreetViewWarningProps {
	t: TFunction;
	totalOfTrips: number;
}

export default function StreetViewWarning({ t, totalOfTrips }: IStreetViewWarningProps) {
	return (
		<Stack
			direction="row"
			spacing={2}
			sx={{
				width: '100%',
				border: (theme) => `1px solid ${theme.palette.divider}`,
				padding: 2,
				borderRadius: 2
			}}
		>
			<Box
				sx={{ backgroundColor: 'background.default' }}
				className="flex shrink-0 relative items-center justify-center w-48 h-48 mr-12 rounded-full self-start"
			>
				<WarningOutlined color="error" />
			</Box>

			<div>
				<Typography fontWeight={600} fontSize={16}>
					{t('STREET_VIEW_WARNING_1')}
				</Typography>
				<Typography color="text.secondary">{t('STREET_VIEW_WARNING_2', { n: totalOfTrips })}</Typography>
			</div>
		</Stack>
	);
}
