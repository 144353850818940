const pt = {
	ROWS_PER_PAGE: 'Itens por página',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	OF: 'de',
	EDIT: 'Editar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	TITLE: 'Manutenções',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar manutenção',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar?',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando manutenção',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar esta manutenção?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar as manutenções selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_MAINTENANCES: 'Ocorreu um erro ao carregar as manutenções, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Manutenção não encontrada, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_MAINTENANCE: 'Adicionar manutenção',
	CREATE_SUCCESS: 'Manutenção criada com sucesso',
	CREATE_ITEM_SUCCESS: 'Item de manutenção criado com sucesso',
	CREATE_ITEM_ERROR: 'Erro ao adicionar item de manutenção',
	EDIT_ITEM_SUCCESS: 'Item de manutenção atualizado com sucesso',
	EDIT_ITEM_ERROR: 'Erro ao atualizar item de manutenção',
	DELETE_ITEM_SUCCESS: 'Item de manutenção deletado com sucesso',
	DELETE_ITEM_ERROR: 'Erro ao deletar item de manutenção',
	CREATE_ERROR: 'Erro ao adicionar manutenção',
	EDIT_SUCCESS: 'Manutenção atualizada com sucesso',
	EDIT_ERROR: 'Erro ao atualizar manutenção',
	DELETE_SUCCESS: 'Manutenção deletada com sucesso',
	DELETE_ERROR: 'Erro ao deletar manutenção',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de manutenções',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar manutenções',
	NO_PERMISSION_edit: 'Você não possui permissão para editar manutenções',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar manutenções',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar manutenções',
	MAINTENANCE_TYPE: 'Tipo de manutenção',
	STATUS: 'Status',
	NOTIFICATIONS: 'Notificações',
	SCHEDULE: 'Realização',
	OPTIONAL: 'Opcional',
	EDIT_MAINTENANCE: 'Editar manutenção',
	SCHEDULE_MAINTENANCE: 'Cadastrar nova manutenção',
	NO_MAINTENANCE_FOUND: 'Nenhuma manutenção encontrada.',
	SELECT_ITEM: 'Selecione qual item será realizada a manutenção',
	PREVENTIVE: 'Preventiva',
	CORRECTIVE: 'Corretiva',
	UNPLANNED: 'Não planejada',
	BY_DATE: 'Por data',
	BY_MILEAGE: 'Por quilometragem',
	MILEAGE_LABEL: 'Digite o KM',
	DEADLINE_LABEL: 'Limite de execução',
	KEEP_FREQUENCY_NEXT: 'Essa manutenção acontecerá regularmente?',
	MONTHLY: 'Mensalmente',
	DAILY: 'Diarianente',
	WEEKLY: 'Semanalmente',
	CONTINUE: 'Continuar',
	BACK: 'Voltar',
	LIMIT_TYPE: 'Tipo de limite',
	MAINTENANCE_STATUS: 'Status da manutenção',
	OPENED: 'Em aberto',
	EXECUTED: 'Realizada',
	MAINTENANCE_VALUE: 'Valor da manutenção',
	ODOMETER: 'Odômetro',
	EXECUTION_DATE: 'Data da realização',
	WARNING_BY: 'Gerar aviso por',
	DATE: 'Data',
	MILEAGE: 'Quilômetro',
	WARNING_DATE: 'Selecione a data do aviso',
	NOTIFY_BY: 'Notificar por',
	APP: 'Plataforma',
	WHATSAPP: 'Whatsapp',
	EMAIL: 'Email',
	PHONE: 'Telefone',
	EXECUTION_LOCATION: 'Local da execução da manutenção',
	EXECUTION_LOCATION_PLACEHOLDER: 'Escreva o local que foi realizada a manutenção',
	EXECUTION_RESPONSIBLE: 'Responsável pela manutenção',
	EXECUTION_RESPONSIBLE_PLACEHOLDER: 'Escreva o nome do responsável por realizar a manutenção',
	INVOICE_LABEL: 'Anexar nota de serviço',
	INVOICE_DROP: 'Clique ou solte arquivos aqui',
	SELECT_MORE_FILES: 'Selecione mais arquivos',
	REMOVE: 'Remover',
	NOTIFICATION_KM: 'Selecione o KM do aviso',
	ITEM: 'Item',
	TYPE: 'Tipo',
	LOCATION: 'Localização',
	PRICE: 'Preço',
	RESPONSIBLE: 'Responsável',
	FREQUENCY: 'Frequência',
	OPTIONALS: 'Opcional',
	ITEM_IMAGE: 'Anexar imagem do item (Opcional)',
	OBSERVATION_LABEL: 'Observação (Opcional)',
	ITEM_IMAGE_DROP: 'clique ou solte arquivos aqui',
	NAME: 'Nome',
	TITLE_ITEM: 'Item de manutenção',
	ADD_MAINTENANCE_ITEM: 'Adicionar item de manutenção',
	CURRENT_ODOMETER: 'Odômetro atual do veículo',
	CONFIRM: 'Confirmar',
	MAINTENANCE_REPORT: 'Relátorio de manutenção',
	EXPORT_DATA: 'Exportar Dados',
	MAINTENANCE_TOTAL_EXPENSES: 'Total gasto em manutenções',
	SCHEDULE_MAINTENANCE_EXPENSES_FORECAST: 'Previsão de gastos em manutenções agendadas',
	MAINTENANCE_ITEMS_RANKING: 'Ranking de itens com mais manutenção',
	MAINTENANCE_FLEET_RANKING: 'Ranking das frotas com mais manutenção',
	FLEET_PERIOD_MEDIAN: 'Média dos períodos entre frotas',
	YEARLY_MEDIAN: 'Média dos últimos 12 meses',
	FOUR_WEEKS_MEDIAN: 'Média das últimas 4 semanas',
	NO_CHART_DATA: 'Nenhum dado encontrado',
	SELECT: 'Selecionar',
	period: 'Período',
	FROM: 'De',
	TO: 'Até',
	GET_REPORT_ERROR: 'Erro ao buscar relatório',
	SCHEDULED_MAINTENANCES: 'Manutenções agendadas',
	EXECUTED_MAINTENANCES: 'Manutenções executadas',
	SELECT_MAINTENANCE_TARGET:
		'Selecione um veículo(s) ou frota(s), se você selecionar um grupo de veículos ou de frotas, a manutenção será cadastrada para todos os veículos do grupo.',
	VEHICLES: 'Veículos',
	FLEETS: 'Frotas',
	COMPANIES: 'Empresas',
	FLEETS_SELECTED: '{{n}} frotas selecionadas',
	VEHICLES_SELECTED: '{{n}} veículos selecionados',
	COMPANY: 'Empresa',
	EXECUTE: 'Executar',
	VIEW: 'Ver',
	MAINTENANCE: 'Manutenção',
	EXECUTE_MAINTENANCE: 'Executar manutenção',
	ODOMETER_LIMIT: 'Limite de quilometragem',
	LIMIT_DATE: 'Data limite',
	ATTACHMENTS: 'Anexos',
	MAINTENANCE_DETAILS: 'Detalhes da manutenção',
	SELECTED_ITEMS: '{{n}} Itens selecionados',
	MAX_ITEM_ERROR: 'Você só pode selecionar no máximo 3 itens',
	MIN_ITEM_ERROR: 'Selecione ao menos 1 item',
	MAINTENANCE_STATUS_LABEL: 'Qual é o status da manutenção?',
	INFORMATIONS: 'Informações',
	MAINTENANCE_INFORMATIONS: 'Informações da manutenção',
	KEEP_FREQUENCY_TOOLTIP_TEXT:
		'Quando essa opção estiver marcada, as próximas manutenções que forem geradas automaticamente usaram os mesmo prazos',
	FREQUENCY_INTERVAL_TOOLTIP_TEXT: 'Selecione o intervalo em que a manutenção deve ser realizada',
	MILEAGE_TOOLTIP_TEXT:
		'Digite o limite de quilometragem até essa manutenção ser executada, por exemplo, se você precisa que a manutenção seja executada a cada 1000km, digite 1000',
	DEADLINE_DATE_TOOLTIP_TEXT: 'Insira a data limite que essa manutenção pode ocorrer',
	MONTHS: 'Mêses',
	DAYS: 'Dias',
	YEARS: 'Anos',
	WEEKS: 'Semanas',
	NOTIFICATION_INTERVAL_INFO: ' {{n}} {{t}} antes de {{v}}',
	NOTIFICATION_MILEAGE_INFO: 'O veículo rodar {{n}} km a partir da quilomentragem atual',
	NOTIFICATION_INFO: 'Você será notificado 1 dia antes da data limite estabelecida',
	OR: 'Ou \n {{n}}. O que acontecer primeiro',
	LIMIT_TYPE_MISSING_ERROR: 'Você deve selecionar ao menos um tipo de limite',
	DEADLINE_DATE_REQUIRED: 'Data limite é obrigatória',
	DEADLINE_MILEAGE_REQUIRED: 'Quilometragem limite é obrigatória',
	FREQUENCY_REQUIRED: 'Selecione a frequência da manutenção',
	SELECT_USERS: 'Selecione os usuários',
	NOTIFICATION_TYPE_MISSING_ERROR: 'Você deve selecionar ao menos um tipo de notificação',
	EMAIL_REQUIRED: 'Email é obrigatório',
	PHONE_REQUIRED: 'Telefone é obrigatório',
	USER_REQUIRED: 'Usuário é obrigatório',
	SELECTED_USERS: '{{n}} selecionados',
	PERIOD: 'Período',
	COMPANY_REQUIRED: 'Empresa é obrigatória',
	VEHICLE_REQUIRED: 'Veículo é obrigatório',
	FLEET_REQUIRED: 'Frota é obrigatória',
	EXECUTION_DATE_REQUIRED: 'Data de execução é obrigatória',
	NOTIFICATION_PERIOD_TOOLTIP_TEXT:
		'Selecione o período que você deseja ser notificado antes da data configurada anteriormente',
	NOTIFICATION_MILEAGE_TOOLTIP_TEXT:
		'Selecione o KM que você deseja ser notificado antes da quilometragem configurada anteriormente',
	PERIOD_AMOUNT: 'Quantidade de períodos',
	CONCLUDE: 'Concluir',
	VEHICLE: 'Veículo',
	NO_LOCATION: 'Nenhum local informado',
	NO_RESPONSIBLE: 'Nenhum responsável informado',
	NO_ATTACHMENTS: 'Nenhum anexo encontrado',
	ITEM_NAME_REQUIRED: 'Item é obrigatório',
	NAME_REQUIRED: 'Nome é obrigatório',
	NO_USER_FOUND: 'Nenhum dado encontrado',
	ALLOW_NOTIFICATION: 'Habilitar notificações',
	INVALID_EMAIL: 'Email inválido',
	EDIT_ITEM: 'Editar Item',
	DELETE_ITEM_MODAL_TITLE: 'Deletar item',
	DELETE_ITEM_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este item?',
	DELETE_ITEM_MODAL_CONFIRM: 'Yes, delete',
	DELETE_ITEM_MODAL_CANCEL: 'Cancel',
	DELETE_ITEM_MODAL_WARNING: 'Esta ação não pode ser desfeita.',
	DELETE_MULTIPLE_INFO: 'Deletar itens selecionados',
	DEADLINE_MILEAGE: 'Quilometragem limite',
	EXECUTION_MILEAGE: 'Quilometragem de execução',
	EXECUTION_MILEAGE_REQUIRED: 'Quilometragem de execução é obrigatória',
	CONTACTS: 'Contatos',
	CONTACTS_LIMIT: '{{n}} contatos selecionados',
	NOTIFICATION_WEB: 'Notificação web'
};

export default pt;
