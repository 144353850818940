import { StyledSnackbar } from '@fuse/core/FuseMessage/FuseMessage';
import { Button, LinearProgress, SnackbarContent, Typography } from '@mui/material';
import { api } from 'app/services/api/api';
import { IApiPagination } from 'app/store/api/types';
import {
	reset,
	selectCurrentPage,
	selectLoading,
	selectTotalPages,
	setCurrentPage,
	setLoading,
	setTotalPages
} from 'app/store/download/downloadSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import { addTranslation, normalise } from './helper';
import { DownloadContextProps, DownloadProviderProps, HandleDownloadProps } from './types';

let needStop = false;
addTranslation();

export const DownloadContext = createContext({} as DownloadContextProps);

const DownloadProvider = ({ children }: DownloadProviderProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('download');
	const loading = useSelector(selectLoading);
	const currentPage = useSelector(selectCurrentPage);
	const totalPages = useSelector(selectTotalPages);

	async function recursiveDownload<T>({ url, params = '', page = 1, result = [] }) {
		if (needStop) return;

		const reqUrl = url + `?page=${page}&limit=1000${params}`;

		const { data } = await api.get<IApiPagination<T>>(reqUrl).catch(() => {
			throw new Error('net-error');
		});

		if (data?.docs) result.push(...data.docs);
		if (data?.totalPages) dispatch(setTotalPages(data.totalPages));
		if (!data?.hasNextPage) return result;

		const newPage = page + 1;
		dispatch(setCurrentPage(newPage));
		return await recursiveDownload({ url, params, page: newPage, result });
	}

	async function handleDownload({ url, params }: HandleDownloadProps) {
		dispatch(setLoading(true));
		needStop = false;

		try {
			const data = await recursiveDownload({ url, params });
			return data;
		} catch (error) {
			if (error?.message === 'net-error') {
				dispatch(
					showMessage({
						message: t('NET_ERROR'),
						autoHideDuration: 3000,
						variant: 'error'
					})
				);
				return;
			}
		} finally {
			handleClose();
		}
	}

	const handleClose = () => {
		needStop = true;
		dispatch(reset());
	};

	const action = (
		<Button color="secondary" size="small" onClick={handleClose}>
			{t('CANCEL')}
		</Button>
	);

	const progress = normalise({ max: totalPages, min: 0, value: currentPage });
	const pageIndicator = totalPages > 0 && currentPage > 0 ? `${currentPage}/${totalPages}` : '';

	const message = (
		<div className="flex flex-col gap-2 flex-1 min-w-[160px]">
			<Typography>{`${t('DOWNLOADING')}... ${pageIndicator}`}</Typography>
			<LinearProgress className="w-full" value={progress} variant="determinate" color="secondary" />
		</div>
	);
	const value = useMemo(() => ({ download: handleDownload, loading }), []);
	return (
		<DownloadContext.Provider value={value}>
			<StyledSnackbar
				open={loading}
				autoHideDuration={null}
				variant="download"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				<SnackbarContent className="FuseMessage-content" message={message} action={action} />
			</StyledSnackbar>

			{children}
		</DownloadContext.Provider>
	);
};

export default DownloadProvider;
