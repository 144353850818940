import { DialogContent, Stack, TextField, Typography } from '@mui/material';
import { type ChangeEvent, useState } from 'react';
import type { TFunction } from 'react-i18next';

import AppButton from 'app/shared-components/Buttons/AppButton';

type TFinalizeDialog = {
	onClose: () => void;
	onConfirm: (_brief: string) => void;
	t: TFunction;
};

export default function FinalizeDialog({ onClose, onConfirm, t }: TFinalizeDialog) {
	const [brief, setBrief] = useState('');

	const onChangeBrief = (e: ChangeEvent<HTMLTextAreaElement>) => setBrief(e.target.value);
	const onClickConfirm = () => onConfirm(brief);

	return (
		<DialogContent
			sx={{
				padding: '20px',
				width: ['100%', '520px'],
				minHeight: '320px',
				'& .MuiTextField-root': {
					marginY: '20px'
				}
			}}
		>
			<Typography variant="h6" className="font-bold">
				{t('FINALIZE_MODAL_TITLE')}
			</Typography>
			<Typography variant="body2" className="mt-[10px]">
				{t('FINALIZE_MODAL_DESCRIPTION')}
			</Typography>
			<Typography color="error" variant="body2">
				{t('FINALIZE_MODAL_WARNING')}
			</Typography>

			<TextField
				label={t('FINALIZE_MODAL_BRIEF')}
				variant="outlined"
				rows={4}
				maxRows={4}
				multiline
				fullWidth
				value={brief}
				onChange={onChangeBrief}
			/>

			<Stack direction="row" spacing={1} className="mt-[20px]" alignItems="center" justifyContent="center">
				<AppButton variant="outlined" color="error" onClick={onClickConfirm} fullWidth>
					{t('CONFIRM')}
				</AppButton>
				<AppButton variant="contained" color="secondary" onClick={onClose} fullWidth>
					{t('CANCEL')}
				</AppButton>
			</Stack>
		</DialogContent>
	);
}
