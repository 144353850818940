import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Theme } from '@mui/material';
import { alpha, Box } from '@mui/system';
import { memo } from 'react';

type IconCardProps = {
	icon?: string | null;
	size?: number;
	children?: React.ReactNode;
};

const IconCard = ({ icon = null, size = 40, children = null }: IconCardProps) => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: `${size}px`,
			width: `${size}px`,
			minHeight: `${size}px`,
			minWidth: `${size}px`,
			borderRadius: '8px',
			backgroundColor: (theme: Theme) => alpha(theme.palette.secondary.main, 0.1)
		}}
	>
		{children}
		{icon && (
			<FuseSvgIcon size={size * 0.5} color="secondary">
				{`material-outline:${icon}`}
			</FuseSvgIcon>
		)}
	</Box>
);

export default memo(IconCard);
