import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

interface IInitialState {
	isFilterOpen: boolean;
}

const initialState: IInitialState = {
	isFilterOpen: false
};

const trackerMetrics = createSlice({
	name: 'trackerMetrics',
	initialState,
	reducers: {
		closeFilter: (state) => {
			state.isFilterOpen = false;
		},
		openFilter: (state) => {
			state.isFilterOpen = true;
		}
	}
});

export const { closeFilter, openFilter } = trackerMetrics.actions;

export const selectIsFilterOpen = (state: RootState) => state.trackerMetrics.isFilterOpen;

export default trackerMetrics.reducer;
