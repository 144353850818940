import i18next from 'i18next';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';
import PublicLinkPage from './PublicLinkPage';

i18next.addResourceBundle('en', 'PublicLinkPage', en);
i18next.addResourceBundle('pt', 'PublicLinkPage', pt);
i18next.addResourceBundle('sp', 'PublicLinkPage', sp);

const PublicLinkConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/public-link',
			children: [
				{
					path: '',
					element: <PublicLinkPage />
				}
			]
		}
	]
};

export default PublicLinkConfig;
