import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store/index';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import { SnackbarVariant } from '@fuse/core/FuseMessage/FuseMessage';

interface MessageState {
	state: boolean;
	options?: Pick<SnackbarProps, 'anchorOrigin' | 'autoHideDuration' | 'message'> & { variant: SnackbarVariant };
}

const initialState: MessageState = {
	state: false,
	options: {
		anchorOrigin: {
			vertical: 'top',
			horizontal: 'center'
		},
		autoHideDuration: 2000,
		message: 'Hi',
		variant: 'info'
	}
};

const messageSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		showMessage: (state, action: PayloadAction<MessageState['options']>) => {
			state.state = true;
			state.options = {
				...initialState.options,
				...action.payload
			};
		},
		hideMessage: (state) => {
			state.state = false;
		}
	}
});

export const { hideMessage, showMessage } = messageSlice.actions;

export const selectFuseMessageState = (state: RootState) => state.fuse.message.state;

export const selectFuseMessageOptions = (state: RootState) => state.fuse.message.options as MessageState['options'];

export default messageSlice.reducer;
