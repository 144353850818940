import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storageService from 'app/services/storage';
import { FuelSupply } from 'app/types/fuel-supply.types';

interface IInitialState {
	visibleColumns: string[];
	selectedRow: FuelSupply;
}

const DEFAULT_VISIBLE_COLUMNS = [
	'supplyDate',
	'vehicle.name',
	'fuelType',
	'fuelVolume',
	'price',
	'fuelPrice',
	'establishment.name'
];

const initialState: IInitialState = {
	visibleColumns: storageService.get('fuel-supply-visible-columns') || DEFAULT_VISIBLE_COLUMNS,
	selectedRow: null
};

const fuelSupplySlice = createSlice({
	name: 'fuelSupply',
	initialState,
	reducers: {
		setVisibleColumns: (state, action) => {
			state.visibleColumns = action.payload;
			storageService.set('fuel-supply-visible-columns', action.payload);
		},
		setSelectedRow: (state, action: PayloadAction<FuelSupply>) => {
			state.selectedRow = action.payload;
		}
	}
});

export const { setVisibleColumns, setSelectedRow } = fuelSupplySlice.actions;

export const selectVisibleColumns = (state) => state.fuelSupply.visibleColumns;

export default fuelSupplySlice.reducer;
