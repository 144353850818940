const locale = {
	TÍTULO: 'Eventos Finalizados',
	SÍ: 'Sí',
	NO: 'No',
	BUSCAR: 'Buscar',
	LIMPIAR_BUSQUEDA: 'Limpiar filtros',
	CANCELAR: 'Cancelar',
	CONFIRMAR: 'Confirmar',
	ITENS_SELECCIONADOS: '{{n}} elementos seleccionados',
	ALTA: 'Alta',
	MEDIA: 'Media',
	BAJA: 'Baja',

	// Tabla
	ITEMS_POR_PAGINA: 'Elementos por página',
	DE: 'de',
	VEHÍCULO: 'Vehículo',
	RASTREADOR: 'Rastreador',
	USUARIO: 'Usuario',
	FECHA: 'Fecha',
	ALERTA: 'Alerta',
	NO_SE_ENCONTRARON_ELEMENTOS: 'Ningún evento encontrado',
	TIEMPO_TOTAL: 'Tiempo total',
	VER_JORNADA: 'Ver Jornada',
	JORNADA_NO_ENCONTRADA: 'Jornada no encontrada',

	// Filtros
	SEVERIDAD: 'Severidad',
	TIEMPO: 'Tiempo',
	MOSTRAR_SEVERIDAD_BAJA: 'Mostrar severidad baja',
	MOSTRAR_SEVERIDAD_MEDIA: 'Mostrar severidad media',
	MOSTRAR_SEVERIDAD_ALTA: 'Mostrar severidad alta',
	ÚLTIMA_HORA: 'Última hora',
	ÚLTIMAS_N_HORAS: 'Últimas {{n}} horas',
	LIMPIAR_TODO: 'Limpiar filtros',
	SELECCIONAR_FILTROS: 'Seleccionar filtros',

	// Descargar
	DESCARGAR_SELECCIONADOS: 'Descargar seleccionados',
	DESCARGAR_TODOS: 'Descargar todos',
	ERROR_DESCARGA: 'Error al descargar eventos finalizados.',

	// Barra lateral
	MOTIVO_FINALIZACIÓN: 'Motivo de finalización.',
	POR_HACER: 'Abierto',
	EN_PROGRESO: 'En progreso',
	TERMINADO: 'Finalizado',
	ACCIONES: 'Acciones realizadas',
	TOTAL_ACCIONES: '{{n}} acciones realizadas',
	MOVED_TO: 'Movido para: {{action}}',

	// Events
	'E.IGN_ON': 'Encendido conectado',
	'E.IGN_OFF': 'Encendido apagado',
	'E.DIN_CHANGED': 'Entrada activada',
	'E.EXT_POWER_CUT_OFF': 'Sin batería',
	'E.PANIC': 'Pánico',
	'E.LOW_BATTERY_VOLTAGE': 'Batería baja',
	'E.SHIFT_WARNING': 'Chilló',
	'E.GPS_ANTENNA_OPEN': 'GPS externo conectado',
	'E.GPS_ANTENNA_SHORT': 'GPS externo apagado',
	'E.EXT_POWER_RECONNECTED': 'Batería reconectada',
	'E.UNDER_SPEED': 'Baja velocidad',
	'E.OVER_SPEED': 'Alta velocidad',
	'E.INTO_FENCE': 'Entró en la cerca',
	'E.OUT_FENCE': 'Se salió de la cerca',
	'E.EXTERNAL_BATTERY_LOW': 'Batería baja',
	'E.DRIVER_OFF': 'Conductor apagado',
	'E.DRIVER_ON': 'Conductor encendido'
};

export default locale;
