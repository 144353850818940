const locale = {
	TITLE: 'Sessões',

	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',

	ON_DISCONNECT_ERROR: 'Erro ao desconectar sessão do WhatsApp',
	DISCONNECT: 'Desconectar',
	QR_CODE_INFO: 'Para fazer login na sessão, escaneie o QR Code acima',

	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	ALREADY_IN_SESSION: 'Já existe uma sessão ativa para as empresas: {{companies}}',

	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',

	DATE: 'Data',
	OF: 'de',

	ROWS_PER_PAGE: 'Itens por página',

	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	DESCRIPTION_REQUIRED: 'O campo descrição é obrigatório',

	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',

	ADD_SESSION: 'Adicionar sessões',
	CREATE_SUCCESS: 'Sessão adicionada com sucesso',
	CREATE_ERROR: 'Erro ao adicionar sessão',
	EDIT_SUCCESS: 'Sessão atualizada com sucesso',
	EDIT_ERROR: 'Erro ao atualizar sessão',
	DELETE_SUCCESS: 'Sessão deletada com sucesso',
	DELETE_ERROR: 'Erro ao deletar sessão',
	NO_SESSION_FOUND: 'Nenhuma sessão foi encontrada',

	LOADING: 'Carregando',
	DELETE_MODAL_TITLE: 'Deletar sessão',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta sessão?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} sessões?',
	EDIT_INFO: 'Editar sessão',
	DELETE_INFO: 'Deletar sessão',
	DOWNLOAD_INFO: 'Baixar sessões selecionados',
	EDIT_MULTIPLE_INFO: 'Editar sessões selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar sessões selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar sessões',
	EDIT_MULTIPLE_SUCCESS: 'Sessãos editadas com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar sessões',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.'
};

export default locale;
