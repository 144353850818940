import { IconProps, Skeleton, Stack, Typography } from '@mui/material';
import { ElementType, ReactNode } from 'react';

interface InformationProps {
	label: string;
	value?: string;
	icon?: ElementType<IconProps>;
	variant?: 'large' | 'small';
	customIcon?: ReactNode;
	iconProps?: IconProps;
	loading?: boolean;
	iconPosition?: 'end' | 'center' | 'start';
}

export const Information: React.FC<InformationProps> = ({
	label,
	value,
	icon: Icon,
	variant = 'small',
	customIcon,
	iconProps,
	iconPosition = 'center',
	loading = false
}) => {
	const direction = variant === 'small' ? 'row' : 'column';
	const alignItems = variant === 'small' ? 'center' : 'flex-start';
	const gap = variant === 'small' ? 1 : 0;
	const fontSize = variant === 'small' ? 12 : 16;
	const color = variant === 'small' ? 'textSecondary' : 'textPrimary';
	const icon = customIcon || (Icon && <Icon {...iconProps} sx={{ fontSize: '12px' }} />);

	if (loading) return <Skeleton variant="text" width="100%" height={20} />;

	return (
		<Stack direction={direction} alignItems={alignItems} gap={gap}>
			{iconPosition === 'start' && icon}
			<Typography fontWeight={500} fontSize={12} color="textSecondary">
				{label}:
			</Typography>
			<Typography fontWeight={600} fontSize={fontSize} color={color} alignItems="center" display="flex" gap={1}>
				{iconPosition === 'center' && icon}
				{value}
				{iconPosition === 'end' && icon}
			</Typography>
		</Stack>
	);
};
