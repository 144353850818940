import { commonEnTranslates } from 'src/constants/common-translates';

const locale = {
	TITLE: 'Map',
	FILTERS: 'Filters',
	COMPANIES: 'Companies',
	VEHICLES: 'Vehicles',
	FLEETS: 'Fleets',
	STATES: 'States',
	SEARCH: 'Search...',
	ADVANCED_FILTERS: 'Advanced Filters',
	VEHICLE_TYPE: 'Vehicle Type',
	IGNITION_ON: 'Ignition On',
	SPEED: 'Speed',
	GREEN: 'Green',
	RED: 'Red',
	YELLOW: 'Yellow',
	PURPLE: 'Purple',
	GRAY: 'Gray',
	BOAT: 'Boat',
	CAR: 'Car',
	BIKE: 'Bike',
	TRUCK: 'Truck',
	MOTORBIKE: 'Motorbike',
	OTHERS: 'Others',
	APPLY: 'Apply',
	SAVE: 'Save',
	NAME: 'Name',
	EQUALS: 'Equals',
	GREATER_THAN: 'Greater than',
	LESS_THAN: 'Less than',
	MY_FILTERS: 'My Filters',
	SUCCESS_SAVE_FILTER: 'Filter created successfully',
	ERROR_SAVE_FILTER: 'Error creating filter',
	SUCCESS_DELETE_FILTER: 'Successfully deleted filter',
	ERROR_DELETE_FILTER: 'Error deleting filter',
	SELECTED_COMPANIES: '{{n}} Selected companies',
	SELECTED_FLEETS: '{{n}} Selected fleets',
	SELECTED_STATES: '{{n}} Selected states',
	SELECTED_VEHICLE_TYPES: '{{n}} Selected vehicles types',
	SELECTED_VEHICLES: '{{n}} Selected vehicles',
	MAKE_DEFAULT: 'Make default',
	ON: 'On',
	OFF: 'Off',
	ON_OFF: 'On and off',
	SUCCESS_DEFAULT_FILTER: 'Default filter successfully set',
	SUCCESS_DEFAULT_FILTER_UNSET: 'Default filter successfully unset',
	ERROR_DEFAULT_FILTER: 'Error at set default filter',
	SUCCESS_APPLY_FILTER: 'Successfully apply filter',
	HISTORY: 'History',
	REAL_TIME: 'Real Time',
	FROM: 'From:',
	TO: 'To:',
	SELECT_RANGE: 'Select a range',
	NO_HISTORY: 'This vehicle do not have history',
	HISTORY_ERROR: 'History Error',
	CANCEL: 'Cancel',
	DISTANCE: 'Distance',
	DURATION: 'Duration',
	SHOW_POSITIONS: 'Show Positions:',
	SHOW_TRACKERS: 'Show Trackers:',
	IGNITION: 'Ignition',
	SATELLITE: 'Satellite',
	OPERATOR: 'Operator',
	BATTERY: 'Voltage',
	IDLE: 'Idle',
	MOVING: 'Moving',
	NO_COMM: 'No Comm',
	WRONG_POSITION: 'Position Error',
	TOWED: 'Towed',
	CONNECTED: 'Connected:',
	TRACKER: 'Tracker:',
	DEVICE: 'Device:',
	DATE: 'Date:',
	TIME: 'Time:',
	SIGNAL: 'Signal',
	IDLE_N: 'Idle <i>({{n}})</i>',
	MOVING_N: 'Moving <i>({{n}})</i>',
	NO_COMM_N: 'No Comm <i>({{n}})</i>',
	WRONG_POSITION_N: 'Position Error <i>({{n}})</i>',
	TOWED_N: 'Towed <i>({{n}})</i>',
	BLOCKED_QUANTITY: 'Blocked <i>({{n}})</i>',
	CLOSE: 'Close',
	GEOFENCE_LOAD_ERROR: 'Error on get geofences',
	FAST_HISTORY: 'Quick History',
	ADVANCED_HISTORY: 'Advanced History',
	START_DATE_MUST_BE_BEFORE_END_DATE: 'Start date must be before end date',
	FOLLOW_VEHICLE: 'Follow Vehicle',
	CANNOT_GET_TRIP: 'Cannot get trip data',
	ROUTE_POINT_BY_POINT: 'Point to point route',
	ROUTERIZED_ROUTE: 'Routerized route',
	EVENT_TYPE: 'Event',
	GPS_TIME: 'Time',
	ALTITUDE: 'Altitude',
	BEARING: 'Bearing',
	SATELLITES: 'Satellites',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	ADDRESS_CITY: 'City',
	ADDRESS_COUNTRY: 'Country',
	ADDRESS_POSTCODE: 'Postcode',
	ADDRESS_ROAD: 'Road',
	ADDRESS_STATE: 'State',
	ADDRESS_SUBURB: 'Suburb',
	HOURMETER: 'Hourmeter',
	MILEAGE: 'Mileage',
	YES: 'Yes',
	NO: 'No',
	CLEAR: 'Clear',
	VIEW_TRACKER: 'View Tracker',
	VIEW_VEHICLE: 'View vehicle',
	ADDRESS: 'Address',
	STATUS: 'Status',
	UNAVAILABLE: 'Unavailable',
	LOCATION: 'Location',
	VOLTAGE: 'Voltage',
	LAST_POSITION: 'Last Position',
	TRACKER_PROBLEM: 'Click here to show your defective trackers',
	CHANGE_MAP: 'Change Map',
	WITHOUT_VEHICLE: 'Without Vehicle',
	WITHOUT_VEHICLE_N: 'Without Vehicle <i>({{n}})</i>',
	NO_FILTERS: 'You do not have filters',
	BLOCK: 'Block',
	UNBLOCK: 'Unblock',
	BLOCK_TRACKER: 'Block tracker',
	BLOCKED: 'Blocked',
	BLOCK_TRACKER_SEND_SUCCESS: 'Block command sent successfully, please wait for confirmation.',
	BLOCK_TRACKER_SEND_ERROR: 'Error sending block command.',
	UNBLOCK_TRACKER_SEND_ERROR: 'Error sending unblock command.',
	UNBLOCK_TRACKER_SEND_SUCCESS: 'Unblock command sent successfully, please wait for confirmation.',
	BLOCK_TRACKER_CONFIRMATION: 'Confirm block',
	BLOCK_TRACKER_CONFIRMATION_TEXT: 'You are about to temporarily block this vehicle.',
	BLOCK_TRACKER_CONFIRMATION_TEXT_WARNING: 'To proceed, click "Confirm" on the button below.',
	UNBLOCK_TRACKER_CONFIRMATION: 'Confirm unblock',
	UNBLOCK_TRACKER_CONFIRMATION_TEXT: 'You are about to unblock this vehicle.',
	UNBLOCK_TRACKER_CONFIRMATION_TEXT_WARNING: 'To proceed, click "Confirm" on the button below.',
	CONFIRM: 'Confirm',
	ACTIONS: 'Actions',
	TO_GOOGLE_MAPS: 'See on Google Maps',
	COPY_INFO: 'Copy Info',
	WAITING: 'Waiting',
	BLOCK_STATUS: 'Block Status',

	PENDING: 'Pending',
	QUEUED: 'Queued',
	'RE-QUEUED': 'Re-queued',
	SENT: 'Sent',
	CONFIRMED: 'Confirmed',
	CANCELED: 'Canceled',
	ERROR: 'Error',
	EXPIRED: 'Expired',
	SCHEDULING: 'Scheduling',

	BLOCK_COMMAND_WARNING:
		'This vehicle has a pending {{commandName}} command sent on: {{blockTime}}, are you sure you want to make a new request?',
	WRONG_POSITION_POPUP_TEXT:
		'The tracker is in the wrong position and will not be available on the map. Check if the tracker was installed correctly or contact support.',

	DRIVER: 'Driver',
	GROUP_TRACKERS: 'Group Trackers',
	SHOW_TRACKERS_NAME: 'Show Trackers Name',
	SHOW_TRACKERS_GROUP_COUNT: 'Show Trackers Group Count',
	FILTER: 'Filter ',
	SHOW_GEO_FENCE: 'Show Geo Fence',
	ROUTERIZED_DISTANCE: 'Routerized Distance',

	START: 'Start',
	END: 'End',

	SENT_AT: 'Sent at',
	RECEIVED_AT: 'Received at',
	LAST_GPS_TIME: 'Last GPS Time',
	LAST_SERVER_TIME: 'Last Server Time',
	VIEW_ANALYTIC_REPORT: 'View Analytic Report',

	UNKNOWN: 'Unknown',
	PUBLIC_LINK: 'Public link',
	CREATE_PUBLIC_LINK: 'Create Public Link',
	'24_HOURS': '24 Hours',
	'48_HOURS': '48 Hours',
	'72_HOURS': '72 Hours',
	CREATE: 'Create',
	PUBLIC_LINK_ERROR: 'Error creating public link',
	PUBLIC_LINK_INFO:
		'This public link will allow temporary access to the vehicle information. Choose the duration of the link validity below. After creation, you will be redirected to the public link page.',
	...commonEnTranslates
};

export default locale;
