import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import themesConfig from 'app/configs/themesConfig';
import storageService from 'app/services/storage';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import { selectUser } from 'app/store/user/userSlice';
import i18next from 'i18next';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'src/app/auth/Auth';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import userHasPermission from 'src/utils/useHasPermission';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import CustomThemeDialog from './CustomThemeDialog';
import { DevelopmentModal } from './development/DevelopmentModal';

const en = {
	THEMES: 'Themes',
	RESET_TO_MASTER: 'Reset to master Account',
	LOGOUT: 'Sign Out',
	DEVELOPMENT: 'Development'
};

const pt = {
	THEMES: 'Temas',
	RESET_TO_MASTER: 'Sair para a conta principal',
	LOGOUT: 'Sair',
	DEVELOPMENT: 'Desenvolvimento'
};

const sp = {
	THEMES: 'Temas',
	RESET_TO_MASTER: 'Salir para la cuenta principal',
	LOGOUT: 'Salir',
	DEVELOPMENT: 'Desarrollo'
};

i18next.addResourceBundle('en', 'userMenu', en);
i18next.addResourceBundle('pt', 'userMenu', pt);
i18next.addResourceBundle('sp', 'userMenu', sp);

function UserMenu() {
	const dispatch = useAppDispatch();
	const user = useSelector(selectUser);
	const theme = useTheme();
	const { t } = useTranslation('userMenu');
	const { signOut } = useContext(AuthContext);
	const [masterToken, setMasterToken] = useLocalStorage('masterAccountToken', undefined);
	const [userMenu, setUserMenu] = useState(null);
	const [ThemeMenu, setThemeMenu] = useState(null);
	const displayName = _.get(user, 'data.displayName', '');
	const photoUrl = _.get(user, 'data.photoURL', null);

	const isAdmin = userHasPermission(SYSTEM_ADMIN_PERMISSION);

	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};

	const themeMenuClick = (event) => {
		setThemeMenu(event.currentTarget);
	};

	const themeMenuClose = () => {
		setUserMenu(null);
		setThemeMenu(null);
	};

	const userMenuClose = () => setUserMenu(null);

	const resetToMasterAccount = () => {
		storageService.set('token', masterToken);
		setMasterToken('');
		window.location.reload();
	};

	const handleDevelopment = () => {
		dispatch(
			openDialog({
				children: <DevelopmentModal />
			})
		);
	};

	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick} color="inherit">
				<div className="hidden md:flex flex-col mx-4 my-auto items-end">
					<Typography component="span" className="font-semibold flex">
						{displayName}
					</Typography>
				</div>

				{photoUrl ? (
					<Avatar className="md:mx-4 w-36 h-36" alt="user photo" src={photoUrl} />
				) : (
					<Avatar className="md:mx-4 w-36 h-36">{displayName[0]}</Avatar>
				)}
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				<MenuItem role="button" onClick={themeMenuClick}>
					<ListItemIcon className="min-w-40">
						<FuseSvgIcon>heroicons-outline:color-swatch</FuseSvgIcon>
					</ListItemIcon>
					<ListItemText primary={t('THEMES')} />
				</MenuItem>

				<CustomThemeDialog
					open={Boolean(ThemeMenu)}
					onClose={themeMenuClose}
					themesConfig={themesConfig}
					theme={theme}
					dispatch={dispatch}
					t={t}
					shemesHandlers
				/>

				{masterToken ? (
					<MenuItem component={NavLink} to="/sign-in" onClick={resetToMasterAccount} role="button">
						<ListItemIcon className="min-w-40">
							<FuseSvgIcon color="warning">heroicons-outline:login</FuseSvgIcon>
						</ListItemIcon>
						<ListItemText primary={t('RESET_TO_MASTER')} />
					</MenuItem>
				) : (
					<MenuItem
						component={NavLink}
						to="/sign-in"
						onClick={() => {
							signOut();
							userMenuClose();
						}}
						role="button"
					>
						<ListItemIcon className="min-w-40">
							<FuseSvgIcon color="warning">heroicons-outline:login</FuseSvgIcon>
						</ListItemIcon>
						<ListItemText primary={t('LOGOUT')} />
					</MenuItem>
				)}

				{isAdmin && (
					<MenuItem onClick={handleDevelopment} role="button">
						<ListItemIcon className="min-w-40">
							<FuseSvgIcon color="secondary">material-outline:dns</FuseSvgIcon>
						</ListItemIcon>
						<ListItemText primary={t('DEVELOPMENT')} />
					</MenuItem>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
