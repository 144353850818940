import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const ContactsPage = lazyRetry(() => import('./Contacts'));

i18next.addResourceBundle('en', 'contactsPage', en);
i18next.addResourceBundle('sp', 'contactsPage', sp);
i18next.addResourceBundle('pt', 'contactsPage', pt);

export const CONTACTS_PERMISSIONS = {
	edit: 'admin.contact.edit',
	view: 'admin.contact.view',
	list: 'admin.contact.list',
	new: 'admin.contact.new',
	delete: 'admin.contact.delete',
	minimal: []
};

const ContactsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.contact.list'],
			path: 'admin/contacts',
			element: (
				<LazyLoaderSuspense>
					<ContactsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<ContactsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default ContactsConfig;
