import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import { selectCompanyLogo } from 'app/store/company/companySettingsSlice';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import NavbarToggleButton from '../../../../shared-components/NavbarToggleButton';
import Navigation from '../../../../shared-components/Navigation';
import _ from 'lodash';

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	'& ::-webkit-scrollbar-thumb': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
		}`
	},
	'& ::-webkit-scrollbar-thumb:active': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
		}`
	}
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
	overscrollBehavior: 'contain',
	overflowX: 'hidden',
	overflowY: 'auto',
	WebkitOverflowScrolling: 'touch',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 40px, 100% 10px',
	backgroundAttachment: 'local, scroll'
}));

function NavbarStyle1Content(props: any) {
	const theme = useSelector(selectCompanyLogo);
	return (
		<Root className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}>
			<div className="flex flex-row items-center shrink-0 justify-between h-48 md:h-72 px-20 mb-10">
				<img
					src={_.get(theme, 'logoLight200x40', '/assets/images/logo/mar.png')}
					alt="logo"
					className="w-auto max-h-36 mt-16"
				/>
				<NavbarToggleButton className="w-40 h-40" />
			</div>

			<StyledContent
				className="flex flex-1 flex-col min-h-0"
				option={{ suppressScrollX: true, wheelPropagation: false }}
			>
				<Navigation layout="vertical" />
			</StyledContent>
		</Root>
	);
}

export default memo(NavbarStyle1Content);
