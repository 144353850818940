import FusePageSimple from '@fuse/core/FusePageSimple/FusePageSimple';
import usePermission from 'app/services/hooks/usePermission';
import NoPermission from 'app/shared-components/NoPermission';
import Content from './components/Content';
import Header from './components/Header';
import { MONITORING_PERMISSIONS } from './MonitoringConfig';
import { useTranslation } from 'react-i18next';

export default function Monitoring() {
	const permissions = usePermission({ pagePermissions: MONITORING_PERMISSIONS });
	const { t } = useTranslation('monitoringPage');
	return (
		<FusePageSimple
			header={permissions.hasList && <Header />}
			content={
				permissions.hasList ? <Content /> : <NoPermission imageSrc="/assets/images/bg/table_blur.png" t={t} />
			}
			scroll="content"
		/>
	);
}
