import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/vehicle/tableColumns';
import { TSidebarMode, TVehicle } from 'app/main/vehicle/types';
import storageService from 'app/services/storage';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TVehicle[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	visibleColumns: string[];
	isRegisterDrawerOpen: boolean;
	currentRegisterDrawerForm: string;
};
const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view',
	visibleColumns: storageService.get('vehiclesVisibleColumns') || defaultVisibleColumns,
	isRegisterDrawerOpen: false,
	currentRegisterDrawerForm: 'tracker'
};

const crudSlice = createSlice({
	name: 'vehiclesCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TVehicle[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			storageService.set('vehiclesVisibleColumns', action.payload);
			state.visibleColumns = action.payload;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		},
		toggleRegisterDrawer: (state) => {
			state.isRegisterDrawerOpen = !state.isRegisterDrawerOpen;
		},
		setCurrentRegisterDrawerForm: (state, action: PayloadAction<string>) => {
			state.currentRegisterDrawerForm = action.payload;
		}
	}
});

export const {
	setSelectedRows,
	setIsSidebarOpen,
	setSidebarMode,
	setVisibleColumns,
	resetSidebarState,
	toggleRegisterDrawer,
	setCurrentRegisterDrawerForm
} = crudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.vehiclesCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.vehiclesCrud.sidebarMode;
export const selectSelectedRows = (state: RootState) => state.vehiclesCrud.selectedRows;
export const selectVisibleColumns = (state: RootState) => state.vehiclesCrud.visibleColumns;
export const selectIsRegisterDrawerOpen = (state: RootState) => state.vehiclesCrud.isRegisterDrawerOpen;
export const selectCurrentRegisterDrawerForm = (state: RootState) => state.vehiclesCrud.currentRegisterDrawerForm;

export default crudSlice.reducer;
