const locale = {
	
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	
	USERS: 'Usuários',
	USER: 'Usuário',
	PASSWORD: 'Senha',
	CPASSWORD: 'Confirmar senha',
	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts acessíveis',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	NAME: 'Nome',
	CODE: 'Código',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar Curso',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando Curso',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar este Curso?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar os rastreadors selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar os cursos, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_USER: 'Adicionar reastreador',
	CREATE_SUCCESS_COURSE: 'Curso adicionado com sucesso',
	CREATE_ERROR_COURSE: 'Erro ao adicionar curso',
	EDIT_SUCCESS_COURSE: 'Curso atualizado com sucesso',
	EDIT_ERROR_COURSE: 'Erro ao atualizar curso',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS_COURSE: 'Curso deletado com sucesso',
	DELETE_ERROR_COURSE: 'Erro ao deletar curso',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de cursos',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar cursos',
	NO_PERMISSION_edit: 'Você não possui permissão para editar cursos',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar cursos',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar cursos',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'Email não encontrado',
	NO_BIRTHDAY: 'Data de nascimento não encontrada',
	LOADING: 'Carregando',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
	PASSWORD_MIN_LENGTH: 'A senha deve ter no mínimo 8 caracteres',
	PASSWORDS_MUST_MATCH: 'As senhas devem ser iguais',
	PASSWORD_MIN_LOWERCASE: 'A senha deve ter no mínimo 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'A senha deve ter no mínimo 1 letra maiúscula',
	PASSWORD_MIN_NUMBERS: 'A senha deve ter no mínimo 1 número',
	PASSWORD_MIN_SYMBOLS: 'A senha deve ter no mínimo 1 caractére especial',
	MORE_THAN: 'mais que',
	NO_USER_FOUND: 'Nenhum curso foi encontrado',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} cursos?',
	EDIT_INFO: 'Editar curso',
	DELETE_INFO: 'Deletar curso',
	DOWNLOAD_INFO: 'Baixar cursos selecionados',
	EDIT_MULTIPLE_INFO_COURSE: 'Editar cursos selecionados',
	DELETE_MULTIPLE_INFO_COURSE: 'Deletar cursos selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar cursos',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar Senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS_COURSE: 'Cursos editados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar cursos',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem meno que 8MB',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	PLATE_INFO: 'Dependendo do tipo de curso, a placa pode não ser obrigatória.',

	MORE_INFO: 'Ver mais informações',

	// Header

	DOWNLOAD_ALL: 'Baixar todos',

	// Sidebar


	SELECT_COMPANY_FIRST: 'Selecione uma empresa primeiro',
	LESSON_TITLE: 'Título da Aula',
	COURSE_TITLE: 'Título do Curso',
	DESCRIPTION: 'Descrição',
	CATEGORY: 'Categoria',
	CATEGORY_NAME: 'Nome da Categoria',
	CONTINUE: 'Continuar',
	LESSONS: 'Aulas',
	SELECTED_LESSONS: '{{n}} Aulas selecionadas',
	SELECTED_CATEGORIES: '{{n}} Categorias selecionados',
	ADD_COURSE: 'Criar Curso',
	SEARCH_COURSE_LABEL: 'Pesquisar Curso',
	ALL: 'Todos',
	NO_COURSE_FOUND: 'Nenhum curso foi encontrado',
	DELETE_COURSE_MODAL_TITLE: 'Deletar Curso?',
	DELETE_COURSE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este curso?',
	BACK_TO_COURSES: 'Voltar para cursos',
	BACK: 'Voltar',
	NEXT: 'Próximo',
	CREATE_SUCCESS_LESSON: 'Aula adicionada com sucesso',
	EDIT_SUCCESS_LESSON: 'Aula atualizada com sucesso',
	DELETE_SUCCESS_LESSON: 'Aula deletada com sucesso',
	EDIT_MULTIPLE_SUCCESS_LESSON: 'Aulas editadas com sucesso',
	CREATE_ERRROR_LESSON: 'Erro ao adicionar aula',
	EDIT_ERROR_LESSON: 'Erro ao atualizar aula',
	DELETE_ERROR_LESSON: 'Erro ao deletar aula',
	EDIT_MULTIPLE_ERROR_LESSON: 'Erro ao editar aulas',
	CATEGORY_NAME_REQUIRED: 'O campo Nome da Categoria é obrigatório',
	TITLE_REQUIRED: 'O campo Título é obrigatório',
	VIDEO_REQUIRED: 'O campo Vídeo é obrigatório',
	CATEGORY_REQUIRED: 'O campo Categoria é obrigatório',
	LESSONS_REQUIRED: 'O campo Aulas é obrigatório',
	NAME_REQUIRED: 'O campo Nome é obrigatório',
	COURSE_TITLE_REQUIRED: 'O campo Título do Curso é obrigatório',
	SEARCH_LESSON_LABEL: 'Pesquisar Aula',
	ADD_LESSON: 'Adicionar Aula',
	WATCH_LESSON: 'Assistir Aula',
	DELETE_LESSON_MODAL_TITLE: 'Deletar Aula?',
	DELETE_LESSON_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta aula?',
	COURSES: 'Cursos',
	COURSES_HEADER_DESCRIPTION:'Trilhas de conhecimento para você conhecer melhor nossos produtos e serviços.',
	LESSONS_HEADER_DESCRIPTION:'Todas Aulas Disponíveis para você.',
	BACK_TO_LESSONS: 'Voltar para Aulas',



};

export default locale;
