import { TTracker } from 'app/main/map/types';
import { AtedBy } from 'app/main/permissions/types';
import { apiSlice } from './apiSlice';

export interface CreatePublicLinkPayload {
	vehicleId: string;
	expirationDate: string;
}

export interface GetPublicVehicleResponse {
	vehicle: TTracker;
	settings: {
		createdBy: AtedBy;
		expirationDate: string;
	};
}

export const publicLinkSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getPublicVehicle: builder.query<GetPublicVehicleResponse, string>({
			query: (token: string) => `/public-link/vehicle/${token}`
		}),
		createPublicLink: builder.mutation<{ token: string }, CreatePublicLinkPayload>({
			query: (body) => ({
				url: '/public-link',
				method: 'POST',
				body
			})
		})
	})
});

export const { useGetPublicVehicleQuery, useCreatePublicLinkMutation } = publicLinkSlice;
