import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { TEditMultipleMonitoringEventPayload, TMonitoringEvent } from 'app/main/monitoring-event/types';
import { apiSlice } from './apiSlice';
import { IApiPagination, TPaginatedQuery } from './types';

export function getStringQuery(paginatedData: TPaginatedQuery, route: string) {
	const queryArgs = {
		page: `${paginatedData.page || 1}`,
		limit: `${paginatedData.limit || 200}`,
		search: paginatedData.search || null,
		searchKey: paginatedData.searchKey || null,
		sortKey: paginatedData.sortKey || null,
		sortValue: paginatedData.sortValue || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${route}?${query.toString()}`;
}

const baseUrl = '/tracker-events';

export const monitoringEventsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllMonitoringEvents: builder.query<IApiPagination<TMonitoringEvent>, TPaginatedQuery>({
			query: (paginatedData: TPaginatedQuery) => ({
				url: getStringQuery(paginatedData, baseUrl),
			}),
			providesTags: ['MonitoringEvents']
		}),
		getMonitoringEvent: builder.query({
			query: (id: string) => `${baseUrl}/${id}`,
			providesTags: ['MonitoringEvents']
		}),
		updateMonitoringEvent: builder.mutation({
			query: (data) => ({
				url: `${baseUrl}/${data.id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['MonitoringEvents']
		}),
		updateMultipleMonitoringEvents: builder.mutation<string, TEditMultipleMonitoringEventPayload>({
			query: (data) => ({
				url: `${baseUrl}/list?ids=${data.ids}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['MonitoringEvents']
		}),
		createMonitoringEvent: builder.mutation<string, TMonitoringEvent>({
			query: (data) => ({
				url: baseUrl,
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['MonitoringEvents']
		}),
		deleteMonitoringEvents: builder.mutation({
			query: (ids: string) => ({
				url: `${baseUrl}/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['MonitoringEvents']
		})
	})
});

export const {
	useGetAllMonitoringEventsQuery,
	useUpdateMonitoringEventMutation,
	useDeleteMonitoringEventsMutation,
	useCreateMonitoringEventMutation,
	useUpdateMultipleMonitoringEventsMutation,
	useLazyGetAllMonitoringEventsQuery
} = monitoringEventsSlice;
