const config = {
	title: 'Layout 2 - Enterprise',
	defaults: {
		mode: 'container',
		navbar: {
			display: true,
			style: 'fixed'
		},
		toolbar: {
			display: true,
			style: 'static',
			position: 'below'
		},
		leftSidePanel: {
			display: true
		},
		rightSidePanel: {
			display: true
		}
	},
	form: {}
};

export default config;
