import { THIRTY_ONE_DAY_ERROR_MESSAGE_SP } from 'src/constants';
import { commonSpTranslates } from 'src/constants/common-translates';

const locale = {
	// Columns
	PLATE: 'Placa',
	DID: 'DID',
	ADDRESS: 'Dirección',
	ODOMETER: 'Odómetro',
	GPS_TIME: 'Hora GPS',
	EVENT_TIME: 'Hora del Evento',
	SPEED: 'Velocidad',
	IGNITION_ON: 'Encendido de la Ignición',
	IS_GPS_RUNNING: 'GPS Activo',
	GOOGLE_MAPS: 'Google Maps',
	SEQUENCE: 'Secuencia',
	PARSER: 'Parser',
	ALTITUDE: 'Altitud',
	LATITUDE: 'Latitud',
	LONGITUDE: 'Longitud',
	HOURS_NUM: '{{n}} Horas',
	UNKNOWN: 'Desconocido',
	VEHICLE_VOLTAGE: 'Voltaje del Vehículo',
	SERVER_TIME: 'Hora del Servidor',
	CLEAR_FILTERS: 'Limpiar filtros',

	// Header

	TITLE: 'Reporte de Eventos',
	ADD: 'Agregar',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabla',
	THIRTY_DAY_ERROR: THIRTY_ONE_DAY_ERROR_MESSAGE_SP,
	SIX_MONTHS_AGO_INFO: commonSpTranslates.SIX_MONTHS_AGO_INFO,

	// Table

	NO_HISTORY_FOUND: 'No se encontró ningún evento',
	NO_FILTERS: 'No se aplicaron filtros',
	NO_FILTERS_SUBTITLE: 'Seleccione los filtros que desea aplicar para ver los resultados',
	ROWS_PER_PAGE: 'Elementos por página',
	OF: 'de',

	// Commons

	COLUMNS: 'Columnas',
	INFO_TRACKER: 'Información del rastreador',
	DETAILS: 'Detalles',
	COORDINATES: 'Coordenadas',
	RXLEV: 'Intensidad de Señal',
	SATELLITES: 'Satélites',
	GPS_FIXED: 'GPS Fijo',
	BATTERY: 'Batería',
	HOURMETER: 'Horómetro',
	SELECT_FILTERS: 'Seleccionar filtros',
	PAGE_NOT_ALLOWED: 'No tiene permiso para acceder a esta pantalla.',
	MAX_POINTS: 'Se excedió el número máximo de puntos, utilizando los primeros 24 elementos',
	SEARCH: 'Buscar',
	VIEW_ON_MAP: 'Ver en Google Maps',
	MAX_TIME_INTERVAL: 'El intervalo máximo es de 12 horas',
	POSITION: 'Posición',
	SELECTED_ITEMS: 'Elementos seleccionados',
	TRACKER: 'Rastreador',
	NO_FILTER_PERMISSION: 'No tiene permiso para utilizar filtros.',
	NO_FILTER_PERMISSION_DESCRIPTION:
		'Póngase en contacto con el administrador para solicitar los siguientes permisos:',
	VEHICLE: 'Vehículo',
	LAT: 'Latitud',
	LNG: 'Longitud',
	ALT: 'Altitud',
	TRIP: 'Viaje nº{{n}}',
	STREE_VIEW_WARNING_1: 'Ha seleccionado más de 24 puntos.',
	STREET_VIEW_WARNING_2:
		'Las coordenadas se agruparon por {{n}} viajes, ordenados por fecha y hora. Puede seleccionar un viaje para ver los puntos en el mapa.',
	CLOSE: 'Cerrar',
	DRIVER: 'Conductor',

	// Filter

	TRACKERS: 'Rastreadores',
	FLEETS: 'Flotas',
	EVENT_TYPE: 'Tipo de Evento',
	END_DATE: 'Fecha de Fin',
	START_DATE: 'Fecha de Inicio',
	FLEETS_SELECTED: '{{n}} flotas',
	VEHICLES_SELECTED: '{{n}} vehículos',
	TRACKERS_SELECTED: '{{n}} rastreadores',
	EVENT_TYPES_SELECTED: '{{n}} tipos de eventos',
	APPLY: 'Aplicar',
	RESET: 'Limpiar filtros',

	// Validations

	END_REQUIRED: 'Se requiere fecha de fin',
	START_REQUIRED: 'Se requiere fecha de inicio',
	COMPANIES_REQUIRED: 'Seleccione una empresa',
	AT_LEAST_ONE_FILTER: 'Seleccione al menos otro filtro',
	END_DATE_SHALL_BE_GREATER: 'La fecha de fin debe ser mayor que la fecha de inicio',
	NO_RESULTS: 'No se encontraron resultados',

	// Download
	DOWNLOAD_SELECTED: 'Descargar seleccionados',
	DOWNLOAD_ALL: 'Descargar todos',
	DOWNLOAD: 'Descargar',
	Download_WARNING: 'Debido al gran número de archivos, la descarga puede tardar unos minutos',
	ERROR_DOWNLOADING_HISTORY: 'Error al descargar eventos',
	UNAVAILABLE: 'Indisponible',

	// Excel

	RUNNING: 'En ejecución',
	NOT_RUNNING: 'No en ejecución',
	ON: 'Encendido',
	OFF: 'Apagado',
	YES: 'Si',
	NO: 'No',

	...commonSpTranslates
};

export default locale;
