import _ from 'lodash';
import GroupedItem from './GroupedItem';
import SingleItem from './SingleItem';
import { TGroupedAlarms, TMonitoringAlert } from '../../types';

export default function VirtualListItem(_index: number, data: TMonitoringAlert | TGroupedAlarms) {
	return _.has(data, 'members') ? (
		<GroupedItem data={data as TGroupedAlarms} />
	) : (
		<SingleItem data={data as TMonitoringAlert} />
	);
}
