import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const MonitoringOperator = lazyRetry(() => import('./MonitoringOperator'));

i18next.addResourceBundle('en', 'monitoringOperatorPage', en);
i18next.addResourceBundle('sp', 'monitoringOperatorPage', sp);
i18next.addResourceBundle('pt', 'monitoringOperatorPage', pt);

export const PAGE_PERMISSIONS = {
	edit: undefined,
	view: 'reports.operator.view',
	list: undefined,
	new: undefined,
	delete: undefined,
	minimal: ['admin.user.list']
};

const PermissionsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['reports.operator.view'],
			path: 'reports/monitoring-operator',
			element: (
				<LazyLoaderSuspense>
					<MonitoringOperator />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default PermissionsConfig;
