import { Box } from '@mui/material';
import { MouseEvent, ReactNode } from 'react';

type TActionBarBtn = {
	selected?: boolean;
	handleClick: (_ev: MouseEvent<HTMLButtonElement>) => void;
	children: ReactNode;
};

export default function ActionBarBtn({ children, handleClick, selected = false }: TActionBarBtn) {
	return (
		<Box
			sx={{
				width: '29px',
				height: '29px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				backgroundColor: '#fff',
				outline: 'none',
				filter: selected ? 'brightness(85%)' : 'none',
				svg: {
					fill: '#000'
				},
				'&:hover': {
					filter: 'brightness(95%)'
				}
			}}
			component="button"
			onClick={handleClick}
		>
			{children}
		</Box>
	);
}
