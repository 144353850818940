/* eslint-disable no-unused-expressions */
import history from '@history';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { selectCompanyLogo } from 'app/store/company/companySettingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { api } from 'src/app/services/api/api';
import { showPopUpMsg } from 'src/utils/utils';
/**
 * Form Validation Schema
 */

const defaultValues = {
	email: ''
};

function RecoverPasswordPage() {
	const { t } = useTranslation('RecoverPasswordPage');
	const companyTheme = useSelector(selectCompanyLogo);

	const schema = yup.object().shape({
		email: yup.string().email(t('ERROR_EMAIL_INVALID')).required(t('ERROR_EMAIL_REQUIRED'))
	});

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const {
		control,
		formState: { errors },
		handleSubmit
	} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	function onSubmit({ email }) {
		setLoading(true);
		api.post('/auth/recover', { email })
			.then(() => {
				dispatch(showPopUpMsg(t('SUCCESS_SEND_EMAIL'), 'success'));
			})
			.catch(() => {
				dispatch(showPopUpMsg(t('ERROR_SEND_EMAIL'), 'warning'));
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div className="flex flex-col sm:flex-row items-center md:items-center sm:justify-center md:justify-start flex-1 min-w-0 max-h-full overflow-y-hidden">
			<Paper className="h-full items-center flex sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 pt-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-400 sm:w-400 mx-auto sm:mx-0">
					<img
						className="w-full"
						src={_.get(companyTheme, 'logoUrl', '/assets/images/logo/mar.png')}
						alt="logo"
					/>

					<Typography className="mt-[48px] mb-[32px] font-semibold text-center" fontSize="24px" variant="h4">
						{t('FORGOT_PASSWORD')}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary" className="mb-[32px] text-center">
						{t('RECOVER_PASSWORD_DESCRIPTION')}
					</Typography>

					<form
						name="loginForm"
						noValidate
						className="flex flex-col justify-center w-full"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Controller
							name="email"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-[32px]"
									label="Email"
									type="email"
									error={!!errors.email}
									helperText={_.get(errors, 'email.message', ' ')}
									variant="outlined"
									required
									fullWidth
								/>
							)}
						/>

						<LoadingButton
							variant="contained"
							color="secondary"
							className="w-full rounded-md h-[53px] mb-[32px]"
							aria-label="Sign in"
							type="submit"
							loading={loading}
							size="large"
						>
							{t('SEND_EMAIL')}
						</LoadingButton>

						<Link className="font-medium" to="/sign-in">
							{'< '}
							{t('BACK')}
						</Link>
					</form>
				</div>
			</Paper>

			<Box
				className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 overflow-hidden md:w-1/2"
				sx={{ backgroundColor: 'primary.main' }}
			>
				<svg
					className="absolute inset-0 pointer-events-none"
					viewBox="0 0 960 540"
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMax slice"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Box
						component="g"
						sx={{ color: 'primary.light' }}
						className="opacity-20"
						fill="none"
						stroke="currentColor"
						strokeWidth="100"
					>
						<circle r="234" cx="196" cy="23" />
						<circle r="234" cx="790" cy="491" />
					</Box>
				</svg>
				<Box
					component="svg"
					className="absolute -top-64 -right-64 opacity-20"
					sx={{ color: 'primary.light' }}
					viewBox="0 0 220 192"
					width="220px"
					height="192px"
					fill="none"
				>
					<defs>
						<pattern
							id="837c3e70-6c3a-44e6-8854-cc48c737b659"
							x="0"
							y="0"
							width="20"
							height="20"
							patternUnits="userSpaceOnUse"
						>
							<rect x="0" y="0" width="4" height="4" fill="currentColor" />
						</pattern>
					</defs>
					<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
				</Box>
			</Box>
		</div>
	);
}

const PasswordEye = ({
	showPassword,
	setShowPassword
}: {
	showPassword: boolean;
	setShowPassword: Dispatch<SetStateAction<boolean>>;
}) => (
	<InputAdornment className="bg-transparent" position="end">
		<IconButton color="secondary" onClick={() => setShowPassword(!showPassword)}>
			{showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
		</IconButton>
	</InputAdornment>
);

function ChangePasswordPage() {
	const [showPassword, setShowPassword] = useState(false);

	const { t } = useTranslation('RecoverPasswordPage');

	const schema = yup.object().shape({
		password: yup.string().required(t('ERROR_PASSWORD_REQUIRED')).min(6, t('ERROR_PASSWORD_MIN')),

		secondPassword: yup
			.string()
			.required(t('ERROR_PASSWORD_REQUIRED'))
			.min(6, t('ERROR_PASSWORD_MIN'))
			.oneOf([yup.ref('password'), null], t('ERROR_PASSWORD_NOT_MATCH'))
	});

	const { control, formState, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues: {
			password: '',
			secondPassword: ''
		},
		resolver: yupResolver(schema)
	});

	const { isValid, dirtyFields, errors } = formState;

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const { id } = useParams<{ id: string }>();

	useEffect(() => {
		api.get(`/auth/recover/${id}`)
			.catch((error) => {
				history.push('/sign-in');
			})
			.finally(() => {
				setLoading(false);
			});
	}, [dispatch, id, t]);

	function onSubmit({ password, secondPassword }: any) {
		setLoading(true);
		api.put(`/auth/recover/${id}`, {
			password,
			confirmPassword: secondPassword
		})
			.then(() => {
				dispatch(showMessage({ message: t('SUCCESS_CHANGE_PASSWORD'), variant: 'success' }));
				history.push('/sign-in');
			})
			.catch(() => dispatch(showMessage({ message: t('ERROR_CHANGE_PASSWORD'), variant: 'error' })))
			.finally(() => setLoading(false));
	}

	const companyTheme = useSelector(selectCompanyLogo);
	return (
		<div className="flex flex-col sm:flex-row items-center md:items-center sm:justify-center md:justify-start flex-1 min-w-0 max-h-full overflow-y-hidden">
			<Paper className="h-full items-center flex sm:h-auto md:flex md:items-center md:justify-center w-full sm:w-auto md:h-full md:w-1/2 pt-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-400 sm:w-400 mx-auto sm:mx-0">
					<img
						className="w-full"
						src={_.get(companyTheme, 'logoUrl', '/assets/images/logo/mar.png')}
						alt="logo"
					/>

					<Typography className="mt-[48px] mb-[32px] font-semibold text-center" fontSize="24px" variant="h4">
						{t('RECOVER_PASSWORD')}
					</Typography>

					<form
						name="loginForm"
						noValidate
						className="flex flex-col justify-center w-full"
						onSubmit={handleSubmit(onSubmit)}
					>
						<Controller
							name="password"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-[32px]"
									label={t('PASSWORD')}
									type={showPassword ? 'text' : 'password'}
									error={!!errors.password}
									helperText={errors?.password?.message}
									InputProps={{
										endAdornment: (
											<PasswordEye
												showPassword={showPassword}
												setShowPassword={setShowPassword}
											/>
										)
									}}
								/>
							)}
						/>

						<Controller
							name="secondPassword"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									className="mb-[32px]"
									label={t('CONFIRM_PASSWORD')}
									type={showPassword ? 'text' : 'password'}
									error={!!errors.secondPassword}
									helperText={errors?.secondPassword?.message}
									InputProps={{
										endAdornment: (
											<PasswordEye
												showPassword={showPassword}
												setShowPassword={setShowPassword}
											/>
										)
									}}
								/>
							)}
						/>

						<LoadingButton
							variant="contained"
							color="secondary"
							className="w-full rounded-md h-[53px] mb-[32px]"
							aria-label="Sign in"
							disabled={_.isEmpty(dirtyFields) || !isValid}
							type="submit"
							loading={loading}
							size="large"
						>
							{t('SEND')}
						</LoadingButton>

						<Link className="font-medium" to="/sign-in">
							{'< '}
							{t('BACK')}
						</Link>
					</form>
				</div>
			</Paper>

			<Box
				className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 overflow-hidden md:w-1/2"
				sx={{ backgroundColor: 'primary.main' }}
			>
				<svg
					className="absolute inset-0 pointer-events-none"
					viewBox="0 0 960 540"
					width="100%"
					height="100%"
					preserveAspectRatio="xMidYMax slice"
					xmlns="http://www.w3.org/2000/svg"
				>
					<Box
						component="g"
						sx={{ color: 'primary.light' }}
						className="opacity-20"
						fill="none"
						stroke="currentColor"
						strokeWidth="100"
					>
						<circle r="234" cx="196" cy="23" />
						<circle r="234" cx="790" cy="491" />
					</Box>
				</svg>
				<Box
					component="svg"
					className="absolute -top-64 -right-64 opacity-20"
					sx={{ color: 'primary.light' }}
					viewBox="0 0 220 192"
					width="220px"
					height="192px"
					fill="none"
				>
					<defs>
						<pattern
							id="837c3e70-6c3a-44e6-8854-cc48c737b659"
							x="0"
							y="0"
							width="20"
							height="20"
							patternUnits="userSpaceOnUse"
						>
							<rect x="0" y="0" width="4" height="4" fill="currentColor" />
						</pattern>
					</defs>
					<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
				</Box>
			</Box>
		</div>
	);
}

export { ChangePasswordPage, RecoverPasswordPage };
