const locale = {
	MONITORING: 'Supervisión',
	OPENED_OCCURRENCES_N: 'ocurrencias abiertas: {{n}}',
	ONGOING_OCCURRENCES_N: 'ocurrencias en curso: {{n}}',
	FINALIZE: 'Finalizar',
	GO_ON: 'Avanzar',
	FILTERS: 'Filtros',
	CANCEL: 'Cancelar',
	APPLY: 'Aplicar',
	'E.IGN_ON': 'Encendido conectado',
	'E.IGN_OFF': 'Encendido apagado',
	'E.DIN_CHANGED': 'Entrada activada',
	'E.EXT_POWER_CUT_OFF': 'Sin batería',
	'E.PANIC': 'Pánico',
	'E.LOW_BATTERY_VOLTAGE': 'Batería baja',
	'E.SHIFT_WARNING': 'Chilló',
	'E.GPS_ANTENNA_OPEN': 'GPS externo conectado',
	'E.GPS_ANTENNA_SHORT': 'GPS externo apagado',
	'E.EXT_POWER_RECONNECTED': 'Batería reconectada',
	'E.UNDER_SPEED': 'Baja velocidad',
	'E.OVER_SPEED': 'Alta velocidad',
	'E.INTO_FENCE': 'Entró en la cerca',
	'E.OUT_FENCE': 'Se salió de la cerca',
	'E.EXTERNAL_BATTERY_LOW': 'Batería baja',
	'E.DRIVER_OFF': 'Conductor apagado',
	'E.DRIVER_ON': 'Conductor encendido',
	FINALIZE_OCC: '¿Está seguro de que desea finalizar este evento?<br> Esta acción no se puede deshacer.',
	FINALIZE_OCC_TITLE: 'Finalizar ocurrencia',
	OPERATED_BY: 'Operado por:',
	OPEN: 'Abierto',
	ON_GOING: 'En proceso',
	GROUPED_N: '{{n}} agrupados',
	FINALIZE_MODAL_TITLE: 'Finalizar',
	FINALIZE_MODAL_DESCRIPTION: 'Finalizar ocurrencia',
	FINALIZE_MODAL_MULTIPLE_DESCRIPTION: 'Finalizar varias ocurrencia',
	FINALIZE_MODAL_WARNING: 'Esta operación no se puede deshacer.',
	FINALIZE_MODAL_BRIEF: 'Finalizar ocurrencia',
	CONFIRM: 'Confirmar',
	FINISHED_EVENTS: 'Eventos finalizados',
	SUCCESS_ONGOING: 'Alerta movida con éxito',
	ERROR_ONGOING: 'Alerta de error al mover',
	SUCCESS_DONE: 'Alerta completada con éxito,',
	ERROR_DONE: 'Alerta de finalización de error',
	NO_FILTER: 'Ningun filtro',
	OPERATOR: 'Operador',
	GO_REALTIME: 'Ver en el mapa',
	TRACKER: 'Rastreador',
	SEVERITY: 'Severidad',
	TIMING: 'Tiempo',
	LOW_SEVERITY: 'Mostrar baja severidad',
	MEDIUM_SEVERITY: 'Mostrar media severidad',
	HIGH_SEVERITY: 'Mostrar alta severidad',
	LAST_HOUR: 'Última hora',
	LAST_N_HOURS: 'Últimas {{n}} horas',
	CLEAR_ALL: 'Limpiar filtros',
	SELECT_FILTERS: 'Seleccionar filtros',
	EVENT_TYPE: 'Evento',
	GPS_TIME: 'Hora',
	ALTITUDE: 'Altitud',
	BEARING: 'Rodamiento',
	SATELLITES: 'Satélites',
	LATITUDE: 'Latitud',
	LONGITUDE: 'Longitud',
	ADDRESS_CITY: 'Ciudad',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_POSTCODE: 'Código postal',
	ADDRESS_ROAD: 'Carretera',
	ADDRESS_STATE: 'Estado',
	ADDRESS_SUBURB: 'Suburbio',
	HOURMETER: 'Contador de horas',
	MILEAGE: 'Kilometraje',
	YES: 'Si',
	NO: 'No',
	CLEAR: 'Limpiar',
	DETAILS: 'Detalles',
	ALARM: 'Alarma',
	VEHICLE: 'Vehículo',
	USER: 'Usuario',
	GROUPS: 'Grupos',
	COMPANY: 'Empresa',
	STATUS: 'Estado',
	SPEED: 'Velocidad',
	GPS_FIXED: 'Gps Fijo',
	IGNITION_ON: 'Encendido',
	IGNITION_OFF: 'Apagado',
	BATTERY: 'Batería',
	EV_TYPE: 'Tipo de Evento',
	EV_CODE: 'Código de Evento',
	SELECTED_N: '{{n}} Seleccionado',
	GROUP_BY: 'Agrupados por',
	MAP_VIEW: 'Ver en el mapa',
	GOOGLE_VIEW: 'Ver en Google View',
	GO_ON_ERROR: 'Error ao atualizar status de alerta',
	GO_ON_SUCCESS_N: '{{n}} Alerta(s) atualizados',
	FINALIZE_ERROR: 'Error ao finalizar alerta(s)',
	FINALIZE_SUCCESS_N: '{{n}} Alerta(s) finalizados',
	TO_GOOGLE_MAPS: 'Ver en Google Maps'
};

export default locale;
