const locale = {
	// Columns

	SCHEDULED_COMMAND_NAME: 'Nome do Comando Agendado',
	NEXT_EXECUTION: 'Próxima Execução',
	TARGET_COMPANIES: 'Empresas Destino',
	TARGET_FLEETS: 'Frotas Destino',
	TARGET_VEHICLES: 'Veículos Destino',
	TARGET_TRACKERS: 'Rastreadores Destino',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	UPDATED_AT: 'Atualizado em',
	STATUS: 'Estado',
	SCHEDULE_COMMAND: 'Agendar Comando',

	// Table

	ROWS_PER_PAGE: 'Linhas por Página',
	OF: 'de',
	NO_SCHEDULED_FOUND: 'Nenhum comando agendado encontrado',

	// Permissions

		PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
		MINIMAL_START: 'As seguintes permissões estão faltando:',
		MINIMAL_END: 'Por favor, entre em contato com o seu administrador',

	// Header

	SCHEDULE: 'Agendar',
	EDIT_SCHEDULE_COMMAND: 'Editar Comando Agendado',
	TITLE: 'Comandos Agendados',

	// Status

	PENDING: 'Pendente',
	QUEUED: 'Agendado',
	'RE-QUEUED': 'Reenfileirado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Erro',
	EXPIRED: 'Expirado',
	SCHEDULING: 'Agendando',

	// Common

	TARGETS: 'Destinatário(s)',
	SCHEDULED_FOR: 'Agendado para',
	NOT_SCHEDULED: 'Imediatamente',
	SCHEDULED_COMMAND: 'Comando Agendado',
	EDIT: 'Editar',
	DELETE: 'Excluir',
	SUMMARY: 'Resumo',
	COMPANY: 'Empresa',
	FLEET: 'Frota',
	VEHICLE: 'Veículo',
	TRACKER: 'Rastreador',
	CLICK_HERE_TO_EXPAND: 'Clique aqui para expandir',
	SUMMARY_ERROR: 'Erro ao carregar o resumo',
	DELETE_MODAL_TITLE: 'Excluir Comando Agendado',
	DELETE_MODAL_DESCRIPTION: 'Tem certeza de que deseja excluir este comando agendado?',
	DELETE_MODAL_WARNING: 'Esta ação não pode ser desfeita.',
	CONFIRM: 'Confirmar',
	COMMAND: 'Comando',
	COMMAND_NAME: 'Nome',
	COMMAND_DESCRIPTION: 'Descrição',
	COMMAND_PARSER: 'Protocolo',
	VALUES: 'Valores',

	// Requireds

	SCHEDULED_COMMAND_NAME_REQUIRED: 'O nome do comando agendado é obrigatório',
	TRACKER_TYPE_REQUIRED: 'O tipo de rastreador é obrigatório',
	COMMAND_REQUIRED: 'O comando é obrigatório',
	TARGETS_REQUIRED: 'Selecione pelo menos um destinatário para o comando.',

	// Form
	CANCEL: 'Cancelar',
	CONTINUE: 'Continuar',
	FINISH: 'Finalizar',
	SENT_COMMAND: 'Comando Enviado',
	SENT_TO: 'Enviado para',
	CLOSE: 'Fechar',
	BACK: 'Voltar',
	SUCCESS: 'Sucesso',

	FREQUENCY: 'Frequência',
	DATA: 'Dados',
	REVIEW: 'Revisar',
	SEND_TO: 'Enviar para',

	SELECT_TRACKER_AND_COMMAND_HEADER:
		'Escolha um nome para a programação, selecione o tipo de rastreador e o comando a ser executado.',
	SELECT_TRACKER_TYPE: 'Selecionar tipo de rastreador',
	SELECT_COMMAND: 'Selecionar comando',
	DEFAULT_COMMANDS: 'Comandos Padrão do Rastreador',
	COMPANY_COMMANDS: 'Comandos da Empresa',
	USER_COMMANDS: 'Comandos do Usuário',
	NO_COMMANDS: 'Nenhum comando encontrado para o tipo de rastreador {{parser}}.',
	SEND_SMS: 'Enviar SMS',

	SELECT_TARGETS_HEADER: 'Selecione os destinatários para os quais deseja enviar o comando.',
	FLEETS_LIMIT_TEXT: '{{n}} frotas selecionadas',
	COMPANIES_LIMIT_TEXT: '{{n}} empresas selecionadas',
	VEHICLES_LIMIT_TEXT: '{{n}} veículos selecionados',
	TRACKERS_LIMIT_TEXT: '{{n}} rastreadores selecionados',

	SELECT_FREQUENCY_HEADER: 'Selecione a frequência com a qual o comando será enviado.',
	YOUR_COMMAND_WILL_BE_SENT: 'Seu comando será enviado:',

	REVIEW_YOUR_SCHEDULE: 'Revise sua programação antes de finalizar.',
	THE_COMMAND_WILL_BE_SENT: 'O comando será enviado a cada {{frequency}}',
	IMMEDIATELY: 'O comando será enviado imediatamente',

	// Cron
	MINUTE: 'Minuto',
	EVERY_MINUTE: 'A cada minuto',
	EVERY_MINUTE_EVERY: 'A cada {{every}} minutos',
	AT_MINUTE_BETWEEN: 'No minuto {{start}} até o minuto {{end}}',
	AT_MINUTE_EVERY: 'No minuto {{start}} a cada {{every}} minutos',
	AT_MINUTE_EVERY_BETWEEN: 'A cada {{every}} minutos, de {{start}} até {{end}}.',
	AT_MINUTE: 'No minuto {{value}}',

	HOUR: 'Hora',
	EVERY_HOUR: 'A cada hora',
	EVERY_HOUR_EVERY: 'A cada {{every}} horas',
	AT_HOUR_BETWEEN: 'Na hora {{start}} até a hora {{end}}',
	AT_HOUR_EVERY: 'Na hora {{start}} a cada {{every}} horas',
	AT_HOUR_EVERY_BETWEEN: 'A cada {{every}} horas, de {{start}} até {{end}}.',
	AT_HOUR: 'Na hora {{value}}',

	DAY: 'Dia',
	EVERY_DAY: 'Todos os dias',
	EVERY_DAY_EVERY: 'A cada {{every}} dias',
	AT_DAY_BETWEEN: 'No dia {{start}} até o dia {{end}}',
	AT_DAY_EVERY: 'No dia {{start}} a cada {{every}} dias',
	AT_DAY_EVERY_BETWEEN: 'A cada {{every}} dias, de {{start}} até {{end}}.',
	AT_DAY: 'No dia {{value}}',
	DAY_OF_WEEK: 'Dia da semana',
	EVERY_DAY_OF_WEEK: 'Todos os dias da semana',
	AT_DAY_OF_WEEK_BETWEEN: 'Na semana {{start}} até a semana {{end}}',
	AT_DAY_OF_WEEK_EVERY: 'Na semana {{start}} a cada {{every}} semanas',
	AT_DAY_OF_WEEK: 'Todos os {{value}}',

	MONTH: 'Mês',
	EVERY_MONTH: 'Todo mês',
	AT_MONTH_BETWEEN: 'No mês {{start}} até o mês {{end}}',
	AT_MONTH_EVERY: 'No mês {{start}} a cada {{every}} meses',
	AT_MONTH: 'No mês {{value}}',

	// Request Messages

	SUCCESS_MESSAGE: 'O comando {{command}} foi agendado com sucesso!',
	CREATE_SCHEDULE_COMMAND_ERROR: 'Erro ao criar o comando agendado',
	CREATE_SCHEDULE_COMMAND_SUCCESS: 'Comando agendado criado com sucesso!',
	EDIT_SCHEDULE_COMMAND_ERROR: 'Erro ao editar o comando agendado',
	EDIT_SCHEDULE_COMMAND_SUCCESS: 'Comando agendado editado com sucesso!',

	// Filters
	SELECT_FILTERS: 'Selecionar filtros',
	FILTERS: 'Filtros',
	CREATED_BY_ME: 'Agendados por mim',
	CLEAR_FILTERS: 'Limpar filtros',
	FROM_STATUS: 'Do Estado',
	TO_STATUS: 'Para o Estado',
	START_DATE: 'Data Inicial',
	END_DATE: 'Data Final'
};

export default locale;
