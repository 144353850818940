import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/monitoring-done/tableColumns';
import { generalFormValidationSchema } from 'app/main/monitoring-settings/components/Modal/DialogForm/formValidationSchema';
import { TMonitoringAlert } from 'app/main/monitoring/types';
import storageService from 'app/services/storage';
import { InferType } from 'yup';
import { RootState } from '..';

export type TFormData = Partial<InferType<typeof generalFormValidationSchema>>;
type TInitialState = {
	isSidebarOpen: boolean;
	selectedRows: TMonitoringAlert[];
	sidebarMode: string;
	visibleColumns: string[];
};

export const initialState: TInitialState = {
	isSidebarOpen: false,
	selectedRows: [],
	sidebarMode: '',
	visibleColumns: storageService.get('monitoringDoneVisibleColumns') || defaultVisibleColumns
};

const monitoringDoneSlice = createSlice({
	name: 'monitoringDone',
	initialState,
	reducers: {
		setSidebar: (state, { payload }: { payload: boolean }) => {
			state.isSidebarOpen = payload;
		},
		setSidebarMode: (state, { payload }: { payload: string }) => {
			state.sidebarMode = payload;
			state.isSidebarOpen = true;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('monitoringDoneVisibleColumns', action.payload);
		},
		setSelectedRows: (state, { payload }: { payload: TMonitoringAlert[] }) => {
			state.selectedRows = payload;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSidebar, setSelectedRows, setSidebarMode, resetSidebarState, setVisibleColumns } = monitoringDoneSlice.actions;

export const selectSidebar = (state: RootState) => state.monitoringDone.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.monitoringDone.sidebarMode as string;
export const selectSelectedRows = (state: RootState) => state.monitoringDone.selectedRows as TMonitoringAlert[];
export const selectVisibleColumns = (state: RootState) => state.monitoringDone.visibleColumns as string[];

export default monitoringDoneSlice.reducer;
