import { apiSlice } from './apiSlice';
export type ILessonPayload = {
	id?: string;
	title: string;
	description?: string;
	category?: string[];
	url: string;
};
const lessonSlice = apiSlice.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		getLessons: query({
			query: () => ({
				url: '/lesson/',
				method: 'GET'
			}),
			providesTags: ['Lessons']
		}),
		deleteLessons: mutation({
			query: (id: string) => ({
				url: `/lesson/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Lessons']
		}),

		editLessons: mutation({
			query: (lessons) => ({
				url: `/lesson/${lessons.id}`,
				method: 'PUT',
				body: lessons
			}),
			invalidatesTags: ['Lessons']
		}),

		getLesson: query({
			query: (id: string) => ({
				url: `/lesson/${id}`,
				method: 'GET'
			}),
			providesTags: ['Lessons']
		}),
		createLessons: mutation<string, ILessonPayload>({
			query: (lessons) => ({
				url: '/lesson',
				method: 'POST',
				body: lessons
			}),
			invalidatesTags: ['Lessons']
		}),
		uploadLessonVideo: mutation({
			query: (formData: FormData) => ({
				url: '/upload/videos/lessons',
				method: 'POST',
				body: formData
			})
		})
	})
});

export const {
	useGetLessonsQuery,
	useGetLessonQuery,
	useCreateLessonsMutation,
	useEditLessonsMutation,
	useDeleteLessonsMutation,
	useUploadLessonVideoMutation
} = lessonSlice;
