const locale = {
	// Colunas

	SCHEDULED_COMMAND_NAME: 'Scheduled Command Name',
	NEXT_EXECUTION: 'Next Execution',
	TARGET_COMPANIES: 'Target Companies',
	TARGET_FLEETS: 'Target Fleets',
	TARGET_VEHICLES: 'Target Vehicles',
	TARGET_TRACKERS: 'Target Trackers',
	CREATED_AT: 'Created At',
	CREATED_BY: 'Created By',
	UPDATED_AT: 'Updated At',
	STATUS: 'Status',
	SCHEDULE_COMMAND: 'Schedule Command',

	// Table

	ROWS_PER_PAGE: 'Rows per Page',
	OF: 'of',
	NO_SCHEDULED_FOUND: 'No scheduled commands found',

	// Permissions

	PAGE_NOT_ALLOWED: 'You are not allowed to view this page.',
	MINIMAL_START: 'Request the Administrator to add the following permissions to your profile:',
	MINIMAL_END: 'Then log out and log back into the system.',

	// Header

	SCHEDULE: 'Schedule',
	TITLE: 'Scheduled Commands',
	EDIT_SCHEDULE_COMMAND: 'Edit Scheduled Command',
	

	// Status

	PENDING: 'Pending',
	QUEUED: 'Queued',
	'RE-QUEUED': 'Re-queued',
	SENT: 'Sent',
	CONFIRMED: 'Confirmed',
	CANCELED: 'Canceled',
	ERROR: 'Error',
	EXPIRED: 'Expired',
	SCHEDULING: 'Scheduling',

	// Common

	TARGETS: 'Targets',
	SCHEDULED_FOR: 'Scheduled For',
	NOT_SCHEDULED: 'Immediately',
	SCHEDULED_COMMAND: 'Scheduled Command',
	EDIT: 'Edit',
	DELETE: 'Delete',
	SUMMARY: 'Summary',
	COMPANY: 'Company',
	FLEET: 'Fleet',
	VEHICLE: 'Vehicle',
	TRACKER: 'Tracker',
	CLICK_HERE_TO_EXPAND: 'Click here to expand',
	SUMMARY_ERROR: 'Error loading summary',
	DELETE_MODAL_TITLE: 'Delete Scheduled Command',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this scheduled command?',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	CONFIRM: 'Confirm',
	COMMAND: 'Command',
	COMMAND_NAME: 'Name',
	COMMAND_DESCRIPTION: 'Description',
	COMMAND_PARSER: 'Parser',
	VALUES: 'Values',

	// Requireds

	SCHEDULED_COMMAND_NAME_REQUIRED: 'Scheduled command name is required',
	TRACKER_TYPE_REQUIRED: 'Tracker type is required',
	COMMAND_REQUIRED: 'Command is required',
	TARGETS_REQUIRED: 'Select at least one target for the command.',

	// Form
	CANCEL: 'Cancel',
	CONTINUE: 'Continue',
	FINISH: 'Finish',
	SENT_COMMAND: 'Sent Command',
	SENT_TO: 'Sent to',
	CLOSE: 'Close',
	BACK: 'Back',

	FREQUENCY: 'Frequency',
	DATA: 'Data',
	REVIEW: 'Review',
	SEND_TO: 'Send to',

	SELECT_TRACKER_AND_COMMAND_HEADER:
		'Choose a name for the schedule, select the tracker type, and the command to be executed.',
	SELECT_TRACKER_TYPE: 'Select tracker type',
	SELECT_COMMAND: 'Select command',
	DEFAULT_COMMANDS: 'Default Tracker Commands',
	COMPANY_COMMANDS: 'Company Commands',
	USER_COMMANDS: 'User Commands',
	NO_COMMANDS: 'No commands found for tracker type {{parser}}.',
	SEND_SMS: 'Send SMS',

	SELECT_TARGETS_HEADER: 'Select the recipients to whom you want to send the command.',
	FLEETS_LIMIT_TEXT: '{{n}} fleets selected',
	COMPANIES_LIMIT_TEXT: '{{n}} companies selected',
	VEHICLES_LIMIT_TEXT: '{{n}} vehicles selected',
	TRACKERS_LIMIT_TEXT: '{{n}} trackers selected',

	SELECT_FREQUENCY_HEADER: 'Select the frequency at which the command will be sent.',
	YOUR_COMMAND_WILL_BE_SENT: 'Your command will be sent:',

	REVIEW_YOUR_SCHEDULE: 'Review your schedule before finishing.',
	THE_COMMAND_WILL_BE_SENT: 'The command will be sent every {{frequency}}',
	IMMEDIATELY: 'The command will be sent immediately',

	// Cron
	MINUTE: 'Minute',
	EVERY_MINUTE: 'Every minute',
	EVERY_MINUTE_EVERY: 'Every {{every}} minutes',
	AT_MINUTE_BETWEEN: 'At minute {{start}} to minute {{end}}',
	AT_MINUTE_EVERY: 'At minute {{start}} every {{every}} minutes',
	AT_MINUTE_EVERY_BETWEEN: 'Every {{every}} minutes, from {{start}} to {{end}}.',
	AT_MINUTE: 'At minute {{value}}',

	HOUR: 'Hour',
	EVERY_HOUR: 'Every hour',
	EVERY_HOUR_EVERY: 'Every {{every}} hours',
	AT_HOUR_BETWEEN: 'At hour {{start}} to hour {{end}}',
	AT_HOUR_EVERY: 'At hour {{start}} every {{every}} hours',
	AT_HOUR_EVERY_BETWEEN: 'Every {{every}} hours, from {{start}} to {{end}}.',
	AT_HOUR: 'At hour {{value}}',

	DAY: 'Day',
	EVERY_DAY: 'Every day',
	EVERY_DAY_EVERY: 'Every {{every}} days',
	AT_DAY_BETWEEN: 'At day {{start}} to day {{end}}',
	AT_DAY_EVERY: 'At day {{start}} every {{every}} days',
	AT_DAY_EVERY_BETWEEN: 'Every {{every}} days, from {{start}} to {{end}}.',
	AT_DAY: 'At day {{value}}',
	DAY_OF_WEEK: 'Day of the week',
	EVERY_DAY_OF_WEEK: 'Every day of the week',
	AT_DAY_OF_WEEK_BETWEEN: 'At week {{start}} to week {{end}}',
	AT_DAY_OF_WEEK_EVERY: 'At week {{start}} every {{every}} weeks',
	AT_DAY_OF_WEEK: 'Every {{value}}',

	MONTH: 'Month',
	EVERY_MONTH: 'Every month',
	AT_MONTH_BETWEEN: 'At month {{start}} to month {{end}}',
	AT_MONTH_EVERY: 'At month {{start}} every {{every}} months',
	AT_MONTH: 'At month {{value}}',

	// Request Messages

	SUCCESS_MESSAGE: 'The command {{command}} has been scheduled successfully!',
	EDIT_SCHEDULE_COMMAND_ERROR: 'Error editing scheduled command',
	CREATE_SCHEDULE_COMMAND_ERROR: 'Error creating scheduled command',
	EDIT_SCHEDULE_COMMAND_SUCCESS: 'Scheduled command edited successfully!',
	CREATE_SCHEDULE_COMMAND_SUCCESS: 'Scheduled command created successfully!',

	//Filters
	SELECT_FILTERS: 'Select filters',
	FILTERS: 'Filters',
	CREATED_BY_ME: 'Scheduled by me',
	CLEAR_FILTERS: 'Clear filters',
	FROM_STATUS: 'From State',
	TO_STATUS: 'To State',
	START_DATE: 'Start Date',
	END_DATE: 'End Date'
};

export default locale;
