import { PureAbility } from '@casl/ability';
import { createCanBoundTo } from '@casl/react';
import { defineCompanyAbility } from './company';
import { defineDriverAbility } from './drivers';
import { defineFleetAbility } from './fleets';
import { defineFuelSupplyAbility } from './fuel-supply';
import { defineGeoFenceAbility } from './geo-fence';
import { defineIButtonAbility } from './ibutton';
import { defineIntegrationsAbility } from './integrations-page';
import { defineRulerNotificationAbility } from './notification-ruler';
import { defineRulerNotificationHistoryAbility } from './notification-ruler-history';
import { defineBlockCommandHistoryAbility } from './reports/blockHistory';
import { defineEventsReportAbility } from './reports/events';
import { defineTripsReportAbility } from './reports/trips';
import { defineScheduledCommandHistoryAbility } from './scheduled-command-history';
import {  defineSimcardAbility } from './simcards';
import { defineTrackerAbility } from './trackers';
import { AppAbility } from './types';
import { defineUserAbility } from './users';
import { defineVehicleAbility } from './vehicles';
import { definePlateAbility } from './plate';
import { defineHistoryAbility } from './reports/history';
import { defineCommandAbility } from './commands';

export const getUserAbility = () => {
	// RULES
	const [notificationRulerAbility] = defineRulerNotificationAbility();
	const [notificationRulerHistoryAbility] = defineRulerNotificationHistoryAbility();

	// GEOFENCE
	const [geoFenceAbility] = defineGeoFenceAbility();

	// FLEET
	const [driverAbility] = defineDriverAbility();
	const [simcardAbility] = defineSimcardAbility();
	const [vehiceAbility] = defineVehicleAbility();
	const [plateAbility] = definePlateAbility();
	const [fleetAbility] = defineFleetAbility();
	const [trackerAbility] = defineTrackerAbility();
	const [userAbility] = defineUserAbility();
	const [ibuttonAbility] = defineIButtonAbility();
	//const [monitoringDoneAbility] = defineMonitoringDoneAbility();
	// FUEL SUPPLY
	const [fuelSupplyAbility] = defineFuelSupplyAbility();

	// SCHEDULED COMMAND HISTORY
	const [scheduledCommandHistoryAbility] = defineScheduledCommandHistoryAbility();

	const [companyAbility] = defineCompanyAbility();

	// REPORTS
	const [blockCommandHistory] = defineBlockCommandHistoryAbility();
	const [tripsReport] = defineTripsReportAbility();
	const [eventsReport] = defineEventsReportAbility();
	const [history] = defineHistoryAbility();

	// INTEGRATIONS
	const [integrations] = defineIntegrationsAbility();

	const [commands] = defineCommandAbility();
	const ability = new PureAbility<AppAbility>([
		...notificationRulerAbility.rules,
		...notificationRulerHistoryAbility.rules,
		...driverAbility.rules,
		...geoFenceAbility.rules,
		...simcardAbility.rules,
		...fuelSupplyAbility.rules,
		...plateAbility.rules,
		...vehiceAbility.rules,
		...fleetAbility.rules,
		...trackerAbility.rules,
		...userAbility.rules,
		...ibuttonAbility.rules,
		...companyAbility.rules,
		...scheduledCommandHistoryAbility.rules,
		...blockCommandHistory.rules,
		...userAbility.rules,
		//...monitoringDoneAbility.rules,
		...eventsReport.rules,
		...tripsReport.rules,
		...history.rules,
		...integrations.rules,
		...commands.rules
	]);

	return ability;
};

export const Can = createCanBoundTo(getUserAbility());
