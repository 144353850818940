import _ from '@lodash';
import { SearchOutlined, TuneOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { TUsePermissionReturn } from 'app/services/hooks/usePermission';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { SearchBar } from 'app/shared-components/CRUDs/Header/SearchInput';
import { setIsSidebarOpen, setSidebarMode } from 'app/store/crud/coursesSlice';
import { useAppDispatch } from 'app/store/index';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'react-i18next';
import CourseCard from '../courses/CourseCard';
import { TCategory } from '../types/types';
import LessonCard from './lessons/LessonCard';

interface AcademyTabComponentProps {
	content: any;
	categories: TCategory[];
	t: TFunction;
	contentType: 'lesson' | 'course';
	permissions: TUsePermissionReturn;
}

function AcademyTabComponent({ content, categories, t, contentType, permissions }: AcademyTabComponentProps) {
	const dispatch = useAppDispatch();

	const handleAddClick = () => {
		dispatch(setSidebarMode('create'));
		dispatch(setIsSidebarOpen(true));
	};

	const [filteredData, setFilteredData] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('all');
	const hideCompleted = false;

	const getFilteredArray = useCallback(() => {
		const isAllCategory = selectedCategory === 'all';

		if (_.isEmpty(searchText) && isAllCategory && !hideCompleted) {
			return content;
		}

		const filteredData = _.filter(content, (item) => {
			const isCompleted = item.progress.completed > 0;
			const haveCategory = item.category.some((cat) => cat.name === selectedCategory);

			if (hideCompleted && isCompleted) {
				return false;
			}

			if (haveCategory) {
				return item.title.toLowerCase().includes(searchText.toLowerCase());
			}

			return false;
		});

		return filteredData;
	}, [content, hideCompleted, searchText, selectedCategory]);

	useEffect(() => {
		if (_.isEmpty(content)) return;
		setFilteredData(getFilteredArray());
	}, [content, getFilteredArray]);

	function handleSelectedCategory(event) {
		setSelectedCategory(event.target.value);
	}

	function handleSearchText(event) {
		setSearchText(event.target.value);
	}

	return (
		<div className="flex flex-col flex-1 w-full mx-auto px-24 pt-24 sm:p-40">
			<div className="flex flex-col shrink-0 sm:flex-row items-center justify-between space-y-16 sm:space-y-0">
				<div className="flex flex-col sm:flex-row w-full sm:w-auto items-center space-y-16 sm:space-y-0 sm:space-x-16">
					<FormControl className="flex w-full sm:w-136" variant="outlined">
						<InputLabel id="category-select-label">{t('CATEGORY')}</InputLabel>
						<Select
							labelId="category-select-label"
							id="category-select"
							label={t('CATEGORY')}
							value={selectedCategory}
							onChange={handleSelectedCategory}
						>
							<MenuItem value="all">
								<em> {t('ALL')} </em>
							</MenuItem>
							{categories.map((category) => (
								<MenuItem value={category.name} key={category._id}>
									{category.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<SearchBar
						placeholder={t('SEARCH_LESSON_LABEL')}
						className="flex w-full sm:w-256 mx-8"
						value={searchText}
						inputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchOutlined color="secondary" />
								</InputAdornment>
							),
							startAdornment: (
								<Tooltip title={t('FILTER')} color="secondary">
									<IconButton color="secondary" size="small">
										<TuneOutlined />
									</IconButton>
								</Tooltip>
							)
						}}
						onChange={handleSearchText}
					/>
				</div>
				{permissions.hasNew ? (
					<Box
						sx={{
							display: 'flex',
							paddingTop: 2,
							paddingBottom: 3,
							gap: 1
						}}
					>
						<AppButton variant="contained" color="secondary" fullWidth onClick={handleAddClick}>
							{t('ADD_LESSON')}
						</AppButton>
					</Box>
				) : undefined}

				{/* TODO IMPLEMENT COMPLETED 
                    <FormControlLabel
                        label="Hide completed"
                        control={
                            <Switch
                                onChange={(ev) => {
                                    setHideCompleted(ev.target.checked);
                                }}
                                checked={hideCompleted}
                                name="hideCompleted"
                            />
                        }
                    /> */}
			</div>
			{useMemo(() => {
				const container = {
					show: {
						transition: {
							staggerChildren: 0.1
						}
					}
				};

				const item = {
					hidden: {
						opacity: 0,
						y: 20
					},
					show: {
						opacity: 1,
						y: 0
					}
				};

				return (
					filteredData &&
					(filteredData.length > 0 ? (
						<motion.div
							className="flex grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-32 mt-32 sm:mt-40"
							variants={container}
							initial="hidden"
							animate="show"
						>
							{filteredData.map((content) => {
								return (
									<motion.div variants={item} key={content.id}>
										{contentType === 'course' && (
											<CourseCard course={content} permissions={permissions} />
										)}
										{contentType === 'lesson' && (
											<LessonCard lesson={content} permissions={permissions} />
										)}
									</motion.div>
								);
							})}
						</motion.div>
					) : (
						<div className="flex flex-1 items-center justify-center">
							<Typography color="text.secondary" className="text-24 my-24">
								{t('NO_COURSE_FOUND')}
							</Typography>
						</div>
					))
				);
			}, [filteredData])}
		</div>
	);
}

export default AcademyTabComponent;
