import { FuseSettingsProps } from '@fuse/core/FuseSettings';
import {
	defaultSettings,
	defaultThemeOptions,
	extendThemeWithMixins,
	getParsedQuerySettings,
	mustHaveThemeOptions
} from '@fuse/default-settings';
import _ from '@lodash';
import { createTheme, getContrastRatio } from '@mui/material/styles';
import { Theme } from '@mui/material/styles/createTheme';
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import settingsConfig from 'app/configs/settingsConfig';
import { darkPaletteText, lightPaletteText } from 'app/configs/themesConfig';
import { AppDispatch, RootState } from 'app/store/index';
import themeLayoutConfigs from 'app/theme-layouts/themeLayoutConfigs';
import { settingsSlice as apiSettingsSlice } from '../api/settingsSlice';

function getInitialSettings() {
	const defaultLayoutStyle =
		settingsConfig.layout && settingsConfig.layout.style ? settingsConfig.layout.style : 'layout1';
	const layout = {
		style: defaultLayoutStyle,
		config: themeLayoutConfigs[defaultLayoutStyle].defaults
	};
	return _.merge({}, defaultSettings, { layout }, settingsConfig, getParsedQuerySettings());
}

export function generateSettings(_defaultSettings: FuseSettingsProps, _newSettings: FuseSettingsProps) {
	return _.merge(
		{},
		_defaultSettings,
		{ layout: { config: themeLayoutConfigs[_newSettings?.layout?.style]?.defaults } },
		_newSettings
	);
}

const initialSettings = getInitialSettings();

interface initialStateProps {
	initial: FuseSettingsProps;
	defaults: FuseSettingsProps;
	current: FuseSettingsProps;
}

const initialState: initialStateProps = {
	initial: initialSettings,
	defaults: initialSettings,
	current: initialSettings
};

export const changeFuseSettings = createAsyncThunk<
	initialStateProps,
	FuseSettingsProps,
	{ dispatch: AppDispatch; state: RootState }
>('fuse/settings/changeSettings', (newSettings, { getState, dispatch }) => {
	const { fuse } = getState();
	const { settings } = fuse;
	const defaults = generateSettings(settings.defaults, newSettings);

	return {
		...settings,
		defaults: _.merge({}, defaults),
		current: _.merge({}, defaults)
	};
});

export const changePreloadFuseTheme = createAsyncThunk<void, FuseSettingsProps['theme'], { state: RootState }>(
	'fuse/settings/changePreloadFuseTheme',
	(theme, { dispatch, getState }) => {
		const { fuse } = getState();
		const { settings } = fuse;

		const newSettings = {
			...settings.current,
			theme: {
				main: theme,
				navbar: theme,
				toolbar: theme,
				footer: theme
			}
		};

		dispatch(saveUserFuseSettings(newSettings));
	}
);

export const saveUserFuseSettings = createAsyncThunk<
	void,
	FuseSettingsProps,
	{ dispatch: AppDispatch; state: RootState }
>('fuse/settings/saveSettings', async (settings, { dispatch }) => {
	dispatch(apiSettingsSlice.endpoints.updateOrCreateUserTheme.initiate(settings));
	dispatch(changeFuseSettings(settings));
});

export const resetToCompanyFuseSettings = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
	'fuse/settings/resetToCompanySettings',
	async (_, { dispatch, getState }) => {
		const { companySettings } = getState();
		const { settings } = companySettings;
		dispatch(changeFuseSettings(settings));
	}
);

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setSettings: (state, action: PayloadAction<FuseSettingsProps>) => {
			const current = generateSettings(state.defaults, action.payload);

			return {
				...state,
				current
			};
		}
	},
	extraReducers: (builder) => {
		builder.addCase(changeFuseSettings.fulfilled, (_, action) => action.payload);
	}
});

const getDirection = (state: RootState) => state.fuse.settings.current.direction;
const getMainTheme = (state: RootState) => state.fuse.settings.current.theme.main;
const getNavbarTheme = (state: RootState) => state.fuse.settings.current.theme.navbar;
const getToolbarTheme = (state: RootState) => state.fuse.settings.current.theme.toolbar;
const getFooterTheme = (state: RootState) => state.fuse.settings.current.theme.footer;

function generateMuiTheme(theme: Theme, direction: 'ltr' | 'rtl') {
	const data = _.merge({}, defaultThemeOptions, theme, mustHaveThemeOptions);

	return createTheme(
		_.merge({}, data, {
			mixins: extendThemeWithMixins(data),
			direction
		})
	);
}

export const selectContrastMainTheme = (bgColor: string) => {
	function isDark(color: string) {
		return getContrastRatio(color, '#ffffff') >= 3;
	}
	return isDark(bgColor) ? selectMainThemeDark : selectMainThemeLight;
};

export function changeThemeMode(theme: Theme, mode: 'dark' | 'light') {
	const modes = {
		dark: {
			palette: {
				mode: 'dark',
				divider: 'rgba(241,245,249,.12)',
				background: {
					paper: '#1E2125',
					default: '#121212'
				},
				text: darkPaletteText
			}
		},
		light: {
			palette: {
				mode: 'light',
				divider: '#e2e8f0',
				background: {
					paper: '#FFFFFF',
					default: '#F7F7F7'
				},
				text: lightPaletteText
			}
		}
	};

	return _.merge({}, theme, modes[mode]);
}

export const selectMainThemeDark = createSelector([getMainTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'dark'), direction)
);

export const selectMainThemeLight = createSelector([getMainTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'light'), direction)
);

export const selectMainTheme = createSelector([getMainTheme, getDirection], (theme, direction) =>
	generateMuiTheme(theme, direction)
);

export const selectNavbarTheme = createSelector([getNavbarTheme, getDirection], (theme, direction) =>
	generateMuiTheme(theme, direction)
);

export const selectNavbarThemeDark = createSelector([getNavbarTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'dark'), direction)
);

export const selectNavbarThemeLight = createSelector([getNavbarTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'light'), direction)
);

export const selectToolbarTheme = createSelector([getToolbarTheme, getDirection], (theme, direction) =>
	generateMuiTheme(theme, direction)
);

export const selectToolbarThemeDark = createSelector([getToolbarTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'dark'), direction)
);

export const selectToolbarThemeLight = createSelector([getToolbarTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'light'), direction)
);

export const selectFooterTheme = createSelector([getFooterTheme, getDirection], (theme, direction) =>
	generateMuiTheme(theme, direction)
);

export const selectFooterThemeDark = createSelector([getFooterTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'dark'), direction)
);

export const selectFooterThemeLight = createSelector([getFooterTheme, getDirection], (theme, direction) =>
	generateMuiTheme(changeThemeMode(theme, 'light'), direction)
);

export const selectFuseCurrentSettings = (state: RootState) => state.fuse.settings.current;

export const selectFuseCurrentLayoutConfig = (state: RootState) => state.fuse.settings.current.layout.config;

export const selectFuseDefaultSettings = (state: RootState) => state.fuse.settings.defaults;

export const selectFuseThemesSettings = (state: RootState) => state.fuse.settings.themes;

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
