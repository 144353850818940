import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const ShipsPage = lazyRetry(() => import('./Ships'));

i18next.addResourceBundle('en', 'shipsPage', en);
i18next.addResourceBundle('sp', 'shipsPage', sp);
i18next.addResourceBundle('pt', 'shipsPage', pt);

export const SHIPS_PERMISSIONS = {
	edit: 'installer.ship.edit',
	view: 'installer.ship.view',
	list: 'installer.ship.list',
	new: 'installer.ship.new',
	delete: 'installer.ship.delete',
	minimal: []
};

const PermissionsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['installer.ship.list'],
			path: 'marine/ships',
			element: (
				<LazyLoaderSuspense>
					<ShipsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<ShipsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default PermissionsConfig;
