const en = {
	DAYS: 'Days',
	HOURS: 'Hours',
	MINUTES: 'Minutes',
	SECONDS: 'Seconds'
};
const pt = {
	DAYS: 'Dias',
	HOURS: 'Horas',
	MINUTES: 'Minutos',
	SECONDS: 'Segundos'
};
const es = {
	DAYS: 'Días',
	HOURS: 'Horas',
	MINUTES: 'Minutos',
	SECONDS: 'Segundos'
};

export const translate = { en, pt, es };
