const locale = {
	RECOVER_PASSWORD: 'Recover Password',
	RECOVER_PASSWORD_DESCRIPTION:
		'To recover your access, we need your email to send the recovery link. If you do not have an email, contact an administrator.',
	SEND_EMAIL: 'Send Email',
	SEND: 'Send',
	ERROR_EMAIL_INVALID: 'You must enter a valid email',
	BACK: 'Back',
	SUCCESS_SEND_EMAIL: 'An email has been sent to your email address with instructions to reset your password.',
	ERROR_SEND_EMAIL: 'Error sending email',
	ERROR_PASSWORD_REQUIRED: 'Password is required',
	ERROR_EMAIL_REQUIRED: 'Email is required',
	ERROR_PASSWORD_NOT_MATCH: 'Passwords do not match',
	ERROR_PASSWORD_MIN: 'Password must have at least 6 characters',
	PASSWORD: 'Password',
	CONFIRM_PASSWORD: 'Confirm Password',
	FORGOT_PASSWORD: 'Forgot Password?',
	SUCCESS_CHANGE_PASSWORD: 'Password changed successfully',
	ERROR_CHANGE_PASSWORD: 'Error changing password'
};

export default locale;
