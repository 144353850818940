import { MapOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';

interface ITripCardProps {
	title: string;
	onClick: () => void;
}

export default function TripCard({ onClick, title }: ITripCardProps) {
	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				width: '100%',
				padding: 1,
				border: (theme) => `1px solid ${theme.palette.divider}`,
				borderRadius: 1
			}}
		>
			<Typography>{title}</Typography>
			<IconButton onClick={onClick} size="small" color="secondary">
				<MapOutlined />
			</IconButton>
		</Stack>
	);
}
