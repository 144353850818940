import { apiSlice } from './apiSlice';

import type { LngLat } from 'react-map-gl/maplibre';
import type { INominatimResponse } from './types';

export const nominatimSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAddressByPosition: builder.query<INominatimResponse, LngLat>({
			query: (data) =>
				`/geocoder/reverse?lat=${data.lat}&lon=${data.lng}&format=geojson&addressdetails=1&extratags=1`
		})
	})
});

export const { useGetAddressByPositionQuery, useGetAddressQuery, useLazyGetAddressByPositionQuery } = nominatimSlice;
