import { yupResolver } from '@hookform/resolvers/yup';
import {
	BadgeRounded,
	Check,
	CheckCircle,
	CloudDone,
	Contacts,
	DirectionsCar,
	Email,
	LinkRounded,
	Person,
	Sync
} from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedChipSelect from 'app/shared-components/Hooked/HookedChipSelect';
import HookedSelect from 'app/shared-components/Hooked/HookedSelect';
import { useGetAllProfilesQuery } from 'app/store/api/profileSlice';
import { useGetAllUrlsQuery } from 'app/store/api/urlSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mask } from 'src/utils/CpfCnpjMask';
import * as yup from 'yup';
import {
	selectFindedAssociate,
	selectIsLoadingAssociate,
	setCreatedAssociate,
	setFindedAssociate
} from '../store/integrate-hinova';
import { useCreateAssociateMutation } from '../store/integrate-hinova-api';
import IconBox from './IconBox';
import LoadingAssociate from './LodingAssociate';
import NoContent from './NoContent';
import VehicleList from './VehicleList';

const syncSchema = yup.object({
	profile: yup
		.object({
			_id: yup.string(),
			name: yup.string()
		})
		.nullable()
		.required('PROFILE_REQUIRED'),
	hosts: yup.array().required('URL_REQUIRED')
});

type SyncSchemaFormValues = yup.InferType<typeof syncSchema>;

export const Content: React.FC = () => {
	const dispatch = useAppDispatch();
	const associate = useAppSelector(selectFindedAssociate);
	const isAssociateLoading = useAppSelector(selectIsLoadingAssociate);
	const { control, handleSubmit } = useForm<SyncSchemaFormValues>({
		resolver: yupResolver(syncSchema),
		defaultValues: {
			profile: null
		}
	});
	const { t } = useTranslation('integrationsPage');

	const [syncAssociate, { isLoading }] = useCreateAssociateMutation();

	const { profiles, isLoadingProfiles } = useGetAllProfilesQuery(
		{ page: 1, limit: 0 },
		{
			selectFromResult: ({ data, isLoading }) => ({
				profiles: data?.docs ?? [],
				isLoadingProfiles: isLoading
			})
		}
	);
	const { urls } = useGetAllUrlsQuery(
		{ page: 1, limit: 0 },
		{
			selectFromResult: ({ data, isLoading }) => ({
				urls:
					data?.docs.map((d) => {
						return {
							id: d._id,
							host: d.host
						};
					}) ?? [],
				isLoadingUrls: isLoading
			})
		}
	);

	const onSubmit = handleSubmit(async (data) => {
		const hosts = data.hosts.map((h) => h.id);
		try {
			const newUser = await syncAssociate({ ...associate, profile: data.profile._id, hosts }).unwrap();
			dispatch(setCreatedAssociate(newUser));

			dispatch(
				showMessage({
					message: t('ASSOCIATE_SYNCED'),
					variant: 'success'
				})
			);

			dispatch(setFindedAssociate({ ...associate, synced: true, company: newUser.companyId }));
		} catch (error) {
			dispatch(
				showMessage({
					message: t('ERROR_WHILE_SAVING'),
					variant: 'error'
				})
			);
		}
	});

	useEffect(() => {
		dispatch(setFindedAssociate(null));
	}, []);

	if (isAssociateLoading) {
		return <LoadingAssociate />;
	}

	if (!associate) {
		return <NoContent />;
	}

	const vehiclesCount = (associate?.veiculos?.length || 0) + (associate?.heavyVeiculos?.length || 0);
	return (
		<motion.div className="w-full" animate="show">
			<Stack
				direction={'column'}
				sx={{ width: '100%' }}
				spacing={1}
				alignItems="center"
				justifyContent="center"
				paddingY={2}
			>
				<Stack direction="row" sx={{ width: '100%' }} spacing={1} alignItems="center" justifyContent="center">
					<Typography
						sx={{
							fontSize: ['18px', '24px'],
							fontWeight: 600,
							color: (theme) => theme.palette.secondary.main
						}}
					>
						{t('FOUND_ASSOCIATE')}
					</Typography>
					<Check fontSize="large" color="secondary" />
				</Stack>

				<Stack
					direction="row"
					sx={{
						width: '100%',
						background: (theme) => theme.palette.background.paper,
						paddingX: 2,

						borderRadius: 3
					}}
					spacing={2}
					alignItems="center"
					justifyContent="center"
				>
					<Stack direction="row" className="w-full flex gap-10 py-20 items-center">
						<IconBox icon={<Person fontSize="medium" color="secondary" />} />
						<Typography sx={{ fontSize: '15px' }}>{`${associate?.nome}`}</Typography>
					</Stack>
					<Stack direction="row" className=" flex gap-10 py-20 items-center">
						<IconBox icon={<Contacts fontSize="medium" color="secondary" />} />
						<Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>
							{mask(associate?.cpf).value}
						</Typography>
					</Stack>
					<Stack direction="row" className="w-full flex gap-10 py-20 items-center">
						<IconBox icon={<Email fontSize="medium" color="secondary" />} />
						<Typography sx={{ fontSize: '15px' }} className="w-full flex gap-10 py-20 items-center">
							{`${associate?.email}`}
						</Typography>
					</Stack>
					<Stack direction="row" className="flex gap-10  py-20 items-center">
						<IconBox icon={<CloudDone fontSize="medium" color="secondary" />} />
						<Typography sx={{ fontSize: '15px' }}>{`${associate.descricao_situacao}`}</Typography>
					</Stack>
					<Stack direction="row" className="flex gap-10 py-20 items-center">
						<IconBox icon={<DirectionsCar fontSize="medium" color="secondary" />} />
						<Typography
							sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}
						>{`${vehiclesCount} Veículos`}</Typography>
					</Stack>
					<Stack direction="row" className="w-full flex gap-10  items-center">
						<IconBox icon={<BadgeRounded fontSize="medium" color="secondary" />} />
						<HookedSelect
							required
							control={control}
							label={t('PROFILE')}
							t={t}
							variant="outlined"
							name="profile"
							options={profiles}
							optionLabel="name"
							fullWidth
							noHelperText
							size="small"
							loading={isLoadingProfiles}
							isDisabled={associate?.synced}
							withTooltip
							placeholder={t('PROFILE_SELECT')}
						/>
					</Stack>
					<Stack direction="row" className="w-full flex gap-10 items-center">
						<IconBox icon={<LinkRounded fontSize="medium" color="secondary" />} />
						<HookedChipSelect
							control={control}
							label={t('HOSTS')}
							t={t}
							required
							variant="outlined"
							name="hosts"
							options={urls}
							optionLabel="host"
							groupBy="companyName"
							limitText={(n) => t('SELECTED_HOSTS', { n })}
							fullWidth
							withTooltip
							noHelperText
							isDisabled={associate?.synced}
						/>
					</Stack>
					<AppButton
						variant="contained"
						color={associate?.synced ? 'success' : 'secondary'}
						size="large"
						onClick={() => {
							!associate?.synced && onSubmit({ ...associate, ...control._formValues });
						}}
						endIcon={
							isLoading ? (
								<Sync className="animate-spin" />
							) : associate?.synced ? (
								<CheckCircle />
							) : (
								<Sync />
							)
						}
						sx={{
							minWidth: '200px'
						}}
					>
						{associate?.synced ? t('SYNCED') : t('SYNC')}
					</AppButton>
				</Stack>
				<Typography
					sx={{
						fontSize: ['16px', '18px'],
						fontWeight: 400,
						color: (theme) => theme.palette.text.secondary
					}}
				>
					{t('ASSOCIATE_DESCRIPTION')}
				</Typography>
				<Typography
					sx={{
						fontSize: ['16px', '18px'],
						fontWeight: 400,
						color: (theme) => theme.palette.text.secondary
					}}
				>
					{t('ASSOCIATE_VEHICLE_HELPER')}
				</Typography>
			</Stack>

			{associate.veiculos && (
				<>
					<Divider flexItem />{' '}
					<VehicleList t={t} disabled={!associate?.synced} vehicles={associate?.veiculos}  />{' '}
				</>
			)}

			<>
				<Divider flexItem />{' '}
				<VehicleList
					t={t}
					disabled={!associate?.synced}
					vehicles={associate?.heavyVeiculos}
					prop="heavyVeiculos"
				/>{' '}
			</>
		</motion.div>
	);
};
