import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { MapRef } from 'react-map-gl/maplibre';
import { VirtuosoHandle } from 'react-virtuoso';

import { TEventPopup } from 'app/main/map/types';
import { RootState } from 'app/store/index';
import { TGetHistoryProps } from '../api/types';

type TInitialState = {
	selectedTrackerId: string;
	isTrackersOpen: boolean;
	mapInstance: MapRef;
	selectedHistory?: TGetHistoryProps;
	isFiltersOpen: boolean;
	trackerToWatch?: string;
	trackerListInstance: VirtuosoHandle;
	eventPopup: TEventPopup;
};

const initialState: TInitialState = {
	selectedTrackerId: null,
	isTrackersOpen: window.screen.width >= 600,
	isFiltersOpen: false,
	mapInstance: null,
	selectedHistory: null,
	trackerToWatch: null,
	trackerListInstance: null,
	eventPopup: {
		open: false,
		data: null
	}
};

const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setSelectedTrackerId: (state, action) => {
			state.selectedTrackerId = action.payload;
		},
		setTrackersOpen: (state, action) => {
			if (!action.payload && state.isFiltersOpen) {
				state.isFiltersOpen = false;
			}
			state.isTrackersOpen = action.payload;
		},
		setFiltersOpen: (state, action) => {
			state.isFiltersOpen = action.payload;
		},
		setMapInstance: (state, action) => {
			state.mapInstance = action.payload;
		},
		setSelectedHistory: (state, action) => {
			state.selectedHistory = action.payload;
		},
		setTrackerToWatch: (state, action) => {
			state.trackerToWatch = action.payload;
		},
		setTrackerListInstance: (state, action) => {
			state.trackerListInstance = action.payload;
		},
		setEventPopup: (state, action: PayloadAction<TEventPopup>) => {
			state.eventPopup = action.payload;
		},
		clearEventPopup: (state) => {
			state.eventPopup = initialState.eventPopup;
		},
		clearSelectedTrackerId: (state) => {
			state.selectedTrackerId = initialState.selectedTrackerId;
		},
		setInitialState: () => initialState
	}
});

export const {
	setSelectedTrackerId,
	setFiltersOpen,
	setTrackersOpen,
	setMapInstance,
	setSelectedHistory,
	setTrackerToWatch,
	setTrackerListInstance,
	setInitialState,
	setEventPopup,
	clearEventPopup,
	clearSelectedTrackerId
} = mapSlice.actions;

export const selectSelectedTrackerId = (state: RootState) => state.map.selectedTrackerId;
export const selectIsTrackersOpen = (state: RootState) => state.map.isTrackersOpen;
export const selectIsFiltersOpen = (state: RootState) => state.map.isFiltersOpen;
export const selectMapInstance = (state: RootState) => state.map.mapInstance;
export const selectSelectedHistory = (state: RootState) => state.map.selectedHistory;
export const selectTrackerToWatch = (state: RootState) => state.map.trackerToWatch;
export const selectTrackerListInstance = (state: RootState) => state.map.trackerListInstance;
export const selectEventPopup = (state: RootState) => state.map.eventPopup;

export default mapSlice.reducer;
