import { SearchOffOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

const NoContent = () => {
	const { t } = useTranslation('integrationsPage');
	return (
		<Stack
			sx={{
				width: '100%'
			}}
			direction="column"
			spacing={1}
			alignItems="center"
			justifyContent="center"
			padding={2}
		>
			<Stack direction="row" spacing={1} alignItems="center">
				<Typography
					sx={{
						fontSize: ['18px', '24px'],
						fontWeight: 600,
						color: (theme) => theme.palette.secondary.main
					}}
				>
					{t('NO_CONTENT_TITLE')}
				</Typography>
				<SearchOffOutlined fontSize="large" color="secondary" />
			</Stack>

                <Typography
                    sx={{ fontSize: ['16px', '18px'], fontWeight: 400, color: (theme) => theme.palette.text.secondary }}
                >
                    {t('NO_CONTENT_DESCRIPTION')}
                </Typography>
		</Stack>
	);
};

export default NoContent;
