import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSidebarMode } from 'app/main/companies/types';
import { defaultVisibleColumns } from 'app/main/reports/historyNew/tableColumns';
import { THistoryPosition } from 'app/main/reports/historyNew/types';
import storageService from 'app/services/storage';

interface HistoryState {
	visibleColumns: string[];
	sideBarOpen: boolean;
	selectedRows: THistoryPosition[];
	sidebarMode: TSidebarMode;
}

const initialState: HistoryState = {
	sideBarOpen: false,
	selectedRows: [],
	visibleColumns: storageService.get('HistoryColumns') || defaultVisibleColumns,
	sidebarMode: 'view'
};

const historySlice = createSlice({
	name: 'history',
	initialState,
	reducers: {
		setSideBarOpen(state, action) {
			state.sideBarOpen = action.payload;
		},
		setSelectedRows(state, action) {
			state.selectedRows = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.sideBarOpen = true;
	},
	}
});

export const { setSideBarOpen, setSelectedRows, setSidebarMode } = historySlice.actions;

export const selectSideBarOpen = (state: { history: HistoryState }) => state.history.sideBarOpen;
export const selectSelectedRows = (state: { history: HistoryState }) => state.history.selectedRows;
export const selectSidebarMode = (state: { history: HistoryState }) => state.history.sidebarMode;


export default historySlice.reducer;
