import history from "@history";
import { Button, Icon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function PermissionNotFound() {
  const {t} = useTranslation('permissionNotFound');
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-24">
      <img src="/assets/images/ui/403.svg" alt="403" className="w-full max-w-512" />
      <Typography variant="h5" className="font-medium mb-16">
        {t('NOT_FOUND')}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        className="normal-case"
        aria-label="Back to home"
        size="large"
        onClick={() => history.push('/')}
      >
        <Icon className="mr-4">arrow_back</Icon>
        {t('BACK_TO_HOME')}
      </Button>
    </div>
  );
}

export default PermissionNotFound;
