import { openDialog } from "app/store/fuse/dialogSlice";
import dayjs from "dayjs";
import _ from "lodash";
import { EventReportSearchParamsFilters } from "../events/types";
import { MAPS_URL } from "../historyNew/helper";
import { HistorySearchParamsFilters, THistoryPosition } from "../historyNew/types";
import { TFormData } from "./filters/filterSchema";
import StreetView from "./StreetView/StreetView";


export function parseFormValuesToFilter(formValues: TFormData): HistorySearchParamsFilters | EventReportSearchParamsFilters {
	const companiesIds = formValues.company.map((item) => item._id).join(',');
	const trackerIds = formValues.tracker.map((item) => item._id).join(',');
	const vehicleIds = formValues.vehicle.map((item) => item._id).join(',');
	const eventTypesIds = formValues.eventType.map((item) => item._id).join(',');

	const { end, start } = formValues;

	return {
		...formValues,
		company: companiesIds || null,
		tracker: trackerIds || null,
		vehicle: vehicleIds || null,
		eventType: eventTypesIds || null,
		start: start?.toISOString() || null,
		end: end?.toISOString() || null
	};
}

export const handleStreetView = (selectedRows: THistoryPosition[], dispatch: any) => {
	let mapsUrl = MAPS_URL;

	const cords = _.map(
		_.sortBy(selectedRows, (item) => dayjs(item.GPS_TIME).unix()),
		(item) => ({
			lat: item.LATITUDE,
			lon: item.LONGITUDE,
			date: item.GPS_TIME
		})
	);

	const isOver24Points = _.size(cords) > 24;

	_.forEach(cords, (item, index) => {
		if (index < 24) {
			mapsUrl += `${item.lat},${item.lon}/`;
		}
	});

	if (isOver24Points) {
		dispatch(
			openDialog({
				children: <StreetView cords={cords} />
			})
		);
	} else {
		window.open(mapsUrl, '_blank');
	}
};