import {
	Autocomplete,
	AutocompleteRenderOptionState,
	CircularProgress,
	Icon,
	InputAdornment,
	styled,
	TextField,
	TextFieldProps,
	tooltipClasses,
	TooltipProps,
	Typography
} from '@mui/material';

import { Tooltip } from '@mui/material';
import _ from 'lodash';
import { SyntheticEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { TFunction } from 'react-i18next';

type THookedSelect<T> = {
	options: T[];
	optionLabel: string;
	label: string;
	placeholder?: string;
	loading?: boolean;
	limitText?: (_n: number) => string;
	fullWidth?: boolean;
	isDisabled?: boolean;
	t: TFunction;
	color?: 'primary' | 'secondary';
	size?: 'small' | 'medium';
	multiple?: boolean;
	maxListHeight?: string | number;
	optionKey?: string;
	disabled?: boolean;
	customOptionEqual?: (_option, _value) => boolean;
	infoText?: string;
	renderOption?: (
		_props: React.HTMLAttributes<HTMLLIElement>,
		_option: T,
		_state: AutocompleteRenderOptionState
	) => React.ReactNode;
	noHelperText?: boolean;
	leftAdornment?: TextFieldProps['InputProps']['endAdornment'];
	onChangeCallback?: (_val: T) => void;
	disableClearable?: boolean;
	className?: string;
	withTooltip?: boolean;
};

type TProps<F extends FieldValues, T> = UseControllerProps<F> & TextFieldProps & THookedSelect<T>;

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[1],
		fontSize: 11,
		color: theme.palette.text.primary,
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.divider}`
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.background.paper
	}
}));

export default function HookedSelect<F extends FieldValues, T>({
	options = [],
	optionLabel,
	label,
	placeholder,
	loading = false,
	fullWidth = false,
	isDisabled = false,
	limitText = undefined,
	name,
	size = 'small',
	color = 'secondary',
	t,
	control,
	multiple = false,
	required = false,
	maxListHeight = undefined,
	optionKey = '_id',
	customOptionEqual = _.isEqual,
	infoText,
	renderOption,
	noHelperText,
	leftAdornment,
	onChangeCallback,
	disableClearable,
	InputProps,
	className,
	withTooltip = false
}: TProps<F, T>) {
	const {
		field,
		formState: { errors }
	} = useController({
		name,
		control
	});

	const handleChange = (_e: SyntheticEvent, val: T) => {
		field.onChange(val);
		if (onChangeCallback) onChangeCallback(val);
	};

	const helperText = _.has(errors, name) ? t(_.get(errors, [name, 'message']) as string) : ' ';

	const renderAutocomplete = () => (
		<Autocomplete
			className={className}
			color={color}
			ChipProps={{ color }}
			multiple={multiple}
			disablePortal
			openOnFocus
			id={name}
			size={size}
			options={options}
			loading={loading}
			limitTags={0}
			renderInput={(params) => (
				<TextField
					className={className}
					{...params}
					disabled={isDisabled}
					variant="outlined"
					label={label}
					placeholder={placeholder}
					fullWidth={fullWidth}
					name={name}
					helperText={noHelperText ? undefined : helperText}
					error={_.has(errors, name)}
					InputLabelProps={{
						required
					}}
					InputProps={{
						...params.InputProps,
						...InputProps,
						startAdornment: <InputAdornment position="start">{leftAdornment}</InputAdornment>,
						endAdornment: (
							<>
								{loading ? (
									<InputAdornment position="start">
										<CircularProgress color="secondary" size={18} />
									</InputAdornment>
								) : (
									<div>{params.InputProps.endAdornment}</div>
								)}
							</>
						)
					}}
				/>
			)}
			disableClearable={disableClearable}
			value={field.value}
			fullWidth={fullWidth}
			disabled={isDisabled}
			onChange={handleChange}
			onBlur={field.onBlur}
			ref={field.ref}
			isOptionEqualToValue={customOptionEqual}
			getLimitTagsText={limitText}
			getOptionLabel={(option) => _.toString(option[optionLabel]) || option._id}
			renderOption={
				renderOption ||
				((props, option) => (
					<li {...props} key={option[optionKey] ?? option[optionLabel]}>
						{option[optionLabel]}
					</li>
				))
			}
			ListboxProps={{
				style: {
					maxHeight: maxListHeight,
					overflowY: 'auto',
					padding: 0
				}
			}}
		/>
	);

	return withTooltip ? (
		<LightTooltip
			title={
				<Typography sx={{ display: 'flex', alignItems: 'center' }}>
					<Icon sx={{ color: 'warning.main', mr: 1 }}>info_outlined</Icon>
					{t(helperText)}
				</Typography>
			}
			placement="top"
			TransitionProps={{ timeout: 500 }}
			disableHoverListener
			open={helperText !== ' '}
		>
			{renderAutocomplete()}
		</LightTooltip>
	) : (
		renderAutocomplete()
	);
}
