import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TMaintenanceItem, TSidebarMode } from 'app/main/maintenance-manager/types';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TMaintenanceItem[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
};
const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view'
};

const maintenanceItemCrudSlice = createSlice({
	name: 'maintenanceItemCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TMaintenanceItem[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSelectedRows, setIsSidebarOpen, setSidebarMode, resetSidebarState } =
	maintenanceItemCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.maintenanceItem.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.maintenanceItem.sidebarMode;
export const selectSelectedRows = (state: RootState) => state.maintenanceItem.selectedRows;

export default maintenanceItemCrudSlice.reducer;
