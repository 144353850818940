import _ from '@lodash';
import { Dialog, DialogActions, DialogContent, Icon, TextField } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { darken, getContrastRatio, lighten } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { darkPaletteText, lightPaletteText } from 'app/configs/themesConfig';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';
import PalettePreview from './PalettePreview';
import SectionPreview from './SectionPreview';

i18next.addResourceBundle('en', 'paletteGenerator', en);
i18next.addResourceBundle('pt', 'paletteGenerator', pt);
i18next.addResourceBundle('sp', 'paletteGenerator', sp);

function isDark(color: any) {
	return getContrastRatio(color, '#ffffff') >= 3;
}

function PaletteSelector(props: any) {
	const { t } = useTranslation('paletteGenerator');

	const { value, section, disabled } = props;
	const [openDialog, setOpenDialog] = useState(false);
	const theme = useTheme();

	const methods = useForm({
		defaultValues: {},
		mode: 'onChange'
	});

	const { reset, formState, trigger, handleSubmit, watch, control, setValue } = methods;

	const { isValid, dirtyFields, errors } = formState;

	useEffect(() => {
		reset(value);
	}, [value, reset]);

	const form = watch();
	const formType = watch('palette.mode') as string;

	useEffect(() => {
		// console.info(form);
	}, [form]);

	useEffect(() => {
		if (!formType || !openDialog) {
			return;
		}

		setTimeout(() => trigger(['palette.background.paper', 'palette.background.default']));
	}, [formType, openDialog, trigger]);

	const backgroundColorValidation = (v: any) => {
		if (formType === 'light' && isDark(v)) {
			return t('MUST_BE_LIGTH');
		}
		if (formType === 'dark' && !isDark(v)) {
			return t('MUST_BE_DARK');
		}
		return true;
	};

	/**
	 * Open Dialog
	 */
	function handleOpenDialog(ev: any) {
		ev.preventDefault();
		ev.stopPropagation();
		setOpenDialog(true);
	}

	/**
	 * Close Dialog
	 */
	function handleCloseDialog() {
		setOpenDialog(false);
	}

	function onSubmit(formData: any) {
		props.onChange(formData);
		handleCloseDialog();
	}

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
			<div onClick={handleOpenDialog} role="button">
				{props.trigger}
			</div>
			<Dialog
				container={document.body}
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="form-dialog-title"
				classes={{
					paper: 'rounded-5 w-full'
				}}
			>
				<AppBar elevation={0} position="static">
					<Toolbar className="flex w-full">
						<Icon className="mr-12">palette</Icon>
						<Typography variant="subtitle1" color="inherit">
							{t('EDIT_PALETTE')}
						</Typography>
					</Toolbar>
				</AppBar>

				<DialogContent>
					<div className="flex w-full">
						<div className="flex flex-col items-center justify-center p-24 flex-1">
							<Controller
								name="palette.mode"
								control={control}
								render={({ field: { onChange: _onChange, value: _value } }) => (
									<ButtonGroup
										disabled={disabled}
										disableElevation
										variant="contained"
										color="secondary"
										className="mb-32"
									>
										<Button
											onClick={async () => {
												_onChange('light');
												setValue('palette.text', lightPaletteText, { shouldDirty: true });
											}}
											variant={_value === 'light' ? 'contained' : 'outlined'}
										>
											{t('LIGHT')}
										</Button>

										<Button
											onClick={async () => {
												_onChange('dark');
												setValue('palette.text', darkPaletteText, { shouldDirty: true });
											}}
											variant={_value === 'dark' ? 'contained' : 'outlined'}
										>
											{t('DARK')}
										</Button>
									</ButtonGroup>
								)}
							/>

							<Controller
								name="palette.primary.main"
								control={control}
								render={({ field: { onChange: _onChange, value: _value } }) => (
									<TextField
										disabled={disabled}
										value={_value}
										onChange={(ev) => {
											_onChange(ev.target.value);
											setValue('palette.primary.light', lighten(ev.target.value, 0.8), {
												shouldDirty: true
											});
											setValue('palette.primary.dark', darken(ev.target.value, 0.2), {
												shouldDirty: true
											});
											setValue(
												'palette.primary.contrastText',
												theme.palette.getContrastText(ev.target.value),
												{ shouldDirty: true }
											);
										}}
										type="color"
										variant="outlined"
										className="mb-32"
										label={t('PRIMARY_COLOR')}
										InputProps={{ className: 'w-200  h-32' }}
									/>
								)}
							/>

							<Controller
								name="palette.secondary.main"
								control={control}
								render={({ field: { onChange: _onChange, value: _value } }) => (
									<TextField
										disabled={disabled}
										value={_value}
										onChange={(ev) => {
											_onChange(ev.target.value);
											setValue('palette.secondary.light', lighten(ev.target.value, 0.8), {
												shouldDirty: true
											});
											setValue('palette.secondary.dark', darken(ev.target.value, 0.2), {
												shouldDirty: true
											});
											setValue(
												'palette.secondary.contrastText',
												theme.palette.getContrastText(ev.target.value),
												{ shouldDirty: true }
											);
										}}
										type="color"
										variant="outlined"
										className="mb-32"
										label={t('SECONDARY_COLOR')}
										InputProps={{ className: 'w-200 h-32' }}
									/>
								)}
							/>

							<Controller
								name="palette.background.paper"
								control={control}
								rules={{
									validate: {
										backgroundColorValidation
									}
								}}
								render={({ field }) => (
									<TextField
										disabled={disabled}
										{...field}
										type="color"
										variant="outlined"
										className="mb-32"
										label={t('PAPER_BACKGROUND')}
										InputProps={{ className: 'w-200 h-32' }}
										error={!!errors?.palette?.background?.paper}
										helperText={errors?.palette?.background?.paper?.message}
									/>
								)}
							/>

							<Controller
								name="palette.background.default"
								control={control}
								rules={{
									validate: {
										backgroundColorValidation
									}
								}}
								render={({ field }) => (
									<TextField
										disabled={disabled}
										{...field}
										type="color"
										variant="outlined"
										className=""
										label={t('DEFAULT_BACKGROUND')}
										InputProps={{ className: 'w-200 h-32' }}
										error={!!errors?.palette?.background?.default}
										helperText={errors?.palette?.background?.default?.message}
									/>
								)}
							/>
						</div>

						<div className="flex flex-col items-center justify-center p-48">
							<Typography className="text-16 font-semibold mb-16 -mt-48" color="text.secondary">
								{t('PREVIEW')}
							</Typography>
							<PalettePreview section={section} className="" palette={form.palette} />
						</div>
					</div>
				</DialogContent>
				<DialogActions className="flex justify-between p-16">
					<Button onClick={handleCloseDialog} color="primary">
						{t('CANCEL')}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						autoFocus
						onClick={handleSubmit(onSubmit)}
						disabled={_.isEmpty(dirtyFields) || !isValid || disabled}
					>
						{t('SAVE')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

PaletteSelector.defaultProps = {
	trigger: (
		<div className="flex flex-col items-center space-y-8 w-128 m-8">
			<SectionPreview section="" />
			<Typography className="flex-1 text-16 font-bold mb-24">Edit Palette</Typography>
		</div>
	)
};
export default PaletteSelector;
