import FusePageSimple from '@fuse/core/FusePageSimple';
import { setCurrentTab } from 'app/store/academy/academySlice';
import { selectIsSidebarOpen } from 'app/store/crud/coursesSlice';
import { useAppDispatch } from 'app/store/index';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BasicTabs from './components/AcademyTabs/CustomTabPanel';
import RegisterComponent from './components/RegisterComponent';
import Sidebar from './components/Sidebar';
import AcademyHeader from './components/header';

interface AcademyProps {
	tab: string;
}

function Academy() {
	const location = useLocation();
	const dispatch = useAppDispatch();
	const isSidebarOpen = useSelector(selectIsSidebarOpen);
	useEffect(() => {
		dispatch(setCurrentTab(location.pathname.split('/')[2]));
	}, [location, dispatch]);
	return (
		<>
			<FusePageSimple
				scroll="page"
				header={<AcademyHeader />}
				content={<BasicTabs />}
				rightSidebarContent={<Sidebar />}
				rightSidebarOpen={isSidebarOpen}
				rightSidebarWidth={428}
				rightSidebarVariant="permanent"
			></FusePageSimple>
			<RegisterComponent />
		</>
	);
}
export default Academy;
