import React, { useState } from 'react';

import { Collapse, FormControlLabel, Switch, Theme, useMediaQuery } from '@mui/material';
import CustomDescriptionTag from 'app/main/reports/events/components/CustomDescriptionTag';
import { getUserAbility } from 'app/permissions';
import { Subjects } from 'app/permissions/types';
import AppButton from 'app/shared-components/Buttons/AppButton';
import TooltipIconButton from 'app/shared-components/Buttons/TooltipIconButton';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { RootState, useAppDispatch } from 'app/store/index';
import { TFunction } from 'react-i18next';
import DownloadAll from '../Header/DownloadAll';
import { ConfigTable } from './ConfigTable';
import DownloadSelected from './DownloadSelected';
import { HeaderSkeleton } from './HeaderSkeleton';

interface HeaderProps {
	loading: boolean;
	visibleColumns;
	paginationData;
	totalItems;
	t: TFunction;
	url?: string;
	fileName?: string;
	setSidebarMode?: (mode: string) => void;
	setIsSidebarOpen?: (open: boolean) => void;
	setVisibleColumns: (columns) => void;
	changeSearchKey: (searchKey: string) => void;
	MassiveCreateModal?: React.ReactNode;
	Filters: React.ReactNode;
	getExcelColumns?: (t: TFunction, any?) => any;
	getColumnsNames: (t: TFunction) => any;
	permissionGroup: Subjects;
	AddIcon?: React.ReactNode;
	AdditionalActions?: React.ReactNode;
	getSearchKeys: (t: TFunction) => any;
	showIconAdd?: boolean;
	hasDownloadAll?: boolean;
	hasDownloadMultiple?: boolean;
	selectSelectedRows?: (state: RootState) => any[];
	showSearchKey?: boolean;
	downloadParams?: string[];
}

export const Header: React.FC<HeaderProps> = ({
	loading,
	visibleColumns,
	paginationData,
	totalItems,
	t,
	url,
	fileName,
	setSidebarMode,
	setIsSidebarOpen,
	setVisibleColumns,
	MassiveCreateModal,
	Filters,
	permissionGroup,
	AddIcon,
	AdditionalActions,
	changeSearchKey,
	getExcelColumns,
	getColumnsNames,
	getSearchKeys,
	showIconAdd = true,
	hasDownloadMultiple = false,
	hasDownloadAll = true,
	selectSelectedRows,
	showSearchKey,
	downloadParams
}) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const [isShowingFilters, setIsShowingFilters] = useState(!isMobile);
	const dispatch = useAppDispatch();
	const userAbility = getUserAbility();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsShowingFilters(event.target.checked);
	};

	const handleAddClick = () => {
		dispatch(setSidebarMode(userAbility.can('create', permissionGroup) ? 'create' : 'noPermission'));
		dispatch(setIsSidebarOpen(true));
	};

	const handleMassiveCreate = () => {
		dispatch(
			openDialog({
				maxWidth: 'lg',
				children: MassiveCreateModal
			})
		);
	};


	if (loading)
		return (
			<div className="flex flex-col justify-between items-start sm:px-[40px] px-[20px] py-[18px]">
				<HeaderSkeleton />
			</div>
		);

	const columns = getColumnsNames(t);

	const searchKeys = getSearchKeys(t);

	return (
		<div className="flex flex-col justify-between items-start sm:px-[40px] px-[20px] py-[30px]">
			<div className="flex flex-col sm:flex-row w-full sm:items-center gap-16">
				<div className="flex flex-row space-x-2">
					<CustomDescriptionTag title={t('TITLE')} />
					<FormControlLabel
						control={
							<Switch
								defaultChecked={isShowingFilters}
								checked={isShowingFilters}
								onChange={handleChange}
							/>
						}
						label={t('SHOW_FILTERS')}
					/>
				</div>
				<div className="flex flex-row items-center gap-16 sm:ml-auto">
					<div className="flex justify-between items-center space-x-8 w-f">
						{hasDownloadMultiple &&
							<DownloadSelected
								t={t}
								selectSelectedRows={selectSelectedRows}
								fileName={fileName}
								visibleColumns={visibleColumns}
							/>}
						{hasDownloadAll && 
						<DownloadAll
							totalItems={totalItems}
							t={t}
							paginationData={paginationData}
							url={url}
							fileName={fileName}
							visibleColumns={visibleColumns}
							getColumnsFunction={getExcelColumns}
							downloadParams={downloadParams}
						/>}
						{MassiveCreateModal && (
							<TooltipIconButton
								iconButtonProps={{ color: 'secondary' }}
								label={t('IMPORT')}
								icon="file_upload"
								size="small"
								onClick={handleMassiveCreate}
							/>
						)}
						{AdditionalActions}
						<ConfigTable
							t={t}
							visibleColumns={visibleColumns}
							setVisibleColumns={setVisibleColumns}
							changeSearchKey={changeSearchKey}
							searchParamsData={paginationData}
							columns={columns}
							searchKeys={searchKeys}
							showSearchKey={showSearchKey}
						/>
					</div>
					{userAbility.can('create', permissionGroup) && showIconAdd ? (
						<AppButton
							className="ml-auto"
							variant="contained"
							color="secondary"
							size="large"
							onClick={handleAddClick}
							endIcon={AddIcon}
						>
							{t('ADD')}
						</AppButton>
					) : undefined}
				</div>
			</div>
			<Collapse className="flex flex-col w-full" in={isShowingFilters}>
				{Filters}
			</Collapse>
		</div>
	);
};
