import { CheckCircle, ErrorOutlineOutlined } from '@mui/icons-material';
import { CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';

interface ISettingsItemProps {
	correct: boolean;
	title: string;
	value?: string;
	loading?: boolean;
}

export default function SettingsItem({ title, value, correct, loading = false }: ISettingsItemProps) {
	let displayComponent = <ErrorOutlineOutlined color="error" fontSize="small" />;

	if (loading) {
		displayComponent = <CircularProgress size={16} />;
	} else if (correct) {
		displayComponent = <CheckCircle fontSize="small" color="success" />;
	}

	return (
		<Stack direction="row" gap={1} alignItems="center">
			<Typography fontWeight={600} fontSize="14px">
				{title}: {loading ? '...' : value}
			</Typography>
			{displayComponent}
		</Stack>
	);
}
