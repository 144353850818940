import { useDeepCompareEffect } from '@fuse/hooks';
import { ListItem, ListItemText } from '@mui/material';
import { TTracker } from 'app/main/trackers/manage/types';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { useGetAllTrackersByCompaniesQuery } from 'app/store/api/trackersSlice';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { splitAndGetOption } from '../../../historyNew/helper';
import { TFormData } from '../filterSchema';

interface TrackersSelectProps {
	initialValue: string;
	sx?: {};
	className?: string;
	fullWidth?: boolean;
	noHelperText?: boolean;
	useDid?: boolean;
}

export const TrackersSelect: React.FC<TrackersSelectProps> = ({ initialValue, sx, fullWidth = true, className, noHelperText, useDid = false }) => {

	const { control, setValue, watch } = useFormContext<TFormData>();
	const { t } = useTranslation('eventsReportPage');

	const selectedCompanies = watch('company');
	const hasCompanySelected = !_.isEmpty(selectedCompanies);
	const companiesIds = _.map(selectedCompanies, '_id');
	const skip = !hasCompanySelected;

	const { trackers, isTrackersLoading } = useGetAllTrackersByCompaniesQuery(companiesIds, {
		selectFromResult: ({ data, ...res }) => ({
			trackers: data?.docs || [],
			isTrackersLoading: res.isLoading
		}),
		skip
	});

	useDeepCompareEffect(() => {
		if (!_.isEmpty(trackers) && initialValue) {
			setValue('tracker', splitAndGetOption(initialValue, trackers, useDid));
		}
	}, [trackers, initialValue, useDid]);

	return (
		<HookedVirtualizedChipSelect<TFormData, TTracker>
			fullWidth={fullWidth}
			className={className}
			sx={sx}
			options={trackers}
			control={control}
			name="tracker"
			label={t('TRACKERS')}
			optionLabel="did"
			t={t}
			isDisabled={!hasCompanySelected}
			loading={isTrackersLoading}
			limitText={(n) => t('TRACKERS_SELECTED', { n })}
			maxListHeight={200}
			renderOption={(props, option) => (
				<ListItem {...props}>
					<ListItemText primary={option?.name} secondary={option?.did} />
				</ListItem>
			)}
			noHelperText={noHelperText}
			filterOptions={(options, { inputValue }) => matchSorter(options, inputValue, { keys: ['name', 'did'] })}
		/>
	);
};
