const locale = {
	TRACKER_COMMAND_UPDATE_ERROR: 'Erro ao atualizar comando do rastreador',
	TRACKER_COMMAND_UPDATED: 'Comando atualizado com sucesso',
	TRACKER_COMMAND_CREATE_SUCCESS: 'Comando criado com sucesso',
	TRACKER_COMMAND_CREATE_ERROR: 'Erro ao criar comando',
	COMMANDS_REGISTER: 'Registro de comandos',
	REQUIRED_FIELDS: 'Campos obrigatórios',
	COMMANDS: 'Comandos',
	REQUIRED_FIELD: 'Campo obrigatório',
	CREATE: 'Criar',
	SAVE: 'Salvar',
	CREATE_COMMAND: 'Criar comando',
	CLOSE: 'Fechar',
	CONFIRM: 'Confirmar',
	CANCEL: 'Cancelar',
	PERMISSIONS_NOT_ALLOWED: 'Você não tem permissão para executar esta ação',
	TRACKER_COMMAND_PARAMETERS: 'Parâmetros',
	TRACKER_COMMAND_ADD_PARAMETER: 'Adicionar parâmetro',
	TRACKER_COMMAND_ICON: 'Ícone',
	DELETE_TRACKER_COMMAND: 'Excluir comando do rastreador',
	DELETE_MODAL_DESCRIPTION: 'Tem certeza que deseja excluir este comando?',
	DELETE_MODAL_TITLE: 'Excluir comando',
	TRACKER_COMMAND_FIELD: 'Campo',
	TRACKER_COMMAND_VALUE: 'Valores',
	COMPANY_COMMANDS: 'Comandos da empresa',
	TRACKER_COMMAND_COMPANIES: 'Empresas',
	SELECTED_COMPANIES: 'Empresas selecionadas',

	TRACKER_COMMAND_TYPE: 'Tipo',
	TRACKER_COMMAND_LABEL: 'Legenda',
	TRACKER_COMMAND_DESCRIPTION: 'Descrição',
	TRACKER_COMMAND_PARSER: 'Protocolo',
	EDIT_TRACKER_COMMAND: 'Editar comando do rastreador',
	TRACKER_COMMAND_NAME: 'Nome do comando do rastreador',
	TRACKER_COMMAND_STRING: 'Comando',
	EXECUTE_COMMAND: 'Executar comando',
	DEFAULT_COMMANDS: 'Comandos padrão',
	USER_COMMANDS: 'Comandos do usuário',
	SCHEDULE_COMMAND: 'Agendar comando',
	EXECUTE_COMMANDS: 'Executar comandos',
	SCHEDULE_COMMANDS: 'Agendar comandos',
	SELECT_COMMAND: 'Selecione o comando',
	VEHICLES: 'Veículos',
	FLEETS: 'Frotas',
	TRACKERS: 'Rastreadores',
	COMPANIES: 'Empresas',
	EXECUTE_COMMANDS_IN: 'Executar comandos em',
	MONTHLY: 'Mensal',
	WEEKLY: 'Semanal',
	DAILY: 'Diário',
	DAY: 'Dia',
	WEEKDAY: 'Dias da semana',
	FREQUENCY: 'Frequência',
	EVERYDAY: 'Todos os dias',
	MONDAY: 'Segunda-feira',
	TUESDAY: 'Terça-feira',
	WEDNESDAY: 'Quarta-feira',
	THURSDAY: 'Quinta-feira',
	FRIDAY: 'Sexta-feira',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',
	UNIC_TIME: 'Horário específico',
	PERIODIC_TIME: 'Horário periódico',
	START: 'Início',
	END: 'Fim',
	TARGET_REQUIRED: 'Você deve selecionar ao menos um destinátario',
	COMMAND_REQUIRED: 'Você deve selecionar um comando',
	NO_COMMANDS: 'Não há comandos disponíveis para este protocolo',
	PARSER: 'Protocolo',
	SELECT_PARSER: 'Selecione o protocolo',
	NO_COMPANIES: 'Não há empresas disponíveis para este protocolo',
	NO_FLEETS: 'Não há frotas disponíveis para este protocolo',
	NO_VEHICLES: 'Não há veículos disponíveis para este protocolo',
	NO_TRACKERS: 'Não há rastreadores disponíveis para este protocolo',
	HOURLY: 'Hora',
	MINUTE: 'Minuto',
	MINUTES: 'De minutos em minutos',
	HOURS: 'De horas em horas',
	SEND_SMS: 'Enviar SMS',
	PRIORITARY: 'Prioritário',
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	FILTER: 'Filtro',
	COLUMNS_INFO: 'Selecione quais campos deseja que apareçam na tabela',
	COLUMNS: 'Colunas',
	ITEMS: 'itens',
	ADD: 'Adicionar',
	SEARCH: 'Pesquisar',
	SEARCH_INFO: 'Selecione campos em que deseja pesquisar na tabela',
	SCHEDULED_COMMANDS: 'Comandos agendados',
	HISTORY_COMMANDS: 'Histórico de comandos',
	NAME: 'Nome',
	SCHEDULED_COMMAND_NAME: 'Nome do comando agendado',
	COMMAND_NAME: 'Nome do comando',
	FROM_STATUS: 'De status',
	TO_STATUS: 'Para status',
	NEXT_EXECUTION: 'Próxima execução',
	NO_DATA: 'Não há dados para exibir',
	CREATED_AT: 'Criado em',
	UPDATED_AT: 'Atualizado em',
	CREATED_BY: 'Criado por',
	VIEW_SUMMARY: 'Ver resumo',
	EDIT_SCHEDULE_COMMAND: 'Editar comando agendado',
	STATUS: 'Status',
	VALUES: 'Valores',
	NO_SUMMARY: 'Não há resumo para este comando',
	ERROR: 'Com erros',
	SENT: 'Enviados',
	CONFIRMED: 'Confirmados',
	QUEUED: 'Agendados',
	SUMMARY_INFO: 'Os resumos do comandos são exibidos por datas de execução',
	EDIT: 'Editar',
	DELETE: 'Excluir',
	EXECUTE_FAIL: 'Falha ao executar comando',
	EXECUTE_SUCCESS: 'Comando executado com sucesso',
	SCHEDULE_FAIL: 'Falha ao agendar comando',
	SCHEDULE_SUCCESS: 'Comando agendado com sucesso',
	NAME_REQUIRED: 'O nome é obrigatório',
	SCHEDULED_NAME: 'Nome do comando agendado',
	SCHEDULED_TO: 'Agendado para',
	YES: 'Sim',
	NO: 'Não',
	COMMAND_DELETED: 'Comando deletado com sucesso',
	COMMAND_NOT_DELETED: 'Falha ao deletar comando',
	TARGETS: 'Enviados para',
	SUMMARY: 'Resumo',
	DELETE_COMMAND_MESSAGE: 'Tem certeza que deseja deletar o comando?',
	DELETE_COMMAND_MESSAGE_2: 'Esta ação não pode ser desfeita',
	DELETE_COMMAND: 'Deletar comando',
	INFO_COMMAND: 'Informações do comando',

	IS_BLOCK: 'Este comando é um bloqueio?',
	IS_UNBLOCK: 'Este comando é um desbloqueio?'
};

export default locale;
