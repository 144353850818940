import i18next from 'i18next';
import { TRANSLATE_KEY } from './helper';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const DriversPage = lazyRetry(() => import('./Drivers'));

i18next.addResourceBundle('en', TRANSLATE_KEY, en);
i18next.addResourceBundle('sp', TRANSLATE_KEY, sp);
i18next.addResourceBundle('pt', TRANSLATE_KEY, pt);

export const DRIVERS_PERMISSIONS = {
	edit: 'fleet.driver.edit',
	view: 'fleet.driver.view',
	list: 'fleet.driver.list',
	new: 'fleet.driver.new',
	delete: 'fleet.driver.delete',
	minimal: ['admin.company.list', 'admin.user.list', 'fleet.driver.list']
};

const DriversConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.driver.list'],
			path: 'fleet/drivers',
			element: (
				<LazyLoaderSuspense>
					<DriversPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<DriversPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default DriversConfig;
