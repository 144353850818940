import { memo } from 'react';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';
import NotificationPanel, { NOTIFICATION_PERMISSIONS } from '../../shared-components/notificationPanel/NotificationPanel';
import usePermission from 'app/services/hooks/usePermission';


function RightSideLayout2() {
	const permissions = usePermission({ pagePermissions: NOTIFICATION_PERMISSIONS });

	return (
		<>
			<QuickPanel />
			{permissions.hasList && <NotificationPanel />}
		</>
	);
}


export default memo(RightSideLayout2);
