import { ISimcardPayload } from 'app/store/api/simcardsSlice';
import { TPaginatedQuery } from 'app/store/api/types';
import { TExcelColumn } from 'app/types/excel-column.types';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FiltersFormValues } from './components/filters/form-schema';
import { TRequestMessages, TSimcard } from './types';

export const REQUEST_MESSAGES: TRequestMessages = {
	create: { success: 'CREATE_SUCCESS', error: 'CREATE_ERROR' },
	edit: { success: 'EDIT_SUCCESS', error: 'EDIT_ERROR' },
	editMultiple: { success: 'EDIT_MULTIPLE_SUCCESS', error: 'EDIT_MULTIPLE_ERROR' },
	delete: { success: 'DELETE_SUCCESS', error: 'DELETE_ERROR' },
	massiveCreate: { success: 'MASSIVE_CREATE_SUCCESS', error: 'MASSIVE_CREATE_ERROR' }
};

export interface SimcardSearchParams extends TPaginatedQuery {
	brand?: string;
	company?: string;
	tracker?: string;
	queryId?: string;
}
export type SimcardSearchParamsFilters = Omit<SimcardSearchParams, 'page' | 'limit'>;

export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export const getExcelColumns = (t: TFunction): TExcelColumn<TSimcard>[] => [
	{
		label: t('ICCID'),
		value: 'iccid'
	},
	{
		label: t('Brand'),
		value: (row) => _.get(row, 'brand.name', '')
	},
	{
		label: t('Company'),
		value: (row) => _.get(row, 'company.name', '')
	},
	{
		label: t('Phone'),
		value: (row) => row?.phone && formatPhoneNumberIntl(row?.phoneCountryCode + row?.phone)
	},
	{
		label: t('Tracker'),
		value: (row) => _.get(row, 'tracker.name', '')
	},
	{
		label: t('Notes'),
		value: 'notes'
	},
	{
		label: t('Supplier'),
		value: 'supplier'
	}
];

export const VALID_CSV_FIELDS: (keyof ISimcardPayload)[] = [
	'iccid',
	'brand',
	'company',
	'phone',
	'phoneCountryCode',
	'phoneCountry',
	'tracker',
	'notes',
	'supplier'
];

export function parseFormValuesToFilter(formValues: FiltersFormValues): SimcardSearchParamsFilters {
	const companyId = _.get(formValues, 'company._id', '');
	const trackerId = _.get(formValues, 'tracker._id', '');
	const simcardBrandId = _.get(formValues, 'brand._id', '');

	return {
		...formValues,
		company: companyId,
		tracker: trackerId,
		brand: simcardBrandId
	};
}
