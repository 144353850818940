import { DialogProps } from '@mui/material/Dialog/Dialog';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store/index';

interface DialogState {
	state: boolean;
	options?: Pick<DialogProps, 'children' | 'maxWidth' | 'classes'>;
}

const initialState: DialogState = {
	state: false,
	options: {
		children: 'Hi'
	}
};

const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		openDialog: (state, action: PayloadAction<DialogState['options']>) => {
			state.state = true;
			state.options = action.payload;
		},
		closeDialog: (state) => {
			state.state = false;
		}
	}
});

export const { closeDialog, openDialog } = dialogSlice.actions;

export const selectFuseDialogState = (state: RootState) => state.fuse.dialog.state;
export const selectFuseDialogOptions = (state: RootState) => state.fuse.dialog.options;

export default dialogSlice.reducer;
