import { ITrackerPayload } from 'app/store/api/trackersSlice';
import { TExcelColumn } from 'app/types/excel-column.types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import formatBattery from 'src/utils/formatBattery';
import { TVehicle } from '../../map/types';
import { FiltersFormValues } from './components/filters/form-schema';
import { TrackerSearchParamsFilters, TRequestMessages } from './types';

export function formatCarPlate(plate: string) {
	if (!plate) return '';
	// deixe apenas letras e números, retire espaços e traços e qualquer outro caractere e deixe tudo em maiúsculo

	const plateWithoutSpacesAndDashes = plate?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

	// se a string tiver 7 caracteres, adicione um traço no meio
	if (plateWithoutSpacesAndDashes.length === 7) {
		return `${plateWithoutSpacesAndDashes.slice(0, 3)}-${plateWithoutSpacesAndDashes.slice(3)}`;
	}

	// se a string tiver 8 caracteres, adicione um espaço no meio
	if (plateWithoutSpacesAndDashes.length === 8) {
		return `${plateWithoutSpacesAndDashes.slice(0, 3)} ${plateWithoutSpacesAndDashes.slice(3)}`;
	}

	// se a string tiver 9 caracteres, adicione um espaço no meio
	if (plateWithoutSpacesAndDashes.length === 9) {
		return `${plateWithoutSpacesAndDashes.slice(0, 3)} ${plateWithoutSpacesAndDashes.slice(
			3,
			6
		)}-${plateWithoutSpacesAndDashes.slice(6)}`;
	}

	return plateWithoutSpacesAndDashes;
}

export const VALID_XLSX_FIELDS: (keyof ITrackerPayload)[] = [
	'did',
	'status',
	'name',
	'notes',
	'vehicle',
	'trackerType',
	'simcard',
	'company',
	'notes'
];

export const getExcelColumns = (t: TFunction): TExcelColumn<TVehicle>[] => [
	{
		label: t('ID'),
		value: '_id'
	},
	{
		label: t('NAME'),
		value: 'name'
	},
	{
		label: t('TRACKER_TYPE'),
		value: (row) => _.get(row, 'trackerType.name', '')
	},
	{
		label: t('SIMCARD'),
		value: (row) => _.get(row, 'simcard.iccid', '')
	},
	{
		label: t('VEHICLE'),
		value: (row) => _.get(row, 'vehicle.name', '')
	},
	{
		label: t('COMPANY'),
		value: (row) => _.get(row, 'company.name', '')
	},
	{
		label: t('STATUS'),
		value: (row) => _.get(row, 'status', '')
	},
	{
		label: t('NOTES'),
		value: 'notes'
	},
	{
		label: t('EVENT_NAME'),
		value: (row) => _.get(row, 'packet.event.name', '')
	},
	{
		label: t('SPEED'),
		value: (row) => {
			const speed = _.get(row, 'packet.SPEED', undefined);
			if (speed === undefined) return '';
			return `${speed} Km/h`;
		}
	},
	{
		label: t('GPS_TIME'),
		value: (row) => dayjs(_.get(row, 'packet.GPS_TIME')).format('DD/MM/YYYY - HH:mm')
	},
	{
		label: t('SERVER_TIME'),
		value: (row) => dayjs(_.get(row, 'packet.SERVER_TIME')).format('DD/MM/YYYY - HH:mm')
	},
	{
		label: t('IGNITION_ON'),
		value: (row) => {
			const ign = _.get(row, 'packet.IGNITION_ON', undefined);
			if (ign === undefined) return '';
			return ign ? t('ON') : t('OFF');
		}
	},
	{
		label: t('VEHICLE_VOLTAGE'),
		value: (row) => {
			const voltage = _.get(row, 'packet.VEHICLE_VOLTAGE', undefined);
			if (voltage === undefined) return '';
			return formatBattery(voltage);
		}
	}
];

export const TRACKER_STATUS = (t: TFunction) => [
	{
		name: t('ACTIVE'),
		status: 'active',
		icon: 'wifi'
	},
	{
		name: t('INACTIVE'),
		status: 'inactive',
		icon: 'signal_wifi_off'
	},
	{ name: t('LOST'), status: 'lost', icon: 'device_unknown' },
	{
		name: t('DAMAGED'),
		status: 'damaged',
		icon: 'wifi_tethering_error_rounded'
	},
	{
		name: t('STOCK'),
		status: 'stock',
		icon: 'inventory'
	},
	{
		name: t('DELETED'),
		status: 'deleted',
		icon: 'delete'
	}
];

export const REQUEST_MESSAGES: TRequestMessages = {
	create: { success: 'CREATE_SUCCESS', error: 'CREATE_ERROR' },
	edit: { success: 'EDIT_SUCCESS', error: 'EDIT_ERROR' },
	editMultiple: { success: 'EDIT_MULTIPLE_SUCCESS', error: 'EDIT_MULTIPLE_ ERROR' },
	delete: { success: 'DELETE_SUCCESS', error: 'DELETE_ERROR' },
	massiveCreate: { success: 'MASSIVE_CREATE_SUCCESS', error: 'MASSIVE_CREATE_ERROR' },
	restore: { success: 'RESTORE_SUCCESS', error: 'RESTORE_ERROR' }
};

export function parseFormValuesToFilter(formValues: FiltersFormValues): TrackerSearchParamsFilters {
	const companyId = _.get(formValues, 'company._id', '');
	const vehicleId = _.get(formValues, 'vehicle._id', '');
	const trackerTypeId = _.get(formValues, 'type._id', '');
	const simcardId = _.get(formValues, 'simcard._id', '');
	const status = _.get(formValues, 'status', '');
	if (status === 'deleted') {
		return {
			...formValues,
			company: companyId,
			vehicle: vehicleId,
			type: trackerTypeId,
			simcard: simcardId,
			deleted: true,
			status: 'deleted'
		};
	}
	return {
		...formValues,
		company: companyId,
		vehicle: vehicleId,
		type: trackerTypeId,
		simcard: simcardId,
		status
	};
}
