import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const ScheduleCommandsPage = lazyRetry(() => import('./ScheduleCommandsPage'));

i18next.addResourceBundle('en', 'scheduleCommandsPage', en);
i18next.addResourceBundle('pt', 'scheduleCommandsPage', pt);
i18next.addResourceBundle('sp', 'scheduleCommandsPage', sp);

export const SCHEDULE_COMMANDS_PERMISSIONS = {
	edit: 'command.schedule.edit',
	view: 'command.schedule.view',
	list: 'command.schedule.list',
	new: 'command.schedule.new',
	delete: 'command.schedule.delete',
	minimal: [
		'fleet.fleet.list',
		'fleet.tracker.list',
		'admin.tracker-command.list',
		'admin.company.list',
		'admin.tracker-type.list',
		'fleet.vehicle.list'
	]
};

const CommandsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['command.schedule.list'],
			path: 'commands/schedule-commands',
			element: (
				<LazyLoaderSuspense>
					<ScheduleCommandsPage />
				</LazyLoaderSuspense>
			),
			children: [{ path: ':id' }]
		}
	]
};

export default CommandsConfig;
