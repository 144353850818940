import { addTranslation } from 'app/contexts/DownloadContext/helper';
import i18next from 'i18next';

import { lazyRetry } from 'src/utils/lazyRetry';
import Academy from './Academy';
import Lesson from './components/lessons/SingleLesson/Lesson';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const Course = lazyRetry(() => import('./course/Course'));

i18next.addResourceBundle('en', 'academyPage', en);
i18next.addResourceBundle('sp', 'academyPage', sp);
i18next.addResourceBundle('pt', 'academyPage', pt);
addTranslation();

export const ACADEMY_COURSE_PERMISSIONS = {
	edit: 'academy.courses.edit',
	view: 'academy.courses.view',
	list: 'academy.courses.list',
	new: 'academy.courses.new',
	delete: 'academy.courses.delete',
	minimal: ['admin.academy.list']
};

export const ACADEMY_LESSONS_PERMISSIONS = {
	edit: 'academy.lessons.edit',
	view: 'academy.lessons.view',
	list: 'academy.lessons.list',
	new: 'academy.lessons.new',
	delete: 'academy.lessons.delete',
	minimal: ['admin.academy.list']
};

export const ACADEMY_COURSECATEGORY_PERMISSIONS = {
	edit: 'academy.courseCategory.edit',
	view: 'academy.courseCategory.view',
	list: 'academy.courseCategory.list',
	new: 'academy.courseCategory.new',
	delete: 'academy.courseCategory.delete',
	minimal: ['admin.academy.list']
};
const AcademyAppConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: '/academy/courses',
			element: <Academy />
		},
		{
			path: '/academy/lessons',
			element: <Academy />
		},

		{
			path: '/academy/courses/:courseId',
			element: <Course />
		},

		{
			path: '/academy/lessons/:lessonId',
			element: <Lesson />
		}
	]
};

export default AcademyAppConfig;
