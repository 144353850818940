import i18next from 'i18next';

import addTranslation from 'app/shared-components/CRUDs/Header/headerTranslation';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const VehiclesPage = lazyRetry(() => import('./Vehicle'));
const ViewVehicle = lazyRetry(() => import('../vehicle-view/ViewVehiclePage'));

i18next.addResourceBundle('en', 'vehiclesPage', en);
i18next.addResourceBundle('sp', 'vehiclesPage', sp);
i18next.addResourceBundle('pt', 'vehiclesPage', pt);
addTranslation();

export const VEHICLE_PERMISSIONS = {
	edit: 'fleet.vehicle.edit',
	view: 'fleet.vehicle.view',
	list: 'fleet.vehicle.list',
	new: 'fleet.vehicle.new',
	delete: 'fleet.vehicle.delete',
	minimal: ['admin.company.list', 'fleet.tracker.list', 'fleet.fleet.list']
};

const VehicleConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.vehicle.list'],
			path: 'fleet/vehicles',
			element: (
				<LazyLoaderSuspense>
					<VehiclesPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<VehiclesPage />
						</LazyLoaderSuspense>
					)
				}
			]
		},
		{
			auth: ['fleet.vehicle.view'],
			path: 'fleet/vehicle/:id',
			element: (
				<LazyLoaderSuspense>
					<ViewVehicle />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default VehicleConfig;
