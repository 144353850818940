import * as yup from 'yup';

export const tableSchema = yup.object({
	name: yup.string().required('NAME_REQUIRED'),
	email: yup.string().email('INVALID_EMAIL').optional(),
	mobile: yup
		.number()
		.typeError('PHONE_LENGHT_AND_FORMAT_WARNING')
		.test('len', 'PHONE_LENGHT_AND_FORMAT_WARNING', (val) => {
			if (val) return val.toString().length === 11;
		}),
	mobileCountryCode: yup.string(),
	phone: yup
		.number()
		.typeError('PHONE_LENGHT_AND_FORMAT_WARNING')
		.test('len', 'PHONE_LENGHT_AND_FORMAT_WARNING', (val) => {
			if (val) return val.toString().length === 11;
		}),
	phoneCountryCode: yup.string(),
	cnh: yup.string(),
	cnhType: yup.string(),
	cnhDueDate: yup.date(),
	cpf: yup.string(),
	rg: yup.string(),
	rgType: yup.string(),
	rgCreatedDate: yup.date(),
	address: yup.string(),
	birthday: yup.date(),
	notes: yup.string(),
	user: yup.string(),
	company: yup.string().required('COMPANY_REQUIRED').matches(/^\d+$/, 'COMPANY_MUST_BE_NUMBER')
});
