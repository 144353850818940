import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TGeoFence } from 'app/main/geoFence/types/types';
import { SubCoordinate } from 'app/main/reports/trips/advanced/types';
import { Position } from 'geojson';
import { RootState } from '../..';

interface IInitialState {
	isShowingRoute: boolean;
	isShowingPosition: boolean;
	markerLocation: Position;
	markerBearing: number;
	markerFixed: boolean;
	isPlaying: boolean;
	motionIndex: number;
	sideBarIndex: number;
	selectedGeoFences: string[];
	animationVelocity: number;
	currentSubCoordinate: SubCoordinate;
}

const initialState: IInitialState = {
	isShowingRoute: false,
	isShowingPosition: true,
	markerLocation: null,
	markerBearing: 0,
	motionIndex: 0,
	isPlaying: false,
	sideBarIndex: 0,
	markerFixed: false,
	selectedGeoFences: [],
	animationVelocity: 5,
	currentSubCoordinate: null
};

const advancedSlice = createSlice({
	name: 'advanced',
	initialState,
	reducers: {
		toggleRoute(state, action: PayloadAction<boolean>) {
			state.isShowingRoute = action.payload;
		},
		togglePosition(state, action: PayloadAction<boolean>) {
			state.isShowingPosition = action.payload;
		},
		setMarkerLocation(state, action: PayloadAction<Position>) {
			state.markerLocation = action.payload;
		},
		setMarkerBearing(state, action: PayloadAction<number>) {
			state.markerBearing = action.payload;
		},
		setMotionIndex(state, action: PayloadAction<number>) {
			state.motionIndex = action.payload;
		},
		setPlaying(state, action: PayloadAction<boolean>) {
			state.isPlaying = action.payload;
		},
		setSideBarIndex(state, action: PayloadAction<number>) {
			state.sideBarIndex = action.payload;
		},
		setMarkerFixed(state, action: PayloadAction<boolean>) {
			state.markerFixed = action.payload;
		},
		setSelectedGeoFences(state, action: PayloadAction<TGeoFence>) {
			const index = state.selectedGeoFences.indexOf(action?.payload?._id);
			if (index === -1) {
				state.selectedGeoFences.push(action.payload._id);
			} else {
				state.selectedGeoFences.splice(index, 1);
			}
		},
		setAnimationVelocity(state, action: PayloadAction<number>) {
			state.animationVelocity = action.payload;
		},
		setCurrentSubCoordinate(state, action: PayloadAction<SubCoordinate>) {
			state.currentSubCoordinate = action.payload;
		}
	}
});

export const {
	toggleRoute,
	togglePosition,
	setMarkerLocation,
	setMotionIndex,
	setPlaying,
	setSideBarIndex,
	setMarkerBearing,
	setMarkerFixed,
	setSelectedGeoFences,
	setAnimationVelocity,
	setCurrentSubCoordinate
} = advancedSlice.actions;

export const selectIsShowingRoute = (state: RootState) => state.trips.advanced.isShowingRoute;
export const selectIsShowingPosition = (state: RootState) => state.trips.advanced.isShowingPosition;

export const selectMarkerLocation = (state: RootState) =>
	state.trips.advanced.currentSubCoordinate?.point?.geometry?.coordinates;

export const selectMotionIndex = (state: RootState) => state.trips.advanced.motionIndex;

export const selectSideBarIndex = (state: RootState) => state.trips.advanced.currentSubCoordinate?.index;

export const selectCurrentSubCoordinate = (state: RootState) => state.trips.advanced?.currentSubCoordinate;

export const selectIsPlaying = (state: RootState) => state.trips.advanced.isPlaying;

export const selectMarkerBearing = (state: RootState) => state.trips.advanced.markerBearing;

export const selectMarkerFixed = (state: RootState) => state.trips.advanced.markerFixed;

export const selectSelectedGeoFences = (state: RootState) => state.trips.advanced.selectedGeoFences;

export const selectAnimationVelocity = (state: RootState) => state.trips.advanced.animationVelocity;

export default advancedSlice.reducer;
