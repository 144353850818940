const en = {
	TITLE: 'Allowed Access Times',
	PARTIAL: 'Partial',
	FULL: 'Full',
	NO_ACCESS: 'No Access',
	START: 'Start',
	END: 'End',
	TO: 'to',
	ACCESS_TYPE: 'Access Type',
	ACTIVE: 'Active',

	MONDAY: 'Monday',
	TUESDAY: 'Tuesday',
	WEDNESDAY: 'Wednesday',
	THURSDAY: 'Thursday',
	FRIDAY: 'Friday',
	SATURDAY: 'Saturday',
	SUNDAY: 'Sunday',

	REMOVE_PERIOD: 'Remove Period',
	ADD_PERIOD: 'Add Another Period',

	SAVE: 'Save',
	CANCEL: 'Cancel',

	CONFIGURE_PERIODS: 'Configure Periods',

	FULL_ACCESS: 'The user will have full access to the system during this day',
	PARTIAL_ACCESS: 'The user will have access at specific times during this day',
	NONE_ACCESS: 'The user will not have access to the system during this day',
	VIEW_INFO: 'Help',
	START_DATE_MUST_BE_GREATER_THAN_END_DATE: 'Start date must be greater than end date',
	PERIOD_NOT_OVERLAP: 'Periods cannot overlap'
};
export default en;
