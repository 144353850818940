const locale = {
	TITLE: 'Eventos Finalizados',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	CANCEL: 'Cancelar',
	CONFIRM: 'Confirmar',
	SELECTED_ITENS: '{{n}} itens selecionados',
	HIGH: 'Severida alta',
	MEDIUM: 'Severidade média',
	LOW: 'Severidade baixa',
	COMPANY: 'Empresa',
	TRACKER_EVENT: 'Eventos',
	SELECT_TIME_RANGE: 'Selecionar por tempo',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	SIMCARD: 'Simcard',
	CAN_BE_BLOCKED: 'Pode ser bloqueado?',
	CAN_BE_BLOQUED_TOOLTIP: 'Isso indica se o rastreador pode ser configurado para bloquear o veículo',
	INFO_TRACKER: 'Informações do último pacote',
	EVENT_NAME: 'Nome do evento',
	SPEED: 'Velocidade',
	SERVER_TIME: 'Hora do servidor',
	GPS_TIME: 'Hora do gps',
	IGNITION_ON: 'Estado da ignição',
	VEHICLE_VOLTAGE: 'Tensão do veículo',
	BATTERY: 'Tensão da bateria',
	ODOMETER: 'Ôdometro',
	UNAVAILABLE: 'Indisponível',
	ON: 'Ligada',
	OFF: 'Desligada',
	LONGITUDE: 'Longitude',
	LATITUDE: 'Latitude',

	// Campos Obrigatórios

	COMPANY_REQUIRED: 'O campo da empresa é obrigatório',
	DID_MUST_BE_NUMBER: 'O campo DID deve ser um número',
	TRACKER_TYPE_REQUIRED: 'O campo de tipo de rastreador é obrigatório',
	DID_REQUIRED: 'O campo DID é obrigatório',
	NAME_REQUIRED: 'O campo de nome é obrigatório',
	WRONG_STATUS: 'O campo de status está errado',
	SELECT_COMPANY_FIRST: 'Selecione uma empresa primeiro',

	// Cabeçalho
	DOWNLOAD_ALL: 'Baixar todos',
	ADD_USER: 'Adicionar Rastreador',
	NO_SIMCARDS: 'Nenhum simcard encontrado',
	NO_VEHICLES: 'Nenhum veículo encontrado',
	FILTER: 'Filtrar',
	SHOW_FILTERS: 'Mostrar filtros',

	// Table
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	VEHICLE: 'Veículo',
	TRACKER: 'Rastreador',
	USER: 'Usuário',
	DATE: 'Data',
	ALERT: 'Alerta',
	NO_ITEM_FOUND: 'Nenhum evento encontrado',
	TOTAL_TIME: 'Tempo total',
	OPEN_ADVANCED: 'Ver Jornada',
	TRIP_NOT_FOUND: 'Jornada não encontrada',
	NO_TRACKERS_FOUND: 'Nenhum rastreador encontrado',

	// Filters
	SEVERITY: 'Severidade',
	TIMING: 'Tempo',
	LOW_SEVERITY: 'Mostrar baixa severidade',
	MEDIUM_SEVERITY: 'Mostrar média severidade',
	HIGH_SEVERITY: 'Mostrar alta severidade',
	LAST_HOUR: 'Última hora',
	LAST_N_HOURS: 'Últimas {{n}} horas',
	CLEAR_ALL: 'Limpar filtros',
	CLEAR_FILTERS: 'Limpar filtros',
	SELECT_FILTERS: 'Selecionar filtros',
	CONFIG_TABLE: 'Configurações',
	// Download
	DOWNLOAD_SELECTED: 'Baixar selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar eventos finalizados.',

	// Sidebar
	BRIEF: 'Motivo da finalização.',
	TO_DO: 'Aberto',
	ON_GOING: 'Andamento',
	DONE: 'Finalizado',
	ACTIONS: 'Ações realizadas',
	TOTAL_ACTIONS: '{{n}} ações realizadas',
	MOVED_TO: 'Movido para: {{action}}',

	ACTIVE: 'Ativo',
	INACTIVE: 'Desativado',
	LOST: 'Perdido',
	DAMAGED: 'Danificado',
	STOCK: 'Em estoque',
	STATUS: 'Status do dispositivo',

	// Events
	'E.IGN_ON': 'Ignição ligada',
	'E.IGN_OFF': 'Ignição desligada',
	'E.DIN_CHANGED': 'Mudança na entrada digital',
	'E.EXT_POWER_CUT_OFF': 'Corte de alimentação externa',
	'E.PANIC': 'Pânico',
	'E.LOW_BATTERY_VOLTAGE': 'Bateria fraca',
	'E.SHIFT_WARNING': 'Guinchado',
	'E.GPS_ANTENNA_OPEN': 'Antena GPS aberta',
	'E.GPS_ANTENNA_SHORT': 'Antena GPS em curto',
	'E.EXT_POWER_RECONNECTED': 'Energia externa reconectada',
	'E.UNDER_SPEED': 'Velocidade baixa',
	'E.OVER_SPEED': 'Alta velocidade',
	'E.INTO_FENCE': 'Entrou em cerca virtual',
	'E.OUT_FENCE': 'Saída de cerca virtual',
	'E.EXTERNAL_BATTERY_LOW': 'Bateria externa fraca',
	'E.DRIVER_OFF': 'Cartão ou motorista removido',
	'E.DRIVER_ON': 'Cartão ou motorista inserido'
};

export default locale;
