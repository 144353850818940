import { createSlice } from '@reduxjs/toolkit';
import { CourseType } from 'app/main/academy/components/types';
import { TLesson } from 'app/main/academy/types/types';

interface AcademyState {
    currentTab: string;
    currentCourse: CourseType | null;
    currentLesson: TLesson | null;
    currentEditLessonUrl: string;
}

const initialState: AcademyState = {
    currentTab: 'courses',
    currentCourse: null,
    currentLesson: null,
    currentEditLessonUrl: ''
};

const academySlice = createSlice({
    name: 'academy',
    initialState,
    reducers: {
        setCurrentTab(state, action) {
            state.currentTab = action.payload;
        },
        setCurrentCourse(state, action) {
            state.currentCourse = action.payload;
        },
        setCurrentLesson(state, action) {
            state.currentLesson = action.payload;
        },
        setCurrentEditLessonUrl(state, action) {
            state.currentEditLessonUrl = action.payload;
        }
    }
});

export const { setCurrentTab } = academySlice.actions;
export const { setCurrentCourse } = academySlice.actions;
export const { setCurrentLesson } = academySlice.actions;
export const { setCurrentEditLessonUrl } = academySlice.actions;

export const selectCurrentTab = (state: { academy: AcademyState }) => state.academy.currentTab;
export const selectCurrentCourse = (state: { academy: AcademyState }) => state.academy.currentCourse;
export const selectCurrentLesson = (state: { academy: AcademyState }) => state.academy.currentLesson;
export const selectCurrentEditLessonUrl = (state: { academy: AcademyState }) => state.academy.currentEditLessonUrl;
export default academySlice.reducer;
