import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type ScheduledCommandAbility = PureAbility<AbilityTuple<'scheduledCommandHistory'>, MatchConditions>;

const LIST_SCHEDULED_COMMAND_HISTORY_PERMISSIONS = ['command.history.list'];

const VIEW_SCHEDULED_COMMAND_HISTORY_PERMISSIONS = ['command.history.view'];

export const defineScheduledCommandHistoryAbility = () => {
	const { can, build } = new AbilityBuilder<ScheduledCommandAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_SCHEDULED_COMMAND_HISTORY_PERMISSIONS),
		create: [],
		edit: [],
		delete: [],
		list: getMissingPermissions(LIST_SCHEDULED_COMMAND_HISTORY_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('scheduledCommandHistory', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(VIEW_SCHEDULED_COMMAND_HISTORY_PERMISSIONS)) can('view', 'scheduledCommandHistory');
	if (userHasPermission(VIEW_SCHEDULED_COMMAND_HISTORY_PERMISSIONS)) can('list', 'scheduledCommandHistory');

	return [build(), missingPermissions] as const;
};
