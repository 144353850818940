import usePermission from 'app/services/hooks/usePermission';
import { useGetCourseCategoryQuery } from 'app/store/api/courseCategorySlice';
import { useGetCoursesQuery } from 'app/store/api/coursesSlice';
import { useTranslation } from 'react-i18next';
import { ACADEMY_COURSE_PERMISSIONS } from '../AcademyAppConfig';
import AcademyTabComponent from '../components/Tab';

function CoursesTab() {
	const { t } = useTranslation('academyPage');

	const permissions = usePermission({ pagePermissions: ACADEMY_COURSE_PERMISSIONS });
	const { categories } = useGetCourseCategoryQuery('?limit=0', {
		selectFromResult: ({ data, ...res }) => ({
			categories: data?.docs ?? [],
			...res
		})
	});

	const { courses } = useGetCoursesQuery('', {
		selectFromResult: ({ data, ...res }) => ({
			courses: data ?? [],
			...res
		})
	});
	return (
		<AcademyTabComponent
			content={courses}
			categories={categories}
			t={t}
			contentType="course"
			permissions={permissions}
		/>
	);
}

export default CoursesTab;
