import { Typography } from '@mui/material';
import { TFunction } from 'react-i18next';

interface INotificationEmptyProps {
	t: TFunction;
}

export default function NotificationEmpty({ t }: INotificationEmptyProps) {
	return (
		<div className="flex flex-1 items-center justify-center p-16">
			<Typography className="text-24 text-center" color="text.secondary">
				{t('NO_NOTIFICATIONS')}
			</Typography>
		</div>
	);
}
