import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/companies/tableColumns';
import { TCompany, TSidebarMode } from 'app/main/companies/types';
import storageService from 'app/services/storage';
import { RootState } from '..';

type TInitialState = {
    selectedRows: TCompany[];
    isSidebarOpen: boolean;
    sidebarMode: TSidebarMode;
    visibleColumns: string[];
};

export const initialState: TInitialState = {
    selectedRows: [],
    isSidebarOpen: false,
    sidebarMode: 'view',
    visibleColumns: storageService.get('companiesVisibleColumns') || defaultVisibleColumns
};

const companiesCrudSlice = createSlice({
    name: 'companiesCrud',
    initialState,
    reducers: {
        setSelectedRows: (state, action: PayloadAction<TCompany[]>) => {
            state.selectedRows = action.payload;
        },
        setVisibleColumns: (state, action: PayloadAction<string[]>) => {
            state.visibleColumns = action.payload;
            storageService.set('companiesVisibleColumns', action.payload);
        },
        setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
            if (!action.payload) {
                state.selectedRows = [];
                state.sidebarMode = 'view';
                state.isSidebarOpen = false;
            } else {
                state.isSidebarOpen = true;
            }
        },
        setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
            state.sidebarMode = action.payload;
            state.isSidebarOpen = true;
        },
		    resetSidebarState: (state) => {
          state.isSidebarOpen = false;
          state.selectedRows = [];
        }
    }
});

export const {
    setSelectedRows,
    setIsSidebarOpen,
    setSidebarMode,
    setVisibleColumns,
    resetSidebarState
} = companiesCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.companiesCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.companiesCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.companiesCrud.selectedRows;
export const selectVisibleColumns = (state: RootState) => state.companiesCrud.visibleColumns as string[];

export default companiesCrudSlice.reducer;
