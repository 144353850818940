import FusePageSimpleSidebar from '@fuse/core/FusePageSimple/FusePageSimpleSidebar';
import { Box } from '@mui/material';
import {
	selectCurrentRegisterDrawerForm,
	selectIsRegisterDrawerOpen,
	toggleRegisterDrawer
} from 'app/store/crud/coursesSlice';
import { useAppDispatch } from 'app/store/index';
import { useSelector } from 'react-redux';
import CategoryForm from './Forms/CategoryForm/CategoryForm';
import LessonForm from './Forms/LessonForm/LessonForm';

export default function RegisterComponent() {
	const dispatch = useAppDispatch();
	const isOpen = useSelector(selectIsRegisterDrawerOpen);
	const currentForm = useSelector(selectCurrentRegisterDrawerForm);

	const Form = {
		lesson: LessonForm,
		category: CategoryForm
	}[currentForm];

	const closeRegisterDrawer = () => dispatch(toggleRegisterDrawer());

	return (
		<FusePageSimpleSidebar open={isOpen} position="right" >
			<Box paddingTop="54px" />
			<Form onCancel={closeRegisterDrawer} onSuccess={closeRegisterDrawer} />
		</FusePageSimpleSidebar>
	);
}
