import { EntityState, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { useMemo } from 'react';

import {
	selectMonitoringAlertById,
	selectMonitoringAlertsEntities,
	useGetAlertsRealtimeQuery
} from 'app/store/api/monitoringAlertsSlice';
import { TMonitoringPaginatedQuery } from 'app/services/hooks/usePaginationQueryMonitoring';
import { TSelectedAlert } from 'app/store/monitoring';
import { GROUP_PROP_MAP, GROUP_TITLE } from './helpers';
import { MONITORING_POLLING } from './MonitoringConfig';
import { TMonitoringAlert, TMonitoringGroup } from './types';

type TUseKanbanDataProps = {
	selectedFilters: Partial<TMonitoringPaginatedQuery>;
	selectedGroup: Partial<TMonitoringGroup>;
	selectedAlert: TSelectedAlert;
	status: TMonitoringPaginatedQuery['status'];
};

export const useKanbanData = ({ selectedAlert, selectedFilters, selectedGroup, status }: TUseKanbanDataProps) => {
	const selectLocalAlerts = useMemo(() => {
		return createSelector(
			(res) => res.currentData,
			(_res, group) => group,
			(data: EntityState<TMonitoringAlert>, group: TMonitoringGroup) => {
				if (!data) return [];
				const alerts = selectMonitoringAlertsEntities(data);
				if (!group) {
					return alerts;
				} else {
					const grouped = _.chain(alerts)
						.groupBy(GROUP_PROP_MAP[group])
						.map((val, key) => ({
							title: _.get(val[0], GROUP_TITLE[group], ''),
							members: val,
							_id: `grouped-${status}-${key}`,
							groupedBy: group
						}))
						.value();
					return grouped;
				}
			}
		);
	}, [status]);

	const { alerts, loading, selected } = useGetAlertsRealtimeQuery(
		{ ...selectedFilters, status },
		{
			pollingInterval: MONITORING_POLLING,
			selectFromResult: (res) => {
				return {
					alerts: res.isSuccess ? selectLocalAlerts(res, selectedGroup) : [],
					selected:
						res.isSuccess && selectedAlert?.id
							? selectMonitoringAlertById(res.data, selectedAlert.id)
							: null,
					loading: res.isLoading
				};
			}
		}
	);

	return { alerts, loading, selected };
};
