import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import usePermission from 'app/services/hooks/usePermission';
import { useAppDispatch } from 'app/store/index';
import { selectTotal } from 'app/store/notifications/notificationsSlice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toggleNotificationPanel } from './store/stateSlice';

export const PERMISSIONS = {
	list: 'admin.notification.list',
	edit: '',
	view: '',
	new: '',
	delete: '',
	minimal: ['']
};
function NotificationPanelToggleButton() {
	const dispatch = useAppDispatch();
	const total = useSelector(selectTotal);
	const { t } = useTranslation('navigation');
	const permissions = usePermission({ pagePermissions: PERMISSIONS });

	return (
		<>
			{permissions.hasList && (
				<Tooltip title={t('NOTIFICATIONS')} placement="bottom">
					<IconButton
						className="w-40 h-40"
						onClick={(ev) => dispatch(toggleNotificationPanel())}
						size="large"
					>
						<Badge
							color="secondary"
							sx={{
								'.MuiBadge-badge': {
									fontSize: '1rem'
								}
							}}
							badgeContent={total || 0}
							max={99}
						>
							<FuseSvgIcon>heroicons-outline:bell</FuseSvgIcon>
						</Badge>
					</IconButton>
				</Tooltip>
			)}
		</>
	);
}

export default NotificationPanelToggleButton;
