import { ShareLocationOutlined } from '@mui/icons-material';
import { Divider, IconButton, Paper, Skeleton, Stack, Tooltip } from '@mui/material';
import { Address } from 'app/main/map/components/tracker-popup/Address';
import { PopupHeader } from 'app/main/map/components/tracker-popup/PopupHeader';
import { TrackerData } from 'app/main/map/components/tracker-popup/TrackerData';
import { getCopyString } from 'app/main/map/components/tracker-popup/TrackerPopup';
import useTrackerGeoLocation from 'app/main/map/hooks/useTrackerGeoLocation';
import { getTrackerClass } from 'app/main/map/mapHelpers';
import { TTracker } from 'app/main/map/types';
import { goToStreetView } from 'app/main/reports/historyNew/helper';
import CopyButton from 'app/shared-components/Buttons/CopyButton';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface PublicVehicleProps {
	vehicle: TTracker;
	isFetching: boolean;
	isLoading: boolean;
}

const Wrapper = ({ children }: { children: React.ReactNode }) => (
	<Paper elevation={2} className="w-full min-h-[224px] rounded-lg flex flex-col">
		{children}
	</Paper>
);

const PublicVehicleSkeleton = () => (
	<Wrapper>
		<Stack direction="row" alignItems="start" gap={2} width="100%" p={2}>
			<Skeleton variant="circular" height={40} width={40} />
			<Stack width="50%">
				<Skeleton variant="text" width="100%" height={24} />
				<Skeleton variant="text" width="50%" height={15} />
			</Stack>
		</Stack>
		<Stack p={2} gap={2}>
			<Skeleton variant="text" width="80%" height={24} />
			<Stack direction="row" gap={2}>
				<Skeleton variant="rounded" width={48} height={24} />
				<Skeleton variant="rounded" width={48} height={24} />
				<Skeleton variant="rounded" width={48} height={24} />
				<Skeleton variant="rounded" width={48} height={24} />
			</Stack>
		</Stack>
	</Wrapper>
);

export const PublicVehicle: React.FC<PublicVehicleProps> = ({ vehicle, isLoading, isFetching }) => {
	const { t } = useTranslation(['PublicLinkPage', 'mapPage']);
	const coords: [number, number] = [_.get(vehicle, 'packet.LONGITUDE', 0), _.get(vehicle, 'packet.LATITUDE', 0)];
	const { address, isLoadingAddress, hasCoords } = useTrackerGeoLocation(coords);

	const copyString = getCopyString(vehicle, coords, address, t);

	const handleGoogleMap = () => {
		if (hasCoords) goToStreetView(coords[1], coords[0]);
	};

	if (isLoading) {
		return <PublicVehicleSkeleton />;
	}

	return (
		<Wrapper>
			<PopupHeader
				company={_.get(vehicle, 'company.name', '')}
				displayName={_.get(vehicle, 'vehicle.name', '')}
				iconName={_.get(vehicle, 'vehicle.type.icon', '')}
				trackerClass={getTrackerClass(vehicle)}
				loading={isFetching}
			/>
			<Divider />
			<Stack direction="row" alignItems="start" p={2} gap={4}>
				<Address address={address} loading={isLoadingAddress} />
				<Stack direction="row">
					<Tooltip title={t('TO_GOOGLE_MAPS', { ns: 'mapPage' })}>
						<IconButton size="small" color="secondary" onClick={handleGoogleMap}>
							<ShareLocationOutlined />
						</IconButton>
					</Tooltip>
					<CopyButton mode="text" data={copyString} tooltipText={t('COPY_INFO', { ns: 'mapPage' })} />
				</Stack>
			</Stack>
			<TrackerData
				hasBatteryStatus={_.has(vehicle, 'packet.BATTERY_STATUS')}
				batteryStatus={_.get(vehicle, 'packet.BATTERY_STATUS')}
				vehicleVoltage={_.get(vehicle, 'packet.VEHICLE_VOLTAGE', 0)}
				ignition={_.get(vehicle, 'packet.IGNITION', false)}
				speed={_.get(vehicle, 'packet.SPEED', 0)}
				isBlocked={_.get(vehicle, 'packet.IS_LOGICALLY_BLOCKED', false)}
				canBeBlocked={_.get(vehicle, 'canBeBlocked', false)}
				isRealtimeWaiting={_.get(vehicle, 'packet.IS_WAITING_BLOCK', false)}
				sx={{ mt: 'auto' }}
			/>
		</Wrapper>
	);
};
