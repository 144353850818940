import _ from 'lodash';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AppButton from 'app/shared-components/Buttons/AppButton';
import { selectOnGoingGroup, selectToDoGroup } from 'app/store/monitoring';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { TMonitoringPaginatedQuery } from 'app/services/hooks/usePaginationQueryMonitoring';
import { TMonitoringGroup } from '../../types';

type TGroupFilters = {
	groups: TMonitoringGroup[];
	column: TMonitoringPaginatedQuery['status'];
	onSubmit: (_selectedGroup: TMonitoringGroup) => void;
};

export default function GroupFilters({ groups = [], onSubmit, column = 'toDo' }: TGroupFilters) {
	const { t } = useTranslation('monitoringPage');
	const dispatch = useDispatch();
	const selectedGroup = useSelector(column === 'onGoing' ? selectOnGoingGroup : selectToDoGroup);
	const [localGroup, setLocalGroup] = useState<TMonitoringGroup>();

	// look how the FormControlLabel name prop is important here
	const handleLocalChange = (ev: ChangeEvent<HTMLInputElement>) =>
		ev.target.checked ? setLocalGroup(ev.target.name as TMonitoringGroup) : setLocalGroup(null);

	const handleSubmit = (ev) => {
		ev.preventDefault();
		onSubmit(localGroup);
		dispatch(closeDialog());
	};

	useEffect(() => {
		setLocalGroup(selectedGroup);
	}, [selectedGroup]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				maxWidth: '100%',
				gap: 2
			}}
			component="form"
			onSubmit={handleSubmit}
		>
			<FormControl component="fieldset" variant="standard">
				<FormLabel component="legend">{t('GROUP_BY')}</FormLabel>
				<FormGroup>
					{_.map(groups, (value) => (
						<FormControlLabel
							control={<Checkbox checked={localGroup === value} onChange={handleLocalChange} />}
							label={t(value.toUpperCase())}
							key={value}
							name={value}
						/>
					))}
				</FormGroup>
			</FormControl>

			<Box
				sx={{
					width: '100%'
				}}
			>
				<AppButton variant="contained" color="secondary" type="submit" fullWidth>
					{t('APPLY')}
				</AppButton>
			</Box>
		</Box>
	);
}
