import { base64Decode, base64Encode } from '../crypto/crypto';

const storageService = {
	set: (key: string, value: any) => {
		const _key = base64Encode(key);
		const _value = base64Encode(JSON.stringify(value));
		localStorage.setItem(_key, _value);
	},

	get: (key: string) => {
		const _key = base64Encode(key);
		const _value = localStorage.getItem(_key);
		if (_value) {
			return JSON.parse(base64Decode(_value));
		}
		return false;
	},

	has: (key: string) => {
		const _key = base64Encode(key);
		return localStorage.getItem(_key) !== null;
	},

	remove: (key: string) => {
		const _key = base64Encode(key);
		localStorage.removeItem(_key);
	},

	clear: () => {
		localStorage.clear();
	}
};

export default storageService;
