import { Button, Popover, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { ChangeEvent, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TSavePopover = {
	isOpen: boolean;
	handleSave: (_n: string) => void;
	handleClose: () => void;
};
// eslint-disable-next-line react/display-name
const SaveFilterPopover = forwardRef<HTMLButtonElement, TSavePopover>(({ handleClose, handleSave, isOpen }, ref) => {
	const { t } = useTranslation('mapPage');
	const [newFilterName, setNewFilterName] = useState('');

	const handleNewFilterName = (ev: ChangeEvent<HTMLInputElement>) => {
		setNewFilterName(ev.target.value);
	};

	const saveWrapper = () => {
		handleSave(newFilterName);
		setNewFilterName('');
	};

	const closeWrapper = () => {
		handleClose();
		setNewFilterName('');
	};

	return (
		<Popover
			open={isOpen}
			anchorEl={ref}
			onClose={closeWrapper}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
		>
			<Stack direction="row" spacing={1} padding={1}>
				<TextField
					placeholder={t('NAME')}
					variant="standard"
					value={newFilterName}
					onChange={handleNewFilterName}
				/>
				<Button
					size="small"
					variant="contained"
					onClick={saveWrapper}
					color="primary"
					disabled={_.isEmpty(newFilterName)}
				>
					Ok
				</Button>
			</Stack>
		</Popover>
	);
});

export default SaveFilterPopover;
