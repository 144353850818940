import { TUsePermissionReturn } from 'app/services/hooks/usePermission';
import { TIdName, TPaginatedQuery } from 'app/store/api/types';
import { TRedisTrackerPacket } from 'app/types/tracker.types';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { T } from 'vitest/dist/reporters-2ff87305.js';
import { THost } from '../../map/types';
export interface TrackerSearchParams extends TPaginatedQuery {
	vehicle?: string;
	company?: string;
	simcard?: string;
	type?: string;
	status?: string;
	deleted?: boolean;
	queryId?: string;
}
export type TrackerSearchParamsFilters = Omit<TrackerSearchParams, 'page' | 'limit'>;

export enum TrackerStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	DAMAGED = 'damaged',
	LOST = 'lost',
	STOCK = 'stock',
	DELETED = 'deleted'
}

export interface TTracker {
	_id: string;
	did: number;
	name: string;
	status: TrackerStatus;
	simcard: TSimcard;
	notes: string;
	imgUrl: string;
	trackerType: TrackerType;
	vehicle: Vehicle;
	company: Company;
	createdBy: TIdName;
	deletedBy?: TIdName;
	externalId: string;
	importServiceId: string;
	deleted?: boolean;
	createdAt: Date;
	updatedAt: Date;
	deletedAt?: Date;
	__v: number;
	packet?: TRedisTrackerPacket;
	canBeBlocked?: boolean;
}

export interface TSimcard {
	_id: string;
	iccid: string;
	phone: string;
	brand: {
		_id: string;
		name: string;
	};
}

export interface Vehicle {
	_id: string;
	name: string;
	plate: string;
	type: Type;
}

export interface Type {
	_id: string;
	name: string;
	icon: string;
}

export interface Company {
	_id: string;
	name: string;
}

export interface TrackerType {
	_id: string;
	port?: string;
	parser?: string;
	name: string;
	model: string;
	manufacturer: string;
}
export type TTrackerColumn = TableColumn<TTracker>;
export type TColumnsNames = { name: string; columnId: string };
export type TSidebarMode = 'edit' | 'create' | 'view' | 'editMultiple' | 'multipleActions' | 'noPermission';
export type TRequestMessagesKey = 'edit' | 'create' | 'editMultiple' | 'delete' | 'massiveCreate' | 'restore';
export type TRequestMessagesValues = { success: string; error: string };
export type TRequestMessages = { [key in TRequestMessagesKey]: TRequestMessagesValues };
export type THostWName = { companyName: string } & THost;
export type TContent = {
	columns: TTrackerColumn[];
	data: TTracker[];
	onChangeLimit: (_currentRowsPerPage: number) => void;
	onChangePage: (_page: number) => void;
	onChangeSort: (_selectedColumn: TableColumn<T>, _sortDirection: SortOrder) => void;
	loading: boolean;
	totalRows: number;
	paginationData: TPaginatedQuery;
};
export interface THeader {
	paginationData: TPaginatedQuery;
	totalItems: number;
	onChangeSearch: (_text: string) => void;
	onChangeFilter: (_s: string, _c: string[], _o: string[]) => void;
	defaultValue: string;
	visibleColumns: string[];
	currentOtherFilters: string[];
	permissions: TUsePermissionReturn;
	currentSearchKey: string;
	loading: boolean;
}
export interface TFormMode {
	row: TTracker;
}

export interface TViewMode {
	row: TTracker;
}

export interface TSideBar {
	queryId: string;
	showDeleted: boolean;
}
