import { AuthContext } from 'app/auth/Auth';
import { useCreatePublicLinkMutation, useGetPublicVehicleQuery } from 'app/store/api/public-link-slice-api';
import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function usePublicToken() {
	const [searchParams] = useSearchParams();
	const [createPublicLink, { isLoading: isCreatingPublicLink }] = useCreatePublicLinkMutation();
	const { signOut } = useContext(AuthContext);
	const token = searchParams.get('token');

	const {
		data,
		isError,
		isLoading: isVehicleLoading,
		isFetching: isVehicleFetching
	} = useGetPublicVehicleQuery(token, {
		selectFromResult: (res) => ({
			...res
		}),
		skip: !token,
		pollingInterval: 10000
	});

	useEffect(() => {
		if (!isError) return;
		signOut();
	}, [isError]);

	return {
		token,
		createPublicLink,
		isCreatingPublicLink,
		vehicle: data?.vehicle ?? null,
		settings: data?.settings ?? null,
		isVehicleFetching,
		isVehicleLoading
	};
}
