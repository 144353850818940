import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import dayjsduration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import i18next from 'i18next';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';

import * as Sentry from '@sentry/react';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { ErrorBoundary } from 'react-error-boundary';
import { injectExceptionErrorsTranslation } from '../utils/exceptionErrors';
import AuthProvider from './auth/Auth';
import { RoutesAuthorization } from './auth/RoutesAuthorization/RoutesAuthorization';
import DownloadProvider from './contexts/DownloadContext/DownloadContext';
import WidgetProvider from './contexts/WidgetContext';
import { ErrorFallbackCompontent } from './shared-components/ErrorFallbackComponent';
import withAppProviders from './withAppProviders';

dayjs.extend(dayjsduration);
dayjs.extend(utc);

axios.defaults.baseURL = '';

const emotionCacheOptions = {
	rtl: {
		key: 'muirtl',
		stylisPlugins: [rtlPlugin],
		insertionPoint: document.getElementById('emotion-insertion-point')
	},
	ltr: {
		key: 'muiltr',
		stylisPlugins: [],
		insertionPoint: document.getElementById('emotion-insertion-point')
	}
};

const pt = { CANCEL: 'Cancelar', SELECT_DATE: 'Selecione a data' };
const es = { CANCEL: 'Cancelar', SELECT_DATE: 'Seleccione la fecha' };
const en = { CANCEL: 'Cancel', SELECT_DATE: 'Select date' };

i18next.addResourceBundle('pt', 'provider', pt);
i18next.addResourceBundle('en', 'provider', en);
i18next.addResourceBundle('sp', 'provider', es);

injectExceptionErrorsTranslation();

const App = () => {
	const langDirection = useSelector(selectCurrentLanguageDirection);
	const mainTheme = useSelector(selectMainTheme);
	const { t } = useTranslation('provider');

	const handleOnError = (error: Error) => {
		Sentry.captureException(error);
	};

	return (
		<CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
			<FuseTheme theme={mainTheme} direction={langDirection}>
				<BrowserRouter>
					<ErrorBoundary FallbackComponent={ErrorFallbackCompontent} onError={handleOnError}>
						<AuthProvider>
							<WidgetProvider>
								<RoutesAuthorization>
									<LocalizationProvider
										dateAdapter={AdapterDayjs}
										adapterLocale="pt-br"
										localeText={{
											okButtonLabel: 'Ok',
											cancelButtonLabel: t('CANCEL'),
											timePickerToolbarTitle: t('SELECT_TIME')
										}}
									>
										<SnackbarProvider
											maxSnack={5}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'right'
											}}
											classes={{
												containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
											}}
										>
											<DownloadProvider>
												<FuseLayout layouts={themeLayouts} />
											</DownloadProvider>
										</SnackbarProvider>
									</LocalizationProvider>
								</RoutesAuthorization>
							</WidgetProvider>
						</AuthProvider>
					</ErrorBoundary>
				</BrowserRouter>
			</FuseTheme>
		</CacheProvider>
	);
};

const AppWithProvider = withAppProviders(App)();

export default AppWithProvider;
