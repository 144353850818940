import dayjs from 'dayjs';

import { TFunction } from 'i18next';

import _ from 'lodash';

import { TExcelColumn } from 'app/types/excel-column.types';
import { FiltersFormValues } from './components/filters/form-schema';
import { HistoryCommandSearchParamsFilters, TScheduledHistoryCommand } from './types';
import { TCommandStatus } from '../types';

export function getHistoryCommandLastExecution(scheduledCommandHistory: TScheduledHistoryCommand) {
	const history = _.get(scheduledCommandHistory, 'history', []);

	const lastElement = _.last(history);
	if (!lastElement) return '';

	return dayjs(lastElement.executedAt).format('DD/MM/YYYY HH:mm');
}

export const getExcelColumns = (t: TFunction): TExcelColumn<TScheduledHistoryCommand>[] => [
	{
		label: t('ID'),
		value: '_id'
	},
	// {
	// 	label: t('RULE_NAME'),
	// 	value: (row) => row?.rule?.name
	// },
	{
		label: t('SCHEDULED_COMMAND_NAME'),
		value: (row) => row?.scheduledCommand?.scheduledCommandName
	},
	{
		label: t('STATUS'),
		value: (row) => row?.history[row?.history.length - 1].status
	},
	{
		label: t('RESPONSE'),
		value: (row) => row?.response
	},
	{
		label: t('NEXT_EXECUTION'),
		value: (row) => row.nextExecution && dayjs(row.nextExecution).format('DD/MM/YYYY HH:mm')
	},
	{
		label: t('SCHEDULED_AT'),
		value: (row) => row.scheduledAt && dayjs(row.scheduledAt).format('DD/MM/YYYY HH:mm')
	},
	{
		label: t('EXECUTED_AT'),
		value: (row) => getHistoryCommandLastExecution(row)
	}
];

export function parseFormValuesToFilter(formValues: FiltersFormValues): HistoryCommandSearchParamsFilters {

	const scheduledCommandId = _.get(formValues, 'scheduledCommandId._id', '');
	const lastStatus: TCommandStatus = _.get(formValues, 'lastStatus', '') as TCommandStatus;

	return {
		...formValues,
		scheduledCommandId: scheduledCommandId || null,
		lastStatus
	};
}
