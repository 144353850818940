const locale = {
	MUST_BE_LIGTH: 'El color debe ser claro',
	MUST_BE_DARK: 'El color debe ser oscuro',
	EDIT_PALETTE: 'Editar paleta',
	LIGHT: 'Claro',
	DARK: 'Oscuro',
	PREVIEW: 'Previsualización',
	CANCEL: 'Cancelar',
	SAVE: 'Guardar',
	PRIMARY_COLOR: 'Color primario',
	SECONDARY_COLOR: 'Color secundario',
	DEFAULT_BACKGROUND: 'Color de fondo predeterminado',
	PAPER_BACKGROUND: 'Color de fondo del papel'
};

export default locale;
