import { SettingsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import _ from 'lodash';
import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

import { openDialog } from 'app/store/fuse/dialogSlice';
import { TTracker } from '../../types';
import CardActionModal from '../CardActionModal';

interface ICardsActions {
	tracker: TTracker;
}

export default function CardActions({ tracker }: ICardsActions) {
	const dispatch = useDispatch();
	const coordinates: [number, number] = [_.get(tracker, 'packet.LONGITUDE', 0), _.get(tracker, 'packet.LATITUDE', 0)];
	const invalidCoordinates = coordinates.some((coord) => coord > 90 || coord < -90);

	const handleMenuIconClick = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(
			openDialog({
				children: <CardActionModal tracker={tracker} />
			})
		);
	};
	return (
		<IconButton
			onClick={handleMenuIconClick}
			aria-label="Card Actions"
			id="item-menu-btn"
			color="secondary"
			disabled={invalidCoordinates}
			sx={{
				padding: 0
			}}
		>
			<SettingsOutlined />
		</IconButton>
	);
}
