import { Download } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { useGetVehicleTypesQuery } from 'app/store/api/apiSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { Vehicle } from '../../types';
import SyncAllModal from './SyncAllModal';
import VehicleRow from './VehicleRow';
interface VehicleListProps {
	vehicles: Vehicle[];
	disabled: boolean;
	t: TFunction;
	prop?: string;
}

const VehicleList = ({ vehicles, disabled, t, prop = 'veiculos' }: VehicleListProps) => {
	const dispatch = useAppDispatch();

	const { vehiclesTypes, isVehiclesTypesLoading } = useGetVehicleTypesQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			vehiclesTypes:
				data?.docs.map((vehicleType) => ({ ...vehicleType, name: _.capitalize(vehicleType?.name) })) || [],
			isVehiclesTypesLoading: isLoading
		})
	});

	const handleSyncAll = () => {
		dispatch(openDialog({ children: <SyncAllModal type={prop} vehicleTypes={vehiclesTypes} /> }));
	};

	return (
		<Stack
			direction={'column'}
			sx={{ width: '100%', opacity: disabled ? 0.4 : 1 }}
			spacing={1}
			alignItems="center"
			justifyContent="center"
			paddingY={2}
		>
			<Stack direction="column" sx={{ width: '100%', marginTop: 2 }} spacing={1} alignItems="center">
				<Typography
					sx={{
						fontSize: ['18px', '24px'],
						fontWeight: 600,
						color: (theme) => theme.palette.secondary.main,
						textAlign: 'center'
					}}
				>
					{prop === 'heavyVeiculos' ? t('ASSOCIATE_HEAVY_VEHICLES') : t('ASSOCIATE_VEHICLES')}
				</Typography>
				<Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
					<AppButton
						variant="contained"
						color="secondary"
						size="small"
						onClick={handleSyncAll}
						endIcon={<Download />}
						disabled={vehicles?.length === 0 || disabled}
						sx={{ minWidth: '200px', marginRight: 2 }}
					>
						{t('SYNC_ALL')}
					</AppButton>
				</Stack>
			</Stack>
			{vehicles?.map((veiculo) => (
				<VehicleRow
					veiculo={veiculo}
					t={t}
					vehiclesTypes={vehiclesTypes}
					isVehiclesTypesLoading={isVehiclesTypesLoading}
					key={veiculo?.codigo_veiculo}
					prop={prop}
				/>
			))}
		</Stack>
	);
};

export default VehicleList;
