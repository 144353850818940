import * as Plot from '@observablehq/plot';
import { ReactNode, useContext, useEffect, useRef } from 'react';

import { ChartContext } from './ChartContainer';

type TBarChartProps = {
	data: object[];
	loading?: boolean;
	barOptions?: Plot.BarYOptions;
	xScaleOptions?: Plot.ScaleOptions;
	yScaleOptions?: Plot.ScaleOptions;
	marginBottom?: number;
	noDataComp?: ReactNode;
};

function BarChart({
	data = [],
	barOptions = {},
	xScaleOptions = {},
	yScaleOptions = {},
	marginBottom = undefined,
	loading = false,
	noDataComp
}: TBarChartProps) {
	const containerRef = useRef<HTMLDivElement>();
	const { dimensions } = useContext(ChartContext);

	useEffect(() => {
		if (!data.length || !containerRef.current) return;
		const plot = Plot.plot({
			height: dimensions.height,
			width: dimensions?.width,
			marginBottom: marginBottom,
			y: { grid: true, label: null, ...yScaleOptions },
			x: { label: null, ...xScaleOptions },
			marks: [Plot.barY(data, { x: 'label', y: 'value', ...barOptions })]
		});
		containerRef.current.append(plot);
		return () => plot.remove();
	}, [data, dimensions, barOptions, xScaleOptions, yScaleOptions, marginBottom]);

	return loading || !data.length ? noDataComp : <div ref={containerRef} className="h-full" />;
}

export { BarChart };
