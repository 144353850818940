import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { TTrackerClass } from 'app/main/map/types';
import { RootState } from 'app/store/index';

export type TActionMenuOptions = 'stateFilter' | 'geoFenceFilter';

type TInitialState = {
	isLabelsVisible: boolean;
	isClusterCountVisible: boolean;
	stateFilter: TTrackerClass[];
	selectedMenu?: TActionMenuOptions;
	geoFenceFilter: string[];
};

const initialState: TInitialState = {
	isClusterCountVisible: true,
	isLabelsVisible: false,
	stateFilter: [],
	selectedMenu: null,
	geoFenceFilter: []
};

const actionBarSlice = createSlice({
	name: 'actionBar',
	initialState,
	reducers: {
		setLabelsVisible: (state, action: PayloadAction<boolean>) => {
			state.isLabelsVisible = action.payload;
		},
		setClusterCountVisible: (state, action: PayloadAction<boolean>) => {
			state.isClusterCountVisible = action.payload;
		},
		toggleLabelsVisible: (state) => {
			state.isLabelsVisible = !state.isLabelsVisible;
		},
		toggleCCVisible: (state) => {
			state.isClusterCountVisible = !state.isClusterCountVisible;
		},
		setStateFilter: (state, action: PayloadAction<TTrackerClass[]>) => {
			state.stateFilter = action.payload;
		},
		setMenuVisible: (state, action: PayloadAction<TActionMenuOptions>) => {
			const current = state.selectedMenu;
			if (current === action.payload) {
				state.selectedMenu = null;
			} else {
				state.selectedMenu = action.payload;
			}
		},
		closeMenu: (state) => {
			state.selectedMenu = null;
		},
		setGeoFenceFilter: (state, action: PayloadAction<string[]>) => {
			state.geoFenceFilter = action.payload;
		},
		setInitialState: () => initialState
	}
});
export const {
	setClusterCountVisible,
	setLabelsVisible,
	toggleLabelsVisible,
	toggleCCVisible,
	setStateFilter,
	closeMenu,
	setMenuVisible,
	setGeoFenceFilter,
	setInitialState
} = actionBarSlice.actions;

export const selectActionMenu = (state: RootState) => state.actionBar.selectedMenu as TActionMenuOptions;
export const selectIsLabelsVisible = (state: RootState) => state.actionBar.isLabelsVisible as boolean;
export const selectIsClusterCountVisible = (state: RootState) => state.actionBar.isClusterCountVisible as boolean;
export const selectStateFilter = (state: RootState) => state.actionBar.stateFilter as TTrackerClass[];
export const selectGeoFenceFilter = (state: RootState) => state.actionBar.geoFenceFilter as string[];
export const selectStateFilterLen = createSelector(
	[selectStateFilter, selectActionMenu],
	(arr, selectedMenu) => arr.length > 0 || selectedMenu === 'stateFilter'
);
export const selectGeoFenceLen = createSelector(
	[selectGeoFenceFilter, selectActionMenu],
	(arr, selectedMenu) => arr.length > 0 || selectedMenu === 'geoFenceFilter'
);
export default actionBarSlice.reducer;
