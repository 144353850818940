const locale = {
	TITLE: 'Contatos',

	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	OF: 'de',
	EDIT: 'Editar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',

	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	WHATSAPP_NOT_FOUND: 'O número de WhatsApp não é válido',

	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',

	ROWS_PER_PAGE: 'Itens por página',

	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	USER_REQUIRED: 'O campo usuário é obrigatório',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	EMAIL_INVALID: 'O campo e-mail é inválido',
	WHATSAPP_REQUIRED: 'O campo WhatsApp é obrigatório',

	NAME: 'Nome',
	USER: 'Usuário',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',

	ADD_CONTACTS: 'Adicionar contatos',
	CREATE_SUCCESS: 'Contato adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar contato',
	EDIT_SUCCESS: 'Contato atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar contato',
	DELETE_SUCCESS: 'Contato deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar contato',
	NO_CONTACT_FOUND: 'Nenhum contato foi encontrado',

	LOADING: 'Carregando',
	DELETE_MODAL_TITLE: 'Deletar contato',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este contato?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} contatos?',
	EDIT_INFO: 'Editar contato',
	DELETE_INFO: 'Deletar contato',
	DOWNLOAD_INFO: 'Baixar contatos selecionados',
	EDIT_MULTIPLE_INFO: 'Editar contatos selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar contatos selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar contatos',
	EDIT_MULTIPLE_SUCCESS: 'Contatos editados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar contatos',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',

	CONTACT_INFO: 'Ao cadastar uma regra de notificação, os contatos escolhidos devem pertencer a mesma empresa.'
};

export default locale;
