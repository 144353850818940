import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import type { Props } from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { useGetAllMonitoringEventsQuery } from 'app/store/api/monitoringEventsSlice';
import { ForwardedRef, forwardRef } from 'react';
import _ from 'src/@lodash/@lodash';

interface TrackerEventIconProps extends Omit<Props, 'children'> {
	eventCode: string;
}

const eventsQuery = {
	limit: 0,
	page: 1
};

export const TrackerEventIcon = forwardRef<ForwardedRef<HTMLElement>, TrackerEventIconProps>(
	({ eventCode, ...props }, ref) => {
		const { trackerEvents, isLoading } = useGetAllMonitoringEventsQuery(eventsQuery, {
			selectFromResult: ({ data, ...res }) => ({
				trackerEvents: data?.docs || [],
				...res
			})
		});

		if (isLoading || !eventCode) return null;
		const current = _.find(trackerEvents, { eventCode });
		const isUnknown = eventCode === 'UNKNOWN';

		if (!current && !isUnknown) return null;

		return (
			<FuseSvgIcon {...props} ref={ref}>
				{isUnknown ? 'heroicons-outline:question-mark-circle' : current?.icon}
			</FuseSvgIcon>
		);
	}
);

TrackerEventIcon.displayName = 'TrackerEventIcon';
