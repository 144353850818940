import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/fleet/tableColumns';
import { TFleet, TSidebarMode } from 'app/main/fleet/types';
import storageService from 'app/services/storage';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TFleet[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	visibleColumns: string[];
	isRegisterDrawerOpen: boolean;
	currentRegisterDrawerForm: string;
};
const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view',
	visibleColumns: storageService.get('fleetsVisibleColumns') || defaultVisibleColumns,
	isRegisterDrawerOpen: false,
	currentRegisterDrawerForm: 'vehicle'
};

const fleetsCrudSlice = createSlice({
	name: 'fleetsCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TFleet[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('fleetsVisibleColumns', action.payload);
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		},
		toggleRegisterDrawer: (state) => {
			state.isRegisterDrawerOpen = !state.isRegisterDrawerOpen;
		},
		setCurrentRegisterDrawerForm: (state, action: PayloadAction<string>) => {
			state.currentRegisterDrawerForm = action.payload;
		}
	}
});

export const {
	setSelectedRows,
	setIsSidebarOpen,
	setSidebarMode,
	setVisibleColumns,
	resetSidebarState,
	toggleRegisterDrawer,
	setCurrentRegisterDrawerForm
} = fleetsCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.fleetsCrud.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.fleetsCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.fleetsCrud.selectedRows as TFleet[];

export const selectVisibleColumns = (state: RootState) => state.fleetsCrud.visibleColumns as string[];

export const selectIsRegisterDrawerOpen = (state: RootState) => state.fleetsCrud.isRegisterDrawerOpen;
export const selectCurrentRegisterDrawerForm = (state: RootState) => state.fleetsCrud.currentRegisterDrawerForm;

export default fleetsCrudSlice.reducer;
