const locale = {
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',
	TITLE: 'Urls',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	ON_DISCONNECT_ERROR: 'Error disconnecting WhatsApp url',
	DISCONNECT: 'Disconnect',
	QR_CODE_INFO: 'To log in to the url, scan the QR Code above',
	SELECTED_COMPANIES: '{{n}} Selected Companies',
	ALREADY_IN_SESSION: 'There is already an active url for the companies: {{companies}}',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	DATE: 'Date',
	OF: 'of',
	ROWS_PER_PAGE: 'Items per page',
	COMPANY_REQUIRED: 'The company field is required',
	NAME_REQUIRED: 'The name field is required',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	ADD_SESSION: 'Add urls',
	CREATE_SUCCESS: 'Url successfully added',
	CREATE_ERROR: 'Error adding url',
	EDIT_SUCCESS: 'Url successfully updated',
	EDIT_ERROR: 'Error updating url',
	DELETE_SUCCESS: 'Url successfully deleted',
	DELETE_ERROR: 'Error deleting url',
	NO_SESSION_FOUND: 'No url found',
	LOADING: 'Loading',
	DELETE_MODAL_TITLE: 'Delete url',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this url?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} urls?',
	EDIT_INFO: 'Edit url',
	DELETE_INFO: 'Delete url',
	DOWNLOAD_INFO: 'Download selected urls',
	EDIT_MULTIPLE_INFO: 'Edit selected urls',
	DELETE_MULTIPLE_INFO: 'Delete selected urls',
	SELECTED_ITENS: '{{n}} selected items',
	DOWNLOAD_ERROR: 'Error downloading urls',
	EDIT_MULTIPLE_SUCCESS: 'Urls edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing urls',
	NO_URL_FOUND: 'No URL found',

	// Default url warning
	DEFAULT_URL_WARNING: 'You are using the default URL',
	DEFAULT_URL_WARNING_DESCRIPTION: 'To use a custom theme, you must have a registered domain and add it to your company.'
};

export default locale;
