import i18next from 'i18next';

import addTranslation from 'app/shared-components/CRUDs/Header/headerTranslation';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const CompaniesPage = lazyRetry(() => import('./Companies'));

i18next.addResourceBundle('en', 'companiesPage', en);
i18next.addResourceBundle('sp', 'companiesPage', sp);
i18next.addResourceBundle('pt', 'companiesPage', pt);
addTranslation();

export const COMPANIES_PERMISSIONS = {
	edit: 'admin.company.edit',
	view: 'admin.company.view',
	list: 'admin.company.list',
	new: 'admin.company.new',
	delete: 'admin.company.delete',
	minimal: ['admin.company.list', 'admin.url.list']
};

const CompaniesConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.company.list'],
			path: 'admin/company',
			element: (
				<LazyLoaderSuspense>
					<CompaniesPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<CompaniesPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default CompaniesConfig;
