const locale = {
	TITLE: 'Companies',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	DESCRIPTION: 'Description',
	CUSTOMER_TYPE: 'Customer Type',
	PJ: 'Legal Person',
	PF: 'Individual Person',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-GB',
	ADDRESS: 'Address',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Phone',
	SELECTED_ITEMS: 'Selected Items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	NAME_REQUIRED: 'The name field is required',
	PHONE_REQUIRED: 'The phone field is required',
	EMAIL_REQUIRED: 'The email field is required',
	ADDRESS_REQUIRED: 'The address field is required',
	TYPE_REQUIRED: 'The customer type field is required',
	CONFIG_TABLE: 'Configure table',
	COLUMN_NAME: 'Name',
	COLUMN_COMPANY: 'Company',
	COLUMN_BIRTHDAY: 'Birthday',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Created by',
	COLUMN_CREATED_AT: 'Created at',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Address',
	COLUMN_PHONE: 'Phone',
	NAME: 'Name',
	CODE: 'Code',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete company',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Editing company',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this company?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected companies?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	FIELD_NAME: 'Name',
	FIELD_CODE: 'Code',
	FIELD_ICON: 'Icon',
	FIELD_DESC: 'Description',
	FIELD_CREATED_AT: 'Created at',
	FIELD_CREATED_BY: 'Created by',
	ERROR_PERMISSION: 'You do not have the necessary permissions, please contact the system administrator',
	ERROR_GETTING_USERS: 'There was an error loading the companies, please try again later',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Internal server error, please try again later',
	ERROR_NETWORK: 'Network error, check your connection and try again',
	ADD_COMPANY: 'Add company',
	CREATE_SUCCESS: 'Company added successfully',
	CREATE_ERROR: 'Error adding company',
	EDIT_SUCCESS: 'Company updated successfully',
	EDIT_ERROR: 'Error updating company',
	ERROR_ADD_EMPTY: 'Fill in all fields',
	DELETE_SUCCESS: 'Company deleted successfully',
	DELETE_ERROR: 'Error deleting company',
	NO_DATA: 'No corresponding items found',
	NO_RESULTS: 'No results found',
	NO_PERMISSION_list: 'You do not have permission to view the list of companies',
	NO_PERMISSION_new: 'You do not have permission to add companies',
	NO_PERMISSION_edit: 'You do not have permission to edit companies',
	NO_PERMISSION_view: 'You do not have permission to view companies',
	NO_PERMISSION_delete: 'You do not have permission to delete companies',
	NO_ADDRESS: 'Address not found',
	NO_PHONE: 'Phone not found',
	NO_EMAIL: 'Email not found',
	LOADING: 'Loading',
	MORE_THAN: 'more than',
	NO_COMPANIES_FOUND: 'No company found',
	DELETE_MODAL_TITLE: 'Delete company',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this company?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} companies?',
	EDIT_INFO: 'Edit company',
	DELETE_INFO: 'Delete company',
	DOWNLOAD_INFO: 'Download selected companies',
	DOWNLOAD_ALL: 'Download all',
	EDIT_MULTIPLE_INFO: 'Edit selected companies',
	DELETE_MULTIPLE_INFO: 'Delete selected companies',
	SELECTED_ITENS: '{{n}} selected items',
	DOWNLOAD_ERROR: 'Error downloading companies',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Selected Companies',
	SELECTED_HOSTS: '{{n}} Selected Hosts',
	PROFILE_REQUIRED: 'The Profile field is required',
	CPASSWORD_REQUIRED: 'The Confirm Password field is required',
	EDIT_MULTIPLE_SUCCESS: 'Companies edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing companies',
	PROFILE_IMAGE_TO_LARGE: 'Please choose an image smaller than 8MB',
	COMPANY_REQUIRED: 'The Company field is required',
	EMAIL_INVALID: 'Invalid email',
	HOST_REQUIRED: 'The Host field is required',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',
	START_DATE: 'Start Date',
	END_DATE: 'End Date',
	CLEAR_FILTERS: 'Clear filters',
	SHOW_FILTERS: 'Show filters',

	BILLABLE: 'Billable',
	NON_BILLABLE: 'Non-billable',
	BILLABLE_COMPANY: 'Billable Company',
	BILLABLE_COMPANY_REQUIRED: 'The Billable Company field is required',
	BILLABLE_TOOLTIP: 'If the company is not billable, select the corresponding billable company',

	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	LOST: 'Lost',
	DAMAGED: 'Damaged',
	STOCK: 'Stock',
	TRACKERS: 'Trackers',
	VEHICLES: 'Vehicles'
};

export default locale;
