import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { TuneOutlined } from '@mui/icons-material';
import { Badge, Box, IconButton, Paper } from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { openDialog } from 'app/store/fuse/dialogSlice';
import {
	selectOnGoingFilters,
	selectOnGoingGroup,
	selectSelectedAlert,
	setDraggedAlert,
	updateOnGoingGroup
} from 'app/store/monitoring';

import { useKanbanData } from '../hooks';
import { TMonitoringGroup } from '../types';
import MonitoringFilterDialog from './filters/MonitoringFilterDialog';
import OnGoingFilters from './filters/OnGoingFilters';
import VirtualListItem from './items/VirtualListItem';
import CardTitle from './shared/CardTitle';
import SkeletonColumn from './shared/SkeletonColumn';

const AVAILABLE_GROUPS: TMonitoringGroup[] = ['alarm', 'tracker', 'user', 'vehicle'];

export default function OnGoingWrapper() {
	const { t } = useTranslation('monitoringPage');
	const dispatch = useDispatch();
	const { setNodeRef: setOnGoingRef } = useSortable({ id: 'onGoingRef' });
	const selectedAlert = useSelector(selectSelectedAlert);
	const selectedFilters = useSelector(selectOnGoingFilters);
	const selectedGroup = useSelector(selectOnGoingGroup);
	const { alerts, loading, selected } = useKanbanData({
		selectedAlert,
		selectedFilters,
		selectedGroup,
		status: 'onGoing'
	});

	const numberOfFiltersApplied = _.filter([
		selectedFilters.severity,
		selectedFilters.did,
		selectedFilters.search
	])?.length;

	const alertsIds = selectedGroup ? [] : alerts.map((a) => a._id);

	const handleGroupSubmit = (group: TMonitoringGroup) => dispatch(updateOnGoingGroup(group));

	const onOpenFiltersModal = () => {
		dispatch(
			openDialog({
				children: (
					<MonitoringFilterDialog
						groups={AVAILABLE_GROUPS}
						onSubmitGroup={handleGroupSubmit}
						column="onGoing"
					>
						<OnGoingFilters />
					</MonitoringFilterDialog>
				)
			})
		);
	};

	useEffect(() => {
		if (selected) {
			dispatch(setDraggedAlert(selected));
		}
	}, [dispatch, selected]);

	return (
		<SortableContext items={alertsIds} strategy={verticalListSortingStrategy} id="onGoing">
			<Paper
				variant="outlined"
				sx={{
					padding: [1, 6],
					overflowY: 'auto',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					width: ['100%', '680px']
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
					<CardTitle title={<Trans t={t} i18nKey="ONGOING_OCCURRENCES_N" values={{ n: alerts.length }} />} />
					<IconButton onClick={onOpenFiltersModal}>
						<Badge badgeContent={numberOfFiltersApplied} color="primary">
							<TuneOutlined />
						</Badge>
					</IconButton>
				</Box>
				<Box
					sx={{
						flex: 2,
						overflowY: 'auto'
					}}
				>
					{loading ? (
						<SkeletonColumn column="onGoing" />
					) : (
						<Virtuoso
							style={{ width: '100%' }}
							data={alerts}
							itemContent={VirtualListItem}
							scrollerRef={setOnGoingRef}
						/>
					)}
				</Box>
			</Paper>
		</SortableContext>
	);
}
