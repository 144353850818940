const locale = {
	// Common

	HOST: 'Host',
	DISPLAY_NAME: 'Nome de exibição',
	HOME: 'Página inicial',

	TRACKER_LAYOUT: 'Layout do card',
	TRACKER_LAYOUT_INFO: 'Layout do Card no mapa',
	TRACKER_LAYOUT_INFO_2: 'O layout do card é o estilo do card que aparecera na lista de rastreadores do mapa.',

	THEME: 'Tema',
	MAIN_PALETTE: 'Principal',
	NAVBAR_PALETTE: 'Barra de navegação',
	TOOLBAR_PALETTE: 'Barra de ferramentas',
	FOOTER_PALETTE: 'Rodapé',
	LAYOUT: 'Layout',
	LAYOUT_STYLE: 'Estilo do layout',
	LAYOUT_STYLE_NOTE: 'Nem todas as opçoes estão disponíveis para todos os layouts',
	OTHER: 'Outros',
	CUSTOM_SCROLLBARS: 'Barras de rolagem personalizadas',
	DIRECTION: 'Direção',
	Navbar: 'Barra de navegação',
	Position: 'Posição',
	Style: 'Estilo',

	LOGO_DARK_200x40: 'Logo escura (Horizontal)',
	LOGO_DARK_200x40_INFO: 'Logo na horizontal, usada para temas claros',

	LOGO_DARK_40x40: 'Logo escura',
	LOGO_DARK_40x40_INFO: 'Usada para temas claros',

	LOGO_LIGHT_200x40: 'Logo clara (Horizontal)',
	LOGO_LIGHT_200x40_INFO: 'Logo na horizontal, usada para temas escuros',

	LOGO_LIGHT_40x40: 'Logo clara',
	LOGO_LIGHT_40x40_INFO: 'Usada para temas escuros',

	LOGO_URL: 'Logo padrão',
	LOGO_URL_INFO: 'Usada para temas claros e escuros',

	primary: 'Primário',
	secondary: 'Secundário',
	tertiary: 'Terciário',
	Left: 'Esquerda',
	Right: 'Direita',
	'Slide (style-1)': 'Slide (estilo-1)',
	'Folded (style-2)': 'Folded (estilo-2)',
	// Required Fields
	HOST_REQUIRED: 'O campo host é obrigatório',
	DISPLAY_NAME_REQUIRED: 'O campo nome de exibição é obrigatório',
	HOME_REQUIRED: 'O campo home é obrigatório',
	LOGO_URL_REQUIRED: 'O campo logo padrão é obrigatório',
	TRACKER_LAYOUT_REQUIRED: 'O campo layout do card é obrigatório',
	LOGO_LIGHT_40x40_REQUIRED: 'O campo logo clara é obrigatório',
	LOGO_LIGHT_200x40_REQUIRED: 'O campo logo clara (horizontal) é obrigatório',
	LOGO_DARK_200x40_REQUIRED: 'O campo logo escura (horizontal) é obrigatório',
	LOGO_DARK_40X40_REQUIRED: 'O campo logo escura é obrigatório',
	LOGO_DARK_200X40_REQUIRED: 'O campo logo escura (horizontal) é obrigatório',
	LOGO_LIGHT_40X40_REQUIRED: 'O campo logo clara é obrigatório',
	LOGO_LIGHT_200X40_REQUIRED: 'O campo logo clara (horizontal) é obrigatório',

	// ======================================= //
	TITLE: 'Urls',

	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',

	ON_DISCONNECT_ERROR: 'Erro ao desconectar url do WhatsApp',
	DISCONNECT: 'Desconectar',
	QR_CODE_INFO: 'Para fazer login na url, escaneie o QR Code acima',

	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	ALREADY_IN_SESSION: 'Já existe uma url ativa para as empresas: {{companies}}',

	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',

	DATE: 'Data',
	OF: 'de',

	ROWS_PER_PAGE: 'Itens por página',

	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	NAME_REQUIRED: 'O campo nome é obrigatório',

	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',

	ADD_URL: 'Adicionar url',
	CREATE_SUCCESS: 'Url adicionada com sucesso',
	CREATE_ERROR: 'Erro ao adicionar Url',
	EDIT_SUCCESS: 'Url atualizada com sucesso',
	EDIT_ERROR: 'Erro ao atualizar url',
	DELETE_SUCCESS: 'Url deletada com sucesso',
	DELETE_ERROR: 'Erro ao deletar url',
	NO_URLS_FOUND: 'Nenhuma url foi encontrada',
	UPLOAD_IMAGE_ERROR: 'Erro ao fazer upload de alguma imagem',

	LOADING: 'Carregando',
	DELETE_MODAL_TITLE: 'Deletar url',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta url?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} urls?',
	EDIT_INFO: 'Editar url',
	DELETE_INFO: 'Deletar url',
	DOWNLOAD_INFO: 'Baixar urls selecionados',
	EDIT_MULTIPLE_INFO: 'Editar urls selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar urls selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar urls',
	EDIT_MULTIPLE_SUCCESS: 'Urls editadas com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar urls',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	NO_URL_FOUND: 'Nenhuma URL foi encontrada',

	// Default url warning
	DEFAULT_URL_WARNING: 'Você está utilizando a URL padrão',
	DEFAULT_URL_WARNING_DESCRIPTION: 'Para utilizar um tema personalizado é necessário possuir um domínio cadastrado e adicioná-lo a sua empresa.'
};

export default locale;
