import Chip from '@mui/material/Chip';

function CourseCategory({ categoryName }: { categoryName: string }) {
  return (
    <Chip
      className="font-semibold text-12"
      label={categoryName}
      size="small"
    />
  );
}

export default CourseCategory;
