
import { base64Decode, base64Encode } from 'app/services/crypto/crypto';
import { useState } from 'react';

export const useLocalStorage = <T>(key: string, defaultValue: T) => {
	const encodedKey = base64Encode(key);

	const [localStorageValue, setLocalStorageValue] = useState<T>(() => {
		const encodedInitialValue = defaultValue ? base64Encode(JSON.stringify(defaultValue)) : '';

		try {
			const value = localStorage.getItem(encodedKey);
			const decodedValue = base64Decode(value);

			if (decodedValue) {
				return JSON.parse(decodedValue);
			} else {
				localStorage.setItem(encodedKey, encodedInitialValue);
				return defaultValue;
			}
		} catch (error) {
			localStorage.setItem(encodedKey, encodedInitialValue);
			return defaultValue;
		}
	});

	const setLocalStorageStateValue = (valueOrFn: T | ((_prevValue: T) => T)) => {
		let newValue: T;
		if (typeof valueOrFn === 'function') {
			const fn = valueOrFn as (_prevValue: T) => T;
			newValue = fn(localStorageValue);
		} else {
			newValue = valueOrFn;
		}

		const encodedValue = base64Encode(JSON.stringify(newValue));
		localStorage.setItem(encodedKey, encodedValue);
		setLocalStorageValue(newValue);
	};

	return [localStorageValue, setLocalStorageStateValue] as const;
};
