import { SearchOutlined, TuneOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { useDebounce } from 'app/services/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SearchBar = styled(TextField)(({ theme }) => ({
	'& .MuiInputBase-root': {
		backgroundColor: theme.palette.background.paper,
		borderRadius: '99999px',
		height: '40px',
		paddingLeft: '8px'
	},
	// label
	'& .MuiInputLabel-root': {
		color: theme.palette.text.secondary,
		'&.Mui-focused': {
			color: theme.palette.text.secondary
		}
	}
}));

type TSearchFieldWithFilterProps = TextFieldProps & {
	onOpenFilter: () => void;
	handleChange: (t: string) => void;
	defaultValue?: string;
	noFilters?: boolean;
};

type TSearchFieldWithoutFilterProps = TextFieldProps & {
	handleChange: (t: string) => void;
	defaultValue?: string;
	noFilters: true;
	onOpenFilter?: never;
};

type TSearchFieldProps = TSearchFieldWithFilterProps | TSearchFieldWithoutFilterProps;

export default function SearchInput({
	handleChange,
	onOpenFilter,
	defaultValue = '',
	noFilters,
	...props
}: TSearchFieldProps) {
	const { t } = useTranslation('Header');
	const [text, setText] = useState(defaultValue);
	const debouncedSearch: string = useDebounce(text, 500);
	const handleTextChange = (ev: ChangeEvent<HTMLInputElement>) => setText(ev.target.value);

	useEffect(() => {
		handleChange(debouncedSearch);
	}, [debouncedSearch, handleChange]);

	return (
		<SearchBar
			placeholder={t('SEARCH')}
			onChange={handleTextChange}
			value={text}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchOutlined color="secondary" />
					</InputAdornment>
				),
				startAdornment: !noFilters && (
					<Tooltip title={t('FILTER')} color="secondary">
						<IconButton color="secondary" size="small" onClick={onOpenFilter}>
							<TuneOutlined />
						</IconButton>
					</Tooltip>
				)
			}}
			{...props}
		/>
	);
}
