const locale = {
	TITLE: 'Usuários',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfis',
	PROFILE: 'Perfil',
	USERS: 'Usuários',
	USER: 'Usuário',
	BIRTHDAY: 'Data de nascimento',
	PASSWORD: 'Senha',
	CPASSWORD: 'Confirmar senha',
	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts acessíveis',
	ADDRESS: 'Endereço',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Telefone',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	ROWS_PER_PAGE: 'Itens por página',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	PHONE_REQUIRED: 'O campo telefone é obrigatório',
	EMAIL_REQUIRED: 'O campo email é obrigatório',
	ADDRESS_REQUIRED: 'O campo endereço é obrigatório',
	TYPE_REQUIRED: 'O campo tipo de cliente é obrigatório',
	COLUMN_NAME: 'Nome',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Data de nascimento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Criado por',
	COLUMN_CREATED_AT: 'Criado em',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Endereço',
	COLUMN_PHONE: 'Telefone',
	NAME: 'Nome',
	CODE: 'Código',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar usuário',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando usuário',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar este usuário?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar os usuários selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	FIELD_NAME: 'Nome',
	FIELD_CODE: 'Código',
	FIELD_ICON: 'Ícone',
	FIELD_DESC: 'Descrição',
	FIELD_CREATED_AT: 'Criado em',
	FIELD_CREATED_BY: 'Criado por',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar os usuários, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_USER: 'Adicionar usuário',
	CREATE_SUCCESS: 'Usuário adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar usuário',
	EDIT_SUCCESS: 'Usuário atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar usuário',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Usuário deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar usuário',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de usuários',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar usuários',
	NO_PERMISSION_edit: 'Você não possui permissão para editar usuários',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar usuários',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar usuários',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'Email não encontrado',
	NO_BIRTHDAY: 'Data de nascimento não encontrada',
	LOADING: 'Carregando',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
	PASSWORD_MIN_LENGTH: 'A senha deve ter no mínimo 8 caracteres',
	PASSWORDS_MUST_MATCH: 'As senhas devem ser iguais',
	PASSWORD_MIN_LOWERCASE: 'A senha deve ter no mínimo 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'A senha deve ter no mínimo 1 letra maiúscula',
	PASSWORD_MIN_NUMBERS: 'A senha deve ter no mínimo 1 número',
	PASSWORD_MIN_SYMBOLS: 'A senha deve ter no mínimo 1 caractére especial',
	PASSWORD_REQUIRED: 'O campo senha é obrigatório',
	MORE_THAN: 'mais que',
	NO_USER_FOUND: 'Nenhum usuário foi encontrado',
	DELETE_MODAL_TITLE: 'Deletar usuário',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este usuário?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} usuários?',
	EDIT_INFO: 'Editar usuário',
	DELETE_INFO: 'Deletar usuário',
	DOWNLOAD_INFO: 'Baixar usuários selecionados',
	EDIT_MULTIPLE_INFO: 'Editar usuários selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar usuários selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar usuários',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar Senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS: 'Usuários editados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar usuários',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem meno que 8MB',
	COMPANY_REQUIRED: 'O campo Empresa é obrigatório',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	EMAIL_ERROR: 'Email já cadastrado',
	PASSWORDS_NOT_MATCH: 'As senhas não conferem',
	HOST_MIN_1: 'O usuário deve ter pelo menos um host',
	LOGIN_AS: 'Entrar com este usuário',
	HOST_WARNING: 'Selecione uma Empresa para mostrar seus Hosts.',

	/* Content Control */
	VIEW_ALL_FLEETS: 'Todas as Frotas',
	VIEW_NONE_FLEETS: 'Nenhuma Frota',
	VIEW_SOME_FLEETS: 'Apenas as Frotas:',
	CONTENT_CONTROL_FLEETS: 'Controle de acesso à frotas',
	SELECTED_FLEETS: '{{n}} frotas selecionadas',
	FLEETS: 'Frotas',
	N_FLEETS: '{{n}} Frotas',

	VIEW_ALL_VEHICLES: 'Todos os Veículos',
	VIEW_NONE_VEHICLES: 'Nenhum Veículo',
	VIEW_SOME_VEHICLES: 'Apenas os Veículos:',
	CONTENT_CONTROL_VEHICLES: 'Controle de acesso à veículos',
	SELECTED_VEHICLES: '{{n}} veículos selecionadas',
	VEHICLES: 'Veículos',
	N_VEHICLES: '{{n}} Veículos',
	EMAIL_OR_USERNAME: 'Email ou Nome de Usuário',
	ERROR_EMAIL_OR_USERNAME_REQUIRED: 'O campo "Email ou Nome de Usuário" é obrigatório',
	PLATFORM_DATA: 'Dados de acesso à plataforma',
	FLEET_WARNING: 'Selecione uma Empresa para mostrar suas Frotas.',
	VEHICLE_WARNING: 'Selecione uma Empresa para mostrar seus Veículos.',
	CHANGE_PASSWORD: 'Alterar senha',
	APPLY: 'Aplicar',
	CHANGE_PASSWORD_SUCCESS: 'Senha alterada com sucesso',
	CHANGE_PASSWORD_ERROR: 'Error ao alterar a senha',

	SHOW_FILTERS: 'Mostrar filtros',
	CLEAR_FILTERS: 'Limpar filtros',
	CONFIG_TABLE: 'Configurar tabela',
	BLOCKED: 'Usuários bloqueados',
	UNBLOCKED: 'Usuários desbloqueados',
	UNBLOCK_USER_SUCCESS: 'Usuário desbloqueado com sucesso',
	UNBLOCK_USER_ERROR: 'Erro ao desbloquear usuário',
	BLOCK_USER_SUCCESS: 'Usuário bloqueado com sucesso',
	BLOCK_USER_ERROR: 'Erro ao bloquear usuário',
	BLOCK_USER: 'Bloquear',
	UNBLOCK_USER: 'Desbloquear',
	BLOCK_USER_PRIMARY: 'Você tem certeza que deseja bloquear este usuário?',
	UNBLOCK_USER_PRIMARY: 'Você tem certeza que deseja desbloquear este usuário?',
	IS_BLOCKED: 'Bloqueado',
	LOGIN_SETTINGS: 'Horários de acesso',

	ACCESSIBLE_COMPANIES: 'Acesso a Empresas',
	USER_COMPANY: 'Empresa do Usuário',

	ACCESSIBLE_COMPANIES_REQUIRED: 'O campo "Acesso a Empresas" é obrigatório',
	USER_COMPANY_REQUIRED: 'O campo "Empresa do Usuário" é obrigatório',

};

export default locale;
