import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import i18next from 'i18next';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

i18next.addResourceBundle('pt', 'geoFenceReportPage', pt);
i18next.addResourceBundle('en', 'geoFenceReportPage', en);
i18next.addResourceBundle('sp', 'geoFenceReportPage', sp);

const GeoFenceReportPage = lazyRetry(() => import('./GeoFenceReportPage'));

export const GeoFenceReportConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: 'reports.geofence-history.list',
			path: '/geo-fence/report',
			element: (
				<LazyLoaderSuspense>
					<GeoFenceReportPage />
				</LazyLoaderSuspense>
			)
		}
	]
};
