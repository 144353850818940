import { Done, FileCopyOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { useState } from 'react';

interface ICopyButtonProps {
	data?: string;
	size?: 'small' | 'medium' | 'large';
	mode: 'targetId' | 'text';
	tooltipText?: string;
	fontSize?: 'small' | 'medium' | 'large';
	variant?: 'outlined' | 'default';
	className?: string;
	share?: boolean;
}

export default function CopyButton({
	mode = 'text',
	data = '',
	size = 'small',
	tooltipText = '',
	fontSize = 'small',
	variant = 'default',
	className,
	share = false
}: ICopyButtonProps) {
	const [isCopied, setIsCopied] = useState(false);
	const theme = useTheme();

	const copyToClipboard = () => {
		if (mode === 'targetId') {
			const elem: HTMLElement | null = document.getElementById(data);

			if (elem?.textContent) {
				navigator.clipboard.writeText(elem.textContent);
				setIsCopied(true);
				setTimeout(() => setIsCopied(false), 1000);
			}
		} else {
			const canShare = navigator?.canShare?.();
			if (canShare && share) {
				navigator.share({ url: data });
			} else {
				navigator.clipboard.writeText(data);
				setIsCopied(true);
				setTimeout(() => setIsCopied(false), 1000);
			}
		}
	};

	const borderColor = isCopied ? theme.palette.success.main : theme.palette.divider;
	const borderStyle = `2px solid ${borderColor}`;

	return (
		<Tooltip title={tooltipText}>
			<IconButton
				sx={(theme) => ({
					border:
						variant === 'outlined'
							? borderStyle
							: 'none',
					':hover': {
						backgroundColor: variant === 'outlined' ? theme.palette.action.hover : 'none'
					}
				})}
				size={size}
				color={isCopied ? 'success' : 'secondary'}
				onClick={copyToClipboard}
				className={className}
			>
				{isCopied ? <Done fontSize={fontSize} /> : <FileCopyOutlined fontSize={fontSize} />}
			</IconButton>
		</Tooltip>
	);
}
