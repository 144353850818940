import { NotificationsActiveOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

export default function CardTitle({ title }) {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<NotificationsActiveOutlined fontSize="large" />
			<Typography>{title}</Typography>
		</Stack>
	);
}
