export default {
	PARTIAL: 'Parcial',
	FULL: 'Total',
	NO_ACCESS: 'Sem acesso',
	START: 'Início',
	END: 'Fim',
	TO: 'até',
	ACCESS_TYPE: 'Tipo de acesso',
	ACTIVE: 'Ativo',
	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	COMPANY: 'Empresa',

	NAME_REQUIRED: 'Nome é obrigatório',
	DESCRIPTION_REQUIRED: 'Descrição é obrigatória',
	COMPANY_REQUIRED: 'Empresa é obrigatória',

	MONDAY: 'Segunda-feira',
	TUESDAY: 'Terça-feira',
	WEDNESDAY: 'Quarta-feira',
	THURSDAY: 'Quinta-feira',
	FRIDAY: 'Sexta-feira',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',

	REMOVE_PERIOD: 'Remover período',
	ADD_PERIOD: 'Adicionar mais um período',

	SAVE: 'Salvar',
	CLEAR: 'Limpar',
	DELETE: 'Excluir',
	VINCULATE: 'Vincular',

	CONFIGURE_PERIODS: 'Configurar períodos',

	ACCESS_PERIOD_UPDATED_SUCCESS: 'Período de acesso atualizado com sucesso',
	ACCESS_PERIOD_CREATED_SUCCESS: 'Período de acesso criado com sucesso',

	ACCESS_PERIOD_UPDATED_ERROR: 'Erro ao atualizar período de acesso',
	ACCESS_PERIOD_CREATED_ERROR: 'Erro ao criar período de acesso',

	ACCESS_PERIOD_DELETED_SUCCESS: 'Período de acesso removido com sucesso',
	ACCESS_PERIOD_DELETED_ERROR: 'Erro ao remover período de acesso',

	DELETE_ACCESS_PERIOD_PRIMARY: 'Tem certeza que deseja remover o período de acesso',
	DELETE_ACCESS_PERIOD_SECONDARY: 'Essa ação não poderá ser desfeita',
	DELETE_ACCESS_PERIOD_TITLE: 'Remover período de acesso',
	PERIOD_NOT_OVERLAP: 'Os períodos não podem se sobrepor',
};
