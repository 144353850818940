import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { CircularProgress, Stack, Typography } from '@mui/material';

import { SearchOffOutlined } from '@mui/icons-material';

interface IProps {
	loading?: boolean;
	text: string;
}

export default function NoDataComponent({ loading = false, text }: IProps) {
	return (
		<div className="flex flex-col items-center content-center h-full mt-[50vh] mx-auto fixed inset-y-0 inset-x-0">
			{loading ? (
				<CircularProgress />
			) : (
				<>
					<FuseSvgIcon className="text-64" color="action">
						heroicons-outline:search-circle
					</FuseSvgIcon>
					<Typography className="text-20 mt-8 text-center" color="textSecondary">
						{text}
					</Typography>
				</>
			)}
		</div>
	);
}

type TNoFilterComponent = { text: string; textSecondary: string };

export function NoFilterComponent({ text, textSecondary }: TNoFilterComponent) {
	return (
		<Stack direction="column" spacing={1} alignItems="center" padding={2} textAlign="center">
			<Stack direction="row" spacing={1} alignItems="center">
				<Typography
					sx={{
						fontSize: ['18px', '24px'],
						fontWeight: 600,
						color: (theme) => theme.palette.secondary.main
					}}
				>
					{text}
				</Typography>
				<SearchOffOutlined fontSize="large" color="secondary" />
			</Stack>

			<Typography
				sx={{ fontSize: ['16px', '18px'], fontWeight: 400, color: (theme) => theme.palette.text.secondary }}
			>
				{textSecondary}
			</Typography>
		</Stack>
	);
}
