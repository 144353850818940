import { useDeepCompareEffect } from '@fuse/hooks';
import { TMonitoringEvent } from 'app/main/monitoring-event/types';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { useGetTrackerEventsQuery } from 'app/store/api/apiSlice';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { splitAndGetOption } from '../../../historyNew/helper';
import { TFormData } from '../filterSchema';

interface EventTypeSelectProps {
	initialValue: string;
	sx?: {};
	className?: string;
	fullWidth?: boolean;
	noHelperText?: boolean;
}

export const EventTypeSelect: React.FC<EventTypeSelectProps> = ({ initialValue, sx, fullWidth = true, className, noHelperText }) => {
	const { control, setValue } = useFormContext<TFormData>();
	const { t } = useTranslation('eventsReportPage');

	const { monitoringEvents, isMonitoringEventsLoading } = useGetTrackerEventsQuery('', {
		selectFromResult: ({ data, ...res }) => ({
			monitoringEvents: data?.docs ?? [],
			isMonitoringEventsLoading: res.isLoading
		})
	});

	useDeepCompareEffect(() => {
		if (!_.isEmpty(monitoringEvents) && initialValue) {
			setValue('eventType', splitAndGetOption(initialValue, monitoringEvents));
		}
	}, [monitoringEvents, initialValue]);

	return (
		<HookedVirtualizedChipSelect<TFormData, TMonitoringEvent>
			fullWidth={fullWidth}
			className={className}
			sx={sx}
			options={monitoringEvents}
			control={control}
			name="eventType"
			label={t('EVENT_TYPE')}
			optionLabel="name"
			t={t}
			loading={isMonitoringEventsLoading}
			limitText={(n) => t('EVENT_TYPES_SELECTED', { n })}
			maxListHeight={200}
			noHelperText={noHelperText}
		/>
	);
};
