import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/reports/block-command-history/tableColumns';
import storageService from 'app/services/storage';
import { RootState } from '..';

interface IInitialState {
	visibleColumns: string[];
	isSidebarOpen: boolean;
}

const initialState: IInitialState = {
	visibleColumns: storageService.get('blockCommandHistory.visibleColumns') || defaultVisibleColumns,
	isSidebarOpen: false
};

const blockCommandHistorySlice = createSlice({
	name: 'blockCommandHistory',
	initialState,
	reducers: {
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('blockCommandHistory.visibleColumns', action.payload);
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		}
	}
});

export const { setVisibleColumns, setIsSidebarOpen } = blockCommandHistorySlice.actions;

export const selectVisibleColumns = (state: RootState) => state.blockCommandHistory.visibleColumns;

export default blockCommandHistorySlice.reducer;
