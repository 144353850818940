const locale = {
	TITLE: 'Contacts',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	OF: 'of',
	EDIT: 'Edit',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',

	SELECTED_COMPANIES: '{{n}} Selected Companies',
	WHATSAPP_NOT_FOUND: 'Invalid WhatsApp number',

	COMPANY: 'Company',
	COMPANIES: 'Companies',

	ROWS_PER_PAGE: 'Items per page',

	COMPANY_REQUIRED: 'The company field is required',
	USER_REQUIRED: 'The user field is required',
	NAME_REQUIRED: 'The name field is required',
	EMAIL_INVALID: 'Invalid email address',
	WHATSAPP_REQUIRED: 'The WhatsApp field is required',

	NAME: 'Name',
	USER: 'User',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',

	ADD_CONTACTS: 'Add contacts',
	CREATE_SUCCESS: 'Contact added successfully',
	CREATE_ERROR: 'Error adding contact',
	EDIT_SUCCESS: 'Contact updated successfully',
	EDIT_ERROR: 'Error updating contact',
	DELETE_SUCCESS: 'Contact deleted successfully',
	DELETE_ERROR: 'Error deleting contact',
	NO_CONTACT_FOUND: 'No contacts found',

	LOADING: 'Loading',
	DELETE_MODAL_TITLE: 'Delete contact',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this contact?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} contacts?',
	EDIT_INFO: 'Edit contact',
	DELETE_INFO: 'Delete contact',
	DOWNLOAD_INFO: 'Download selected contacts',
	EDIT_MULTIPLE_INFO: 'Edit selected contacts',
	DELETE_MULTIPLE_INFO: 'Delete selected contacts',
	SELECTED_ITENS: '{{n}} selected items',
	DOWNLOAD_ERROR: 'Error downloading contacts',
	EDIT_MULTIPLE_SUCCESS: 'Contacts edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing contacts',
	PAGE_NOT_ALLOWED: 'You do not have permission to view this page.',

	CONTACT_INFO: 'When registering a notification rule, the chosen contacts must belong to the same company.'
};

export default locale;
