import { Stack, Typography } from '@mui/material';
import { SentimentDissatisfiedOutlined } from '@mui/icons-material';

type TNoTableData = { text: string };

export default function NoTableData({ text }: TNoTableData) {
	return (
		<Stack direction="column" spacing={1} alignItems="center" padding={2}>
			<SentimentDissatisfiedOutlined fontSize="large" color="secondary" />
			<Typography
				sx={{
					fontSize: ['16px', '18px'],
					fontWeight: 600,
					color: (theme) => theme.palette.secondary.main,
					textAlign: 'center'
				}}
			>
				{text}
			</Typography>
		</Stack>
	);
}
