const en = {
	NAME: 'Name',
	DESCRIPTION: 'Description',
	SELECTED_TAGS: '{{limit}} selected tags',
	TAGS: 'Tags',
	SELECTED_COMPANIES: '{{limit}} selected companies',
	COMPANIES: 'Companies',
	NAME_REQUIRED: 'Name is required',
	DESCRIPTION_REQUIRED: 'Description is required',
	TAGS_REQUIRED: 'Tags are required',
	COMPANIES_REQUIRED: 'Companies are required',
	EDIT: 'Edit',
	DELETE: 'Delete',
	CREATE: 'Create',
	DETAILS: 'Details',
	GEO_FENCES: 'Geo Fences',
	CLEAR: 'Clear',
	SAVE: 'Save',
	VIEW: 'View',
	SEARCH_ADDRESS: 'Search address',
	DRAW_POLYGON: 'Draw polygon',
	DELETE_POINT: 'Delete selected point or fence',
	SAVE_GEO_FENCE: 'Save geo fence',
	SUCCESS_SAVING_GEO_FENCE: 'Geo fence saved successfully',
	ERROR_SAVING_GEO_FENCE: 'Error saving geo fence',
	ADD: 'Add',
	LOADING: 'Loading...',
	CREATED_BY: 'Created by',
	UPDATED_BY: 'Updated by',
	CREATED_AT: 'Created at',
	UPDATED_AT: 'Updated at',
	NO_OPTIONS: 'No options found',
	GEOFENCE_DELETED_SUCCESSFULLY: 'Geo fence deleted successfully',
	ERROR_DELETING_GEOFENCE: 'Error deleting geo fence',
	DELETE_GEOFENCE: 'Delete geo fence',
	DELETE_GEOFENCE_PRIMARY: 'Are you sure you want to delete this geo fence?',
	DELETE_GEOFENCE_SECONDARY: 'This action cannot be undone.',
	DRAW_POLYGON_FIRST: 'Draw the polygon first',
	SELECT_RANGE: 'Select a range',
	SELECT_VEHICLE: 'Select a vehicle',
	BUILD_GEOFENCE: 'Build Geo Fence',
	VEHICLE: 'Vehicle',
	START_DATE: 'Start',
	END_DATE: 'End',
	SELECT_RADIUS: 'Select the radius in meters from the center point',
	CREATE_GEOFENCE_DESCRIPTION:
		'You can create a virtual fence based on the position history of a vehicle from a date range and a radius in meters.',
	ERROR_CREATING_GEOFENCE: 'Error creating geo fence',
	NO_POSITIONS_FOUND: 'There are not enough positions to create a geo fence.',
	ERROR_EMPTY_GEOJSON: 'Error saving geo fence. The polygon cannot be empty.',
	CREATE_TRIP_GEO_FENCE: 'Create trip-based geo fence',
};

export default en;
