import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { EntityState, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectAllTrackers, selectTrackerById, useGetRealtimeQuery } from 'app/store/api/trackingSlice';
import { selectStateFilter, setStateFilter } from 'app/store/map/actionBarSlice';
import { clearSelectedTrackerId, selectSelectedTrackerId } from 'app/store/map/mapSlice';
import { POLLING_INTERVAL } from '../../MapConfig';
import { getSelectedOptions, getTrackerClass } from '../../mapHelpers';
import { TTracker, TTrackerClass } from '../../types';

export default function StateFilterMenu() {
	const { t } = useTranslation('mapPage');
	const [searchParams] = useSearchParams();
	const filterId = searchParams.get('filterId') || '';
	const selectedTrackerId = useSelector(selectSelectedTrackerId);

	const selectStateCounters = useMemo(() => {
		const emptyObj: { [key in TTrackerClass]: number } = {
			'gps-error': 0,
			'moving-cars': 0,
			'not-connected-cars': 0,
			'stopped-cars': 0,
			'towed-cars': 0,
			'without-vehicle': 0,
			blocked: 0
		};
		return createSelector(
			(res) => res.currentData,
			(data: EntityState<TTracker>) => {
				if (!data) return emptyObj;
				const allTrackers = selectAllTrackers(data);
				const counters = _.chain(allTrackers)
					.map((tracker) => {
						const state = getTrackerClass(tracker);
						return { ...tracker, state };
					})
					.countBy('state')
					.value();

				return { ...emptyObj, ...counters };
			}
		);
	}, []);

	const { quantities, selectedTracker } = useGetRealtimeQuery(filterId, {
		pollingInterval: POLLING_INTERVAL,
		selectFromResult: (res) => ({
			quantities: selectStateCounters(res),
			selectedTracker: res.data && selectedTrackerId ? selectTrackerById(res.data, selectedTrackerId) : null
		})
	});

	const dispatch = useDispatch();
	const selectedOptions = useSelector(selectStateFilter);

	const TRACKER_STATES: { [key in TTrackerClass]: ReactNode } = useMemo(
		() => ({
			'gps-error': <Trans t={t} i18nKey="WRONG_POSITION_N" values={{ n: quantities['gps-error'] }} />,
			'moving-cars': <Trans t={t} i18nKey="MOVING_N" values={{ n: quantities['moving-cars'] }} />,
			'not-connected-cars': <Trans t={t} i18nKey="NO_COMM_N" values={{ n: quantities['not-connected-cars'] }} />,
			'stopped-cars': <Trans t={t} i18nKey="IDLE_N" values={{ n: quantities['stopped-cars'] }} />,
			'towed-cars': <Trans t={t} i18nKey="TOWED_N" values={{ n: quantities['towed-cars'] }} />,
			'without-vehicle': (
				<Trans t={t} i18nKey="WITHOUT_VEHICLE_N" values={{ n: quantities['without-vehicle'] }} />
			),
			blocked: <Trans t={t} i18nKey="BLOCKED_QUANTITY" values={{ n: quantities['blocked'] }} />
		}),
		[quantities, t]
	);

	const handleClick = (state: TTrackerClass) => {
		const newSelected: TTrackerClass[] = getSelectedOptions(state, selectedOptions);
		if (selectedTracker) {
			const trackerClass = getTrackerClass(selectedTracker);
			if (!newSelected.includes(trackerClass)) {
				dispatch(clearSelectedTrackerId());
			}
		}

		dispatch(setStateFilter(newSelected));
	};

	return (
		<List
			sx={{
				overflowY: 'auto',
				height: '100%'
			}}
		>
			{Object.keys(TRACKER_STATES).map((state: TTrackerClass) => (
				<ListItem disablePadding key={state} divider>
					<ListItemButton role={undefined} dense onClick={() => handleClick(state)}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={selectedOptions.includes(state)}
								tabIndex={-1}
								disableRipple
								inputProps={{ 'aria-labelledby': state }}
							/>
						</ListItemIcon>
						<ListItemText id={state} primary={TRACKER_STATES[state]} />
					</ListItemButton>
				</ListItem>
			))}
		</List>
	);
}
