import { HorizontalSplitOutlined, LandscapeOutlined, MapOutlined, VerticalSplitOutlined } from '@mui/icons-material';
import { TFunction } from 'react-i18next';
import InfoBox from './InfoBox';
import InfoItem from './InfoItem';

interface ViewCoordinatesProps {
	lat: number;
	lng: number;
	alt: number;
	t: TFunction;
}

export default function ViewCoordinates({ t, alt, lat, lng }: ViewCoordinatesProps) {
	return (
		<InfoBox label={t('COORDINATES')} icon={<MapOutlined color="secondary" />}>
			<InfoItem icon={<HorizontalSplitOutlined />} label={t('LAT')} value={Number(lat?.toFixed(5))} />
			<InfoItem icon={<VerticalSplitOutlined />} label={t('LNG')} value={Number(lng?.toFixed(5))} />
			<InfoItem icon={<LandscapeOutlined />} label={t('ALT')} value={alt ? `${alt}m` : 0} />
		</InfoBox>
	);
}
