import i18next from 'i18next';

import addTranslation from 'app/shared-components/CRUDs/Header/headerTranslation';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const SessionsPage = lazyRetry(() => import('./Sessions'));

i18next.addResourceBundle('en', 'sessionsPage', en);
i18next.addResourceBundle('sp', 'sessionsPage', sp);
i18next.addResourceBundle('pt', 'sessionsPage', pt);
addTranslation();

export const POOLING_QRCODE_INTERVAL = 5000;

export const SESSIONS_PERMISSIONS = {
	edit: 'admin.whatsapp-session.edit',
	view: 'admin.whatsapp-session.view',
	list: 'admin.whatsapp-session.list',
	new: 'admin.whatsapp-session.new',
	delete: 'admin.whatsapp-session.delete',
	minimal: []
};

const SessionsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.whatsapp-session.list'],
			path: 'admin/sessions',
			element: (
				<LazyLoaderSuspense>
					<SessionsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<SessionsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default SessionsConfig;
