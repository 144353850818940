import * as Plot from '@observablehq/plot';
import { ReactNode, useContext, useEffect, useRef } from 'react';
import { ChartContext } from './ChartContainer';

type LineChartProps = {
	data: object[];
	loading?: boolean;
	lineOptions?: Plot.LineYOptions;
	xScaleOptions?: Plot.ScaleOptions;
	yScaleOptions?: Plot.ScaleOptions;
	noDataComp?: ReactNode;
};

function LineChart({
	data = [],
	lineOptions = {},
	xScaleOptions = {},
	yScaleOptions = {},
	loading = false,
	noDataComp
}: LineChartProps) {
	const containerRef = useRef<HTMLDivElement>();
	const { dimensions } = useContext(ChartContext);

	useEffect(() => {
		if (!data.length || !containerRef.current) return;
		const plot = Plot.plot({
			...dimensions,
			y: { grid: true, label: null, ...yScaleOptions },
			x: { label: null, ...xScaleOptions },
			marks: [Plot.lineY(data, { x: 'label', y: 'value', ...lineOptions })]
		});
		containerRef.current.append(plot);
		return () => plot.remove();
	}, [data, lineOptions, xScaleOptions, yScaleOptions, dimensions]);

	return !data.length || loading ? noDataComp : <div ref={containerRef} className="h-full" />;
}

export { LineChart };
