import { FuelTypes } from '../../../../types/fuel-supply.types';

const pt = {
	SUPPLY_DATE: 'Data de Abastecimento',
	VEHICLE: 'Veículo',
	FUEL_TYPE: 'Tipo de Combustível',
	BACK: 'Voltar',
	[FuelTypes.BIODIESEL]: 'Biodiesel',
	[FuelTypes.CNG]: 'GNV',
	[FuelTypes.DIESEL]: 'Diesel',
	[FuelTypes.ETHANOL]: 'Etanol',
	[FuelTypes.GASOLINE]: 'Gasolina',
	ODOMETER: 'Odômetro',
	PRICE: 'Preço',
	FUEL_VOLUME: 'Quantidade de Combustível',
	FUEL_PRICE: 'Preço do Combustível',
	ESTABLISHMENT: 'Estabelecimento',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	FUEL_SUPPLY: 'Abastecimento',
	SHOW_FILTERS: 'Mostrar Filtros',
	SEARCH: 'Pesquisar',
	ADD_MORE: 'Adicionar',
	UPDATE: 'Editar',
	CONFIG_TABLE: 'Configurar Tabela',
	SUPPLY_INFORMATION: 'Informações do Abastecimento',
	VEHICLE_INFORMATION: 'Informações do Veículo',
	COMPANY: 'Empresa',
	PRICE_REQUIRED: 'Preço é obrigatório',
	FUEL_VOLUME_REQUIRED: 'Quantidade é obrigatória',
	FUEL_PRICE_REQUIRED: 'Preço do combustível é obrigatório',
	FUEL_TYPE_REQUIRED: 'Tipo de combustível é obrigatório',
	SUPPLY_DATE_REQUIRED: 'Data de abastecimento é obrigatória',
	STABILISHMENT_REQUIRED: 'Estabelecimento é obrigatório',
	VEHICLE_REQUIRED: 'Veículo é obrigatório',
	REGISTER_FUEL_SUPPLY: 'Registrar Abastecimento',
	UPDATE_FUEL_SUPPLY: 'Editar Abastecimento',
	ODOMETER_REQUIRED: 'Odômetro é obrigatório',
	TOTAL_PRICE: 'Preço Total',

	FUEL_SUPPLY_UPDATED: 'Abastecimento atualizado com sucesso',
	FUEL_SUPPLY_SAVED: 'Abastecimento salvo com sucesso',
	ERROR_WHILE_UPDATING: 'Erro ao atualizar abastecimento',
	ERROR_WHILE_SAVING: 'Erro ao salvar abastecimento',
	ADD_ESTABLISHMENT: 'Adicionar Estabelecimento',
	ESTABLISHMENT_NAME_REQUIRED: 'Nome é obrigatório',
	ESTABLISHMENT_NAME: 'Nome do Estabelecimento',
	SAVE: 'Salvar',
	ADD_ESTABLISHMENT_INFO:
		'Para criar um estabelecimento, clique no mapa ou no botão acima usando sua localização atual',
	FUEL_INFORMATION: 'Informações do Combustível',
	USE_MY_LOCATION: 'Minha Localização',
	ESTABLISHMENT_SAVED_SUCCESSFULLY: 'Estabelecimento salvo com sucesso',
	ERROR_SAVING_ESTABLISHMENT: 'Erro ao salvar estabelecimento',
	SELECT_ESTABLISHMENT: 'Selecione um estabelecimento',
	DELETE: 'Excluir',
	CONFIRM_DELETE: 'Confirmar exclusão',
	CANCEL_DELETE: 'Cancelar exclusão',
	ESTABLISHMENT_DELETED_SUCCESSFULLY: 'Estabelecimento excluído com sucesso',
	ERROR_DELETING_ESTABLISHMENT: 'Erro ao excluir estabelecimento',
	INVOICE_DROP: 'Adicionar nota fiscal aqui',
	MAX_FILES: 'Máximo de arquivos atingidos',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
};

export default pt;
