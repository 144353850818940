import usePermission from 'app/services/hooks/usePermission';
import { useGetCourseCategoryQuery } from 'app/store/api/courseCategorySlice';
import { useGetLessonsQuery } from 'app/store/api/lessonsSlice';
import { useTranslation } from 'react-i18next';
import { ACADEMY_COURSE_PERMISSIONS } from '../../AcademyAppConfig';
import AcademyTabComponent from '../Tab';

function Lessons() {
	const { t } = useTranslation('academyPage');

	const permissions = usePermission({ pagePermissions: ACADEMY_COURSE_PERMISSIONS });
	const { categories } = useGetCourseCategoryQuery('?limit=0', {
		selectFromResult: ({ data, ...res }) => ({
			categories: data?.docs ?? [],
			...res
		})
	});

	const { lessons } = useGetLessonsQuery('?limit=0', {
		selectFromResult: ({ data, ...res }) => ({
			lessons: data?.docs || [],
			...res
		})
	});
	return (
		<AcademyTabComponent
			content={lessons}
			categories={categories}
			t={t}
			contentType="lesson"
			permissions={permissions}
		/>
	);
}

export default Lessons;
