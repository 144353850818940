import _ from 'lodash';
import * as yup from 'yup';
import { TrackerStatus } from '../../types';

export const tableSchema = yup.object({
	did: yup.string().required('DID_REQUIRED').matches(/^\d+$/, 'DID_MUST_BE_NUMBER'),
	name: yup.string().required('NAME_REQUIRED'),
	status: yup.mixed().oneOf(_.values(TrackerStatus), 'WRONG_STATUS').required('STATUS_REQUIRED'),
	simcard: yup.string().optional(),
	notes: yup.string().optional(),
	vehicle: yup.string().optional().min(7, 'PLATE_LENGTH').max(7, 'PLATE_LENGTH'),
	trackerType: yup.string().required('TRACKER_TYPE_REQUIRED'),
	company: yup.string().required('COMPANY_REQUIRED').matches(/^\d+$/, 'COMPANY_MUST_BE_NUMBER')
});
