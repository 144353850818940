import { ExpandMoreOutlined } from '@mui/icons-material';
import { IconButton, IconButtonProps, styled } from '@mui/material';

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}
const ChevronButton = styled((props: ExpandMoreProps) => {
	return (
		<IconButton {...props}>
			<ExpandMoreOutlined />
		</IconButton>
	);
})(({ expand }) => ({
	transform: !expand ? 'rotate(180deg)' : 'rotate(0deg)',
	marginLeft: 'auto',
	transition: 'transform 0.3s'
}));

export default ChevronButton;
