import { alpha, Box } from '@mui/system';

const IconBox = ({ icon }) => {
	return (
		<Box
			sx={(theme) => ({
				backgroundColor: alpha(theme.palette.secondary.main, 0.12),
				p: 1,
				borderRadius: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: 40,
				height: 40
			})}
		>
			{icon}
		</Box>
	);
};

export default IconBox;
