import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TMonitoringEvent } from 'app/main/monitoring-event/types';
import { TSidebarMode } from 'app/main/users/types';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TMonitoringEvent[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
};

export const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view'
};

const monitoringEventsSlice = createSlice({
	name: 'monitoringEvents',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TMonitoringEvent[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			if (!action.payload) {
				state.selectedRows = [];
				state.sidebarMode = 'view';
				state.isSidebarOpen = false;
			} else {
				state.isSidebarOpen = true;
			}
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSelectedRows, setIsSidebarOpen, setSidebarMode, resetSidebarState } = monitoringEventsSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.monitoringEvents.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.monitoringEvents.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.monitoringEvents.selectedRows as TMonitoringEvent[];

export default monitoringEventsSlice.reducer;
