import FusePageSimple from '@fuse/core/FusePageSimple';
import { Box, Typography } from '@mui/material';
import { getUserAbility } from 'app/permissions';
import { defineIntegrationsAbility } from 'app/permissions/integrations-page';
import HeaderWrapper from 'app/shared-components/CRUDs/Header/HeaderWrapper';
import NoPermission from 'app/shared-components/NoPermission';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IntegrationsCard from './components/IntegrationCard';
import HinovaConfigureDialog from './hinova/configureDialog';
import { useFindCompanyTokenQuery } from './hinova/sync/store/integrate-hinova-api';

interface Integration {
	id: number;
	name: string;
	image: string;
	description: string;
	configureDialog: React.ReactNode;
	handleConnect: () => void;
	disabled?: boolean;
}

const IntegrationsPage: React.FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('integrationsPage');
	const { token } = useFindCompanyTokenQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			token: data || null,
			isLoadingUrls: isLoading
		})
	});
	const integrations: Integration[] = [
		{
			id: 1,
			name: 'Hinova',
			image: 'https://hinova.com.br/wp-content/uploads//2021/06/simbolo-hinova.svg',
			description: 'Hinova é uma plataforma de gestão de manutenção',
			configureDialog: <HinovaConfigureDialog />,
			handleConnect: () => {
				navigate('/integrations/hinova');
			},
			disabled: !token
		}
	];
	const containerRef = useRef();

	const userAbility = getUserAbility();
	const [, { view }] = defineIntegrationsAbility();

	return (
		<FusePageSimple
			header={
				<HeaderWrapper>
					<Box
						sx={{
							display: 'flex',
							justifyContent: ['space-between', 'flex-start'],
							alignItems: ['center', 'flex-start'],
							flexDirection: ['row', 'column'],
							gap: ['0px', '8px'],
							width: ['100%', 'fit-content'],
							flexWrap: 'wrap'
						}}
					>
						<Typography
							sx={{
								fontSize: ['20px', '24px'],
								fontWeight: 700
							}}
						>
							{t('INTEGRATIONS')}
						</Typography>
					</Box>
				</HeaderWrapper>
			}
			content={
				userAbility.can('view', 'integrations') ? (
					<Box
						sx={{
							width: '100%',
							height: '100%',
							paddingX: ['20px', '40px'],
							paddingBottom: ['18px', '0px'],
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							overflowY: 'auto'
						}}
						ref={containerRef}
					>
						<IntegrationsCard t={t} integrations={integrations} />
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%'
						}}
					>
						<NoPermission t={t} permissionCodes={view} />
					</Box>
				)
			}
		/>
	);
};

export default IntegrationsPage;
