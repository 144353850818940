import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import { TTableColumn } from './types';

const minColumnWidth = '240px';

type TColumnsNames = {
	columnId: string;
	name: string;
};

export const defaultVisibleColumns = ['_id', 'name', 'description', 'company', 'createdAt', 'updatedAt'];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{
		columnId: '_id',
		name: t('ID')
	},
	{
		columnId: 'name',
		name: t('NAME')
	},
	{
		columnId: 'description',
		name: t('DESCRIPTION')
	},
	{
		columnId: 'company',
		name: t('COMPANY')
	},
	{
		columnId: 'createdAt',
		name: t('CREATED_AT')
	},
	{
		columnId: 'updatedAt',
		name: t('UPDATED_AT')
	}
];

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{
		columnId: '_id',
		name: t('ID')
	},
	{
		columnId: 'name',
		name: t('NAME')
	},
	{
		columnId: 'description',
		name: t('DESCRIPTION')
	},
	{
		columnId: 'company',
		name: t('COMPANY')
	}
];

export const getTableColumns = (t: TFunction): TTableColumn[] => [
	{
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		minWidth: minColumnWidth,
		reorder: true
	},
	{
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:badge" />,
		selector: (row) => row.name,
		sortable: true,
		minWidth: minColumnWidth,
		reorder: true
	},
	{
		id: 'description',
		name: <ColumnName name={t('DESCRIPTION')} icon="material-outline:description" />,
		selector: (row) => row.description,
		sortable: true,
		minWidth: minColumnWidth,
		reorder: true
	},
	{
		id: 'company',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row.company?.name,
		sortable: true,
		minWidth: minColumnWidth,
		reorder: true
	},
	{
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:date_range" />,
		selector: (row) => row.createdAt && dayjs(row.createdAt).format('DD/MM/YYYY HH:mm'),
		sortable: true,
		minWidth: minColumnWidth,
		reorder: true
	},
	{
		id: 'updatedAt',
		name: <ColumnName name={t('UPDATED_AT')} icon="material-outline:date_range" />,
		selector: (row) => row.updatedAt && dayjs(row.updatedAt).format('DD/MM/YYYY HH:mm'),
		sortable: true,
		minWidth: minColumnWidth,
		reorder: true
	}
];
