import { IPayload } from 'app/store/api/driversSlice';
import { TExcelColumn } from 'app/types/excel-column.types';
import dayjs from 'dayjs';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FiltersFormValues } from './components/filters/form-schema';
import { DriverSearchParamsFilters, TDriver, TRequestMessages } from './types';

export const REQUEST_MESSAGES: TRequestMessages = {
	create: { success: 'CREATE_SUCCESS', error: 'CREATE_ERROR' },
	edit: { success: 'EDIT_SUCCESS', error: 'EDIT_ERROR' },
	editMultiple: { success: 'EDIT_MULTIPLE_SUCCESS', error: 'EDIT_MULTIPLE_ ERROR' },
	delete: { success: 'DELETE_SUCCESS', error: 'DELETE_ERROR' },
	massiveCreate: { success: 'MASSIVE_CREATE_SUCCESS', error: 'MASSIVE_CREATE_ERROR' }
};

export function formatCNH(cnh: string) {
	if (!cnh) return null;
	const regex = /^(\d{2})(\d{3})(\d{3})(\d)(\d{2})$/;

	const groups = RegExp(regex).exec(cnh.replace(/[^\d]/g, ''));

	if (groups) {
		return `${groups[1]}.${groups[2]}.${groups[3]}-${groups[4]}.${groups[5]}`;
	}
	return cnh;
}

export function formatRG(rg: string) {
	const regex = /^(\d{1,2})(\d{3})(\d{3})([\dX])$/;
	const groups = RegExp(regex).exec(rg.replace(/[^\dX]/g, ''));

	if (groups) {
		return `${groups[1]}.${groups[2]}.${groups[3]}-${groups[4]}`;
	}
	return rg;
}

export const TRANSLATE_KEY = 'driversPage';

export const getExcelColumns = (t: TFunction): TExcelColumn<TDriver>[] => [
	{
		label: t('NAME'),
		value: 'name'
	},
	{
		label: t('EMAIL'),
		value: 'email'
	},
	{
		label: t('MOBILE'),
		value: (row) => row?.mobile && formatPhoneNumberIntl(row?.mobileCountryCode + row.mobile)
	},
	{
		label: t('PHONE'),
		value: (row) => row?.phone && formatPhoneNumberIntl(row?.phoneCountryCode + row.phone)
	},
	{
		label: t('CNH'),
		value: (row) => row?.cnh && formatCNH(row.cnh)
	},
	{
		label: t('CNH_DUE_DATE'),
		value: (row) => row?.cnhDueDate && dayjs(row.cnhDueDate).format('DD/MM/YYYY')
	},
	{
		label: t('CPF'),
		value: (row) => row?.cpf && row.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
	},
	{
		label: t('RG'),
		value: (row) => row?.rg && formatRG(row.rg) + ' - ' + row?.rgType
	},
	{
		label: t('RG_CREATED_DATE'),
		value: (row) => row?.rgCreatedDate && dayjs(row.rgCreatedDate).format('DD/MM/YYYY')
	},
	{
		label: t('ADDRESS'),
		value: 'address'
	},
	{
		label: t('BIRTHDAY'),
		value: (row) => row?.birthday && dayjs(row.birthday).format('DD/MM/YYYY')
	},
	{
		label: t('NOTES'),
		value: 'notes'
	},
	{
		label: t('USER'),
		value: (row) => row.user?.name
	},
	{
		label: t('COMPANY'),
		value: (row) => row.company?.name
	},
	{
		label: t('CREATED_AT'),
		value: (row) => row.createdAt && dayjs(row.createdAt).format('DD/MM/YYYY')
	},
	{
		label: t('UPDATED_AT'),
		value: (row) => row.updatedAt && dayjs(row.updatedAt).format('DD/MM/YYYY')
	}
];

export const VALID_XLSX_FIELDS: (keyof Partial<IPayload>)[] = [
	'name',
	'email',
	'mobile',
	'mobileCountryCode',
	'phone',
	'phoneCountryCode',
	'cnh',
	'cnhDueDate',
	'cnhType',
	'cpf',
	'rg',
	'rgType',
	'rgCreatedDate',
	'address',
	'birthday',
	'notes',
	'user',
	'company'
];

export function parseFormValuesToFilter(formValues: FiltersFormValues): DriverSearchParamsFilters {
	const companyId = _.get(formValues, 'company._id', null);
	const userId = _.get(formValues, 'user._id', null);

	return {
		...formValues,
		company: companyId,
		user: userId
	};
}
