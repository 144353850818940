const locale = {
	// vehicleInformation

	YEAR: 'Ano',
	BRAND: 'Marca',
	TYPE: 'Tipo',
	MODEL: 'Modelo',
	COLOR: 'Cor',
	PLATE: 'Placa',
	VENDOR: 'Vendedor',

	// header

	EXPORT: 'Exportar',

	// commons

	NOT_PROVIDED: 'Não informado',
	LAST_TRACKER_EVENT: 'Último evento do rastreador',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	TRACKERS: 'Rastreadores',
	TRACKERS_LABEL: '{{n}} Rastreadores',
	FLEETS: 'Frotas',
	FLEETS_LABEL: '{{n}} Frotas',
	COMPANY: 'Empresa',
	VIEW_COMPANY: 'Ver empresa',
	VIEW_FLEET: 'Ver frota',
	VEHICLE_INFORMATION: 'Informações do veículo',
	VIEW_SIMCARD: 'Ver simcard',
	SIMCARDS: 'Simcards',
	SIMCARDS_LABEL: '{{n}} Simcards',

	// lastTrackerEvent

	EVENT_TYPE: 'Tipo de evento',
	ADDRESS: 'Endereço',

	COORDINATES: 'Coordenadas',
	LAT: 'Lat',
	LNG: 'Lng',
	ALT: 'Altitude',

	INFO_TRACKER: 'Informações do rastreador',
	SATELLITES: 'Satélites',
	RXLEV: 'Intensidade do sinal',
	GPS_FIXED: 'GPS fixado',
	IGNITION_ON: 'Ignição',
	ODOMETER: 'Odômetro',
	HOURMETER: 'Horímetro',
	CLOSE: 'Fechar',
	BATTERY: 'Bateria'
};

export default locale;
