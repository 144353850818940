const locale = {
	APPLICATIONS: 'Aplicaciones',
	EXAMPLE: 'Ejemplo',
	PROFILES: 'Perfiles',
	USERS: 'Usuarios',
	RECORDS: 'Registros',
	RECORDS_MANAGEMENT: 'Gestión de Registros',
	FLEETS: 'Flotas',
	FLEETS_MANAGEMENT: 'Gestión de Flotas',
	SIMCARDS: 'Simcards',
	PERMISSIONS: 'Permisos',
	VEHICLES: 'Vehículos',
	COMPANIES: 'Empresas',
	TRACKERS: 'Rastreadores',
	MAP: 'Mapa',
	IBUTTONS: 'Identificadores',
	DRIVERS: 'Conductores',
	URLS: 'URLs',
	FUEL_SUPPLY: 'Suministrar',
	REPORTS: 'Reportes',
	REPORTS_MANAGEMENT: 'Gestión de Reportes',
	HISTORY: 'Historial',
	CONTACTS: 'Contactos',
	COMMANDS: 'Comandos',
	COMMANDS_MANAGEMENT: 'Gestión de Comandos',
	FENCE: 'Cerca',
	NOTIFICATIONS: 'Notificaciones',
	NOTIFICATIONS_MANAGEMENT: 'Gestión de Notificaciones',
	SESSIONS: 'Sesiones',
	RULER: 'Reglas',
	GEO_FENCES: 'Cercas virtuales',
	TRIPS: 'Viajes',
	TRIPS_MANAGEMENT: 'Gestión de Viajes',
	DRIVERS_MANAGEMENT: 'Gestión de Conductores',
	COMMANDS_REGISTER: 'Registro de Comandos',
	MONITORING: 'Supervisión',
	SCHEDULE_COMMANDS: 'Programar Comandos',
	HISTORY_COMMANDS: 'Historial de Comandos',
	RULER_HISTORY: 'Historial de Notificaciones',
	RULES_MANAGEMENT: 'Gestión de Reglas',
	NEW_FEATURES: 'Notas de versión',
	NEW_FEATURES_SUBTITLE: 'Novedades y actualizaciones',
	MONITORING_EVENTS: 'Eventos',
	SHIPS: 'Embarcaciones',
	MARINE_MANAGEMENT: 'Gestión de Instalaciones de Marine',
	MONITORING_SETTINGS: 'Configuraciones',
	MONITORING_DONE: 'Finalizados',
	MONITORING_MANAGEMENT: 'Gestión de Supervisión',
	ANALYTICS: 'Analítico',
	MONITORING_OPERATOR: 'Operadores',
	EVENTS: 'Eventos',
	TRACKER_METRICS: 'Paquetes Enviados',
	MAINTENANCE_MANAGEMENT: 'Gestión de Mantenimiento',
	MAINTENANCE: 'Mantenimiento',
	MAINTENANCE_ITEM: 'Item de mantenimiento',
	BLOCK_COMMAND_HISTORY: 'Bloqueo/Desbloqueo',
	ACCESS_PERIOD: 'Horarios de acceso',
	MAINTENANCE_REPORT: 'Informe de mantenimiento',
	COURSES: 'Cursos',
	LESSONS: 'Lecciones',
	GEOFENCE_MANAGEMENT: 'Gestión de Cercas Virtuales',
	GEOFENCE: 'Cerca Virtual',
	GEOFENCE_MANAGE: 'Cercas Virtuales',
	GEOFENCE_REPORT: 'Informe de Cercas Virtuales',
	INTEGRATIONS: 'Integraciones'
};

export default locale;
