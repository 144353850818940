import { THIRTY_ONE_DAY_ERROR_MESSAGE_PT } from 'src/constants';

const locale = {
	// Header
	DOWNLOAD_ERROR: 'Falha ao baixar arquivo, por favor tente novamente.',
	DOWNLOAD_ALL: 'Baixar Todos',
	DOWNLOAD_SELECTED: 'Baixar Selecionados',
	SHOW_FILTERS: 'Mostrar filtros',
	RULER_COMMAND_NAME: 'Nome do Comando de Regra',
	CONFIG_TABLE: 'Configurar tabela',
	THIRTY_DAY_ERROR: THIRTY_ONE_DAY_ERROR_MESSAGE_PT,

	// Columns

	RULE_NAME: 'Nome da regra',
	SCHEDULED_COMMAND_NAME: 'Nome do comando agendado',
	NEXT_EXECUTION: 'Próxima execução',
	RESPONSE: 'Resposta',
	STATUS: 'Status',
	SCHEDULED_AT: 'Agendado em',
	EXECUTED_AT: 'Executado em',

	// Table

	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	NO_SCHEDULED_FOUND: 'Nenhum comando agendado encontrado.',

	// Permissions

	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',

	// Header

	SCHEDULE: 'Agendar',
	TITLE: 'Histórico de comandos',

	// Status

	PENDING: 'Pendente',
	QUEUED: 'Agendado',
	'RE-QUEUED': 'Re-enfileirado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Erro',
	EXPIRED: 'Expirado',
	SCHEDULING: 'Agendando',

	// Modal

	COMMAND_INFO: 'Informações do comando',
	COMMAND_NAME: 'Nome',
	COMMAND_SENT: 'Comando enviado',
	COMMAND_STRING: 'Comando',
	COMMAND_DESCRIPTION: 'Descrição',

	SCHEDULED_COMMAND_INFO: 'Informações do comando agendado',

	EXECUTIONS: 'Execuções',

	FROM: 'De',
	TO: 'para',

	SHOW_SCHEDULED_COMMAND: 'Ir até comando agendado',
	INFO: 'Informações',

	REASON: 'Razão do erro',
	TARGETS: 'Enviado para',
	CREATED_AT: 'Criado em',
	UPDATED_AT: 'Atualizado em',

	// Filters
	SELECT_FILTERS: 'Selecionar filtros',
	FILTERS: 'Filtros',
	SCHEDULED_COMMAND: 'Comando agendado',
	CREATED_BY_ME: 'Agendados por mim',
	CLEAR_FILTERS: 'Limpar filtros',
	FROM_STATUS: 'Do Estado',
	TO_STATUS: 'Para o Estado',
	START_DATE: 'Data Inicial',
	END_DATE: 'Data Final',
	RULES: 'Regras',
	RULES_SELECTED: '{{n}} regras selecionadas',
	SEARCH: 'Pesquisar',

	SCHEDULED_HISTORY: 'Histórico de comandos agendados',
	RULER_HISTORY: 'Histórico de comandos de regra',
	SCHEDULED: 'Agendado',
	IMMEDIATE: 'Imediato',
	TYPE: 'Tipo',
};

export default locale;
