import { Theme, useMediaQuery } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import SearchFilterInput from './SearchFilterInput';

interface FiltersProps {
	t: TFunction;
	children: React.ReactNode;
	form: any;
	handleApplyFilters: () => void;
}

const searchInputProps = {
	minWidth: 250,
	backgroundColor: 'background.paper'
};

export const Filters: React.FC<FiltersProps> = ({ handleApplyFilters, t, form, children }) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const handleClearFilters = () => {
		form.reset();
		handleApplyFilters();
	};
	return (
		<FormProvider {...form}>
			<div className="flex flex-col justify-end gap-16 mt-[18px] flex-wrap">
				<div className="flex gap-16 flex-wrap w-full sm:w-auto">
					{children}
					<SearchFilterInput
						form={form}
						searchInputProps={searchInputProps}
						handleApplyFilters={handleApplyFilters}
						handleClearFilters={handleClearFilters}
						t={t}
						isMobile={isMobile}
					/>
				</div>
			</div>
		</FormProvider>
	);
};
