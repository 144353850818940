import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface IConfirmationModalProps {
	onConfirm: () => void;
	onCancel: () => void;
	title: string;
	primary: string;
	secondary?: string;
	loading?: boolean;
}

const pt = {
	CANCEL: 'Cancelar',
	CONFIRM: 'Confirmar'
};
const en = {
	CANCEL: 'Cancel',
	CONFIRM: 'Confirm'
};
const sp = {
	CANCEL: 'Cancelar',
	CONFIRM: 'Confirmar'
};

i18next.addResourceBundle('pt', 'ConfirmationModal', pt);
i18next.addResourceBundle('sp', 'ConfirmationModal', sp);
i18next.addResourceBundle('en', 'ConfirmationModal', en);

export default function ConfirmationModal({
	onCancel,
	onConfirm,
	title,
	secondary,
	primary,
	loading
}: IConfirmationModalProps) {
	const { t } = useTranslation('ConfirmationModal');

	return (
		<>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{primary}</DialogContentText>
				{secondary && <DialogContentText color="error">{secondary}</DialogContentText>}
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="error" variant="text">
					{t('CANCEL')}
				</Button>
				<LoadingButton onClick={onConfirm} color="secondary" variant="contained" loading={loading}>
					{t('CONFIRM')}
				</LoadingButton>
			</DialogActions>
		</>
	);
}
