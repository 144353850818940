import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import { selectCompanyLogo } from 'app/store/company/companySettingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Navigation from '../../../../shared-components/Navigation';

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	'& ::-webkit-scrollbar-thumb': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
		}`
	},
	'& ::-webkit-scrollbar-thumb:active': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
		}`
	}
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
	overscrollBehavior: 'contain',
	overflowX: 'hidden',
	overflowY: 'auto',
	paddingTop: 20,
	WebkitOverflowScrolling: 'touch',
	background: 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%)',
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 40px, 100% 10px',
	backgroundAttachment: 'local, scroll'
}));

function NavbarStyle2Content(props: any) {
	const theme = useSelector(selectCompanyLogo);
	const navbar = useSelector(selectFuseNavbar);
	const [source, setSource] = useState<string>('');

	useEffect(() => {
		if (navbar.foldedOpen || navbar.mobileOpen) {
			setSource(_.get(theme, 'logoLight200x40', '/assets/images/logo/mar.png'));
		} else {
			setSource(_.get(theme, 'logoLight40x40', '/assets/images/logo/mar_logo.png'));
		}
	}, [navbar, theme]);

	return (
		<Root className={clsx('flex flex-auto flex-col overflow-hidden h-full', props.className)}>
			<div className="overflow-x-hidden flex items-center justify-center shrink-0  md:h-64 px-12 m-auto">
				<motion.img
					key={source}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.6, type: 'tween' }}
					src={source}
					alt="Logo"
					className="w-auto max-h-full my-auto py-12 m-auto"
				/>
			</div>
			<StyledContent
				className="flex flex-1 flex-col min-h-0"
				option={{ suppressScrollX: true, wheelPropagation: false }}
			>
				<Navigation layout="vertical" />
			</StyledContent>
		</Root>
	);
}

export default memo(NavbarStyle2Content);
