import { CircularProgress, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { formatBigNumbers } from 'src/utils/formatBigNumbers';
import TitleAndQuantity from './TitleAndQuantity';

interface INotificationPannelHeaderProps {
	totalDocs: number;
	isLoading: boolean;
	onMarkAllAsRead: () => void;
	title: string;
	t: TFunction;
	closeButton: ReactNode;
}

export default function NotificationPannelHeader({
	isLoading,
	totalDocs,
	onMarkAllAsRead,
	title,
	t,
	closeButton
}: INotificationPannelHeaderProps) {
	return (
		<div className="flex pb-16 flex-col pl-16 pt-32">
			{closeButton}
			<TitleAndQuantity quantity={formatBigNumbers(totalDocs)} title={title} />
			<Typography
				onClick={onMarkAllAsRead}
				className="text-14 font-600 hover:underline cursor-pointer"
				color="text.secondary"
			>
				{t('MARK_ALL_AS_READ')}
				{isLoading && <CircularProgress size={10} color="secondary" className="ml-4" />}
			</Typography>
		</div>
	);
}
