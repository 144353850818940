import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { styled } from '@mui/material/styles';
import AppContext from 'app/AppContext';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';

const Root = styled('div')(() => ({
	'& .container': {
		maxWidth: `100vw`,
		width: '100%',
		margin: '0 auto'
	},
	'& .boxed': {
		maxWidth: `100vw`,
		width: '100%',
		margin: '0 auto'
	}
}));

function DenseComponent(props: any) {
	const config = useSelector(selectFuseCurrentLayoutConfig);
	const { routes: appRoutes } = useContext(AppContext);
	const routes = useRoutes(appRoutes);

	return (
		<Root id="fuse-layout" className="w-full flex">
			<LeftSideLayout1 />

			<div className="flex flex-auto min-w-0">
				{config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

				<main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
					{config.toolbar.display && (
						<ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />
					)}
					<div className="flex flex-col flex-auto min-h-0 relative z-10">
						<FuseDialog />
						<FuseSuspense>{routes}</FuseSuspense>
						{props.children}
					</div>
				</main>
				{config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
			</div>
			{config.rightSidePanel.display && <RightSideLayout1 />}
			<FuseMessage />
		</Root>
	);
}

export default memo(DenseComponent);
