import store from "app/store/index";

export const formatDistance = (distance: number, unit: 'km' | 'm' = 'km', decimals = 2) => {
	const dividers = {
		km: 1000,
		m: 1
	};
	const locales = {
		pt: 'pt-BR',
		en: 'en-US',
		es: 'es-ES'
	}
	const location =locales[store.getState().i18n.language];
	return new Intl.NumberFormat(location, { maximumFractionDigits: decimals }).format(distance / dividers[unit]) + ' ' + unit;
};
