import { AnyAction } from '@reduxjs/toolkit';
import { TSidebarMode } from 'app/main/users/types';
import { useAppDispatch } from 'app/store/index';
import { useCallback, useEffect, useMemo } from 'react';
import { SortOrder, TableColumn } from 'react-data-table-component';
import { createSearchParams, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { T } from 'vitest/dist/reporters-2ff87305.js';

export const useCRUDSearchParams = (param: string[], handleSideBar?: (_mode: TSidebarMode) => AnyAction) => {
	const [_, setSearchParams] = useSearchParams({
		page: '1',
		limit: '100'
	});
	const dispatch = useAppDispatch();
	const { search } = useLocation();
	const { id } = useParams();
	const searchParamsData = useMemo(() => {
		const searchParams = createSearchParams(search);
		const paramsObj = {};

		param.forEach((p) => {
			paramsObj[p] = searchParams.get(p);
		});

		const sortKey = searchParams.get('sortKey') || '';
		const sortValue = searchParams.get('sortValue') as SortOrder;
		const page = searchParams.get('page') || '1';
		const limit = searchParams.get('limit') || '100';

		return {
			...paramsObj,
			page: isNaN(Number(page)) ? 1 : Number(page),
			limit: isNaN(Number(limit)) ? 100 : Number(limit),
			sortKey,
			sortValue,
			search: searchParams.get('search') || '',
			searchKey: searchParams.get('searchKey') || '',
			company: searchParams.get('company') || ''
		};
	}, [search]);

	const handleSortChange = useCallback(
		(selectedColumn: TableColumn<T>, sortDirection: SortOrder) => {
			setSearchParams((prev) => {
				if (selectedColumn) {
					prev.set('sortKey', `${selectedColumn.id}`);
				} else {
					prev.delete('sortKey');
				}
				if (sortDirection) {
					prev.set('sortValue', sortDirection);
				} else {
					prev.delete('sortValue');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleSearchChange = useCallback(
		(newSearch: string) => {
			setSearchParams((prev) => {
				if (newSearch) {
					prev.set('search', newSearch);
				} else {
					prev.delete('search');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleChangeLimit = useCallback(
		(limit: number) => {
			setSearchParams((prev) => {
				prev.set('limit', limit.toString());
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleChangePage = useCallback(
		(page: number) => {
			setSearchParams((prev) => {
				prev.set('page', page.toString());
				return prev;
			});
		},
		[setSearchParams]
	);

	const handleChangeParam = useCallback(
		(param: string, value: string | boolean) => {
			setSearchParams((prev) => {
				if (value !== undefined && value !== null) prev.set(param, value.toString());
				else prev.delete(param);
				return prev;
			});
		},
		[setSearchParams]
	);

	const changeSearchKey = useCallback(
		(newSearchKey: string) => {
			setSearchParams((prev) => {
				if (newSearchKey) {
					prev.set('searchKey', newSearchKey);
				} else {
					prev.delete('searchKey');
				}
				return prev;
			});
		},
		[setSearchParams]
	);

	useEffect(() => {
		if (!id) return;
		if (handleSideBar) dispatch(handleSideBar('view'));
	}, [id, dispatch, handleSideBar]);

	return {
		searchParamsData,
		queryId: id,
		changeSearch: handleSearchChange,
		changePage: handleChangePage,
		changeLimit: handleChangeLimit,
		changeParam: handleChangeParam,
		changeSort: handleSortChange,
		changeSearchKey,
		setSearchParams
	};
};
