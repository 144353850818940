import { DownloadOutlined } from '@mui/icons-material';
import { DialogContent, Stack } from '@mui/material';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AppButton from '../Buttons/AppButton';
import DownloadMessage from './DownloadMessage';
import { locale } from './locale';

interface IDownloadWarningProps {
	onConfirm: () => void;
	loading: boolean;
}

locale();

export default function DownloadWarning({ loading, onConfirm }: IDownloadWarningProps) {
	const dispatch = useDispatch();

	const { t } = useTranslation('downloadWarning');

	const handleCancel = () => dispatch(closeDialog());

	return (
		<DialogContent sx={{ maxWidth: '480px' }}>
			<Stack sx={{ width: '100%' }} spacing={2} alignItems="center" justifyContent="center" direction="column">
				<DownloadMessage t={t} />
				<Stack direction="row" spacing={2} width="100%">
					<AppButton variant="outlined" color="error" fullWidth onClick={handleCancel}>
						{`${t('CANCEL')}`}
					</AppButton>
					<AppButton
						fullWidth
						color="secondary"
						disabled={loading}
						variant="contained"
						endIcon={<DownloadOutlined />}
						onClick={onConfirm}
					>
						{`${t('CONTINUE')}`}
					</AppButton>
				</Stack>
			</Stack>
		</DialogContent>
	);
}
