import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/rulerHistory/tableColumns';
import { TRulerHistory, TSidebarMode } from 'app/main/rulerHistory/types';
import storageService from 'app/services/storage';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TRulerHistory[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	visibleColumns: string[];
};

const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view',
	visibleColumns: storageService.get('NotificationsRulerHistoryColumns') || defaultVisibleColumns
};

const rulerHistorySlice = createSlice({
	name: 'rulerHistory',
	initialState,
	reducers: {
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('NotificationsRulerHistoryColumns', action.payload);
		},
		setSelectedRows: (state, action: PayloadAction<TRulerHistory[]>) => {
			state.selectedRows = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setIsSidebarOpen, setSidebarMode, setSelectedRows, setVisibleColumns, resetSidebarState } =
	rulerHistorySlice.actions;
export const selectIsSidebarOpen = (state: RootState) => state.rulerHistory.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.rulerHistory.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.rulerHistory.selectedRows as TRulerHistory[];
export const selectVisibleColumns = (state: RootState) => state.rulerHistory.visibleColumns as string[];
export default rulerHistorySlice.reducer;
