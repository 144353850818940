const pt = {
	TITLE: 'Horários permitidos para acesso',
	PARTIAL: 'Parcial',
	FULL: 'Total',
	NO_ACCESS: 'Sem acesso',
	START: 'Início',
	END: 'Fim',
	TO: 'até',
	ACCESS_TYPE: 'Tipo de acesso',
	ACTIVE: 'Ativo',
	ACCESS_PERIOD_PRESET: 'Selecionar predefinido',

	MONDAY: 'Segunda-feira',
	TUESDAY: 'Terça-feira',
	WEDNESDAY: 'Quarta-feira',
	THURSDAY: 'Quinta-feira',
	FRIDAY: 'Sexta-feira',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',

	REMOVE_PERIOD: 'Remover período',
	ADD_PERIOD: 'Adicionar mais um período',

	SAVE: 'Salvar',
	CLEAR: 'Limpar',

	CONFIGURE_PERIODS: 'Configurar períodos',

	FULL_ACCESS: 'O usuário terá acesso total ao sistema durante este dia',
	PARTIAL_ACCESS: 'O usuário terá acesso em horários específicos durante este dia',
	NONE_ACCESS: 'O usuário não terá acesso ao sistema durante este dia',
	VIEW_INFO: 'Ajuda',

	UPDATE_LOGIN_SETTINGS_SUCCESS: 'Configurações de acesso atualizadas com sucesso',
	UPDATE_LOGIN_SETTINGS_ERROR: 'Erro ao atualizar configurações de acesso',

	PERIOD_NOT_OVERLAP: 'Os períodos não podem se sobrepor',
	START_DATE_MUST_BE_GREATER_THAN_END_DATE: 'A data de início deve ser maior que a data de fim'
};
export default pt;
