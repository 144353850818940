const locale = {
	TITLE: 'Registros de Instalación',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar Filtros',
	CANCEL: 'Cancelar',
	CONFIRM: 'Confirmar',
	SELECTED_ITEMS: '{{n}} elementos seleccionados',

	// Tabla
	ROWS_PER_PAGE: 'Elementos por página',
	OF: 'de',
	NAME: 'Nombre',
	BRAND: 'Marca',
	MODEL: 'Modelo',
	YEAR: 'Año',
	INSTALLATION_DATE: 'Fecha de Instalación',
	MAINTENANCE_DATE: 'Fecha de Mantenimiento',
	NO_MAINTENANCE: 'Sin registro de mantenimiento',

	// Eliminar
	DELETE: 'Eliminar',
	DELETE_MULTIPLE_INFO: 'Eliminar registros de instalación',
	DELETE_SUCCESS: '¡Registro de instalación eliminado con éxito!',
	DELETE_ERROR: 'Error al eliminar el registro de instalación.',
	DELETE_MODAL_TITLE: 'Eliminar Registro de Instalación',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro de que deseas eliminar {{n}} registros de instalación?',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas eliminar este registro de instalación?',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',

	// Descargar
	DOWNLOAD_INFO: 'Descargar registros de instalación',
	DOWNLOAD_ERROR: 'Error al descargar registros de instalación.'
};

export default locale;
