import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/access-periods/list-page/tableColumns';
import storageService from 'app/services/storage';
import { RootState } from '../..';

interface IInitialState {
	visibleColumns: string[];
}

const initialState: IInitialState = {
	visibleColumns: storageService.get('accessPeriodsVisibleColumns') || defaultVisibleColumns
};

const accessPeriodsListPageSlice = createSlice({
	name: 'accessPeriodsListPage',
	initialState,
	reducers: {
		setAccessPeriodsVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('accessPeriodsVisibleColumns', action.payload);
		}
	}
});

export const { setAccessPeriodsVisibleColumns } = accessPeriodsListPageSlice.actions;

export const selectAccessPeriodsVisibleColumns = (state: RootState) => state.accessPeriodsListPage.visibleColumns;

export default accessPeriodsListPageSlice.reducer;
