import { Skeleton, Stack } from '@mui/material';

export const HeaderSkeleton: React.FC = () => {
	return (
		<Stack direction="column" spacing={2} width="100%">
			<Stack direction="row" alignItems="center">
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="circular" width={32} height={32} />
				<Skeleton variant="rounded" width={32} height={18} />
			</Stack>
			<Stack direction="row" alignItems="center">
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
			</Stack>
			<Stack direction="row" alignItems="center">
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="rounded" width={250} height={32} className="mr-16" />
				<Skeleton variant="circular" width={32} height={32} className="mr-16" />
				<Skeleton variant="circular" width={32} height={32} className="mr-16" />
				<Skeleton variant="circular" width={32} height={32} className="mr-16" />
			</Stack>
		</Stack>
	);
};
