import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const BlockCommandHistoryPage = lazyRetry(() => import('./BlockCommandHistoryPage'));

i18next.addResourceBundle('en', 'blockCommandHistoryPage', en);
i18next.addResourceBundle('sp', 'blockCommandHistoryPage', sp);
i18next.addResourceBundle('pt', 'blockCommandHistoryPage', pt);

export const BLOCK_COMMAND_HISTORY_PERMISSIONS = {
	edit: 'reports.analytic.edit',
	view: 'reports.analytic.view',
	list: 'reports.analytic.list',
	new: 'reports.analytic.new',
	delete: 'reports.analytic.delete',
	minimal: ['fleet.fleet.list', 'fleet.tracker.list', 'fleet.vehicle.list', 'realtime.history.view']
};

export const BlockCommandHistoryConfig = {
	routes: [
		{
			auth: ['command.block.list'],
			path: '/reports/block-command-history',
			element: (
				<LazyLoaderSuspense>
					<BlockCommandHistoryPage />
				</LazyLoaderSuspense>
			),
			children: [{ path: '/reports/block-command-history/:id' }]
		}
	]
};
