import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { ITrackerCardProps } from '.';
import CardActions from './CardActions';
import { DateIcon, SpeedIcon, TrackerIconMini } from './ControlledIcons';

export default function SecondaryLayout({
	isDarkMode,
	handleCardClick,
	parsedDates,
	tooltipText,
	tracker,
	trackerClass,
	selected,
	displayName
}: ITrackerCardProps) {
	return (
		<div className="pb-8 m-0 flex justify-center">
			<Paper
				sx={{
					width: ['100%', '390px'],
					height: '104px',
					margin: 0,
					border: '1px solid #79747E',
					borderRadius: '12px',
					padding: '20px 30px',
					cursor: 'pointer',
					backgroundColor: (theme) => {
						if (selected) return `${theme.palette.secondary.main}1a`;
						return isDarkMode ? '#323C4D' : '#fff';
					}
				}}
				variant="outlined"
				onClick={handleCardClick}
			>
				<Stack direction="column" spacing={1} width="100%">
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						<Tooltip title={tooltipText}>
							<TrackerIconMini
								isDarkMode={isDarkMode}
								trackerClass={trackerClass}
								iconName={_.get(tracker, 'vehicle.type.icon', 'mat_outline:devices_other')}
							/>
						</Tooltip>
						<Typography
							sx={{
								fontStyle: 'normal',
								fontWeight: 600,
								fontSize: '18px',
								lineHeight: '24px',
								maxWidth: '240px',
								color: isDarkMode ? '#fff' : '#000',
								paddingLeft: 1
							}}
							noWrap
						>
							{displayName}
						</Typography>
						<Box marginLeft="auto">
							<CardActions tracker={tracker} />
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}}
					>
						{parsedDates ? (
							<Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
								<DateIcon isDarkMode={isDarkMode} />
								<Typography
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '24px',
										color: isDarkMode ? '#9FA5A1' : '#79747E'
									}}
								>
									{_.get(parsedDates, 'date', '')}
								</Typography>
								<Typography
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '12px',
										lineHeight: '24px',
										color: isDarkMode ? '#9FA5A1' : '#79747E',
										paddingLeft: 1
									}}
								>
									{_.get(parsedDates, 'time', '')}
								</Typography>
							</Stack>
						) : undefined}
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								marginLeft: 'auto'
							}}
						>
							<SpeedIcon isDarkMode={isDarkMode} />
							<div className="flex">
								<Typography
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '16px',
										lineHeight: '24px',
										color: isDarkMode ? '#9FA5A1' : '#79747E',
										paddingLeft: 1,
										paddingRight: '4px'
									}}
								>
									{`${_.get(tracker, 'packet.SPEED', '0')}`}
								</Typography>
								<Box
									sx={{
										fontStyle: 'normal',
										fontWeight: 600,
										fontSize: '10px',
										lineHeight: '24px',
										paddingTop: '2px',
										color: isDarkMode ? '#9FA5A1' : '#79747E'
									}}
								>
									km/h
								</Box>
							</div>
						</Box>
					</Box>
				</Stack>
			</Paper>
		</div>
	);
}
