import {
	Battery0BarOutlined,
	Battery20Outlined,
	Battery30Outlined,
	Battery50Outlined,
	Battery60Outlined,
	Battery80Outlined,
	Battery90Outlined,
	BatteryAlertOutlined,
	BatteryFullOutlined,
	Key,
	KeyOff
} from '@mui/icons-material';
import { IconProps } from '@mui/material';

interface IgnitionIconProps extends IconProps {
	on: boolean;
}

export const IgnitionIcon: React.FC<IgnitionIconProps> = ({ on }) => {
	return on ? <Key color="success" fontSize="small" /> : <KeyOff color="error" fontSize="small" />;
};

export const BatteryIcon = ({ battery }: { battery: number }) => {
	if (battery === undefined) return <BatteryAlertOutlined fontSize="small" color="error" />;

	let Icon = BatteryFullOutlined;
	let color: IconProps['color'] = 'success';

	if (battery >= 0 && battery <= 10) {
		Icon = Battery0BarOutlined;
		color = 'error';
	}
	if (battery >= 11 && battery <= 20) {
		Icon = Battery20Outlined;
		color = 'error';
	}
	if (battery >= 21 && battery <= 30) {
		Icon = Battery30Outlined;
		color = 'warning';
	}
	if (battery >= 31 && battery <= 50) {
		Icon = Battery50Outlined;
		color = 'warning';
	}
	if (battery >= 51 && battery <= 60) {
		Icon = Battery60Outlined;
		color = 'warning';
	}
	if (battery >= 61 && battery <= 80) {
		Icon = Battery80Outlined;
		color = 'warning';
	}
	if (battery >= 81 && battery <= 90) {
		Icon = Battery90Outlined;
		color = 'success';
	}

	return <Icon fontSize="small" color={color} className="rotate-90" />;
};

export { TrackerEventIcon } from './TrackerEventIcon';
