import { BlockCommandHistoryConfig } from './block-command-history/BlockCommandHIstoryConfig';
import EventsReportConfig from './events/EventsReportConfig';
import HistoryConfig from './historyNew/HistoryConfig';
import OperatorsConfig from './monitoring-operators/OperatorsReportConfig';
import TrackerMetricsConfig from './tracker-metrics/TrackerMetricsConfig';
import TripsConfig from './trips/TripsConfig';

const ReportsConfig = [
	EventsReportConfig,
	HistoryConfig,
	TripsConfig,
	OperatorsConfig,
	TrackerMetricsConfig,
	BlockCommandHistoryConfig
];

export default ReportsConfig;
