const locale = {
	RECOVER_PASSWORD: 'Recuperar Senha',
	RECOVER_PASSWORD_DESCRIPTION:
		'Para recuperar seu acesso, precisamos do seu e-mail para enviar o link de recuperação. Se você não possuir um email, entre em contato com um administrador.',
	SEND_EMAIL: 'Enviar E-mail',
	SEND: 'Enviar',
	ERROR_EMAIL_INVALID: 'Você deve digitar um e-mail válido',
	BACK: 'Voltar',
	SUCCESS_SEND_EMAIL: 'Um e-mail foi enviado para o seu endereço de e-mail com instruções para redefinir sua senha.',
	ERROR_SEND_EMAIL: 'Erro ao enviar e-mail',
	ERROR_EMAIL_REQUIRED: 'Email é obrigatório',
	ERROR_PASSWORD_REQUIRED: 'Senha é obrigatória',
	ERROR_PASSWORD_NOT_MATCH: 'Senhas não correspondem',
	ERROR_PASSWORD_MIN: 'A senha deve ter pelo menos 6 caracteres',
	PASSWORD: 'Senha',
	CONFIRM_PASSWORD: 'Confirmar Senha',
	FORGOT_PASSWORD: 'Esqueceu a senha?',
	SUCCESS_CHANGE_PASSWORD: 'Senha alterada com sucesso',
	ERROR_CHANGE_PASSWORD: 'Erro ao alterar a senha'
};

export default locale;
