import { ChevronRightOutlined } from '@mui/icons-material';
import { Button, Theme, alpha, styled } from '@mui/material';

const RectangleButton = styled(Button)(({ theme }: { theme: Theme }) => ({
	display: 'inline-flex',
	// maxWidth: '325px',
	justifyContent: 'space-between',
	borderRadius: '12px',
	backgroundColor: theme.palette.background.paper,
	'&:hover': {
		backgroundColor: alpha(theme.palette.secondary.main, 0.1)
	}
}));

const RectangleButtonContent = styled('div')(({ theme }: { theme: Theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: '14px'
}));

const CardButton = ({ text, icon, onClick, fullWidth = false }) => (
	<RectangleButton onClick={onClick} size="large" variant="text" className="shadow py-20" fullWidth={fullWidth}>
		<RectangleButtonContent>
			{icon}
			{text}
		</RectangleButtonContent>
		<ChevronRightOutlined color="secondary" />
	</RectangleButton>
);
export default CardButton;
