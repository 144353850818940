import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	baseUrl: string;
}

const initialState: IInitialState = {
	baseUrl: import.meta.env.VITE_API_URL
};

const configurationSlice = createSlice({
	name: 'configuration',
	initialState,
	reducers: {
		setBaseUrl(state, action: PayloadAction<string>) {
			state.baseUrl = action.payload;
		}
	}
});

export const { setBaseUrl } = configurationSlice.actions;

export default configurationSlice.reducer;
