import { Typography } from '@mui/material';
import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Primitive } from 'react-data-table-component/dist/src/DataTable/types';
import { TFunction } from 'react-i18next';
import { isColumnOmitted } from '../users/tableColumns';
import { TColumnsNames, TRulerHistoryColumn } from './types';

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('NAME'), columnId: 'ruler.name' },
	{ name: t('RULES'), columnId: 'rules' },
	{ name: t('VEHICLE'), columnId: 'vehicle.name' },
	{ name: t('CREATED_AT'), columnId: 'created_at' },
	{ name: t('AGGREGATED_DATA'), columnId: 'row.aggregate' },
	{ name: t('TRACKER'), columnId: 'trackerMsgId[0].DID' }
];
export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('NAME'), columnId: 'ruler.name' },
	{ name: t('RULES'), columnId: 'rules' },
	{ name: t('VEHICLE'), columnId: 'vehicle.name' },
	{ name: t('CREATED_AT'), columnId: 'created_at' }
];
export const getOtherFiltersNames = (t: TFunction) => [{ name: t('SHOW_DELETED'), value: 'deleted' }];
export const avaliableFilters = ['deleted'];

export const columnsSearchKeys = ['ruler.name', 'rules', 'vehicle.name', 'created_at'];

export const defaultVisibleColumns = [
	'ruler.name',
	'rules',
	'vehicle.name',
	'created_at',
	'row.aggregate',
	'trackerMsgId[0].DID'
];
export const columnSortKeys = ['_id', 'ruler.name', 'rules', 'vehicle.name', 'created_at'];

export const getColumns = (t: TFunction, filteredColumns: string[]): TRulerHistoryColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'ruler.name',
		name: <ColumnName name={t('NAME')} icon="material-outline:circle_notifications" />,
		selector: (row) => (row.ruler?.name || <Typography color="error">{t('DELETED_RULE')}</Typography>) as Primitive,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('ruler.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'rules',
		name: <ColumnName name={t('RULES')} icon="material-outline:rule" />,
		selector: (row) => t('REGISTRED_RULES', { n: row.rule?.length || row.rules?.length }) as string,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('rules', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'vehicle.name',
		name: <ColumnName name={t('VEHICLE')} icon="material-outline:commute" />,
		selector: (row) => row.vehicle?.name || "' - - - - '",
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('vehicle.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'created_at',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:calendar_today" />,
		selector: (row) => dayjs(row.created_at).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('created_at', filteredColumns)
	},

	{
		minWidth: '200px',
		id: 'row.aggregate',
		name: <ColumnName name={t('AGGREGATED_DATA')} icon="material-outline:account_tree" />,
		selector: (row) =>
			`${dayjs(row.aggregate?.start).format('DD/MM HH:mm')} - ${dayjs(row.aggregate?.end).format('DD/MM HH:mm')}`,
		reorder: true,
		omit: isColumnOmitted('row.aggregate', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'trackerMsgId[0].DID',
		name: <ColumnName name={t('TRACKER')} icon="material-outline:wifi" />,
		selector: (row) => _.get(row, 'trackerMsgId[0].DID'),
		reorder: false,
		omit: isColumnOmitted('trackerMsgId[0].DID', filteredColumns)
	}
];
