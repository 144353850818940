const locale = {
	YEAR_INVALID: 'Invalid year',
	SELECTED_FLEETS: '{{n}} fleets selected',
	SELECTED_TRACKERS: '{{n}} trackers selected',
	FLEETS: 'Fleets',
	PLATE: 'Plate',
	PLATE_REQUIRED: 'The plate field is required',
	COLOR: 'Color',
	COLOR_REQUIRED: 'The color field is required',
	BRAND: 'Brand',
	BRAND_REQUIRED: 'The brand field is required',
	YEAR: 'Year',
	YEAR_REQUIRED: 'The year field is required',
	MODEL: 'Model',
	MODEL_REQUIRED: 'The model field is required',
	VENDOR: 'Vendor',
	VENDOR_REQUIRED: 'The vendor field is required',
	TRACKERS: 'Trackers',
	TRACKER: 'Tracker',
	TRACKERS_REQUIRED: 'The trackers field is required',
	TYPE: 'Type',
	TYPE_REQUIRED: 'The type field is required',
	COMPANY_REQUIRED: 'The company field is required',
	DID_MUST_BE_NUMBER: 'The DID field must be a number',
	TRACKER_TYPE_REQUIRED: 'The tracker type field is required',
	DID_REQUIRED: 'The DID field is required',
	NOTES: 'Notes',
	VEHICLE: 'Vehicle',
	TRACKER_TYPE: 'Tracker type',
	TITLE: 'Vehicles',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	PROFILES: 'Profiles',
	PROFILE: 'Profile',
	USERS: 'Users',
	USER: 'User',
	BIRTHDAY: 'Birth date',
	PASSWORD: 'Password',
	CPASSWORD: 'Confirm password',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Accessible hosts',
	ADDRESS: 'Address',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Phone',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	NAME_REQUIRED: 'The name field is required',
	PHONE_REQUIRED: 'The phone field is required',
	EMAIL_REQUIRED: 'The email field is required',
	ADDRESS_REQUIRED: 'The address field is required',
	COLUMN_NAME: 'Name',
	COLUMN_COMPANY: 'Company',
	COLUMN_BIRTHDAY: 'Birth date',
	COLUMN_ID: 'ID',
	COLUMN_CREATED_BY: 'Created by',
	COLUMN_CREATED_AT: 'Created at',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Address',
	COLUMN_PHONE: 'Phone',
	NAME: 'Name',
	CODE: 'Code',
	DESC: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete vehicle',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Editing vehicle',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this vehicle?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected veículos',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	ERROR_PERMISSION: 'You do not have the necessary permissions, please contact the system administrator',
	ERROR_GETTING_USERS: 'There was an error loading the vehicles, please try again later',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Internal server error, please try again later',
	ERROR_NETWORK: 'Network error, check your connection and try again',
	ADD_USER: 'Add Vehicle',
	CREATE_SUCCESS: 'Vehicle added successfully',
	CREATE_ERROR: 'Error adding vehicle',
	EDIT_SUCCESS: 'Vehicle updated successfully',
	EDIT_ERROR: 'Error updating vehicle',
	ERROR_ADD_EMPTY: 'Please fill in all fields',
	DELETE_SUCCESS: 'Vehicle deleted successfully',
	DELETE_ERROR: 'Error deleting vehicle',
	NO_DATA: 'No data found',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'You do not have permission to view the list of vehicles',
	NO_PERMISSION_new: 'You do not have permission to add vehicles',
	NO_PERMISSION_edit: 'You do not have permission to edit vehicles',
	NO_PERMISSION_view: 'You do not have permission to view vehicles',
	NO_PERMISSION_delete: 'You do not have permission to delete vehicles',
	NO_ADDRESS: 'Address not found',
	NO_PHONE: 'Phone not found',
	NO_EMAIL: 'Email not found',
	NO_BIRTHDAY: 'Birthday not found',
	LOADING: 'Loading',
	UPLOAD_ERROR: 'Error uploading file',
	PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters',
	PASSWORDS_MUST_MATCH: 'Passwords must match',
	PASSWORD_MIN_LOWERCASE: 'Password must have at least 1 lowercase letter',
	PASSWORD_MIN_UPPERCASE: 'Password must have at least 1 uppercase letter',
	PASSWORD_MIN_NUMBERS: 'Password must have at least 1 number',
	PASSWORD_MIN_SYMBOLS: 'Password must have at least 1 special character',
	MORE_THAN: 'more than',
	NO_USER_FOUND: 'No vehicle found',
	DELETE_MODAL_TITLE: 'Delete vehicle',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this vehicle?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} vehicles?',
	EDIT_INFO: 'Edit vehicle',
	DELETE_INFO: 'Delete vehicle',
	DOWNLOAD_INFO: 'Download selected vehicles',
	EDIT_MULTIPLE_INFO: 'Edit selected vehicles',
	DELETE_MULTIPLE_INFO: 'Delete selected vehicles',
	SELECTED_ITENS: '{{n}} items selected',
	DOWNLOAD_ERROR: 'Error downloading vehicles',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} companies selected',
	SELECTED_HOSTS: '{{n}} hosts selected',
	PROFILE_REQUIRED: 'The Profile field is required',
	CPASSWORD_REQUIRED: 'The Confirm Password field is required',
	EDIT_MULTIPLE_SUCCESS: 'Vehicles edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing vehicles',
	PROFILE_IMAGE_TO_LARGE: 'Please choose an image smaller than 8MB',
	EMAIL_INVALID: 'Invalid email',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',
	PLATE_INFO: 'Depending on the type of vehicle, the plate may not be mandatory.',
	CHASSIS: 'Chassis',
	RENAVAM: 'Renavam',
	RENAVAM_MUST_BE_NUMBERS: 'Only numbers',
	MORE_INFO: 'More info',

	// Header

	DOWNLOAD_ALL: 'Download all',

	// Sidebar

	WARNING_CHANGE_COMPANY_TITLE: 'Changing Company',
	WANING_CHANGED_COMPANY: 'The trackers linked to this vehicle will also be moved to the company',

	CARRY_LOAD_MUST_MATCH_ONE_OF_ITS_VEHICLE_TYPE: 'The size of the vehicle must match its type',
	CARRY_LOAD: 'Carry Load',
	CARRY_LOAD_LIGHT: 'Light',
	CARRY_LOAD_MEDIUM: 'Medium',
	CARRY_LOAD_HEAVY: 'Heavy',

	SELECT_COMPANY_FIRST: 'Select a company first',

	FUELLING: 'Fueling',
	SHOW_FILTERS: 'Show filters',
	CONFIG_TABLE: 'Configure Table',
	FILTER: 'Filter',
	CLEAR_FILTERS: 'Clear filters'
};
export default locale;
