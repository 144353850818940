import {
	ClosedCaptionOutlined,
	FenceOutlined,
	SellOutlined,
	TuneOutlined,
	WorkspacesRounded
} from '@mui/icons-material';
import { Popover, Stack, Tooltip, useTheme } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUpdateUserPreferencesMutation } from 'app/store/api/userSlice';
import {
	closeMenu,
	selectActionMenu,
	selectGeoFenceLen,
	selectIsClusterCountVisible,
	selectIsLabelsVisible,
	selectStateFilterLen,
	setMenuVisible,
	toggleCCVisible,
	toggleLabelsVisible
} from 'app/store/map/actionBarSlice';
import { selectUser } from 'app/store/user/userSlice';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ActionBarBtn from './ActionBarBtn';
import GeoFenceMenu from './GeoFenceMenu';
import StateFilterMenu from './StateFilterMenu';

export default function ActionBar() {
	const dispatch = useDispatch();
	const theme = useTheme();
	const [updateUser] = useUpdateUserPreferencesMutation();
	const isLabelsVisible = useSelector(selectIsLabelsVisible);
	const isClusterCountVisible = useSelector(selectIsClusterCountVisible);
	const selectedMenu = useSelector(selectActionMenu);
	const isStateFilterSelected = useSelector(selectStateFilterLen);
	const isGeoFenceSelected = useSelector(selectGeoFenceLen);
	const user = useSelector(selectUser);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const { t } = useTranslation('mapPage');
	const isDarkMode = theme.palette.mode === 'dark';

	const handleLabels = () => dispatch(toggleLabelsVisible());
	const handleCC = () => dispatch(toggleCCVisible());
	const handleCluster = () =>
		updateUser({
			id: user.data.userId,
			preferences: {
				...user.preferences,
				isClustered: !user.preferences.isClustered
			}
		});

	const handleStateFilter = (ev: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(ev.currentTarget);
		dispatch(setMenuVisible('stateFilter'));
	};
	const handleGeoFenceFilter = (ev: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(ev.currentTarget);
		dispatch(setMenuVisible('geoFenceFilter'));
	};
	const handleClose = () => {
		setAnchorEl(null);
		dispatch(closeMenu());
	};

	const popOpen = Boolean(anchorEl);

	return (
		<Stack
			sx={{
				position: 'absolute',
				top: 181,
				left: 10,
				background: isDarkMode ? '#323C4D' : '#fff',
				boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
				borderRadius: '4px',
				width: '29px',
				overflow: 'hidden'
			}}
			direction="column"
			alignItems="center"
			justifyContent="flex-start"
		>
			<ActionBarBtn handleClick={handleCluster} selected={_.get(user, 'preferences.isClustered', true)}>
				<Tooltip title={t('GROUP_TRACKERS')}>
					<WorkspacesRounded />
				</Tooltip>
			</ActionBarBtn>

			<ActionBarBtn handleClick={handleLabels} selected={isLabelsVisible}>
				<Tooltip title={t('SHOW_TRACKERS_NAME')}>
					<SellOutlined />
				</Tooltip>
			</ActionBarBtn>
			<ActionBarBtn handleClick={handleCC} selected={isClusterCountVisible}>
				<Tooltip title={t('SHOW_TRACKERS_GROUP_COUNT')}>
					<ClosedCaptionOutlined />
				</Tooltip>
			</ActionBarBtn>
			<ActionBarBtn handleClick={handleStateFilter} selected={isStateFilterSelected}>
				<Tooltip title={t('FILTER')}>
					<TuneOutlined />
				</Tooltip>
			</ActionBarBtn>
			<ActionBarBtn handleClick={handleGeoFenceFilter} selected={isGeoFenceSelected}>
				<Tooltip title={t('SHOW_GEO_FENCE')}>
					<FenceOutlined />
				</Tooltip>
			</ActionBarBtn>
			<Popover
				id={'layers_button'}
				open={popOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'right'
				}}
				sx={{
					'& .MuiPopover-paper': {
						backgroundColor: '#fff',
						color: '#000'
					}
				}}
			>
				{selectedMenu === 'geoFenceFilter' ? <GeoFenceMenu /> : <StateFilterMenu />}
			</Popover>
		</Stack>
	);
}
