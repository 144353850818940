import i18next from 'i18next';

import addTranslation from 'app/shared-components/CRUDs/Header/headerTranslation';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';
import enItem from './maintenance-item/i18n/en';
import ptItem from './maintenance-item/i18n/pt';
import spItem from './maintenance-item/i18n/sp';
import MaintenanceReport from './maintenance-report/MaintenanceReport';

const MaintenancePage = lazyRetry(() => import('./maintenance/Maintenance'));
const MaintenanceItemPage = lazyRetry(() => import('./maintenance-item/MaintenanceItem'));

i18next.addResourceBundle('en', 'maintenancePage', en);
i18next.addResourceBundle('sp', 'maintenancePage', sp);
i18next.addResourceBundle('pt', 'maintenancePage', pt);

i18next.addResourceBundle('en', 'maintenanceItemPage', enItem);
i18next.addResourceBundle('sp', 'maintenanceItemPage', spItem);
i18next.addResourceBundle('pt', 'maintenanceItemPage', ptItem);
addTranslation();

const MaintenanceConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['maintenance.maintenance.list'],
			path: 'maintenance/list',
			element: (
				<LazyLoaderSuspense>
					<MaintenancePage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<MaintenancePage />
						</LazyLoaderSuspense>
					)
				}
			]
		},
		{
			auth: ['maintenance.item.list'],
			path: 'maintenance/item',
			element: (
				<LazyLoaderSuspense>
					<MaintenanceItemPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<MaintenanceItemPage />
						</LazyLoaderSuspense>
					)
				}
			]
		},
		{
			auth: ['maintenance.report.list'],
			path: 'maintenance/report',
			element: (
				<LazyLoaderSuspense>
					<MaintenanceReport />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default MaintenanceConfig;
