import i18next from 'i18next';

export const commonPt = {
	NO_DATA: 'Nenhum dado encontrado',
	ADDRESS: 'Endereço',
	DATE_TIME: 'Data e hora',
	IGNITION: 'Ignição',
	MAP: 'Mapa',
	ON: 'Ligado',
	OFF: 'Desligado',
	DRIVER: 'Motorista',
	FLEET: 'Frota',
	TRACKER: 'Rastreador',
	HISTORY: 'Histórico',
	START: 'Início',
	END: 'Fim',
	VEHICLE: 'Veículo',
	DISTANCE: 'Distância',
	MINUTES: 'Minutos',
	SEARCH: 'Buscar',
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	START_DATE: 'Data inicial',
	END_DATE: 'Data final',
	END_DATE_SHALL_BE_GREATER: 'Data final deve ser maior que a data inicial',
	FILTERS: 'Filtros',
	DATE: 'Data',
	SELECT_FILTERS: 'Selecionar filtros',
	CLEAR_FILTERS: 'Limpar filtros',
	SELECTED_FLEETS: '{{n}} frotas selecionadas',
	SELECTED_VEHICLES: '{{n}} veículos selecionados',
	SELECTED_TRACKERS: '{{n}} rastreadores selecionados',
	SELECTED_EVENT_TYPES: '{{n}} eventos selecionados',
	SELECTED_DRIVERS: '{{n}} motoristas selecionados',
	SELECTED_COMPANIES: '{{n}} empresas selecionadas'
};

export const commonEn = {
	NO_DATA: 'No data found',
	ADDRESS: 'Address',
	DATE_TIME: 'Date and time',
	IGNITION: 'Ignition',
	MAP: 'Map',
	ON: 'On',
	OFF: 'Off',
	DRIVER: 'Driver',
	FLEET: 'Fleet',
	TRACKER: 'Tracker',
	HISTORY: 'History',
	START: 'Start',
	END: 'End',
	VEHICLE: 'Vehicle',
	DISTANCE: 'Distance',
	MINUTES: 'Minutes',
	SEARCH: 'Search',
	ROWS_PER_PAGE: 'Rows per page',
	OF: 'of',
	FILTERS: 'Filters',
	DATE: 'Date',
	SELECT_FILTERS: 'Select filters',
	CLEAR_FILTERS: 'Clear filters',
	SELECTED_FLEETS: '{{n}} selected fleets',
	SELECTED_VEHICLES: '{{n}} selected vehicles',
	SELECTED_TRACKERS: '{{n}} selected trackers',
	SELECTED_EVENT_TYPES: '{{n}} selected events',
	SELECTED_DRIVERS: '{{n}} selected drivers',
	SELECTED_COMPANIES: '{{n}} selected companies'
};

export const commonSp = {
	NO_DATA: 'No se encontraron datos',
	ADDRESS: 'Dirección',
	DATE_TIME: 'Fecha y hora',
	IGNITION: 'Encendido',
	MAP: 'Mapa',
	ON: 'Encendido',
	OFF: 'Apagado',
	DRIVER: 'Conductor',
	FLEET: 'Flota',
	TRACKER: 'Rastreador',
	HISTORY: 'Historial',
	START: 'Inicio',
	END: 'Fin',
	VEHICLE: 'Vehículo',
	DISTANCE: 'Distancia',
	MINUTES: 'Minutos',
	SEARCH: 'Buscar',
	ROWS_PER_PAGE: 'Filas por página',
	OF: 'de',
	FILTERS: 'Filtros',
	DATE: 'Fecha',
	CLEAR_FILTERS: 'Limpiar filtros',
	SELECTED_FLEETS: '{{n}} flotas seleccionadas',
	SELECTED_VEHICLES: '{{n}} vehículos seleccionados',
	SELECTED_TRACKERS: '{{n}} rastreadores seleccionados',
	SELECTED_EVENT_TYPES: '{{n}} eventos seleccionados',
	SELECTED_DRIVERS: '{{n}} conductores seleccionados',
	SELECTED_COMPANIES: '{{n}} empresas seleccionadas'
};

export const addHeaderTranslation = () => {
	const pt = {
		OK: 'OK',
		CANCEL: 'Cancelar',
		SELECT_DATE_TIME: 'Selecionar data e hora',
		COLUMNS: 'Colunas',
		SORT_BY: 'Parâmetros',
		PERIOD: 'Periodo',
		SEARCH: 'Pesquisar',
		FILTER: 'Filtro',
		COLUMNS_INFO: 'Selecione quais campos deseja que apareçam na tabela',
		SEARCH_INFO: 'Selecione campos em que deseja pesquisar na tabela',
		LOADING: 'Carregando',
		REQUIRED_ITEM: 'Item obrigatório',
		CLEAR_DATE: 'Limpar Período',
		SELECT_FILTERS: 'Selecionar filtros',
		CLEAR_FILTERS: 'Limpar'
	};

	const sp = {
		OK: 'OK',
		CANCEL: 'Cancelar',
		SELECT_DATE_TIME: 'Seleccionar fecha y hora',
		COLUMNS: 'Columnas',
		SORT_BY: 'Parámetros',
		PERIOD: 'Periodo',
		SEARCH: 'Buscar',
		FILTER: 'Filtro',
		COLUMNS_INFO: 'Selecione que campos desea que aparezcan en la tabla',
		SEARCH_INFO: 'Selecione campos que deseja pesquisar na tabela',
		LOADING: 'Cargando',
		REQUIRED_ITEM: 'Item requerido',
		CLEAR_DATE: 'Limpiar Período',
		SELECT_FILTERS: 'Seleccionar filtros',
		CLEAR_FILTERS: 'Limpiar'
	};

	const en = {
		OK: 'OK',
		CANCEL: 'Cancel',
		SELECT_DATE_TIME: 'Select date and time',
		COLUMNS: 'Columns',
		SORT_BY: 'Parameters',
		PERIOD: 'Period',
		SEARCH: 'Search',
		FILTER: 'Filter',
		COLUMNS_INFO: 'Select which fields you want to appear in the table',
		SEARCH_INFO: 'Select fields you want to search in the table',
		LOADING: 'Loading',
		REQUIRED_ITEM: 'Required item',
		CLEAR_DATE: 'Clear Period',
		SELECT_FILTERS: 'Select filters',
		CLEAR_FILTERS: 'Clear'
	};
	i18next.addResourceBundle('pt', 'translation', pt);
	i18next.addResourceBundle('sp', 'translation', sp);
	i18next.addResourceBundle('en', 'translation', en);
};
