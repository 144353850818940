import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'user'>, MatchConditions>;

const LIST_USER_PERMISSIONS = ['admin.user.list'];

const VIEW_USER_PERMISSIONS = ['admin.user.view'];

const CREATE_USER_PERMISSIONS = [
	'fleet.vehicle.list',
	'admin.company.list',
	'admin.profile.list',
	'admin.url.list',
	'admin.user.new'
];

const UPDATE_USER_PERMISSIONS = [
	'admin.user.edit',
	'admin.user.view',
	'admin.user.list',
	'admin.user.new',
	'admin.user.delete',
	'admin.company.list',
	'admin.profile.list',
	'admin.url.list'
];

const DELETE_USER_PERMISSIONS = ['admin.user.delete'];

export const defineUserAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_USER_PERMISSIONS),
		create: getMissingPermissions(CREATE_USER_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_USER_PERMISSIONS),
		delete: getMissingPermissions(DELETE_USER_PERMISSIONS),
		list: getMissingPermissions(LIST_USER_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('user', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_USER_PERMISSIONS)) can('delete', 'user');
	if (userHasPermission(CREATE_USER_PERMISSIONS)) can('create', 'user');
	if (userHasPermission(UPDATE_USER_PERMISSIONS)) can('edit', 'user');
	if (userHasPermission(VIEW_USER_PERMISSIONS)) can('view', 'user');
	if (userHasPermission(LIST_USER_PERMISSIONS)) can('list', 'user');

	return [build(), missingPermissions] as const;
};
