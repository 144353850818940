import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'plate'>, MatchConditions>;

const VIEW_PLATE_PERMISSIONS = ['fleet.plate', 'fleet.plate.view'];

export const definePlateAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_PLATE_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('plate', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(VIEW_PLATE_PERMISSIONS)) can('view', 'plate');

	return [build(), missingPermissions] as const;
};
