import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type FuelSupplyAbility = PureAbility<AbilityTuple<'fuel-supply'>, MatchConditions>;

const LIST_FUEL_SUPPLIES_PERMISSIONS = ['admin.company.list', 'fleet.vehicle.list', 'fleet.fuel-supply.list'];
const CREATE_FUEL_SUPPLIES_PERMISSIONS = ['admin.company.list', 'fleet.vehicle.list', 'fleet.fuel-supply.new'];

export const defineFuelSupplyAbility = () => {
	const { can, build } = new AbilityBuilder<FuelSupplyAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		list: getMissingPermissions(LIST_FUEL_SUPPLIES_PERMISSIONS),
		create: getMissingPermissions(CREATE_FUEL_SUPPLIES_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('fuel-supply', can);
		return [build(), missingPermissions] as const;
	}

	userHasPermission(LIST_FUEL_SUPPLIES_PERMISSIONS) && can('list', 'fuel-supply');
	userHasPermission(CREATE_FUEL_SUPPLIES_PERMISSIONS) && can('create', 'fuel-supply');

	return [build(), missingPermissions] as const;
};
