export const mask = (v: string) => {
	if (!v) return { type: 'CPF', value: '' };
	v = v.replace(/\D/g, '');

	if (v.length <= 11) {
		v = v.replace(/(\d{3})(\d)/, '$1.$2');
		v = v.replace(/(\d{3})(\d)/, '$1.$2');
		v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	} else {
		v = v.replace(/^(\d{2})(\d)/, '$1.$2');
		v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
		v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
		v = v.replace(/(\d{4})(\d)/, '$1-$2');
	}

	const type = v.length <= 14 ? 'CPF' : 'CNPJ';

	return { type, value: v };
};
