import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SelectedTrip } from 'app/main/reports/shared/types';
import * as T from 'app/main/reports/shared/types';
import { Dayjs } from 'dayjs';
import { RootState } from '..';
import storageService from 'app/services/storage';
import { defaultVisibleColumns } from 'app/main/reports/trips/list/components/Columns';
import { TDriver } from 'app/main/drivers/types';

type TState = {
	sidebar: boolean;
	coordinates: T.Coordinates | null;
	content: T.Content | null;
	position: T.Position | null;
	couldDownloadData: boolean;
	selectedTrip: SelectedTrip | null;

	selectedTrackers?: T.Tracker[];
	selectedDrivers?: TDriver[];
	selectedCompanies?: T.IdAndName[];
	selectedFleets?: T.IdAndName[];

	selectedRows?: T.Trip[];

	startDate?: Dayjs | null;
	endDate?: Dayjs | null;

	eventPopup?: T.Position | null;
	visibleColumns: string[];
};

const initialState = {
	sidebar: false,
	coordinates: null,
	content: null,
	position: null,
	couldDownloadData: true,
	selectedTrip: null,
	selectedRows: [],
	visibleColumns: storageService.get('tripsReport.visibleColumns') || defaultVisibleColumns

} as TState;

const tripsManagementSlice = createSlice({
	name: 'tripsManagement',
	initialState,
	reducers: {
		setSidebarState: (state, action: PayloadAction<boolean>) => {
			state.sidebar = action.payload;
		},
		setSelectedRows: (state, action: PayloadAction<T.Trip[]>) => {
			state.selectedRows = action.payload;
		},

		setCoordinates: (state, action: PayloadAction<T.Coordinates>) => {
			state.coordinates = action.payload;
		},
		setContent: (state, action: PayloadAction<T.Content>) => {
			state.content = action.payload;
		},
		setPosition: (state, action: PayloadAction<T.Position>) => {
			state.position = action.payload;
		},
		setCouldDownloadData: (state, action: PayloadAction<boolean>) => {
			state.couldDownloadData = action.payload;
		},
		setSelectedTrip: (state, action: PayloadAction<SelectedTrip>) => {
			state.selectedTrip = action.payload;
		},
		setEventPopup: (state, action: PayloadAction<T.Position | null>) => {
			state.eventPopup = action.payload;
		},
		clearPopup: (state) => {
			state.eventPopup = null;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('tripsReport.visibleColumns', action.payload);
		}
	}
});

export const {
	setCoordinates,
	setContent,
	setPosition,
	setSidebarState,
	setCouldDownloadData,
	setSelectedTrip,
	setEventPopup,
	clearPopup,
	setSelectedRows,
	setVisibleColumns
} = tripsManagementSlice.actions;

export const selectState = (state: RootState) => state.tripsManagement;
export const selectSidebar = (state: RootState) => state.tripsManagement.sidebar;
export const selectPopupContent = (state: RootState) => state.tripsManagement.eventPopup;
export const selectSelectedRows = (state: RootState) => state.tripsManagement.selectedRows;
export const selectVisibleColumns = (state: RootState) => state.tripsManagement.visibleColumns as string[];


export default tripsManagementSlice.reducer;
