const locale = {
	// Columns
	SCHEDULED_COMMAND_NAME: 'Nombre del Comando Programado',
	NEXT_EXECUTION: 'Próxima Ejecución',
	TARGET_COMPANIES: 'Empresas Destino',
	TARGET_FLEETS: 'Flotas Destino',
	TARGET_VEHICLES: 'Vehículos Destino',
	TARGET_TRACKERS: 'Rastreadores Destino',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	UPDATED_AT: 'Actualizado en',
	STATUS: 'Estado',
	SCHEDULE_COMMAND: 'Programar Comando',

	// Table

	ROWS_PER_PAGE: 'Filas por Página',
	OF: 'de',
	NO_SCHEDULED_FOUND: 'No se encontraron comandos programados',

	// Permissions
	
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',

	// Header

	SCHEDULE: 'Programar',
	EDIT_SCHEDULE_COMMAND: 'Editar Comando Programado',
	TITLE: 'Comandos Programados',

	// Status

	PENDING: 'Pendiente',
	QUEUED: 'En Cola',
	'RE-QUEUED': 'Reencolado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Error',
	EXPIRED: 'Caducado',
	SCHEDULING: 'Programando',

	// Common

	TARGETS: 'Destinatario(s)',
	SCHEDULED_FOR: 'Programado para',
	NOT_SCHEDULED: 'Inmediatamente',
	SCHEDULED_COMMAND: 'Comando Programado',
	EDIT: 'Editar',
	DELETE: 'Eliminar',
	SUMMARY: 'Resumen',
	COMPANY: 'Empresa',
	FLEET: 'Flota',
	VEHICLE: 'Vehículo',
	TRACKER: 'Rastreador',
	CLICK_HERE_TO_EXPAND: 'Haz clic aquí para expandir',
	SUMMARY_ERROR: 'Error al cargar el resumen',
	DELETE_MODAL_TITLE: 'Eliminar Comando Programado',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que quieres eliminar este comando programado?',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	CONFIRM: 'Confirmar',
	COMMAND: 'Comando',
	COMMAND_NAME: 'Nombre',
	COMMAND_DESCRIPTION: 'Descripción',
	COMMAND_PARSER: 'Protocolo',
	VALUES: 'Valores',

	// Requireds

	SCHEDULED_COMMAND_NAME_REQUIRED: 'El nombre del comando programado es obligatorio',
	TRACKER_TYPE_REQUIRED: 'Se requiere el tipo de rastreador',
	COMMAND_REQUIRED: 'Se requiere el comando',
	TARGETS_REQUIRED: 'Selecciona al menos un destinatario para el comando.',

	// Form
	CANCEL: 'Cancelar',
	CONTINUE: 'Continuar',
	FINISH: 'Finalizar',
	SENT_COMMAND: 'Comando Enviado',
	SENT_TO: 'Enviado a',
	CLOSE: 'Cerrar',
	BACK: 'Volver',

	FREQUENCY: 'Frecuencia',
	DATA: 'Datos',
	REVIEW: 'Revisar',
	SEND_TO: 'Enviar a',

	SELECT_TRACKER_AND_COMMAND_HEADER:
		'Elija un nombre para la programación, seleccione el tipo de rastreador y el comando a ejecutar.',
	SELECT_TRACKER_TYPE: 'Seleccionar tipo de rastreador',
	SELECT_COMMAND: 'Seleccionar comando',
	DEFAULT_COMMANDS: 'Comandos Predeterminados del Rastreador',
	COMPANY_COMMANDS: 'Comandos de la Empresa',
	USER_COMMANDS: 'Comandos del Usuario',
	NO_COMMANDS: 'No se encontraron comandos para el tipo de rastreador {{parser}}.',
	SEND_SMS: 'Enviar SMS',

	SELECT_TARGETS_HEADER: 'Seleccione los destinatarios a quienes desea enviar el comando.',
	FLEETS_LIMIT_TEXT: '{{n}} flotas seleccionadas',
	COMPANIES_LIMIT_TEXT: '{{n}} empresas seleccionadas',
	VEHICLES_LIMIT_TEXT: '{{n}} vehículos seleccionados',
	TRACKERS_LIMIT_TEXT: '{{n}} rastreadores seleccionados',

	SELECT_FREQUENCY_HEADER: 'Seleccione la frecuencia con la que se enviará el comando.',
	YOUR_COMMAND_WILL_BE_SENT: 'Tu comando será enviado:',

	REVIEW_YOUR_SCHEDULE: 'Revisa tu programación antes de finalizar.',
	THE_COMMAND_WILL_BE_SENT: 'El comando se enviará cada {{frequency}}',
	IMMEDIATELY: 'El comando se enviará inmediatamente',

	// Cron
	MINUTE: 'Minuto',
	EVERY_MINUTE: 'Cada minuto',
	EVERY_MINUTE_EVERY: 'Cada {{every}} minutos',
	AT_MINUTE_BETWEEN: 'En el minuto {{start}} al minuto {{end}}',
	AT_MINUTE_EVERY: 'En el minuto {{start}} cada {{every}} minutos',
	AT_MINUTE_EVERY_BETWEEN: 'Cada {{every}} minutos, desde {{start}} hasta {{end}}.',
	AT_MINUTE: 'En el minuto {{value}}',

	HOUR: 'Hora',
	EVERY_HOUR: 'Cada hora',
	EVERY_HOUR_EVERY: 'Cada {{every}} horas',
	AT_HOUR_BETWEEN: 'En la hora {{start}} a la hora {{end}}',
	AT_HOUR_EVERY: 'En la hora {{start}} cada {{every}} horas',
	AT_HOUR_EVERY_BETWEEN: 'Cada {{every}} horas, desde {{start}} hasta {{end}}.',
	AT_HOUR: 'En la hora {{value}}',

	DAY: 'Día',
	EVERY_DAY: 'Cada día',
	EVERY_DAY_EVERY: 'Cada {{every}} días',
	AT_DAY_BETWEEN: 'En el día {{start}} al día {{end}}',
	AT_DAY_EVERY: 'En el día {{start}} cada {{every}} días',
	AT_DAY_EVERY_BETWEEN: 'Cada {{every}} días, desde {{start}} hasta {{end}}.',
	AT_DAY: 'En el día {{value}}',
	DAY_OF_WEEK: 'Día de la semana',
	EVERY_DAY_OF_WEEK: 'Todos los días de la semana',
	AT_DAY_OF_WEEK_BETWEEN: 'En la semana {{start}} a la semana {{end}}',
	AT_DAY_OF_WEEK_EVERY: 'En la semana {{start}} cada {{every}} semanas',
	AT_DAY_OF_WEEK: 'Todos los {{value}}',

	MONTH: 'Mes',
	EVERY_MONTH: 'Cada mes',
	AT_MONTH_BETWEEN: 'En el mes {{start}} al mes {{end}}',
	AT_MONTH_EVERY: 'En el mes {{start}} cada {{every}} meses',
	AT_MONTH: 'En el mes {{value}}',

	// Request Messages

	SUCCESS_MESSAGE: '¡El comando {{command}} se ha programado correctamente!',
	EDIT_SCHEDULE_COMMAND_ERROR: 'Error al editar el comando programado',
	CREATE_SCHEDULE_COMMAND_ERROR: 'Error al crear el comando programado',
	EDIT_SCHEDULE_COMMAND_SUCCESS: 'Comando programado editado exitosamente!',
	CREATE_SCHEDULE_COMMAND_SUCCESS: 'Comando programado creado exitosamente!',

	//Filtros
	SELECT_FILTERS: 'Seleccionar filtros',
	FILTROS: 'Filtros',
	CREATED_BY_ME: 'Programado por mí',
	CLEAR_FILTERS: 'Borrar filtros',
	FROM_STATUS: 'Del Estado',
	TO_STATUS: 'Al estado',
	START_DATE: 'Fecha de inicio',
	END_DATE: 'Fecha de finalización'
};

export default locale;
