import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
	DragIndicatorOutlined,
	InfoOutlined,
	NotificationsActiveOutlined,
	PersonOutline,
	ShareLocationOutlined,
	WarningAmber
} from '@mui/icons-material';
import { Box, Button, Stack, SvgIcon, Tooltip, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHref } from 'react-router-dom';

import { useThemeMediaQuery } from '@fuse/hooks';
import { DateIcon } from 'app/main/map/components/tracker-card-layouts/ControlledIcons';
import usePermission from 'app/services/hooks/usePermission';
import ActionBarBtn from 'app/shared-components/Buttons/MiniButton';
import { useFinalizeAlertMutation } from 'app/store/api/monitoringAlertsSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectSelectedAlert } from 'app/store/monitoring';
import { ALARM_COLOR } from '../../helpers';
import { MONITORING_PERMISSIONS } from '../../MonitoringConfig';
import { TMonitoringAlert } from '../../types';
import AlarmDetailDialog from '../shared/AlarmDetailDialog';
import CardGrid from '../shared/CardGrid';
import CloseButton from '../shared/CloseButton';
import FinalizeDialog from '../shared/FinalizeDialog';
import MobileActions from './MobileActions';

type TSingleItem = { data: TMonitoringAlert };

const DetailButton = ({ data }) => {
	const { t } = useTranslation('monitoringPage');
	const dispatch = useDispatch();

	const handleClick = () =>
		dispatch(
			openDialog({
				children: <AlarmDetailDialog data={data} />
			})
		);
	return (
		<Button variant="text" color="secondary" startIcon={<InfoOutlined />} onClick={handleClick} size="small">
			{t('DETAILS')}
		</Button>
	);
};

export default function SingleItem({ data }: TSingleItem) {
	const { attributes, listeners, transform, transition, setNodeRef } = useSortable({ id: data._id });
	const theme = useTheme();
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
	const dispatch = useDispatch();
	const { t } = useTranslation('monitoringPage');
	const href = useHref('/realtime');
	const selected = useSelector(selectSelectedAlert);
	const [updateAlert] = useFinalizeAlertMutation();
	const isDarkMode = theme.palette.mode === 'dark';
	const mainTextColor = isDarkMode ? '#FFF' : '#000';
	const normalTextColor = isDarkMode ? '#9FA5A1' : '#79747E';
	const isToDo = data.status === 'toDo';
	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	};
	const permissions = usePermission({ pagePermissions: MONITORING_PERMISSIONS });

	const renderButton = () => {
		const isTodoAndMobile = isToDo && isMobile;
		const isTodoAndNotMobile = isToDo && !isMobile;

		if (isTodoAndNotMobile) {
			return (
				permissions.hasEdit && (
					<Button
						size="small"
						color="secondary"
						variant="contained"
						sx={{
							borderRadius: '8px'
						}}
						onClick={handleGoOn}
					>
						{t('GO_ON')}
					</Button>
				)
			);
		}

		if (isTodoAndMobile) {
			return (
				<MobileActions
					data={data}
					handleFinalizeDialog={handleFinalizeDialog}
					handleGoOn={handleGoOn}
					handleRealtime={handleRealtime}
				/>
			);
		}

		return permissions.hasView && <DetailButton data={data} />;
	};

	const handleRealtime = () => {
		const trackerId = _.get(data, 'trackerMessage.tracker._id', null);
		if (!trackerId) return;
		window.open(`${href}?trackerId=${trackerId}`, '_blank');
	};

	const handleGoOn = async () => {
		try {
			await updateAlert({ id: data._id, status: 'onGoing' }).unwrap();
			dispatch(
				showMessage({
					message: t('SUCCESS_ONGOING'),
					variant: 'success'
				})
			);
		} catch {
			dispatch(
				showMessage({
					message: t('ERROR_ONGOING'),
					variant: 'error'
				})
			);
		}
	};

	const handleCloseDelete = () => dispatch(closeDialog());

	const handleFinalize = async (brief: string) => {
		try {
			await updateAlert({ id: data._id, status: 'done', brief }).unwrap();
			dispatch(
				showMessage({
					message: t('SUCCESS_DONE'),
					variant: 'success'
				})
			);
		} catch {
			dispatch(
				showMessage({
					message: t('ERROR_DONE'),
					variant: 'error'
				})
			);
		}
		handleCloseDelete();
	};

	const handleFinalizeDialog = () =>
		dispatch(
			openDialog({
				children: <FinalizeDialog t={t} onClose={handleCloseDelete} onConfirm={handleFinalize} />
			})
		);

	return (
		<div className="pb-10 m-0" ref={setNodeRef} style={style}>
			<Box
				sx={{
					paddingX: '16px',
					paddingY: '14px',
					width: ['100%', '520px'],
					display: 'flex',
					justifyContent: ['flex-start', 'space-between'],
					background: isDarkMode ? '#323C4D' : '#fff',
					borderRadius: '12px',
					border: '1px solid #E0E0E0',
					margin: 0,
					flexDirection: ['column', 'row']
				}}
			>
				<CardGrid
					firstCol={
						<Box
							sx={{
								background: _.get(ALARM_COLOR, data.severity, ALARM_COLOR[2]),
								borderRadius: 1,
								padding: '4px',
								flex: 1
							}}
						>
							<NotificationsActiveOutlined htmlColor="#FFF" />
						</Box>
					}
					secondCol={
						<>
							<Typography
								sx={{
									fontSize: '16px',
									fontWeight: 600,
									color: mainTextColor,
									width: ['260px', '180px']
								}}
								noWrap
							>
								{_.get(data, 'trackerMessage.vehicle.name', t('NOT_FOUND'))}
							</Typography>
							<Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
								<SvgIcon htmlColor={normalTextColor} fontSize="inherit" viewBox="0 0 14 14">
									<path d="M7.526 4.134a2.1 2.1 0 1 0-.875.037v2.296L3.312 9.805a1.01 1.01 0 0 0-.112-.006H1a1 1 0 0 0-1 1v2.2a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-2.2a.996.996 0 0 0-.138-.507l3.065-3.065 3.116 2.74a.999.999 0 0 0-.444.832v2.2a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-2.2a1 1 0 0 0-1-1h-1.622L7.526 6.413v-2.28Z" />
								</SvgIcon>
								<Typography
									sx={{
										fontWeight: 600,
										fontSize: '12px',
										color: normalTextColor
									}}
								>
									{_.get(data, 'trackerMessage.tracker.did', t('NOT_FOUND'))}
								</Typography>
							</Stack>
							{isToDo && !isMobile ? (
								permissions.hasView && <DetailButton data={data} />
							) : (
								<Stack direction="row" alignItems="center" justifyContent="start" spacing={1}>
									<PersonOutline htmlColor={normalTextColor} fontSize="small" />
									<Stack direction="column">
										<Typography
											sx={{
												fontSize: '8px',
												color: normalTextColor
											}}
										>
											{t('OPERATED_BY')}
										</Typography>
										<Typography
											sx={{
												fontWeight: 600,
												fontSize: '12px',
												color: mainTextColor
											}}
										>
											{_.get(data, 'user.name', '')}
										</Typography>
									</Stack>
								</Stack>
							)}
						</>
					}
				/>
				<CardGrid
					isReverse
					firstCol={
						<Stack
							direction={['row', 'column']}
							spacing={1}
							flex={1}
							justifyContent="space-between"
							display={['none', 'flex']}
						>
							<ActionBarBtn size="medium" handleClick={handleRealtime}>
								<Tooltip title={t('GO_REALTIME')}>
									<ShareLocationOutlined />
								</Tooltip>
							</ActionBarBtn>

							{permissions.hasEdit && (
								<CloseButton isDarkMode={isDarkMode} handleClick={handleFinalizeDialog} t={t} />
							)}
						</Stack>
					}
					secondCol={
						<>
							<Stack
								direction={['row-reverse', 'row']}
								justifyContent="start"
								alignItems="center"
								flex={1}
								spacing={1}
							>
								<Typography
									sx={{
										fontWeight: 600,
										fontSize: '14px',
										color: mainTextColor,
										mr: 1
									}}
								>
									{t(_.get(data, 'trackerMessage.EVENT_TYPE', ''))}
								</Typography>
								<WarningAmber fontSize="small" />
							</Stack>
							<Stack direction={['row-reverse', 'row']} justifyContent="start" alignItems="center">
								<Typography
									sx={{
										fontWeight: 500,
										fontSize: '12px',
										color: normalTextColor,
										mr: 1
									}}
								>
									{typeof data.createdAt === 'string'
										? dayjs(data.createdAt).format('DD/MM/YYYY - HH:mm:ss')
										: ''}
								</Typography>
								<DateIcon isDarkMode={isDarkMode} />
							</Stack>

							{renderButton()}
						</>
					}
				/>

				<Box
					{...attributes}
					{...listeners}
					sx={{
						cursor: selected ? 'grabbing' : 'grab',
						display: ['none', 'inline']
					}}
				>
					{permissions.hasEdit && <DragIndicatorOutlined />}
				</Box>
			</Box>
		</div>
	);
}
