import { ReplayOutlined } from '@mui/icons-material';
import { Button, Paper, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import i18next from 'i18next';
import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

i18next.addResourceBundle('en', 'ErrorFallbackCompontent', {
	RELOAD: 'Reload',
	ERROR: 'Something went wrong!',
	ERROR_MESSAGE:
		'Please click the button below to reload, or contact support through the identifier in the bottom right corner of the screen.'
});

i18next.addResourceBundle('sp', 'ErrorFallbackCompontent', {
	RELOAD: 'Recargar',
	ERROR: '¡Algo salió mal!',
	ERROR_MESSAGE:
		'Por favor, haga clic en el botón de abajo para recargar, o póngase en contacto con el soporte a través del identificador en la esquina inferior derecha de la pantalla.'
});

i18next.addResourceBundle('pt', 'ErrorFallbackCompontent', {
	RELOAD: 'Recarregar',
	ERROR: 'Algo deu errado!',
	ERROR_MESSAGE:
		'Por favor, clique no botão abaixo para carregar novamente, ou entre em contato com o suporte através do identificador no canto inferior direito da tela.'
});

type ErrorFallbackCompontentProps = FallbackProps;

export const ErrorFallbackCompontent: React.FC<ErrorFallbackCompontentProps> = ({ error, resetErrorBoundary }) => {
	const { t } = useTranslation('ErrorFallbackCompontent');
	const handleReset = () => resetErrorBoundary();

	const isChunkFailedMessage = /Failed to fetch dynamically imported module/.test(error?.message || '');

	// Isso serve para os erros de "Dynamic import" que ocorrem quando o usuário está na aplicação
	useEffect(() => {
		if (isChunkFailedMessage) window.location.reload();
	}, [isChunkFailedMessage]);

	if (isChunkFailedMessage) return null;

	return (
		<Paper className="flex flex-col flex-1 items-center justify-center p-16">
			<motion.div
				className="w-full max-w-3xl flex flex-col justify-center items-center gap-16"
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
			>
				<img className="mb-16" src="/assets/images/ui/Error.svg" alt="error" />
				<Typography variant="h4" fontWeight={600} align="center">
					{t('ERROR')}
				</Typography>
				<Typography variant="body1" align="center" color="error">
					{error?.message}
				</Typography>

				<Typography variant="caption" align="center" color="text.secondary" maxWidth={460}>
					{t('ERROR_MESSAGE')}
				</Typography>
				<Button
					endIcon={<ReplayOutlined />}
					fullWidth={false}
					variant="contained"
					color="secondary"
					onClick={handleReset}
				>
					{t('RELOAD')}
				</Button>
			</motion.div>
		</Paper>
	);
};
