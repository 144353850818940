import { Paper, StackProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import i18next from 'i18next';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { translate } from './i18n/translate';

dayjs.extend(duration);
dayjs.extend(isBetween);

interface CountDownProps extends StackProps {
	onComplete?: () => void;
	endDate?: Dayjs | Date | string;
	className?: string;
	title?: string;
}

i18next.addResourceBundle('en', 'countDown', translate.en);
i18next.addResourceBundle('pt', 'countDown', translate.pt);
i18next.addResourceBundle('es', 'countDown', translate.es);

/**
 * CountDown
 * A React component used to display the number of days, hours, minutes and seconds left until a specified end date.
 * It allows a callback function to be passed in to be executed when the end date is reached.
 */
function CountDown({ onComplete, endDate, className, title, ...props }: CountDownProps) {
	const { t } = useTranslation('countDown');
	const [endDateVal] = useState(dayjs.isDayjs(endDate) ? endDate : dayjs(endDate));

	const [countdown, setCountdown] = useState({
		days: '0',
		hours: '0',
		minutes: '0',
		seconds: '0'
	});
	const intervalRef = useRef<number | null>(null);

	const complete = useCallback(() => {
		if (intervalRef.current) {
			window.clearInterval(intervalRef.current);
		}

		if (onComplete) {
			onComplete();
		}
	}, [onComplete]);

	const formatNumber = (num: number) => (num < 10 ? `0${num}` : num.toString());

	const tick = useCallback(() => {
		const currDate = dayjs();
		const diff = endDateVal.diff(currDate, 'second');

		if (diff < 0) {
			complete();
			return;
		}

		const timeLeft = dayjs.duration(diff, 'seconds');
		setCountdown({
			days: formatNumber(timeLeft.days()),
			hours: formatNumber(timeLeft.hours()),
			minutes: formatNumber(timeLeft.minutes()),
			seconds: formatNumber(timeLeft.seconds())
		});
	}, [complete, endDateVal]);

	useEffect(() => {
		intervalRef.current = window.setInterval(tick, 1000);
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [tick]);

	return (
		<Paper className={clsx('flex rounded-md justify-around')} elevation={2} sx={{ p: 1 }}>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h6" color="text.secondary">
					{countdown.days}
				</Typography>
				<Typography variant="caption" color="secondary">
					{t('DAYS')}
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h6" color="text.secondary">
					{countdown.hours}
				</Typography>
				<Typography variant="caption" color="secondary">
					{t('HOURS')}
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h6" color="text.secondary">
					{countdown.minutes}
				</Typography>
				<Typography variant="caption" color="secondary">
					{t('MINUTES')}
				</Typography>
			</div>
			<div className="flex flex-col items-center justify-center px-12">
				<Typography variant="h6" color="text.secondary">
					{countdown.seconds}
				</Typography>
				<Typography variant="caption" color="secondary">
					{t('SECONDS')}
				</Typography>
			</div>
		</Paper>
	);
}

export default memo(CountDown);
