import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import HinovaIntegrationPage from './hinova/sync/integrationPage';
import { en } from './i18n/en';
import { pt } from './i18n/pt';
import { sp } from './i18n/sp';
import IntegrationsPage from './Integration';

i18next.addResourceBundle('en', 'integrationsPage', en);
i18next.addResourceBundle('sp', 'integrationsPage', sp);
i18next.addResourceBundle('pt', 'integrationsPage', pt);

const IntegrationsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['integrations.integrate'],
			path: 'integrations',
			element: (
				<LazyLoaderSuspense>
					<IntegrationsPage />
				</LazyLoaderSuspense>
			)
		},
		{
			path: 'integrations/hinova',
			element: (
				<LazyLoaderSuspense>
					<HinovaIntegrationPage />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default IntegrationsConfig;
