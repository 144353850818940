export const formatCpfCnpj = (value) => {
	const cleanedValue = value.replace(/\D/g, '');

	if (cleanedValue.length <= 11) {
		return cleanedValue.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/, (_, p1, p2, p3, p4) => {
			if (p4) return `${p1}.${p2}.${p3}-${p4}`;
			if (p3) return `${p1}.${p2}.${p3}`;
			if (p2) return `${p1}.${p2}`;
			return p1;
		});
	} else {
		return cleanedValue.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/, (_, p1, p2, p3, p4, p5) => {
			if (p5) return `${p1}.${p2}.${p3}/${p4}-${p5}`;
			if (p4) return `${p1}.${p2}.${p3}/${p4}`;
			if (p3) return `${p1}.${p2}.${p3}`;
			if (p2) return `${p1}.${p2}`;
			return p1;
		});
	}
};
