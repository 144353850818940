import AppButton from 'app/shared-components/Buttons/AppButton';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch } from 'app/store/index';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getApiError } from 'src/utils/getApiError';

import { Search } from '@mui/icons-material';
import { useEffect } from 'react';
import { FindEntitySFormValues } from '../form-schema';
import { setFindedAssociate, setIsLoadingAssociate } from '../store/integrate-hinova';
import { useLazyGetAssociateDataByCpfQuery } from '../store/integrate-hinova-api';

interface SubmitButtonProps {
	className?: string;
	disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ className = 'ml-auto', disabled = false }) => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation('integrationsPage');
	const { handleSubmit } = useFormContext<FindEntitySFormValues>();
	const [getAssociateData, { isLoading, isFetching }] = useLazyGetAssociateDataByCpfQuery();

	useEffect(() => {
		dispatch(setIsLoadingAssociate(isLoading || isFetching));
	}, [isLoading, dispatch]);

	const onSubmit = handleSubmit(async (data) => {
		const successMsg = t('FIND_ASSOCIATE_SUCCESS');
		try {
			const associateData = await getAssociateData(data.search.replace(/\D/g, '')).unwrap();
			dispatch(setFindedAssociate(associateData));

			dispatch(
				showMessage({
					message: successMsg,
					variant: 'success'
				})
			);
		} catch (error) {
			const apiErr = getApiError(error, t);
			const defaultMsg = t('ERROR_WHILE_SAVING');
			dispatch(
				showMessage({
					message: t(apiErr) || defaultMsg,
					variant: 'error'
				})
			);
		}
	});

	return (
		<AppButton
			className={className}
			variant="contained"
			color="secondary"
			size="large"
			onClick={onSubmit}
			endIcon={<Search />}
			disabled={isLoading || isFetching}
			loading={isLoading || isFetching}
			sx={{ maxWidth: '150px' }}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					onSubmit();
				}
			}}
		>
			{t('SEARCH')}
		</AppButton>
	);
};
