import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const IbuttonsPage = lazyRetry(() => import('./Ibutton'));

i18next.addResourceBundle('en', 'ibuttonsPage', en);
i18next.addResourceBundle('sp', 'ibuttonsPage', sp);
i18next.addResourceBundle('pt', 'ibuttonsPage', pt);

export const IBUTTONS_PERMISSIONS = {
	edit: 'fleet.ibutton.edit',
	view: 'fleet.ibutton.view',
	list: 'fleet.ibutton.list',
	new: 'fleet.ibutton.new',
	delete: 'fleet.ibutton.delete',
	minimal: ['admin.company.list', 'fleet.driver.list']
};
const IbuttonsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.ibutton.list'],
			path: 'fleet/ibuttons',
			element: (
				<LazyLoaderSuspense>
					<IbuttonsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<IbuttonsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default IbuttonsConfig;
