const locale = {
	MUST_BE_LIGTH: 'A cor deve ser clara',
	MUST_BE_DARK: 'A cor deve ser escura',
	EDIT_PALETTE: 'Editar paleta',
	LIGHT: 'Claro',
	DARK: 'Escuro',
	PREVIEW: 'Pré-visualização',
	CANCEL: 'Cancelar',
	SAVE: 'Salvar',
	PRIMARY_COLOR: 'Cor primária',
	SECONDARY_COLOR: 'Cor secundária',
	DEFAULT_BACKGROUND: 'Cor de fundo padrão',
	PAPER_BACKGROUND: 'Cor de fundo do paper'
};

export default locale;
