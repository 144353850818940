import { usePrevious } from '@fuse/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { REQUEST_MESSAGES } from 'app/main/drivers/helper';

import { showMessage } from 'app/store/fuse/messageSlice';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { IGenericFormProps } from '../types';
import schema from './schema';
import { ICourseCategoryPayload, useCreateCourseCategoryMutation } from 'app/store/api/courseCategorySlice';
import { ViewIcon } from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import HookedTextfield from 'app/shared-components/Hooked/HookedTextField';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { TCategory } from 'app/main/academy/types/types';

const emptyArr = [];

type TFormData = yup.InferType<typeof schema>;

type ICategoryFormProps = IGenericFormProps<TFormData, TCategory>;

const initialForm: TFormData = {
	name: ''
};

const parseCategoryDataForApi = (data: TFormData, id = '') =>
	_.chain({
		...(id ? { id } : {}),
		name: data.name
	})
		.omitBy(_.isNil)
		.value() as ICourseCategoryPayload;

export default function CategoryForm({ onCancel, onSuccess, initialData, mode = 'create' }: ICategoryFormProps) {
	const { t } = useTranslation('academyPage');
	const dispatch = useDispatch();
	const prevMode = usePrevious(mode);

	const [createCategory, { isLoading: isCreateLoading }] = useCreateCourseCategoryMutation();

	const isLoading = isCreateLoading;

	const { control, handleSubmit, reset } = useForm<TFormData>({
		resolver: yupResolver(schema),
		defaultValues: initialForm,
		mode: 'all'
	});

	const onSubmit = async (data: TFormData) => {
		try {
			const parsed = parseCategoryDataForApi(data);

			if (mode === 'create') {
				await createCategory(parsed).unwrap();
			}
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES.create.success),
					variant: 'success'
				})
			);

			if (onSuccess) {
				onSuccess(data);
			}

			reset(initialForm);
		} catch (err) {
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES.create.error),
					variant: 'error'
				})
			);
		}
	};

	useEffect(() => {
		if (!prevMode) return;
		if (prevMode !== 'create' && mode === 'create') {
			reset(initialForm);
		}
	}, [mode, prevMode, reset]);

	return (
		<Stack component="form" direction="column" onSubmit={handleSubmit(onSubmit)} spacing={3} paddingX={3}>
			<Stack direction="row" spacing={1}>
				<ViewIcon icon="dns" />
				<HookedTextfield<TFormData>
					required
					control={control}
					label={t('CATEGORY_NAME')}
					t={t}
					variant="outlined"
					name="name"
					size="small"
					fullWidth
				/>
			</Stack>

			<Stack direction="row" paddingBottom={3} paddingTop={2} gap={1}>
				<AppButton variant="outlined" color="secondary" onClick={onCancel} fullWidth>
					{t('CANCEL')}
				</AppButton>
				<AppButton
					variant="contained"
					color="secondary"
					fullWidth
					type="submit"
					loading={isLoading}
					disabled={isLoading}
				>
					{t('SAVE')}
				</AppButton>
			</Stack>
		</Stack>
	);
}
