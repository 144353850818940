import { apiSlice } from './apiSlice';
export type ICourseCategoryPayload = {
	id?: string;
	name: string;
};
const courseCategorySlice = apiSlice.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		getCourseCategory: query({
			query: () => ({
				url: '/courseCategory/',
				method: 'GET'
			}),
			providesTags: ['CourseCategory']
		}),
		deleteCourseCategory: mutation({
			query: (id: string) => ({
				url: `/courseCategory/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['CourseCategory']
		}),

		editCourseCategory: mutation({
			query: (courseCategorys) => ({
				url: `/courseCategory/${courseCategorys._id}`,
				method: 'PUT',
				body: courseCategorys
			}),
			invalidatesTags: ['CourseCategory']
		}),

		getOneCourseCategory: query({
			query: (id: string) => ({
				url: `/courseCategory/${id}`,
				method: 'GET'
			}),
			providesTags: ['CourseCategory']
		}),
		createCourseCategory: mutation<string, ICourseCategoryPayload>({
			query: (courseCategorys) => ({
				url: '/courseCategory',
				method: 'POST',
				body: courseCategorys
			}),
			invalidatesTags: ['CourseCategory']
		})
	})
});

export const {
	useGetCourseCategoryQuery,
	useGetOneCourseCategoryQuery,
	useCreateCourseCategoryMutation,
	useEditCourseCategoryMutation,
	useDeleteCourseCategoryMutation
} = courseCategorySlice;
