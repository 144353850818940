import { Skeleton, Stack, Typography } from '@mui/material';

interface AddressProps {
	address: string;
	loading: boolean;
}

export const Address: React.FC<AddressProps> = ({ address, loading }) => {
	if (loading)
		return (
			<Stack className="w-full">
				<Skeleton variant="text" />
				<Skeleton variant="text" />
			</Stack>
		);

	return (
		<Typography
			fontWeight={500}
			fontSize={12}
			textOverflow="ellipsis"
			sx={{
				display: '-webkit-box',
				WebkitLineClamp: 2
			}}
		>
			{address}
		</Typography>
	);
};
