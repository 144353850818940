const locale = {
	TITLE: 'Operators Report',
	YES: 'Yes',
	NO: 'No',
	OPERATOR: 'Operator: {{name}}',
	ALERTS: 'Alerts',

	// Buttons
	DOWNLOAD_REPORT: 'Download Report',
	SELECT_FILTERS: 'Select Filters',
	SIMPLIFIED_VIEW: 'Simplified View',
	LIST_VIEW: 'List View',

	// Filter
	CLEAR_ALL: 'Clear All',
	USER: 'User',
	SINCE: 'Since',
	UNTIL: 'Until',
	GROUP_BY: 'Group by',
	USER_REQUIRED: 'The "User" field is mandatory',
	SINCE_REQUIRED: 'The "From" field is mandatory',
	GROUP_BY_REQUIRED: 'The "Group by" field is required',
	DAY_SHALL_BE_LESS_THAN_31: 'The maximum interval for grouping by day is 31 days',
	WEEK_SHALL_BE_LESS_THAN_12: 'The maximum interval for grouping by week is 12 weeks',
	MONTH_SHALL_BE_LESS_THAN_12: 'The maximum interval for grouping by month is 12 months',

	// Widgets
	DONE_ALERTS: 'Resolved Alerts',
	MONTLY_AVERAGE: 'Monthly Average',
	TOTAL_TIME: 'Total Time',
	HOURS: 'Hours',
	MAX_TIME: 'Max Time',
	AT_DAY: 'On Day',
	VIEW_DETAILS: 'View Details',
	AVERAGE_TIME_PER_ALERT: 'Average Time per Alert',
	MONTHLY_AVERAGE_TIME: 'Monthly Average Time',

	// Chart
	ALERTS_BY_DAY: 'Alerts by day:',
	ALERTS_BY_WEEK: 'Alerts by week:',
	ALERTS_BY_MONTH: 'Alerts by month:',
	DAY: 'Day',
	WEEK: 'Week',
	MONTH: 'Month',
	ALERTS_AT_DAY: 'Alerts on Day',

	// Simplified view
	TOTAL_ALARMS: 'Total Alarms',
	DONE_AT_DAY: 'Done on the day',
	DONE_AT_WEEK: 'Done in the week',
	DONE_AT_MONTH: 'Done in the month',
	ALARMS_AT_DAY: 'Alarms on the day',
	ALARMS_AT_WEEK: 'Alarms in the week',
	ALARMS_AT_MONTH: 'Alarms in the month',
	TIME_AT_DAY: 'Time on the day',
	TIME_AT_WEEK: 'Time in the week',
	TIME_AT_MONTH: 'Time in the month',
	AVERAGE_PER_ALERT: 'Average per Alert',
	VEHICLE: 'Vehicle',
	TRACKER: 'Tracker',
	UPDATED_AT: 'Updated at',
	ALERT_EVENT: 'Alert Event',

	// List view
	NO_ALERTS_THIS_DAY: 'No alerts resolved on this day',

	// No data
	NO_FILTERS: 'No filters applied',
	NO_FILTERS_SUBTITLE: 'Select a user and a period to generate the report',
	OPERATOR_HAS_NO_REPORT_DATA: 'Selected operator has no history.',

	// Months
	JANUARY: "January",
	FEBRUARY: "February",
	MARCH: "March",
	APRIL: "April",
	MAY: "May",
	JUNE: "June",
	JULY: "July",
	AUGUST: "August",
	SEPTEMBER: "September",
	OCTOBER: "October",
	NOVEMBER: "November",
	DECEMBER: "December"
};

export default locale;
