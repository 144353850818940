import { ShareLocation } from '@mui/icons-material';
import { Box } from '@mui/material';
import IconCard from 'app/shared-components/CRUDs/components/ViewRow/IconCard';
import DecoratedVehicleNameWithSeverity from 'app/shared-components/SeverityDecorator';
import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import _ from 'lodash';
import type { Primitive } from 'react-data-table-component/dist/src/DataTable/types';
import type { TFunction } from 'react-i18next';
import dayjsFormatDuration from 'src/utils/dayjsFormatDuration';
import { TMonitoringEvent } from '../monitoring-event/types';
import type { TColumn, TColumnsNames } from './types';

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('VEHICLE'), columnId: 'trackerMessage.vehicle.name' },
	{ name: t('TRACKER'), columnId: 'trackerMessage.tracker.did' },
	{ name: t('USER'), columnId: 'user.name' }
];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: 'ID', columnId: '_id' },
	{ name: t('DATE'), columnId: 'date' },
	{ name: t('ALERT'), columnId: 'row.trackerMessage.EVENT_TYPE' },
	{ name: t('TOTAL_TIME'), columnId: 'totalTime' },
	{ name: t('OPEN_ADVANCED'), columnId: 'open_advanced' },
	...getSearchKeysNames(t)
];

export const columnsSearchKeys = ['trackerMessage.vehicle.name', 'trackerMessage.tracker.did', 'user.name'];
export const columnsSortKeys = [...columnsSearchKeys, 'date', 'row.trackerMessage.EVENT_TYPE', 'totalTime'];
export const defaultVisibleColumns = [...columnsSortKeys, 'open_advanced'];

export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export const getColumns = (
	t: TFunction<'', string>,
	filteredColumns: string[],
	viewTripFunction,
	trackerEvents: TMonitoringEvent[]
): TColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={'ID'} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		width: '300px',
		id: 'trackerMessage.vehicle.name',
		name: <ColumnName name={t('VEHICLE')} icon="material-outline:directions_car" />,
		selector: (row) =>
			(
				<DecoratedVehicleNameWithSeverity severity={row.severity} name={row.trackerMessage?.vehicle?.name} />
			) as unknown as Primitive,
		sortable: false,
		reorder: true,
		wrap: true,
		omit: isColumnOmitted('trackerMessage.vehicle.name', filteredColumns)
	},
	{
		minWidth: '240px',
		id: 'trackerMessage.tracker.did',
		name: <ColumnName name={t('TRACKER')} icon="material-outline:device_hub" />,
		selector: (row) => row.trackerMessage?.tracker?.did,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('trackerMessage.vehicle.name', filteredColumns)
	},
	{
		minWidth: '240px',
		id: 'date',
		name: <ColumnName name={t('DATE')} icon="material-outline:calendar_today" />,
		selector: (row) => dayjs(row.updatedAt).format('DD/MM/YYYY HH:mm'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('date', filteredColumns)
	},
	{
		minWidth: '240px',
		id: 'totalTime',
		name: <ColumnName name={t('TOTAL_TIME')} icon="material-outline:timer" />,
		selector: (row) => dayjsFormatDuration({ duration: row.totalTime, formatStr: 'HH:mm:ss' }),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('totalTime', filteredColumns)
	},
	{
		minWidth: '240px',
		id: 'row.trackerMessage.EVENT_TYPE',
		name: <ColumnName name={t('ALERT')} icon="material-outline:warning_amber" />,
		selector: (row) => {
			const event = _.find(trackerEvents, { eventCode: row.trackerMessage.EVENT_TYPE });
			return event?.name || (t(row.trackerMessage.EVENT_TYPE) as unknown as string);
		},
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('row.trackerMessage.EVENT_TYPE', filteredColumns)
	},
	{
		minWidth: '240px',
		id: 'user.name',
		name: <ColumnName name={t('USER')} icon="material-outline:person" />,
		selector: (row) => row.user?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('user.name', filteredColumns)
	},
	{
		minWidth: '240px',
		id: 'open_advanced',
		name: <ColumnName name={t('OPEN_ADVANCED')} icon="material-outline:share_location" />,
		selector: (row) =>
			(
				<Box sx={{ cursor: 'pointer' }} onClick={() => viewTripFunction(row)}>
					<IconCard size={36}>
						<ShareLocation color="secondary" />
					</IconCard>
				</Box>
			) as unknown as Primitive,
		sortable: true,
		reorder: true,
		center: true,
		omit: _.some([isColumnOmitted('open_advanced', filteredColumns), !viewTripFunction])
	}
];
