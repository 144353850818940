import { Box, darken, lighten } from '@mui/system';
import clsx from 'clsx';

function PalettePreview(props: any) {
	const { palette, className, section } = props;

	if (!palette) return null;

	return (
		<Box
			className={clsx(
				'w-200 text-left h-136 rounded-6 relative flex font-bold shadow overflow-hidden',
				className
			)}
			sx={{
				backgroundColor: (theme) =>
					theme.palette.mode === 'light'
						? darken(theme.palette.background.default, 0.1)
						: lighten(theme.palette.background.default, 0.1)
			}}
		>
			{section === 'navbar' && (
				<Box
					className="w-56 h-full px-8 pt-8 relative shadow"
					sx={{
						backgroundColor: palette.background.default
					}}
				>
					<Box
						className="h-8 w-full rounded-8 mb-5"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
					<Box
						className="h-8 w-full rounded-8 mb-5"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
					<Box
						className="h-8 w-full rounded-8 mb-5"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
				</Box>
			)}
			{section === 'toolbar' && (
				<Box
					className="w-full h-24 px-8 py-4 relative shadow flex justify-end items-center gap-5 content-center"
					sx={{
						backgroundColor: palette.background.default
					}}
				>
					<Box
						className="h-8 w-8 rounded-full self-center"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
					<Box
						className="h-8 w-8 rounded-full"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
					<Box
						className="h-8 w-8 rounded-full"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
				</Box>
			)}
			{section === 'footer' && (
				<Box
					className="w-full h-24 px-8 py-4 relative shadow flex justify-end items-center gap-5 self-end"
					sx={{
						backgroundColor: palette.background.default
					}}
				>
					<Box
						className="h-8 w-8 rounded-full self-center"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
					<Box
						className="h-8 w-8 rounded-full"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
					<Box
						className="h-8 w-8 rounded-full"
						sx={{
							backgroundColor: palette.secondary.main,
							color: (theme: any) =>
								palette.secondary.contrastText || theme.palette.getContrastText(palette.primary.main)
						}}
					/>
				</Box>
			)}

			{section === 'main' && (
				<Box
					className="w-full h-full px-16 py-16 flex relative justify-center items-center"
					sx={{
						backgroundColor: palette.background.default
					}}
				>
					<Box
						className="w-full h-full rounded-8 flex flex-col gap-5 pt-6 px-6 items-start"
						sx={{
							backgroundColor: palette.background.paper
						}}
					>
						<div className="flex gap-5">
							<Box
								className="h-8 w-8 rounded-full"
								sx={{
									backgroundColor: palette.secondary.main,
									color: (theme: any) =>
										palette.secondary.contrastText ||
										theme.palette.getContrastText(palette.primary.main)
								}}
							/>
							<Box
								className="h-8 w-8 rounded-full"
								sx={{
									backgroundColor: palette.secondary.main,
									color: (theme: any) =>
										palette.secondary.contrastText ||
										theme.palette.getContrastText(palette.primary.main)
								}}
							/>
							<Box
								className="h-8 w-8 rounded-full"
								sx={{
									backgroundColor: palette.secondary.main,
									color: (theme: any) =>
										palette.secondary.contrastText ||
										theme.palette.getContrastText(palette.primary.main)
								}}
							/>
						</div>
						<div className="flex gap-5">
							<Box
								className="h-8 w-8 rounded-full justify-self-start"
								sx={{
									backgroundColor: palette.primary.main,
									color: (theme: any) =>
										palette.secondary.contrastText ||
										theme.palette.getContrastText(palette.primary.main)
								}}
							/>
							<Box
								className="h-8 w-8 rounded-full justify-self-start"
								sx={{
									backgroundColor: palette.primary.main,
									color: (theme: any) =>
										palette.secondary.contrastText ||
										theme.palette.getContrastText(palette.primary.main)
								}}
							/>
							<Box
								className="h-8 w-8 rounded-full justify-self-start"
								sx={{
									backgroundColor: palette.primary.main,
									color: (theme: any) =>
										palette.secondary.contrastText ||
										theme.palette.getContrastText(palette.primary.main)
								}}
							/>
						</div>
					</Box>
				</Box>
			)}

			{/* <Box
				className="w-full h-56 px-8 pt-8 relative"
				sx={{
					backgroundColor: palette.primary.main,
					color: (theme: any) =>
						palette.primary.contrastText || theme.palette.getContrastText(palette.primary.main)
				}}
			>
				<span className="text-12">Header (Primary)</span>

				<Box
					className="flex items-center justify-center w-20 h-20 rounded-full absolute bottom-0 right-0 -mb-10 shadow text-10 mr-4"
					sx={{
						backgroundColor: palette.secondary.main,
						color: (theme: any) =>
							palette.secondary.contrastText || theme.palette.getContrastText(palette.secondary.main)
					}}
				>
					<span className="">S</span>
				</Box>
			</Box> */}
			{/* <div className="pl-8 pr-28 -mt-24 w-full">
				<Box
					className="w-full h-96 rounded-4 relative shadow p-8"
					sx={{
						backgroundColor: palette.background.paper,
						color: palette.text.primary
					}}
				>
					<span className="text-12 opacity-75">Paper</span>
				</Box>
			</div> */}

			{/* <div className="px-8 py-8 w-full">
				<span className="text-12 opacity-75">Background</span>
			</div> */}
			{/*
			<pre className="language-js p-24 w-400">{JSON.stringify(palette, null, 2)}</pre> */}
		</Box>
	);
}

export default PalettePreview;
