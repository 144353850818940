import { Box } from '@mui/material';
import type { ReactNode } from 'react';

export default function LoaderFallback(): ReactNode {
	return (
		<Box id="fuse-splash-screen" sx={{ background: 'transparent' }}>
			<Box className="center">
				<Box className="logo">
					<Box
						id="svg-splash-image"
						className="shapeshifter play"
						style={{
							backgroundImage: 'url(assets/images/splash/sprite_30fps.svg)'
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
}
