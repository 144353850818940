import i18next from 'i18next';

import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';
import DashboardConfig from './dashboard/DashboardConfig';
import PanelConfig from './panels/PanelConfig';

i18next.addResourceBundle('en', 'dashboardPage', en);
i18next.addResourceBundle('sp', 'dashboardPage', sp);
i18next.addResourceBundle('pt', 'dashboardPage', pt);

const DashboardMenuConfig = [DashboardConfig, PanelConfig];

export default DashboardMenuConfig;
