import FusePageCarded from '@fuse/core/FusePageCarded';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Content } from './components/Content';

export default function MaintenanceReport() {
	const { t } = useTranslation('maintenancePage');
	return (
		<FusePageCarded
			scroll="page"
			sx={{ p: 3, gap: 3 }}
			header={
				<Typography fontSize="26px" fontWeight={700} width="100%">
					{t('MAINTENANCE_REPORT')}
				</Typography>
			}
			content={<Content />}
		/>
	);
}
