import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { generalFormValidationSchema } from 'app/main/monitoring-settings/components/Modal/DialogForm/formValidationSchema';
import { TModalMode, TMonitoringSetting } from 'app/main/monitoring-settings/types';
import { InferType } from 'yup';
import { RootState } from '..';

export type TFormData = Partial<InferType<typeof generalFormValidationSchema>>;
type TInitialState = {
	isModalOpen: boolean;
	modalMode: TModalMode;
	selectedRows: TMonitoringSetting[];
	selectedSetting: TMonitoringSetting | null;
	form: {
		currentStep: number;
		data: TFormData;
	};
};

export const initialState: TInitialState = {
	isModalOpen: false,
	modalMode: 'new',
	selectedRows: [],
	selectedSetting: null,
	form: {
		currentStep: 1,
		data: {}
	}
};

const monitoringSettingsSlice = createSlice({
	name: 'monitoringSettings',
	initialState,
	reducers: {
		setModalMode: (state, action: PayloadAction<TModalMode>) => {
			state.isModalOpen = true;
			state.modalMode = action.payload;
		},

		setSelectedSetting: (state, action: PayloadAction<TMonitoringSetting>) => {
			state.selectedSetting = action.payload;
		},

		nextFormStep: (state) => {
			state.form.currentStep = state.form.currentStep + 1;
		},
		prevFormStep: (state) => {
			state.form.currentStep = state.form.currentStep - 1;
		},
		goToFormStep: (state, { payload }: { payload: number }) => {
			state.form.currentStep = payload;
		},
		addFormData: (state, { payload }: { payload: TFormData }) => {
			state.form.data = {
				...state.form.data,
				...payload
			};
		},
		clearFormData: (state) => {
			state.form = {
				currentStep: 1,
				data: null
			};
		},
		clearSelectSettingStep: (state) => {
			state.form = {
				...state.form,
				data: null
			};
		},
		clearSelectTargetStep: (state) => {
			state.form = {
				...state.form,
				data: null
			};
		},
		setSelectedRows: (state, { payload }: { payload: TMonitoringSetting[] }) => {
			state.selectedRows = payload;
		}
	}
});

export const {
	setModalMode,
	nextFormStep,
	prevFormStep,
	goToFormStep,
	addFormData,
	clearFormData,
	clearSelectSettingStep,
	clearSelectTargetStep,
	setSelectedSetting,
	setSelectedRows
} = monitoringSettingsSlice.actions;

export const selectModalMode = (state: RootState) => state.monitoringSettings.modalMode;
export const selectCurrentFormStep = (state: RootState) => state.monitoringSettings.form.currentStep;
export const selectCurrentFormData = (state: RootState) => state.monitoringSettings.form.data;
export const selectSelectedCompany = (state: RootState) => state.monitoringSettings.form.data?.company?._id ?? '';
export const selectSelectedSetting = (state: RootState) => state.monitoringSettings.selectedSetting;
export const selectSelectedRows = (state: RootState) => state.monitoringSettings.selectedRows;

export default monitoringSettingsSlice.reducer;
