import { SettingsOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { TColumnsNames } from 'app/main/users/types';
import FilterDialog from 'app/shared-components/CRUDs/Header/FilterDialog';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import { TFunction } from 'react-i18next';

interface ConfigTableProps {
	t: TFunction;
	visibleColumns: string[];
	changeSearchKey: (searchKey: string) => void;
	setVisibleColumns: any;
	searchParamsData: any;
	columns: TColumnsNames[];
	searchKeys: TColumnsNames[];
	showSearchKey?: boolean;
}

export const ConfigTable: React.FC<ConfigTableProps> = ({
	t,
	visibleColumns,
	changeSearchKey,
	setVisibleColumns,
	searchParamsData,
	columns,
	searchKeys,
	showSearchKey
}) => {
	const dispatch = useAppDispatch();

	const handleOpenConfigTable = () => {
		dispatch(
			openDialog({
				children: (
					<FilterDialog
						searchKeys={searchKeys}
						currentSelectedColumns={visibleColumns}
						currentSearchKey={searchParamsData?.searchKey || ''}
						handleApply={handleApply}
						columns={columns}
						t={t}
						showSearchKey={showSearchKey}
					/>
				)
			})
		);
	};

	const handleApply = (searchKey: string, columns: string[]) => {
		try {
			const action = setVisibleColumns(columns);
			if (action && typeof action === 'object' && 'type' in action) {
				dispatch(action);
			} else {
				console.error('Invalid action:', action);
			}
			changeSearchKey(searchKey);
		} catch (error) {
			console.error('Error in handleApply:', error);
		}
	};

	return (
		<Tooltip title={t('CONFIG_TABLE')} arrow>
			<IconButton color="secondary" onClick={handleOpenConfigTable}>
				<SettingsOutlined />
			</IconButton>
		</Tooltip>
	);
};
