const locale = {
	ICON_REQUIRED: 'El campo de ícono es obligatorio',
	DESCRIPTION_REQUIRED: 'El campo de descripción es obligatorio',
	ICON: 'Ícone',
	DESCRIPTION: 'Descripción',
	COMPANY_REQUIRED: 'El campo de empresa es obligatorio',
	DID_MUST_BE_NUMBER: 'El campo DID debe ser un número',
	TRACKER_TYPE_REQUIRED: 'El campo de tipo de permiso es obligatorio',
	DID_REQUIRED: 'El campo DID es obligatorio',
	NOTES: 'Notas',
	VEHICLE: 'Seguimiento',
	TRACKER_TYPE: 'Tipo de permiso',
	TITLE: 'Permisos',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfiles',
	PROFILE: 'Perfil',
	USERS: 'Usuarios',
	USER: 'Usuario',
	BIRTHDAY: 'Fecha de nacimiento',
	PASSWORD: 'Contraseña',
	CPASSWORD: 'Confirmar contraseña',
	DATE: 'Fecha',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts accesibles',
	ADDRESS: 'Dirección',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Correo electrónico',
	PHONE: 'Teléfono',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún elemento seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	ROWS_PER_PAGE: 'Elementos por página',
	NAME_REQUIRED: 'El campo de nombre es obligatorio',
	PHONE_REQUIRED: 'El campo de teléfono es obligatorio',
	EMAIL_REQUIRED: 'El campo de correo electrónico es obligatorio',
	ADDRESS_REQUIRED: 'El campo de dirección es obligatorio',
	TYPE_REQUIRED: 'El campo de tipo de cliente es obligatorio',
	COLUMN_NAME: 'Nombre',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Fecha de nacimiento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Creado por',
	COLUMN_CREATED_AT: 'Creado en',
	COLUMN_EMAIL: 'Correo electrónico',
	COLUMN_ADDRESS: 'Dirección',
	COLUMN_PHONE: 'Teléfono',
	NAME: 'Nombre',
	CODE: 'Código',
	DESC: 'Descripción',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar permiso',
	DELETE_CONFIRMATION: '¿Está seguro que desea eliminar',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Edición de permiso',
	EDIT_CONFIRMATION: '¿Está seguro que desea editar este permiso?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Está seguro que desea editar los seguimientos seleccionados?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	ERROR_PERMISSION: 'No tiene los permisos necesarios, por favor contacte al administrador del sistema',
	ERROR_GETTING_USERS: 'Hubo un error cargando los permisos, por favor intente nuevamente más tarde',
	ERROR_RESPONSE_404: 'Error: Datos no encontrados, por favor contacte al administrador del sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor, por favor intente nuevamente más tarde',
	ERROR_NETWORK: 'Error de red, verifique su conexión e intente nuevamente',
	ADD_USER: 'Añadir Rastreador',
	CREATE_SUCCESS: 'Rastreador agregado exitosamente',
	CREATE_ERROR: 'Error al agregar rastreador',
	EDIT_SUCCESS: 'Rastreador actualizado con éxito',
	EDIT_ERROR: 'Error al actualizar rastreador',
	ERROR_ADD_EMPTY: 'Complete todos los campos',
	DELETE_SUCCESS: 'Rastreador eliminado con éxito',
	DELETE_ERROR: 'Error al eliminar rastreador',
	NO_DATA: 'No se encontraron elementos coincidentes',
	NO_RESULTS: 'No se encontraron resultados',
	NO_PERMISSION_list: 'No tienes permiso para ver la lista de rastreadores',
	NO_PERMISSION_new: 'No tienes permiso para agregar rastreadores',
	NO_PERMISSION_edit: 'No tienes permiso para editar rastreadores',
	NO_PERMISSION_view: 'No tienes permiso para ver rastreadores',
	NO_PERMISSION_delete: 'No tienes permiso para eliminar rastreadores',
	NO_ADDRESS: 'Dirección no encontrada',
	NO_PHONE: 'Teléfono no encontrado',
	NO_EMAIL: 'Correo electrónico no encontrado',
	NO_BIRTHDAY: 'Fecha de nacimiento no encontrada',
	LOADING: 'Cargando',
	UPLOAD_ERROR: 'Error al cargar el archivo',
	PASSWORD_MIN_LENGTH: 'debe tener al menos 8 caracteres',
	PASSWORDS_MUST_MATCH: 'Las contraseñas deben coincidir',
	PASSWORD_REQUIRED: 'el campo de contraseña es obligatorio',
	PASSWORD_MIN_LOWERCASE: 'debe tener al menos 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'debe tener al menos 1 letra mayúscula',
	PASSWORD_MIN_NUMBERS: 'debe tener al menos 1 número',
	PASSWORD_MIN_SYMBOLS: 'debe tener al menos 1 símbolo',
	MORE_THAN: 'más que',
	NO_DATA_FOUND: 'No se encontraron datos',
	DELETE_MODAL_WARNING: '¡Advertencia!',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Está seguro de que desea eliminar {{n}} rastreadores?',
	EDIT_INFO: 'Editar rastreador',
	DELETE_INFO: 'Eliminar rastreador',
	DOWNLOAD_INFO: 'Descargar rastreador',
	EDIT_MULTIPLE_INFO: 'Editar rastreadores seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar rastreadores seleccionados',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar el archivo',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	SELECTED_HOSTS: '{{n}} Host seleccionados',
	PROFILE_REQUIRED: 'El campo Perfil es obligatorio',
	CPASSWORD_REQUIRED: 'El campo Confirmar contraseña es obligatorio',
	EDIT_MULTIPLE_SUCCESS: 'Rastreadors actualizados correctamente',
	EDIT_MULTIPLE_ERROR: 'Error al actualizar rastreadores',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, elige una imagen de menos de 8MB',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',
	CODE_REQUIRED: 'El campo Código es obligatorio',
	HELPER_SEND_TO_MONITORING: 'Enviar la regla al monitoreo',
	SEND_TO_MONITORING_TOOLTIP: 'Agrega una nueva alerta a la pantalla de monitoreo cuando se alcanzan las reglas',
	BACK: 'Volver',
	GET_TRACKER_COMMANDS_ERROR: 'Error al obtener comandos de rastreador',
	GET_GEOFENCES_ERROR: 'Error al obtener geocercas',
	ALL_USERS_OF: 'Todos los usuarios de',
	ALL_FLEETS_OF: 'Todos los vehículos de la flota',
	TARGETS: 'Objetivos',
	ALL_TARGETS_OF: 'Todos los objetivos de',
	NOTIFICATION_APP: 'Notificaciones dentro de la aplicación'
};
export default locale;
