import { Box, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';

const ITEM_HEIGHT = 51;

function TableRowSkeleton() {
	return (
		<Stack height={`${ITEM_HEIGHT}px`} direction="row" alignItems="center" spacing={2} paddingX="16px">
			<Skeleton variant="rounded" width="20px" height="20px" />
			<Skeleton variant="text" width="100%" height="20px" />
		</Stack>
	);
}

type TTableSkeletonProps = {
	height: number;
	width?: number | string;
};

export default function TableSkeleton({ height = 0, width = '100%' }: TTableSkeletonProps) {
	return height ? (
		<Box
			sx={{
				height,
				width,
				display: 'flex',
				flexDirection: 'column',
				gap: '2px'
			}}
		>
			{Array.from({ length: Math.round(height / ITEM_HEIGHT) }).map((_, i) => (
				<TableRowSkeleton key={`skeleton-row-${i}`} />
			))}
		</Box>
	) : undefined;
}
