import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

interface IInitialState {
	isGeoFenceListOpen: boolean;
}

const initialState: IInitialState = {
	isGeoFenceListOpen: true
};

const manageGeoFenceSlice = createSlice({
	name: 'manageGeoFence',
	initialState,
	reducers: {
		toggleGeoFenceList: (state) => {
			state.isGeoFenceListOpen = !state.isGeoFenceListOpen;
		},
		closeGeoFenceList: (state) => {
			state.isGeoFenceListOpen = false;
		}
	}
});

export const { toggleGeoFenceList, closeGeoFenceList } = manageGeoFenceSlice.actions;

export const selectIsGeoFenceListOpen = (state: RootState) => state.manageGeoFence.isGeoFenceListOpen;

export default manageGeoFenceSlice.reducer;
