import { ReactNode, createContext, useCallback, useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import storageService from 'app/services/storage';
import LoaderFallback from 'app/shared-components/LoaderFallback';
import { useCompanyThemeQuery } from 'app/store/api/settingsSlice';
import { resetToCompanyFuseSettings } from 'app/store/fuse/settingsSlice';
import { selectUserLoading } from 'app/store/user/userSlice';
import { useSelector } from 'react-redux';
import { isTokenValid } from 'src/utils/utils';
import { useAppDispatch } from '../store';

interface IAuthContext {
	signOut: () => void;
}

type TAuthProvider = {
	children: ReactNode;
};

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const OPEN_ROUTES = [
	'/sign-in',
	'/sign-out',
	'/recover-password',
	'/recover-password/:id',
	'/permission-not-found',
	'/public-link'
];

const HOST = window.location.host;

export default function AuthProvider({ children }: TAuthProvider) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { isLoading: companyLoading } = useCompanyThemeQuery(HOST);
	const userLoading = useSelector(selectUserLoading);

	const isOpenRoute = OPEN_ROUTES.some((route) =>
		matchPath(
			{
				path: route
			},
			location.pathname
		)
	);

	const isUserLoading = userLoading && !isOpenRoute;

	const signOut = useCallback(() => {
		storageService.remove('token');
		dispatch({ type: 'user/userLoggedOut' });
		dispatch(resetToCompanyFuseSettings());
		navigate('/sign-in');
	}, [dispatch, navigate]);

	useEffect(() => {
		if (!isOpenRoute && !isTokenValid()) {
			signOut();
		}
	}, [signOut, location, isOpenRoute]);

	return (
		<AuthContext.Provider
			value={{
				signOut
			}}
		>
			{companyLoading || isUserLoading ? <LoaderFallback /> : children}
		</AuthContext.Provider>
	);
}
