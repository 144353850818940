const locale = {
	// Común

	EMAIL: 'Correo electrónico',
	PHONE: 'Teléfono',
	MOBILE: 'Móvil',
	CNH: 'Licencia de conducir',
	CNH_TYPE: 'Tipo de licencia de conducir',
	CNH_DUE_DATE: 'Fecha de vencimiento de la licencia de conducir',
	CPF: 'CPF',
	RG: 'ID',
	RG_TYPE: 'Emisor',
	RG_CREATED_DATE: 'Fecha de emisión de la ID',
	BIRTHDAY: 'Fecha de nacimiento',
	NOTES: 'Notas',
	USER: 'Usuario',
	ADDRESS: 'Dirección',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	DATE: 'Fecha',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	NAME: 'Nombre',
	DESCRIPTION: 'Descripción',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	ADD_DRIVER: 'Agregar conductores',
	LOADING: 'Cargando',
	CONFIRM: 'Confirmar',

	// Encabezado

	TITLE: 'Conductores',
	IMPORT_DRIVERS: 'Importar conductores',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	DOWNLOAD_ALL: 'Descargar todo',

	// Barra lateral

	EDIT_INFO: 'Editar información del conductor',
	DELETE_INFO: 'Eliminar información del conductor',
	DOWNLOAD_INFO: 'Descargar conductores seleccionados',
	EDIT_MULTIPLE_INFO: 'Editar conductores seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar conductores seleccionados',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar conductores',
	EDIT_MULTIPLE_SUCCESS: 'Conductores editados correctamente',
	EDIT_MULTIPLE_ERROR: 'Error al editar conductores',

	// Campos obligatorios

	COMPANY_REQUIRED: 'El campo empresa es obligatorio',
	NAME_REQUIRED: 'El campo nombre es obligatorio',
	EMAIL_REQUIRED: 'El campo correo electrónico es obligatorio',
	INVALID_EMAIL: 'Correo electrónico inválido',
	INVALID_COMPANY: 'Empresa inválida',

	// Tabla

	OF: 'de',
	ROWS_PER_PAGE: 'Elementos por página',
	NO_DRIVER_FOUND: 'No se encontraron conductores',

	// Petición

	CREATE_SUCCESS: 'Conductor agregado correctamente',
	CREATE_ERROR: 'Error al agregar conductor',
	EDIT_SUCCESS: 'Conductor actualizado correctamente',
	EDIT_ERROR: 'Error al actualizar conductor',
	DELETE_SUCCESS: 'Conductor eliminado correctamente',
	DELETE_ERROR: 'Error al eliminar conductor',
	MASSIVE_CREATE_SUCCESS: 'Conductores agregados correctamente',
	MASSIVE_CREATE_ERROR: 'Error al agregar conductores, verifique los datos e inténtelo de nuevo',

	// Modal

	DELETE_MODAL_TITLE: 'Eliminar conductor',
	DELETE_MODAL_DESCRIPTION: '¿Seguro que desea eliminar este conductor?',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Seguro que desea eliminar {{n}} conductores?',

	// Permisos

	PAGE_NOT_ALLOWED: 'No tiene permiso para ver esta página.',
	MINIMAL_START: 'Solicite al administrador que agregue los siguientes permisos a su perfil:',
	MINIMAL_END: 'Luego cierre sesión y vuelva a iniciar sesión en el sistema.',

	// Granel

	SHOW_INVALID_ROWS: 'Mostrar filas inválidas',
	CLEAR_TABLE: 'Limpiar tabla',
	CREATING: 'Creando',
	DESCRIPTION_HEADER:
		'Aquí puede crear conductores en masa, simplemente cargue un archivo CSV con los datos de los conductores, o descargue la plantilla y complete los datos de los conductores que desea crear, puede abrir el archivo CSV con Excel u otro editor de hojas de cálculo.',
	DOWNLOAD_TEMPLATE: 'Descargar plantilla.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arrastre y suelte el archivo CSV aquí o haga clic para seleccionar.',
	REMOVE_ROW: 'Eliminar filas',

	// Mensajes de error

	USER_ALREADY_REGISTERED_AS_A_DRIVER: 'El usuario seleccionado ya ha sido registrado como conductor.',

	CREATING_DRIVER: 'Creando conductor',
	VALIDATING_IBUTTON: 'Validando iButton',
	CREATING_IBUTTON: 'Creando iButton',
	DRIVER_CREATED: 'Conductor creado',
	LINK_HEADER:
		"Aquí puede registrar conductores y sus respectivos identificadores, simplemente descargue y complete la plantilla .xlsx a continuación con los datos de los conductores y sus identificadores, y haga clic en 'Importar'. Los identificadores se validarán y crearán automáticamente si aún no existen en la empresa proporcionada, si ya existen, solo se vincularán a ellos.",

	LINK_IBUTTON_DRIVER_SUCCESS: 'Conductores e identificadores vinculados correctamente',
	LINK_IBUTTON_DRIVER_ERROR: 'Error al vincular conductores e identificadores',
	LINK_IBUTTON_DRIVER: 'Vincular conductores e identificadores',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabla'
};

export default locale;
