import { CloseOutlined, DragIndicatorOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { memo, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import { closeMapToolbar, selectMapToolbarOpen, selectMapToolbarOptions } from 'app/store/map/mapToolbarSlice';

function MapToolbar() {
	const dispatch = useDispatch();
	const isOpen = useSelector(selectMapToolbarOpen);
	const options = useSelector(selectMapToolbarOptions);
	const [isDragging, setIsDragging] = useState(false);

	const handleClose = () => {
		if (typeof options.onClose === 'function') {
			options.onClose();
		}
		dispatch(closeMapToolbar());
	};
	const handleDragStart = () => setIsDragging(true);
	const handleDragStop = () => setIsDragging(false);

	return (
		<Draggable handle=".handle" cancel=".cancel" onStart={handleDragStart} onStop={handleDragStop}>
			<Box
				sx={{
					display: isOpen ? 'flex' : 'none',
					position: 'absolute',
					bottom: '140px',
					left: 12,
					background: (theme) => theme.palette.background.paper,
					cursor: isDragging ? 'grabbing' : 'grab',
					justifyContent: 'flex-start',
					boxShadow: '0px 1px 7px rgba(17, 17, 17, 0.3)',
					borderRadius: 1,
					padding: 1
				}}
			>
				<Box
					sx={{
						paddingRight: 1,
						alignSelf: 'flex-start'
					}}
				>
					<DragIndicatorOutlined className="handle" />
				</Box>
				<Box flex={1}>{options.content}</Box>
				<Box
					sx={{
						paddingLeft: 1,
						alignSelf: 'flex-start'
					}}
				>
					<IconButton size="small" onClick={handleClose}>
						<CloseOutlined fontSize="small" />
					</IconButton>
				</Box>
			</Box>
		</Draggable>
	);
}

const Memonized = memo(MapToolbar);

export default Memonized;
