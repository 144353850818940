import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'notificationRulerHistory'>, MatchConditions>;

const LIST_RULER_PERMISSIONS = [
	'admin.notification-ruler-history.list',
	'admin.tracker-command.view',
	'admin.tracker-command.list'
];

const VIEW_RULER_PERMISSIONS = [
	'admin.notification-ruler-history.view',
	'admin.tracker-command.view',
	'admin.tracker-command.list'
];

export const defineRulerNotificationHistoryAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_RULER_PERMISSIONS),
		create: [],
		edit: [],
		delete: [],
		list: getMissingPermissions(LIST_RULER_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('notificationRulerHistory', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(VIEW_RULER_PERMISSIONS)) can('view', 'notificationRulerHistory');
	if (userHasPermission(LIST_RULER_PERMISSIONS)) can('list', 'notificationRulerHistory');

	return [build(), missingPermissions] as const;
};
