import { THIRTY_ONE_DAY_ERROR_MESSAGE_EN } from 'src/constants';
import { commonEnTranslates } from 'src/constants/common-translates';

/* prettier-ignore */
const locale = {
  // Columns
  PLATE: 'License Plate',
  DID: 'DID',
  ADDRESS: 'Address',
  ODOMETER: 'Odometer',
  GPS_TIME: 'GPS Time',
  EVENT_TIME: 'Event Time',
  SPEED: 'Speed',
  IGNITION_ON: 'Ignition On',
  IS_GPS_RUNNING: 'GPS Running',
  GOOGLE_MAPS: 'Google Maps',
  SEQUENCE: 'Sequence',
  PARSER: 'Parser',
  ALTITUDE: 'Altitude',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  HOURS_NUM: '{{n}} Hours',
  UNKNOWN: 'Unknown',
  VEHICLE_VOLTAGE: 'Vehicle Voltage',
  SERVER_TIME: 'Hora do servidor',


  // Header

  TITLE: 'Events Report',
  ADD: 'Add',
  SHOW_FILTERS: 'Show Filters',
  CONFIG_TABLE: 'Configure table',
  THIRTY_DAY_ERROR: THIRTY_ONE_DAY_ERROR_MESSAGE_EN,
  SIX_MONTHS_AGO_INFO: commonEnTranslates.SIX_MONTHS_AGO_INFO,

  // Table

  NO_HISTORY_FOUND: 'No events found',
  NO_FILTERS: 'No filters applied',
  NO_FILTERS_SUBTITLE: 'Select the filters you want to apply to see the results',
  ROWS_PER_PAGE: 'Items per page',
  OF: 'of',

  // Commons

  COLUMNS: 'Columns',
  INFO_TRACKER: 'Tracker Information',
  DETAILS: 'Details',
  COORDINATES: 'Coordinates',
  RXLEV: 'Signal Strength',
  SATELLITES: 'Satellites',
  GPS_FIXED: 'GPS Fixed',
  BATTERY: 'Battery',
  HOURMETER: 'Hour Meter',
  SELECT_FILTERS: 'Select filters',
  PAGE_NOT_ALLOWED: 'You do not have permission to access this screen.',
  MAX_POINTS: 'Maximum number of points exceeded, using the first 24 items',
  SEARCH: 'Search',
  VIEW_ON_MAP: 'View on Google Maps',
  MAX_TIME_INTERVAL: 'The maximum interval is 12 hours',
  POSITION: 'Position',
  SELECTED_ITEMS: 'Selected items',
  TRACKER: 'Tracker',
  VEHICLE: 'Vehicle',
  NO_FILTER_PERMISSION: 'You do not have permission to use filters.',
  NO_FILTER_PERMISSION_DESCRIPTION: 'Contact the administrator to request the following permissions:',
  LAT: 'Latitude',
  LNG: 'Longitude',
  ALT: 'Altitude',
  TRIP: 'Trip nº{{n}}',
  STREET_VIEW_WARNING_1: 'You selected more than 24 points.',
  STREET_VIEW_WARNING_2:
    'The coordinates were grouped by {{n}} trips, ordered by date and time. You can select a trip to see the points on the map.',
  CLOSE: 'Close',
  DRIVER: 'Driver',
  CLEAR_FILTERS: 'Clear filters',


  // Filter

  TRACKERS: 'Trackers',
  FLEETS: 'Fleets',
  EVENT_TYPE: 'Event Type',
  END_DATE: 'End Date',
  START_DATE: 'Start Date',
  FLEETS_SELECTED: '{{n}} fleets',
  VEHICLES_SELECTED: '{{n}} vehicles',
  TRACKERS_SELECTED: '{{n}} trackers',
  EVENT_TYPES_SELECTED: '{{n}} event types',
  APPLY: 'Apply',
  RESET: 'Reset Filters',

  // Validations

  END_REQUIRED: 'End date is required',
  START_REQUIRED: 'Start date is required',
  COMPANIES_REQUIRED: 'Select a company',
  AT_LEAST_ONE_FILTER: 'Select at least one other filter',
  END_DATE_SHALL_BE_GREATER: 'End date must be greater than start date',
  NO_RESULTS: 'No results found',

  // Download

  DOWNLOAD_SELECTED: 'Download selected',
  DOWNLOAD_ALL: 'Download all',
  DOWNLOAD: 'Download',
  DOWNLOAD_WARNING: 'The download may take a few minutes due to the large number of files',
  ERROR_DOWNLOADING_HISTORY: 'Error downloading events',
  UNAVAILABLE: 'Unavailable',

  // Excel

  RUNNING: 'Running',
  NOT_RUNNING: 'Not running',
  ON: 'On',
  OFF: 'Off',
  YES: 'Yes',
  NO: 'No',


  ...commonEnTranslates
};

export default locale;
