import i18next from 'i18next';
import en from './i18n/en';
import pt from './i18n/pt';
import SignInPage from './SignInPage';

import sp from './i18n/sp';

i18next.addResourceBundle('en', 'SignInPage', en);
i18next.addResourceBundle('pt', 'SignInPage', pt);
i18next.addResourceBundle('sp', 'SignInPage', sp);

const SignInConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			path: 'sign-in',
			element: <SignInPage />
		}
	]
};

export default SignInConfig;
