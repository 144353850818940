import { apiSlice } from './apiSlice';

export type IPayload = {
	id?: string;
	title: string;
	description: string;
	category: string[];
	lessons: string[];
};
export type IEditPayload = {
	id?: string;
	title: string;
	description: string;
	category: string[];
	lessons: string[];
};
const coursesSlice = apiSlice.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		getCourses: query({
			query: () => ({
				url: '/course/',
				method: 'GET'
			}),
			providesTags: ['Course']
		}),
		deleteCourses: mutation({
			query: (id: string) => ({
				url: `/course/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Course']
		}),

		editCourses: mutation<string, IEditPayload>({
			query: (courses) => ({
				url: `/course/${courses.id}`,
				method: 'PUT',
				body: courses,
				
			}),
			invalidatesTags: ['Course'],
		
		}),

		getCourse: query({
			query: (id: string) => ({
				url: `/course/${id}`,
				method: 'GET'
			}),
			providesTags: ['Course']
		}),
		createCourses: mutation({
			query: (courses) => ({
				url: '/course',
				method: 'POST',
				body: courses
			}),
			invalidatesTags: ['Course']
		}),
		
	})
});

export const {
	useGetCoursesQuery,
	useGetCourseQuery,
	useCreateCoursesMutation,
	useEditCoursesMutation,
	useDeleteCoursesMutation
} = coursesSlice;
