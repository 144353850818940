import { TMonitoringGroup } from './types';

export const EVENT_TYPE = {
	'E.IGN_ON': 'IGN_ON',
	'E.IGN_OFF': 'IGN_OFF',
	'E.DIN_CHANGED': 'DIN_CHANGED',
	'E.EXT_POWER_CUT_OFF': 'EXT_POWER_OFF',
	'E.PANIC': 'PANIC',
	'E.LOW_BATTERY_VOLTAGE': 'LOW_BATTERY',
	'E.SHIFT_WARNING': 'SHIFT_WARNING',
	'E.GPS_ANTENNA_OPEN': 'GPS_ANTENNA_OPEN',
	'E.GPS_ANTENNA_SHORT': 'GPS_ANTENNA_SHORT',
	'E.EXT_POWER_RECONNECTED': 'EXT_POWER_RECONNECTED',
	'E.UNDER_SPEED': 'UNDER_SPEED',
	'E.OVER_SPEED': 'OVER_SPEED',
	'E.INTO_FENCE': 'INTO_FENCE',
	'E.OUT_FENCE': 'OUT_FENCE',
	'E.EXTERNAL_BATTERY_LOW': 'EXTERNAL_BATTERY_LOW'
} as const;

export const ALARM_COLOR = {
	1: '#1E9756',
	2: '#FFC645',
	3: '#E45353'
};

export const ALARM_TEXT = {
	1: 'LOW',
	2: 'MEDIUM',
	3: 'HIGH'
};

export const GROUP_PROP_MAP: { [key in TMonitoringGroup]: string } = {
	alarm: 'trackerMessage.EVENT_CODE',
	tracker: 'trackerMessage.tracker._id',
	user: 'user._id',
	vehicle: 'trackerMessage.vehicle._id'
};

export const GROUP_TITLE: { [key in TMonitoringGroup]: string } = {
	alarm: 'trackerMessage.EVENT_TYPE',
	tracker: 'trackerMessage.tracker.name',
	user: 'user.name',
	vehicle: 'trackerMessage.vehicle.name'
};

export const GROUP_ITEM_TITLE: { [key in TMonitoringGroup]: string } = {
	alarm: 'trackerMessage.vehicle.name',
	tracker: 'trackerMessage.EVENT_TYPE',
	user: 'trackerMessage.EVENT_TYPE',
	vehicle: 'trackerMessage.EVENT_TYPE'
};
