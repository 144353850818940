const pt = {
	// HEADER
	FILTER: 'Filtrar',
	FILTER_DESCRIPTION: 'Os campos abaixo, mostrarão os dados apenas das empresas selecionadas.',
	EXPORT: 'Exportar dados',
	TITLE: 'Relátorio de envio de pacotes',

	// MODAL
	DATE: 'Data',

	RESET: 'Limpar filtros',
	SEARCH: 'Buscar',

	TRACKER: 'Rastreador',
	SELECTED_TRACKERS: '{{n}} Rastreadores selecionados',

	COMPANY: 'Empresa',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',

	VEHICLE: 'Veículo',
	SELECTED_VEHICLES: '{{n}} Veículos selecionados',

	AT_LEAST_ONE_FILTER: 'Pelo menos um filtro deve ser selecionado',
	START_DATE_REQUIRED: 'Campo obrigatório',
	END_DATE_REQUIRED: 'Campo obrigatório',

	WEEKLY_METRICS: 'Pacotes enviados',
	BY_WEEK: 'Por semana',

	MONTHLY_METRICS: 'Pacotes enviados',
	BY_MONTH: 'Por mês',

	DAILY_METRICS: 'Pacotes enviados',
	BY_DAY: 'Por dia',

	SELECTED_MONTH: 'Mês selecionado',
	LAST_MONTH: 'Mês anterior',

	NO_DATA: 'Nenhum dado encontrado',
	PACKETS_SENT: 'Pacotes enviados',

	NO_FILTER_COMPONENT_TEXT: 'Nenhum filtro selecionado',
	NO_FILTER_COMPONENT_TEXT_SECONDARY: 'Selecione um ou mais filtros para visualizar os dados',

	TRACKERS: 'Rastreadores',
	BY_STATE: 'Por estado',

	IDLE: 'Parado',
	MOVING: 'Em movimento',
	NO_COMM: 'Sem comunicação',
	WRONG_POSITION: 'Posicionamento errado',
	TOWED: 'Guinchado',
	CONNECTED: 'Conectado',
	WITHOUT_VEHICLE: 'Sem veículo',
	BLOCKED: 'Bloqueado',

	PACKETS: 'Pacotes',
	TOTAL_PACKETS_SEND_BY_TRACKERS: 'Total de pacotes enviados por rastreadores',

	TOP_FIVE_TRACKERS: 'Ranking dos rastreadores  que mais enviam pacotes '
};

export default pt;
