import { ShareOutlined } from '@mui/icons-material';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Tooltip,
	Typography
} from '@mui/material';
import usePublicToken from 'app/main/public-link/hooks/use-public.token';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch } from 'app/store/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PublicLinkProps {
	vehicleId: string;
}

export const PublicLink: React.FC<PublicLinkProps> = ({ vehicleId }) => {
	const { t } = useTranslation('mapPage');
	const dispatch = useAppDispatch();
	const { createPublicLink, isCreatingPublicLink } = usePublicToken();
	const [open, setOpen] = useState(false);
	const [expirationDate, setExpirationDate] = useState('24');

	const handleOpenModal = () => {
		setOpen(true);
	};

	const handleCloseModal = () => {
		setOpen(false);
	};

	const handleExpirationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExpirationDate(event.target.value);
	};

	const handleCreatePublicLink = async () => {
		try {
			const { token } = await createPublicLink({
				expirationDate: new Date(Date.now() + parseInt(expirationDate) * 60 * 60 * 1000).toISOString(),
				vehicleId
			}).unwrap();

			const publicLinkUrl = `/public-link/?token=${token}`;
			window.open(publicLinkUrl, '_blank');
			handleCloseModal();
		} catch (error) {
			dispatch(
				showMessage({
					variant: 'error',
					message: t('PUBLIC_LINK_ERROR')
				})
			);
		}
	};

	return (
		<>
			<Tooltip title={t('PUBLIC_LINK')}>
				<IconButton size="small" color="secondary" onClick={handleOpenModal}>
					{isCreatingPublicLink ? <CircularProgress size={20} color="secondary" /> : <ShareOutlined />}
				</IconButton>
			</Tooltip>
			<Dialog open={open} onClose={handleCloseModal}>
				<DialogTitle>{t('CREATE_PUBLIC_LINK')}</DialogTitle>
				<DialogContent>
					<Typography color="text.secondary" sx={{ mb: 2 }}>
						{t('PUBLIC_LINK_INFO')}
					</Typography>
					<RadioGroup row value={expirationDate} onChange={handleExpirationChange}>
						<FormControlLabel value="24" control={<Radio />} label={t('24_HOURS')} />
						<FormControlLabel value="48" control={<Radio />} label={t('48_HOURS')} />
						<FormControlLabel value="72" control={<Radio />} label={t('72_HOURS')} />
					</RadioGroup>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseModal} color="error">
						{t('CANCEL')}
					</Button>
					<Button
						onClick={handleCreatePublicLink}
						color="secondary"
						variant="contained"
						disabled={isCreatingPublicLink}
					>
						{isCreatingPublicLink ? <CircularProgress size={24} color="inherit" /> : t('CREATE')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
