const locale = {
	TITLE: 'Empresas',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	DESCRIPTION: 'Descrição',
	CUSTOMER_TYPE: 'Tipo de cliente',
	PJ: 'Pessoa jurídica',
	PF: 'Pessoa física',
	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	ADDRESS: 'Endereço',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Telefone',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	ROWS_PER_PAGE: 'Itens por página',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	PHONE_REQUIRED: 'O campo telefone é obrigatório',
	EMAIL_REQUIRED: 'O campo email é obrigatório',
	ADDRESS_REQUIRED: 'O campo endereço é obrigatório',
	TYPE_REQUIRED: 'O campo tipo de cliente é obrigatório',
	BILLABLE_COMPANY_REQUIRED: 'O campo empresa financeira é obrigatório',
	CONFIG_TABLE: 'Configurar tabela',
	COLUMN_NAME: 'Nome',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Data de nascimento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Criado por',
	COLUMN_CREATED_AT: 'Criado em',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Endereço',
	COLUMN_PHONE: 'Telefone',
	NAME: 'Nome',
	CODE: 'Código',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar empresa',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando empresa',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar esta empresa?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar as empresas selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	FIELD_NAME: 'Nome',
	FIELD_CODE: 'Código',
	FIELD_ICON: 'Ícone',
	FIELD_DESC: 'Descrição',
	FIELD_CREATED_AT: 'Criado em',
	FIELD_CREATED_BY: 'Criado por',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar as empresas, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_COMPANY: 'Adicionar empresa',
	CREATE_SUCCESS: 'Empresa adicionada com sucesso',
	CREATE_ERROR: 'Erro ao adicionar empresa',
	EDIT_SUCCESS: 'Empresa atualizada com sucesso',
	EDIT_ERROR: 'Erro ao atualizar empresa',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Empresa deletada com sucesso',
	DELETE_ERROR: 'Erro ao deletar empresa',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de empresas',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar empresas',
	NO_PERMISSION_edit: 'Você não possui permissão para editar empresas',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar empresas',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar empresas',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'Email não encontrado',
	LOADING: 'Carregando',
	MORE_THAN: 'mais que',
	NO_COMPANIES_FOUND: 'Nenhuma empresa foi encontrada',
	DELETE_MODAL_TITLE: 'Deletar empresa',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta empresa?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} empresas?',
	EDIT_INFO: 'Editar empresa',
	DELETE_INFO: 'Deletar empresa',
	DOWNLOAD_INFO: 'Baixar empresas selecionadas',
	EDIT_MULTIPLE_INFO: 'Editar empresas selecionadas',
	DELETE_MULTIPLE_INFO: 'Deletar empresas selecionadas',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar empresas',
	DOWNLOAD_ALL: 'Baixar todas',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar Senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS: 'Empresas editadas com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar empresas',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem meno que 8MB',
	COMPANY_REQUIRED: 'O campo Empresa é obrigatório',
	EMAIL_INVALID: 'Email inválido',
	HOST_REQUIRED: 'O campo Host é obrigatório',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',

	START_DATE: 'Data de cadastro',
	END_DATE: 'Data limite',
  
  CLEAR_FILTERS: 'Limpar filtros',
  SHOW_FILTERS: 'Mostrar filtros',
	BILLABLE: 'Financeira',
	NON_BILLABLE: 'Não Financeira',
	BILLABLE_COMPANY: 'Empresa Financeira',
	BILLABLE_TOOLTIP: 'Se a empresa não for financeira, selecione a empresa a ser cobrada',

	ACTIVE: 'Ativos',
	INACTIVE: 'Desativados',
	LOST: 'Perdidos',
	DAMAGED: 'Danificados',
	STOCK: 'Estoque',
	TRACKERS: 'Rastreadores',
	VEHICLES: 'Veículos'
};

export default locale;
