const pt = {
	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	SELECTED_TAGS: '{{limit}} tags selecionadas',
	TAGS: 'Tags',
	SELECTED_COMPANIES: '{{limit}} empresas selecionadas',
	COMPANIES: 'Empresas',
	NAME_REQUIRED: 'Nome é obrigatório',
	DESCRIPTION_REQUIRED: 'Descrição é obrigatória',
	TAGS_REQUIRED: 'Tags são obrigatórias',
	COMPANIES_REQUIRED: 'Empresas são obrigatórias',
	EDIT: 'Editar',
	DELETE: 'Excluir',
	CREATE: 'Criar',
	DETAILS: 'Detalhes',
	GEO_FENCES: 'Cercas Virtuais',
	CLEAR: 'Limpar',
	SAVE: 'Salvar',
	VIEW: 'Visualizar',
	SEARCH_ADDRESS: 'Buscar endereço',
	DRAW_POLYGON: 'Adicionar novos pontos',
	DELETE_POINT: 'Deletar ponto ou cerca selecionada',
	SAVE_GEO_FENCE: 'Salvar cerca virtual',
	SUCCESS_SAVING_GEO_FENCE: 'Cerca virtual salva com sucesso',
	ERROR_SAVING_GEO_FENCE: 'Erro ao salvar cerca virtual',
	ADD: 'Adicionar',
	LOADING: 'Carregando...',
	CREATED_BY: 'Criado por',
	UPDATED_BY: 'Atualizado por',
	CREATED_AT: 'Criado em',
	UPDATED_AT: 'Atualizado em',
	NO_OPTIONS: 'Nenhuma opção encontrada',
	GEOFENCE_DELETED_SUCCESSFULLY: 'Cerca virtual excluída com sucesso',
	ERROR_DELETING_GEOFENCE: 'Erro ao excluir cerca virtual',
	DELETE_GEOFENCE: 'Excluir cerca virtual',
	DELETE_GEOFENCE_PRIMARY: 'Você tem certeza que deseja excluir esta cerca virtual?',
	DELETE_GEOFENCE_SECONDARY: 'Esta ação não pode ser desfeita.',
	DRAW_POLYGON_FIRST: 'Desenhe o polígono primeiro',
	SELECT_RANGE: 'Selecione um intervalo',
	SELECT_VEHICLE: 'Selecione um veículo',
	BUILD_GEOFENCE: 'Criar cerca virtual',
	VEHICLE: 'Veículo',
	START_DATE: 'Início',
	END_DATE: 'Fim',
	SELECT_RADIUS: 'Selecione o raio em metros a partir do ponto central',
	CREATE_GEOFENCE_DESCRIPTION:
		'Você pode criar uma cerca virtual baseada no histórico de posições de um veículo a partir de um intervalo de datas e um raio em metros.',
	ERROR_CREATING_GEOFENCE: 'Erro ao criar cerca virtual',
	NO_POSITIONS_FOUND: 'Não existem posiçoes suficientes para criar uma cerca virtual.',
	ERROR_EMPTY_GEOJSON: 'Erro ao salvar cerca virtual. O polígono não pode ser vazio.',
	CREATE_TRIP_GEO_FENCE: 'Criar cerca virtual baseada em viagem'
};
export default pt;
