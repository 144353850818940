const locale = {
	MUST_BE_LIGTH: 'The color must be light',
	MUST_BE_DARK: 'The color must be dark',
	EDIT_PALETTE: 'Edit palette',
	LIGHT: 'Light',
	DARK: 'Dark',
	PREVIEW: 'Preview',
	CANCEL: 'Cancel',
	SAVE: 'Save',
	PRIMARY_COLOR: 'Primary color',
	SECONDARY_COLOR: 'Secondary color',
	DEFAULT_BACKGROUND: 'Default background color',
	PAPER_BACKGROUND: 'Paper background color'
};

export default locale;
