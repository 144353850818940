const locale = {
	ICON_REQUIRED: 'The icon field is required',
	DESCRIPTION_REQUIRED: 'The description field is required',
	ICON: 'Icon',
	DESCRIPTION: 'Description',
	COMPANY_REQUIRED: 'The company field is required',
	DID_MUST_BE_NUMBER: 'The DID field must be a number',
	TRACKER_TYPE_REQUIRED: 'The permission type field is required',
	DID_REQUIRED: 'The DID field is required',
	NOTES: 'Notes',
	VEHICLE: 'Tracked',
	TRACKER_TYPE: 'Permission type',
	TITLE: 'Permissions',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	PROFILES: 'Profiles',
	PROFILE: 'Profile',
	USERS: 'Users',
	USER: 'User',
	BIRTHDAY: 'Birth date',
	PASSWORD: 'Password',
	CPASSWORD: 'Confirm password',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Accessible hosts',
	ADDRESS: 'Address',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Phone',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	NAME_REQUIRED: 'The name field is required',
	PHONE_REQUIRED: 'The phone field is required',
	EMAIL_REQUIRED: 'The email field is required',
	ADDRESS_REQUIRED: 'The address field is required',
	TYPE_REQUIRED: 'The customer type field is required',
	COLUMN_NAME: 'Name',
	COLUMN_COMPANY: 'Company',
	COLUMN_BIRTHDAY: 'Birth date',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Created by',
	COLUMN_CREATED_AT: 'Created at',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Address',
	COLUMN_PHONE: 'Phone',
	NAME: 'Name',
	CODE: 'Code',
	DESC: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete permission',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Editing permission',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this permission?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected trackers?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	ERROR_PERMISSION: 'You do not have the necessary permissions, please contact the system administrator',
	ERROR_GETTING_USERS: 'There was an error loading the permissions, please try again later',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Internal server error, please try again later',
	ERROR_NETWORK: 'Network error, please check your connection and try again',
	ADD_USER: 'Add tracker',
	CREATE_SUCCESS: 'Permission added successfully',
	CREATE_ERROR: 'Error adding tracker',
	EDIT_SUCCESS: 'Permission updated successfully',
	EDIT_ERROR: 'Error updating permission',
	ERROR_ADD_EMPTY: 'Fill in all fields',
	DELETE_SUCCESS: 'Permission deleted successfully',
	DELETE_ERROR: 'Error deleting permission',
	NO_DATA: 'No matching items were found',
	NO_RESULTS: 'No results found',
	NO_PERMISSION_list: "You don't have permission to view the permission list",
	NO_PERMISSION_new: "You don't have permission to add permissions",
	NO_PERMISSION_edit: "You don't have permission to edit permissions",
	NO_PERMISSION_view: "You don't have permission to view permissions",
	NO_PERMISSION_delete: "You don't have permission to delete permissions",
	NO_ADDRESS: 'Address not found',
	NO_PHONE: 'Phone not found',
	NO_EMAIL: 'Email not found',
	NO_BIRTHDAY: 'Date of birth not found',
	LOADING: 'Loading',
	UPLOAD_ERROR: 'Error uploading file',
	PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters long',
	PASSWORDS_MUST_MATCH: 'Passwords must match',
	PASSWORD_MIN_LOWERCASE: 'Password must have at least 1 lowercase letter',
	PASSWORD_MIN_UPPERCASE: 'Password must have at least 1 uppercase letter',
	PASSWORD_MIN_NUMBERS: 'Password must have at least 1 number',
	PASSWORD_MIN_SYMBOLS: 'Password must have at least 1 special character',
	MORE_THAN: 'more than',
	NO_DATA_FOUND: 'No data found',
	DELETE_MODAL_TITLE: 'Delete permission',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this permission?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} permissions?',
	EDIT_INFO: 'Edit permission',
	DELETE_INFO: 'Delete permission',
	DOWNLOAD_INFO: 'Download selected permissions',
	EDIT_MULTIPLE_INFO: 'Edit selected permissions',
	DELETE_MULTIPLE_INFO: 'Delete selected permissions',
	SELECTED_ITENS: '{{n}} selected items',
	DOWNLOAD_ERROR: 'Error downloading permissions',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} selected companies',
	SELECTED_HOSTS: '{{n}} selected hosts',
	PROFILE_REQUIRED: 'The Profile field is required',
	CPASSWORD_REQUIRED: 'The Confirm Password field is required',
	EDIT_MULTIPLE_SUCCESS: 'Permissions successfully updated',
	EDIT_MULTIPLE_ERROR: 'Error updating permissions',
	PROFILE_IMAGE_TO_LARGE: 'Please, choose an image less than 8MB',
	EMAIL_INVALID: 'Invalid email',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',
	CODE_REQUIRED: 'The code field is required',
	HELPER_SEND_TO_MONITORING: 'Send the rule to monitoring',
	SEND_TO_MONITORING_TOOLTIP: 'Adds a new alert to the monitoring screen when the rules are reached',
	BACK: 'Back',
	NOTIFICATION_APP: 'In-app notifications',

	GET_TRACKER_COMMANDS_ERROR: 'Error getting tracker commands',
	GET_GEOFENCES_ERROR: 'Error getting geofences',
	ALL_USERS_OF: 'All users of',
	ALL_TRACKERS_OF: 'All trackers of',
	ALL_FLEETS_OF: 'All vehicles in the fleet',
	TARGETS: 'Targets',
	TRACKER_EVENT: 'Tracker events',
	SELECT_ALL: 'Select all',
	SHOW: 'Show',
	MONITOR: 'Monitor',
};

export default locale;
