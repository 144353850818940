import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { RootState } from 'app/store/index';

type TMapToolbarOpt = {
	content?: ReactNode;
	onClose?: () => void;
};

type TMapToolbar = {
	open: boolean;
	options: TMapToolbarOpt;
};

const initialState: TMapToolbar = {
	open: false,
	options: {
		content: null,
		onClose: null
	}
};

const mapToolbarSlice = createSlice({
	name: 'mapToolbar',
	initialState,
	reducers: {
		openMapToolbar: (state, action: PayloadAction<TMapToolbarOpt>) => {
			state.open = true;
			state.options = action.payload;
		},
		closeMapToolbar: (state) => {
			state.open = false;
			state.options = initialState.options;
		},
		setInitialState: () => initialState
	}
});

export const { closeMapToolbar, openMapToolbar, setInitialState } = mapToolbarSlice.actions;

export const selectMapToolbarOpen = (state: RootState) => state.mapToolbar.open;
export const selectMapToolbarOptions = (state: RootState) => state.mapToolbar.options;

export default mapToolbarSlice.reducer;
