export default {
	TITLE: 'Horarios permitidos para acceso',
	NAME: 'Nombre',
	DESCRIPTION: 'Descripción',
	COMPANY: 'Empresa',
	CREATED_AT: 'Creado en',
	UPDATED_AT: 'Actualizado en',

	ADD_MORE: 'Agregar otro período',

	USERS: 'Usuarios',
	COMPANIES: 'Empresas',
	ACCESS_PERIOD: 'Horario de acceso',
	VINCULATE: 'Vincular',

	VINCULATE_MODAL_DESCRIPTION:
		'Estás a punto de vincular este horario de acceso para {{selectedUsers}} usuarios y {{selectedCompanies}} empresas.',

	COMPANIES_LIMIT: '{{limit}} Empresas seleccionadas',
	USERS_LIMIT: '{{limit}} Usuarios seleccionados',

	COMPANIES_OR_USERS_REQUIRED: 'Selecciona al menos una empresa o un usuario',
	ACCESS_PERIOD_REQUIRED: 'Selecciona un horario de acceso',

	VINCULATE_ERROR: 'Error al vincular usuarios y empresas',
	VINCULATING_USERS: 'Vinculando usuarios seleccionados',
	VINCULATING_USERS_IN_COMPANIES: 'Vinculando usuarios en las empresas elegidas',
	VINCULATE_SUCCESS: 'Usuarios y empresas vinculados exitosamente',

	COMPANIES_INFO: 'Al actualizar los periodos de acceso de una empresa, su usuario no será cambiado.'
};
