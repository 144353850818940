import HookedDatePicker from 'app/shared-components/Hooked/HookedDatePicker';
import { useGetDateFilterValue } from 'src/utils/DateInputs/helper';
import { TDateInputProps } from 'src/utils/DateInputs/types';

const slotProps = {
	textField: {
		InputProps: {
			size: 'small',
			sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
		},
		InputLabelProps: {
			size: 'small',
			sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
		}
	} as const
};

export const StartFilter: React.FC<TDateInputProps> = ({
	form,
	t,
	endDate,
	name,
	noHelperText,
	initialValue,
	defaultValues = false // if defaultValues is true, the form will not be updated with the initial date
}) => {
	useGetDateFilterValue({ name, initialValue, form, defaultValues });

	return (
		<HookedDatePicker
			control={form.control}
			t={t}
			name={name}
			label={t('START_DATE').toString()}
			sx={{ maxWidth: 250 }}
			className="w-full sm:w-auto"
			noHelperText={noHelperText}
			{...(noHelperText && slotProps)}
		/>
	);
};
