import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Box, Stack, alpha } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TLesson } from '../types/types';

interface LessonsDraggerProps {
	selectedLessons: TLesson[];
	setValue: (name: string, value: any) => void;
}

const LessonsDragger: React.FC<LessonsDraggerProps> = ({ selectedLessons, setValue }) => {
	const handleDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = Array.from(selectedLessons);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		setValue('lessons', items);
	};

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId="lessons">
				{(provided, snapshot) => (
					<Stack
						direction="column"
						spacing={3}
						paddingX={3}
						ref={provided.innerRef}
						{...provided.droppableProps}
						sx={{
							border: '2px',
							borderColor:
								_.size(selectedLessons) > 0
									? (theme) => alpha(theme.palette.secondary.main, 0.13)
									: 'transparent',
							borderRadius: 4,
							borderStyle: 'solid',
							padding: 2,
							backgroundColor: snapshot.isDraggingOver
								? (theme) => alpha(theme.palette.secondary.main, 0.13)
								: 'transparent'
						}}
					>
						<Box>
							{selectedLessons?.map((lesson, index) => (
								<Draggable key={lesson._id} draggableId={lesson._id} index={index}>
									{(provided, snapshot) => (
										<Box
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											marginBottom={2}
											padding={1}
											borderRadius={2}
											sx={{
												...provided.draggableProps.style,
												backgroundColor: snapshot.isDragging
													? (theme) => alpha(theme.palette.secondary.main, 0.5)
													: (theme) => alpha(theme.palette.secondary.main, 1),
												boxShadow: snapshot.isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none'
											}}
										>
											<Stack direction="row" spacing={1}>
												<FuseSvgIcon size={18} color="inherit">
													{`material-outline:drag_handle`}
												</FuseSvgIcon>
												<div>{lesson.title}</div>
											</Stack>
										</Box>
									)}
								</Draggable>
							))}
						</Box>
						{provided.placeholder}
					</Stack>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default LessonsDragger;
