const locale = {
	YEAR_INVALID: 'Ano inválido',
	SELECTED_FLEETS: '{{n}} frotas selecionadas',
	SELECTED_TRACKERS: '{{n}} rastreadores selecionados',
	FLEETS: 'Frotas',
	TYPE: 'Tipo',
	TYPE_REQUIRED: 'O campo tipo é obrigatório',
	PLATE: 'Placa',
	PLATE_REQUIRED: 'O campo placa é obrigatório',
	COLOR: 'Cor',
	COLOR_REQUIRED: 'O campo cor é obrigatório',
	BRAND: 'Marca',
	BRAND_REQUIRED: 'O campo marca é obrigatório',
	YEAR: 'Ano',
	YEAR_REQUIRED: 'O campo ano é obrigatório',
	MODEL: 'Modelo',
	MODEL_REQUIRED: 'O campo modelo é obrigatório',
	VENDOR: 'Fornecedor',
	VENDOR_REQUIRED: 'O campo fornecedor é obrigatório',
	TRACKERS: 'Rastreadores',
	TRACKER: 'Rastreadores',
	TRACKERS_REQUIRED: 'O campo rastreadores é obrigatório',
	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	DID_MUST_BE_NUMBER: 'O campo DID deve ser um número',
	TRACKER_TYPE_REQUIRED: 'O campo tipo de rastreador é obrigatório',
	DID_REQUIRED: 'O campo DID é obrigatório',
	NOTES: 'Notas',
	VEHICLE: 'Rastreado',
	TRACKER_TYPE: 'Tipo de rastreador',
	TITLE: 'Veículos',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfis',
	PROFILE: 'Perfil',
	USERS: 'Usuários',
	USER: 'Usuário',
	BIRTHDAY: 'Data de nascimento',
	PASSWORD: 'Senha',
	CPASSWORD: 'Confirmar senha',
	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts acessíveis',
	ADDRESS: 'Endereço',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Telefone',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	ROWS_PER_PAGE: 'Itens por página',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	PHONE_REQUIRED: 'O campo telefone é obrigatório',
	EMAIL_REQUIRED: 'O campo email é obrigatório',
	ADDRESS_REQUIRED: 'O campo endereço é obrigatório',
	COLUMN_NAME: 'Nome',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Data de nascimento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Criado por',
	COLUMN_CREATED_AT: 'Criado em',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Endereço',
	COLUMN_PHONE: 'Telefone',
	NAME: 'Nome',
	CODE: 'Código',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar veículo',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando veículo',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar este veículo?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar os veículos selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar os veículos, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_USER: 'Adicionar veículo',
	CREATE_SUCCESS: 'Veículo adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar veículo',
	EDIT_SUCCESS: 'Veículo atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar veículo',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Veículo deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar veículo',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de veículos',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar veículos',
	NO_PERMISSION_edit: 'Você não possui permissão para editar veículos',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar veículos',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar veículos',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'Email não encontrado',
	NO_BIRTHDAY: 'Data de nascimento não encontrada',
	LOADING: 'Carregando',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
	PASSWORD_MIN_LENGTH: 'A senha deve ter no mínimo 8 caracteres',
	PASSWORDS_MUST_MATCH: 'As senhas devem ser iguais',
	PASSWORD_MIN_LOWERCASE: 'A senha deve ter no mínimo 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'A senha deve ter no mínimo 1 letra maiúscula',
	PASSWORD_MIN_NUMBERS: 'A senha deve ter no mínimo 1 número',
	PASSWORD_MIN_SYMBOLS: 'A senha deve ter no mínimo 1 caractére especial',
	MORE_THAN: 'mais que',
	NO_USER_FOUND: 'Nenhum veículo foi encontrado',
	DELETE_MODAL_TITLE: 'Deletar veículo',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este veículo?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} veículos?',
	EDIT_INFO: 'Editar veículo',
	DELETE_INFO: 'Deletar veículo',
	DOWNLOAD_INFO: 'Baixar veículos selecionados',
	EDIT_MULTIPLE_INFO: 'Editar veículos selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar veículos selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar veículos',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar Senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS: 'Veículos editados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar veículos',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem meno que 8MB',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	PLATE_INFO: 'Dependendo do tipo de veículo, a placa pode não ser obrigatória.',

	MORE_INFO: 'Ver mais informações',

	// Header

	DOWNLOAD_ALL: 'Baixar todos',

	// Sidebar

	WARNING_CHANGE_COMPANY_TITLE: 'Alterando Empresa',
	WANING_CHANGED_COMPANY: 'Os rastreadores atrelados a este veículo também serão movidos para a empresa',

	CARRY_LOAD_MUST_MATCH_ONE_OF_ITS_VEHICLE_TYPE: 'O porte do veículo deve condizer com seu tipo',
	CARRY_LOAD: 'Porte',
	CARRY_LOAD_LIGHT: 'Leve',
	CARRY_LOAD_MEDIUM: 'Médio',
	CARRY_LOAD_HEAVY: 'Pesado',

	SELECT_COMPANY_FIRST: 'Selecione uma empresa primeiro',

	CHASSIS: 'Chassi',
	RENAVAM: 'Renavam',
	RENAVAM_MUST_BE_NUMBERS: 'Apenas números',
	FUELLING: 'Abastecimento',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar Tabela',
	FILTER: 'Filtrar',
	CLEAR_FILTERS: 'Limpar filtros'
};

export default locale;
