export enum TrackerCommandStatus {
	Scheduling = 'SCHEDULING',
	Pending = 'PENDING',
	Queued = 'QUEUED',
	ReQueued = 'RE-QUEUED',
	Sent = 'SENT',
	Confirmed = 'CONFIRMED',
	Canceled = 'CANCELED',
	Error = 'ERROR',
	Expired = 'EXPIRED'
}
