import { Autorenew } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

const LoadingAssociate = () => {
	const { t } = useTranslation('integrationsPage');
	return (
		<Stack
			sx={{
				width: '100%'
			}}
			direction="column"
			spacing={1}
			alignItems="center"
			justifyContent="center"
			padding={2}
		>
			<Stack direction="row" spacing={1} alignItems="center">
				<Typography
					sx={{
						fontSize: ['18px', '24px'],
						fontWeight: 600,
						color: (theme) => theme.palette.secondary.main
					}}
				>
					{t('FINDING_ASSOCIATE')}
				</Typography>
				<Autorenew className="animate-spin" color="secondary" fontSize="large" />
			</Stack>
		</Stack>
	);
};

export default LoadingAssociate;
