const locale = {
  TITLE: "Eventos de Monitoramento",
  ADD: "Adicionar",
  YES: "Sim",
  NO: "Não",
  SEARCH: "Pesquisar",
  CLEAR_SEARCH: "Limpar filtros",
  EDIT: "Editar",
  VIEW: "Visualizar",
  SAVE: "Salvar",
  DELETE: "Deletar",
  CANCEL: "Cancelar",
  CONFIRM: "Confirmar",
  DATE: "Data",
  OF: "de",

  SELECTED_ITEMS: "Itens selecionados",
  NO_ITEM_SELECTED: "Nenhum item selecionado",
  EXPORT_SELECTED: "Exportar itens selecionados",
  DELETE_SELECTED: "Excluir itens selecionados",
  EDIT_SELECTED: "Editar itens selecionados",
  ROWS_PER_PAGE: "Itens por página",

  NAME_REQUIRED: "O campo nome é obrigatório",
  PARSERS_REQUIRED: "O campo parsers é obrigatório",
  EVENT_CODE_REQUIRED: "O campo código do evento é obrigatório",

  NO_EVENT_FOUND: "Nenhum evento encontrado",

  NAME: "Nome",
  UPDATED_AT: "Atualizado em",
  CREATED_AT: "Criado em",
  CREATED_BY: "Criado por",
  PARSERS: "Parsers",
  PARSER: "Parser",
  ADD_EVENT: "Adicionar evento",
  EVENT_CODE: "Código do evento",
  ICON: "Ícone",
  N_PARSERS: "{{n}} Parsers",
  DESCRIPTION: "Descrição",

  CREATE_SUCCESS: "Evento adicionado com sucesso",
  CREATE_ERROR: "Erro ao adicionar evento",
  EDIT_SUCCESS: "Evento atualizado com sucesso",
  EDIT_ERROR: "Erro ao atualizar evento",
  ERROR_ADD_EMPTY: "Preencha todos os campos",
  DELETE_SUCCESS: "Evento deletado com sucesso",
  DELETE_ERROR: "Erro ao deletar evento",
  MORE_THAN: "mais que",

  DELETE_MODAL_TITLE: "Deletar evento",
  DELETE_MODAL_DESCRIPTION: "Você tem certeza que deseja deletar este evento?",
  DELETE_MODAL_WARNING: "Esta ação não poderá ser desfeita.",
  DELETE_MODAL_MULTIPLE_DESCRIPTION: "Você tem certeza que deseja deletar {{n}} eventos?",

  EDIT_INFO: 'Editar evento',
  DELETE_INFO: 'Deletar evento',
  DOWNLOAD_INFO: 'Baixar eventos selecionados',
  EDIT_MULTIPLE_INFO: 'Editar eventos selecionados',
  DELETE_MULTIPLE_INFO: 'Deletar eventos selecionados',

  SELECTED_ITENS: '{{n}} itens selecionados',
  DOWNLOAD_ERROR: 'Erro ao baixar eventos',

  EDIT_MULTIPLE_SUCCESS: "Eventos editados com sucesso",
  EDIT_MULTIPLE_ERROR: "Error ao editar eventos",

  PAGE_NOT_ALLOWED: "Você não tem permissão para acessar esta página.",
  MINIMAL_START: "Solicite ao Administrador que adicione ao seu perfil as permissões:",
  MINIMAL_END: "Depois saia e entre do sistema.",
};

export default locale;
