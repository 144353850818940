import { Skeleton } from '@mui/material';
import _ from 'lodash';

export default function NotificationPannelSkeleton() {
	const content = _.times(10, (i) => (
		<Skeleton className="mb-16" variant="rounded" height={101} key={`notification-skeleton-${i}`} />
	));

	return <div className="p-16">{content}</div>;
}
