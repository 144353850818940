import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface IAppButton extends ButtonProps {
	loading?: boolean;
}

export default function AppButton({ loading = false, children, sx, ...props }: IAppButton) {
	return (
		<Button
			{...props}
			sx={{
				borderRadius: '8px',
				fontSize: ['12px', '16px'],
				fontWeight: 600,
				...(props.variant === 'outlined' && {
					border: '1px solid',
					':hover': {
						border: '1px solid'
					}
				}),
				...sx
			}}
		>
			{loading ? <CircularProgress color="secondary" size={20} /> : children}
		</Button>
	);
}
