import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TPermission, TSidebarMode } from 'app/main/permissions/types';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TPermission[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
};
const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view'
};

const crudSlice = createSlice({
	name: 'permissionsCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TPermission[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSelectedRows, setIsSidebarOpen, setSidebarMode, resetSidebarState } = crudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.permissionsCrud.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.permissionsCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.permissionsCrud.selectedRows as TPermission[];

export default crudSlice.reducer;
