const locale = {
	VIEW_ALL: 'View all',
	TITLE: 'Fleets',
	VEHICLE: 'Vehicle',
	VEHICLES: 'Vehicles',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-GB',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	COMPANY_REQUIRED: 'The company field is required',
	NAME_REQUIRED: 'The name field is required',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete fleet',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Editing fleet',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this fleet?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected fleets?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	ERROR_PERMISSION: 'You do not have the necessary permissions. Please contact the system administrator.',
	ERROR_GETTING_USERS: 'An error occurred while loading the fleets. Please try again later.',
	ERROR_RESPONSE_404: 'Error: Data not found. Please contact the system administrator.',
	ERROR_INTERNAL_SERVER_ERROR: 'Internal server error. Please try again later.',
	ERROR_NETWORK: 'Network error. Please check your connection and try again.',
	ADD_FLEET: 'Add fleets',
	CREATE_SUCCESS: 'Fleet added successfully',
	CREATE_ERROR: 'Error adding fleet',
	EDIT_SUCCESS: 'Fleet updated successfully',
	EDIT_ERROR: 'Error updating fleet',
	ERROR_ADD_EMPTY: 'Fill in all fields',
	DELETE_SUCCESS: 'Fleet deleted successfully',
	DELETE_ERROR: 'Error deleting fleet',
	NO_DATA: 'No matching items found',
	NO_RESULTS: 'No results found',
	NO_PERMISSION_list: 'You do not have permission to view the list of fleets',
	NO_PERMISSION_new: 'You do not have permission to add fleets',
	NO_PERMISSION_edit: 'You do not have permission to edit fleets',
	NO_PERMISSION_view: 'You do not have permission to view fleets',
	NO_PERMISSION_delete: 'You do not have permission to delete fleets',
	LOADING: 'Loading',
	MORE_THAN: 'more than',
	NO_FLEET_FOUND: 'No fleet found',
	DELETE_MODAL_TITLE: 'Delete fleet',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this fleet?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} fleets?',
	EDIT_INFO: 'Edit fleet',
	DELETE_INFO: 'Delete fleet',
	DOWNLOAD_INFO: 'Download selected fleets',
	EDIT_MULTIPLE_INFO: 'Edit selected fleets',
	DELETE_MULTIPLE_INFO: 'Delete selected fleets',
	SELECTED_ITENS: '{{n}} selected items',
	DOWNLOAD_ERROR: 'Error downloading fleets',
	EDIT_MULTIPLE_SUCCESS: 'Fleets edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing fleets',

	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',

	// Header

	DOWNLOAD_ALL: 'Download all',
	SHOW_FILTERS: 'Show filters',
	CONFIG_TABLE: 'Configure table',
	CLEAR_FILTERS: 'Clear filters',

	VEHICLES_SELECTED: '{{n}} vehicles selected'
};

export default locale;
