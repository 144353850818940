import { Tooltip, Typography } from '@mui/material';
import _ from 'lodash';

type TTableArrayCell<T> = {
	data: T[];
	displayProp: string;
	columnDisplayName: string;
};

export default function TableArrayCell<T>({ data, displayProp, columnDisplayName }: TTableArrayCell<T>) {
	if (!data) {
		return undefined;
	}
	if (typeof data === 'object' && data.length === 0) {
		return undefined;
	}
	if (data.length === 1) {
		return <Typography data-tag="allowRowEvents">{_.get(data, `[0].${displayProp}`, '')}</Typography>;
	}

	const len = data.length;
	const joined = data.map((o) => o[displayProp]).join(', ');

	return (
		<Tooltip title={joined}>
			<Typography data-tag="allowRowEvents">{`${len} ${columnDisplayName}`}</Typography>
		</Tooltip>
	);
}
