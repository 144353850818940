import { lazy } from 'react';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';

const DashboardBuilder = lazy(() => import('./DashboardBuilder'));

export const DASHBOARD_PERMISSIONS = {
	edit: 'admin.dashboard.edit',
	view: 'admin.dashboard.view',
	list: 'admin.dashboard.list',
	new: 'admin.dashboard.new',
	delete: 'admin.dashboard.delete',
	minimal: []
};

const DashboardConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['dashboard.list'],
			path: '/dashboard',
			element: (
				<LazyLoaderSuspense>
					<DashboardBuilder />
				</LazyLoaderSuspense>
			)
		},
		{
			path: '/dashboard/:id/*',
			element: (
				<LazyLoaderSuspense>
					<DashboardBuilder />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default DashboardConfig;
