import { SearchOutlined } from '@mui/icons-material';
import {
	Checkbox,
	LinearProgress,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	TextField
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useDebounce } from 'app/services/hooks';
import { useGetGeoFencesQuery } from 'app/store/api/geoFenceSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectGeoFenceFilter, setGeoFenceFilter } from 'app/store/map/actionBarSlice';

const emptyArr = [];

const getFilteredList = (data: [], searchValue: string) => {
	if (!data.length) return [];
	const result = matchSorter(data, searchValue, { keys: ['name', 'description'] });
	return result;
};

function getSelectedOptions(newValue: string, current: string[]) {
	const currentIndex = current.indexOf(newValue);
	const newChecked = [...current];

	if (currentIndex === -1) {
		newChecked.push(newValue);
	} else {
		newChecked.splice(currentIndex, 1);
	}
	return newChecked;
}

export default function GeoFenceMenu() {
	const dispatch = useDispatch();
	const { t } = useTranslation('mapPage');
	const { fences, isLoading, isError } = useGetGeoFencesQuery('?limit=0', {
		selectFromResult: (res) => ({
			fences: res?.data?.docs ?? emptyArr,
			...res
		})
	});
	const [searchValue, setSearchValue] = useState('');
	const [filteredList, setFilteredList] = useState([]);
	const debouncedSearch = useDebounce(searchValue, 500);
	const selectedFences = useSelector(selectGeoFenceFilter);

	const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => setSearchValue(ev.target.value);
	const handleClick = (id: string) => {
		const newSelected: string[] = getSelectedOptions(id, selectedFences);
		dispatch(setGeoFenceFilter(newSelected));
	};

	if (isError) {
		dispatch(
			showMessage({
				message: t('GEOFENCE_LOAD_ERROR'),
				variant: 'error'
			})
		);
	}

	useEffect(() => {
		if (!debouncedSearch) {
			setFilteredList(fences);
		} else {
			const result = getFilteredList(fences, debouncedSearch);
			setFilteredList(result);
		}
	}, [debouncedSearch, fences]);

	return (
		<Stack
			spacing={1}
			sx={{
				height: '100%',
				overflowY: 'auto',
				p: 1
			}}
		>
			<TextField
				InputProps={{
					endAdornment: <SearchOutlined color="secondary" />
				}}
				sx={{
					'& .MuiOutlinedInput-root': {
						borderRadius: '28px',
						color: '#000'
					}
				}}
				fullWidth
				variant="outlined"
				placeholder={t('SEARCH')}
				size="small"
				onChange={handleSearchChange}
			/>
			<LinearProgress
				color="secondary"
				sx={{
					display: isLoading ? 'block' : 'none',
					width: '100%'
				}}
			/>
			<List
				sx={{
					alignSelf: 'center',
					width: '100%',
					overflowY: 'auto'
				}}
			>
				{filteredList.map((f) => (
					<ListItem disablePadding key={f._id} divider>
						<ListItemButton role={undefined} dense onClick={() => handleClick(f._id)}>
							<ListItemIcon>
								<Checkbox
									edge="start"
									checked={selectedFences.includes(f._id)}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': f.name }}
								/>
							</ListItemIcon>
							<ListItemText id={f._id} primary={f.name} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Stack>
	);
}
