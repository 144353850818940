import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const MonitoringSettings = lazyRetry(() => import('./MonitoringSettingsPage'));

i18next.addResourceBundle('en', 'monitoringSettingsPage', en);
i18next.addResourceBundle('pt', 'monitoringSettingsPage', pt);
i18next.addResourceBundle('sp', 'monitoringSettingsPage', sp);

export const MONITORING_SETTINGS_PERMISSIONS = {
	edit: 'monitoring.settings.update',
	view: 'monitoring.settings.view',
	list: 'monitoring.settings.list',
	new: 'monitoring.settings.create',
	delete: 'monitoring.settings.delete',
	minimal: ['fleet.vehicle.list', 'fleet.fleet.list', 'fleet.company.list', 'system.tracker-events.list']
};

const MonitoringSettingsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['monitoring.settings.list'],
			path: 'monitoring/settings',
			element: (
				<LazyLoaderSuspense>
					<MonitoringSettings />
				</LazyLoaderSuspense>
			),
			children: [{ path: ':id' }]
		}
	]
};

export default MonitoringSettingsConfig;
