import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import _ from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';

type TFormAndDatePicker<F> = UseControllerProps<F> &
  DatePickerProps<Dayjs | null> & { t: TFunction; required?: boolean; fullWidth?: boolean; noHelperText?: boolean };

export default function HookedDatePicker<F extends Record<string, any>>({
  control,
  name,
  label,
  t,
  format = 'DD/MM/YYYY',
  required = false,
  disableFuture = false,
  fullWidth = true,
  noHelperText = true,
  ...props
}: TFormAndDatePicker<F>) {
  const {
    field,
    formState: { errors }
  } = useController({ name, control });

  const handleChange = (date: Dayjs | null) => {
    if (date !== null && date.isValid()) {
      field?.onChange(date);
    } else {
      field?.onChange(null);
    }
  };

  const error = _.has(errors, name) ? t(String(_.get(errors, [name, 'message']))) : undefined;

  return (
    <DatePicker
      label={label}
      value={field?.value}
      onChange={handleChange}
      slotProps={{
        textField: {
          required,
          onBlur: field?.onBlur,
          helperText: noHelperText ? null : error,
          error: _.has(errors, name),
          size: 'small',
          fullWidth,
          name,
          InputProps: {
            sx: { backgroundColor: 'background.paper', maxWidth: '250px', textAlign: 'left' }
          },
          sx: { bgcolor: (theme) => theme.palette.background.default, maxWidth: '250px', ml: 0, pl: 0, textAlign: 'left' },
          ...(props.slotProps?.textField || {})
        }
      }}
      {...props}
      format={format}
      disableFuture={disableFuture}
    />
  );
}
