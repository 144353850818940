const locale = {
	MONITORING: 'Monitoring',
	OPENED_OCCURRENCES_N: 'opened alarms: {{n}}',
	ONGOING_OCCURRENCES_N: 'on going alarms: {{n}}',
	FINALIZE: 'Finalize',
	GO_ON: 'Go On',
	FILTERS: 'Filters',
	CANCEL: 'Cancel',
	APPLY: 'Apply',
	'E.IGN_ON': 'Ignition On',
	'E.IGN_OFF': 'Ignition Off',
	'E.DIN_CHANGED': 'Input Changed',
	'E.EXT_POWER_CUT_OFF': 'Power Off',
	'E.PANIC': 'Panic',
	'E.LOW_BATTERY_VOLTAGE': 'Low Battery',
	'E.SHIFT_WARNING': 'Towed',
	'E.GPS_ANTENNA_OPEN': 'GPS Antenna Open',
	'E.GPS_ANTENNA_SHORT': 'External GPS Off',
	'E.EXT_POWER_RECONNECTED': 'EXT_POWER_RECONNECTED',
	'E.UNDER_SPEED': 'Under Speed',
	'E.OVER_SPEED': 'Over Speed',
	'E.INTO_FENCE': 'Into Fence',
	'E.OUT_FENCE': 'Out Fence',
	'E.EXTERNAL_BATTERY_LOW': 'Battery Low',
	'E.DRIVER_OFF': 'Driver off',
	'E.DRIVER_ON': 'Driver on',
	FINALIZE_OCC: 'Are you sure about this? This operation cant be undone',
	FINALIZE_OCC_TITLE: 'Finalize Alarm',
	OPERATED_BY: 'Operated by:',
	OPEN: 'Open',
	ON_GOING: 'On Going',
	GROUPED_N: '{{n}} grouped',
	FINALIZE_MODAL_TITLE: 'Finalize',
	FINALIZE_MODAL_DESCRIPTION: 'Finalize Alarm',
	FINALIZE_MODAL_MULTIPLE_DESCRIPTION: 'Finalize multiple alarms',
	FINALIZE_MODAL_WARNING: 'This operation cant be undone',
	FINALIZE_MODAL_BRIEF: 'Finalize Alarm',
	CONFIRM: 'Confirm',
	FINISHED_EVENTS: 'Finished Events',
	SUCCESS_ONGOING: 'Alert moved with success',
	ERROR_ONGOING: 'Error moving alert',
	SUCCESS_DONE: 'Alert finalized',
	ERROR_DONE: 'Error finalizing the alert',
	NO_FILTER: 'No filter',
	OPERATOR: 'Operator',
	GO_REALTIME: 'Show on map',
	TRACKER: 'Tracker',
	SEVERITY: 'Severity',
	TIMING: 'Timing',
	LOW_SEVERITY: 'Show low severity',
	MEDIUM_SEVERITY: 'Show medium severity',
	HIGH_SEVERITY: 'Show high severity',
	LAST_HOUR: 'Last hour',
	LAST_N_HOURS: 'Last {{n}} hours',
	CLEAR_ALL: 'Clear filters',
	SELECT_FILTERS: 'Select filters',
	EVENT_TYPE: 'Event',
	GPS_TIME: 'Time',
	ALTITUDE: 'Altitude',
	BEARING: 'Bearing',
	SATELLITES: 'Satellites',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	ADDRESS_CITY: 'City',
	ADDRESS_COUNTRY: 'Country',
	ADDRESS_POSTCODE: 'Postcode',
	ADDRESS_ROAD: 'Road',
	ADDRESS_STATE: 'State',
	ADDRESS_SUBURB: 'Suburb',
	HOURMETER: 'Hourmeter',
	MILEAGE: 'Mileage',
	YES: 'Yes',
	NO: 'No',
	CLEAR: 'Clear',
	DETAILS: 'Details',
	ALARM: 'Alarm',
	VEHICLE: 'Vehicle',
	USER: 'User',
	GROUPS: 'Groups',
	COMPANY: 'Company',
	STATUS: 'Status',
	SPEED: 'Speed',
	GPS_FIXED: 'Gps Fixed',
	IGNITION_ON: 'Ignition On',
	IGNITION_OFF: 'Ignition Off',
	BATTERY: 'Battery',
	EV_TYPE: 'Event Type',
	EV_CODE: 'Event Code',
	SELECTED_N: '{{n}} Selected',
	GROUP_BY: 'Grouped by',
	MAP_VIEW: 'View on Map',
	GOOGLE_VIEW: 'View on Google',
	GO_ON_ERROR: 'Error on update alert status',
	GO_ON_SUCCESS_N: '{{n}} Alert/s updated',
	FINALIZE_SUCCESS_N: '{{n}} Alert/s finalized',
	FINALIZE_ERROR: 'Error on finalize alarm',
	TO_GOOGLE_MAPS: 'View on Google Maps'
};

export default locale;
