import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const PermissionsPage = lazyRetry(() => import('./Permissions'));

i18next.addResourceBundle('en', 'permissionsPage', en);
i18next.addResourceBundle('sp', 'permissionsPage', sp);
i18next.addResourceBundle('pt', 'permissionsPage', pt);

export const PERMISSION_PERMISSIONS = {
	edit: 'admin.permission.edit',
	view: 'admin.permission.view',
	list: 'admin.permission.list',
	new: 'admin.permission.new',
	delete: 'admin.permission.delete',
	minimal: []
};

const PermissionsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.permission.list'],
			path: 'admin/permissions',
			element: (
				<LazyLoaderSuspense>
					<PermissionsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<PermissionsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default PermissionsConfig;
