import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Stack } from '@mui/system';
import { TMonitoringPaginatedQuery } from 'app/services/hooks/usePaginationQueryMonitoring';
import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { TMonitoringGroup } from '../../types';
import GroupFilters from './GroupFilters';

type TTabName = 'filters' | 'groups';
type TMonitoringFilterDialog = {
	groups: TMonitoringGroup[];
	children: ReactNode;
	column: TMonitoringPaginatedQuery['status'];
	onSubmitGroup: (_selectedGroup: TMonitoringGroup) => void;
};
export default function MonitoringFilterDialog({
	groups,
	onSubmitGroup,
	children,
	column = 'toDo'
}: TMonitoringFilterDialog) {
	const dispatch = useDispatch();
	const { t } = useTranslation('monitoringPage');
	const [currentTab, setCurrentTab] = useState<TTabName>('filters');

	const handleCloseModal = () => dispatch(closeDialog());
	const handleChangeTab = (_event: SyntheticEvent, newValue: TTabName) => {
		setCurrentTab(newValue);
	};

	return (
		<DialogWrapper
			title={t('FILTERS')}
			onClose={handleCloseModal}
			content={
				<Stack flex={1}>
					<TabContext value={currentTab}>
						<TabList
							variant="scrollable"
							scrollButtons="auto"
							textColor="secondary"
							indicatorColor="secondary"
							onChange={handleChangeTab}
						>
							<Tab label={t('FILTERS')} sx={{ width: '50%' }} value="filters" />
							<Tab label={t('GROUPS')} sx={{ width: '50%' }} value="groups" />
						</TabList>
						<TabPanel value="filters" sx={{ height: '100%' }}>
							{children}
						</TabPanel>
						<TabPanel value="groups" sx={{ height: '100%' }}>
							<GroupFilters groups={groups} onSubmit={onSubmitGroup} column={column} />
						</TabPanel>
					</TabContext>
				</Stack>
			}
		></DialogWrapper>
	);
}
