const en = {
	SUPPLY_DATE: 'Supply Date',
	BACK: 'Back',
	REGISTER_FUEL_SUPPLY: 'Register Fuel Supply',
	UPDATE_FUEL_SUPPLY: 'Update Fuel Supply',
	ADD_MORE: 'Add More',
	UPDATE: 'Update',
	FUEL_SUPPLY_UPDATED: 'Fuel supply updated successfully',
	FUEL_SUPPLY_SAVED: 'Fuel supply saved successfully',
	ESTABLISHMENT_DELETED_SUCCESSFULLY: 'Establishment deleted successfully',
	ERROR_DELETING_ESTABLISHMENT: 'Error deleting establishment',
	INVOICE_DROP: 'Drop the invoice here',
	MAX_FILES: 'Maximum files reached',
	UPLOAD_ERROR: 'Error uploading file'
};

export default en;
