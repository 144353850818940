const locale = {
  TITLE: "Profiles",
  ADD: "Add",
  SAVE: "Save",
  DELETE: "Delete",
  CANCEL: "Cancel",
  COMPANY: "Company",
  COMPANIES: "Companies",
  OF: "of",
  ROWS_PER_PAGE: "Items per page",
  NAME_REQUIRED: "The name field is required",
  NAME: "Name",
  CREATED_AT: "Created on",
  CREATED_BY: "Created by",
  DELETE_TITLE: "Delete profile",
  ADD_PROFILE: "Add Profile",
  CREATE_SUCCESS: "Profile added successfully",
  CREATE_ERROR: "Error adding profile",
  EDIT_SUCCESS: "Profile updated successfully",
  EDIT_ERROR: "Error updating profile",
  DELETE_SUCCESS: "Successfully deleted profile",
  DELETE_ERROR: "Error deleting profile",
  UPLOAD_ERROR: "Error uploading file",
  NO_PROFILE_FOUND: 'No profile was found',
  DELETE_MODAL_TITLE: "Delete profile",
  DELETE_MODAL_DESCRIPTION: "Are you sure you want to delete this profile?",
  CONFIRM: "Confirm",
  DELETE_MODAL_WARNING: "This action cannot be undone.",
  DELETE_MODAL_MULTIPLE_DESCRIPTION: "Are you sure you want to delete {{n}} profiles?",
  EDIT_INFO: 'Edit profile',
  DOWNLOAD_INFO: 'Download selected profiles',
  EDIT_MULTIPLE_INFO: 'Edit selected profiles',
  DELETE_MULTIPLE_INFO: 'Delete selected profiles',
  SELECTED_ITENS: '{{n}} selected items',
  DOWNLOAD_ERROR: 'Error downloading profiles',
  SELECTED_COMPANIES: '{{n}} Selected companies',
  EDIT_MULTIPLE_SUCCESS: "Profiles successfully edited",
  COMPANY_REQUIRED: 'Company field is mandatory',
  PERMISSIONS_REQUIRED: "The Permissions field is required",
  PERMISSIONS: 'Permissions'
};

export default locale;
