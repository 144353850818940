const locale = {
	TITLE: 'Informe de Operadores',
	YES: 'Sí',
	NO: 'No',
	OPERATOR: 'Operador: {{name}}',
	ALERTS: 'Alertas',

	// Botones
	DOWNLOAD_REPORT: 'Descargar Informe',
	SELECT_FILTERS: 'Seleccionar Filtros',
	SIMPLIFIED_VIEW: 'Vista Simplificada',
	LIST_VIEW: 'Vista de Lista',

	// Filtro
	CLEAR_ALL: 'Borrar Todos',
	USER: 'Usuario',
	SINCE: 'Desde',
	UNTIL: 'Ate',
	GROUP_BY: 'Agrupar por',
	USER_REQUIRED: 'El campo "Usuario" es obligatorio',
	SINCE_REQUIRED: 'El campo "De" es obligatorio',
	GROUP_BY_REQUIRED: 'El campo "Agrupar por" es obligatorio',
	DAY_SHALL_BE_LESS_THAN_31: 'El intervalo máximo para agrupar por día es 31 días',
	WEEK_SHALL_BE_LESS_THAN_12: 'El intervalo máximo para agrupar por semana es 12 semanas',
	MONTH_SHALL_BE_LESS_THAN_12: 'El intervalo máximo para agrupar por mes es de 12 meses',

	// Widgets
	DONE_ALERTS: 'Alertas Resueltas',
	MONTLY_AVERAGE: 'Promedio Mensual',
	TOTAL_TIME: 'Tiempo Total',
	HOURS: 'Horas',
	MAX_TIME: 'Tiempo Máximo',
	AT_DAY: 'En Día',
	VIEW_DETAILS: 'Ver Detalles',
	AVERAGE_TIME_PER_ALERT: 'Tiempo Promedio por Alerta',
	MONTHLY_AVERAGE_TIME: 'Promedio Mensual de Tiempo',
	VEHICLE: 'Vehículo',
	TRACKER: 'Rastreador',
	UPDATED_AT: 'Actualizado en',
	ALERT_EVENT: 'Evento del Alerta',

	// Gráfico
	ALERTS_BY_DAY: 'Alertas por día:',
	ALERTS_BY_WEEK: 'Alertas por semana:',
	ALERTS_BY_MONTH: 'Alertas por mes:',
	DAY: 'Día',
	WEEK: 'Semana',
	MONTH: 'Mes',
	ALERTS_AT_DAY: 'Alertas en el Día',

	// Vista simplificada
	TOTAL_ALARMS: 'Alarmas Totales',
	DONE_AT_DAY: 'Realizados en el día',
	DONE_AT_WEEK: 'Realizados en la semana',
	DONE_AT_MONTH: 'Realizados en el mes',
	ALARMS_AT_DAY: 'Alarmas en el día',
	ALARMS_AT_WEEK: 'Alarmas en la semana',
	ALARMS_AT_MONTH: 'Alarmas en el mes',
	TIME_AT_DAY: 'Tiempo en el día',
	TIME_AT_WEEK: 'Tiempo en la semana',
	TIME_AT_MONTH: 'Tiempo en el mes',
	AVERAGE_PER_ALERT: 'Promedio por Alerta',

	// Vista de lista
	NO_ALERTS_THIS_DAY: 'No hay alertas resueltas en este día',

	// Sin datos
	NO_FILTERS: 'Ningún filtro aplicado',
	NO_FILTERS_SUBTITLE: 'Selecciona un usuario y un período para generar el informe',
	OPERATOR_HAS_NO_REPORT_DATA: 'El operador seleccionado no tiene historial.',

	// Months
	JANUARY: "Enero",
	FEBRUARY: "Febrero",
	MARCH: "Marzo",
	APRIL: "Abril",
	MAY: "Mayo",
	JUNE: "Junio",
	JULY: "Julio",
	AUGUST: "Agosto",
	SEPTEMBER: "Septiembre",
	OCTOBER: "Octubre",
	NOVEMBER: "Noviembre",
	DECEMBER: "Diciembre"
};

export default locale;
