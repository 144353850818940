import { createSlice } from '@reduxjs/toolkit';

interface CourseState {
    currentStep: number;
}

const initialState: CourseState = {
    currentStep: 1
};

const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setCurrentStep(state, action) {
            state.currentStep = action.payload;
        },
        
    }
});

export const { setCurrentStep } = courseSlice.actions;

export const selectCurrentStep = (state: { course: CourseState }) => state.course.currentStep;

export default courseSlice.reducer;
