import { TFunction } from 'react-i18next';

import ColumnName from 'app/shared-components/Table/ColumnName';
import DoubleDataCell from 'app/shared-components/Table/DoubleDataCell';
import dayjs from 'dayjs';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { isColumnOmitted } from '../../users/tableColumns';
import { formatCarPlate } from './helper';
import { TColumnsNames, TTrackerColumn } from './types';

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('DID'), columnId: 'did' },
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('NOTES'), columnId: 'notes' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('DID'), columnId: 'did' },
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('NOTES'), columnId: 'notes' },
	{ name: t('VEHICLE'), columnId: 'vehicle.plate' },
	{ name: t('SIMCARD'), columnId: 'simcard.phone' },
	{ name: t('TRACKER_TYPE'), columnId: 'trackerType.name' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const columnsSearchKeys = ['name', 'did', 'notes', 'createdBy.name'];

export const defaultVisibleColumns = [...columnsSearchKeys];

export const columnSortKeys = ['_id', 'name', 'did', 'createdAt'];

export const getOtherFiltersNames = (t: TFunction) => [{ name: t('SHOW_DELETED'), value: 'deleted' }];
export const avaliableFilters = ['deleted'];

export const getColumns = (t: TFunction, filteredColumns: string[]): TTrackerColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'did',
		name: <ColumnName name={t('DID')} icon="material-outline:dns" />,
		selector: (row) => row.did,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('did', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:memory" />,
		selector: (row) => row?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row?.company?.name,
		sortable: false,
		reorder: false,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		width: '200px',
		id: 'vehicle.plate',
		name: <ColumnName name={t('VEHICLE')} icon="material-outline:directions_car" />,
		cell: (row) => (
			<DoubleDataCell
				title={row?.vehicle?.name}
				subtitle={row?.vehicle?.plate ? formatCarPlate(row.vehicle.plate) : null}
			/>
		),
		sortable: false,
		reorder: false,
		omit: isColumnOmitted('vehicle.plate', filteredColumns)
	},
	{
		minWidth: '300px',
		id: 'trackerType.name',
		name: <ColumnName name={t('TRACKER_TYPE')} icon="material-outline:device_hub" />,
		selector: (row) => row?.trackerType?.name,
		sortable: false,
		reorder: false,
		omit: isColumnOmitted('trackerType.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'simcard.phone',
		name: <ColumnName name={t('SIMCARD')} icon="material-outline:sim_card" />,
		cell: (row) => (
			<DoubleDataCell
				title={row?.simcard?.iccid}
				subtitle={row?.simcard?.phone ? formatPhoneNumberIntl(row.simcard.phone) : null}
			/>
		)
	},
	{
		minWidth: '200px',
		id: 'notes',
		name: <ColumnName name={t('NOTES')} icon="material-outline:notes" />,
		selector: (row) => row?.notes,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('notes', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:calendar_today" />,
		selector: (row) => (row.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY') : ''),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row) => row?.createdBy?.name,
		sortable: false,
		reorder: false,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	}
];
