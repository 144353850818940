import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';

import type { IMonitoringAlertDone } from 'app/main/monitoring-done/types';
import { TMonitoringAlert } from 'app/main/monitoring/types';
import { TMonitoringPaginatedQuery } from 'app/services/hooks/usePaginationQueryMonitoring';
import { initialFilters } from '../monitoring';
import { apiSlice } from './apiSlice';
import type { IApiPagination, TEditMultipleAlertsPayload } from './types';

export const monitoringAlertsAdapter = createEntityAdapter({
	selectId: (data: TMonitoringAlert) => data._id
});

const initialState = monitoringAlertsAdapter.getInitialState();

const insertAt = (arr = [], position = 0, item = {}) => [...arr.slice(0, position), item, ...arr.slice(position)];

export default function getStringQuery(paginatedData: TMonitoringPaginatedQuery, route: string) {
	const since = paginatedData.since ? dayjs().subtract(paginatedData.since, 'hours').toISOString() : null;
	const queryArgs = {
		page: `${paginatedData.page || 1}`,
		limit: `${paginatedData.limit || 500}`,
		search: paginatedData.search || null,
		searchKey: paginatedData.searchKey || null,
		sortKey: paginatedData.sortKey || 'updatedAt',
		sortValue: paginatedData.sortValue || 'desc',
		severity: paginatedData.severity || null,
		since,
		company: paginatedData.company || null,
		vehicle: paginatedData.vehicle || null,
		tracker: paginatedData.tracker || null,
		did: paginatedData.did || null,
		status: paginatedData.status || null,
		event: paginatedData.event || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${route}?${query.toString()}`;
}

const baseUrl = '/monitoring/alerts';
const cacheKey = 'MonitoringAlerts';

export const monitoringAlertsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllDoneAlerts: builder.query<IApiPagination<IMonitoringAlertDone>, TMonitoringPaginatedQuery>({
			query: (paginatedData: TMonitoringPaginatedQuery) => ({
				url: `${getStringQuery(paginatedData, baseUrl)}&done=true`
			}),
			providesTags: [cacheKey]
		}),
		getAlertsRealtime: builder.query<EntityState<TMonitoringAlert>, Partial<TMonitoringPaginatedQuery>>({
			query: (paginatedData: TMonitoringPaginatedQuery) => {
				return getStringQuery(paginatedData, baseUrl);
			},
			transformResponse: (data: IApiPagination<TMonitoringAlert>) =>
				monitoringAlertsAdapter.setAll(initialState, data?.docs),
			providesTags: [cacheKey]
		}),

		finalizeAlert: builder.mutation({
			query: ({ id, ...patch }) => ({
				url: `${baseUrl}/${id}`,
				method: 'PUT',
				body: patch
			}),
			invalidatesTags: [cacheKey]
		}),
		updateMultipleAlerts: builder.mutation<string, TEditMultipleAlertsPayload>({
			query: (data) => ({
				url: `${baseUrl}/list?ids=${data.ids}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),
		updateAlert: builder.mutation({
			query: ({ id, ...patch }) => ({
				url: `${baseUrl}/${id}`,
				method: 'PUT',
				body: patch
			}),

			async onQueryStarted({ id, overId = null, ...patch }, { dispatch, queryFulfilled, getState }) {
				const state = getState();
				const userName = _.get(state, 'user.data.displayName', '');
				const userId = _.get(state, 'user.data.userId', '');

				const toDoFilters: TMonitoringPaginatedQuery = _.get(state, 'monitoring.selectedToDoFilters', {
					...initialFilters,
					status: 'toDo'
				});
				const onGoingFilters: TMonitoringPaginatedQuery = _.get(state, 'monitoring.selectedOnGoingFilters', {
					...initialFilters,
					status: 'onGoing'
				});

				const toDoList =
					monitoringAlertsSlice.endpoints.getAlertsRealtime.select(toDoFilters)(state).data.entities;
				const onGoingList =
					monitoringAlertsSlice.endpoints.getAlertsRealtime.select(onGoingFilters)(state).data.entities;

				// before any situation it's necessary to path(update) the toDo and onGoing columns
				// the update mutation is called with 3 arguments:
				// {
				// 	id: of the item being dragged,
				//	status: the new status of the item being dragged,
				//	overId: the ID of the container where the dragged item was dropped  Ex:  useSortable({ id: 'toDoRef' })
				// }

				const patchToDo = dispatch(
					monitoringAlertsSlice.util.updateQueryData('getAlertsRealtime', toDoFilters, (draft) => {
						if (overId === 'toDoRef') {
							// single item was dropped at the empty toDo column
							const newItem = _.find(onGoingList, { _id: id });
							monitoringAlertsAdapter.addOne(draft, { ...newItem, status: 'toDo', user: null });
						} else if (patch.status === 'onGoing') {
							// item dropped at onGoing column, remove it from toDo
							monitoringAlertsAdapter.removeOne(draft, id);
						} else {
							// an item of the onGoing column was dropped at the toDo column (not empty case)
							const newItem = _.find(onGoingList, { _id: id });
							if (!newItem) return;
							const patchedItem: TMonitoringAlert = {
								...newItem,
								status: 'toDo',
								user: null
							};
							const toDoArr = Object.values(toDoList);
							const overIndex = _.findIndex(toDoArr, { _id: overId });
							if (overIndex) {
								const newArr = insertAt(toDoArr, overIndex, patchedItem);
								monitoringAlertsAdapter.setAll(draft, newArr);
							} else {
								const newArr: TMonitoringAlert[] = [patchedItem, ...toDoArr];
								monitoringAlertsAdapter.setAll(draft, newArr);
							}
						}
					})
				);
				const patchOnGoing = dispatch(
					monitoringAlertsSlice.util.updateQueryData('getAlertsRealtime', onGoingFilters, (draft) => {
						if (overId === 'onGoingRef') {
							// single item was dropped at the empty onGoing column
							const newItem = _.find(toDoList, { _id: id });
							if (!newItem) return;
							const patchedItem: TMonitoringAlert = {
								...newItem,
								status: 'onGoing',
								user: { _id: userId, name: userName }
							};
							monitoringAlertsAdapter.addOne(draft, patchedItem);
						} else if (patch.status === 'toDo') {
							// item dropped at toDo column, remove it from onGoing column
							monitoringAlertsAdapter.removeOne(draft, id);
						} else {
							// an item of the toDo column was dropped at the onGoing column (not empty case)
							const newItem = _.find(toDoList, { _id: id });
							if (!newItem) return;
							const patchedItem: TMonitoringAlert = {
								...newItem,
								status: 'onGoing',
								user: { _id: userId, name: userName }
							};
							const onGoingArr = Object.values(onGoingList);
							const overIndex = _.findIndex(onGoingArr, { _id: overId });
							if (overIndex) {
								const newArr = insertAt(onGoingArr, overIndex, patchedItem);
								monitoringAlertsAdapter.setAll(draft, newArr);
							} else {
								const newArr: TMonitoringAlert[] = [patchedItem, ...onGoingArr];
								monitoringAlertsAdapter.setAll(draft, newArr);
							}
						}
					})
				);

				try {
					await queryFulfilled;
				} catch (err) {
					patchToDo.undo();
					patchOnGoing.undo();

					/**
					 * Alternatively, on failure you can invalidate the corresponding cache tags
					 * to trigger a re-fetch:
					 * dispatch(api.util.invalidateTags(['Post']))
					 */
				}
			}
		})
	})
});

export const {
	useGetAllDoneAlertsQuery,
	useGetAlertsRealtimeQuery,
	useUpdateAlertMutation,
	useFinalizeAlertMutation,
	useUpdateMultipleAlertsMutation
} = monitoringAlertsSlice;

export const {
	selectAll: selectMonitoringAlertsEntities,
	selectTotal: selectTotalMonitoringAlerts,
	selectById: selectMonitoringAlertById
} = monitoringAlertsAdapter.getSelectors();
