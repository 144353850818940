import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TrackerCommand } from '../api/trackerCommandsSlice';

interface IRegisterCommandsState {
	sideBar: boolean;
	currentCommandId: string | null;
	currentCommand: TrackerCommand | null;
	sideBarMode: 'create' | 'edit' | 'view' | null;
	currentParser: string | null;
}

const initialState: IRegisterCommandsState = {
	sideBar: false,
	currentCommandId: null,
	currentCommand: null,
	sideBarMode: null,
	currentParser: null
};

const registerCommandsSlice = createSlice({
	name: 'registerCommands',
	initialState,
	reducers: {
		openRegisterCommandSideBar: (state) => {
			state.sideBar = true;
		},
		closeRegisterCommandSideBar: (state) => {
			state.sideBar = false;
		},
		toggleRegisterCommandSideBar: (state) => {
			state.sideBar = !state.sideBar;
		},
		setSideBarMode: (state, action) => {
			state.sideBarMode = action.payload;
		},
		setCurrentCommandId: (state, action) => {
			state.currentCommandId = action.payload;
		},
		setCurrentCommand: (state, action) => {
			state.currentCommand = action.payload;
		},
		clearRegisterCommandSideBar: (state) => {
			state.sideBar = null;
			state.currentCommandId = null;
			state.currentCommand = null;
			state.sideBarMode = null;
			state.currentParser = null;
		},
		setCurrentParser: (state, action) => {
			state.currentParser = action.payload;
		}
	}
});

export const selectRegisterCommandsSideBar = (state: RootState) => state.registerCommands.sideBar;

export const selectCurrentCommandId = (state: RootState) => state.registerCommands.currentCommandId;

export const selectCurrentCommand = (state: RootState) => state.registerCommands.currentCommand as TrackerCommand;

export const selectSideBarMode = (state: RootState) => state.registerCommands.sideBarMode;

export const selectCurrentParser = (state: RootState) => state.registerCommands.currentParser;

export const {
	openRegisterCommandSideBar,
	clearRegisterCommandSideBar,
	toggleRegisterCommandSideBar,
	setCurrentCommandId,
	setSideBarMode,
	setCurrentCommand,
	closeRegisterCommandSideBar,
	setCurrentParser
} = registerCommandsSlice.actions;

export default registerCommandsSlice.reducer;
