import i18next from 'i18next';

import addTranslation from 'app/shared-components/CRUDs/Header/headerTranslation';
import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import en from './list-page/i18n/en';
import pt from './list-page/i18n/pt';
import sp from './list-page/i18n/sp';

import en2 from './register-page/i18n/en';
import pt2 from './register-page/i18n/pt';
import sp2 from './register-page/i18n/sp';

import { lazyRetry } from 'src/utils/lazyRetry';
import en3 from './user-page/i18n/en';
import pt3 from './user-page/i18n/pt';
import sp3 from './user-page/i18n/sp';

const AccessPeriodsPage = lazyRetry(() => import('./list-page/AccessPeriodsPage'));
const RegisterAccessPeriodsPage = lazyRetry(() => import('./register-page/RegisterAccessPeriodsPage'));
const AccessLimiterPage = lazyRetry(() => import('./user-page/AccessLimiterPage'));

i18next.addResourceBundle('en', 'accessPeriodsPage', en);
i18next.addResourceBundle('sp', 'accessPeriodsPage', sp);
i18next.addResourceBundle('pt', 'accessPeriodsPage', pt);

i18next.addResourceBundle('en', 'registerAccessPeriodsPage', en2);
i18next.addResourceBundle('pt', 'registerAccessPeriodsPage', pt2);
i18next.addResourceBundle('sp', 'registerAccessPeriodsPage', sp2);

i18next.addResourceBundle('en', 'accessLimiterPage', en3);
i18next.addResourceBundle('pt', 'accessLimiterPage', pt3);
i18next.addResourceBundle('sp', 'accessLimiterPage', sp3);
addTranslation();

export const ACCESS_PERIODS_PERMISSIONS = {
	edit: 'admin.access-period.edit',
	view: 'admin.access-period.view',
	list: 'admin.access-period.list',
	new: 'admin.access-period.new',
	delete: 'admin.access-period.delete'
};

const AccessPeriodsConfig = {
	routes: [
		{
			path: 'access-period/user/:userId',
			element: (
				<LazyLoaderSuspense>
					<AccessLimiterPage />
				</LazyLoaderSuspense>
			),
			auth: ['admin.user.access-period']
		},
		{
			auth: [ACCESS_PERIODS_PERMISSIONS.list],
			path: 'admin/access-period',
			element: (
				<LazyLoaderSuspense>
					<AccessPeriodsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<AccessPeriodsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		},
		{
			path: 'admin/access-period/register',
			element: (
				<LazyLoaderSuspense>
					<RegisterAccessPeriodsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<RegisterAccessPeriodsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export { AccessPeriodsConfig };
