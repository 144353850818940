const locale = {
	TITLE: 'Users',
	ADD: 'add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANIES: 'Companies',
	PROFILES: 'Profiles',
	PROFILE: 'Profile',
	USERS: 'Users',
	USER: 'User',
	BIRTHDAY: 'Birth date',
	PASSWORD: 'Password',
	CPASSWORD: 'Confirm password',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-US',
	INACESSIBLE_HOSTS: 'Accessible hosts',
	ADDRESS: 'Address',
	EMAIL: 'Email',
	PHONE: 'Phone',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	NAME_REQUIRED: 'The name field is required',
	CODE_REQUIRED: 'The code field is required',
	ICON_REQUIRED: 'You need to select an icon',
	PHONE_REQUIRED: 'The phone field is required',
	DOC_REQUIRED: 'The CPF/CNPJ field is required',
	EMAIL_REQUIRED: 'The email field is required',
	ADDRESS_REQUIRED: 'The address field is required',
	TYPE_REQUIRED: 'The type of client field is required',
	COLUMN_NAME: 'Name',
	COLUMN_COMPANY: 'Company',
	COLUMN_BIRTHDAY: 'Birth date',
	COLUMN_CREATED_BY: 'Created by',
	COLUMN_CREATED_AT: 'Created at',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Address',
	COLUMN_PHONE: 'Phone',
	NAME: 'Name',
	DESC: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete user',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Edit user',
	EDIT_CONFIRMATION: 'Are you sure you want to edit the user',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected users',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	FIELD_NAME: 'Name',
	FIELD_DESC: 'Description',
	FIELD_CREATED_AT: 'Created at',
	FIELD_CREATED_BY: 'Created by',
	ERROR_PERMISSION: 'You do not have permission to access this page, please contact the system administrator',
	ERROR_GETTING_USERS: 'Error getting users, please contact the system administrator',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Error: Internal server error, please contact the system administrator',
	ERROR_NETWORK: 'Error: Network error, please check your connection and try again',
	ADD_USER: 'Add User',
	CREATE_SUCCESS: 'User successfully added',
	CREATE_ERROR: 'Error adding user',
	EDIT_SUCCESS: 'User successfully updated',
	EDIT_ERROR: 'Error updating user',
	ERROR_ADD_EMPTY: 'Please fill in all fields',
	DELETE_SUCCESS: 'User successfully deleted',
	DELETE_ERROR: 'Error deleting user',
	NO_DATA: 'No matching items were found',
	NO_RESULTS: 'No results found',
	NO_PERMISSION_list: "You don't have permission to view the user list",
	NO_PERMISSION_new: "You don't have permission to add users",
	NO_PERMISSION_edit: "You don't have permission to edit users",
	NO_PERMISSION_view: "You don't have permission to view users",
	NO_PERMISSION_delete: "You don't have permission to delete users",
	NO_ADDRESS: 'Address not found',
	NO_PHONE: 'Phone not found',
	NO_EMAIL: 'Email not found',
	NO_BIRTHDAY: 'Date of birth not found',
	LOADING: 'Loading',
	UPLOAD_ERROR: 'Error uploading file',
	PASSWORD_MIN_LENGTH: 'must be at least 8 characters long',
	PASSWORDS_MUST_MATCH: 'passwords must match',
	PASSWORD_MIN_LOWERCASE: 'must have at least 1 lowercase letter',
	PASSWORD_MIN_UPPERCASE: 'must have at least 1 uppercase letter',
	PASSWORD_MIN_NUMBERS: 'must have at least 1 number',
	PASSWORD_MIN_SYMBOLS: 'must have at least 1 symbol',
	MORE_THAN: 'more than',
	NO_USER_FOUND: 'No user found',
	DELETE_MODAL_TITLE: 'Delete user',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete the user',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} users',
	EDIT_INFO: 'Edit user',
	DELETE_INFO: 'Delete user',
	DOWNLOAD_INFO: 'Download selected users',
	EDIT_MULTIPLE_INFO: 'Edit selected users',
	DELETE_MULTIPLE_INFO: 'Delete selected users',
	DOWNLOAD_ERROR: 'Error downloading file',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Selected companies',
	SELECTED_HOSTS: '{{n}} Selected host',
	PROFILE_REQUIRED: 'The Profile field is required',
	CPASSWORD_REQUIRED: 'The Confirm Password field is required',
	EDIT_MULTIPLE_SUCCESS: 'Users successfully updated',
	EDIT_MULTIPLE_ERROR: 'Error updating users',
	PROFILE_IMAGE_TO_LARGE: 'Please, choose an image less than 8MB',
	COMPANY_REQUIRED: 'The Confirm Company field is required',
	EMAIL_INVALID: 'Invalid email',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',
	EMAIL_ERROR: 'Email already registered',
	PASSWORDS_NOT_MATCH: 'Passwords do not match',
	HOST_MIN_1: 'You must select at least one host',
	HOST_WARNING: 'You must select a Company to show her hosts.',

	/* Content Control */
	VIEW_ALL_FLEETS: 'All Fleets',
	VIEW_NONE_FLEETS: 'No Fleet',
	VIEW_SOME_FLEETS: 'Fleets only:',
	CONTENT_CONTROL_FLEETS: 'Fleet access control',
	SELECTED_FLEETS: '{{n}} selected fleets',
	FLEETS: 'Fleets',
	N_FLEETS: '{{n}} Fleets',

	VIEW_ALL_VEHICLES: 'All Vehicles',
	VIEW_NONE_VEHICLES: 'No Vehicles',
	VIEW_SOME_VEHICLES: 'Vehicles only:',
	CONTENT_CONTROL_VEHICLES: 'Vehicle access control',
	SELECTED_VEHICLES: '{{n}} selected vehicles',
	VEHICLES: 'Vehicles',
	N_VEHICLES: '{{n}} Vehicles',
	ERROR_EMAIL_OR_USERNAME_REQUIRED: 'You must enter an Email or Username',
	EMAIL_OR_USERNAME: 'Email or Username',
	PLATFORM_DATA: 'Platform access data',
	FLEET_WARNING: 'Select a Company to show her Fleets.',
	VEHICLE_WARNING: 'Select a Company to show her Vehicles.',
	CHANGE_PASSWORD: 'Change Password',
	APPLY: 'Apply',
	CHANGE_PASSWORD_SUCCESS: 'Password changed successfully',
	CHANGE_PASSWORD_ERROR: 'Error on password change',

	UNBLOCK_USER_SUCCESS: 'User successfully unblocked',
	UNBLOCK_USER_ERROR: 'Error while unblocking user',
	BLOCK_USER_SUCCESS: 'User successfully blocked',
	BLOCK_USER_ERROR: 'Error while blocking user',
	BLOCK_USER: 'Block',
	UNBLOCK_USER: 'Unblock',
	BLOCK_USER_PRIMARY: 'Are you sure you want to block this user?',
	UNBLOCK_USER_PRIMARY: 'Are you sure you want to unblock this user?',
	IS_BLOCKED: 'Blocked',
	LOGIN_SETTINGS: 'Login settings',

	ACCESSIBLE_COMPANIES: 'Accessible Companies',
	USER_COMPANY: 'User Company',

	ACCESSIBLE_COMPANIES_REQUIRED: 'The Accessible Companies field is required',
	USER_COMPANY_REQUIRED: 'The User Company field is required'
};

export default locale;
