export default {
	TITLE: 'Allowed Access Times',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	COMPANY: 'Company',
	CREATED_AT: 'Created at',
	UPDATED_AT: 'Updated at',

	ADD_MORE: 'Add another period',

	USERS: 'Users',
	COMPANIES: 'Companies',
	ACCESS_PERIOD: 'Access Period',
	VINCULATE: 'Link',

	VINCULATE_MODAL_DESCRIPTION:
		'You are about to link this access period for {{selectedUsers}} users and {{selectedCompanies}} companies.',

	COMPANIES_LIMIT: '{{limit}} Selected companies',
	USERS_LIMIT: '{{limit}} Selected users',

	COMPANIES_OR_USERS_REQUIRED: 'Select at least one company or one user',
	ACCESS_PERIOD_REQUIRED: 'Select an access period',

	VINCULATE_ERROR: 'Error linking users and companies',
	VINCULATING_USERS: 'Linking selected users',
	VINCULATING_USERS_IN_COMPANIES: 'Linking users in the chosen companies',
	VINCULATE_SUCCESS: 'Users and companies linked successfully',

	COMPANIES_INFO: 'When updating the access periods of a company, your user will not be changed.'
};
