import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InitialState {
	totalPages: number;
	currentPage: number;
	loading: boolean;
}

const initialState: InitialState = {
	totalPages: 0,
	currentPage: 0,
	loading: false
};

const downloadSlice = createSlice({
	name: 'download',
	initialState,
	reducers: {
		setLoading(state, { payload }: PayloadAction<boolean>) {
			state.loading = payload;
		},
		setCurrentPage(state, { payload }: PayloadAction<number>) {
			state.currentPage = payload;
		},
		setTotalPages(state, { payload }: PayloadAction<number>) {
			state.totalPages = payload;
		},
		reset(state) {
			state.totalPages = 0;
			state.currentPage = 0;
			state.loading = false;
		}
	}
});

export const selectLoading = (state) => state.download.loading as boolean;
export const selectCurrentPage = (state) => state.download.currentPage as number;
export const selectTotalPages = (state) => state.download.totalPages as number;

export const { setLoading, setCurrentPage, setTotalPages, reset } = downloadSlice.actions;

export default downloadSlice.reducer;
