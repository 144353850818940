import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { FiltersFormValues } from './components/filters/form-schema';
import { FleetSearchParamsFilters, TRequestMessages } from './types';
export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export const REQUEST_MESSAGES: TRequestMessages = {
	create: { success: 'CREATE_SUCCESS', error: 'CREATE_ERROR' },
	edit: { success: 'EDIT_SUCCESS', error: 'EDIT_ERROR' },
	editMultiple: { success: 'EDIT_MULTIPLE_SUCCESS', error: 'EDIT_MULTIPLE_ ERROR' },
	delete: { success: 'DELETE_SUCCESS', error: 'DELETE_ERROR' }
};

export const getExcelColumns = (t: TFunction) => [
	{ label: t('NAME'), value: 'name' },
	{
		label: t('ID'),
		value: (row) => row._id
	},
	{
		label: t('NAME'),
		value: (row) => row.name
	},
	{
		label: t('VEHICLES'),
		value: (row) =>
			Array.isArray(row.vehicle) && row.vehicle.length > 1
				? `${row.vehicle?.length} ${t('VEHICLES')}`
				: row.vehicle?.[0]?.name
	},
	{
		label: t('COMPANY'),
		value: (row) => row.company?.name
	},
	{
		label: t('DESCRIPTION'),
		value: (row) => row.notes
	},
	{
		label: t('CREATED_AT'),
		value: (row) => (row.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY') : '')
	},
	{
		label: t('CREATED_BY'),
		value: (row) => row.createdBy?.name
	}
];
export function parseFormValuesToFilter(formValues: FiltersFormValues): FleetSearchParamsFilters {
	const companyId = _.get(formValues, 'company._id', '');
	const vehicleId = _.get(formValues, 'vehicle._id', '');

	return {
		...formValues,
		company: companyId,
		vehicle: vehicleId
	};
}
