import { TUser } from 'app/main/users/types';
import { IUserMeResponse } from 'app/types/user.type';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';
import { updateLocalUserPreferences } from '../user/userSlice';
import { apiSlice } from './apiSlice';
import {
	IApiPagination,
	IUpdateUserPreferencesArgs,
	TEditMultipleUserPayload,
	TPaginatedQuery,
	TUserPayload
} from './types';

export function getStringQuery(
	paginatedData: TPaginatedQuery & {
		company?: string;
		withDriver?: boolean;
		profile?: string;
		isBlocked?: boolean | null;
	},
	route: string
) {
	const queryArgs = {
		page: `${paginatedData.page || 1}`,
		limit: `${paginatedData.limit || 0}`,
		search: paginatedData.search || null,
		searchKey: paginatedData.searchKey || null,
		sortKey: paginatedData.sortKey || null,
		sortValue: paginatedData.sortValue || null,
		company: paginatedData.company || null,
		profile: paginatedData.profile || null,
		isBlocked: paginatedData.isBlocked || null,
		withDriver: paginatedData.withDriver || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${route}?${query.toString()}`;
}

type TBlockUserPayload = {
	id: string;
	isBlocked: boolean;
};

export const usersSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		blockUser: builder.mutation<TUser, TBlockUserPayload>({
			query: (payload) => ({
				url: `user/${payload.id}`,
				method: 'PUT',
				body: {
					isBlocked: payload.isBlocked
				}
			}),
			invalidatesTags: ['User']
		}),
		getAllUsers: builder.query<
			IApiPagination<TUser>,
			TPaginatedQuery & { profile?: string; company?: string; withDriver?: boolean }
		>({
			query: (
				paginatedData: TPaginatedQuery & {
					company?: string;
					withDriver?: boolean;
					profile?: string;
					isBlocked?: boolean | null;
				}
			) => ({
				url: getStringQuery(paginatedData, '/user')
			}),
			providesTags: ['User']
		}),
		getUser: builder.query<IUserMeResponse, string>({
			query: (id: string) => `/user/me/${id}`,
			providesTags: ['User']
		}),
		getUserById: builder.query<TUser, string>({
			query: (id: string) => `/user/${id}`,
			providesTags: (_, __, id) => [{ type: 'User', id }]
		}),
		updateUser: builder.mutation<string, Partial<TUserPayload>>({
			query: (userData) => ({
				url: `/user/${userData.id}`,
				method: 'PUT',
				body: userData
			}),
			invalidatesTags: ['User']
		}),
		updateLoginSettings: builder.mutation<
			string,
			Pick<TUser, 'accessPeriod' | 'accessPeriodId'> & { userId: string }
		>({
			query: (data) => ({
				url: `/user/${data.userId}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: ['User']
		}),
		updateUserFilter: builder.mutation({
			query: (userData) => ({
				url: `/user/${userData.id}`,
				method: 'PUT',
				body: { realtimeDefaultFilterId: userData.realtimeDefaultFilterId }
			}),
			invalidatesTags: ['User']
		}),
		updateMultipleUsers: builder.mutation<string, TEditMultipleUserPayload>({
			query: (userData) => ({
				url: `/user/list?ids=${userData.ids}`,
				method: 'PUT',
				body: userData
			}),
			invalidatesTags: ['User']
		}),
		createUser: builder.mutation<string, TUserPayload>({
			query: (userData) => ({
				url: '/user',
				method: 'POST',
				body: userData
			}),
			invalidatesTags: ['User']
		}),
		deleteUsers: builder.mutation({
			query: (ids: string) => ({
				url: `/user/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['User']
		}),
		switchAccount: builder.mutation({
			query: (id: string) => ({
				url: `/user/${id}/switch`,
				method: 'GET'
			})
		}),
		updateUserPreferences: builder.mutation<string, IUpdateUserPreferencesArgs>({
			query: ({ id, preferences }) => ({
				url: `/user/${id}`,
				method: 'PUT',
				body: { preferences }
			}),
			onQueryStarted(arg, { dispatch }) {
				dispatch(updateLocalUserPreferences(arg.preferences));
			}
		})
	})
});

export const {
	useGetAllUsersQuery,
	useUpdateUserMutation,
	useDeleteUsersMutation,
	useCreateUserMutation,
	useUpdateMultipleUsersMutation,
	useLazyGetAllUsersQuery,
	useUpdateUserFilterMutation,
	useSwitchAccountMutation,
	useLazyGetUserQuery,
	useUpdateUserPreferencesMutation,
	useBlockUserMutation,
	useGetUserQuery,
	useGetUserByIdQuery,
	useUpdateLoginSettingsMutation
} = usersSlice;
