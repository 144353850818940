import { IconButton, Theme, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

type TMiniButton = {
	selected?: boolean;
	handleClick: () => void;
	children: ReactNode;
	size?: 'small' | 'medium';
};
export default function MiniButton({ children, handleClick, selected = false, size = 'small' }: TMiniButton) {
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const isDarkMode = theme.palette.mode === 'dark';
	const selectedIconColor = isDarkMode ? '#323C4D' : '#fff';

	// this the only working solution to make click/touch properly work on mobile that I found.
	// https://github.com/react-grid-layout/react-draggable/issues/550#issuecomment-815185351
	const dagWrapper = (ev) => {
		if (isMobile && ev.type === 'touchstart') {
			handleClick();
		} else if (!isMobile && ev.type === 'click') {
			handleClick();
		}
	};

	return (
		<IconButton
			color="secondary"
			className="cancel"
			sx={{
				width: size === 'small' ? '28px' : '36px',
				height: size === 'small' ? '28px' : '36px',
				borderRadius: '4px',
				padding: '4px',
				background: selected ? theme.palette.secondary.main : `${theme.palette.secondary.main}1a`,
				color: selected ? selectedIconColor : theme.palette.secondary.main,
				'&:hover': {
					background: theme.palette.secondary.main,
					color: isDarkMode ? '#323C4D' : '#fff'
				}
			}}
			onClick={dagWrapper}
			onTouchStart={dagWrapper}
		>
			{children}
		</IconButton>
	);
}
