import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { changeLanguage, selectCurrentLanguage, selectLanguages } from 'app/store/i18nSlice';
import { useAppDispatch } from 'app/store/index';
import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const en = {
	CHANGE_LANGUAGE: 'Change Language'
};

const pt = {
	CHANGE_LANGUAGE: 'Mudar Idioma'
};

const sp = {
	CHANGE_LANGUAGE: 'Cambiar Idioma'
};

i18next.addResourceBundle('en', 'languageSwitcher', en);
i18next.addResourceBundle('pt', 'languageSwitcher', pt);
i18next.addResourceBundle('sp', 'languageSwitcher', sp);

function LanguageSwitcher(props: any) {
	const currentLanguage = useSelector(selectCurrentLanguage);
	const languages = useSelector(selectLanguages);
	const [menu, setMenu] = useState(null);
	const dispatch = useAppDispatch();
	const { t } = useTranslation('languageSwitcher');
	const langMenuClick = (event: any) => {
		setMenu(event.currentTarget);
	};

	const langMenuClose = () => {
		setMenu(null);
	};

	function handleLanguageChange(lng: any) {
		dispatch(changeLanguage(lng.id));

		langMenuClose();
	}

	return (
		<>
			<Tooltip title={t('CHANGE_LANGUAGE')} placement="bottom">
				<Button className="h-40 w-64" onClick={langMenuClick}>
					<img
						className="mx-4 min-w-20"
						src={`assets/images/flags/${currentLanguage.flag}.svg`}
						alt={currentLanguage.title}
					/>

					<Typography className="mx-4 font-semibold uppercase" color="text.secondary">
						{currentLanguage.id}
					</Typography>
				</Button>
			</Tooltip>
			<Popover
				open={Boolean(menu)}
				anchorEl={menu}
				onClose={langMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{languages.map((lng) => (
					<MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
						<ListItemIcon className="min-w-40">
							<img className="min-w-20" src={`assets/images/flags/${lng.flag}.svg`} alt={lng.title} />
						</ListItemIcon>
						<ListItemText primary={lng.title} />
					</MenuItem>
				))}
			</Popover>
		</>
	);
}

export default LanguageSwitcher;
