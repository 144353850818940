import { Box, DialogContentText, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import getBbox from '@turf/bbox';
import { lineString, point } from '@turf/helpers';
import length from '@turf/length';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef } from 'react-map-gl/maplibre';
import { useDispatch, useSelector } from 'react-redux';

import { Position } from 'app/main/reports/shared/types';
import AppButton from 'app/shared-components/Buttons/AppButton';
import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import { useLazyGetHistoryQuery } from 'app/store/api/trackingSlice';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectMapInstance, setSelectedHistory, setSelectedTrackerId, setTrackersOpen } from 'app/store/map/mapSlice';
import { openMapToolbar } from 'app/store/map/mapToolbarSlice';
import {
	setToolbarData,
	togglePosition,
	toggleRouteByPosition,
	toggleShowRoute,
	toggleTrackers
} from 'app/store/map/routeToolbarSlice';
import RouteToolbar from './RouteToolbar';

interface IHistoryRangeProps {
	trackerId: string;
	vehicleId: string;
	name: string;
}

type TInitialDates = {
	startDate: Dayjs;
	endDate: Dayjs;
};

const endDate = dayjs();
const startDate = endDate.subtract(12, 'hours');

const initialDates: TInitialDates = {
	startDate,
	endDate
};

export default function HistoryDateRange({ vehicleId, trackerId, name }: IHistoryRangeProps) {
	const dispatch = useDispatch();
	const [triggerHist, { isFetching }] = useLazyGetHistoryQuery();
	const [dates, setDates] = useState(initialDates);
	const { t } = useTranslation('mapPage');
	const mapInstance: MapRef = useSelector(selectMapInstance);
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const handleStart = (newValue: Dayjs) => setDates((p) => ({ ...p, startDate: newValue }));

	const handleEnd = (newValue: Dayjs) => setDates((p) => ({ ...p, endDate: newValue }));

	const handleClose = () => dispatch(closeDialog());

	const clearHistory = () => {
		dispatch(setSelectedHistory(null));
		dispatch(togglePosition(false));
		dispatch(toggleTrackers(true));
		dispatch(toggleShowRoute(false));
		dispatch(toggleRouteByPosition(false));
	};

	const handleOk = async () => {
		try {
			const reqProps = {
				vehicleId,
				trackerId,
				endDate: dates.endDate.toISOString(),
				startDate: dates.startDate.toISOString()
			};
			const result = await triggerHist(reqProps).unwrap();
			const mapMatching = _.get(result, 'mapMatching', false);

			if (!mapMatching) {
				dispatch(
					showMessage({
						message: t('NO_HISTORY'),
						variant: 'warning'
					})
				);
			} else {
				const linePath = mapMatching.routes.map((d) =>
					lineString(d.geometry.coordinates, { distance: d.distance, duration: d.duration })
				);

				const pointsArr = [];

				const sorted = [...result.docs].sort((a, b) => {
					const aTime = new Date(a.GPS_TIME).getTime();
					const bTime = new Date(b.GPS_TIME).getTime();
					return aTime - bTime;
				}) as Position[];

				const wayPoints = sorted
					.filter((d) => d.LATITUDE && d.LONGITUDE)
					.map((d, i, arr) => {
						const coord = [d.LONGITUDE, d.LATITUDE];
						pointsArr.push(coord);
						return point(coord, {
							..._.pick(d, [
								'EVENT_TYPE',
								'GPS_TIME',
								'ALTITUDE',
								'SPEED',
								'BEARING',
								'SATELLITES',
								'IGNITION_ON',
								'LATITUDE',
								'LONGITUDE',
								'ADDRESS_CITY',
								'ADDRESS_COUNTRY',
								'ADDRESS_POSTCODE',
								'ADDRESS_ROAD',
								'ADDRESS_STATE',
								'ADDRESS_SUBURB',
								'HOURMETER',
								'MILEAGE'
							]),
							...(i === 0 ? { isFirst: true } : {}),
							...(i === arr.length - 1 ? { isLast: true } : {})
						});
					});
				const lineFromPoints = lineString(pointsArr, { isFromPoints: true });

				const bbox = getBbox(linePath[0]);
				const parsed = {
					type: 'FeatureCollection',
					properties: {
						vehicleId,
						trackerId
					},
					features: [...linePath, ...wayPoints, lineFromPoints]
				};

				// Clear selected tracker to remove popup in map
				dispatch(setSelectedTrackerId(null));

				// Toggle to point-based route
				dispatch(toggleRouteByPosition(true));
				dispatch(toggleShowRoute(false));
				dispatch(togglePosition(true));

				// Set the data to the toolbar
				dispatch(setSelectedHistory(parsed));

				// Remove the trackers from map
				dispatch(toggleTrackers(false));

				const [first, last] = [_.first(sorted), _.last(sorted)];
				const startGpsTime = new Date(first?.GPS_TIME).getTime();
				const endGpsTime = new Date(last?.GPS_TIME).getTime();
				const distance = length(lineFromPoints, { units: 'meters' });
				const routerizedDistance = _.get(mapMatching, 'routes[0].distance', 0);

				dispatch(
					setToolbarData({
						name,
						distanceInMeters: distance,
						routerizedDistanceInMeters: routerizedDistance,
						startDate: new Date(startGpsTime),
						endDate: new Date(endGpsTime)
					})
				);

				dispatch(
					openMapToolbar({
						content: <RouteToolbar />,
						onClose: clearHistory
					})
				);

				if (isMobile) {
					dispatch(setTrackersOpen(false));
				}

				mapInstance.fitBounds(bbox);
				handleClose();
			}
		} catch (err) {
			dispatch(
				showMessage({
					message: t('HISTORY_ERROR'),
					variant: 'error'
				})
			);
		}
	};

	return (
		<DialogWrapper
			title={t('HISTORY')}
			onClose={handleClose}
			content={
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						flex: 1
					}}
				>
					<Stack direction="column" spacing={2}>
						<DialogContentText>{t('SELECT_RANGE')}</DialogContentText>

						<Stack direction="row" spacing={1} alignItems="center">
							<Typography variant="caption">{t('FROM')}</Typography>
							<DateTimePicker
								value={dates.startDate}
								onChange={handleStart}
								format="DD/MM/YYYY - HH:mm:ss"
								disableFuture
							/>
						</Stack>
						<Stack direction="row" spacing={1} alignItems="center">
							<Typography variant="caption">{t('TO')}</Typography>
							<DateTimePicker
								value={dates.endDate}
								onChange={handleEnd}
								format="DD/MM/YYYY - HH:mm:ss"
								disableFuture
							/>
						</Stack>
					</Stack>

					<Box marginTop="auto" width="100%">
						<AppButton
							onClick={handleOk}
							disabled={isFetching}
							loading={isFetching}
							variant="contained"
							color="secondary"
							fullWidth
						>
							{t('APPLY')}
						</AppButton>
					</Box>
				</Box>
			}
		/>
	);
}
