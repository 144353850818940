import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { lighten } from '@mui/material/styles';
import { ViewIcon } from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import DeleteDialog from 'app/shared-components/DeleteModal';
import { setCurrentCourse } from 'app/store/academy/academySlice';
import { useDeleteCoursesMutation } from 'app/store/api/coursesSlice';
import { setSidebarMode } from 'app/store/crud/coursesSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { REQUEST_MESSAGES_COURSE } from '../components/helper';
import CourseInfo from '../course/CourseInfo';
import CourseProgress from '../course/CourseProgress';

function CourseCard({ course, permissions }) {
	const { t } = useTranslation('academyPage');
	const dispatch = useDispatch();
	const [deleteCourse, { isLoading: isDeleting }] = useDeleteCoursesMutation();

	const handleEditSingle = () => {
		dispatch(setSidebarMode('edit'));
		dispatch(setCurrentCourse(course));
	};

	const handleOpenDeleteModal = () => {
		dispatch(
			openDialog({
				children: (
					<DeleteDialog
						t={t}
						targetIds={[course?._id]}
						onClose={handleDeleteModalClose}
						onConfirm={handleDelete}
						loading={isDeleting}
					/>
				)
			})
		);
	};

	const handleDeleteModalClose = () => {
		dispatch(closeDialog());
	};

	const handleDelete = async () => {
		try {
			await deleteCourse(course._id).unwrap();
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_COURSE.delete.success),
					variant: 'success'
				})
			);
			dispatch(closeDialog());
		} catch {
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_COURSE.delete.error),
					variant: 'error'
				})
			);
		}
	};

	function buttonStatus() {
		switch (course.activeStep) {
			// case course.totalSteps:
			//   return 'Completed';
			case 0:
				return t('START');
			default:
				return t('CONTINUE');
		}
	}

	return (
		<Card className="flex flex-col h-384 shadow">
			<CardContent className="flex flex-col flex-auto p-24">
				<CourseInfo course={course} className="" />
			</CardContent>
			<CourseProgress className="" course={course} />
			<CardActions
				className="flex items-center justify-between py-16 px-24"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? lighten(theme.palette.background.default, 0.4)
							: lighten(theme.palette.background.default, 0.03)
				}}
			>
				<div className="flex space-x-5">
					{permissions.hasEdit ? (
						<ViewIcon icon="edit" onClick={() => handleEditSingle()} button />
					) : undefined}
					{permissions.hasDelete ? (
						<ViewIcon icon="delete" onClick={() => handleOpenDeleteModal()} button />
					) : undefined}
				</div>

				<Button
					to={`/academy/courses/${course._id}`}
					component={Link}
					className="px-16 min-w-128"
					color="secondary"
					variant="contained"
					endIcon={
						<FuseSvgIcon className="" size={20}>
							heroicons-solid:arrow-sm-right
						</FuseSvgIcon>
					}
				>
					{buttonStatus()}
				</Button>
			</CardActions>
		</Card>
	);
}

export default CourseCard;
