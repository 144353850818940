import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';

import StatusChip from './components/StatusChip';
import StatusLine from './components/StatusLine';
import { getHistoryCommandLastExecution } from './helper';
import { TColumn, TColumnsNames } from './types';

export const validSortKeys = [
	'scheduledCommand.scheduledCommandName',
	'nextExecution',
	'toStatus',
	'response',
	'rule.name'
];

export const defaultVisibleColumns = [
	'scheduledCommand.scheduledCommandName',
	'scheduledCommand.cronString',
	'nextExecution',
	'toStatus',
	'response',
	'scheduledAt',
	'executedAt'
];

export const getSearchKeys = (t: TFunction) => {
	const columns = [{ columnId: 'response', name: t('RESPONSE') }];

	return columns;
};

// export const getSearchKeysScheduled = (t: TFunction, tableMode?: THistoryType) => {
// 	const columns = [
// 		{ columnId: 'response', name: t('RESPONSE') },
// 		{ columnId: 'scheduledCommand.scheduledCommandName', name: t('SCHEDULED_COMMAND_NAME'), mode: 'scheduled' },
// 	];

// 	if (!tableMode) return columns;

// 	return _.filter(columns, (column) => !column.mode || column.mode === tableMode);
// };

// export const getSearchKeysRule = (t: TFunction, tableMode?: THistoryType) => {
// 	const columns = [
// 		{ columnId: 'response', name: t('RESPONSE') },
// 		{ columnId: 'scheduledCommand.scheduledCommandName', name: t('RULE_NAME'), mode: 'rule' },
// 	];

// 	if (!tableMode) return columns;

// 	return _.filter(columns, (column) => !column.mode || column.mode === tableMode);
// };

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('SCHEDULED_COMMAND_NAME'), columnId: 'scheduledCommand.scheduledCommandName' },
	{ name: t('TYPE'), columnId: 'scheduledCommand.cronString' },
	{ name: t('STATUS'), columnId: 'history.status' },
	{ name: t('RESPONSE'), columnId: 'response' },
	{ name: t('NEXT_EXECUTION'), columnId: 'nextExecution' },
	{ name: t('SCHEDULED_AT'), columnId: 'scheduledAt' },
	{ name: t('EXECUTED_AT'), columnId: 'executedAt' }
];

export const getColumns = (t: TFunction, visibleColumns: string[] /*, tableMode: THistoryType*/): TColumn[] => {
	const isColumnOmitted = (columnId: string, allColumns: string[] /*, mode?: THistoryType*/) => {
		// if (mode && mode != tableMode) return true;
		return allColumns ? !allColumns.includes(columnId) : false;
	};

	return [
		{
			id: '_id',
			minWidth: '256px',
			name: <ColumnName name="Id" icon="material-outline:dns" />,
			selector: (row) => row._id,
			sortable: true,
			omit: isColumnOmitted('_id', visibleColumns)
		},
		// {
		// 	minWidth: '300px',
		// 	id: 'rule.name',
		// 	name: <ColumnName name={t('RULE_NAME')} icon="material-outline:alarm" />,
		// 	selector: (row) => row?.scheduledCommand?.scheduledCommandName,
		// 	omit: isColumnOmitted('rule.name', visibleColumns, 'ruler')
		// },
		{
			minWidth: '300px',
			id: 'scheduledCommand.scheduledCommandName',
			name: <ColumnName name={t('SCHEDULED_COMMAND_NAME')} icon="material-outline:alarm" />,
			selector: (row) => row?.scheduledCommand?.scheduledCommandName,
			sortable: false,
			omit: isColumnOmitted('scheduledCommand.scheduledCommandName', visibleColumns)
		},
		{
			id: 'scheduledCommand.cronString',
			name: <ColumnName name={t('TYPE')} icon="material-outline:alarm" />,
			cell: (row) => (
				<StatusChip t={t} status={row?.scheduledCommand?.cronString === '' ? 'IMMEDIATE' : 'SCHEDULED'} />
			),
			sortable: false,
			omit: isColumnOmitted('scheduledCommand.cronString', visibleColumns)
		},
		{
			minWidth: '400px',
			id: 'tostatus',
			name: <ColumnName name={t('STATUS')} icon="material-outline:check_circle" />,
			cell: (row) => <StatusLine history={row?.history ?? []} />,
			sortable: false,
			omit: isColumnOmitted('history.status', visibleColumns)
		},
		{
			minWidth: '700px',
			id: 'response',
			name: <ColumnName name={t('RESPONSE')} icon="material-outline:notes" />,
			selector: (row) => row?.response,
			sortable: true,
			wrap: true,
			omit: isColumnOmitted('response', visibleColumns)
		},
		{
			minWidth: '400px',
			id: 'nextExecution',
			name: <ColumnName name={t('NEXT_EXECUTION')} icon="material-outline:access_time" />,
			selector: (row) => row.nextExecution && dayjs(row.nextExecution).format('DD/MM/YYYY HH:mm'),
			sortable: true,
			omit: isColumnOmitted('nextExecution', visibleColumns)
		},
		{
			minWidth: '400px',
			id: 'scheduledAt',
			name: <ColumnName name={t('SCHEDULED_AT')} icon="material-outline:access_time" />,
			selector: (row) => row.scheduledAt && dayjs(row.scheduledAt).format('DD/MM/YYYY HH:mm'),
			sortable: true,
			omit: isColumnOmitted('scheduledAt', visibleColumns)
		},
		{
			minWidth: '250px',
			id: 'executedAt',
			name: <ColumnName name={t('EXECUTED_AT')} icon="material-outline:access_time" />,
			selector: (row) => getHistoryCommandLastExecution(row),
			sortable: true,
			omit: isColumnOmitted('executedAt', visibleColumns)
		}
	];
};
