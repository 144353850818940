import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllStepsFormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/general-schema';
import { Step1FormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/step-1/form-schema';
import { Step2FormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/step-2/form-schema';
import { Step3FormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/step-3/form-schema';
import { Step4FormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/step-4/form-schema';
import { Step5FormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/step-5/form-schema';
import { Step6FormData } from 'app/main/ruler/components/Dialog/DialogForm/steps/step-6/form-schema';
import { TRuler, TSidebarMode } from 'app/main/ruler/types';
import _ from 'lodash';
import { RootState } from '..';

const emptyArr = [];

export const ruleForm: AllStepsFormData = {
	_id: '',
	name: '',
	company: null,
	sendToCompanies: emptyArr,
	contacts: emptyArr,
	targetCompanies: emptyArr,
	targetVehicles: emptyArr,
	targetFleets: emptyArr,
	rule: emptyArr,
	aggregate: false,
	sendToMonitoring: false,
	commands: emptyArr,
	period: emptyArr
};

type TInitialState = {
	currentStep: number;
	form: AllStepsFormData;
	selectedRows: TRuler[];
	isSidebarOpen: boolean;
	dialogMode: undefined | 'edit' | 'new' | 'view';
	sidebarMode: TSidebarMode;
	viewRule?: TRuler;
};

const initialState: TInitialState = {
	currentStep: 0,
	form: ruleForm,
	selectedRows: [],
	isSidebarOpen: false,
	dialogMode: undefined,
	sidebarMode: 'view',
	viewRule: undefined
};

const rulerCrudSlice = createSlice({
	name: 'rulerCrud',
	initialState,
	reducers: {
		setCurrentStep: (state, action: PayloadAction<number>) => {
			state.currentStep = action.payload;
		},

		setNameAndCompany: (state, action: PayloadAction<Step1FormData>) => {
			const { name, company } = action.payload;
			state.form.name = name;
			state.form.company = company;
		},

		setSendTo: (state, action: PayloadAction<Step2FormData>) => {
			const { sendToCompanies, contacts } = action.payload;
			state.form.sendToCompanies = sendToCompanies;
			state.form.contacts = contacts;
		},

		setTargets: (state, action: PayloadAction<Step3FormData>) => {
			const { targetCompanies, targetVehicles, targetFleets } = action.payload;
			state.form.targetCompanies = targetCompanies;
			state.form.targetVehicles = targetVehicles;
			state.form.targetFleets = targetFleets;
		},

		setRule: (state, action: PayloadAction<Step4FormData>) => {
			const { rule, aggregate, sendToMonitoring } = action.payload;
			state.form.rule = rule;
			state.form.aggregate = aggregate;
			state.form.sendToMonitoring = sendToMonitoring;
		},

		setCommands: (state, action: PayloadAction<Partial<Step5FormData>>) => {
			const { commands } = action.payload;
			state.form.commands = commands;
		},

		setPeriod: (state, action: PayloadAction<Partial<Step6FormData>>) => {
			const { period } = action.payload;
			state.form.period = period;
		},

		resetForm: (state) => {
			state.form = ruleForm;
			state.currentStep = 0;
		},

		setSelectedRows: (state, action: PayloadAction<TRuler[]>) => {
			state.selectedRows = action.payload;
		},

		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			if (!action.payload) {
				state.selectedRows = [];
				state.sidebarMode = 'view';
				state.isSidebarOpen = false;
			} else {
				state.isSidebarOpen = true;
			}
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},

		setViewRule: (state, action: PayloadAction<TRuler>) => {
			state.viewRule = action.payload;
		},

		setDialogMode: (state, action: PayloadAction<undefined | Partial<AllStepsFormData> | 'new' | 'view'>) => {
			if (_.isString(action.payload) || _.isUndefined(action.payload)) {
				state.dialogMode = action.payload;
			} else if (_.isObject(action.payload)) {
				state.dialogMode = 'edit';
				state.form = _.merge(state.form, action.payload);
				console.log(state.form);
			}
		}
	}
});

export const {
	setNameAndCompany,
	setTargets,
	setSendTo,
	setRule,
	setPeriod,
	setCommands,
	resetForm,
	setSelectedRows,
	setIsSidebarOpen,
	setSidebarMode,
	setViewRule,
	setDialogMode,
	setCurrentStep
} = rulerCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.rulerCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.rulerCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.rulerCrud.selectedRows;
export const selectViewRule = (state: RootState) => state.rulerCrud.viewRule;
export const selectDialogMode = (state: RootState) => state.rulerCrud.dialogMode;
export const selectCurrentStep = (state: RootState) => state.rulerCrud.currentStep;

export const selectRulerFormData = (state: RootState) => ({
	_id: state.rulerCrud?.form?._id,
	name: state.rulerCrud?.form?.name,
	company: state.rulerCrud?.form?.company,
	sendToCompanies: state.rulerCrud?.form?.sendToCompanies,
	contacts: state.rulerCrud?.form?.contacts,
	targetCompanies: state.rulerCrud?.form?.targetCompanies,
	targetVehicles: state.rulerCrud?.form?.targetVehicles,
	targetFleets: state.rulerCrud?.form?.targetFleets,
	rule: state.rulerCrud?.form?.rule,
	aggregate: state.rulerCrud?.form?.aggregate,
	sendToMonitoring: state.rulerCrud?.form?.sendToMonitoring,
	commands: state.rulerCrud?.form?.commands,
	period: state.rulerCrud?.form?.period
});

export default rulerCrudSlice.reducer;
