import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface IHeaderWrapperProps {
	children: ReactNode;
}

export default function HeaderWrapper({ children }: IHeaderWrapperProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: ['flex-start', 'center'],
				flexDirection: ['column', 'row'],
				paddingY: ['18px', '36px'],
				paddingX: ['20px', '40px'],
				width: '100%',
				gap: ['8px', '0px']
			}}
		>
			{children}
		</Box>
	);
}
