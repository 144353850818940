import { Typography } from '@mui/material';

interface DoubleDataCellProps {
	title: string;
	subtitle: string;
}

const DoubleDataCell = ({ title, subtitle }: DoubleDataCellProps) => {
	return (
		<div className="w-[200px] pr-8">
			<Typography className="truncate">{title}</Typography>
			<Typography className="text-10 ">{subtitle}</Typography>
		</div>
	);
};

export default DoubleDataCell;
