import { TRequestMessages } from './types';

export const REQUEST_MESSAGES_COURSE: TRequestMessages = {
	create: { success: 'CREATE_SUCCESS_COURSE', error: 'CREATE_ERROR_COURSE' },
	edit: { success: 'EDIT_SUCCESS_COURSE', error: 'EDIT_ERROR_COURSE' },
	editMultiple: { success: 'EDIT_MULTIPLE_SUCCESS_COURSE', error: 'EDIT_MULTIPLE_ERROR_COURSE' },
	delete: { success: 'DELETE_SUCCESS_COURSE', error: 'DELETE_ERROR_COURSE' }
};

export const REQUEST_MESSAGES_LESSON: TRequestMessages = {
	create: { success: 'CREATE_SUCCESS_LESSON', error: 'CREATE_ERROR_LESSON' },
	edit: { success: 'EDIT_SUCCESS_LESSON', error: 'EDIT_ERROR_LESSON' },
	editMultiple: { success: 'EDIT_MULTIPLE_SUCCESS_LESSON', error: 'EDIT_MULTIPLE_ERROR_LESSON' },
	delete: { success: 'DELETE_SUCCESS_LESSON', error: 'DELETE_ERROR_LESSON' }
};
