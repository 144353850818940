import HookedDateTimePicker from 'app/shared-components/Hooked/HookedDateTimePicker';
import { useGetDateFilterValue } from 'src/utils/DateInputs/helper';
import { TDateInputProps } from 'src/utils/DateInputs/types';

export const StartTimerFilter: React.FC<TDateInputProps> = ({ initialValue, form, t, endDate, name }) => {
	useGetDateFilterValue({ name, initialValue, form });

	return (
		<HookedDateTimePicker
			control={form.control}
			t={t}
			name={name}
			label={t('START_DATE').toString()}
			sx={{ minWidth: 250 }}
			shouldDisableDate={(date) => date.isAfter(endDate)}
			className="w-full sm:w-auto"
		/>
	);
};