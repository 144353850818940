import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSettings, { FuseSettingsProps } from '@fuse/core/FuseSettings';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseThemeSchemes from '@fuse/core/FuseThemeSchemes';
import { Dialog, IconButton, Typography, styled } from '@mui/material';
import { changePreloadFuseTheme } from 'app/store/fuse/settingsSlice';
import React from 'react';

interface Props {
	open: boolean;
	onClose: any;
	themesConfig: any;
	dispatch: any;
	t: any;
	shemesHandlers: any;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-paper': {
		position: 'fixed',
		width: 400,
		maxWidth: '100vw',
		backgroundColor: theme.palette.background.paper,
		top: 0,
		height: '100%',
		minHeight: '100%',
		bottom: 0,
		right: 0,
		margin: 0,
		zIndex: 1000,
		borderRadius: 0,
		transition: theme.transitions.create(['transform', 'opacity', 'width'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.shorter
		})
	}
}));

const CustomThemeDialog: React.FC<Props> = ({ open, onClose, themesConfig, dispatch, t, shemesHandlers }) => {
	const handleChangeTheme = (theme: FuseSettingsProps['theme']) => {
		dispatch(changePreloadFuseTheme(theme));
	};

	return (
		<StyledDialog
			className="relative"
			open={open}
			onClose={onClose}
			aria-labelledby="schemes-panel"
			aria-describedby="schemes"
			classes={{
				paper: 'shadow-lg'
			}}
			{...shemesHandlers}
		>
			<FuseScrollbars className="p-16">
				<IconButton onClick={onClose} className="fixed top-16 ltr:right-16 rtl:left-16 z-10" size="small">
					<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
				</IconButton>

				<Typography variant="h6" className="mb-16">
					{t('THEMES')}
				</Typography>

				<FuseSettings />
				<FuseThemeSchemes themes={themesConfig} onSelect={handleChangeTheme} />
			</FuseScrollbars>
		</StyledDialog>
	);
};

export default CustomThemeDialog;
