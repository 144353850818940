import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store/index';
import dayjs from 'dayjs';
import { TTracker } from 'src/app/main/map/types';
import { formatDistance } from 'src/utils/formatDistance';

export type TToolbarData = {
	name: string;
	tracker?: TTracker;
	distanceInMeters: number;
	routerizedDistanceInMeters?: number;
	endDate: Date;
	startDate: Date;
};

type TRouteToolbar = {
	showPositions: boolean;
	showTrackers: boolean;
	showRouteByPosition: boolean;
	showRoute: boolean;
	data: TToolbarData;
};

const initialState: TRouteToolbar = {
	showPositions: false,
	showTrackers: true,
	showRouteByPosition: false,
	showRoute: false,
	data: {
		distanceInMeters: 0,
		endDate: new Date(),
		startDate: new Date(),
		routerizedDistanceInMeters: 0,
		name: '',
		tracker: null
	}
};

const routeToolbarSlice = createSlice({
	name: 'routeToolbar',
	initialState,
	reducers: {
		togglePosition: (state, action: PayloadAction<boolean>) => {
			state.showPositions = action.payload;
		},
		toggleTrackers: (state, action: PayloadAction<boolean>) => {
			state.showTrackers = action.payload;
		},
		toggleRouteByPosition: (state, action: PayloadAction<boolean>) => {
			state.showRouteByPosition = action.payload;
		},
		toggleShowRoute: (state, action: PayloadAction<boolean>) => {
			state.showRoute = action.payload;
		},
		setToolbarData: (state, action: PayloadAction<TToolbarData>) => {
			state.data = action.payload;
		},
		setInitialState: () => initialState
	}
});

export const {
	togglePosition,
	toggleTrackers,
	toggleRouteByPosition,
	toggleShowRoute,
	setInitialState,
	setToolbarData
} = routeToolbarSlice.actions;

export const selectToolbarData = (state: RootState) => state.routeToolbar.data;
export const selectShowPositions = (state: RootState) => state.routeToolbar.showPositions;
export const selectShowTrackers = (state: RootState) => state.routeToolbar.showTrackers;
export const selectShowRouteByPosition = (state: RootState) => state.routeToolbar.showRouteByPosition;
export const selectShowRoute = (state: RootState) => state.routeToolbar.showRoute;

export const selectParsedToolbarData = createSelector(selectToolbarData, (data: TToolbarData) => {
	const distance = formatDistance(data.distanceInMeters);
	const routerizedDistance = formatDistance(data.routerizedDistanceInMeters);

	return {
		distance,
		routerizedDistance,
		name: data.name,
		tracker: data.tracker,
		startDate: dayjs(data.startDate).format('DD/MM/YYYY HH:mm'),
		endDate: dayjs(data.endDate).format('DD/MM/YYYY HH:mm')
	};
});

export default routeToolbarSlice.reducer;
