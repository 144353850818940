import { CloseOutlined, LaunchOutlined } from '@mui/icons-material';
import { DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHref } from 'react-router-dom';

import { goToStreetView } from 'app/main/reports/historyNew/helper';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import formatBattery from 'src/utils/formatBattery';
import { ALARM_TEXT } from '../../helpers';
import { TMonitoringAlert } from '../../types';

type TAlarmDetailDialog = { data: TMonitoringAlert };
type TItem = { title: string; value: string | number };

const Item = ({ title = '', value = '' }: TItem) => (
	<Stack direction="row" justifyContent="space-between" component="li">
		<Typography sx={{ fontSize: 14 }} gutterBottom>
			{title}
		</Typography>
		<Typography sx={{ fontSize: 14, fontWeight: 600 }}>{value}</Typography>
	</Stack>
);

export default function AlarmDetailDialog({ data }: TAlarmDetailDialog) {
	const dispatch = useDispatch();
	const { t } = useTranslation('monitoringPage');
	const href = useHref('/realtime');

	const handleCloseModal = () => dispatch(closeDialog());

	const handleRealtime = () => {
		const trackerId = _.get(data, 'trackerMessage.tracker._id', null);
		if (!trackerId) return;
		window.open(`${href}?trackerId=${trackerId}`, '_blank');
	};

	const handleGoogle = () => {
		const position = {
			longitude: _.get(data, 'trackerMessage.LONGITUDE', 0),
			latitude: _.get(data, 'trackerMessage.LATITUDE', 0)
		};
		if (!position.latitude || !position.longitude) return;
		goToStreetView(position.latitude, position.longitude);
	};

	return (
		<DialogContent
			sx={{
				width: ['100%', '380px'],
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				overflow: 'hidden'
			}}
		>
			<Stack direction="column" spacing={3} flex="1">
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<DialogTitle
						noWrap
						width={250}
						sx={{
							padding: 0
						}}
					>
						{t(_.get(data, 'trackerMessage.EVENT_TYPE', ''))}
					</DialogTitle>
					<IconButton onClick={handleCloseModal} size="small">
						<CloseOutlined />
					</IconButton>
				</Stack>

				<ul className="list-none">
					<Item title={t('VEHICLE')} value={_.get(data, 'trackerMessage.vehicle.name', '')} />
					<Item title={t('DDI')} value={_.get(data, 'trackerMessage.DID', '')} />
					<Item title={t('COMPANY')} value={_.get(data, 'company.name', '')} />
					<Item title={t('STATUS')} value={t(_.get(data, 'status', '').toUpperCase())} />
					<Item title={t('SEVERITY')} value={t(ALARM_TEXT[_.get(data, 'severity', 1)])} />
					<Item title={t('ALTITUDE')} value={_.get(data, 'trackerMessage.ALTITUDE', '')} />
					<Item title={t('SPEED')} value={_.get(data, 'trackerMessage.SPEED', '')} />
					<Item title={t('BEARING')} value={_.get(data, 'trackerMessage.BEARING', '')} />
					<Item title={t('SATELLITES')} value={_.get(data, 'trackerMessage.SATELLITES', 0)} />
					<Item
						title={t('GPS_FIXED')}
						value={_.get(data, 'trackerMessage.GPS_FIXED', false) ? t('YES') : t('NO')}
					/>
					<Item
						title={t('IGNITION_ON')}
						value={_.get(data, 'trackerMessage.IGNITION_ON', false) ? t('YES') : t('NO')}
					/>
					<Item title={t('LONGITUDE')} value={_.get(data, 'trackerMessage.LONGITUDE', 0)} />
					<Item title={t('LATITUDE')} value={_.get(data, 'trackerMessage.LATITUDE', 0)} />
					<Item
						title={t('BATTERY')}
						value={t(formatBattery(_.get(data, 'trackerMessage.VEHICLE_VOLTAGE', 0)))}
					/>
					<Item title={t('MILEAGE')} value={_.get(data, 'trackerMessage.MILEAGE', 0)} />
					<Item title={t('HOURMETER')} value={_.get(data, 'trackerMessage.HOURMETER', 0)} />
				</ul>
				<AppButton color="secondary" endIcon={<LaunchOutlined />} onClick={handleRealtime} variant="contained">
					{t('MAP_VIEW')}
				</AppButton>
				<AppButton color="secondary" endIcon={<LaunchOutlined />} onClick={handleGoogle} variant="contained">
					{t('TO_GOOGLE_MAPS')}
				</AppButton>
			</Stack>
		</DialogContent>
	);
}
