import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'driver'>, MatchConditions>;

const LIST_DRIVER_PERMISSIONS = ['fleet.driver.list'];

const VIEW_DRIVER_PERMISSIONS = ['fleet.driver.view'];

const CREATE_DRIVER_PERMISSIONS = ['fleet.driver.new', 'admin.company.list', 'admin.user.list'];

const UPDATE_DRIVER_PERMISSIONS = ['fleet.driver.edit', 'admin.company.list', 'admin.user.list'];

const DELETE_DRIVER_PERMISSIONS = ['admin.driver.delete'];

export const defineDriverAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_DRIVER_PERMISSIONS),
		create: getMissingPermissions(CREATE_DRIVER_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_DRIVER_PERMISSIONS),
		delete: getMissingPermissions(DELETE_DRIVER_PERMISSIONS),
		list: getMissingPermissions(LIST_DRIVER_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('driver', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_DRIVER_PERMISSIONS)) can('delete', 'driver');
	if (userHasPermission(CREATE_DRIVER_PERMISSIONS)) can('create', 'driver');
	if (userHasPermission(UPDATE_DRIVER_PERMISSIONS)) can('edit', 'driver');
	if (userHasPermission(VIEW_DRIVER_PERMISSIONS)) can('view', 'driver');
	if (userHasPermission(LIST_DRIVER_PERMISSIONS)) can('list', 'driver');

	return [build(), missingPermissions] as const;
};
