import type { SelectedTrip, Trip } from 'app/main/reports/shared/types';
import { AdvancedTrip } from 'app/main/reports/trips/advanced/types';
import type { Dayjs } from 'dayjs';
import _ from 'lodash';
import type { SortOrder } from 'react-data-table-component/dist/src/DataTable/types';
import { createSearchParams } from 'react-router-dom';
import { apiSlice } from './apiSlice';
import type { IApiPagination, TPaginatedQuery } from './types';
import { TripsReportSearchParams } from 'app/main/reports/trips/list/types';
import getStringQuery from './getStringQuery';

export interface TPaginatedQueryTrips extends TPaginatedQuery {
	did?: string;
	byCompany?: string;
	byFleet?: string;
	byVehicle?: string;
	byDriver?: string;
	beginAt?: Date | Dayjs;
	endAt?: Date | Dayjs;
	withEventAt?: Date | Dayjs;
}
export type AdvancedTripParams = {
	id: string;
	tracker?: string;
	vehicle?: string;
	company?: string;
	startTime?: string;
	endTime?: string;
};

type TDidAndVehicle = {
	did: string;
	byVehicle?: string;
};

export function getTripsAdvancedStringQuery(paginatedData: TPaginatedQueryTrips, route: string) {
	const queryArgs = {
		...paginatedData,
		page: `${paginatedData.page || 1}`,
		limit: `${paginatedData.limit || 100}`,
		search: paginatedData.search || null,
		searchKey: paginatedData.searchKey || null,
		sortKey: paginatedData.sortKey || 'endTime',
		sortValue: paginatedData.sortValue || 'desc',
		byCompany: paginatedData.byCompany || null,
		byFleet: paginatedData.byFleet || null,
		byVehicle: paginatedData.byVehicle || null,
		byDriver: paginatedData.byDriver || null,
		did: paginatedData.did || null,
		beginAt: paginatedData.beginAt || null,
		endAt: paginatedData.endAt || null,
		withEventAt: paginatedData.withEventAt || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${route}?${query.toString()}`;
}

export function getTripsStringQuery(paginatedData: TripsReportSearchParams) {
	const queryArgs = {
		byCompany: paginatedData.byCompany || null,
		byFleet: paginatedData.byFleet || null,
		byVehicle: paginatedData.byVehicle || null,
		byDriver: paginatedData.byDriver || null,
		did: paginatedData.did || null,
		beginAt: paginatedData.beginAt || null,
		endAt: paginatedData.endAt || null,
		withEventAt: paginatedData.withEventAt || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `&${query.toString()}`;
}
const baseUrl = '/trip';
const cacheKey = 'Trips';

export const tripsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllTrips: builder.query<IApiPagination<Trip> & {
			totalDurationInSeconds: number;
			totalDistanceInMeters: number;
		}, TripsReportSearchParams>({
			query: (paginatedData: TripsReportSearchParams) => ({
				url:
					getStringQuery(paginatedData, baseUrl) +
					getTripsStringQuery(paginatedData)
			}),
			providesTags: [cacheKey]
		}),
		getOneTrip: builder.query<SelectedTrip, string>({
			query: (id: string) => ({
				url: `${baseUrl}/${id}`,
				method: 'GET'
			}),
			providesTags: [cacheKey]
		}),
		getTripWithEventAt: builder.mutation<IApiPagination<Trip>, TPaginatedQueryTrips>({
			query: ({ byVehicle, did, withEventAt }: Partial<TPaginatedQueryTrips>) => ({
				url: getTripsAdvancedStringQuery(
					{
						byVehicle,
						did,
						withEventAt,
						limit: 1,
						sortKey: 'createdAt',
						sortValue: 'desc' as SortOrder,
						page: 1
					},
					baseUrl
				),
				method: 'GET'
			})
		}),
		getLastTrip: builder.mutation({
			query: ({ did, byVehicle }: TDidAndVehicle) => ({
				url: getTripsAdvancedStringQuery(
					{
						did,
						byVehicle,
						limit: 1,
						sortKey: 'endTime',
						sortValue: 'desc' as SortOrder,
						page: 1
					},
					baseUrl
				),
				method: 'GET'
			})
		}),

		findOneAdvancedTrip: builder.query<AdvancedTrip, AdvancedTripParams>({
			query: (tripParams: AdvancedTripParams) => ({
				url: `/trip/advanced/${tripParams.id === 'custom' ? 'undefined' : tripParams.id}`,
				method: 'GET',
				params: _.omitBy(_.omit(tripParams, 'id'), _.isNil)
			})
		}),

		getOneTripData: builder.mutation({
			query: ({ vehicle, tracker, beginAt, endAt, tripId }) => ({
				url: `/tracking/history/app/vehicle/${vehicle}/tracker/${tracker}?${
					tripId ? `trip=${tripId}` : `start=${beginAt}&end=${endAt}`
				}`,
				method: 'GET'
			})
		})
	})
});

export const {
	useGetAllTripsQuery,
	useGetTripWithEventAtMutation,
	useGetOneTripQuery,
	useLazyGetOneTripQuery,
	useGetLastTripMutation,
	useGetOneTripDataMutation,
	useFindOneAdvancedTripQuery
} = tripsSlice;


