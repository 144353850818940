import i18next from '../../i18n';
import en from './navigation-i18n/en';
import pt from './navigation-i18n/pt';
import sp from './navigation-i18n/sp';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('pt', 'navigation', pt);
i18next.addResourceBundle('sp', 'navigation', sp);

const navigationConfig = [
	{
		id: 'map',
		title: 'Map',
		translate: 'MAP',
		type: 'item',
		icon: 'heroicons-outline:location-marker',
		url: '/realtime'
	},
	{
		auth: 'geofence.menu',
		id: 'geofence',
		title: 'Geofence',
		translate: 'GEOFENCE',
		type: 'collapse',
		icon: 'heroicons-outline:map',
		subtitle: 'Geofence management',
		subtitleTranslate: 'GEOFENCE_MANAGEMENT',
		children: [
			{
				auth: 'geofence.list',
				id: 'geofence-manage',
				title: 'Geofence Manage',
				translate: 'GEOFENCE_MANAGE',
				type: 'item',
				icon: 'heroicons-outline:map',
				url: 'geo-fence/manage'
			},
			{
				auth: 'reports.geofence-history.menu',
				id: 'geofence-report',
				title: 'Geofence Report',
				translate: 'GEOFENCE_REPORT',
				type: 'item',
				icon: 'material-outline:insert_chart',
				url: 'geo-fence/report'
			}
		]
	},
	{
		auth: 'panel.menu',
		id: 'panel-menu',
		title: 'Panels',
		type: 'item',
		translate: 'PANELS',
		icon: 'heroicons-outline:chart-bar',
		url: '/panels'
	},
	{
		auth: 'fleet.menu',
		id: 'fleet',
		title: 'fleets',
		translate: 'FLEETS',
		subtitle: 'Fleets management',
		subtitleTranslate: 'FLEETS_MANAGEMENT',
		type: 'collapse',
		icon: 'heroicons-outline:truck',
		children: [
			{
				auth: 'fleet.simcard.menu',
				id: 'simcards',
				title: 'Simcards',
				translate: 'SIMCARDS',
				type: 'item',
				icon: 'material-outline:sim_card',
				url: 'fleet/simcards'
			},
			{
				auth: 'fleet.tracker.menu',
				id: 'trackers',
				title: 'Trackers',
				translate: 'TRACKERS',
				type: 'item',
				icon: 'heroicons-outline:wifi',
				url: 'fleet/trackers'
			},
			{
				auth: 'fleet.vehicle.menu',
				id: 'vehicles',
				title: 'Vehicles',
				translate: 'VEHICLES',
				type: 'item',
				icon: 'material-outline:directions_car',
				url: 'fleet/vehicles'
			},
			{
				auth: 'fleet.fleet.menu',
				id: 'fleets',
				title: 'Fleets',
				translate: 'FLEETS',
				type: 'item',
				icon: 'material-outline:commute',
				url: 'fleet/fleets'
			},
			{
				auth: 'fleet.ibutton.menu',
				id: 'ibutton',
				title: 'iButtons',
				translate: 'IBUTTONS',
				type: 'item',
				icon: 'material-outline:nfc',
				url: 'fleet/ibuttons'
			},
			{
				auth: 'fleet.driver.menu',
				id: 'drivers',
				title: 'Drivers',
				translate: 'DRIVERS',
				type: 'item',
				icon: 'heroicons-outline:user',
				url: 'fleet/drivers'
			},
			{
				auth: 'fleet.fuelsupply.menu',
				id: 'fuel-supply',
				title: 'FUEL_SUPPLY',
				translate: 'FUEL_SUPPLY',
				type: 'item',
				icon: 'material-outline:local_gas_station',
				url: 'fleet/fuel-supply'
			}
		]
	},

	{
		id: 'commands',
		title: 'Commands',
		translate: 'COMMANDS',
		type: 'collapse',
		auth: 'command.menu',
		icon: 'material-outline:settings_remote',
		subtitle: 'Commands management',
		subtitleTranslate: 'COMMANDS_MANAGEMENT',
		children: [
			{
				auth: 'command.schedule.menu',
				id: 'schedule-commands',
				title: 'Schedule Commands',
				translate: 'SCHEDULE_COMMANDS',
				type: 'item',
				icon: 'material-outline:alarm',
				url: 'commands/schedule-commands'
			},
			{
				auth: 'command.history.menu',
				id: 'history-commands',
				title: 'History Commands',
				translate: 'HISTORY',
				type: 'item',
				icon: 'material-outline:notes',
				url: 'commands/history-commands'
			}
		]
	},
	{
		auth: 'monitoring.menu',
		id: 'monitoring-menu',
		title: 'Ruler',
		translate: 'MONITORING',
		type: 'collapse',
		icon: 'heroicons-outline:view-boards',
		subtitle: 'Monitoring management',
		subtitleTranslate: 'MONITORING_MANAGEMENT',
		children: [
			{
				auth: 'monitoring.alerts.menu',
				id: 'monitoring',
				title: 'Monitoring',
				translate: 'MONITORING',
				type: 'item',
				icon: 'heroicons-outline:view-boards',
				url: '/monitoring/monitoring'
			},
			{
				auth: 'monitoring.alerts.menu',
				id: 'monitoring-done',
				title: 'Monitoring done',
				translate: 'MONITORING_DONE',
				type: 'item',
				icon: 'material-outline:check_circle',
				url: '/monitoring/done'
			},
			{
				auth: 'system.tracker-events.menu',
				id: 'monitoring_events',
				title: 'Events',
				translate: 'MONITORING_EVENTS',
				type: 'item',
				icon: 'material-outline:all_out',
				url: 'monitoring/events'
			},
			{
				auth: 'monitoring.settings.menu',
				id: 'monitoring-settings',
				title: 'Monitoring settings',
				translate: 'MONITORING_SETTINGS',
				type: 'item',
				icon: 'material-outline:settings',
				url: '/monitoring/settings'
			}
		]
	},
	{
		auth: 'reports.menu',
		id: 'reports',
		icon: 'material-outline:assessment',
		title: 'Reports',
		translate: 'REPORTS',
		type: 'collapse',
		subtitle: 'Reports management',
		subtitleTranslate: 'REPORTS_MANAGEMENT',
		children: [
			{
				auth: 'reports.operator.menu',
				id: 'monitoring-operator',
				title: 'Monitoring Operator Report',
				translate: 'MONITORING_OPERATOR',
				type: 'item',
				icon: 'material-outline:supervised_user_circle',
				url: 'reports/monitoring-operator'
			},
			{
				auth: 'reports.analytic.menu',
				id: 'analytic',
				title: 'Analytic',
				translate: 'ANALYTICS',
				type: 'item',
				icon: 'material-outline:history',
				url: 'reports/analytic'
			},
			{
				auth: 'reports.event.menu',
				id: 'event',
				title: 'Events',
				translate: 'EVENTS',
				type: 'item',
				icon: 'material-outline:all_out',
				url: 'reports/events'
			},
			{
				auth: 'reports.trips.menu',
				id: 'trips-management',
				title: 'Trips Management',
				translate: 'TRIPS',
				type: 'item',
				icon: 'material-outline:map',
				url: 'reports/trips'
			},
			{
				auth: 'reports.tracker-metrics.menu',
				id: 'tracker-metrics',
				title: 'Tracker Metrics',
				translate: 'TRACKER_METRICS',
				type: 'item',
				icon: 'material-outline:device_hub',
				url: 'reports/tracker-metrics'
			},
			{
				auth: 'command.block.menu',
				id: 'block-command-history',
				title: 'Block Command History',
				translate: 'BLOCK_COMMAND_HISTORY',
				type: 'item',
				icon: 'material-outline:lock',
				url: 'reports/block-command-history'
			}
		]
	},
	{
		auth: 'admin.ruler.menu',
		id: 'ruler menu',
		title: 'Ruler',
		translate: 'RULER',
		type: 'collapse',
		icon: 'material-outline:edit_notifications',
		subtitle: 'Ruler management',
		subtitleTranslate: 'RULES_MANAGEMENT',
		children: [
			{
				auth: 'admin.ruler.list',
				id: 'ruler',
				title: 'Notification - ruler',
				translate: 'RULER',
				type: 'item',
				icon: 'material-outline:edit_notifications',
				url: 'ruler'
			},
			{
				auth: 'admin.ruler.view',
				id: 'ruler history',
				title: 'Notification - ruler',
				translate: 'HISTORY',
				type: 'item',
				icon: 'material-outline:circle_notifications',
				url: 'ruler-history'
			}
		]
	},
	{
		auth: 'maintenance.menu',
		id: 'maintenance menu',
		title: 'Maintenance',
		translate: 'MAINTENANCE',
		type: 'collapse',
		icon: 'heroicons-outline:wrench-screwdriver',
		subtitleTranslate: 'MAINTENANCE_MANAGEMENT',
		children: [
			{
				auth: 'maintenance.maintenance.list',
				id: 'maintenance',
				title: 'maintenance',
				translate: 'MAINTENANCE',
				type: 'item',
				icon: 'heroicons-outline:wrench-screwdriver',
				url: 'maintenance/list'
			},
			{
				auth: 'maintenance.maintenance-item.menu',
				id: 'maintenance item',
				title: 'Maintenance Item',
				translate: 'MAINTENANCE_ITEM',
				type: 'item',
				icon: 'material-outline:build',
				url: 'maintenance/item'
			},
			{
				auth: 'maintenance.maintenance-report.list',
				id: 'maintenance report',
				title: 'Maintenance Report',
				translate: 'MAINTENANCE_REPORT',
				type: 'item',
				icon: 'material-outline:insert_chart',
				url: 'maintenance/report'
			}
		]
	},
	{
		auth: 'integrations.menu',
		id: 'integrations',
		icon: 'material-outline:sync',
		title: 'Integrations',
		translate: 'INTEGRATIONS',
		url: 'integrations',
		type: 'item'
	},
	{
		auth: 'admin.menu',
		id: 'records',
		icon: 'heroicons-outline:clipboard-list',
		title: 'Records',
		translate: 'RECORDS',
		subtitle: 'Records management',
		subtitleTranslate: 'RECORDS_MANAGEMENT',
		type: 'collapse',
		children: [
			{
				auth: 'admin.company.menu',
				id: 'companies',
				title: 'Companies',
				translate: 'COMPANIES',
				type: 'item',
				icon: 'heroicons-outline:office-building',
				url: 'admin/company'
			},
			{
				auth: 'admin.url.menu',
				id: 'urls',
				title: 'Urls',
				translate: 'URLS',
				type: 'item',
				icon: 'heroicons-outline:link',
				url: 'admin/urls'
			},
			{
				auth: 'admin.user.menu',
				id: 'users',
				title: 'Users',
				translate: 'USERS',
				type: 'item',
				icon: 'heroicons-outline:users',
				url: 'admin/users'
			},
			{
				auth: 'admin.profile.menu',
				id: 'profiles',
				title: 'Profiles',
				translate: 'PROFILES',
				type: 'item',
				icon: 'heroicons-outline:identification',
				url: 'admin/profiles'
			},
			{
				auth: 'admin.permission.menu',
				id: 'permissions',
				title: 'Permissions',
				translate: 'PERMISSIONS',
				type: 'item',
				icon: 'heroicons-outline:key',
				url: 'admin/permissions'
			},
			{
				auth: 'admin.contact.menu',
				id: 'contacts',
				title: 'Contacts',
				translate: 'CONTACTS',
				type: 'item',
				icon: 'material-outline:contacts',
				url: 'admin/contacts'
			},
			{
				auth: 'admin.whatsapp-session.menu',
				id: 'sessions',
				title: 'Sessions',
				translate: 'SESSIONS',
				type: 'item',
				icon: 'material-outline:whatsapp',
				url: 'admin/sessions'
			},
			{
				auth: 'admin.tracker-command.menu',
				id: 'commands',
				title: 'Commands',
				translate: 'COMMANDS',
				type: 'item',
				icon: 'material-outline:settings_remote',
				url: 'admin/commands'
			},
			{
				auth: 'admin.access-period.menu',
				id: 'access-period',
				title: 'Access Period',
				translate: 'ACCESS_PERIOD',
				type: 'item',
				icon: 'material-outline:access_time',
				url: 'admin/access-period'
			}
		]
	},

	{
		auth: 'installer.menu',
		id: 'marine-menu',
		icon: 'material-outline:plumbing',
		title: 'Marine',
		subtitle: 'Marine installation management',
		subtitleTranslate: 'MARINE_MANAGEMENT',
		type: 'collapse',
		children: [
			{
				id: 'ships',
				title: 'Ships',
				translate: 'SHIPS',
				type: 'item',
				icon: 'material-outline:directions_boat',
				url: 'marine/ships'
			}
		]
	},
	{
		auth: 'academy.menu',
		id: 'marine-academy',
		icon: 'material-outline:school',
		title: 'Academy',
		subtitle: 'Marine Academy Courses',
		subtitleTranslate: 'MARINE_ACADEMY',
		type: 'collapse',
		children: [
			{
				id: 'courses',
				title: 'Courses',
				translate: 'COURSES',
				type: 'item',
				icon: 'heroicons-outline:book-open',
				url: 'academy/courses'
			},
			{
				id: 'lessons',
				title: 'Lessons',
				translate: 'LESSONS',
				type: 'item',
				icon: 'heroicons-outline:play',
				url: 'academy/lessons'
			}
		]
	},
	{
		id: 'features',
		title: 'New Features',
		translate: 'NEW_FEATURES',
		subtitle: 'New Features',
		subtitleTranslate: 'NEW_FEATURES_SUBTITLE',
		type: 'item',
		icon: 'material-outline:new_releases',
		url: 'changelog'
	}
];

export default navigationConfig;
