const locale = {
	// Common

	CURRENT_USAGE: 'Uso atual',
	CURRENT_USAGE_RX: 'Download',
	CURRENT_USAGE_TX: 'Upload',
	TRACKER: 'Rastreador',
	NOTES: 'Notas',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PHONE: 'Telefone',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	BRAND: 'Operadora',
	SUPPLIER: 'Fornecedor',

	// Header

	TITLE: 'Simcards',
	IMPORT_SIMCARDS: 'Importar Simcards',
	ADD_SIMCARD: 'Adicionar simcard',
	ADD: 'Adicionar',
	CONFIRM: 'Confirmar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	DOWNLOAD_ALL: 'Baixar todos',

	// Required Fields

	TRACKER_REQUIRED: 'O campo rastreador é obrigatório',
	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	ICCID_REQUIRED: 'O campo ICCID é obrigatório',
	PHONE_REQUIRED: 'O campo telefone é obrigatório',
	BRAND_REQUIRED: 'O campo operadora é obrigatório',
	INVALID_COMPANY: 'Empresa inválida',

	// Table

	OF: 'de',
	ROWS_PER_PAGE: 'Itens por página',
	NO_SIMCARD_FOUND: 'Nenhum simcard encontrado',

	// Sidebar

	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	EDIT_TITLE: 'Editando simcard',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar este simcard?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar os simcards selecionados?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	SELECTED_ITENS: '{{n}} Itens selecionados',
	EDIT_MULTIPLE_INFO: 'Editar simcards',
	DELETE_MULTIPLE_INFO: 'Deletar simcards',
	DOWNLOAD_INFO: 'Baixar simcards',

	// Modal

	DELETE_MODAL_TITLE: 'Deletar simcard',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este simcard?',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} simcards?',

	// Permissions

	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',

	// Requests

	CREATE_SUCCESS: 'Simcard adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar simcard',
	EDIT_SUCCESS: 'Simcard atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar simcard',
	DELETE_SUCCESS: 'Simcard deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar simcard',
	MASSIVE_CREATE_SUCCESS: 'Simcards adicionados com sucesso',
	MASSIVE_CREATE_ERROR: 'Erro ao adicionar simcard',

	// Bulk

	SHOW_INVALID_ROWS: 'Mostrar linhas inválidas',
	CLEAR_TABLE: 'Limpar tabela',
	CREATING: 'Criando',
	DESCRIPTION_HEADER:
		'Aqui você pode criar simcards em massa, basta fazer o upload de um arquivo XLSX com os dados dos simcards, ou baixar o modelo e preencher com os dados dos simcards que deseja criar, você pode abrir o arquivo XLSX com o Excel ou outro editor de planilhas.',
	DOWNLOAD_TEMPLATE: 'Baixar modelo.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arraste e solte o arquivo XLSX aqui ou clique para selecionar.',
	REMOVE_ROW: 'Remover linhas',

	// 1nce
	RESET_SIMCARD: 'Resetar simcard',
	SIMCARD_ACTIONS: 'Ações do simcard',
	RESET_SIMCARD_CONFIRM: 'Você tem certeza que deseja resetar este simcard?',
	RESET_SIMCARD_SUCCESS: 'Simcard resetado com sucesso',
	RESET_SIMCARD_ERROR: 'Erro ao resetar simcard',
	SMS_DETAILS: 'Detalhes do SMS',
	RECEIVE_SMS_DETAILS: 'Solicitar detalhes via SMS',
	SMS_DETAILS_SUCCESS: 'Detalhes do SMS recebidos com sucesso',
	SMS_DETAILS_ERROR: 'Erro ao receber detalhes do SMS',
	SEND_SMS: 'Enviar SMS',
	SMS_MESSAGE_REQUIRED: 'O campo mensagem é obrigatório',
	SMS_MESSAGE: 'Mensagem',
	MESSAGE_ID: 'ID da mensagem',
	SUBMIT_DATE: 'Data de envio',
	DELIVERY_DATE: 'Data de entrega',
	PAYLOAD: 'Payload',
	EXPIRY_DATE: 'Data de expiração',
	SMS_SENT_SUCCESS: 'SMS enviado com sucesso',
	SMS_SEND_ERROR: 'Erro ao enviar SMS',
	ADDITIONAL_INFORMATION: 'Informações adicionais',
	LAST_DELIVERY_ATTEMPT: 'Última tentativa de entrega',
	STATUS: 'Status',
	EXPIRES_IN: 'Expira em',
	SMS_QUOTA: 'Cota de SMS',
	REFRESH: 'Atualizar',
	LAST_VOLUME_ADDED: 'Último volume adicionado',
	TRESHOLD_PERCENTAGE: 'Disponível',
	LAST_STATUS_CHANGE_DATE: 'Última alteração',
	SMS_QUOTA_DESCRIPTION:
		'A contagem de SMS para simcards 1nce, é feita de acordo com o volume de mensagens enviadas.',
	PHONE_LENGHT_AND_FORMAT_WARNING:
		'O telefone deve ter 11 dígitos, sem caracteres especiais, incluindo o DDD, por exemplo: 11999999999',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar Tabela',
	FILTER: 'Filtrar',
	CLEAR_FILTERS: 'Limpar filtros',
	ERROR: 'Erro',
	SUCCESS: 'Sucesso'
};

export default locale;
