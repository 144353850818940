import useResizeObserver from '@react-hook/resize-observer';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export const useSize = (target) => {
	const [size, setSize] = useState({ width: 0, height: 0 });
	const previousSize = useRef({ width: 0, height: 0 });

	const updateSize = useCallback(() => {
		if (target.current) {
			const { width, height } = target.current.getBoundingClientRect();
			if (
				Math.abs(previousSize.current.width - width) > 1 ||
				Math.abs(previousSize.current.height - height) > 1
			) {
				setSize({ width, height });
				previousSize.current = { width, height };
			}
		}
	}, [target]);

	useLayoutEffect(() => {
		updateSize();
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	}, [updateSize]);

	useResizeObserver(target, updateSize);

	return size;
};
