const locale = {
	// Común

	CURRENT_USAGE: 'Uso Actual',
	CURRENT_USAGE_RX: 'Descarga',
	CURRENT_USAGE_TX: 'Subida',
	TRACKER: 'Rastreador',
	NOTES: 'Notas',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PHONE: 'Teléfono',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	BRAND: 'Marca',
	SUPPLIER: 'Proveedor',

	// Encabezado

	TITLE: 'Tarjetas SIM',
	IMPORT_SIMCARDS: 'Importar Tarjetas SIM',
	ADD_SIMCARD: 'Agregar simcard',
	ADD: 'Agregar',
	CONFIRM: 'Confirmar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar Filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	DOWNLOAD_ALL: 'Descargar Todo',

	// Campos Requeridos

	TRACKER_REQUIRED: 'El campo Rastreador es obligatorio',
	COMPANY_REQUIRED: 'El campo Empresa es obligatorio',
	ICCID_REQUIRED: 'El campo ICCID es obligatorio',
	PHONE_REQUIRED: 'El campo Teléfono es obligatorio',
	BRAND_REQUIRED: 'El campo Marca es obligatorio',
	INVALID_COMPANY: 'Empresa inválida',

	// Tabla

	OF: 'de',
	ROWS_PER_PAGE: 'Elementos por página',
	NO_SIMCARD_FOUND: 'No se encontraron tarjetas SIM',

	// Barra Lateral

	SELECTED_ITEMS: 'Elementos Seleccionados',
	NO_ITEM_SELECTED: 'Ningún elemento seleccionado',
	EXPORT_SELECTED: 'Exportar Elementos Seleccionados',
	DELETE_SELECTED: 'Eliminar Elementos Seleccionados',
	EDIT_SELECTED: 'Editar Elementos Seleccionados',
	EDIT_TITLE: 'Editando Tarjeta SIM',
	EDIT_CONFIRMATION: '¿Estás seguro de que deseas editar esta tarjeta SIM?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Estás seguro de que deseas editar las tarjetas SIM seleccionadas?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_MULTIPLE_INFO: 'Editar tarjetas SIM',
	DELETE_MULTIPLE_INFO: 'Eliminar tarjetas SIM',
	DOWNLOAD_INFO: 'Descargar tarjetas SIM',

	// Modal

	DELETE_MODAL_TITLE: 'Eliminar Tarjeta SIM',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que deseas eliminar esta tarjeta SIM?',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro de que deseas eliminar {{n}} tarjetas SIM?',

	// Permisos

	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',

	// Solicitudes

	CREATE_SUCCESS: 'Tarjeta SIM agregada exitosamente',
	CREATE_ERROR: 'Error al agregar tarjeta SIM',
	EDIT_SUCCESS: 'Tarjeta SIM actualizada exitosamente',
	EDIT_ERROR: 'Error al actualizar tarjeta SIM',
	DELETE_SUCCESS: 'Tarjeta SIM eliminada exitosamente',
	DELETE_ERROR: 'Error al eliminar tarjeta SIM',
	MASSIVE_CREATE_SUCCESS: 'Tarjetas SIM agregadas exitosamente',
	MASSIVE_CREATE_ERROR: 'Error al agregar tarjeta SIM',
	MASSIVE_CREATE_BAD_REQUEST: 'Datos inválidos, verifica el archivo y vuelve a intentarlo.',

	// Granel

	SHOW_INVALID_ROWS: 'Mostrar filas inválidas',
	CLEAR_TABLE: 'Limpiar tabla',
	CREATING: 'Creando',
	DESCRIPTION_HEADER:
		'Aquí puedes crear tarjetas SIM en granel al cargar un archivo XLSX con datos de los conductores o descargar la plantilla y completarla con los datos de los conductores que deseas crear. Puedes abrir el archivo XLSX con Excel u otro editor de hojas de cálculo.',
	DOWNLOAD_TEMPLATE: 'Descargar plantilla.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arrastra y suelta el archivo XLSX aquí o haz clic para seleccionar.',
	REMOVE_ROW: 'Eliminar filas',

	// 1nce
	RESET_SIMCARD: 'Reiniciar tarjeta SIM',
	SIMCARD_ACTIONS: 'Acciones de la tarjeta SIM',
	RESET_SIMCARD_CONFIRM: '¿Estás seguro de que quieres reiniciar esta tarjeta SIM?',
	RESET_SIMCARD_SUCCESS: 'Tarjeta SIM reiniciada con éxito',
	RESET_SIMCARD_ERROR: 'Error al reiniciar la tarjeta SIM',
	SMS_DETAILS: 'Detalles del SMS',
	RECEIVE_SMS_DETAILS: 'Solicitar detalles por SMS',
	SMS_DETAILS_SUCCESS: 'Detalles del SMS recibidos con éxito',
	SMS_DETAILS_ERROR: 'Error al recibir detalles del SMS',
	SEND_SMS: 'Enviar SMS',
	SMS_MESSAGE_REQUIRED: 'El campo de mensaje es obligatorio',
	SMS_MESSAGE: 'Mensaje',
	MESSAGE_ID: 'ID del mensaje',
	SUBMIT_DATE: 'Fecha de envío',
	DELIVERY_DATE: 'Fecha de entrega',
	PAYLOAD: 'Carga útil',
	EXPIRY_DATE: 'Fecha de expiración',
	SMS_SENT_SUCCESS: 'SMS enviado con éxito',
	SMS_SEND_ERROR: 'Error al enviar SMS',
	ADDITIONAL_INFORMATION: 'Información adicional',
	LAST_DELIVERY_ATTEMPT: 'Último intento de entrega',
	STATUS: 'Estado'
};

export default locale;
