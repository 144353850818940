import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { selectCurrentTab } from 'app/store/academy/academySlice';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function AcademyHeader() {
	const { t } = useTranslation('academyPage');
	const tab = useSelector(selectCurrentTab);
	return (
		<Box
			className="overflow-hidden flex shrink-0 items-center justify-center px-16 py-10 md:p-64"
			sx={{
				backgroundColor: 'primary.main',
				color: (theme) => theme.palette.getContrastText(theme.palette.primary.main)
			}}
		>
			<div className="flex flex-col items-center justify-center  mx-auto w-full">
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0 } }}>
					<Typography color="inherit" className="text-18 font-semibold">
						MARINE ACADEMY
					</Typography>
				</motion.div>
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0 } }}>
					<Typography
						color="inherit"
						className="text-center text-32 sm:text-48 font-extrabold tracking-tight mt-4"
					>
						{tab == 'courses' ? t('COURSES') : t('LESSONS')}
					</Typography>
				</motion.div>
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
					<Typography
						color="inherit"
						className="text-16 sm:text-20 mt-16 sm:mt-24 opacity-75 tracking-tight max-w-md text-center"
					>
						{tab == 'courses' ? t('COURSES_HEADER_DESCRIPTION') : t('LESSONS_HEADER_DESCRIPTION')}
					</Typography>
				</motion.div>
			</div>
		</Box>
	);
}
export default AcademyHeader;
