import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';

import { useSSE } from 'app/services/hooks/useSSE';

import { TTrackerSettings } from 'app/types/tracker-settings.types';

import { Restore } from '@mui/icons-material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CopyButton from 'app/shared-components/Buttons/CopyButton';
import userHasPermission from 'src/utils/useHasPermission';
import SettingsItem from './SettingsItem';

interface ITrackerSettingsProps {
	trackerDID: number;
}

type TRestoreTvd = {
	TrackerId: string;
	VehicleId: string;
	CompanyId: string;
	did: number;
	count: number;
	totalDocs: number;
	percent: number;
};

const verifyTime = (serverTime: string) => {
	if (!serverTime) return false;

	const time = dayjs(serverTime);
	const unix = time.unix();

	if (unix === 0) return false;

	const now = dayjs();
	const diff = now.diff(time, 'year');

	return diff <= 1;
};

export default function TrackerSettings({ trackerDID }: ITrackerSettingsProps) {
	const { t } = useTranslation('mapPage');

	const [trigger, { data, loading: triggerLoading }] = useSSE<TTrackerSettings>(`/tracker/${trackerDID}/status`, {
		defaultValue: {
			gateway: null,
			realtime: null,
			tvd: null
		},
		method: 'GET'
	});

	const [restoreTvd, { loading }] = useSSE<TRestoreTvd>(`/tracker/${trackerDID}/tvd`, {
		defaultValue: null,
		method: 'PUT'
	});

	const hasTvd = _.get(data, 'tvd.statusOk', false);
	const packet = _.get(data, 'realtime.PACKAGE', null);
	const isAdmin = userHasPermission('system.admin');

	useEffect(() => {
		trigger();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleRestoreTvd = async () => {
		try {
			await restoreTvd();
			trigger(null);
		} catch (err) {
			console.error(err);
		}
	};

	const serverTime = _.get(data, 'realtime.SERVER_TIME', undefined);
	const serverTimeIsCorrect = verifyTime(serverTime);

	// const gatewayTime = _.get(data, 'gateway.date', undefined);
	// const gatewayTimeIsCorrect = verifyTime(gatewayTime);

	return (
		<Stack direction="column" gap={2} width={460} overflow="auto" padding={2}>
			{isAdmin && (
				<Stack direction="row" alignItems="center" gap={1}>
					<SettingsItem title={t('ADJUSTED')} correct={hasTvd} loading={triggerLoading} />
					{!hasTvd && !triggerLoading && (
						<Tooltip title={t('RESTORE_TVD')}>
							<IconButton size="small" color="primary" onClick={handleRestoreTvd}>
								{loading ? <CircularProgress size={16} /> : <Restore fontSize="small" />}
							</IconButton>
						</Tooltip>
					)}
				</Stack>
			)}
			{/* <SettingsItem
				title={t('LAST_COMMUNICATION')}
				correct={gatewayTimeIsCorrect}
				value={gatewayTime && dayjs(gatewayTime).format('DD/MM/YYYY HH:mm:ss')}
				loading={triggerLoading}
			/> */}
			<SettingsItem
				title={t('LAST_GPS')}
				correct={serverTimeIsCorrect}
				value={serverTime && dayjs(serverTime).format('DD/MM/YYYY HH:mm:ss')}
				loading={triggerLoading}
			/>
			{isAdmin && (
				<Stack direction="row" alignItems="center" gap={1}>
					<SettingsItem
						title={t('LAST_PACKET')}
						correct={_.has(data, 'realtime.PACKAGE')}
						loading={triggerLoading}
						value={_.get(data, 'lst-asset-find.EVENT_TYPE', null)}
					/>
					<CopyButton mode="text" data={packet} />
				</Stack>
			)}
		</Stack>
	);
}
