import { AbilityBuilder, MatchConditions, PureAbility } from "@casl/ability";
import { SYSTEM_ADMIN_PERMISSION } from "src/constants";
import { defineAdminAbility } from "src/utils/define-admin-ability";
import userHasPermission from "src/utils/useHasPermission";
import { getMissingPermissions } from "../helper";
import { AbilityTuple, MissingPermissions } from "../types";

type RulerAbility = PureAbility<AbilityTuple<'tripsReport'>, MatchConditions>;

const LIST_TRIPS_REPORT_PERMISSIONS = ['reports.trips.list'];

const VIEW_TRIPS_REPORT_PERMISSIONS = ['reports.trips.view'];

export const defineTripsReportAbility = () => {
    const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

    const missingPermissions: MissingPermissions = {
        list: getMissingPermissions(LIST_TRIPS_REPORT_PERMISSIONS),
        view: getMissingPermissions(VIEW_TRIPS_REPORT_PERMISSIONS),
        create: [],
        edit: [],
        delete: []
    };

    if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
        defineAdminAbility('tripsReport', can);
        return [build(), missingPermissions] as const;
    }

    if (userHasPermission(LIST_TRIPS_REPORT_PERMISSIONS)) can('list', 'tripsReport');
    if (userHasPermission(VIEW_TRIPS_REPORT_PERMISSIONS)) can('view', 'tripsReport');

    return [build(), missingPermissions] as const;
}