const locale = {
	// vehicleInformation

	YEAR: 'Year',
	BRAND: 'Brand',
	TYPE: 'Type',
	MODEL: 'Model',
	COLOR: 'Color',
	PLATE: 'Plate',
	VENDOR: 'Vendor',

	// header

	EXPORT: 'Export',

	// commons

	NOT_PROVIDED: 'Not provided',
	LAST_TRACKER_EVENT: 'Last tracker event',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	TRACKERS: 'Trackers',
	TRACKERS_LABEL: '{{n}} Trackers',
	FLEETS: 'Fleets',
	FLEETS_LABEL: '{{n}} Fleets',
	COMPANY: 'Company',
	VIEW_COMPANY: 'View company',
	VIEW_FLEET: 'View fleet',
	VEHICLE_INFORMATION: 'Vehicle information',
	VIEW_SIMCARD: 'View simcard',
	SIMCARDS: 'Simcards',
	SIMCARDS_LABEL: '{{n}} Simcards',
	// lastTrackerEvent

	EVENT_TYPE: 'Event type',
	ADDRESS: 'Address',

	COORDINATES: 'Coordinates',
	LAT: 'Lat',
	LNG: 'Lng',
	ALT: 'Altidude',

	INFO_TRACKER: 'Info tracker',
	SATELLITES: 'Satellites',
	RXLEV: 'Rxlev',
	GPS_FIXED: 'GPS fixed',
	IGNITION_ON: 'Ignition',
	ODOMETER: 'Odometer',
	HOURMETER: 'Hourmeter',
	CLOSE: 'Close',
	BATTERY: 'Battery'
};
export default locale;
