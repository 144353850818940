import { useDownload } from 'app/services/hooks';
import TooltipIconButton from 'app/shared-components/Buttons/TooltipIconButton';
import DownloadWarning from 'app/shared-components/DownloadWarning/DownloadWarning';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import { TFunction } from 'i18next';
import xlsx, { IJsonSheet } from 'json-as-xlsx';
import _ from 'lodash';
import { objectToStringParams } from 'src/utils/objectToStringParams';
import { stringNow } from 'src/utils/stringNow';

interface DownloadProps {
	totalItems: number;
	t: TFunction;
	paginationData: any;
	visibleColumns: any;
	url: string;
	fileName: string;
	getColumnsFunction: (t: TFunction) => any;
	downloadParams?: string[];
}

const DownloadAll = ({
	t,
	totalItems,
	paginationData,
	visibleColumns,
	url,
	fileName,
	getColumnsFunction,
	downloadParams
}: DownloadProps) => {
	const dispatch = useAppDispatch();
	const { download, loading: isDownloading } = useDownload();

	const handleOpenDownloadModal = () => {
		dispatch(
			openDialog({
				children: <DownloadWarning onConfirm={handleConfirmDownload} loading={isDownloading} />
			})
		);
	};

	const handleConfirmDownload = async () => {
		const defaultParams = ['sortKey', 'sortValue', 'search', 'searchKey'];
		const allParams = defaultParams.concat(downloadParams || []);
		const _params = _.pick(paginationData, allParams);
		const params = objectToStringParams(_params);		

		dispatch(closeDialog());

		const data = await download({
			url,
			params,
			filename: stringNow(fileName),
			headers: visibleColumns
		});
		const sheet = {
			sheet: fileName,
			columns: getColumnsFunction(t),
			content: data
		} as unknown as IJsonSheet;

		const settings = {
			fileName: stringNow(fileName)
		};

		xlsx([sheet], settings);
	};

	return (
		<div>
			<TooltipIconButton
				iconButtonProps={{ disabled: !totalItems, color: 'secondary' }}
				label={t('DOWNLOAD_ALL')}
				icon="file_download"
				size="small"
				onClick={handleOpenDownloadModal}
			/>
		</div>
	);
};

export default DownloadAll;
