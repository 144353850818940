import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';

import { commonEn, commonPt, commonSp } from '../shared/commonTranslation';
import listEn from './list/i18n/en';
import listPt from './list/i18n/pt';
import listSp from './list/i18n/sp';

import { lazyRetry } from 'src/utils/lazyRetry';
import advancedEn from './advanced/i18n/en';
import advancedPt from './advanced/i18n/pt';
import advancedSp from './advanced/i18n/sp';

const Landing = lazyRetry(() => import('./list/TripsList'));
const TripsAdvancedPage = lazyRetry(() => import('./advanced/TripsAdvanced'));

export const TRIPS_PERMISSIONS = {
	edit: '',
	view: 'reports.trips.view',
	list: 'reports.trips.list',
	new: '',
	delete: '',
	minimal: []
};

i18next.addResourceBundle('en', 'tripsListPage', { ...listEn, ...commonEn });
i18next.addResourceBundle('sp', 'tripsListPage', { ...listSp, ...commonSp });
i18next.addResourceBundle('pt', 'tripsListPage', { ...listPt, ...commonPt });

i18next.addResourceBundle('en', 'tripsAdvancedPage', advancedEn);
i18next.addResourceBundle('sp', 'tripsAdvancedPage', advancedSp);
i18next.addResourceBundle('pt', 'tripsAdvancedPage', advancedPt);

const TripsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['reports.trips.view'],
			path: 'reports/trips/advanced/:trip',
			element: (
				<LazyLoaderSuspense>
					<TripsAdvancedPage />
				</LazyLoaderSuspense>
			)
		},
		{
			auth: ['reports.trips.list'],
			path: 'reports/trips',
			element: (
				<LazyLoaderSuspense>
					<Landing />
				</LazyLoaderSuspense>
			),
			children: [{ path: ':id' }]
		}
	]
};

export default TripsConfig;
