import FusePageSimple from '@fuse/core/FusePageSimple';
import { Box } from '@mui/system';
import { getUserAbility } from 'app/permissions';
import { defineHistoryAbility } from 'app/permissions/reports/history';
import { useCRUDSearchParams } from 'app/services/hooks/useCrudSearchParams';
import TooltipIconButton from 'app/shared-components/Buttons/TooltipIconButton';
import { Header } from 'app/shared-components/CRUDs/NewHeader/Header';
import NoPermission from 'app/shared-components/NoPermission';
import { useGetTrackerEventsQuery } from 'app/store/api/apiSlice';
import { useGetAllHistoryQuery } from 'app/store/api/newHistorySlice';
import { selectSelectedRows, selectSideBarOpen, setSidebarMode, setSideBarOpen } from 'app/store/reports/historySlice';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'src/utils/useLocalStorage';
import { EventAndHistoryFilters } from '../common/filters/EventAndHistoryFilters';
import { handleStreetView } from '../common/helper';
import Content from './components/Content';
import Sidebar from './components/Sidebar/Sidebar';
import { getExcelColumns } from './helper';
import { defaultVisibleColumns, getColumns, getColumnsNames, getSearchKeysNames } from './tableColumns';
import { HistorySearchParams, HistorySearchParamsFilters, THistoryPosition } from './types';

const emptyArr = [];

export default function History() {
	const { t } = useTranslation('historyPage');

	const sideBarIsOpen = useSelector(selectSideBarOpen);
	const selectedRows = useSelector(selectSelectedRows);
	const hasRowsSelected = _.size(selectedRows) > 0;
	const dispatch = useDispatch();

	const [visibleColumns, setVisibleColumns] = useLocalStorage('HistoryColumns', defaultVisibleColumns);

	const params = ['start', 'end', 'company', 'vehicle', 'tracker', 'fleet', 'eventType'];

	const {
		searchParamsData,
		changeSearchKey,
		changeSearch,
		changeParam,
		changeLimit,
		changeSort,
		changePage,
		queryId
	}: ReturnType<typeof useCRUDSearchParams> & { searchParamsData: HistorySearchParams } = useCRUDSearchParams(params, setSidebarMode);

	const hasFilters = useMemo(() => {
		const { tracker, vehicle, company, eventType, start, end } = searchParamsData;

		return !!(tracker || vehicle || company || eventType || start || end);
	}, [searchParamsData]);

	const userAbility = getUserAbility();
	const [, { list, view }] = defineHistoryAbility();

	const { history, totalItems, selectedRow, isLoading, isFetching } = useGetAllHistoryQuery(searchParamsData, {
		selectFromResult: ({ data, ...res }) => ({
			history: data?.docs || emptyArr,
			selectedRow: _.find(data?.docs, { _id: queryId }) as THistoryPosition,
			totalItems: data?.totalDocs || 0,
			...res
		}),
		skip: !hasFilters || !userAbility.can('list', 'history') || !userAbility.can('view', 'history')
	});

	const { trackerEvents, isTrackerEventsLoading } = useGetTrackerEventsQuery('', {
		selectFromResult: ({ data, ...res }) => ({
			trackerEvents: data?.docs || emptyArr,
			isTrackerEventsLoading: _.some([res.isLoading, res.isFetching]),
			...res
		})
	});

	const columns = useMemo(() => getColumns(t, visibleColumns, trackerEvents), [t, visibleColumns, trackerEvents]);

	const handleApplyFilters = (values: HistorySearchParamsFilters) => {
		changeSearch(values.search);
		changeParam('company', values.company);
		changeParam('tracker', values.tracker);
		changeParam('vehicle', values.vehicle);
		changeParam('eventType', values.eventType);
		changeParam('start', values.start?.toString());
		changeParam('end', values.end?.toString());
	};

	const handleStreetViewClick = () => {
		handleStreetView(selectedRows, dispatch);
	};

	return (
		<FusePageSimple
			header={
				userAbility.can('list', 'history') ? (
					<Header
						loading={isLoading}
						paginationData={searchParamsData}
						visibleColumns={visibleColumns}
						totalItems={totalItems}
						t={t}
						url='/report/history'
						fileName='history_report'
						permissionGroup='history'
						setIsSidebarOpen={setSideBarOpen}
						Filters={
							<EventAndHistoryFilters
								onApplyFilters={handleApplyFilters}
								defaultValues={searchParamsData}
								userAbility={userAbility}
								isEventReport={false}
							/>
						}
						getColumnsNames={getColumnsNames}
						getSearchKeys={getSearchKeysNames}
						changeSearchKey={changeSearchKey}
						setVisibleColumns={setVisibleColumns}
						getExcelColumns={getExcelColumns}
						downloadParams={params}
						hasDownloadAll={true}
						selectSelectedRows={selectSelectedRows}
						hasDownloadMultiple={true}
						showIconAdd={false}
						setSidebarMode={setSidebarMode}
						AdditionalActions={
							<TooltipIconButton
								iconButtonProps={{ disabled: !hasRowsSelected, color: 'secondary' }}
								label="Google Maps"
								icon="map"
								size="small"
								onClick={handleStreetViewClick}
							/>
						}
					/>
				) : undefined
			}
			content={
				userAbility.can('list', 'history') ? (
					<Content
						onChangeLimit={changeLimit}
						onChangePage={changePage}
						onChangeSort={changeSort}
						columns={columns}
						loading={isFetching || isTrackerEventsLoading}
						data={history}
						totalRows={totalItems}
						paginationData={searchParamsData}
						hasFilters={hasFilters}
					/>
				) : (
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							height: '100%'
						}}
					>
						<NoPermission permissionCodes={list} imageSrc="/assets/images/bg/table_blur.png" t={t} />
					</Box>
				)
			}
			scroll="content"
			rightSidebarWidth={428}
			rightSidebarOpen={sideBarIsOpen}
			rightSidebarContent={
				userAbility.can('view', 'history') ? (
					<Sidebar history={selectedRow} />
				) : (
					<NoPermission permissionCodes={view} t={t} imageSrc="/assets/images/bg/sidebar_blur.png" />
				)
			}
		/>
	);
}
