import { Action, PreloadedState, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { apiSlice } from './api/apiSlice';
import reducers from './rootReducer';

const NODE_ENV = import.meta.env.MODE;

const middlewares = [];

if (NODE_ENV === 'development') {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const { createLogger } = await import('redux-logger');
	const logger = createLogger({ collapsed: (_getState, _action, logEntry) => !logEntry.error });

	middlewares.push(logger);
}

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
	configureStore({
		reducer: reducers,
		preloadedState,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				immutableCheck: false,
				serializableCheck: false
			}).concat([...middlewares, apiSlice.middleware]),
		devTools: NODE_ENV === 'development'
	});

const store = setupStore();

export type RootState = ReturnType<typeof reducers>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
