import { styled } from '@mui/material';
import { Popup } from 'react-map-gl/maplibre';

const MapPopup = styled(Popup)(({ theme }) => ({
	'.maplibregl-popup-anchor-top .maplibregl-popup-tip': {
		borderBottomColor: `${theme.palette.background.default} !important`
	},
	'.maplibregl-popup-anchor-bottom .maplibregl-popup-tip': {
		borderTopColor: `${theme.palette.background.default} !important`
	},
	'.maplibregl-popup-anchor-left .maplibregl-popup-tip': {
		borderTopRight: `${theme.palette.background.default} !important`
	},
	'.maplibregl-popup-anchor-right .maplibregl-popup-tip': {
		borderTopLeft: `${theme.palette.background.default} !important`
	},
	'.maplibregl-popup-content': {
		background: theme.palette.background.default,
		borderRadius: 16,
		width: 540,
		minHeight: 0,
		height: 'auto',
		padding: 0,
		'div:only-child': {
			width: '100%'
		}
	}
}));

export default MapPopup;
