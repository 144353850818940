import { Box, Typography, Stack, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { clearEventPopup, selectEventPopup } from 'app/store/map/mapSlice';
import MapPopup from 'app/shared-components/MapPopup';

const TextItem = ({ value = '' }) => (
	<Typography
		sx={{
			fontWeight: 400,
			fontSize: '14px'
		}}
		noWrap
	>
		{value}
	</Typography>
);

const ITEMS_MAP = {
	EVENT_TYPE: (data) => <TextItem value={data} />,
	GPS_TIME: (data) => (data ? <TextItem value={dayjs(data).format('DD/MM/YYYY - HH:mm:ss')} /> : ''),
	ALTITUDE: (data) => <TextItem value={data} />,
	BEARING: (data) => <TextItem value={data} />,
	SATELLITES: (data) => <TextItem value={data} />,
	LATITUDE: (data) => <TextItem value={data} />,
	LONGITUDE: (data) => <TextItem value={data} />,
	ADDRESS_CITY: (data) => <TextItem value={data} />,
	ADDRESS_COUNTRY: (data) => <TextItem value={data} />,
	ADDRESS_POSTCODE: (data) => <TextItem value={data} />,
	ADDRESS_ROAD: (data) => <TextItem value={data} />,
	ADDRESS_STATE: (data) => <TextItem value={data} />,
	ADDRESS_SUBURB: (data) => <TextItem value={data} />,
	HOURMETER: (data) => <TextItem value={data} />,
	MILEAGE: (data) => <TextItem value={data} />,
	SPEED: (data) => <TextItem value={data} />,
	IGNITION_ON: (data, t) => (data ? <TextItem value={t('YES')} /> : <TextItem value={t('NO')} />)
};

export default function EventPopup() {
	const { t } = useTranslation('mapPage');
	const dispatch = useDispatch();
	const eventPopup = useSelector(selectEventPopup);

	const handleClose = () => dispatch(clearEventPopup());

	if (!eventPopup.data) {
		return undefined;
	}

	return (
		<MapPopup
			longitude={eventPopup.data.LONGITUDE}
			latitude={eventPopup.data.LATITUDE}
			closeButton={false}
			onClose={handleClose}
			maxWidth="none"
		>
			<Stack alignItems="flex-end" height="100%">
				<IconButton onClick={handleClose} size="small">
					<CloseOutlined />
				</IconButton>
				<Stack
					spacing={1}
					sx={{
						overflowY: 'auto',
						padding: '8px',
						width: '100%',
						height: '320px'
					}}
					alignItems="center"
				>
					{Object.keys(eventPopup.data).map((dataKey) => (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%'
							}}
							key={dataKey}
						>
							<Typography
								sx={{
									fontWeight: 600,
									fontSize: '14px',
									paddingRight: 1
								}}
							>
								{t(dataKey)}
							</Typography>
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: '12px'
								}}
								noWrap
							>
								{ITEMS_MAP[dataKey](eventPopup.data[dataKey], t)}
							</Typography>
						</Box>
					))}
				</Stack>
			</Stack>
		</MapPopup>
	);
}
