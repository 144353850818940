import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { lighten } from '@mui/material/styles';
import { ViewIcon } from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import DeleteDialog from 'app/shared-components/DeleteModal';
import { setCurrentEditLessonUrl, setCurrentLesson } from 'app/store/academy/academySlice';
import { useDeleteLessonsMutation } from 'app/store/api/lessonsSlice';
import { setSidebarMode } from 'app/store/crud/coursesSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { REQUEST_MESSAGES_LESSON } from '../helper';
import LessonInfo from './LessonInfo';
import LessonProgress from './LessonProgress';

function LessonCard({ lesson, permissions }) {
	const { t } = useTranslation('academyPage');
	const dispatch = useDispatch();
	const [deleteLesson, { isLoading: isDeleting }] = useDeleteLessonsMutation();

	const handleEditSingle = () => {
		dispatch(setSidebarMode('edit'));
		dispatch(setCurrentLesson(lesson));
		dispatch(setCurrentEditLessonUrl(lesson?.url));
	};

	const handleOpenDeleteModal = () => {
		dispatch(
			openDialog({
				children: (
					<DeleteDialog
						t={t}
						targetIds={[lesson?._id]}
						onClose={handleDeleteModalClose}
						onConfirm={handleDelete}
						loading={isDeleting}
					/>
				)
			})
		);
	};

	const handleDeleteModalClose = () => {
		dispatch(closeDialog());
	};

	const handleDelete = async () => {
		try {
			await deleteLesson(lesson._id).unwrap();
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_LESSON.delete.success),
					variant: 'success'
				})
			);
			dispatch(closeDialog());
		} catch {
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_LESSON.delete.error),
					variant: 'error'
				})
			);
		}
	};

	function buttonStatus() {
		switch (lesson.activeStep) {
			// case lesson.totalSteps:
			//   return 'Completed';
			case 0:
				return t('START');
			default:
				return t('WATCH_LESSON');
		}
	}

	return (
		<Card className="flex flex-col h-384 shadow">
			<CardContent className="flex flex-col flex-auto p-24">
				<LessonInfo lesson={lesson} className="" />
			</CardContent>
			<LessonProgress className="" lesson={lesson} />
			<CardActions
				className="flex items-center justify-between py-16 px-24"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? lighten(theme.palette.background.default, 0.4)
							: lighten(theme.palette.background.default, 0.03)
				}}
			>
				<div className="flex space-x-5">
					{permissions.hasEdit ? (
						<ViewIcon icon="edit" onClick={() => handleEditSingle()} button />
					) : undefined}
					{permissions.hasDelete ? (
						<ViewIcon icon="delete" onClick={() => handleOpenDeleteModal()} button />
					) : undefined}
				</div>

				<Button
					to={`/academy/lessons/${lesson._id}`}
					component={Link}
					className="px-16 min-w-128"
					color="secondary"
					variant="contained"
					endIcon={
						<FuseSvgIcon className="" size={20}>
							heroicons-solid:eye
						</FuseSvgIcon>
					}
				>
					{buttonStatus()}
				</Button>
			</CardActions>
		</Card>
	);
}

export default LessonCard;
