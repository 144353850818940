import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const RegisterFuelSupplyPage = lazyRetry(() => import('./RegisterFuelSupplyPage'));

i18next.addResourceBundle('en', 'registerFuelSupplyPage', en);
i18next.addResourceBundle('sp', 'registerFuelSupplyPage', sp);
i18next.addResourceBundle('pt', 'registerFuelSupplyPage', pt);

const RegisterFuelSupplyConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.fuel-supply.new', 'fleet.fuel-supply.edit'],
			path: 'fleet/fuel-supply/register/:fuelSupplyId',
			element: (
				<LazyLoaderSuspense>
					<RegisterFuelSupplyPage />
				</LazyLoaderSuspense>
			)
		},
		{
			auth: ['fleet.fuel-supply.new', 'fleet.fuel-supply.edit'],
			path: 'fleet/fuel-supply/register/',
			element: (
				<LazyLoaderSuspense>
					<RegisterFuelSupplyPage />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default RegisterFuelSupplyConfig;
