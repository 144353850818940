import { Paper, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import _ from 'lodash';

import { ITrackerCardProps } from '.';
import CardActions from './CardActions';
import formatBattery from 'src/utils/formatBattery';

type TPackItemsProps = {
	label: string;
	value: string | number;
	variant?: 'row' | 'col';
	isDarkMode: boolean;
};

const PacketItem = ({ label = '', value = '', variant = 'row', isDarkMode = false }: TPackItemsProps) => {
	const rowColor = isDarkMode ? '#fff' : '#000';
	const colColor = isDarkMode ? '#9FA5A1' : '#79747E';
	return (
		<div
			className={clsx(
				'flex',
				variant === 'row' ? 'items-center justify-start' : 'flex-col justify-center items-start'
			)}
		>
			<Typography
				sx={{
					fontStyle: 'normal',
					fontWeight: variant === 'row' ? 400 : 600,
					fontSize: variant === 'row' ? '14px' : '12px',
					lineHeight: '24px',
					color: variant === 'row' ? rowColor : colColor,
					paddingRight: variant === 'row' ? '2px' : 0
				}}
			>
				{label}
			</Typography>
			<Typography
				sx={{
					fontStyle: 'normal',
					fontWeight: variant === 'row' ? 500 : 600,
					fontSize: variant === 'row' ? '14px' : '16px',
					lineHeight: '24px',
					maxWidth: '220px',
					color: isDarkMode ? '#fff' : '#000'
				}}
				noWrap
			>
				{value}
			</Typography>
		</div>
	);
};

export default function TertiaryLayout({
	isDarkMode,
	parsedDates,
	tracker,
	t,
	selected,
	handleCardClick,
	displayName
}: ITrackerCardProps) {
	return (
		<div className="pb-8 m-0 flex justify-center">
			<Paper
				sx={{
					width: ['100%', '390px'],
					height: '194px',
					margin: 0,
					border: '1px solid #79747E',
					borderRadius: '12px',
					padding: '20px 30px',
					cursor: 'pointer',
					backgroundColor: (theme) => {
						if (selected) return `${theme.palette.secondary.main}1a`;
						return isDarkMode ? '#323C4D' : '#fff';
					}
				}}
				variant="outlined"
				onClick={handleCardClick}
			>
				<div className="flex flex-col space-y-1 relative w-full h-full">
					<PacketItem label={t('TRACKER')} value={_.get(tracker, 'did', '0')} isDarkMode={isDarkMode} />
					<span className="absolute top-0 right-0">
						<CardActions tracker={tracker} />
					</span>
					<PacketItem label={t('DEVICE')} value={displayName} isDarkMode={isDarkMode} />
					{parsedDates ? (
						<PacketItem label={t('DATE')} value={_.get(parsedDates, 'date', '')} isDarkMode={isDarkMode} />
					) : undefined}
					{parsedDates ? (
						<PacketItem label={t('TIME')} value={_.get(parsedDates, 'time', '')} isDarkMode={isDarkMode} />
					) : undefined}
					<Stack
						direction="row"
						spacing={1}
						sx={{
							marginTop: 'auto !important'
						}}
					>
						<PacketItem
							label={t('SPEED')}
							value={`${_.get(tracker, 'packet.SPEED', '0')} km/h`}
							variant="col"
							isDarkMode={isDarkMode}
						/>
						<PacketItem
							label={t('IGNITION')}
							value={_.get(tracker, 'packet.IGNITION_ON', false) ? t('OFF') : t('ON')}
							variant="col"
							isDarkMode={isDarkMode}
						/>
						<PacketItem
							label={t('BATTERY')}
							value={formatBattery(_.get(tracker, 'packet.VEHICLE_VOLTAGE', 0))}
							variant="col"
							isDarkMode={isDarkMode}
						/>
						{/* 			<PacketItem
							label={t('OPERATOR')}
							value={_.get(tracker, 'packet.RXLEV', 0)}
							variant="col"
							isDarkMode={isDarkMode}
						/> */}
						<PacketItem
							label={t('SIGNAL')}
							value={_.get(tracker, 'packet.SATELLITES', 0)}
							variant="col"
							isDarkMode={isDarkMode}
						/>
					</Stack>
				</div>
			</Paper>
		</div>
	);
}
