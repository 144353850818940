import HookedDateTimePicker from 'app/shared-components/Hooked/HookedDateTimePicker';
import { useGetDateFilterValue } from 'src/utils/DateInputs/helper';
import { TDateInputProps } from 'src/utils/DateInputs/types';


export const EndTimeFilter: React.FC<TDateInputProps> = ({ t, form, startDate, name, initialValue }) => {
	useGetDateFilterValue({ name, initialValue, form });

	return (
		<HookedDateTimePicker
			control={form.control}
			t={t}
			name={name}
			label={t('END_DATE').toString()}
			sx={{ minWidth: 250 }}
			shouldDisableDate={(date) => date.isBefore(startDate)}
			className="w-full sm:w-auto"
		/>
	);
};
