import _ from 'lodash';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { selectCompanyLogo } from 'app/store/company/companySettingsSlice';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';
import Navigation from '../../shared-components/Navigation';
import NotificationPanelToggleButton from '../../shared-components/notificationPanel/NotificationPanelToggleButton';
import UserMenu from '../../shared-components/UserMenu';

function ToolbarLayout3(props) {
	const toolbarTheme = useSelector(selectToolbarTheme);
	const companySettings = useSelector(selectCompanyLogo);

	return (
		<ThemeProvider theme={toolbarTheme}>
			<AppBar
				id="fuse-toolbar"
				className={clsx('flex relative z-20 shadow-md max-w-[100vw]', props.className)}
				color="default"
				style={{ backgroundColor: toolbarTheme.palette.background.paper }}
			>
				<Toolbar className="container max-w-[100vw] overflow-scroll p-0 lg:px-24 min-h-48 md:min-h-76">
					<div className={clsx('flex mx-20 shrink-0 items-center')}>
						<img
							className="w-40 h-40"
							src={_.get(companySettings, 'logoLight40x40', '/assets/images/logo/mar_logo.png')}
							alt="logo"
						/>
					</div>

					<div className="flex flex-1">
						<FuseScrollbars className="flex h-full items-center">
							<Navigation className="w-full" layout="horizontal" dense />
						</FuseScrollbars>
					</div>

					<div className="flex items-center mr-32 gap-8 px-0 h-full">
						<LanguageSwitcher />
						<AdjustFontSize />
						<FullScreenToggle />
						<NotificationPanelToggleButton />
						<UserMenu />
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}

export default ToolbarLayout3;
