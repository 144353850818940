import { TrackerIcon } from 'app/main/map/components/tracker-card-layouts/ControlledIcons';
import { getTrackerClass, isInvalidCoords } from 'app/main/map/mapHelpers';
import { TTracker } from 'app/main/map/types';
import { memo, useEffect } from 'react';
import { Marker as MapMarker, useMap } from 'react-map-gl';
import { packetCoordinates } from 'src/utils/tracker/packetCoordinates';

interface MarkerProps {
	tracker: TTracker;
}

const MarkerComponent: React.FC<MarkerProps> = ({ tracker }) => {
	const { current } = useMap();
	const coords = packetCoordinates(tracker?.packet);

	useEffect(() => {
		if (isInvalidCoords(coords)) return;
		current?.flyTo({ center: coords, zoom: 16 });
	}, [coords, current]);

	if (!tracker || isInvalidCoords(coords)) return null;

	return (
		<MapMarker longitude={coords[0]} latitude={coords[1]}>
			<TrackerIcon
				iconName={tracker?.vehicle?.type?.icon}
				isDarkMode={false}
				trackerClass={getTrackerClass(tracker)}
			/>
		</MapMarker>
	);
};

const Marker = memo(MarkerComponent);
export { Marker };
