import FusePageSimple from '@fuse/core/FusePageSimple';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/system';
import { getUserAbility } from 'app/permissions';
import { defineIntegrationsAbility } from 'app/permissions/integrations-page';
import NoPermission from 'app/shared-components/NoPermission';
import { useTranslation } from 'react-i18next';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { findEntitySchema, FindEntitySFormValues } from './form-schema';

const initialFormValues = {
	search: ''
};

export default function HinovaIntegrationPage() {
	const form = useForm<FindEntitySFormValues>({
		defaultValues: initialFormValues,
		resolver: yupResolver(findEntitySchema)
	});

	const userAbility = getUserAbility();
	const [, { view }] = defineIntegrationsAbility();
	const { t } = useTranslation('integrationsPage');
	return (
		<FormProvider {...form}>
			{userAbility.can('view', 'integrations') ? (
				<FusePageSimple content={<Content />} header={<Header />} sx={{ padding: 4 }} />
			) : (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						height: '100%'
					}}
				>
					<NoPermission t={t} permissionCodes={view} />
				</Box>
			)}
		</FormProvider>
	);
}
