const locale = {
	TITLE: 'Frotas',

	VEHICLE: 'Veículo',
	VEHICLES: 'Veículos',
	VIEW_ALL: 'Ver todos',

	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',

	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',

	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',

	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	ROWS_PER_PAGE: 'Itens por página',

	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	NAME_REQUIRED: 'O campo nome é obrigatório',

	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',

	DELETE_TITLE: 'Deletar frota',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',

	EDIT_TITLE: 'Editando frota',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar esta frota?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar as frotas selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',

	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar as frotaes, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',

	ADD_FLEET: 'Adicionar frotas',
	CREATE_SUCCESS: 'Frota adicionada com sucesso',
	CREATE_ERROR: 'Erro ao adicionar reastreador',
	EDIT_SUCCESS: 'Frota atualizada com sucesso',
	EDIT_ERROR: 'Erro ao atualizar frota',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Frota deletada com sucesso',
	DELETE_ERROR: 'Erro ao deletar frota',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',

	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de frotas',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar frotas',
	NO_PERMISSION_edit: 'Você não possui permissão para editar frotas',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar frotas',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar frotas',

	LOADING: 'Carregando',
	MORE_THAN: 'mais que',
	NO_FLEET_FOUND: 'Nenhuma frota foi encontrada',
	DELETE_MODAL_TITLE: 'Deletar frota',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta frota?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} frotas?',
	EDIT_INFO: 'Editar frota',
	DELETE_INFO: 'Deletar frota',
	DOWNLOAD_INFO: 'Baixar frotas selecionados',
	EDIT_MULTIPLE_INFO: 'Editar frotas selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar frotas selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar frotas',
	EDIT_MULTIPLE_SUCCESS: 'Frotas editadas com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar frotas',

	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',

	// Header

	DOWNLOAD_ALL: 'Baixar todos',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabela',
	CLEAR_FILTERS: 'Limpar filtros',

	VEHICLES_SELECTED: '{{n}} veículos selecionados'
};

export default locale;
