const locale = {
	// Common
	CURRENT_USAGE: 'Current Usage',
	CURRENT_USAGE_RX: 'Download',
	CURRENT_USAGE_TX: 'Upload',
	TRACKER: 'Tracker',
	NOTES: 'Notes',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	PHONE: 'Phone',
	CREATED_AT: 'Created At',
	CREATED_BY: 'Created By',
	BRAND: 'Brand',
	SUPPLIER: 'Supplier',

	// Header
	TITLE: 'Simcards',
	IMPORT_SIMCARDS: 'Import Simcards',
	ADD_SIMCARD: 'Add Simcard',
	ADD: 'Add',
	CONFIRM: 'Confirm',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear Filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	DOWNLOAD_ALL: 'Download All',

	// Required Fields
	TRACKER_REQUIRED: 'The tracker field is required',
	COMPANY_REQUIRED: 'The company field is required',
	ICCID_REQUIRED: 'The ICCID field is required',
	PHONE_REQUIRED: 'The phone field is required',
	BRAND_REQUIRED: 'The brand field is required',
	INVALID_COMPANY: 'Invalid company',

	// Table
	OF: 'of',
	ROWS_PER_PAGE: 'Items per page',
	NO_SIMCARD_FOUND: 'No simcard found',

	// Sidebar
	SELECTED_ITEMS: 'Selected Items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export Selected Items',
	DELETE_SELECTED: 'Delete Selected Items',
	EDIT_SELECTED: 'Edit Selected Items',
	EDIT_TITLE: 'Editing Simcard',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this simcard?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected simcards?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	SELECTED_ITENS: '{{n}} Selected Items',
	EDIT_MULTIPLE_INFO: 'Edit Simcards',
	DELETE_MULTIPLE_INFO: 'Delete Simcards',
	DOWNLOAD_INFO: 'Download Simcards',

	// Modal
	DELETE_MODAL_TITLE: 'Delete Simcard',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this simcard?',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} simcards?',

	// Permissions
	PAGE_NOT_ALLOWED: 'You do not have permission to view this page.',
	MINIMAL_START: 'Ask the Administrator to add the following permissions to your profile:',
	MINIMAL_END: 'Then log out and log back into the system.',

	// Requests
	CREATE_SUCCESS: 'Simcard added successfully',
	CREATE_ERROR: 'Error adding simcard',
	EDIT_SUCCESS: 'Simcard updated successfully',
	EDIT_ERROR: 'Error updating simcard',
	DELETE_SUCCESS: 'Simcard deleted successfully',
	DELETE_ERROR: 'Error deleting simcard',
	MASSIVE_CREATE_SUCCESS: 'Simcards added successfully',
	MASSIVE_CREATE_ERROR: 'Error adding simcard',

	// Bulk
	SHOW_INVALID_ROWS: 'Show invalid rows',
	CLEAR_TABLE: 'Clear table',
	CREATING: 'Creating',
	DESCRIPTION_HEADER:
		"Here you can create simcards in bulk, just upload a XLSX file with the simcards' data, or download the template and fill in with the data of the simcards you want to create, you can open the XLSX file with Excel or another spreadsheet editor.",
	DOWNLOAD_TEMPLATE: 'Download template.',
	IMPORT: 'Import',
	DROPZONE_TEXT: 'Drag and drop the XLSX file here or click to select.',
	REMOVE_ROW: 'Remove rows',

	// 1nce
	RESET_SIMCARD: 'Reset Simcard',
	SIMCARD_ACTIONS: 'Simcard Actions',
	RESET_SIMCARD_CONFIRM: 'Are you sure you want to reset this simcard?',
	RESET_SIMCARD_SUCCESS: 'Simcard reset successfully',
	RESET_SIMCARD_ERROR: 'Error resetting simcard',
	SMS_DETAILS: 'SMS Details',
	RECEIVE_SMS_DETAILS: 'Request details via SMS',
	SMS_DETAILS_SUCCESS: 'SMS details received successfully',
	SMS_DETAILS_ERROR: 'Error receiving SMS details',
	SEND_SMS: 'Send SMS',
	SMS_MESSAGE_REQUIRED: 'The message field is required',
	SMS_MESSAGE: 'Message',
	MESSAGE_ID: 'Message ID',
	SUBMIT_DATE: 'Submission Date',
	DELIVERY_DATE: 'Delivery Date',
	PAYLOAD: 'Payload',
	EXPIRY_DATE: 'Expiry Date',
	SMS_SENT_SUCCESS: 'SMS sent successfully',
	SMS_SEND_ERROR: 'Error sending SMS',
	ADDITIONAL_INFORMATION: 'Additional Information',
	LAST_DELIVERY_ATTEMPT: 'Last delivery attempt',
	STATUS: 'Status',
	EXPIRES_IN: 'Expires in',
	SMS_QUOTA: 'SMS Quota',
	REFRESH: 'Refresh',
	LAST_VOLUME_ADDED: 'Last volume added',
	TRESHOLD_PERCENTAGE: 'Available',
	LAST_STATUS_CHANGE_DATE: 'Last change',
	SMS_QUOTA_DESCRIPTION: 'The SMS count for 1nce simcards is based on the volume of messages sent.',
	PHONE_LENGHT_AND_FORMAT_WARNING:
		'The phone number must have 11 digits, no special caracteres , including the area code, for example: 11999999999',
	SHOW_FILTERS: 'Show Filters',
	CONFIG_TABLE: 'Configure Table',
	FILTER: 'Filter',
	CLEAR_FILTERS: 'Clear Filters',
	ERROR: 'Error',
	SUCCESS: 'Success'
};

export default locale;
