import dayjs from 'dayjs';
import type { DurationUnitType } from 'dayjs/plugin/duration';

type TDayjsFormatDuration = {
    duration: number;
    formatStr?: string;
    unit?: DurationUnitType
}

export default function dayjsFormatDuration({
    duration = 0,
    formatStr,
    unit = 'milliseconds'
}: TDayjsFormatDuration) {
    const dur = dayjs.duration(duration, unit);

    if(!formatStr) {
        if (dur.get('hour') >= 1) {
            return dur.format('HH[h] mm[m]');
        }
        return dur.format('mm[m] ss[s]');
    }

    return dur.format(formatStr);
}