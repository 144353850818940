import {
	useBlockTrackerMutation,
	useCheckBlockCommandStatusQuery,
	useUnblockTrackerMutation
} from 'app/store/api/trackingSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useAppDispatch } from 'app/store/index';
import { TrackerCommandStatus } from 'app/types/tracker-command-status.types';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiError } from 'src/utils/getApiError';
import userHasPermission from 'src/utils/useHasPermission';

export default function useBlockTracker(trackerDID: number) {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('mapPage');

	const userHasBlockCommandPermission = userHasPermission('command.block.status');

	const [blockTracker, { blockLoading }] = useBlockTrackerMutation({
		selectFromResult: (res) => ({ ...res, blockLoading: res?.isLoading })
	});
	const [unblockTracker, { unblockLoading }] = useUnblockTrackerMutation({
		selectFromResult: (res) => ({ ...res, unblockLoading: res?.isLoading })
	});

	const { lastBlockCommand, currentBlockHistory, isBlockCommandLoading } = useCheckBlockCommandStatusQuery(
		trackerDID,
		{
			pollingInterval: 5000,
			selectFromResult: (res) => ({
				currentBlockHistory: _.get(res?.data, 'scheduledCommandHistory.history', []),
				isBlockCommandLoading: res.isFetching || res.isLoading,
				lastBlockCommand: res?.data || null
			}),
			skip: !trackerDID || !userHasBlockCommandPermission
		}
	);

	const lastBlockStatus = _.last(currentBlockHistory)?.status as TrackerCommandStatus;
	const lastBlockTime = _.last(currentBlockHistory)?.executedAt;

	const hasBlockCommand =
		lastBlockStatus !== TrackerCommandStatus.Confirmed && lastBlockStatus !== TrackerCommandStatus.Canceled;

	const handleUnblock = useCallback(async () => {
		try {
			await unblockTracker(trackerDID).unwrap();

			dispatch(
				showMessage({
					message: t('UNBLOCK_TRACKER_SEND_SUCCESS'),
					variant: 'success'
				})
			);
		} catch (error) {
			const apiErr = getApiError(error, t);
			const errMsg = apiErr || t('UNBLOCK_TRACKER_SEND_ERROR');

			dispatch(
				showMessage({
					message: errMsg,
					variant: 'error'
				})
			);
		}
	}, [dispatch, t, trackerDID, unblockTracker]);

	const handleBlock = useCallback(async () => {
		try {
			await blockTracker(trackerDID).unwrap();

			dispatch(
				showMessage({
					message: t('BLOCK_TRACKER_SEND_SUCCESS'),
					variant: 'success'
				})
			);
		} catch (error) {
			const apiErr = getApiError(error, t);
			const errMsg = apiErr || t('BLOCK_TRACKER_SEND_ERROR');

			dispatch(
				showMessage({
					message: errMsg,
					variant: 'error'
				})
			);
		}
	}, [blockTracker, dispatch, t, trackerDID]);

	return {
		unblock: handleUnblock,
		block: handleBlock,
		blockLoading,
		unblockLoading,
		isBlockCommandLoading,
		lastBlockStatus,
		lastBlockTime,
		hasBlockCommand,
		lastBlockCommand
	};
}
