import i18n from 'src/i18n';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';
import PermissionNotFound from './PermissionNotFound';

i18n.addResourceBundle('en', 'permissionNotFound', en)
i18n.addResourceBundle('pt', 'permissionNotFound', pt)
i18n.addResourceBundle('sp', 'permissionNotFound', sp)

const PermissionNotFoundConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				header: {
					display: false,

				},
			}
		}
	},
	routes: [
		{
			path: '/permission-not-found',
			element: <PermissionNotFound />,
		}
	]
};

export default PermissionNotFoundConfig;

