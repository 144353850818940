import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const PanelsPage = lazyRetry(() => import('./Panels'));
const PanelBuilder = lazyRetry(() => import('./PanelBuilder'));

i18next.addResourceBundle('en', 'panelsPage', en);
i18next.addResourceBundle('sp', 'panelsPage', sp);
i18next.addResourceBundle('pt', 'panelsPage', pt);

export const PANEL_PERMISSIONS = {
	edit: 'admin.panel.edit',
	view: 'admin.panel.view',
	list: 'admin.panel.list',
	new: 'admin.panel.new',
	delete: 'admin.panel.delete',
	minimal: []
};

const PanelsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.panel.list'],
			path: '/panels',
			element: (
				<LazyLoaderSuspense>
					<PanelsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<PanelsPage />
						</LazyLoaderSuspense>
					)
				},
				{
					path: '/panels/new',
					element: (
						<LazyLoaderSuspense>
							<PanelBuilder />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default PanelsConfig;
