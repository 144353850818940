const locale = {
	ICON_REQUIRED: 'O campo ícone é obrigatório',
	DESCRIPTION_REQUIRED: 'O campo descrição é obrigatório',
	ICON: 'Ícone',
	DESCRIPTION: 'Descrição',
	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	DID_MUST_BE_NUMBER: 'O campo DID deve ser um número',
	TRACKER_TYPE_REQUIRED: 'O campo tipo de permissão é obrigatório',
	DID_REQUIRED: 'O campo DID é obrigatório',
	NOTES: 'Notas',
	VEHICLE: 'Rastreado',
	TRACKER_TYPE: 'Tipo de permissão',
	TITLE: 'Permissões',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfis',
	PROFILE: 'Perfil',
	USERS: 'Usuários',
	USER: 'Usuário',
	BIRTHDAY: 'Data de nascimento',
	PASSWORD: 'Senha',
	CPASSWORD: 'Confirmar senha',
	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts acessíveis',
	ADDRESS: 'Endereço',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Telefone',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	ROWS_PER_PAGE: 'Itens por página',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	PHONE_REQUIRED: 'O campo telefone é obrigatório',
	EMAIL_REQUIRED: 'O campo email é obrigatório',
	ADDRESS_REQUIRED: 'O campo endereço é obrigatório',
	TYPE_REQUIRED: 'O campo tipo de cliente é obrigatório',
	COLUMN_NAME: 'Nome',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Data de nascimento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Criado por',
	COLUMN_CREATED_AT: 'Criado em',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Endereço',
	COLUMN_PHONE: 'Telefone',
	NAME: 'Nome',
	CODE: 'Código',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar permissão',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando permissão',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar esta permissão?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar as permissões selecionadas?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar as permissões, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_USER: 'Adicionar permissão',
	CREATE_SUCCESS: 'Permissão adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar permissão',
	EDIT_SUCCESS: 'Permissão atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar permissão',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Permissão deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar permissão',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de permissões',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar permissões',
	NO_PERMISSION_edit: 'Você não possui permissão para editar permissões',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar permissões',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar permissões',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'Email não encontrado',
	NO_BIRTHDAY: 'Data de nascimento não encontrada',
	LOADING: 'Carregando',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
	PASSWORD_MIN_LENGTH: 'A senha deve ter no mínimo 8 caracteres',
	PASSWORDS_MUST_MATCH: 'As senhas devem ser iguais',
	PASSWORD_MIN_LOWERCASE: 'A senha deve ter no mínimo 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'A senha deve ter no mínimo 1 letra maiúscula',
	PASSWORD_MIN_NUMBERS: 'A senha deve ter no mínimo 1 número',
	PASSWORD_MIN_SYMBOLS: 'A senha deve ter no mínimo 1 caractére especial',
	MORE_THAN: 'mais que',
	NO_USER_FOUND: 'Nenhuma permissão foi encontrada',
	DELETE_MODAL_TITLE: 'Deletar permissão',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta permissão?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} permissões?',
	EDIT_INFO: 'Editar permissão',
	DELETE_INFO: 'Deletar permissão',
	DOWNLOAD_INFO: 'Baixar permissões selecionadas',
	EDIT_MULTIPLE_INFO: 'Editar permissões selecionadas',
	DELETE_MULTIPLE_INFO: 'Deletar permissões selecionadas',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar permissões',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar Senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS: 'Permissões editadas com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar permissões',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem meno que 8MB',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	CODE_REQUIRED: 'O campo Código é obrigatório',
};

export default locale;
