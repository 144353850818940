import { Box, createSvgIcon, Stack, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

type TLabeledIcon = {
	label: ReactNode;
	icon: ReactNode;
	iconValue?: ReactNode;
};

type TIcon = {
	path: string;
	displayName: string;
};

export const Icon = ({ path, displayName }: TIcon) => createSvgIcon(path, displayName);
export const Label = ({ children, ...props }: TypographyProps) => (
	<Typography
		sx={{
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '12px',
			lineHeight: '24px'
		}}
		{...props}
	>
		{children}
	</Typography>
);
export const IconValue = ({ children, ...props }: TypographyProps) => (
	<Typography
		sx={{
			fontStyle: 'normal',
			fontWeight: 600,
			fontSize: '16px',
			lineHeight: '24px'
		}}
		{...props}
	>
		{children}
	</Typography>
);

export const LabeledIcon = ({ label, icon, iconValue }: TLabeledIcon) => (
	<Stack direction="column" spacing={0} height="100%">
		{label}
		{iconValue ? (
			<Stack direction="row" spacing={1} justifyContent="start" alignItems="center">
				<div>{icon}</div>
				{iconValue}
			</Stack>
		) : (
			<Box
				sx={{
					flexGrow: 1,
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center'
				}}
			>
				{icon}
			</Box>
		)}
	</Stack>
);
