import { BatteryStatus } from 'app/types/battery-status.types';
import _ from 'src/@lodash/@lodash';

export default function formatBattery(vehicleVoltage: number, BATTERY_STATUS?: string | BatteryStatus): string {
	if (!_.isUndefined(BATTERY_STATUS)) {
		return BATTERY_STATUS;
	}
	if (typeof vehicleVoltage !== 'number') return 'UNAVAILABLE';
	const voltage = vehicleVoltage / 1000;
	return `${Number.parseFloat(voltage.toFixed(1))} V`;
}
