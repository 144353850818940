import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import _ from 'lodash';

import { FuseSettingsProps } from '@fuse/core/FuseSettings';
import storageService from 'app/services/storage';
import { ICompanyLogo, ICompanyThemeResponse } from 'app/types/company.types';
import { IToken } from 'app/types/token.types';
import { TUserThemeResponse } from 'app/types/user.type';
import jwtDecode from 'jwt-decode';
import { isTokenValid, parseUserData, setFaviconAndTitle } from 'src/utils/utils';
import { setCompanyFuseSettings, setCompanyLogo } from '../company/companySettingsSlice';
import { changeFuseSettings } from '../fuse/settingsSlice';
import { updateUserData, updateUserLoading } from '../user/userSlice';
import { apiSlice } from './apiSlice';
import type { IApiPagination, IRealtimeSettings, IRealtimeSettingsPayload } from './types';
import { usersSlice } from './userSlice';

export const DEFAULT_COMPANY_LOGO: ICompanyLogo = {
	appName: 'Do Telematics',
	home: '/realtime',
	trackerLayout: 'primary',
	logoUrl: '/assets/images/logo/logoUrl.png',
	logoDark40x40: '/assets/images/logo/logoDark40x40.png',
	logoDark200x40: '/assets/images/logo/logoDark200x40.png',
	logoLight40x40: '/assets/images/logo/logoLight40x40.png',
	logoLight200x40: '/assets/images/logo/logoLight200x40.png'
};

const settingsAdapter = createEntityAdapter<IRealtimeSettings>({
	selectId: (setting) => setting._id
});
const initialSettingsState = settingsAdapter.getInitialState();

export const settingsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getRealtimeSettings: builder.query<EntityState<IRealtimeSettings>, string>({
			query: () => '/settings/realtime?page=1&limit=500&sortKey=createdAt&sortValue=desc',
			transformResponse: (responseData: IApiPagination<IRealtimeSettings>) => {
				const filtered = _.filter(responseData.docs, (p) => !p.ttl);
				return settingsAdapter.setAll(initialSettingsState, filtered);
			},
			providesTags: ['Settings']
		}),
		postRealtimeSettings: builder.mutation<IRealtimeSettings, IRealtimeSettingsPayload>({
			query: (payload) => ({
				url: '/settings/realtime',
				method: 'POST',
				body: payload
			}),
			invalidatesTags: ['Settings']
		}),
		deleteRealtimeSettings: builder.mutation<string, string>({
			query: (id) => ({
				url: `/settings/realtime/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Settings']
		}),
		companyTheme: builder.query<ICompanyThemeResponse, string>({
			query: (host) => ({
				url: `/settings/theme/company/${host}`
			}),
			async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
				try {
					const response = await queryFulfilled;
					const companyLogo = _.get(response, 'data.companyLogo', null);
					const companySettings = _.get(response, 'data.settings', null);

					if (companyLogo) {
						dispatch(setCompanyLogo(companyLogo));
						setFaviconAndTitle({ fav: companyLogo.logoUrl, title: companyLogo.appName });
					}

					if (companySettings) {
						dispatch(setCompanyFuseSettings(companySettings));
					}

					if (isTokenValid()) {
						const token = jwtDecode<IToken>(storageService.get('token'));

						const { data: userSettings } = await dispatch(settingsSlice.endpoints.userTheme.initiate());
						const { data: userMe } = await dispatch(usersSlice.endpoints.getUser.initiate(token.userId));

						const parsedUser = parseUserData(userMe);
						dispatch(updateUserData(parsedUser));

						const userThemeSettings = _.get(userSettings, 'settings', null);
						dispatch(changeFuseSettings(userThemeSettings || companySettings));
					} else {
						dispatch(changeFuseSettings(companySettings));
					}
				} catch (err) {
					console.error(err);
				} finally {
					dispatch(updateUserLoading(false));
				}
			}
		}),
		userTheme: builder.query<TUserThemeResponse, void>({
			query: () => ({
				url: `/settings/theme/user`
			})
		}),
		updateOrCreateUserTheme: builder.mutation<void, FuseSettingsProps>({
			query: (settings) => ({
				url: `/settings/theme/user`,
				method: 'POST',
				body: {
					settings
				}
			})
		})
	})
});

export const {
	selectAll: selectAllSettings,
	selectById: selectSettingById,
	selectIds: selectSettingsIds,
	selectEntities: selectSettingsEntities
} = settingsAdapter.getSelectors();

export const {
	usePostRealtimeSettingsMutation,
	useGetRealtimeSettingsQuery,
	useDeleteRealtimeSettingsMutation,
	useCompanyThemeQuery,
	useLazyUserThemeQuery,
	useUpdateOrCreateUserThemeMutation
} = settingsSlice;
