const locale = {
	TITLE: 'Contactos',

	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	OF: 'de',
	EDIT: 'Editar',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',

	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	WHATSAPP_NOT_FOUND: 'El número de WhatsApp no es válido',

	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',

	ROWS_PER_PAGE: 'Elementos por página',

	COMPANY_REQUIRED: 'El campo empresa es obligatorio',
	USER_REQUIRED: 'El campo usuario es obligatorio',
	NAME_REQUIRED: 'El campo nombre es obligatorio',
	EMAIL_INVALID: 'El campo de correo electrónico no es válido',
	WHATSAPP_REQUIRED: 'El campo WhatsApp es obligatorio',

	NAME: 'Nombre',
	USER: 'Usuario',
	CREATED_AT: 'Creado el',
	CREATED_BY: 'Creado por',

	ADD_CONTACTS: 'Agregar contactos',
	CREATE_SUCCESS: 'Contacto agregado correctamente',
	CREATE_ERROR: 'Error al agregar contacto',
	EDIT_SUCCESS: 'Contacto actualizado correctamente',
	EDIT_ERROR: 'Error al actualizar contacto',
	DELETE_SUCCESS: 'Contacto eliminado correctamente',
	DELETE_ERROR: 'Error al eliminar contacto',
	NO_CONTACT_FOUND: 'No se encontraron contactos',

	LOADING: 'Cargando',
	DELETE_MODAL_TITLE: 'Eliminar contacto',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro de que quieres eliminar este contacto?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro de que quieres eliminar {{n}} contactos?',
	EDIT_INFO: 'Editar contacto',
	DELETE_INFO: 'Eliminar contacto',
	DOWNLOAD_INFO: 'Descargar contactos seleccionados',
	EDIT_MULTIPLE_INFO: 'Editar contactos seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar contactos seleccionados',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar contactos',
	EDIT_MULTIPLE_SUCCESS: 'Contactos editados correctamente',
	EDIT_MULTIPLE_ERROR: 'Error al editar contactos',
	PAGE_NOT_ALLOWED: 'No tienes permiso para ver esta página.',

	CONTACT_INFO: 'Al registrar una regla de notificación, los contactos elegidos deben pertenecer a la misma empresa.'
};

export default locale;
