import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type BlockCommandHistoryAbility = PureAbility<AbilityTuple<'blockCommandHistory'>, MatchConditions>;

const LIST_BLOCK_COMMAND_HISTORY_PERMISSIONS = ['reports.analytic.list'];

const VIEW_BLOCK_COMMAND_HISTORY_PERMISSIONS = ['reports.analytic.view'];

export const defineBlockCommandHistoryAbility = () => {
    const { can, build } = new AbilityBuilder<BlockCommandHistoryAbility>(PureAbility);

    const missingPermissions: MissingPermissions = {
        view: getMissingPermissions(VIEW_BLOCK_COMMAND_HISTORY_PERMISSIONS),
        create: [],
        edit: [],
        delete: [],
        list: getMissingPermissions(LIST_BLOCK_COMMAND_HISTORY_PERMISSIONS)
    };

    if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
        defineAdminAbility('blockCommandHistory', can);
        return [build(), missingPermissions] as const;
    }

    if (userHasPermission(VIEW_BLOCK_COMMAND_HISTORY_PERMISSIONS)) can('view', 'blockCommandHistory');
    if (userHasPermission(LIST_BLOCK_COMMAND_HISTORY_PERMISSIONS)) can('list', 'blockCommandHistory');

    return [build(), missingPermissions] as const;
};
