import { selectTrackerById, useGetRealtimeByDIDQuery, useGetRealtimeQuery } from 'app/store/api/trackingSlice';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { POLLING_INTERVAL } from '../MapConfig';

export default function useTracker(trackerId: string) {
	const [searchParams] = useSearchParams();
	const filterId = searchParams.get('filterId') || '';

	const { currentTracker, isRealtimeLoading } = useGetRealtimeQuery(filterId, {
		selectFromResult: ({ data, ...res }) => ({
			currentTracker: data && trackerId ? selectTrackerById(data, trackerId) : null,
			isRealtimeLoading: res.isFetching || res.isLoading
		}),
		pollingInterval: POLLING_INTERVAL
	});

	const trackerDID = _.get(currentTracker, 'did', null);

	const { isFetching, isLoading } = useGetRealtimeByDIDQuery(
		{ did: trackerDID, filterId },
		{ pollingInterval: 10000, skip: !trackerDID }
	);

	const isRealtimeBlocked = _.get(currentTracker, 'packet.IS_LOGICALLY_BLOCKED', false);
	const isRealtimeWaiting = _.get(currentTracker, 'packet.IS_WAITING_BLOCK', false);

	return {
		trackerDID,
		currentTracker,
		isRealtimeBlocked,
		isRealtimeWaiting,
		isRealtimeLoading,
		isFetching: isFetching || isLoading
	};
}
