import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import * as yup from 'yup';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedSelect from 'app/shared-components/Hooked/HookedSelect';
import HookedVirtualizedSelect from 'app/shared-components/Hooked/HookedVirtualizedSelect';
import { useGetAllTrackersQuery } from 'app/store/api/trackersSlice';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import { selectToDoFilters, updateToDoFilters } from 'app/store/monitoring';

type TSeverity = { name: string; value: 1 | 2 | 3 };

const query = { limit: 1000, page: 1 };

const IdAndNameYup = yup
	.object()
	.shape({ _id: yup.string(), name: yup.string(), did: yup.number().optional(), value: yup.number().optional() })
	.nullable();

const formSchema = yup.object({
	selectedSeverity: IdAndNameYup.optional(),
	selectedTracker: IdAndNameYup.optional()
});
type TFormModal = yup.InferType<typeof formSchema>;

export default function ToDoFilters() {
	const dispatch = useAppDispatch();
	const selectedFilters = useAppSelector(selectToDoFilters);
	const { t } = useTranslation('monitoringPage');

	const severitiesValues: TSeverity[] = useMemo(
		() => [
			{ name: t('LOW_SEVERITY'), value: 1 },
			{ name: t('MEDIUM_SEVERITY'), value: 2 },
			{ name: t('HIGH_SEVERITY'), value: 3 }
		],
		[t]
	);
	const { trackers, isLoadingTrackers } = useGetAllTrackersQuery(query, {
		selectFromResult: ({ data, isLoading }) => {
			return {
				trackers: data?.docs ?? [],
				isLoadingTrackers: isLoading
			};
		}
	});

	const { control, handleSubmit } = useForm<TFormModal>({
		resolver: yupResolver(formSchema),
		defaultValues: {
			selectedSeverity: selectedFilters.severity
				? _.find(severitiesValues, { value: selectedFilters.severity })
				: null,
			selectedTracker: selectedFilters.did ? _.find(trackers, { did: selectedFilters.did }) : null
		},
		mode: 'all'
	});

	const handleApplyFilters = (data: TFormModal) => {
		dispatch(
			updateToDoFilters({
				severity: data.selectedSeverity?.value,
				did: data.selectedTracker?.did
			})
		);
		dispatch(closeDialog());
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				maxWidth: '100%',
				gap: 1
			}}
			component="form"
			onSubmit={handleSubmit(handleApplyFilters)}
		>
			<HookedSelect
				control={control}
				name="selectedSeverity"
				label={t('SEVERITY')}
				options={severitiesValues}
				optionLabel="name"
				placeholder={t('NO_FILTER')}
				fullWidth
				t={t}
				size="small"
				maxListHeight={150}
			/>
			<HookedVirtualizedSelect
				control={control}
				options={trackers}
				optionLabel="name"
				name="selectedTracker"
				placeholder={t('NO_FILTER')}
				label={t('TRACKER')}
				loading={isLoadingTrackers}
				fullWidth
				t={t}
				size="small"
				maxListHeight={150}
			/>
			<Box
				sx={{
					width: '100%'
				}}
			>
				<AppButton variant="contained" color="secondary" type="submit" fullWidth>
					{t('APPLY')}
				</AppButton>
			</Box>
		</Box>
	);
}
