import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, closestCorners } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Box } from '@mui/material';
import _ from 'lodash';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import {
	monitoringAlertsAdapter,
	monitoringAlertsSlice,
	useUpdateAlertMutation
} from 'app/store/api/monitoringAlertsSlice';
import { useAppDispatch } from 'app/store/index';
import { selectBothFilters, selectDraggedAlert, setDraggedAlert, setSelectedAlert } from 'app/store/monitoring';
import { TMonitoringAlert } from '../types';
import OnGoingWrapper from './OnGoingWrapper';
import ToDoWrapper from './ToDoWrapper';
import SingleItemPresentational from './items/SingleItemPresentational';

export default function Content() {
	const dispatch = useAppDispatch();
	const [updateAlert] = useUpdateAlertMutation();
	const selectedFilters = useSelector(selectBothFilters);
	const selectedAlert = useSelector(selectDraggedAlert);

	const handleDragStart = (ev: DragStartEvent) => {
		const containerId = _.get(ev, 'active.data.current.sortable.containerId', null);
		dispatch(setSelectedAlert({ id: ev.active.id as string, column: containerId || null }));
	};

	const handleDragEnd = (ev: DragEndEvent) => {
		if (ev.over) {
			const over: string = _.get(ev, 'over.data.current.sortable.containerId', null);
			const active: string = _.get(ev, 'active.data.current.sortable.containerId', null);
			if (!over) return;
			if (ev.over.id === 'toDoRef' && active === 'onGoing') {
				// dropped at empty toDo column
				updateAlert({ id: ev.active.id, status: 'toDo', overId: ev.over.id });
			} else if (ev.over.id === 'onGoingRef' && active === 'toDo') {
				// dropped at empty onGoing column
				updateAlert({ id: ev.active.id, status: 'onGoing', overId: ev.over.id });
			} else if (over === active) {
				// dropped at the same column were the drag started
				const column = active;
				dispatch(
					monitoringAlertsSlice.util.updateQueryData(
						'getAlertsRealtime',
						selectedFilters[column],
						(draft) => {
							const data = Object.values(draft.entities);
							const activeIndex = _.findIndex(data, (o) => o._id === ev.active.id);
							const overIndex = _.findIndex(data, (o) => o._id === ev.over.id);
							const sorted = arrayMove(data, activeIndex, overIndex);
							monitoringAlertsAdapter.setAll(draft, sorted);
						}
					)
				);
			} else {
				// dropped at a different column from the start one
				updateAlert({ id: ev.active.id, status: over, overId: ev.over.id });
			}
		}
		// in any case clear all
		dispatch(setSelectedAlert(null));
		dispatch(setDraggedAlert(null));
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				paddingX: ['20px', '40px'],
				paddingBottom: ['18px', '32px'],
				overflowY: 'auto',
				overflowX: 'hidden',
				display: 'flex',
				flexDirection: ['column', 'row'],
				flex: 1,
				gap: 2
			}}
		>
			<DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
				<ToDoWrapper />
				<OnGoingWrapper />
				{createPortal(
					<DragOverlay>
						{selectedAlert ? (
							<SingleItemPresentational data={selectedAlert as TMonitoringAlert} />
						) : undefined}
					</DragOverlay>,
					document.body
				)}
			</DndContext>
		</Box>
	);
}
