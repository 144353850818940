import { TGeoFence } from 'app/main/geoFence/types/types';
import { apiSlice } from './apiSlice';

import type { IApiPagination, TPaginatedQuery } from './types';

export interface IGeoFenceQuery extends TPaginatedQuery {
	mode?: 'view' | 'edit' | 'add';
	fenceId?: string;
}

export type IGeoFenceApiQuery = Omit<IGeoFenceQuery, 'mode' | 'fenceId'>;

const geoFenceSlice = apiSlice.injectEndpoints({
	endpoints: ({ query, mutation }) => ({
		paginateGeoFences: query<IApiPagination<TGeoFence>, IGeoFenceApiQuery>({
			query: (params) => ({
				url: '/geofence',
				method: 'GET',
				params
			}),
			providesTags: ['GeoFence']
		}),
		getGeoFences: query<IApiPagination<TGeoFence>, unknown>({
			query: () => ({
				url: '/geofence',
				method: 'GET'
			}),
			providesTags: ['GeoFence']
		}),
		deleteGeoFence: mutation({
			query: (id: string) => ({
				url: `/geofence/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['GeoFence']
		}),

		editGeoFence: mutation({
			query: (geoFence) => ({
				url: `/geofence/${geoFence._id}`,
				method: 'PUT',
				body: geoFence
			}),
			invalidatesTags: ['GeoFence']
		}),

		getGeoFence: query({
			query: (id: string) => ({
				url: `/geofence/${id}`,
				method: 'GET'
			}),
			providesTags: ['GeoFence']
		}),
		createGeoFence: mutation({
			query: (geoFence) => ({
				url: '/geofence',
				method: 'POST',
				body: geoFence
			}),
			invalidatesTags: ['GeoFence']
		})
	})
});

export const {
	useGetGeoFencesQuery,
	useGetGeoFenceQuery,
	useCreateGeoFenceMutation,
	useEditGeoFenceMutation,
	useDeleteGeoFenceMutation,
	usePaginateGeoFencesQuery,
	useLazyPaginateGeoFencesQuery
} = geoFenceSlice;
