import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';

import ColumnName from 'app/shared-components/Table/ColumnName';
import TableArrayCell from 'app/shared-components/Table/TableArrayCell';
import { formatPhoneNumber } from 'src/utils/format-phone-number';
import { TColumnsNames, TUser, TUserColumn } from './types';

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('EMAIL'), columnId: 'email' },
	{ name: t('BIRTHDAY'), columnId: 'birthday' },
	{ name: t('PHONE'), columnId: 'mobile' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('PROFILE'), columnId: 'profile.name' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	...getSearchKeysNames(t),
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('HOSTS'), columnId: 'permittedHosts.host' },
	{ name: t('IS_BLOCKED'), columnId: 'isBlocked' }
];

export const columnsSearchKeys = [
	'name',
	'email',
	'birthday',
	'createdAt',
	'company.name',
	'profile.name',
	'createdBy.name',
	'mobile',
	'address',
	'isBlocked'
];

export const defaultVisibleColumns = [
	'name',
	'email',
	'birthday',
	'createdAt',
	'createdBy.name',
	'company.name',
	'profile.name',
	'mobile',
	'address',
	'permittedHosts.host',
	'isBlocked'
];

export const columnsSortKeys = ['_id', 'name', 'email', 'birthday', 'createdAt', 'address', 'mobile'];

export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export const getColumns = (t: TFunction, filteredColumns: string[]): TUserColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row: TUser) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:person" />,
		selector: (row: TUser) => row.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'email',
		name: <ColumnName name={t('EMAIL')} icon="material-outline:alternate_email" />,
		selector: (row: TUser) => row.email,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('email', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		cell: (row) => <TableArrayCell data={row.company} displayProp="name" columnDisplayName={t('COMPANIES')} />,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'isBlocked',
		name: <ColumnName name={t('IS_BLOCKED')} icon="material-outline:lock" />,
		cell: (row) => (row?.isBlocked ? t('YES') : t('NO')),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('isBlocked', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'mobile',
		name: <ColumnName name={t('PHONE')} icon="material-outline:phone" />,
		selector: (row) => formatPhoneNumber(row?.mobile),
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('mobile', filteredColumns)
	},

	{
		minWidth: '200px',
		id: 'permittedHosts.host',
		name: <ColumnName name={t('HOSTS')} icon="material-outline:link" />,
		cell: (row) => <TableArrayCell data={row.permittedHosts} displayProp="host" columnDisplayName={t('HOSTS')} />,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('permittedHosts.host', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'profile.name',
		name: <ColumnName name={t('PROFILE')} icon="material-outline:badge" />,
		cell: (row) => row?.profile?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('profile.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'address',
		name: <ColumnName name={t('ADDRESS')} icon="material-outline:location_on" />,
		selector: (row) => row?.address,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('address', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'birthday',
		name: <ColumnName name={t('BIRTHDAY')} icon="material-outline:cake" />,
		selector: (row) => (row.birthday ? dayjs(row.birthday).format('DD/MM/YYYY') : ''),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('birthday', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:calendar_today" />,
		selector: (row: TUser) => (row.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY') : ''),
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row: TUser) => row.createdBy?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	}
];
