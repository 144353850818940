import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const EventsReportPage = lazyRetry(() => import('./EventsReportPage'));

i18next.addResourceBundle('en', 'eventsReportPage', en);
i18next.addResourceBundle('sp', 'eventsReportPage', sp);
i18next.addResourceBundle('pt', 'eventsReportPage', pt);

const EventsReportConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['reports.event.list'],
			path: '/reports/events',
			element: (
				<LazyLoaderSuspense>
					<EventsReportPage />
				</LazyLoaderSuspense>
			),
			children: [{ path: '/reports/events/:id' }]
		}
	]
};

export default EventsReportConfig;
