import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useAppDispatch } from 'app/store/index';
import { useTranslation } from 'react-i18next';

export const GpsErrorPopup: React.FC = () => {
	const { t } = useTranslation('mapPage');
	const dispatch = useAppDispatch();

	const handleClose = () => dispatch(closeDialog());

	return (
		<DialogWrapper
			isSmall
			onClose={handleClose}
			title={t('WRONG_POSITION')}
			content={t('WRONG_POSITION_POPUP_TEXT')}
		/>
	);
};
