import _ from 'lodash';
import { TFunction } from 'react-i18next';

export const getApiError = (error: unknown, t: TFunction) => {
	const hasCode = _.has(error, 'data.code');

	if (hasCode) {
		const code = _.get(error, 'data.code');
		return t(code, { ns: 'exceptionErrors' });
	}

	return null;
};
