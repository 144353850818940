import { ElectricBoltOutlined, LockOpenOutlined, LockOutlined, SpeedOutlined } from '@mui/icons-material';

import { Stack, StackProps } from '@mui/material';
import { Information } from 'app/shared-components/Information';
import { IgnitionIcon } from 'app/shared-components/tracker-icons';
import { BatteryIcon } from 'app/shared-components/tracker-icons/BatteryIcon';
import { BatteryStatus } from 'app/types/battery-status.types';

import { useTranslation } from 'react-i18next';
import formatBattery from 'src/utils/formatBattery';
import { formatSpeed } from 'src/utils/formatSpeed';

interface TrackerDataProps extends StackProps {
	hasBatteryStatus: boolean;
	batteryStatus?: BatteryStatus;
	vehicleVoltage: number;
	ignition: boolean;
	speed: number;
	isBlocked: boolean;
	canBeBlocked: boolean;
	isRealtimeWaiting: boolean;
	blockComponent?: React.ReactNode;
}

export const TrackerData: React.FC<TrackerDataProps> = ({
	hasBatteryStatus,
	batteryStatus,
	vehicleVoltage,
	ignition,
	speed,
	isBlocked,
	canBeBlocked,
	blockComponent,
	...props
}) => {
	const { t } = useTranslation('mapPage');

	return (
		<Stack direction="row" alignItems="center" px={2} pb={2} gap={2} {...props}>
			<Information icon={SpeedOutlined} variant="large" label={t('SPEED')} value={formatSpeed(speed)} />
			<Information
				customIcon={
					hasBatteryStatus ? (
						<BatteryIcon batteryStatus={batteryStatus} />
					) : (
						<ElectricBoltOutlined sx={{ fontSize: '12px' }} />
					)
				}
				variant="large"
				label={t('BATTERY')}
				value={t(formatBattery(vehicleVoltage, batteryStatus))}
			/>
			<Information
				customIcon={<IgnitionIcon on={ignition} />}
				variant="large"
				label={t('IGNITION_ON')}
				value={ignition ? t('YES') : t('NO')}
			/>
			{canBeBlocked && (
				<Information
					iconProps={{ color: isBlocked ? 'error' : 'success' }}
					icon={isBlocked ? LockOutlined : LockOpenOutlined}
					variant="large"
					label={t('BLOCKED')}
					value={isBlocked ? t('YES') : t('NO')}
				/>
			)}
			{blockComponent}
		</Stack>
	);
};
