import {
	Autocomplete,
	CircularProgress,
	Icon,
	InputAdornment,
	TextField,
	TextFieldProps,
	Typography
} from '@mui/material';
import _ from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import { LightTooltip } from './HookedSelect';

type THookedChipSelect<T> = {
	options: T[];
	optionLabel: string;
	label: string;
	placeholder?: string;
	loading?: boolean;
	limitText?: (_n: number) => string;
	fullWidth?: boolean;
	isDisabled?: boolean;
	t: TFunction;
	color?: 'primary' | 'secondary';
	size?: 'small' | 'medium';
	groupBy?: string;
	isMultiple?: boolean;
	maxListHeight?: number;
	optionKey?: string;
	infoText?: string;
	onChangeCallback?: (_data: T[]) => void;
	customOptionEqual?: (_option, _value) => boolean;
	withTooltip?: boolean;
	noHelperText?: boolean;
};

type TProps<F, T> = UseControllerProps<F> & TextFieldProps & THookedChipSelect<T>;

export default function HookedChipSelect<F, T>({
	options = [],
	optionLabel,
	label,
	placeholder,
	loading = false,
	fullWidth = false,
	isDisabled = false,
	limitText = undefined,
	name,
	size = 'small',
	color = 'secondary',
	groupBy = undefined,
	t,
	control,
	isMultiple = true,
	required = false,
	maxListHeight = undefined,
	optionKey = '_id',
	infoText = undefined,
	onChangeCallback = undefined,
	customOptionEqual = _.isEqual,
	withTooltip = false,
	noHelperText = false
}: TProps<F, T>) {
	const {
		field,
		formState: { errors }
	} = useController({
		name,
		control
	});

	const handleChange = (_, val: T[]) => {
		field.onChange(val);
		if (typeof onChangeCallback === 'function') {
			onChangeCallback(val);
		}
	};

	// Commented out because it was causing issues with the form validation
	// useEffect(() => {
	// 	if (_.isEmpty(field.value) && _.size(options) === 1) {
	// 		field.onChange([options[0]]);
	// 	}
	// }, [options, field]);

	const helperText = _.has(errors, name) ? t(_.get(errors, [name, 'message']) as string) : ' ';

	const renderAutoComplete = () => (
		<Autocomplete
			color={color}
			ChipProps={{ color }}
			multiple={isMultiple}
			disablePortal
			openOnFocus
			id={name}
			size={size}
			options={options}
			loading={loading}
			limitTags={0}
			getOptionLabel={(option) => _.toString(option[optionLabel]) || option._id}
			renderOption={(props, option) => (
				<li {...props} key={option[optionKey] ?? option[optionLabel]}>
					{option[optionLabel]}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					InputLabelProps={{ required }}
					variant="outlined"
					label={label}
					placeholder={placeholder}
					fullWidth={fullWidth}
					name={name}
					helperText={noHelperText ? undefined : helperText}
					error={_.has(errors, name)}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<InputAdornment position="start">
										<CircularProgress color="secondary" size={18} />
									</InputAdornment>
								) : (
									params.InputProps.endAdornment
								)}
							</>
						)
					}}
				/>
			)}
			onBlur={field.onBlur}
			value={field.value}
			onChange={handleChange}
			ref={field.ref}
			fullWidth={fullWidth}
			disabled={isDisabled}
			isOptionEqualToValue={customOptionEqual}
			getLimitTagsText={limitText}
			disableCloseOnSelect
			groupBy={groupBy ? (opt) => opt[groupBy] : undefined}
			ListboxProps={{
				style: {
					maxHeight: maxListHeight,
					overflowY: 'auto'
				}
			}}
		/>
	);

	return withTooltip ? (
		<LightTooltip
			title={
				<Typography sx={{ display: 'flex', alignItems: 'center' }}>
					<Icon sx={{ color: 'warning.main', mr: 1 }}>info_outlined</Icon>
					{t(helperText)}
				</Typography>
			}
			placement="top"
			TransitionProps={{ timeout: 500 }}
			disableHoverListener
			open={helperText !== ' '}
		>
			{renderAutoComplete()}
		</LightTooltip>
	) : (
		renderAutoComplete()
	);
}
