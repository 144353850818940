import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const RulerPage = lazyRetry(() => import('./Ruler'));

i18next.addResourceBundle('en', 'rulerPage', en);
i18next.addResourceBundle('sp', 'rulerPage', sp);
i18next.addResourceBundle('pt', 'rulerPage', pt);

export const RULER_PERMISSIONS = {
	edit: 'admin.ruler.edit',
	view: 'admin.ruler.view',
	list: 'admin.ruler.list',
	new: 'admin.ruler.new',
	delete: 'admin.ruler.delete',
	minimal: []
};

const RulerConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.ruler.list'],
			path: 'ruler',
			element: (
				<LazyLoaderSuspense>
					<RulerPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<RulerPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default RulerConfig;
