import { PureAbility } from '@casl/ability';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { ShareLocation } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { AppAbility } from 'app/permissions/types';
import IconCard from 'app/shared-components/CRUDs/components/ViewRow/IconCard';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import type { TFunction } from 'i18next';
import dayjsFormatDuration from 'src/utils/dayjsFormatDuration';
import { formatDistance } from 'src/utils/formatDistance';
import { TTableColumn } from '../types';

dayjs.extend(duration);

const title = (name: string, icon: string) => (
	<div className="flex flex-row items-center gap-8">
		<FuseSvgIcon size={16} color="inherit">
			{`material-outline:${icon}`}
		</FuseSvgIcon>
		<Typography>{name}</Typography>
	</div>
);

export const getTime = (durationInSeconds: number) =>
	dayjsFormatDuration({
		duration: durationInSeconds,
		formatStr: 'HH:mm:ss',
		unit: 'seconds'
	});

export const getColumnsNames = (t: TFunction) => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('VEHICLE'), columnId: 'vehicle.name' },
	{ name: t('DRIVER'), columnId: 'driver.name' },
	{ name: t('START'), columnId: 'startTime' },
	{ name: t('END'), columnId: 'endTime' },
	{ name: t('DURATION'), columnId: 'durationInSeconds' },
	{ name: t('DISTANCE'), columnId: 'distanceInMeters' },
	{ name: t('START_ADDRESS'), columnId: 'startAddress' },
	{ name: t('END_ADDRESS'), columnId: 'endAddress' }
];

export const getSearchKeys = (t: TFunction) => [
	{ name: t('VEHICLE'), columnId: 'vehicle.name' },
	{ name: t('DRIVER'), columnId: 'driver.name' }
];

export const defaultVisibleColumns = [
	'_id',
	'vehicle.name',
	'driver.name',
	'startTime',
	'endTime',
	'durationInSeconds',
	'startAddress',
	'endAddress'
];

export const searchKeys = ['_id', 'vehicle.name', 'driver.name', 'startTime', 'endTime', 'startAddress', 'endAddress'];

export const sortKeys = [
	'vehicle.name',
	'driver.name',
	'startTime',
	'endTime',
	'durationInSeconds',
	'distanceInMeters'
];

export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export default function getColumns(
	t: TFunction,
	filteredColumns: string[],
	permissions: PureAbility<AppAbility>
): TTableColumn[] {
	const viewTripFunction = (trip: string) => {
		if (permissions.can('view', 'tripsReport')) window.open(`/reports/trips/advanced/${trip}`, '_blank');
	};

	return [
		{
			id: '_id',
			name: title(t('ID'), 'dns'),
			selector: (row) => row._id,
			minWidth: '256px',
			omit: isColumnOmitted('_id', filteredColumns)
		},
		{
			id: 'vehicle.name',
			name: title(t('VEHICLE'), 'directions_car'),
			selector: (row) => row.vehicle?.name,
			sortable: true,
			width: '300px',
			omit: isColumnOmitted('vehicle.name', filteredColumns)
		},
		{
			id: 'driver.name',
			name: title(t('DRIVER'), 'person'),
			selector: (row) => row.driver?.name,
			sortable: true,
			width: '300px',
			omit: isColumnOmitted('driver.name', filteredColumns)
		},
		{
			id: 'startTIme',
			name: title(t('START'), 'today'),
			selector: (row) => (row.startTime ? dayjs(row?.startTime)?.format('DD/MM/YYYY HH:mm:ss') : 'N/A'),
			sortable: true,
			omit: isColumnOmitted('startTime', filteredColumns),
			width: '300px'
		},
		{
			id: 'startAddress',
			name: title(t('START_ADDRESS'), 'location_on'),
			selector: (row) => row.startAddress,
			omit: isColumnOmitted('startAddress', filteredColumns),
			width: '300px'
		},
		{
			id: 'endTime',
			name: title(t('END'), 'event'),
			selector: (row) => (row.endTime ? dayjs(row?.endTime)?.format('DD/MM/YYYY HH:mm:ss') : 'N/A'),
			sortable: true,
			omit: isColumnOmitted('endTime', filteredColumns),
			width: '300px'
		},
		{
			id: 'endAddress',
			name: title(t('END_ADDRESS'), 'location_on'),
			selector: (row) => row.endAddress,

			omit: isColumnOmitted('endAddress', filteredColumns),
			width: '300px'
		},
		{
			id: 'durationInSeconds',
			name: title(t('DURATION'), 'hourglass_bottom'),
			selector: (row) => row.durationInSeconds && getTime(row.durationInSeconds),
			sortable: true,
			omit: isColumnOmitted('durationInSeconds', filteredColumns),
			width: '300px'
		},
		{
			id: 'distanceInMeters',
			name: title(t('DISTANCE'), 'timeline'),
			selector: (row) => row.distanceInMeters && formatDistance(row.distanceInMeters),
			sortable: true,
			omit: isColumnOmitted('distanceInMeters', filteredColumns),
			width: '300px'
		},
		{
			id: 'open_history',
			name: title(t('OPEN_ADVANCED'), 'share_location'),
			cell: (row) => (
				<Box sx={{ cursor: 'pointer' }} onClick={() => viewTripFunction(row?._id)}>
					<IconCard size={36}>
						<ShareLocation color="secondary" />
					</IconCard>
				</Box>
			),
			button: true,
			grow: 1.5,
			omit: !permissions.can('view', 'tripsReport'),
			width: '300px',
			center: true,
			sortable: false
		}
	];
}
