import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TContact, TSidebarMode } from 'app/main/contacts/types';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TContact[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
};
const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view'
};

const contactsCrudSlice = createSlice({
	name: 'contactsCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TContact[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSelectedRows, setIsSidebarOpen, setSidebarMode, resetSidebarState } = contactsCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.contactsCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.contactsCrud.sidebarMode;
export const selectSelectedRows = (state: RootState) => state.contactsCrud.selectedRows;

export default contactsCrudSlice.reducer;
