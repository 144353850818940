import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { apiSlice } from './apiSlice';
import {
	IApiPagination,
	TCreateProfilePayload,
	TEditMultipleProfilesPayload,
	TEditProfilePayload,
	TPaginatedQuery,
	TProfile
} from './types';

export function getStringQuery(paginatedData: TPaginatedQuery, route: string) {
	const queryArgs = {
		page: `${paginatedData.page ?? 1}`,
		limit: `${paginatedData.limit ?? 100}`,
		search: paginatedData.search || null,
		searchKey: paginatedData.searchKey || null,
		sortKey: paginatedData.sortKey || null,
		sortValue: paginatedData.sortValue || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));
	return `${route}?${query.toString()}`;
}

export const profileSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllProfiles: builder.query<IApiPagination<TProfile>, TPaginatedQuery>({
			query: (paginatedData) => getStringQuery(paginatedData, '/profile'),
			providesTags: ['Profile']
		}),
		getProfile: builder.query({
			query: (id: string) => `/profile/${id}`
		}),
		updateProfile: builder.mutation<string, TEditProfilePayload>({
			query: (userData) => ({
				url: `/profile/${userData.id}`,
				method: 'PUT',
				body: userData
			}),
			invalidatesTags: ['Profile']
		}),
		updateMultipleProfiles: builder.mutation<string, TEditMultipleProfilesPayload>({
			query: (userData) => ({
				url: `/profile/list?ids=${userData.ids}`,
				method: 'PUT',
				body: userData
			}),
			invalidatesTags: ['Profile']
		}),
		createProfile: builder.mutation<string, TCreateProfilePayload>({
			query: (data) => ({
				url: '/profile',
				method: 'POST',
				body: data
			}),
			invalidatesTags: ['Profile']
		}),
		deleteProfiles: builder.mutation({
			query: (ids: string) => ({
				url: `/profile/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Profile']
		})
	})
});

export const {
	useGetAllProfilesQuery,
	useDeleteProfilesMutation,
	useCreateProfileMutation,
	useUpdateProfileMutation,
	useUpdateMultipleProfilesMutation
} = profileSlice;
