import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSession, TSidebarMode } from 'app/main/sessions/types';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TSession[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
};

export const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view'
};

const sessionsCrudSlice = createSlice({
	name: 'sessionsCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TSession[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			if (!action.payload) {
				state.selectedRows = [];
				state.sidebarMode = 'view';
				state.isSidebarOpen = false;
			} else {
				state.isSidebarOpen = true;
			}
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setSelectedRows, setIsSidebarOpen, setSidebarMode, resetSidebarState } = sessionsCrudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.sessionsCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.sessionsCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.sessionsCrud.selectedRows;

export default sessionsCrudSlice.reducer;
