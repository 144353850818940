import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

interface InfoBoxProps {
	label: string;
	icon: React.ReactNode;
	children: React.ReactNode;
}

export default function InfoBox({ label, icon, children }: InfoBoxProps) {
	return (
		<Box
			sx={(theme) => ({
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				width: '100%',
				height: '100%',
				borderRadius: 2,
				border: `1px solid${theme.palette.divider}`
			})}
		>
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				width="100%"
				padding={2}
				sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
			>
				<Typography variant="body1" fontWeight={600}>
					{label}
				</Typography>
				{icon}
			</Stack>
			<Stack direction="column" spacing={2} padding={2}>
				{children}
			</Stack>
		</Box>
	);
}
