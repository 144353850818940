import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultVisibleColumns } from 'app/main/reports/historyNew/tableColumns';
import { THistoryPosition } from 'app/main/reports/historyNew/types';
import storageService from 'app/services/storage';
import { RootState } from '..';

interface HistoryState {
	sideBarOpen: boolean;
	selectedRows: THistoryPosition[];
	visibleColumns: string[];
}

const initialState: HistoryState = {
	sideBarOpen: false,
	selectedRows: [],
	visibleColumns: storageService.get('eventReport.visibleColumns') || defaultVisibleColumns
};

const eventReportSlice = createSlice({
	name: 'eventReport',
	initialState,
	reducers: {
		setSideBarOpen(state, action) {
			state.sideBarOpen = action.payload;
		},
		setSelectedRows(state, action) {
			state.selectedRows = action.payload;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('eventReport.visibleColumns', action.payload);
		}
	}
});

export const { setSideBarOpen, setSelectedRows, setVisibleColumns } = eventReportSlice.actions;

export const selectSideBarOpen = (state: RootState) => state.eventsReportSlice.sideBarOpen;
export const selectSelectedRows = (state: RootState) => state.eventsReportSlice.selectedRows;
export const selectVisibleColumns = (state: RootState) => state.eventsReportSlice.visibleColumns as string[];

export default eventReportSlice.reducer;
