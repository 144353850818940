const en = {
	// HEADER
	FILTER: 'Filter',
	FILTER_DESCRIPTION: 'The fields below will display data only from the selected companies.',
	EXPORT: 'Export Data',
	TITLE: 'Packet Sending Report',

	// MODAL
	DATE: 'Date',

	RESET: 'Clear Filters',
	SEARCH: 'Search',

	TRACKER: 'Tracker',
	SELECTED_TRACKERS: '{{n}} Selected Trackers',

	COMPANY: 'Company',
	SELECTED_COMPANIES: '{{n}} Selected Companies',

	VEHICLE: 'Vehicle',
	SELECTED_VEHICLES: '{{n}} Selected Vehicles',

	AT_LEAST_ONE_FILTER: 'At least one filter must be selected',
	START_DATE_REQUIRED: 'Required Field',
	END_DATE_REQUIRED: 'Required Field',

	WEEKLY_METRICS: 'Packets Sent',
	BY_WEEK: 'By Week',

	MONTHLY_METRICS: 'Packets Sent',
	BY_MONTH: 'By Month',

	DAILY_METRICS: 'Packets Sent',
	BY_DAY: 'By Day',

	SELECTED_MONTH: 'Selected Month',
	LAST_MONTH: 'Last Month',

	NO_DATA: 'No data found',
	PACKETS_SENT: 'Packets Sent',

	NO_FILTER_COMPONENT_TEXT: 'No filters selected',
	NO_FILTER_COMPONENT_TEXT_SECONDARY: 'Select one or more filters to view data',

	TRACKERS: 'Trackers',
	BY_STATE: 'By State',

	IDLE: 'Idle',
	MOVING: 'Moving',
	NO_COMM: 'No Communication',
	WRONG_POSITION: 'Wrong Positioning',
	TOWED: 'Towed',
	CONNECTED: 'Connected',
	WITHOUT_VEHICLE: 'Without Vehicle',
	BLOCKED: 'Blocked',

	PACKETS: 'Packets',
	TOTAL_PACKETS_SEND_BY_TRACKERS: 'Total Packets Sent by Trackers',

	TOP_FIVE_TRACKERS: 'Top Five Trackers Sending the Most Packets'
};

export default en;
