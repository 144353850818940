import * as yup from 'yup';

const categorySchema = yup
	.object({
		_id: yup.string(),
		name: yup.string()
	})
	.nullable();

const schema = yup.object({
	title: yup.string().required('TITLE_REQUIRED'),
	description: yup.string().optional(),
	category: yup.array().of(categorySchema),
	url: yup.string().notRequired(),
	videoFile: yup.mixed().notRequired(),
});

export default schema;
