const locale = {
	MONITORING: 'Monitoramento',
	OPENED_OCCURRENCES_N: 'alarmes abertos: {{n}}',
	ONGOING_OCCURRENCES_N: 'alarmes em andamento: {{n}}',
	FINALIZE: 'Finalizar',
	GO_ON: 'Dar andamento',
	FILTERS: 'Filtros',
	CANCEL: 'Cancelar',
	APPLY: 'Aplicar',
	'E.IGN_ON': 'Ignição ligada',
	'E.IGN_OFF': 'Ignição desligada',
	'E.DIN_CHANGED': 'Mudança na entrada digital',
	'E.EXT_POWER_CUT_OFF': 'Corte de alimentação externa',
	'E.PANIC': 'Pânico',
	'E.LOW_BATTERY_VOLTAGE': 'Bateria fraca',
	'E.SHIFT_WARNING': 'Guinchado',
	'E.GPS_ANTENNA_OPEN': 'Antena GPS aberta',
	'E.GPS_ANTENNA_SHORT': 'Antena GPS em curto',
	'E.EXT_POWER_RECONNECTED': 'Energia externa reconectada',
	'E.UNDER_SPEED': 'Velocidade baixa',
	'E.OVER_SPEED': 'Alta velocidade',
	'E.INTO_FENCE': 'Entrou em cerca virtual',
	'E.OUT_FENCE': 'Saída de cerca virtual',
	'E.EXTERNAL_BATTERY_LOW': 'Bateria externa fraca',
	'E.DRIVER_OFF': 'Cartão ou motorista removido',
	'E.DRIVER_ON': 'Cartão ou motorista inserido',
	OPERATED_BY: 'Operado por:',
	OPEN: 'Aberto',
	ON_GOING: 'Em andamento',
	GROUPED_N: '{{n}} agrupados',
	FINALIZE_MODAL_TITLE: 'Finalizar alarme',
	FINALIZE_MODAL_DESCRIPTION: 'Você tem certeza que deseja finalizar este alarme?',
	FINALIZE_MODAL_MULTIPLE_DESCRIPTION: 'Finalizar multiplos alarmes',
	FINALIZE_MODAL_WARNING: 'Esta operação não pode ser desfeita.',
	FINALIZE_MODAL_BRIEF: 'Informe o motivo da finalização.',
	CONFIRM: 'Finalizar',
	FINISHED_EVENTS: 'Alarmes finalizados',
	SUCCESS_ONGOING: 'Alarme movido com sucesso',
	ERROR_ONGOING: 'Erro ao mover o alarme',
	SUCCESS_DONE: 'Alarme finalizado com sucesso',
	ERROR_DONE: 'Erro ao finalizer o alarme',
	NO_FILTER: 'Nenhum filtro',
	OPERATOR: 'Operador',
	GO_REALTIME: 'Ver no mapa',
	SEVERITY: 'Severidade',
	TIMING: 'Tempo',
	TRACKER: 'Rastreador',
	LOW_SEVERITY: 'Mostrar baixa severidade',
	MEDIUM_SEVERITY: 'Mostrar média severidade',
	HIGH_SEVERITY: 'Mostrar alta severidade',
	LAST_HOUR: 'Última hora',
	LAST_N_HOURS: 'Últimas {{n}} horas',
	CLEAR_ALL: 'Limpar filtros',
	SELECT_FILTERS: 'Selecionar filtros',
	EVENT_TYPE: 'Evento',
	GPS_TIME: 'Horário',
	ALTITUDE: 'Altitude',
	BEARING: 'Ângulo',
	SATELLITES: 'Satélites',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	ADDRESS_CITY: 'Cidade',
	ADDRESS_COUNTRY: 'País',
	ADDRESS_POSTCODE: 'CEP',
	ADDRESS_ROAD: 'Rua',
	ADDRESS_STATE: 'Estado',
	ADDRESS_SUBURB: 'Bairro',
	HOURMETER: 'Hourimetro',
	MILEAGE: 'Quilometragem',
	YES: 'Sim',
	NO: 'Não',
	CLEAR: 'Limpar',
	DETAILS: 'Detalhes',
	ALARM: 'Alarme',
	VEHICLE: 'Veículo',
	USER: 'Usuário',
	GROUPS: 'Grupos',
	COMPANY: 'Empresa',
	STATUS: 'Status',
	SPEED: 'Velocidade',
	GPS_FIXED: 'Gps Fixo',
	IGNITION_ON: 'Ignição Ligada',
	IGNITION_OFF: 'Ignição Desligada',
	BATTERY: 'Bateria',
	EV_TYPE: 'Tipo de Evento',
	EV_CODE: 'Código de Evento',
	SELECTED_N: '{{n}} Selecionados',
	GROUP_BY: 'Agrupado por',
	MAP_VIEW: 'Ver no mapa',
	GOOGLE_VIEW: 'Ver no Google Street View',
	GO_ON_ERROR: 'Error ao atualizar status de alarme',
	GO_ON_SUCCESS_N: '{{n}} Alerta(s) atualizados',
	FINALIZE_ERROR: 'Error ao finalizar alarme(s)',
	FINALIZE_SUCCESS_N: '{{n}} Alarme(s) finalizados',
	TO_GOOGLE_MAPS: 'Ver no Google Maps'
};

export default locale;
