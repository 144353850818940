import { FuseSettingsProps } from '@fuse/core/FuseSettings';
import _ from '@lodash';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'app/store/index';

import type { TUserPreferences } from 'app/types/user.type';
import type { TIdName } from '../api/types';

export interface UserProps {
	permissions?: { _id: string; code: string; name: string }[];
	data?: {
		userId?: string;
		displayName?: string;
		photoURL?: string;
		email?: string;
		shortcuts?: string[];
	};
	defaultFilter?: string;
	company?: TIdName[];
	isLoading: boolean;
	preferences?: TUserPreferences;
}

export const setUser = createAsyncThunk('user/setUser', async (user?: UserProps) => user);

export const updateUserSettings = createAsyncThunk(
	'user/updateSettings',
	async (settings: FuseSettingsProps, thunkApi) => {
		const { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState } = thunkApi;
		const { user } = getState();

		const newUser = _.merge({}, user, { settings: { settings } });

		dispatch(updateUserData(newUser));

		return newUser;
	}
);

export const updateUserShortcuts = createAsyncThunk('user/updateShortucts', async (shortcuts: string[], thunkApi) => {
	const { dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState } = thunkApi;
	const { user } = getState();
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(newUser));

	return newUser;
});

export const initialState: UserProps = {
	permissions: [],
	defaultFilter: '',
	data: {
		userId: '',
		displayName: '',
		photoURL: '',
		email: '',
		shortcuts: null
	},
	isLoading: true,
	preferences: {
		baseMap: 'osm',
		routeType: 'routerized',
		isClustered: true
	}
};
const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		userLoggedOut: () => initialState,
		updateUserData: (state, action: PayloadAction<Partial<UserProps>>) => {
			return {
				...state,
				...action.payload
			};
		},
		updateUserDefaultFilter: (state, action: PayloadAction<string>) => {
			state.defaultFilter = action.payload;
		},
		updateUserLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		updateLocalUserPreferences: (state, action: PayloadAction<Partial<TUserPreferences>>) => {
			state.preferences = {
				...state.preferences,
				...action.payload
			};
		}
	}
});

export const { userLoggedOut, updateUserData, updateUserDefaultFilter, updateUserLoading, updateLocalUserPreferences } =
	userSlice.actions;

export const selectUser = (state: RootState) => state.user;
export const selectUserId = (state: RootState) => state.user.data.userId;
export const selectUserLoading = (state: RootState) => state.user.isLoading;
export const selectUserShortcuts = (state: RootState) => state.user.data.shortcuts;
export const selectUserPermissions = (state: RootState) => state.user?.permissions;
export const selectUserDefaultFilter = (state: RootState) => state.user.defaultFilter;
export const selectUserPreferences = (state: RootState) => state.user.preferences;

export default userSlice.reducer;
