import { ArrowBackOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import NoPermission from 'app/shared-components/NoPermission';
import { selectCurrentCourse, selectCurrentLesson, selectCurrentTab } from 'app/store/academy/academySlice';
import { selectSidebarMode, setIsSidebarOpen } from 'app/store/crud/coursesSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ACADEMY_COURSE_PERMISSIONS } from '../../AcademyAppConfig';
import LessonForm from '../Forms/LessonForm/LessonForm';
import FormMode from './FormMode';

export default function Sidebar() {
	const { t } = useTranslation('academyPage');
	const mode = useSelector(selectSidebarMode);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const tab = useSelector(selectCurrentTab);
	const course = useSelector(selectCurrentCourse);
	const lesson = useSelector(selectCurrentLesson);
	const handleClose = () => {
		dispatch(setIsSidebarOpen(false));
		navigate(`/academy/courses`, { replace: true });
	};
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				width: ['100vw', '100%'],
				height: '100%'
			}}
		>
			<Box
				sx={{
					height: '54px',
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					padding: 1,
					marginBottom: 3,
					background: (theme) => theme.palette.secondary.main
				}}
			>
				<IconButton color="primary" onClick={handleClose} size="large">
					<ArrowBackOutlined />
				</IconButton>
			</Box>

			{
				{
					edit:
						tab == 'courses' ? (
							<FormMode course={course} />
						) : (
							<LessonForm onCancel={handleClose} onSuccess={handleClose} lesson={lesson} />
						),
					create:
						tab == 'courses' ? (
							<FormMode course={course} />
						) : (
							<LessonForm onCancel={handleClose} onSuccess={handleClose} />
						),
					noPermission: (
						<NoPermission
							messageType="minimal"
							permissionCodes={ACADEMY_COURSE_PERMISSIONS.minimal}
							imageSrc="/assets/images/bg/sidebar_blur.png"
							t={t}
						/>
					)
				}[mode]
			}
		</Box>
	);
}
