import { createSelector, createSlice } from '@reduxjs/toolkit';
import { notificationsSlice } from 'src/app/store/api/notificationsSlice';
import { RootState } from 'src/app/store/index';

const notificationsStateSlice = createSlice({
	name: 'notifications',
	initialState: {
		total: 0
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addMatcher(notificationsSlice.endpoints.getNotifications.matchFulfilled, (state, action) => {
			state.total = action.payload.totalDocs;
		});
	}
});

export default notificationsStateSlice.reducer;

export const selectTotal = createSelector(
	(state: RootState) => state.notifications.total,
	(total) => total
);
