import { CloseOutlined, VideoFileOutlined } from '@mui/icons-material';
import { Box, FormHelperText, FormLabel } from '@mui/material';
import { Stack } from '@mui/system';
import { setCurrentEditLessonUrl } from 'app/store/academy/academySlice';
import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

import { TFunction } from 'react-i18next';
import { useDispatch } from 'react-redux';

type TLessonVideo<F> = UseControllerProps<F> & { t: TFunction } & {
	videoUrl?: string;
	inputId: string;
	w: number | string;
	h: number | string;
	label?: string;
};
const PreviewVideo = ({ file, setFile, w, h, videoUrl }) => {
	const [img, setVideo] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (videoUrl) {
			setVideo(videoUrl);
			return;
		}
		if (!file) return;

		const url = URL.createObjectURL(file);
		setVideo(url);
	}, [file, videoUrl]);

	const handleClose = () => {
		setVideo(null);
		setFile(null);
		dispatch(setCurrentEditLessonUrl(''));
	};

	return (
		<Box
			sx={{
				width: w,
				height: h,
				cursor: 'pointer',
				backgroundColor: 'transparent',
				border: '1px solid',
				borderColor: 'divider',
				padding: 3,
				borderRadius: 1,
				':hover': {
					borderColor: 'secondary.main'
				},
				position: 'relative'
			}}
		>
			<video
				src={img}
				style={{
					width: '100%',
					height: '100%',
					objectFit: 'contain',
					display: 'block'
				}}
				controls // Add the controls attribute
			>
				<track kind="captions" src="captions.vtt" label="English" />
			</video>
			<CloseOutlined
				sx={{
					position: 'absolute',
					top: -10,
					right: -10,
					cursor: 'pointer'
				}}
				onClick={handleClose}
			/>
		</Box>
	);
};

export default function HookedVideoInput<F>({
	name,
	control,
	t,
	videoUrl = '',
	inputId,
	w,
	h,
	label
}: TLessonVideo<F>) {
	const {
		field,
		formState: { errors }
	} = useController({
		control,
		name
	});
	const helperText = _.has(errors, name) ? t(_.get(errors, [name, 'message'])) : ' ';
	const hasError = _.has(errors, name);
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e?.target?.files) {
			const file = e.target.files[0];
			field.onChange(file);
		}
	};

	return (
		<Stack direction="column" spacing={1}>
			<FormLabel htmlFor={inputId}>
				<input
					accept="video/*"
					id={inputId}
					onChange={handleChange}
					type="file"
					style={{
						display: 'none',
						pointerEvents: field.value ? 'none' : 'auto'
					}}
				/>

				<Box
					sx={{
						width: w,
						height: h,
						cursor: 'pointer',
						display: field.value || videoUrl ? 'none' : 'flex',
						backgroundColor: 'transparent',
						border: '1px solid',
						borderColor: hasError ? 'error.main' : 'divider',
						padding: 3,
						borderRadius: 1,
						':hover': {
							borderColor: hasError ? 'error.main' : 'secondary.main'
						}
					}}
				>
					<VideoFileOutlined color={hasError ? 'error' : 'secondary'} />
				</Box>
			</FormLabel>
			{(field.value || videoUrl) && (
				<PreviewVideo file={field.value} setFile={field.onChange} videoUrl={videoUrl} w={w} h={h} />
			)}

			<FormHelperText error={hasError}>{hasError ? t(helperText) : ''}</FormHelperText>
		</Stack>
	);
}
