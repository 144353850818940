import { createSlice } from '@reduxjs/toolkit';
import storageService from 'app/services/storage';

interface IInitialState {
	visibleColumns: string[];
}

export const DEFAULT_VISIBLE_COLUMNS = [
	'geofence.name',
	'date',
	'company.name',
	'vehicle.name',
	'tracker.did',
	'type',
	'driver.name'
];

const initialState: IInitialState = {
	visibleColumns: storageService.get('geo-fence-report-visible-columns') || DEFAULT_VISIBLE_COLUMNS
};

const geoFenceReportSlice = createSlice({
	name: 'geoFenceReportSlice',
	initialState,
	reducers: {
		setVisibleColumns: (state, action) => {
			state.visibleColumns = action.payload;
			storageService.set('geo-fence-report-visible-columns', action.payload);
		}
	}
});

export const { setVisibleColumns } = geoFenceReportSlice.actions;

export default geoFenceReportSlice.reducer;
