import { ArrowRightOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TScheduledHistoryCommand } from '../types';
import StatusChip from './StatusChip';

interface StatusLineProps {
	history: TScheduledHistoryCommand['history'];
}

export default function StatusLine({ history = [] }: StatusLineProps) {
	const { t } = useTranslation('scheduleCommandsPage');

	if (!history) return undefined;

	const statusChips = _.map(_.initial(history), ({ status }, i) => (
		<Fragment key={`${status}-${i}`}>
			<Typography variant="body2">{t(status)}</Typography>
			<ArrowRightOutlined />
		</Fragment>
	));

	const currentStatus = _.get(_.last(history), 'status');

	return (
		<Stack spacing={0.1} direction="row" alignItems="center">
			{statusChips}
			<StatusChip status={currentStatus} t={t} />
		</Stack>
	);
}
