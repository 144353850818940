import { Box } from '@mui/material';
import { ReactNode } from 'react';

type TCardGrid = {
	firstCol: ReactNode;
	secondCol: ReactNode;
	isReverse?: boolean;
};

export default function CardGrid({ firstCol, secondCol, isReverse }: TCardGrid) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: ['column', isReverse ? 'row-reverse' : 'row'],
				gap: 2
			}}
		>
			<Box
				sx={{
					width: '32ppx',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				{firstCol}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: ['start', isReverse ? 'end' : 'start'],
					gap: 1
				}}
			>
				{secondCol}
			</Box>
		</Box>
	);
}
