import { combineReducers } from '@reduxjs/toolkit';

import { apiSlice } from './api/apiSlice';
import historyCommands from './commands/historyCommandsSlice';
import scheduleCommands from './commands/scheduleCommandsSlice';
import companySettings from './company/companySettingsSlice';
import companiesCrud from './crud/companiesCrudSlice';
import contactsCrud from './crud/contactsSlice';

import fuelSupply from 'app/main/fuel-supply/list/store/fuel-supply';
import registerFuelSupply from 'app/main/fuel-supply/register/store/register-fuel-supply';
import geoFenceReportSlice from 'app/main/geo-fence/report/store/geo-fence-report-slice';
import hinovaIntegration from 'app/main/integrations/hinova/sync/store/integrate-hinova';
import chatPanel from 'app/theme-layouts/shared-components/chatPanel/store';
import notificationPanel from 'app/theme-layouts/shared-components/notificationPanel/store';
import quickPanel from 'app/theme-layouts/shared-components/quickPanel/store';
import academy from './academy/academySlice';
import course from './academy/course/courseSlice';
import accessPeriodsListPage from './access-periods/list-page/accessPeriodsListPageSlice';
import configuration from './configuration/configurationSlice';
import coursesCrud from './crud/coursesSlice';
import driversCrud from './crud/drivers/driversSlice';
import linkIButtonDriver from './crud/drivers/linkIButtonDriverSlice';
import fleetsCrud from './crud/fleetsSlice';
import ibuttonsCrud from './crud/ibuttonsSlice';
import maintenanceItem from './crud/maintenanceItemSlice';
import maintenance from './crud/maintenanceSlice';
import monitoringEvents from './crud/monitoringEventsSlice';
import panelCrud from './crud/panelSlice';
import permissionsCrud from './crud/permissionsSlice';
import profilesCrud from './crud/profilesSlice';
import rulerCrud from './crud/ruler';
import rulerHistory from './crud/rulerHistorySlice';
import sessionsCrud from './crud/sessionsCrudSlice';
import sideBar from './crud/sideBarSlice';
import simcardsCrud from './crud/simcardsSlice';
import trackersCrud from './crud/trackersSlice';
import urlsCrud from './crud/urlsSlice';
import usersCrud from './crud/usersCrudSlice';
import vehiclesCrud from './crud/vehicleSlice';
import download from './download/downloadSlice';
import fence from './fence/fenceSlice';
import fuse from './fuse';
import manageGeoFence from './geo-fence/manageGeoFenceSlice';
import i18n from './i18nSlice';
import maintenanceReport from './maintenance-report/maintenanceReportSlice';
import actionBar from './map/actionBarSlice';
import advanceFilter from './map/advanceFilterSlice';
import map from './map/mapSlice';
import mapToolbar from './map/mapToolbarSlice';
import routeToolbar from './map/routeToolbarSlice';
import monitoring from './monitoring';
import monitoringDone from './monitoring/donePageSlice';
import monitoringSettings from './monitoring/settingsSlice';
import notifications from './notifications/notificationsSlice';
import registerCommands from './register-commands/registerCommandsSlice';
import blockCommandHistory from './reports/blockCommandHistorySlice';
import eventsReportSlice from './reports/eventsReportSlice';
import history from './reports/historySlice';
import monitoringOperator from './reports/monitoringOperatorSlice';
import { tripsReducer } from './reports/trips';
import tripsManagement from './reports/tripsManagementSlice';
import trackerMetrics from './tracker-metrics/trackerMetrics';
import user from './user/userSlice';
const staticReducers = {
	[apiSlice.reducerPath]: apiSlice.reducer,
	trackerMetrics,
	notifications,
	fuse,
	i18n,
	user,
	profilesCrud,
	sideBar,
	permissionsCrud,
	companiesCrud,
	simcardsCrud,
	usersCrud,
	trackersCrud,
	driversCrud,
	fleetsCrud,
	ibuttonsCrud,
	vehiclesCrud,
	coursesCrud,
	contactsCrud,
	companySettings,
	map,
	routeToolbar,
	advanceFilter,
	mapToolbar,
	fence,
	sessionsCrud,
	actionBar,
	registerCommands,
	tripsManagement,
	history,
	urlsCrud,
	monitoring,
	scheduleCommands,
	rulerCrud,
	rulerHistory,
	download,
	monitoringEvents,
	monitoringSettings,
	monitoringDone,
	historyCommands,
	monitoringOperator,
	quickPanel,
	notificationPanel,
	chatPanel,
	maintenance,
	maintenanceItem,
	course,
	academy,
	blockCommandHistory,
	accessPeriodsListPage,
	maintenanceReport,
	panelCrud,
	configuration,
	linkIButtonDriver,
	manageGeoFence,
	trips: tripsReducer,
	geoFenceReportSlice,
	fuelSupply,
	registerFuelSupply,
	eventsReportSlice,
	hinovaIntegration
};

export default combineReducers(staticReducers);
