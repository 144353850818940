const locale = {
	TITLE: 'Finished Events',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	CANCEL: 'Cancel',
	CONFIRM: 'Confirm',
	SELECTED_ITEMS: '{{n}} items selected',
	HIGH: 'High',
	MEDIUM: 'Medium',
	LOW: 'Low',

	// Table
	ROWS_PER_PAGE: 'Items per page',
	OF: 'of',
	VEHICLE: 'Vehicle',
	TRACKER: 'Tracker',
	USER: 'User',
	DATE: 'Date',
	ALERT: 'Alert',
	NO_ITEM_FOUND: 'No event found',
	TOTAL_TIME: 'Total time',
	OPEN_ADVANCED: 'View Journey',
	TRIP_NOT_FOUND: 'Journey not found',

	// Filters
	SEVERITY: 'Severity',
	TIMING: 'Time',
	LOW_SEVERITY: 'Show low severity',
	MEDIUM_SEVERITY: 'Show medium severity',
	HIGH_SEVERITY: 'Show high severity',
	LAST_HOUR: 'Last hour',
	LAST_N_HOURS: 'Last {{n}} hours',
	CLEAR_ALL: 'Clear filters',
	SELECT_FILTERS: 'Select filters',

	// Download
	DOWNLOAD_SELECTED: 'Download selected',
	DOWNLOAD_ALL: 'Download all',
	DOWNLOAD_ERROR: 'Error while downloading finished events.',
	SHOW_FILTERS: 'Show Filters',

	// Sidebar
	BRIEF: 'Reason for completion.',
	TO_DO: 'Open',
	ON_GOING: 'Ongoing',
	DONE: 'Finished',
	ACTIONS: 'Actions performed',
	TOTAL_ACTIONS: '{{n}} actions performed',
	MOVED_TO: 'Moved to: {{action}}',

	// Events
	'E.IGN_ON': 'Ignition On',
	'E.IGN_OFF': 'Ignition Off',
	'E.DIN_CHANGED': 'Input Changed',
	'E.EXT_POWER_CUT_OFF': 'Power Off',
	'E.PANIC': 'Panic',
	'E.LOW_BATTERY_VOLTAGE': 'Low Battery',
	'E.SHIFT_WARNING': 'Towed',
	'E.GPS_ANTENNA_OPEN': 'GPS Antenna Open',
	'E.GPS_ANTENNA_SHORT': 'External GPS Off',
	'E.EXT_POWER_RECONNECTED': 'EXT_POWER_RECONNECTED',
	'E.UNDER_SPEED': 'Under Speed',
	'E.OVER_SPEED': 'Over Speed',
	'E.INTO_FENCE': 'Into Fence',
	'E.OUT_FENCE': 'Out Fence',
	'E.EXTERNAL_BATTERY_LOW': 'Battery Low',
	'E.DRIVER_OFF': 'Driver off',
	'E.DRIVER_ON': 'Driver on'
};

export default locale;
