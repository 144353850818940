const locale = {
  TITLE: "Perfis",
  ADD: "Adicionar",
  SAVE: "Salvar",
  DELETE: "Deletar",
  CANCEL: "Cancelar",
  COMPANY: "Empresa",
  COMPANIES: "Empresas",
  OF: "de",
  ROWS_PER_PAGE: "Itens por página",
  NAME_REQUIRED: "O campo nome é obrigatório",
  NAME: "Nome",
  CREATED_AT: "Criado em",
  CREATED_BY: "Criado por",
  DELETE_TITLE: "Deletar perfil",
  ADD_PROFILE: "Adicionar perfil",
  CREATE_SUCCESS: "Perfil adicionado com sucesso",
  CREATE_ERROR: "Erro ao adicionar perfil",
  EDIT_SUCCESS: "Perfil atualizado com sucesso",
  EDIT_ERROR: "Erro ao atualizar perfil",
  DELETE_SUCCESS: "Perfil deletado com sucesso",
  DELETE_ERROR: "Erro ao deletar perfil",
  UPLOAD_ERROR: "Erro ao fazer upload do arquivo",
  NO_PROFILE_FOUND: 'Nenhum perfil foi encontrado',
  DELETE_MODAL_TITLE: "Deletar perfil",
  DELETE_MODAL_DESCRIPTION: "Você tem certeza que deseja deletar este perfil?",
  CONFIRM: "Confirmar",
  DELETE_MODAL_WARNING: "Esta ação não poderá ser desfeita.",
  DELETE_MODAL_MULTIPLE_DESCRIPTION: "Você tem certeza que deseja deletar {{n}} perfis?",
  EDIT_INFO: 'Editar perfil',
  DOWNLOAD_INFO: 'Baixar perfis selecionados',
  EDIT_MULTIPLE_INFO: 'Editar perfis selecionados',
  DELETE_MULTIPLE_INFO: 'Deletar perfis selecionados',
  SELECTED_ITENS: '{{n}} itens selecionados',
  DOWNLOAD_ERROR: 'Erro ao baixar perfis',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
  EDIT_MULTIPLE_SUCCESS: "Perfis editados com sucesso",
  COMPANY_REQUIRED: 'O campo Empresa é obrigatório',
  PERMISSIONS_REQUIRED: "O campo Permissões é obrigatório",
	PERMISSIONS: 'Permissões'
};

export default locale;
