import * as yup from 'yup';

export const tableSchema = yup.object({
	iccid: yup.string().required('ICCID_REQUIRED'),
	phone: yup
		.number()
		.typeError('PHONE_LENGHT_AND_FORMAT_WARNING')
		.test('len', 'PHONE_LENGHT_AND_FORMAT_WARNING', (val) => {
			if (val) return val.toString().length === 11;
		}),
	phoneCountry: yup.string(),
	phoneCountryCode: yup.string(),
	notes: yup.string(),
	tracker: yup.string(),
	company: yup.string().required('COMPANY_REQUIRED'),
	brand: yup.string()
});
