export const pt = {
	CONFIGURE_INTEGRATION: 'Configurar integração - {{ n }}',
	TOKEN: 'Token',
	SAVE: 'Salvar',
	INTEGRATIONS: 'Integrações',
	SYNC: 'Sincronizar',
	CONFIGURE: 'Configurar',
	SYNC_PLATAFORM: 'Sincronizar - {{ n }}',
	FOUND_ASSOCIATE: 'Associado encontrado',
	ASSOCIATE_VEHICLES: 'Veículos do Associado',
	SYNCED: 'Sincronizado',
	PROFILE: 'Perfil',
	SEARCH: 'Buscar',
	CPF_CNPJ: 'CPF/CNPJ',
	PROFILE_SELECT: 'Selecione o perfil',
	ASSOCIATE_DESCRIPTION:
		'Ao sincronizar o associado, ele será adicionado ao sistema. Criando uma empresa com o mesmo nome e cpf/cnpj e um usuário com o mesmo email,  vinculando a empresa criada.',
	ASSOCIATE_VEHICLE_HELPER:
		'Para trazer os veículos do associado, é necessário que ele esteja sincronizado. Caso não esteja, sincronize-o primeiro.',
	NO_CONTENT_TITLE: 'Nenhum Associado',
	NO_CONTENT_DESCRIPTION: 'Pesquise por um associado para começar a sincronização.',
	FINDING_ASSOCIATE: 'Buscando associado...',
	TYPE: 'Tipo de Veículo',
	SYNC_ALL: 'Sincronizar todos',
	CONCLUDE: 'Concluir',
	SYNCING: 'Sincronizando...',
	SELECTED_HOSTS: '{{ n }} selecionados',
	HOSTS: 'Hosts',
	PROFILE_REQUIRED: 'Selecione ao menos um perfil de permissão',
	HOSTS_REQUIRED: 'Selecione ao menos um host',
	START: 'Iniciar',
	BACK: 'Voltar',
	NO_VEHICLE_TO_SYNC: 'Nenhum veículo para sincronizar',
	CREATE_TOKEN_SUCCESS: 'Token criado com sucesso',
	FIND_ASSOCIATE_SUCCESS: 'Associado encontrado com sucesso',
	ASSOCIATE_NOT_FOUND: 'Associado não encontrado',
	ASSOCIATE_SYNCED: 'Associado sincronizado com sucesso',
	VEHICLE_SYNCED: 'Veículo sincronizado com sucesso',
	VEHICLES: 'Veículos',
	VEHICLE: 'Veículo',
	REMOVE: 'Remover',
	STATUS: 'Status',
	CANCEL: 'Cancelar',
	TOKEN_REQUIRED: 'Token é obrigatório',
	HEAVY_TOKEN: 'Token para acessar veículos pesados',
	ASSOCIATE_HEAVY_VEHICLES: 'Veículos Pesados do Associado'
};
