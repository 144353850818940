export const commonPtTranslates = {
	SHUTDOWN: 'Desligado',
	EXTREMELY_LOW_POWER: 'Baixíssima',
	VERY_LOW_POWER: 'Muito baixa',
	LOW_BATTERY: 'Baixa',
	MEDIUM: 'Média',
	HIGH: 'Alta',
	VERY_HIGH: 'Muito alta',
	UNKNOWN: 'Desconhecido',
	SIX_MONTHS_AGO_INFO: 'Você está consultando dados de um período superior a seis meses atrás, isso pode levar alguns instantes.'
};
export const commonEnTranslates = {
	SHUTDOWN: 'Shutdown',
	EXTREMELY_LOW_POWER: 'Extremely low',
	VERY_LOW_POWER: 'Very low',
	LOW_BATTERY: 'Low',
	MEDIUM: 'Medium',
	HIGH: 'High',
	VERY_HIGH: 'Very high',
	UNKNOWN: 'Unknown',
	SIX_MONTHS_AGO_INFO: 'You are consulting data from a period longer than six months ago, this may take a few moments.'
};
export const commonSpTranslates = {
	SHUTDOWN: 'Apagado',
	EXTREMELY_LOW_POWER: 'Muy bajo',
	VERY_LOW_POWER: 'Bajo',
	LOW_BATTERY: 'Bajo',
	MEDIUM: 'Medio',
	HIGH: 'Alto',
	VERY_HIGH: 'Muy alto',
	UNKNOWN: 'Desconocido',
	SIX_MONTHS_AGO_INFO: 'Estás consultando datos de un periodo mayor a seis meses, esto puede demorar unos instantes.'
};
