import { apiSlice } from './apiSlice';
import { IApiPagination } from './types';

export interface INotification {
	_id: string;
	time: Date;
	read: boolean;
	icon: string;
	title: string;
	tag: string;
	description: string;
	link: string;
	userouter: boolean;
	createdAt: Date;
}

export const notificationsSlice = apiSlice.injectEndpoints({
	endpoints: ({ mutation, query }) => ({
		getNotifications: query<IApiPagination<INotification>, { page: number }>({
			query: ({ page }) => ({
				url: '/notification',
				params: { page, sortValue: 'desc', limit: 10 },
				method: 'GET'
			}),
			providesTags: ['Notifications']
		}),
		deleteNotification: mutation<string, string>({
			query: (id) => ({
				url: `/notification/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Notifications']
		}),
		deleteAllNotifications: mutation({
			query: (page) => ({
				url: `/notification`,
				method: 'DELETE',
				params: { page }
			}),
			invalidatesTags: ['Notifications']
		}),
		markAsRead: mutation<string, string>({
			query: (id) => ({
				url: `/notification/${id}/read`,
				method: 'POST',
				body: { read: true }
			}),
			invalidatesTags: ['Notifications']
		}),
		markAllAsRead: mutation<string, void>({
			query: () => ({
				url: `/notification/all/read`,
				method: 'POST'
			}),
			invalidatesTags: ['Notifications']
		})
	})
});

export const {
	useGetNotificationsQuery,
	useDeleteNotificationMutation,
	useDeleteAllNotificationsMutation,
	useMarkAsReadMutation,
	useMarkAllAsReadMutation
} = notificationsSlice;
