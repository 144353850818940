import { createSlice } from '@reduxjs/toolkit';
import { TUser } from 'app/main/users/types';
import { Associate, Vehicle } from '../../types';

interface IInitialState {
	findedAssociate: Associate;
	isLoadingAssociate: boolean;
	createdAssociate: TUser;
	selectedVehicleRows: Vehicle[];
}

const initialState: IInitialState = {
	findedAssociate: null,
	isLoadingAssociate: false,
	createdAssociate: null,
	selectedVehicleRows: []
};

const hinovaIntegrationSlice = createSlice({
	name: 'hinovaIntegration',
	initialState,
	reducers: {
		setFindedAssociate: (state, action) => {
			state.findedAssociate = action.payload;
		},
		setIsLoadingAssociate: (state, action) => {
			state.isLoadingAssociate = action.payload;
		},
		setCreatedAssociate: (state, action) => {
			state.createdAssociate = action.payload;
		}
	}
});

export const { setFindedAssociate, setIsLoadingAssociate, setCreatedAssociate } = hinovaIntegrationSlice.actions;

export const selectFindedAssociate = (state) => state.hinovaIntegration?.findedAssociate;
export const selectIsLoadingAssociate = (state) => state.hinovaIntegration?.isLoadingAssociate;
export default hinovaIntegrationSlice.reducer;
