import { alpha, Theme, useTheme } from '@mui/material';
import { TableStyles } from 'react-data-table-component';

export function useTableTheme(hasData = false): TableStyles {
	const theme = useTheme();

	return {
		subHeader: {
			style: {
				backgroundColor: theme.palette.background.default,
				color: theme.palette.text.primary,
				padding: '1.6rem 1.6rem'
			}
		},
		contextMenu: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		tableWrapper: {
			style: {
				height: '100%',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,
				display: 'table',
				'& > *': {
					display: 'table-row'
				}
			}
		},

		responsiveWrapper: {
			style: {
				height: '100%',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		progress: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		rows: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.secondary,
				'&:not(:last-of-type)': {
					borderBottomStyle: 'solid',
					borderBottomWidth: '1px',
					borderBottomColor: theme.palette.divider
				}
			},
			highlightOnHoverStyle: {
				backgroundColor: alpha(theme.palette.secondary.main, 0.1),
				borderColor: theme.palette.divider,
				color: theme.palette.text.primary,
				outlineStyle: 'none',
				cursor: 'pointer',
				'&:not(:last-of-type)': {
					borderBottomStyle: 'solid',
					borderBottomWidth: '1px',
					borderBottomColor: theme.palette.divider
				}
			},
			selectedHighlightStyle: {
				'&:nth-of-type(n)': {
					backgroundColor: alpha(theme.palette.secondary.main, 0.1),
					borderColor: theme.palette.divider,
					color:
						theme.palette.mode === 'dark'
							? theme.palette.getContrastText(alpha(theme.palette.secondary.main, 0.1))
							: theme.palette.text.primary
				}
			}
		},

		table: {
			style: {
				height: '100%',
				border: 'none',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,
				display: 'flex'
			}
		},

		headRow: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,
				position: 'sticky',
				top: 0,
				zIndex: 1
			}
		},

		headCells: {
			style: {
				fontSize: '14px',
				color: theme.palette.text.primary,
				border: 'none'
			}
		},
		pagination: {
			pageButtonsStyle: {
				fill: theme.palette.text.primary,
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.background.default,
				paginationPreviousPage: {
					backgroundColor: theme.palette.background.default,
					color: theme.palette.text.primary,
					fill: theme.palette.text.primary
				}
			},
			style: {
				backgroundColor: theme.palette.background.default,
				color: theme.palette.text.primary,
				fill: theme.palette.text.primary
			}
		},
		noData: {
			style: {
				height: '100%',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		}
	} as TableStyles;
}

export default function getTableTheme(): TableStyles {
	const theme: Theme = useTheme();

	return {
		subHeader: {
			style: {
				backgroundColor: theme.palette.background.default,
				color: theme.palette.text.primary,
				padding: '1.6rem 1.6rem'
			}
		},
		contextMenu: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		tableWrapper: {
			style: {
				height: '100%',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,
				display: 'table',
				'& > *': {
					display: 'table-row'
				}
			}
		},

		responsiveWrapper: {
			style: {
				height: '100%',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				overflowX: 'auto',
				overflowY: 'auto'
			}
		},

		progress: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		rows: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.secondary,
				'&:not(:last-of-type)': {
					borderBottomStyle: 'solid',
					borderBottomWidth: '1px',
					borderBottomColor: theme.palette.divider
				}
			},
			highlightOnHoverStyle: {
				backgroundColor: alpha(theme.palette.secondary.main, 0.1),
				borderColor: theme.palette.divider,
				color: theme.palette.text.primary,
				outlineStyle: 'none',
				cursor: 'pointer',
				'&:not(:last-of-type)': {
					borderBottomStyle: 'solid',
					borderBottomWidth: '1px',
					borderBottomColor: theme.palette.divider
				}
			},
			selectedHighlightStyle: {
				'&:nth-of-type(n)': {
					backgroundColor: alpha(theme.palette.secondary.main, 0.1),
					borderColor: theme.palette.divider,
					color:
						theme.palette.mode === 'dark'
							? theme.palette.getContrastText(alpha(theme.palette.secondary.main, 0.1))
							: theme.palette.text.primary
				}
			}
		},

		table: {
			style: {
				height: '100%',
				border: 'none',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		headRow: {
			style: {
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		},

		headCells: {
			style: {
				fontSize: '14px',
				color: theme.palette.text.primary,
				border: 'none'
			}
		},
		pagination: {
			pageButtonsStyle: {
				fill: theme.palette.text.primary,
				color: theme.palette.text.primary,
				backgroundColor: theme.palette.background.default,
				paginationPreviousPage: {
					backgroundColor: theme.palette.background.default,
					color: theme.palette.text.primary,
					fill: theme.palette.text.primary
				}
			},
			style: {
				backgroundColor: theme.palette.background.default,
				color: theme.palette.text.primary,
				fill: theme.palette.text.primary
			}
		},
		noData: {
			style: {
				height: '100%',
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary
			}
		}
	};
}
