import { StyledEngineProvider } from '@mui/material/styles';
import routes from 'app/configs/routesConfig';
import Provider from 'react-redux/es/components/Provider';

import AppContext from './AppContext';
import store from './store';

const withAppProviders = (Component: any) => () => {
	const WrapperComponent = () => {
		return (
			<AppContext.Provider value={{ routes }}>
				<Provider store={store}>
					<StyledEngineProvider injectFirst>
						<Component />
					</StyledEngineProvider>
				</Provider>
			</AppContext.Provider>
		);
	};

	return WrapperComponent;
};

export default withAppProviders;
