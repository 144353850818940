import i18next from 'i18next';

const en = {
	FILTER: 'Filter',
	FILTERS: 'Filters',
	COLUMNS: 'Columns',
	SEARCH_KEYS: 'Search by',
	COLUMNS_INFO: 'Select which fields you want to appear in the table',
	SEARCH_KEYS_INFO: 'Select fields you want to search in the table',
	OTHERS: 'Others',
	ITEMS: 'items',
	ADD: 'Add',
	SEARCH: 'Pesquisar',
	SEARCH_INFO: 'Select fields you want to search in the table',
	APPLY: 'Apply Filters',
	CANCEL: 'Cancel',
	SELECT_DATE: 'Select date',
	TYPE: 'Type',
	EDIT_INFO: 'Edit Information',
	DELETE: 'Delete item',
	CLOSE: 'Close',
	COMPANIES: 'Companies',
	SAVE: 'Save',
	DOWNLOAD: 'Download selected items',
	EDIT_ITENS: 'Edit selected items',
	DELETE_ITENS: 'Delete selected items',
	SELECTED: 'selected item(s)'
};

const pt = {
	FILTER: 'Filtro',
	FILTERS: 'Filtros',
	COLUMNS: 'Colunas',
	SEARCH_KEYS: 'Pesquisar por',
	COLUMNS_INFO: 'Selecione quais campos devem aparecer na tabela',
	SEARCH_KEYS_INFO: 'Selecione campos em que deseja pesquisar na tabela',
	OTHERS: 'Outros',
	ITEMS: 'itens',
	ADD: 'Adicionar',
	SEARCH: 'Pesquisar',
	SEARCH_INFO: 'Selecione campos em que deseja pesquisar na tabela',
	APPLY: 'Aplicar filtros',
	CANCEL: 'Cancelar',
	SELECT_DATE: 'Selecionar data',
	TYPE: 'Tipo',
	EDIT_INFO: 'Editar Informações',
	DELETE: 'Deletar item',
	CLOSE: 'Fechar',
	COMPANIES: 'Empresas',
	SAVE: 'Salvar',
	DOWNLOAD: 'Baixar itens selecionados',
	EDIT_ITENS: 'Editar itens selecionados',
	DELETE_ITENS: 'Deletar itens selecionados',
	SELECTED: 'item(s) selecionado(s)'
};

const sp = {
	FILTER: 'Filtro',
	FILTERS: 'Filtros',
	COLUMNS: 'Columnas',
	SEARCH_KEYS: 'Pesquisar por',
	COLUMNS_INFO: 'Selecione que campos desea que aparezcan en la tabla',
	SEARCH_KEYS_INFO: 'Selecione campos que desea pesquisar na tabela',
	OTHERS: 'Otros',
	ITEMS: 'itens',
	ADD: 'Adicionar',
	SEARCH: 'Pesquisar',
	SEARCH_INFO: 'Selecione campos que deseja pesquisar na tabela',
	APPLY: 'Aplicar filtros',
	TYPE: 'Tipo',
	EDIT_INFO: 'Editar Información',
	DELETE: 'Eliminar elemento',
	CANCEL: 'Cancelar',
	SELECT_DATE: 'Seleccionar fecha',
	CLOSE: 'Cerrar',
	COMPANIES: 'Empresas',
	SAVE: 'Salvar',
	DOWNLOAD: 'Descargar elementos seleccionados',
	EDIT_ITENS: 'Editar elementos seleccionados',
	DELETE_ITENS: 'Eliminar elementos seleccionados',
	SELECTED: 'elemento(s) seleccionado(s)'
};

export default () => {
	i18next.addResourceBundle('en', 'Header', en);
	i18next.addResourceBundle('pt', 'Header', pt);
	i18next.addResourceBundle('sp', 'Header', sp);
};
