import { TUser } from 'app/main/users/types';
import { apiSlice } from 'src/app/store/api/apiSlice';
import { Associate } from '../../types';

type HinovaIntegrationSearch = {
	cpf: string;
};

const baseUrl = '/integrations/hinova';
const integrateHinovaApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllAssociates: builder.query({
			query: () => ({
				url: baseUrl
			})
		}),
		getAssociateDataByCpf: builder.query({
			query: (params: string) => `${baseUrl}/${params}`,
			providesTags: ['Hinova']
		}),
		createAssociate: builder.mutation<TUser & { companyId: string }, any>({
			query: (body: Associate & { profile: string }) => ({
				url: baseUrl,
				method: 'POST',
				body
			}),
			invalidatesTags: ['Hinova']
		}),
		createToken: builder.mutation<{ token: string; type?: string }, any>({
			query: (body: { token: string; type?: string }) => ({
				url: `${baseUrl}/create-token`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['HinovaToken']
		}),
		findCompanyToken: builder.query({
			query: (params) => ({
				url: `${baseUrl}/token`,
				params: params
			}),
			providesTags: ['HinovaToken']
		})
	})
});
export const {
	useCreateTokenMutation,
	useGetAllAssociatesQuery,
	useGetAssociateDataByCpfQuery,
	useLazyGetAssociateDataByCpfQuery,
	useCreateAssociateMutation,
	useFindCompanyTokenQuery
} = integrateHinovaApi;

export default integrateHinovaApi;
