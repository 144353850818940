import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { IconProps } from '@mui/material';

interface BlockIconProps extends IconProps {
	isBlockCommand: boolean;
}

export const BlockIcon: React.FC<BlockIconProps> = ({ isBlockCommand, sx }) => {
	return isBlockCommand ? <LockOutlined color="error" sx={sx} /> : <LockOpenOutlined color="success" sx={sx} />;
};
