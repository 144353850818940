import type { CircleLayer, FillLayer, LineLayer, MapboxStyle, RasterLayer, SymbolLayer } from 'react-map-gl/maplibre';
import { TRACKERS_SRC } from './mapHelpers';

export const osmLayer: RasterLayer = {
	id: 'osm',
	type: 'raster',
	source: 'osm'
};

export const styleBasic: MapboxStyle = {
	version: 8,
	sources: {
		osm: {
			type: 'raster',
			tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
			tileSize: 256,
			attribution: '&copy; OpenStreetMap Contributors',
			maxzoom: 19
		},
		satellite: {
			type: 'raster',
			url: `https://api.maptiler.com/maps/hybrid/tiles.json?key=${import.meta.env.VITE_MAPTILER_KEY}`
		}
	},
	layers: [],
	glyphs: 'https://cdn.jsdelivr.net/gh/marine-br/front-map-fonts/fonts/{fontstack}/{range}.pbf',
	sprite: 'https://cdn.jsdelivr.net/gh/marine-br/front-map-sprites/sprite'
};

export const clusterLayer: CircleLayer = {
	id: 'clusters',
	type: 'circle',
	source: TRACKERS_SRC.cluster,
	filter: ['has', 'point_count'],
	paint: {
		// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
		'circle-color': '#4F46E5',
		'circle-radius': ['step', ['get', 'point_count'], 12, 6, 24, 11, 34, 26, 44, 51, 56, 101, 68],
		'circle-opacity': ['step', ['get', 'point_count'], 0.9, 6, 0.8, 11, 0.7, 26, 0.6, 51, 0.5, 101, 0.4]
	}
};

export const clusterCountLayer: SymbolLayer = {
	id: 'cluster-count',
	type: 'symbol',
	source: TRACKERS_SRC.cluster,
	filter: ['has', 'point_count'],
	layout: {
		'text-field': '{point_count_abbreviated}',
		'text-font': ['Metropolis Extra Bold'],
		'text-size': 10
	},
	paint: {
		'text-color': '#fff'
	}
};

export const trackerPopupLayer: SymbolLayer = {
	id: 'tracker-popup',
	type: 'symbol',
	source: TRACKERS_SRC.cluster,
	layout: {
		'text-field': ['get', 'popupText'],
		'icon-text-fit': 'both',
		'icon-image': ['get', 'popupImage'],
		'text-font': ['Metropolis Bold'],
		'text-size': 12,
		'icon-allow-overlap': true
	},
	paint: {
		'icon-translate': [-16, -64],
		'text-translate': [-16, -64]
	}
};

export const fenceFillLayer: FillLayer = {
	id: 'fence-fill',
	type: 'fill',
	source: 'fences',
	paint: {
		'fill-color': '#627BC1',
		'fill-opacity': 0.15
	}
};

export const fenceLineLayer: LineLayer = {
	id: 'fence-line',
	type: 'line',
	source: 'fences',
	paint: {
		'line-color': '#627BC1',
		'line-width': 3
	}
};

export const fenceLabelLayer: SymbolLayer = {
	id: 'fence-label',
	type: 'symbol',
	source: 'fences',
	layout: {
		'text-field': ['get', 'name'],
		'text-size': 12,
		'icon-allow-overlap': true
	}
};
