import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { CircleLayer, FillLayer, LineLayer, RasterLayer, SymbolLayer } from 'react-map-gl/maplibre';
import { useSelector } from 'react-redux';

import {
	clusterCountLayer,
	clusterLayer,
	fenceFillLayer,
	fenceLineLayer,
	trackerPopupLayer
} from 'app/main/map/mapLayers';
import { selectGeoFenceFilter, selectIsClusterCountVisible, selectIsLabelsVisible } from 'app/store/map/actionBarSlice';
import { selectSelectedTrackerId } from 'app/store/map/mapSlice';
import { selectShowTrackers } from 'app/store/map/routeToolbarSlice';
import { selectUserPreferences } from 'app/store/user/userSlice';
import { TRACKERS_SRC } from '../mapHelpers';

export const useMapLayers = () => {
	const theme = useTheme();
	const isLabelsVisible = useSelector(selectIsLabelsVisible);
	const isClusterCountVisible = useSelector(selectIsClusterCountVisible);
	const selectedTrackerId = useSelector(selectSelectedTrackerId);
	const selectedFences = useSelector(selectGeoFenceFilter);
	const isTrackersVisible = useSelector(selectShowTrackers);
	const userPreferences = useSelector(selectUserPreferences);

	const isAnyFenceVisible = selectedFences.length > 0;
	const isClusterActive = userPreferences.isClustered;
	const selectedBasemap = userPreferences.baseMap;

	const controlledHybridLayer = useMemo(() => {
		const layer: RasterLayer = {
			id: 'satellite',
			source: 'satellite',
			type: 'raster',
			layout: {
				visibility: selectedBasemap === 'satellite' ? 'visible' : 'none'
			}
		};
		return layer;
	}, [selectedBasemap]);

	const controlledOSMLayer = useMemo(() => {
		const layer: RasterLayer = {
			id: 'osm',
			source: 'osm',
			type: 'raster',
			layout: {
				visibility: selectedBasemap === 'osm' ? 'visible' : 'none'
			}
		};
		return layer;
	}, [selectedBasemap]);

	const controlledUnclusteredPointLayer = useMemo(() => {
		const layer: SymbolLayer = {
			id: 'unclustered-point',
			type: 'symbol',
			source: isClusterActive ? TRACKERS_SRC.cluster : TRACKERS_SRC.noCluster,
			filter: ['!', ['has', 'point_count']],
			layout: {
				'icon-image': ['get', 'icon'],
				'icon-size': 1,
				'icon-anchor': 'bottom-right',
				'icon-allow-overlap': true,
				visibility: isTrackersVisible ? 'visible' : 'none',
				'icon-rotate': ['get', 'bearing']
			}
		};

		return layer;
	}, [isTrackersVisible, isClusterActive]);

	const controlledClusterLayer = useMemo(() => {
		const layer: CircleLayer = {
			...clusterLayer,
			layout: {
				visibility: isTrackersVisible && isClusterActive ? 'visible' : 'none'
			},
			paint: {
				...clusterLayer.paint,
				'circle-color': theme.palette.secondary.main
			}
		};

		return layer;
	}, [isTrackersVisible, theme, isClusterActive]);

	const controlledClusterCountLayer = useMemo(() => {
		const layer: SymbolLayer = {
			...clusterCountLayer,
			layout: {
				...clusterCountLayer.layout,
				visibility: isTrackersVisible && isClusterCountVisible && isClusterActive ? 'visible' : 'none'
			},
			paint: {
				...clusterCountLayer.paint,
				'text-color': theme.palette.secondary.contrastText
			}
		};

		return layer;
	}, [isTrackersVisible, theme, isClusterCountVisible, isClusterActive]);

	const controlledPopupLayer = useMemo(() => {
		const layer: SymbolLayer = {
			...trackerPopupLayer,
			filter: ['!', ['has', 'point_count']],
			source: isClusterActive ? TRACKERS_SRC.cluster : TRACKERS_SRC.noCluster,
			layout: {
				...trackerPopupLayer.layout,
				visibility: isLabelsVisible && !selectedTrackerId ? 'visible' : 'none'
			}
		};
		return layer;
	}, [isLabelsVisible, selectedTrackerId, isClusterActive]);

	const controlledFenceFillLayer = useMemo(() => {
		const layer: FillLayer = {
			...fenceFillLayer,
			paint: {
				...fenceFillLayer.paint,
				'fill-color': theme.palette.secondary.main
			},
			layout: {
				visibility: isAnyFenceVisible ? 'visible' : 'none'
			}
		};
		return layer;
	}, [theme, isAnyFenceVisible]);

	const controlledFenceLineLayer = useMemo(() => {
		const layer: LineLayer = {
			...fenceLineLayer,
			paint: {
				...fenceLineLayer.paint,
				'line-color': theme.palette.secondary.main
			},
			layout: {
				visibility: isAnyFenceVisible ? 'visible' : 'none'
			}
		};
		return layer;
	}, [theme, isAnyFenceVisible]);

	return {
		controlledClusterCountLayer,
		controlledClusterLayer,
		controlledUnclusteredPointLayer,
		controlledPopupLayer,
		controlledFenceFillLayer,
		controlledFenceLineLayer,
		controlledHybridLayer,
		controlledOSMLayer
	};
};
