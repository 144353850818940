import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'company'>, MatchConditions>;

const LIST_COMPANY_PERMISSIONS = ['admin.company.list'];

const VIEW_COMPANY_PERMISSIONS = ['admin.company.view'];

const CREATE_COMPANY_PERMISSIONS = ['admin.company.new', 'admin.company.list'];

const UPDATE_COMPANY_PERMISSIONS = ['admin.company.list', 'admin.company.edit'];

const DELETE_COMPANY_PERMISSIONS = ['fleet.company.delete'];

export const defineCompanyAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_COMPANY_PERMISSIONS),
		create: getMissingPermissions(CREATE_COMPANY_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_COMPANY_PERMISSIONS),
		delete: getMissingPermissions(DELETE_COMPANY_PERMISSIONS),
		list: getMissingPermissions(LIST_COMPANY_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('company', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_COMPANY_PERMISSIONS)) can('delete', 'company');
	if (userHasPermission(CREATE_COMPANY_PERMISSIONS)) can('create', 'company');
	if (userHasPermission(UPDATE_COMPANY_PERMISSIONS)) can('edit', 'company');
	if (userHasPermission(VIEW_COMPANY_PERMISSIONS)) can('view', 'company');
	if (userHasPermission(LIST_COMPANY_PERMISSIONS)) can('list', 'company');

	return [build(), missingPermissions] as const;
};
