const locale = {
	DRIVER: 'Driver',
	DRIVER_REQUIRED: 'The driver field is required',

	TRACKER: 'Tracker',
	COMPANY_REQUIRED: 'The company field is required',
	DID_MUST_BE_NUMBER: 'The DID field must be a number',
	TRACKER_TYPE_REQUIRED: 'The simcard type field is required',
	DID_REQUIRED: 'The DID field is required',
	NOTES: 'Notes',
	VEHICLE: 'Vehicle',
	TRACKER_TYPE: 'Tracker type',
	TITLE: 'Identifiers',
	ADD: 'add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANIES: 'Companies',
	PROFILES: 'Profiles',
	PROFILE: 'Profile',
	USERS: 'Users',
	USER: 'User',
	BIRTHDAY: 'Birth date',
	PASSWORD: 'Password',
	CPASSWORD: 'Confirm password',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-US',
	INACESSIBLE_HOSTS: 'Accessible hosts',
	ADDRESS: 'Address',
	EMAIL: 'Email',
	PHONE: 'Phone',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	NAME_REQUIRED: 'The name field is required',
	CODE_REQUIRED: 'The code field is required',
	ICON_REQUIRED: 'You need to select an icon',
	PHONE_REQUIRED: 'The phone field is required',
	DOC_REQUIRED: 'The CPF/CNPJ field is required',
	EMAIL_REQUIRED: 'The email field is required',
	ADDRESS_REQUIRED: 'The address field is required',
	TYPE_REQUIRED: 'The type of client field is required',
	COLUMN_NAME: 'Name',
	COLUMN_COMPANY: 'Company',
	COLUMN_BIRTHDAY: 'Birth date',
	COLUMN_CREATED_BY: 'Created by',
	COLUMN_CREATED_AT: 'Created at',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Address',
	COLUMN_PHONE: 'Phone',
	NAME: 'Name',
	DESC: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete user',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Edit simcard',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this identifier?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected identifiers?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	FIELD_NAME: 'Name',
	FIELD_DESC: 'Description',
	FIELD_CREATED_AT: 'Created at',
	FIELD_CREATED_BY: 'Created by',
	ERROR_PERMISSION: 'You do not have permission to access this page, please contact the system administrator',
	ERROR_GETTING_USERS: 'Error getting simcards, please contact the system administrator',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Error: Internal server error, please contact the system administrator',
	ERROR_NETWORK: 'Error: Network error, please check your connection and try again',
	ADD_USER: 'Add IButton',
	CREATE_SUCCESS: 'Identifier successfully added',
	CREATE_ERROR: 'Error adding identifier',
	EDIT_SUCCESS: 'Identifier successfully updated',
	EDIT_ERROR: 'Error updating identifier',
	DELETE_SUCCESS: 'Identifier successfully deleted',
	DELETE_ERROR: 'Error deleting identifier',
	ERROR_ADD_EMPTY: 'Please fill in all fields',
	NO_DATA: 'No matching items were found',
	NO_RESULTS: 'No results found',
	NO_PERMISSION_list: "You don't have permission to view the identifier list",
	NO_PERMISSION_new: "You don't have permission to add identifiers",
	NO_PERMISSION_edit: "You don't have permission to edit identifiers",
	NO_PERMISSION_view: "You don't have permission to view identifiers",
	NO_PERMISSION_delete: "You don't have permission to delete identifiers",
	NO_ADDRESS: 'Address not found',
	NO_PHONE: 'Phone not found',
	NO_EMAIL: 'Email not found',
	NO_BIRTHDAY: 'Date of birth not found',
	LOADING: 'Loading',
	UPLOAD_ERROR: 'Error uploading file',
	PASSWORD_MIN_LENGTH: 'must be at least 8 characters long',
	PASSWORDS_MUST_MATCH: 'passwords must match',
	PASSWORD_MIN_LOWERCASE: 'must have at least 1 lowercase letter',
	PASSWORD_MIN_UPPERCASE: 'must have at least 1 uppercase letter',
	PASSWORD_MIN_NUMBERS: 'must have at least 1 number',
	PASSWORD_MIN_SYMBOLS: 'must have at least 1 symbol',
	ICCID_REQUIRED: 'The ICCID field is required',
	MORE_THAN: 'more than',
	NO_USER_FOUND: 'No user found',
	DELETE_MODAL_TITLE: 'Delete user',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete the identifier',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} identifiers',
	EDIT_INFO: 'Edit identifiers',
	DELETE_INFO: 'Delete identifiers',
	DOWNLOAD_INFO: 'Download selected identifiers',
	EDIT_MULTIPLE_INFO: 'Edit selected identifiers',
	DELETE_MULTIPLE_INFO: 'Delete selected identifiers',
	DOWNLOAD_ERROR: 'Error downloading file',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Selected companies',
	SELECTED_HOSTS: '{{n}} Selected host',
	PROFILE_REQUIRED: 'The Profile field is required',
	CPASSWORD_REQUIRED: 'The Confirm Password field is required',
	EDIT_MULTIPLE_SUCCESS: 'Trackers successfully updated',
	EDIT_MULTIPLE_ERROR: 'Error updating identifiers',
	PROFILE_IMAGE_TO_LARGE: 'Please, choose an image less than 8MB',
	EMAIL_INVALID: 'Invalid email',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',

	EVENT_TYPE: 'Event type',
	TRACKER_ID: 'Tracker ID',
	IGNITION: 'Ignition',
	ODOMETER: 'Odometer',
	GPS_STATUS: 'GPS status',
	FIXED: 'FIXED',
	UNFIXED: 'NOT FIXED',
	DATE_TIME: 'Date and time',
	DELETED_RULE: 'Deleted rule',
	SHOW_DELETED: 'Show deleted rules',
	AGGREGATED_DATA: 'Aggregated data',
	AGGREGATED_DATA_DESCRIPTION:
		'This rule was reached {{counter}} times during {{totalTime}} between {{start}} and {{end}}',
	AGGREGATED_DATA_INFO: 'I follow the aggregated data of the rule below',
	QUANTITY: 'Quantity',
	START: 'Start',
	END: 'End',
	TOTAL_TIME: 'Duration',
	AGGREGATE: 'Rule duration',
	PACKETS: 'Packets',

	// Download

	DOWNLOAD_ALL: 'Download all',

	// Common

	SPEED: 'Speed'
};

export default locale;
