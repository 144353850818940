import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface IDialogWrapperProps {
	title: string;
	content: ReactNode;
	description?: string;
	isSmall?: boolean;
	onClose: () => void;
}

export default function DialogWrapper({
	title = '',
	content,
	isSmall = false,
	onClose,
	description
}: IDialogWrapperProps) {
	return (
		<Box
			sx={{
				minHeight: isSmall ? 'fit-content' : '400px',
				width: ['100%', 'auto'],
				maxWidth: ['400px', 'auto'], // 'auto' or '400px' or '600px
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				p: 2,
				height: '100%'
			}}
		>
			<Stack direction="column" spacing={3} flex={1}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: 400,
							width: ['240px']
						}}
						noWrap
					>
						{title}
					</Typography>
					<IconButton size="small" onClick={onClose}>
						<CloseOutlined />
					</IconButton>
				</Stack>

				{description && (
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							color: 'text.secondary',
							maxWidth: ['400px']
						}}
					>
						{description}
					</Typography>
				)}
				<Box flex={1} display="flex">
					{content}
				</Box>
			</Stack>
		</Box>
	);
}
