import { usePrevious } from '@fuse/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { REQUEST_MESSAGES } from 'app/main/drivers/helper';

import {
	ILessonPayload,
	useCreateLessonsMutation,
	useEditLessonsMutation,
	useUploadLessonVideoMutation
} from 'app/store/api/lessonsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { TCategory, TLesson } from 'app/main/academy/types/types';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { ViewIcon } from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import HookedTextfield from 'app/shared-components/Hooked/HookedTextField';
import HookedVideoInput from 'app/shared-components/Hooked/HookedVideoInput';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { selectCurrentEditLessonUrl, selectCurrentTab } from 'app/store/academy/academySlice';
import { useGetCourseCategoryQuery } from 'app/store/api/courseCategorySlice';
import { selectSidebarMode, setCurrentRegisterDrawerForm, toggleRegisterDrawer } from 'app/store/crud/coursesSlice';
import { useNavigate } from 'react-router-dom';
import { REQUEST_MESSAGES_LESSON } from '../../helper';
import { IGenericFormProps } from '../types';
import schema from './schema';

const emptyArr = [];

type TFormData = yup.InferType<typeof schema>;

type ILessonFormProps = IGenericFormProps<TFormData, TLesson>;

const initialForm: TFormData = {
	title: '',
	description: '',
	category: [],
	url: '',
	videoFile: null
};

const parseLessonDataForApi = (data: TFormData, id = '') =>
	_.chain({
		...(id ? { id } : {}),
		title: data.title,
		description: data.description,
		category: _.map(data.category, '_id'),
		url: data.url
	})
		.omitBy(_.isNil)
		.value() as ILessonPayload;

const parseLessonInitialData = (data: TLesson) => ({
	title: data?.title,
	description: data?.description,
	category: data?.category,
	url: data?.url
});

export default function LessonForm({ onCancel, onSuccess, lesson }: ILessonFormProps) {
	const mode = useSelector(selectSidebarMode);
	const navigate = useNavigate();
	const tab = useSelector(selectCurrentTab);

	const handleToggleRegisterSidebar = (form: string) => {
		dispatch(toggleRegisterDrawer());
		dispatch(setCurrentRegisterDrawerForm(form));
	};

	const { t } = useTranslation('academyPage');
	const dispatch = useDispatch();

	const prevMode = usePrevious(mode);
	const [uploadCourseVideo, { isLoading: isUploadingCourseVideo }] = useUploadLessonVideoMutation();
	const [createLesson, { isLoading: isCreateLoading }] = useCreateLessonsMutation();
	const [updateLesson, { isLoading: isUpdateLoading }] = useEditLessonsMutation();

	const isLoading = isCreateLoading || isUpdateLoading || isUploadingCourseVideo;
	const videoUrl = useSelector(selectCurrentEditLessonUrl);

	const { categories, isCategoriesLoading } = useGetCourseCategoryQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			categories: data?.docs || emptyArr,
			isCategoriesLoading: isLoading
		})
	});

	const { control, handleSubmit, reset } = useForm<TFormData>({
		resolver: yupResolver(schema),
		defaultValues: mode === 'edit' ? parseLessonInitialData(_.omitBy(lesson, _.isNull) as TLesson) : initialForm,
		mode: 'all'
	});

	const handleCancel = () => {
		onCancel();
		tab == 'lessons' && navigate(`/academy/lessons`, { replace: true });
	};
	const onSubmit = async (data: TFormData) => {
		try {
			if (data.videoFile) {
				const formData = new FormData();
				if (formData.has('video')) formData.delete('video');
				formData.append('video', data.videoFile);
				const { url } = await uploadCourseVideo(formData).unwrap();
				data.url = url;
			}
			const parsed = parseLessonDataForApi(data, lesson?._id);

			if (mode === 'create') {
				await createLesson(parsed).unwrap();
			} else {
				await updateLesson(parsed).unwrap();
			}

			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_LESSON.create.success),
					variant: 'success'
				})
			);

			if (onSuccess) {
				onSuccess(data);
				navigate(`/academy/lessons`);
			}

			reset(initialForm);
		} catch (err) {
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES.create.error),
					variant: 'error'
				})
			);
		}
	};

	useEffect(() => {
		if (!prevMode) return;
		if (prevMode !== 'create' && mode === 'create') {
			reset(initialForm);
		}
	}, [mode, prevMode, reset]);

	useEffect(() => {
		if (!_.isEmpty(lesson)) {
			reset(parseLessonInitialData(_.omitBy(lesson, _.isNull) as TLesson));
		}
		// eslint-disable-next-line
	}, [lesson]);

	return (
		<Stack component="form" direction="column" onSubmit={handleSubmit(onSubmit)} spacing={3} paddingX={3}>
			<Stack direction="row" spacing={1}>
				<ViewIcon icon="dns" />
				<HookedTextfield<TFormData>
					required
					control={control}
					label={t('LESSON_TITLE')}
					t={t}
					variant="outlined"
					name="title"
					size="small"
					fullWidth
				/>
			</Stack>

			<Stack direction="row" spacing={1}>
				<ViewIcon icon="device_hub" />
				<HookedTextfield<TFormData>
					required
					control={control}
					label={t('DESCRIPTION')}
					t={t}
					variant="outlined"
					name="description"
					size="medium"
					fullWidth
				/>
			</Stack>

			<Stack direction="row" spacing={1}>
				<ViewIcon icon="business" />
				<HookedVirtualizedChipSelect<TFormData, TCategory>
					optionLabel="name"
					label={t('CATEGORY')}
					options={categories}
					loading={isCategoriesLoading}
					limitText={(n) => t('SELECTED_CATEGORIES', { n })}
					fullWidth
					size="small"
					control={control}
					name="category"
					t={t}
				/>

				<ViewIcon icon="add" onClick={() => handleToggleRegisterSidebar('category')} button />
			</Stack>

			<Stack direction="column" spacing={1}>
				<HookedVideoInput
					w="100%"
					h={200}
					inputId="videoUrl"
					name="videoFile"
					t={t}
					control={control}
					videoUrl={mode === 'edit' ? videoUrl : undefined}
				/>
			</Stack>

			<Stack direction="row" paddingBottom={3} paddingTop={2} gap={1}>
				<AppButton variant="outlined" color="secondary" onClick={handleCancel} fullWidth>
					{t('CANCEL')}
				</AppButton>
				<AppButton
					variant="contained"
					color="secondary"
					fullWidth
					type="submit"
					loading={isLoading}
					disabled={isLoading}
				>
					{t('SAVE')}
				</AppButton>
			</Stack>
		</Stack>
	);
}
