import { Box, Checkbox } from '@mui/material';
import { useRef } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';

import { ArrowDownwardOutlined } from '@mui/icons-material';
import NoTableData from 'app/shared-components/Table/NoTableData';
import TableSkeleton from 'app/shared-components/Table/TableSkeleton';
import getTableTheme from 'app/shared-components/dataTableTheme';
import { useSize } from 'app/shared-components/hooks';
import { setSelectedRows } from 'app/store/reports/historySlice';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { NoFilterComponent } from '../../shared/noDataComponent';
import { getAddress } from '../helper';
import { TContentProps, THistoryPosition } from '../types';

export default function Content({
	columns,
	data,
	onChangeLimit,
	onChangePage,
	onChangeSort,
	loading,
	totalRows,
	paginationData,
	hasFilters
}: TContentProps) {
	const { t } = useTranslation('historyPage');
	const dispatch = useDispatch();
	const containerRef = useRef();
	const size = useSize(containerRef);
	const { search } = useLocation();
	const navigate = useNavigate();

	const handleRowClick = (row: THistoryPosition) => {
		navigate(`${row._id}${search.toString()}`, { replace: false });
	};

	const onSelectedRowsChange = async ({ selectedRows }: { selectedRows: any[] }) => {
		if (selectedRows.length === 0) {
			dispatch(setSelectedRows([]));
		} else {

			const updatedRows = await Promise.all(selectedRows.map(async (row) => {
				const address = {
					ADDRESS_ROAD: row.ADDRESS_ROAD,
					ADDRESS_SUBURB: row.ADDRESS_SUBURB,
					ADDRESS_STATE: row.ADDRESS_STATE,
					ADDRESS_COUNTRY: row.ADDRESS_COUNTRY,
					ADDRESS_POSTCODE: row.ADDRESS_ZIP
				};
				const addressFormated = getAddress(address);

				return {
					...row,
					address: addressFormated
				};
			}));
			dispatch(setSelectedRows(updatedRows));
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				paddingX: ['20px', '40px'],
				paddingBottom: ['18px', '0px'],
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				overflowY: 'auto'
			}}
			ref={containerRef}
		>
			<DataTable
				responsive
				selectableRows
				fixedHeader
				pointerOnHover
				highlightOnHover
				columns={columns}
				data={data}
				fixedHeaderScrollHeight={`${size?.height ? size.height - 64 : 0}px`}
				style={{
					height: '100%'
				}}
				customStyles={getTableTheme()}
				progressPending={loading}
				progressComponent={<TableSkeleton width={size?.width} height={size?.height} />}
				noDataComponent={
					!hasFilters ? (
						<NoFilterComponent text={t('NO_FILTERS')} textSecondary={t('NO_FILTERS_SUBTITLE')} />
					) : (
						<NoTableData text={t('NO_HISTORY_FOUND')} />
					)
				}
				sortIcon={<ArrowDownwardOutlined sx={{ paddingLeft: '4px' }} />}
				pagination
				paginationServer
				paginationServerOptions={{
					persistSelectedOnPageChange: false,
					persistSelectedOnSort: false
				}}
				paginationComponentOptions={{
					rowsPerPageText: t('ROWS_PER_PAGE'),
					rangeSeparatorText: t('OF')
				}}
				paginationRowsPerPageOptions={[60, 100, 500]}
				paginationPerPage={paginationData.limit}
				paginationTotalRows={totalRows}
				sortServer
				defaultSortFieldId={paginationData.sortKey ?? undefined}
				onSort={onChangeSort}
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeLimit}
				selectableRowsComponent={Checkbox}
				paginationDefaultPage={paginationData.page}
				selectableRowsComponentProps={{ color: 'secondary' }}
				onSelectedRowsChange={onSelectedRowsChange}
				onRowClicked={handleRowClick}
			/>
		</Box>
	);
}
