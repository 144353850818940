import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'vehicle'>, MatchConditions>;

const LIST_VEHICLE_PERMISSIONS = ['fleet.vehicle.list'];

const VIEW_VEHICLE_PERMISSIONS = ['fleet.vehicle.view'];

const CREATE_VEHICLE_PERMISSIONS = ['fleet.vehicle.new', 'admin.company.list', 'fleet.tracker.list'];

const UPDATE_VEHICLE_PERMISSIONS = ['fleet.vehicle.edit', 'admin.company.list', 'fleet.tracker.list'];

const DELETE_VEHICLE_PERMISSIONS = ['fleet.vehicle.delete'];

export const defineVehicleAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_VEHICLE_PERMISSIONS),
		create: getMissingPermissions(CREATE_VEHICLE_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_VEHICLE_PERMISSIONS),
		delete: getMissingPermissions(DELETE_VEHICLE_PERMISSIONS),
		list: getMissingPermissions(LIST_VEHICLE_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('vehicle', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_VEHICLE_PERMISSIONS)) can('delete', 'vehicle');
	if (userHasPermission(CREATE_VEHICLE_PERMISSIONS)) can('create', 'vehicle');
	if (userHasPermission(UPDATE_VEHICLE_PERMISSIONS)) can('edit', 'vehicle');
	if (userHasPermission(VIEW_VEHICLE_PERMISSIONS)) can('view', 'vehicle');
	if (userHasPermission(LIST_VEHICLE_PERMISSIONS)) can('list', 'vehicle');

	return [build(), missingPermissions] as const;
};
