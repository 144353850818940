import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'fleet'>, MatchConditions>;

const LIST_FLEET_PERMISSIONS = ['fleet.fleet.list'];

const VIEW_FLEET_PERMISSIONS = ['fleet.fleet.view'];

const CREATE_FLEET_PERMISSIONS = ['fleet.fleet.new', 'admin.company.list'];

const UPDATE_FLEET_PERMISSIONS = ['fleet.fleet.edit', 'admin.company.list'];

const DELETE_FLEET_PERMISSIONS = ['fleet.fleet.delete'];

export const defineFleetAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(VIEW_FLEET_PERMISSIONS),
		create: getMissingPermissions(CREATE_FLEET_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_FLEET_PERMISSIONS),
		delete: getMissingPermissions(DELETE_FLEET_PERMISSIONS),
		list: getMissingPermissions(LIST_FLEET_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('fleet', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(DELETE_FLEET_PERMISSIONS)) can('delete', 'fleet');
	if (userHasPermission(CREATE_FLEET_PERMISSIONS)) can('create', 'fleet');
	if (userHasPermission(UPDATE_FLEET_PERMISSIONS)) can('edit', 'fleet');
	if (userHasPermission(VIEW_FLEET_PERMISSIONS)) can('view', 'fleet');
	if (userHasPermission(LIST_FLEET_PERMISSIONS)) can('list', 'fleet');

	return [build(), missingPermissions] as const;
};
