import { Close } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';

export default function CloseButton({ isDarkMode = false, handleClick, t }) {

	
	return (
		<Box
			sx={{
				color: (theme) => theme.palette.error.main,
				border: (theme) => `2px solid ${theme.palette.error.main}`,
				borderRadius: '50%',
				width: '34px',
				height: '34px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				'&:hover': {
					color: isDarkMode ? '#323C4D' : '#fff',
					background: (theme) => theme.palette.error.main
				}
			}}
			onClick={handleClick}
		>
			<Tooltip title={t('FINALIZE')}>
				<Close color="inherit" />
			</Tooltip>
		</Box>
	);
}
