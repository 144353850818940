import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const RegisterCommandsPage = lazyRetry(() => import('./RegisterCommands'));

i18next.addResourceBundle('en', 'RegisterCommandsPage', en);
i18next.addResourceBundle('pt', 'RegisterCommandsPage', pt);
i18next.addResourceBundle('sp', 'RegisterCommandsPage', sp);

const RegisterCommandsConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['admin.tracker-command.list'],
			path: '/admin/commands',
			element: (
				<LazyLoaderSuspense>
					<RegisterCommandsPage />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default RegisterCommandsConfig;
