import { Box, Stack, TextField } from '@mui/material';
import * as Plot from '@observablehq/plot';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';
import { BarChart } from 'app/shared-components/charts/BarChart';
import { ChartContainer } from 'app/shared-components/charts/ChartContainer';
import { LineChart } from 'app/shared-components/charts/LineChart';
import { NoChartData } from 'app/shared-components/charts/NoChartData';
import { StackedBarChart } from 'app/shared-components/charts/StackedChart';
import { useGetCompaniesQuery } from 'app/store/api/apiSlice';
import { useLazyGetMaintenanceReportQuery } from 'app/store/api/maintenanceSlice';
import { MaintenanceReportFilter } from 'app/store/api/types';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { getPrettyValue } from 'app/store/maintenance-report/maintenanceReportSlice';
import { DateRangeDialog } from './DateRangeDialog';

yup.addMethod(yup.object, 'dayjs', function method(message) {
	return this.test('dayjs', message, function validate(value) {
		if (!value) {
			return true;
		}
		return dayjs.isDayjs(value);
	});
});

const formSchema = yup.object({
	since: yup.mixed<Dayjs>().nullable().optional(),
	until: yup.mixed<Dayjs>().nullable().optional(),
	company: yup.object().nullable().required('COMPANY_REQUIRED')
});

const endDate = dayjs();
const startDate = endDate.subtract(7, 'days');

const initialForm = {
	since: startDate,
	until: endDate,
	company: null
};

type FormData = typeof initialForm;

function Content() {
	const { t } = useTranslation('maintenancePage');
	const dispatch = useDispatch();
	const [triggerReport, { isFetching, data: reportData }] = useLazyGetMaintenanceReportQuery();
	const { companies, isCompaniesLoading } = useGetCompaniesQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			companies: data?.docs || [],
			isCompaniesLoading: isLoading
		})
	});
	const { control, handleSubmit, watch } = useForm<FormData>({
		defaultValues: initialForm,
		resolver: yupResolver(formSchema)
	});

	const [since, until] = watch(['since', 'until']);
	const prettyValue = getPrettyValue(since, until);

	const openPeriodDialog = () =>
		dispatch(
			openDialog({
				children: <DateRangeDialog control={control} />
			})
		);

	const handleSearch = useCallback(
		async (params: MaintenanceReportFilter) => {
			try {
				await triggerReport(params).unwrap();
			} catch {
				dispatch(
					showMessage({
						variant: 'error',
						message: t('GET_REPORT_ERROR')
					})
				);
			}
		},
		[t, triggerReport, dispatch]
	);

	const onSubmit = handleSubmit(async (data) => {
		const parsed = {
			since: data.since.toISOString(),
			until: data.until.toISOString(),
			company: data.company._id
		};
		await handleSearch(parsed);
	});

	const handlePrint = () => {
		const report = document.getElementById('maintenance-report-content').innerHTML;
		const a = window.open('', '', 'height=800, width=600');
		a.document.write('<html>');
		a.document.write(
			'<style>* { font-family: Arial, sans-serif} @media print { .no-print { display: none}} </style>'
		);
		a.document.write(`<body > <h1>${t('MAINTENANCE_REPORT')}</h1> <h3>${prettyValue}</h3> <br>`);
		a.document.write(report);
		a.document.write('</body></html>');
		a.document.close();
		a.print();
	};

	return (
		<Box
			sx={{
				display: 'grid',
				gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
				gap: 2,
				paddingY: 2,
				paddingX: [1, 4],
				marginX: 'auto',
				overflowY: 'auto'
			}}
			id="maintenance-report-content"
		>
			<Stack direction={['column', 'row']} justifyContent={'space-between'} gridColumn={['span 1', 'span 2']}>
				<Box
					sx={{
						display: 'flex',
						gap: [0, 2],
						flexDirection: ['column', 'row'],
						mb: [2, 0]
					}}
					component={'form'}
					onSubmit={onSubmit}
				>
					<TextField
						onClick={openPeriodDialog}
						value={prettyValue}
						size="small"
						InputProps={{ className: 'no-print' }}
						helperText={' '}
						sx={{
							width: ['100%', '200px']
						}}
						label={t('PERIOD')}
					/>
					<HookedVirtualizedChipSelect
						control={control}
						name="company"
						optionLabel="name"
						t={t}
						loading={isCompaniesLoading}
						maxListHeight={200}
						variant="outlined"
						options={companies}
						label={t('COMPANIES')}
						isMultiple={false}
						sx={{
							width: ['100%', '240px']
						}}
						fullWidth
						required
					/>

					<AppButton variant="outlined" type="submit" loading={isFetching} className="no-print">
						{t('SEARCH')}
					</AppButton>
				</Box>
				<AppButton variant="contained" onClick={handlePrint}>
					{t('EXPORT_DATA')}
				</AppButton>
			</Stack>
			<ChartContainer title={t('MAINTENANCE_TOTAL_EXPENSES')}>
				<BarChart
					barOptions={{ x: 'type', y: 'totalPrice', fill: 'type' }}
					yScaleOptions={{ label: 'R$' }}
					xScaleOptions={{ tickFormat: (d) => t(String(d).toUpperCase()) }}
					data={reportData?.sumPriceByTypes ?? []}
					loading={isFetching}
					noDataComp={
						<NoChartData skeletonDirection="up" skeletonLen={6} loading={isFetching}>
							{t('NO_CHART_DATA')}
						</NoChartData>
					}
				/>
			</ChartContainer>
			<ChartContainer title={t('SCHEDULE_MAINTENANCE_EXPENSES_FORECAST')}>
				<LineChart
					data={reportData?.forecastPriceByMonths ?? []}
					yScaleOptions={{ label: 'R$' }}
					xScaleOptions={{ tickFormat: Plot.formatMonth('pt-BR') }}
					lineOptions={{ marker: true, x: 'month', y: 'totalPrice' }}
					loading={isFetching}
					noDataComp={
						<NoChartData skeletonDirection="up" skeletonLen={6} loading={isFetching}>
							{t('NO_CHART_DATA')}
						</NoChartData>
					}
				/>
			</ChartContainer>
			<ChartContainer title={t('MAINTENANCE_ITEMS_RANKING')}>
				<StackedBarChart
					data={reportData?.countByItems ?? []}
					loading={isFetching}
					barOptions={{ x: 'count', y: 'item', fill: 'item' }}
					textOptions={{ text: (d) => d.count, x: 'count', y: 'item' }}
					noDataComp={
						<NoChartData skeletonDirection="stack" skeletonLen={4} loading={isFetching}>
							{t('NO_CHART_DATA')}
						</NoChartData>
					}
				/>
			</ChartContainer>
			<ChartContainer title={t('MAINTENANCE_FLEET_RANKING')}>
				<StackedBarChart
					data={reportData?.countByFleets ?? []}
					loading={isFetching}
					barOptions={{ x: 'count', y: 'fleet', fill: 'fleet' }}
					textOptions={{ text: (d) => d.count, x: 'count', y: 'fleet' }}
					noDataComp={
						<NoChartData skeletonDirection="stack" skeletonLen={4} loading={isFetching}>
							{t('NO_CHART_DATA')}
						</NoChartData>
					}
				/>
			</ChartContainer>
			<Box gridColumn={['span 1', 'span 2']}>
				<ChartContainer title={t('FLEET_PERIOD_MEDIAN')}>
					<BarChart
						data={reportData?.avgPriceByFleet ?? []}
						yScaleOptions={{ label: 'R$' }}
						xScaleOptions={{ tickRotate: -30, inset: 20 }}
						marginBottom={60}
						barOptions={{ sort: { x: 'y', reverse: true } }}
						loading={isFetching}
						noDataComp={
							<NoChartData skeletonDirection="up" skeletonLen={12} loading={isFetching}>
								{t('NO_CHART_DATA')}
							</NoChartData>
						}
					/>
				</ChartContainer>
			</Box>
			<ChartContainer title={t('YEARLY_MEDIAN')}>
				<BarChart
					data={reportData?.avgPriceByFleetLastYear ?? []}
					yScaleOptions={{ label: 'R$' }}
					barOptions={{ x: 'fleet', y: 'avgPrice', fill: 'fleet' }}
					loading={isFetching}
					noDataComp={
						<NoChartData skeletonDirection="up" skeletonLen={4} loading={isFetching}>
							{t('NO_CHART_DATA')}
						</NoChartData>
					}
				/>
			</ChartContainer>
			<ChartContainer title={t('FOUR_WEEKS_MEDIAN')}>
				<BarChart
					data={reportData?.avgPriceByFleetLast4Weeks ?? []}
					yScaleOptions={{ label: 'R$' }}
					barOptions={{ x: 'fleet', y: 'avgPrice', fill: 'fleet' }}
					loading={isFetching}
					noDataComp={
						<NoChartData skeletonDirection="up" skeletonLen={4} loading={isFetching}>
							{t('NO_CHART_DATA')}
						</NoChartData>
					}
				/>
			</ChartContainer>
		</Box>
	);
}

export { Content };
