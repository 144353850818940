import { AbilityBuilder, MatchConditions, PureAbility } from "@casl/ability";
import { SYSTEM_ADMIN_PERMISSION } from "src/constants";
import { defineAdminAbility } from "src/utils/define-admin-ability";
import userHasPermission from "src/utils/useHasPermission";
import { getMissingPermissions } from "../helper";
import { AbilityTuple, MissingPermissions } from "../types";

type RulerAbility = PureAbility<AbilityTuple<'history'>, MatchConditions>;

const LIST_HISTORY_PERMISSIONS = ['reports.analytic.list'];

const VIEW_HISTORY_PERMISSIONS = ['reports.analytic.view'];

export const defineHistoryAbility = () => {
  const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

  const missingPermissions: MissingPermissions = {
    list: getMissingPermissions(LIST_HISTORY_PERMISSIONS),
    view: getMissingPermissions(VIEW_HISTORY_PERMISSIONS),
    create: [],
    edit: [],
    delete: []
  };

  if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
    defineAdminAbility('history', can);
    return [build(), missingPermissions] as const;
  }

  if (userHasPermission(LIST_HISTORY_PERMISSIONS)) can('list', 'history');
  if (userHasPermission(VIEW_HISTORY_PERMISSIONS)) can('view', 'history');

  return [build(), missingPermissions] as const;
}