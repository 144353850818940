const sp = {
	// HEADER
	FILTER: 'Filtrar',
	FILTER_DESCRIPTION: 'Los campos a continuación mostrarán datos solo de las empresas seleccionadas.',
	EXPORT: 'Exportar datos',
	TITLE: 'Informe de envío de paquetes',

	// MODAL
	DATE: 'Fecha',

	RESET: 'Limpiar filtros',
	SEARCH: 'Buscar',

	TRACKER: 'Rastreador',
	SELECTED_TRACKERS: '{{n}} Rastreadores seleccionados',

	COMPANY: 'Empresa',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',

	VEHICLE: 'Vehículo',
	SELECTED_VEHICLES: '{{n}} Vehículos seleccionados',

	AT_LEAST_ONE_FILTER: 'Al menos un filtro debe ser seleccionado',
	START_DATE_REQUIRED: 'Campo obligatorio',
	END_DATE_REQUIRED: 'Campo obligatorio',

	WEEKLY_METRICS: 'Paquetes enviados',
	BY_WEEK: 'Por semana',

	MONTHLY_METRICS: 'Paquetes enviados',
	BY_MONTH: 'Por mes',

	DAILY_METRICS: 'Paquetes enviados',
	BY_DAY: 'Por día',

	SELECTED_MONTH: 'Mes seleccionado',
	LAST_MONTH: 'Mes anterior',

	NO_DATA: 'No hay datos',
	PACKETS_SENT: 'Paquetes enviados',

	NO_FILTER_COMPONENT_TEXT: 'Ningún filtro seleccionado',
	NO_FILTER_COMPONENT_TEXT_SECONDARY: 'Seleccione uno o más filtros para ver los datos',

	TRACKERS: 'Rastreadores',
	BY_STATE: 'Por estado',

	IDLE: 'Inactivo',
	MOVING: 'En movimiento',
	NO_COMM: 'Sin comunicación',
	WRONG_POSITION: 'Posicionamiento incorrecto',
	TOWED: 'Remolcado',
	CONNECTED: 'Conectado',
	WITHOUT_VEHICLE: 'Sin vehículo',
	BLOCKED: 'Bloqueado',

	PACKETS: 'Paquetes',
	TOTAL_PACKETS_SEND_BY_TRACKERS: 'Total de paquetes enviados por rastreadores',

	TOP_FIVE_TRACKERS: 'Top cinco rastreadores que más envían paquetes'
};

export default sp;
