export default {
	PARTIAL: 'Partial',
	FULL: 'Full',
	NO_ACCESS: 'No access',
	START: 'Start',
	END: 'End',
	TO: 'to',
	ACCESS_TYPE: 'Access Type',
	ACTIVE: 'Active',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	COMPANY: 'Company',

	NAME_REQUIRED: 'Name is required',
	DESCRIPTION_REQUIRED: 'Description is required',
	COMPANY_REQUIRED: 'Company is required',

	MONDAY: 'Monday',
	TUESDAY: 'Tuesday',
	WEDNESDAY: 'Wednesday',
	THURSDAY: 'Thursday',
	FRIDAY: 'Friday',
	SATURDAY: 'Saturday',
	SUNDAY: 'Sunday',

	REMOVE_PERIOD: 'Remove period',
	ADD_PERIOD: 'Add another period',

	SAVE: 'Save',
	CLEAR: 'Clear',
	DELETE: 'Delete',
	VINCULATE: 'Vinculate',

	CONFIGURE_PERIODS: 'Configure periods',

	ACCESS_PERIOD_UPDATED_SUCCESS: 'Access period updated successfully',
	ACCESS_PERIOD_CREATED_SUCCESS: 'Access period created successfully',

	ACCESS_PERIOD_UPDATED_ERROR: 'Error updating access period',
	ACCESS_PERIOD_CREATED_ERROR: 'Error creating access period',

	ACCESS_PERIOD_DELETED_SUCCESS: 'Access period deleted successfully',
	ACCESS_PERIOD_DELETED_ERROR: 'Error deleting access period',

	DELETE_ACCESS_PERIOD_PRIMARY: 'Are you sure you want to delete the access period?',
	DELETE_ACCESS_PERIOD_SECONDARY: 'This action cannot be undone',
	DELETE_ACCESS_PERIOD_TITLE: 'Delete access period',
	PERIOD_NOT_OVERLAP: 'Periods cannot overlap'
};
