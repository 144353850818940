const locale = {
	NOTES: 'Notes',
	course: 'course',
	TITLE: 'courses',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	PROFILES: 'Profiles',
	PROFILE: 'Profile',
	USERS: 'Users',
	USER: 'User',
	BIRTHDAY: 'Birth date',
	PASSWORD: 'Password',
	CPASSWORD: 'Confirm password',
	DATE: 'Date',
	OF: 'of',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Accessible hosts',
	ADDRESS: 'Address',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Phone',
	SELECTED_ITEMS: 'Selected items',
	NO_ITEM_SELECTED: 'No item selected',
	EXPORT_SELECTED: 'Export selected items',
	DELETE_SELECTED: 'Delete selected items',
	EDIT_SELECTED: 'Edit selected items',
	ROWS_PER_PAGE: 'Items per page',
	NAME_REQUIRED: 'The name field is required',
	PHONE_REQUIRED: 'The phone field is required',
	EMAIL_REQUIRED: 'The email field is required',
	ADDRESS_REQUIRED: 'The address field is required',
	COLUMN_NAME: 'Name',
	COLUMN_COMPANY: 'Company',
	COLUMN_BIRTHDAY: 'Birth date',
	COLUMN_ID: 'ID',
	COLUMN_CREATED_BY: 'Created by',
	COLUMN_CREATED_AT: 'Created at',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Address',
	COLUMN_PHONE: 'Phone',
	NAME: 'Name',
	CODE: 'Code',
	DESC: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',
	DELETE_TITLE: 'Delete course',
	DELETE_CONFIRMATION: 'Are you sure you want to delete',
	DELETE_CONFIRMATION_2: 'This action cannot be undone.',
	EDIT_TITLE: 'Editing course',
	EDIT_CONFIRMATION: 'Are you sure you want to edit this course?',
	EDIT_CONFIRMATION_MULTIPLE: 'Are you sure you want to edit the selected trackers?',
	EDIT_CONFIRMATION_2: 'This action cannot be undone.',
	ERROR_PERMISSION: 'You do not have the necessary permissions, please contact the system administrator',
	ERROR_GETTING_USERS: 'There was an error loading the courses, please try again later',
	ERROR_RESPONSE_404: 'Error: Data not found, please contact the system administrator',
	ERROR_INTERNAL_SERVER_ERROR: 'Internal server error, please try again later',
	ERROR_NETWORK: 'Network error, check your connection and try again',
	ADD_USER: 'Add tracker',
	NO_DATA: 'No data found',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'You do not have permission to view the list of courses',
	NO_PERMISSION_new: 'You do not have permission to add courses',
	NO_PERMISSION_edit: 'You do not have permission to edit courses',
	NO_PERMISSION_view: 'You do not have permission to view courses',
	NO_PERMISSION_delete: 'You do not have permission to delete courses',
	NO_ADDRESS: 'Address not found',
	NO_PHONE: 'Phone not found',
	NO_EMAIL: 'Email not found',
	NO_BIRTHDAY: 'Birthday not found',
	LOADING: 'Loading',
	UPLOAD_ERROR: 'Error uploading file',
	PASSWORD_MIN_LENGTH: 'Password must be at least 8 characters',
	PASSWORDS_MUST_MATCH: 'Passwords must match',
	PASSWORD_MIN_LOWERCASE: 'Password must have at least 1 lowercase letter',
	PASSWORD_MIN_UPPERCASE: 'Password must have at least 1 uppercase letter',
	PASSWORD_MIN_NUMBERS: 'Password must have at least 1 number',
	PASSWORD_MIN_SYMBOLS: 'Password must have at least 1 special character',
	MORE_THAN: 'more than',
	NO_USER_FOUND: 'No course found',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} courses?',
	EDIT_INFO: 'Edit course',
	DELETE_INFO: 'Delete course',
	DOWNLOAD_INFO: 'Download selected courses',
	EDIT_MULTIPLE_INFO: 'Edit selected courses',
	DELETE_MULTIPLE_INFO: 'Delete selected courses',
	SELECTED_ITENS: '{{n}} items selected',
	DOWNLOAD_ERROR: 'Error downloading courses',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} companies selected',
	SELECTED_HOSTS: '{{n}} hosts selected',
	PROFILE_REQUIRED: 'The Profile field is required',
	CPASSWORD_REQUIRED: 'The Confirm Password field is required',
	EDIT_MULTIPLE_SUCCESS: 'courses edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing courses',
	PROFILE_IMAGE_TO_LARGE: 'Please choose an image smaller than 8MB',
	EMAIL_INVALID: 'Invalid email',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page.",
	MINIMAL_START: 'Ask the Administrator to add the permissions to your profile:',
	MINIMAL_END: 'Then log out and log in from the system.',
	PLATE_INFO: 'Depending on the type of course, the plate may not be mandatory.',
	MORE_INFO: 'More info',
	DOWNLOAD_ALL: 'Download all',
	LESSON_TITLE: "Lesson Title",
	COURSE_TITLE: "Course Title",
	DESCRIPTION: "Description",
	CATEGORY: "Category",
	CATEGORY_NAME: "Category Name",
	CONTINUE: "Continue",
	LESSONS: "Lessons",
	SELECTED_LESSONS: "{{n}} Selected Lessons",
	SELECTED_CATEGORIES: "{{n}} Selected Categories",
	ADD_COURSE: "Create Course",
	SEARCH_COURSE_LABEL: "Search Course",
	ALL: "All",
	NO_COURSE_FOUND: "No course found",
	DELETE_COURSE_MODAL_TITLE: "Delete Course?",
	DELETE_COURSE_MODAL_DESCRIPTION: "Are you sure you want to delete this course?",
	EDIT_MULTIPLE_SUCCESS_COURSE: "Courses edited successfully",
	BACK_TO_COURSES: "Back to Courses",
	BACK:  "Back",
	NEXT: "Next",
	CREATE_SUCCESS_COURSE: 'Course added successfully',
	CREATE_ERROR_COURSE: 'Error adding course',
	EDIT_SUCCESS_COURSE: 'Course updated successfully',
	EDIT_ERROR_COURSE: 'Error updating course',
	ERROR_ADD_EMPTY: 'Fill in all fields',
	DELETE_SUCCESS_COURSE: 'Course deleted successfully',
	DELETE_ERROR_COURSE: 'Error deleting course',
	CREATE_SUCCESS_LESSON: "Lesson added successfully",
	EDIT_SUCCESS_LESSON: "Lesson updated successfully",
	DELETE_SUCCESS_LESSON: "Lesson deleted successfully",
	EDIT_MULTIPLE_SUCCESS_LESSON: "Lessons edited successfully", 
	CREATE_ERRROR_LESSON: "Error adding lesson",
	EDIT_ERROR_LESSON: "Error updating lesson",
	DELETE_ERROR_LESSON: "Error deleting lesson",
	EDIT_MULTIPLE_ERROR_LESSON: "Error editing lessons",
	CATEGORY_NAME_REQUIRED: "The category name field is required",
	TITLE_REQUIRED: "The title field is required",
	VIDEO_REQUIRED: "The video field is required",
	CATEGORY_REQUIRED: "The category field is required",
	LESSONS_REQUIRED: "The lessons field is required",
	COURSE_TITLE_REQUIRED: "The course title field is required",
	SEARCH_LESSON_LABEL: "Search Lesson",
	ADD_LESSON: "Add Lesson",
	WATCH_LESSON: "Watch Lesson",
	DELETE_LESSON_MODAL_TITLE: "Delete Lesson?", 
	DELETE_LESSON_MODAL_DESCRIPTION: "Are you sure you want to delete this lesson?",	
	COURSES: "Courses",
	COURSES_HEADER_DESCRIPTION: 'Knowledge trails for you to learn more about our products and services.',
	LESSONS_HEADER_DESCRIPTION: 'All Classes Available for you.',
	BACK_TO_LESSONS: "Back to Lessons",
	  
	  

};
export default locale;
