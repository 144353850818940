import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TPanel } from 'app/main/panels/types';
import { RootState } from '..';

type TInitialState = {
	selectedPanel: TPanel;
};

const initialState: TInitialState = {
	selectedPanel: null
};

const panelSlice = createSlice({
	name: 'panelCrud',
	initialState,
	reducers: {
		setSelectedPanel: (state, action: PayloadAction<TPanel>) => {
			state.selectedPanel = action.payload;
		}
	}
});

export const { setSelectedPanel } = panelSlice.actions;
export const selectSelectedPanel = (state: RootState) => state.panelCrud.selectedPanel;

export default panelSlice.reducer;
