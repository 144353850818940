import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSidebarMode } from 'app/main/vehicle/types';
import { RootState } from '..';

type TInitialState = {
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	isRegisterDrawerOpen: boolean;
	currentRegisterDrawerForm: string;
};
const initialState: TInitialState = {
	isSidebarOpen: false,
	sidebarMode: 'create',
	isRegisterDrawerOpen: false,
	currentRegisterDrawerForm: 'lesson'
};

const crudSlice = createSlice({
	name: 'coursesCrud',
	initialState,
	reducers: {
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		toggleRegisterDrawer: (state) => {
			state.isRegisterDrawerOpen = !state.isRegisterDrawerOpen;
		},
		setCurrentRegisterDrawerForm: (state, action: PayloadAction<string>) => {
			state.currentRegisterDrawerForm = action.payload;
		}
	}
});

export const { setIsSidebarOpen, setSidebarMode, toggleRegisterDrawer, setCurrentRegisterDrawerForm } =
	crudSlice.actions;

export const selectIsSidebarOpen = (state: RootState) => state.coursesCrud.isSidebarOpen;
export const selectSidebarMode = (state: RootState) => state.coursesCrud.sidebarMode;
export const selectIsRegisterDrawerOpen = (state: RootState) => state.coursesCrud.isRegisterDrawerOpen;
export const selectCurrentRegisterDrawerForm = (state: RootState) => state.coursesCrud.currentRegisterDrawerForm;

export default crudSlice.reducer;
