import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectUserPermissions } from 'app/store/user/userSlice';

const NO_PERMISSIONS = {
	isAdmin: false,
	hasView: false,
	hasList: false,
	hasNew: false,
	hasEdit: false,
	hasDelete: false,
	hasMinimal: false
};

const ALL_PERMISSIONS = {
	isAdmin: true,
	hasView: true,
	hasList: true,
	hasNew: true,
	hasEdit: true,
	hasDelete: true,
	hasMinimal: true
};

const ADMIN_PERMISSION = 'system.admin';

type TUsePermissionParams = {
	pagePermissions: { view: string; list: string; edit: string; delete: string; new: string; minimal: string[] };
};

export type TUsePermissionReturn = {
	isAdmin: boolean;
	hasView: boolean;
	hasList: boolean;
	hasNew: boolean;
	hasEdit: boolean;
	hasDelete: boolean;
	hasMinimal: boolean;
};

export default function usePermission({ pagePermissions }: TUsePermissionParams): TUsePermissionReturn {
	const loggedUserPermissions = useSelector(selectUserPermissions);

	const userPermissions = useMemo(() => {
		if (!loggedUserPermissions) {
			return NO_PERMISSIONS;
		}

		const permissionCodes: string[] = loggedUserPermissions.map((p) => p.code);

		if (permissionCodes.includes(ADMIN_PERMISSION)) {
			return ALL_PERMISSIONS;
		}

		const permissions: TUsePermissionReturn = {
			isAdmin: false,
			hasView: permissionCodes.some((c: string) => c === pagePermissions.view),
			hasList: permissionCodes.some((c: string) => c === pagePermissions.list),
			hasNew: permissionCodes.some((c: string) => c === pagePermissions.new),
			hasEdit: permissionCodes.some((c: string) => c === pagePermissions.edit),
			hasDelete: permissionCodes.some((c: string) => c === pagePermissions.delete),
			hasMinimal: pagePermissions.minimal.length
				? pagePermissions.minimal.every((c: string) => permissionCodes.includes(c))
				: true
		};
		return permissions;
	}, [loggedUserPermissions, pagePermissions]);

	return userPermissions;
}
