import i18next from 'i18next';

import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';
import Monitoring from './Monitoring';

i18next.addResourceBundle('en', 'monitoringPage', en);
i18next.addResourceBundle('sp', 'monitoringPage', sp);
i18next.addResourceBundle('pt', 'monitoringPage', pt);

export const MONITORING_POLLING = 30000;

export const MONITORING_PERMISSIONS = {
	edit: 'monitoring.alerts.edit',
	view: 'monitoring.alerts.view',
	list: 'monitoring.alerts.list',
	new: 'monitoring.alerts.new',
	delete: 'monitoring.alerts.delete',
	minimal: []
};

const MonitoringConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['monitoring.alerts.list'],
			path: '/monitoring/monitoring',
			element: (
				<Monitoring />
			)
		}
	]
};

export default MonitoringConfig;
