import { THIRTY_ONE_DAY_ERROR_MESSAGE_EN } from "src/constants";

const locale = {
	TITLE: 'Journey Report',
	DOWNLOAD_ALL: 'Download all',
	DOWNLOAD_SELECTED: 'Download selected',

	REPORT_HISTORY: 'History Report',
	COMPANY: 'Company',

	SHOW_FILTERS: 'Show Filters',
	CONFIG_TABLE: 'Configure table',
	TRACKER_REALTIME: 'Real-time of this tracker',
	SEE_REALTIME_MAP: 'Go to real-time map',
	SUMMARY_HISTORY: 'Summary of this History',
	DURATION: 'Duration',
	MILEAGE: 'Mileage',
	FILTER_DESCRIPTION: 'The fields below will show data only from selected companies.',
	THIRTY_DAY_ERROR: THIRTY_ONE_DAY_ERROR_MESSAGE_EN,


	OPEN_ADVANCED: 'Advanced History',

	SELECT_TRIP: 'Select trip',
	NO_TRIPS: 'No trips found',
	TRIPS: 'Journeys',
	TRIP_DETAILS: 'Trip details',
	SELECT_TRACKER: 'Select a tracker',
	TRACKER_REQUIRED: 'Tracker selection required',
	COMPANY_REQUIRED: 'Company is mandatory',
	DRIVERS: 'Drivers',
	DRIVERS_SELECTED: '{{n}} drivers',

	NO_TRACKERS_IN_COMPANIES: 'No trackers available for selected companies',
	NO_VEHICLES_IN_COMPANIES: 'No vehicles available for selected companies',
	NO_FLEETS_IN_COMPANIES: 'No fleets available for selected companies',
	NO_DRIVERS_IN_COMPANIES: 'No drivers available for selected companies',
	ERROR_GETTING_TRIPS: 'Error getting trips',

	START_TIME: 'Start date and time',
	END_TIME: 'End date and time',
	AVG_SPEED: 'Average speed',
	NO_FILTERS: 'No filters applied',
	NO_FILTERS_SUBTITLE: 'Select the filters you want to apply to see the results',

	COULDNT_FIND_TRIP: 'Could not find trip data',
	COULDNT_FIND_TRIP_END: 'Please contact the system administrator.    Trip ID: {{id}}',

	END_SHALL_BE_GREATER: 'End date must be greater than start date',
	AT_LEAST_ONE_FILTER: 'At least one filter must be selected',

	CLOSE: 'Close',

	EVENT_TYPE: 'Event',
	GPS_TIME: 'GPS Time',
	ALTITUDE: 'Altitude',
	BEARING: 'Bearing',
	SATELLITES: 'Satellites',
	LATITUDE: 'Latitude',
	LONGITUDE: 'Longitude',
	ADDRESS_CITY: 'City',
	ADDRESS_COUNTRY: 'Country',
	ADDRESS_POSTCODE: 'Postal code',
	ADDRESS_ROAD: 'Road',
	ADDRESS_STATE: 'State',
	ADDRESS_SUBURB: 'Suburb',
	HOURMETER: 'Hourmeter',
	SPEED: 'Speed',
	IGNITION_ON: 'Ignition on',
	IGNITION_OFF: 'Ignition off',
	GPS_FIXED: 'GPS fixed',
	GPS_NOT_FIXED: 'GPS not fixed',
	SIGNAL_LEVEL: 'Signal level',
	ODOMETER: 'Odometer',
	BATTERY: 'Battery',
	OPERATOR: 'Operator',
	YES: 'Yes',
	NO: 'No',
	BACK: 'Back',
	UNAVAILABLE: 'Unavailable',
	START_ADDRESS: 'Start address',
	END_ADDRESS: 'End address',

	TOTAL_DURATION: 'Total duration',
	TOTAL_DISTANCE: 'Total distance',
	TOTAL: 'Total journeys',
	SUMMARY_DESCRIPTION: 'To see details of multiple journeys, select them in the table and click "Generate summary"',
	SUMMARY: 'Summary'
};

export default locale;
