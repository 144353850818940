import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import _ from '@lodash';
import IconButton from '@mui/material/IconButton';
import { navbarToggle, navbarToggleMobile } from 'app/store/fuse/navbarSlice';
import { changeFuseSettings, selectFuseCurrentSettings } from 'app/store/fuse/settingsSlice';
import { useAppDispatch } from 'app/store/index';
import { useSelector } from 'react-redux';

function NavbarToggleButton(props: any) {
	const dispatch = useAppDispatch();
	const isMobile = useThemeMediaQuery((theme: any) => theme.breakpoints.down('lg'));
	const settings = useSelector(selectFuseCurrentSettings);
	const { config } = settings.layout;

	return (
		<IconButton
			className={props.className}
			color="inherit"
			size="small"
			onClick={(ev) => {
				if (isMobile) {
					dispatch(navbarToggleMobile());
				} else if (config.navbar.style === 'style-2') {
					dispatch(
						changeFuseSettings(
							_.set({}, 'layout.config.navbar.folded', !settings.layout.config.navbar.folded)
						)
					);
				} else {
					dispatch(navbarToggle());
				}
			}}
		>
			{props.children}
		</IconButton>
	);
}

NavbarToggleButton.defaultProps = {
	children: (
		<FuseSvgIcon size={20} color="action">
			heroicons-outline:view-list
		</FuseSvgIcon>
	)
};

export default NavbarToggleButton;
