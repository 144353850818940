const locale = {
	// Common

	EMAIL: 'Email',
	PHONE: 'Phone',
	MOBILE: 'Mobile',
	CNH: "Driver's License",
	CNH_TYPE: "Driver's License Type",
	CNH_DUE_DATE: "Driver's License Due Date",
	CPF: 'CPF',
	RG: 'ID',
	RG_TYPE: 'Issuer',
	RG_CREATED_DATE: 'ID Issuance Date',
	BIRTHDAY: 'Birthdate',
	NOTES: 'Notes',
	USER: 'User',
	ADDRESS: 'Address',
	SELECTED_COMPANIES: '{{n}} Selected Companies',
	DATE: 'Date',
	COMPANY: 'Company',
	COMPANIES: 'Companies',
	NAME: 'Name',
	DESCRIPTION: 'Description',
	CREATED_AT: 'Created At',
	CREATED_BY: 'Created By',
	ADD_DRIVER: 'Add Drivers',
	LOADING: 'Loading',
	CONFIRM: 'Confirm',

	// Header

	TITLE: 'Drivers',
	IMPORT_DRIVERS: 'Import Drivers',
	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear Filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',
	DOWNLOAD_ALL: 'Download All',

	// Sidebar

	EDIT_INFO: 'Edit Driver',
	DELETE_INFO: 'Delete Driver',
	DOWNLOAD_INFO: 'Download Selected Drivers',
	EDIT_MULTIPLE_INFO: 'Edit Selected Drivers',
	DELETE_MULTIPLE_INFO: 'Delete Selected Drivers',
	SELECTED_ITENS: '{{n}} Selected Items',
	DOWNLOAD_ERROR: 'Error downloading drivers',
	EDIT_MULTIPLE_SUCCESS: 'Drivers edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing drivers',

	// Required Fields

	COMPANY_REQUIRED: 'Company field is required',
	NAME_REQUIRED: 'Name field is required',
	EMAIL_REQUIRED: 'Email field is required',
	INVALID_EMAIL: 'Invalid email',
	INVALID_COMPANY: 'Invalid company',

	// Table

	OF: 'of',
	ROWS_PER_PAGE: 'Items per page',
	NO_DRIVER_FOUND: 'No drivers found',

	// Request

	CREATE_SUCCESS: 'Driver added successfully',
	CREATE_ERROR: 'Error adding tracker',
	EDIT_SUCCESS: 'Driver updated successfully',
	EDIT_ERROR: 'Error updating driver',
	DELETE_SUCCESS: 'Driver deleted successfully',
	DELETE_ERROR: 'Error deleting driver',
	MASSIVE_CREATE_SUCCESS: 'Drivers added successfully',
	MASSIVE_CREATE_ERROR: 'Error adding drivers, check the data and try again',

	// Modal

	DELETE_MODAL_TITLE: 'Delete Driver',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this driver?',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} drivers?',

	// Permissions

	PAGE_NOT_ALLOWED: 'You are not allowed to view this page.',
	MINIMAL_START: 'Ask the Administrator to add the following permissions to your profile:',
	MINIMAL_END: 'Then log out and log back into the system.',

	// Bulk

	SHOW_INVALID_ROWS: 'Show invalid rows',
	CLEAR_TABLE: 'Clear table',
	CREATING: 'Creating',
	DESCRIPTION_HEADER:
		'Here you can create drivers in bulk, just upload a XLSX file with the drivers data, or download the template and fill in the data of the drivers you want to create, you can open the XLSX file with Excel or another spreadsheet editor.',
	DOWNLOAD_TEMPLATE: 'Download template.',
	IMPORT: 'Import',
	DROPZONE_TEXT: 'Drag and drop the XLSX file here or click to select.',
	REMOVE_ROW: 'Remove rows',

	// Error messages
	USER_ALREADY_REGISTERED_AS_A_DRIVER: 'The selected user has already been registered as a driver.',

	CREATING_DRIVER: 'Creating driver',
	VALIDATING_IBUTTON: 'Validating iButton',
	CREATING_IBUTTON: 'Creating iButton',
	DRIVER_CREATED: 'Driver created',
	LINK_HEADER:
		"Here you can register drivers and their respective identifiers, just download and fill in the .xlsx template below with the data of the drivers and their identifiers, and click on 'Import'. The identifiers will be validated and created automatically if they do not already exist in the provided company, if they already exist, they will only be linked to them.",
	LINK_IBUTTON_DRIVER_SUCCESS: 'Drivers linked successfully',
	LINK_IBUTTON_DRIVER_ERROR: 'Error linking drivers, check the data and try again',
	LINK_IBUTTON_DRIVER: 'Link Drivers',

	COMPANY_MUST_BE_NUMBER: 'Company must be a CNPJ or CPF number withou special characters',
	PHONE_LENGHT_AND_FORMAT_WARNING:
		'The phone number must have 11 digits, no special caracteres , including the area code, for example: 11999999999',
	SHOW_FILTERS: 'Show filters',
	CONFIG_TABLE: 'Configure table',	
	CLEAR_FILTERS: 'Clear filters',
	};

export default locale;
