import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TSidebarMode, TUrl } from 'app/main/urls/types';
import { RootState } from '..';

type TInitialState = {
	selectedRows: TUrl[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
};

const initialState: TInitialState = {
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view'
};

const urlsCrudSlice = createSlice({
	name: 'urlsCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TUrl[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		}
	}
});

export const { setIsSidebarOpen, setSidebarMode, setSelectedRows, resetSidebarState } = urlsCrudSlice.actions;
export const selectIsSidebarOpen = (state: RootState) => state.urlsCrud.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.urlsCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.urlsCrud.selectedRows as TUrl[];

export default urlsCrudSlice.reducer;
