import { EventStreamContentType, FetchEventSourceInit, fetchEventSource } from '@microsoft/fetch-event-source';
import { useCallback, useMemo, useState } from 'react';
import storageService from '../storage';

const Authorization = 'Bearer ' + storageService.get('token');

type TUseSSEReturn<T> = [
	(_body?: unknown) => Promise<void>,
	{
		data: T;
		loading: boolean;
		error: Error | null;
		reset: () => void;
	}
];

type TOptions<T> = Omit<FetchEventSourceInit, 'body'> & {
	defaultValue?: T;
	onMessage?: (_data: T) => void;
};

type TUploadInfo<T> = {
	data: T;
	loading: boolean;
	error: Error | null;
};

export const INVALID_REQUEST = 'INVALID_REQUEST';
export const INVALID_RESPONSE = 'INVALID_RESPONSE';

export const useSSE = <T>(url: string, options?: TOptions<T>): TUseSSEReturn<T> => {
	const [uploadInfo, setUploadInfo] = useState<TUploadInfo<T>>({
		data: options?.defaultValue ?? null,
		loading: false,
		error: null
	});

	const parsedUrl = url?.startsWith('/') ? url : `/${url}`;
	const controller = useMemo(() => new AbortController(), []);

	const fetcher = useCallback(
		async (body: FetchEventSourceInit['body']) => {
			setUploadInfo((prev) => ({ ...prev, loading: true }));
			try {
				await fetchEventSource(import.meta.env.VITE_API_URL + parsedUrl, {
					openWhenHidden: true,
					method: 'POST',
					headers: {
						Authorization,
						'Content-Type': 'application/json'
					},
					async onopen(response) {
						if (response.ok && response.headers.get('content-type') === EventStreamContentType) return;
						if (response.status === 400) throw new Error(INVALID_REQUEST);
						throw new Error(INVALID_RESPONSE);
					},
					onmessage: (msg) => {
						if (!msg.data) return;
						const data = JSON.parse(msg.data) as T;

						setUploadInfo((prev) => ({ ...prev, data }));
						options?.onMessage?.(data);
					},
					onerror: (err: Error) => {
						setUploadInfo((prev) => ({ ...prev, error: err }));
						throw new Error(err.message);
					},
					body: JSON.stringify(body),
					signal: controller.signal,
					...options
				});
			} catch (error) {
				setUploadInfo((prev) => ({ ...prev, error }));
				throw new Error(error);
			} finally {
				setUploadInfo((prev) => ({ ...prev, loading: false }));
			}
		},
		[options, parsedUrl, controller.signal]
	);

	const reset = useCallback(() => {
		controller.abort();
		setUploadInfo({
			data: options?.defaultValue,
			error: null,
			loading: false
		});
	}, [options, controller]);

	return [
		fetcher,
		{
			data: uploadInfo?.data,
			error: uploadInfo?.error,
			loading: uploadInfo?.loading,
			reset
		}
	];
};
