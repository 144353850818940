const locale = {
	TITLE: 'Sessions',

	ADD: 'Add',
	YES: 'Yes',
	NO: 'No',
	SEARCH: 'Search',
	CLEAR_SEARCH: 'Clear filters',
	EDIT: 'Edit',
	VIEW: 'View',
	SAVE: 'Save',
	DELETE: 'Delete',
	CANCEL: 'Cancel',

	ON_DISCONNECT_ERROR: 'Error disconnecting WhatsApp session',
	DISCONNECT: 'Disconnect',
	QR_CODE_INFO: 'To log in to the session, scan the QR Code above',

	SELECTED_COMPANIES: '{{n}} Selected Companies',
	ALREADY_IN_SESSION: 'There is already an active session for the companies: {{companies}}',

	COMPANY: 'Company',
	COMPANIES: 'Companies',

	DATE: 'Date',
	OF: 'of',

	ROWS_PER_PAGE: 'Items per page',

	COMPANY_REQUIRED: 'The company field is required',
	NAME_REQUIRED: 'The name field is required',
	DESCRIPTION_REQUIRED: 'The description field is required',

	NAME: 'Name',
	DESCRIPTION: 'Description',
	CREATED_AT: 'Created at',
	CREATED_BY: 'Created by',

	ADD_SESSION: 'Add sessions',
	CREATE_SUCCESS: 'Session successfully added',
	CREATE_ERROR: 'Error adding session',
	EDIT_SUCCESS: 'Session successfully updated',
	EDIT_ERROR: 'Error updating session',
	DELETE_SUCCESS: 'Session successfully deleted',
	DELETE_ERROR: 'Error deleting session',
	NO_SESSION_FOUND: 'No session found',

	LOADING: 'Loading',
	DELETE_MODAL_TITLE: 'Delete session',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this session?',
	CONFIRM: 'Confirm',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Are you sure you want to delete {{n}} sessions?',
	EDIT_INFO: 'Edit session',
	DELETE_INFO: 'Delete session',
	DOWNLOAD_INFO: 'Download selected sessions',
	EDIT_MULTIPLE_INFO: 'Edit selected sessions',
	DELETE_MULTIPLE_INFO: 'Delete selected sessions',
	SELECTED_ITENS: '{{n}} selected items',
	DOWNLOAD_ERROR: 'Error downloading sessions',
	EDIT_MULTIPLE_SUCCESS: 'Sessions edited successfully',
	EDIT_MULTIPLE_ERROR: 'Error editing sessions',
	PAGE_NOT_ALLOWED: "You don't have permission to see this page."
};

export default locale;
