import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import { isColumnOmitted } from '../users/tableColumns';
import { TColumnsNames, TIbuttonColumn } from './types';

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('CODE'), columnId: 'code' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('DRIVER'), columnId: 'driver.name' },
	{ name: t('NOTES'), columnId: 'notes' }
];
export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('CODE'), columnId: 'code' },
	{ name: t('NOTES'), columnId: 'notes' }
];

export const columnsSearchKeys = ['code', 'notes' ];

export const defaultVisibleColumns = ['code', 'company.name', 'driver.name', 'notes', 'createdAt', 'createdBy.name'];

export const columnSortKeys = ['_id', 'code', 'notes', 'createdAt'];

export const getColumns = (t: TFunction, filteredColumns: string[]): TIbuttonColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'code',
		name: <ColumnName name={t('CODE')} icon="material-outline:dns" />,
		selector: (row) => row?.code,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('code', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row?.company?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'driver.name',
		name: <ColumnName name={t('DRIVER')} icon="material-outline:person" />,
		selector: (row) => row?.driver?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('driver.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'notes',
		name: <ColumnName name={t('NOTES')} icon="material-outline:notes" />,
		selector: (row) => row?.notes,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('notes', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:date_range" />,
		selector: (row) => row?.createdAt && dayjs(row.createdAt).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row) => row?.createdBy?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	}
];
