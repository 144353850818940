import { NotificationsActiveOutlined } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	FormControlLabel,
	LinearProgress,
	List,
	ListSubheader,
	Stack,
	SvgIcon,
	Typography,
	useTheme
} from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import usePermission from 'app/services/hooks/usePermission';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { useUpdateMultipleAlertsMutation } from 'app/store/api/monitoringAlertsSlice';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { MONITORING_PERMISSIONS } from '../../MonitoringConfig';
import { TGroupedAlarms } from '../../types';
import FinalizeDialog from '../shared/FinalizeDialog';
import GroupedListItem from './GroupedListItem';

type TGroupedItem = { data: TGroupedAlarms };

const GroupedItem = ({ data }: TGroupedItem) => {
	const theme = useTheme();
	const [selectedAlarms, setSelectedAlarms] = useState([]);
	const dispatch = useDispatch();
	const { t } = useTranslation('monitoringPage');
	const [updateAlerts, { isLoading }] = useUpdateMultipleAlertsMutation();

	const isDarkMode = theme.palette.mode === 'dark';
	const mainTextColor = isDarkMode ? '#FFF' : '#000';
	const normalTextColor = isDarkMode ? '#9FA5A1' : '#79747E';
	const membersIds = data.members.map((alarm) => alarm._id);
	const isToDo = _.get(data, 'members[0].status', '') === 'toDo';
	const permissions = usePermission({ pagePermissions: MONITORING_PERMISSIONS });

	const handleListClick = (value: string) => {
		const currentIndex = selectedAlarms.indexOf(value);
		const newChecked = [...selectedAlarms];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setSelectedAlarms(newChecked);
	};

	const handleClose = () => dispatch(closeDialog());

	const handleCheckAll = (ev: ChangeEvent<HTMLInputElement>) =>
		setSelectedAlarms(ev.target.checked ? membersIds : []);

	const handleFinalize = async () => {
		try {
			handleClose();
			await updateAlerts({ status: 'done', ids: selectedAlarms.join() }).unwrap();
			dispatch(
				showMessage({
					message: t('FINALIZE_SUCCESS_N', { n: selectedAlarms.length }),
					variant: 'success'
				})
			);
		} catch (_err) {
			dispatch(
				showMessage({
					message: t('FINALIZE_ERROR'),
					variant: 'error'
				})
			);
		} finally {
			setSelectedAlarms([]);
		}
	};

	const handleGoOn = async () => {
		try {
			handleClose();
			await updateAlerts({ status: 'onGoing', ids: selectedAlarms.join() }).unwrap();
			dispatch(
				showMessage({
					message: t('GO_ON_SUCCESS_N', { n: selectedAlarms.length }),
					variant: 'success'
				})
			);
		} catch (_err) {
			dispatch(
				showMessage({
					message: t('GO_ON_ERROR'),
					variant: 'error'
				})
			);
		} finally {
			setSelectedAlarms([]);
		}
	};

	const handleFinalizeDialog = () =>
		isToDo
			? handleGoOn()
			: dispatch(
					openDialog({
						children: <FinalizeDialog t={t} onClose={handleClose} onConfirm={handleFinalize} />
					})
			  );

	return (
		<div className="pb-10 m-0">
			<Box
				sx={{
					paddingX: '16px',
					paddingY: '14px',
					width: ['100%', '520px'],
					maxHeight: '470px',
					display: 'grid',
					gridTemplateColumns: '52px 1fr',
					gridTemplateRows: 'repeat(4, 24px) 1fr 56px',
					background: isDarkMode ? '#323C4D' : '#fff',
					borderRadius: '12px',
					border: '1px solid #E0E0E0',
					margin: 0,
					position: 'relative',
					overflow: 'hidden'
				}}
			>
				<Box
					sx={{
						display: isLoading ? 'block' : 'none',
						width: '100%',
						position: 'absolute',
						top: 0,
						left: 0
					}}
				>
					<LinearProgress
						color="secondary"
						sx={{
							borderTopRightRadius: '12px',
							borderTopLeftRadius: '12px'
						}}
					/>
				</Box>
				<Box
					sx={{
						gridRow: '1 / 5'
					}}
				>
					<Box
						sx={{
							background: (theme) => theme.palette.secondary.main,
							borderRadius: 1,
							padding: '4px',
							width: '38px',
							height: '100%',
							textAlign: 'center'
						}}
					>
						<NotificationsActiveOutlined htmlColor="#FFF" />
					</Box>
				</Box>
				<Stack direction="column" spacing={1}>
					<Typography
						sx={{
							fontSize: '16px',
							fontWeight: 600,
							color: mainTextColor
						}}
					>
						{data.groupedBy === 'alarm' ? t(_.get(data, 'title', '')) : _.get(data, 'title', '')}
					</Typography>
					<Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
						<SvgIcon htmlColor={normalTextColor} fontSize="inherit" viewBox="0 0 14 14">
							<path d="M7.526 4.134a2.1 2.1 0 1 0-.875.037v2.296L3.312 9.805a1.01 1.01 0 0 0-.112-.006H1a1 1 0 0 0-1 1v2.2a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-2.2a.996.996 0 0 0-.138-.507l3.065-3.065 3.116 2.74a.999.999 0 0 0-.444.832v2.2a1 1 0 0 0 1 1h2.2a1 1 0 0 0 1-1v-2.2a1 1 0 0 0-1-1h-1.622L7.526 6.413v-2.28Z" />
						</SvgIcon>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: '12px',
								color: normalTextColor
							}}
						>
							{t('GROUPED_N', { n: data?.members?.length || 0 })}
						</Typography>
					</Stack>
				</Stack>

				<Box
					sx={{
						gridColumn: '2 / span 2',
						gridRow: '4 / 6',
						overflowY: 'auto'
					}}
				>
					<List
						subheader={
							<ListSubheader disableGutters>
								<Stack direction="row" spacing={1} alignItems="center" paddingLeft={1}>
									<FormControlLabel
										control={<Checkbox />}
										label="Selecionar todos"
										onChange={handleCheckAll}
										checked={selectedAlarms.length === data.members.length}
									/>
									<Typography>{t('SELECTED_N', { n: selectedAlarms.length })}</Typography>
								</Stack>
							</ListSubheader>
						}
					>
						{data.members.map((item) => (
							<GroupedListItem
								data={item}
								key={item._id}
								groupedBy={data.groupedBy}
								handleCheck={handleListClick}
								checked={selectedAlarms.includes(item._id)}
							/>
						))}
					</List>
				</Box>
				<Box
					sx={{
						gridColumn: '1 / span 3',
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'flex-end'
					}}
				>
					{permissions.hasEdit && (
						<AppButton
							size="small"
							color="secondary"
							variant="contained"
							loading={isLoading}
							disabled={!selectedAlarms.length}
							onClick={handleFinalizeDialog}
						>
							{isToDo ? t('GO_ON') : t('FINALIZE')}
						</AppButton>
					)}
				</Box>
			</Box>
		</div>
	);
};

export default memo(GroupedItem);
