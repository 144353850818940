import {
	Autocomplete,
	AutocompleteRenderOptionState,
	CircularProgress,
	InputAdornment,
	TextField,
	TextFieldProps
} from '@mui/material';
import _ from 'lodash';
import { SyntheticEvent } from 'react';
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';
import ListboxComponent from './ListBoxComponent';

type THookedVirtualizedSelect<T> = {
	options: T[];
	optionLabel: string;
	label: string;
	placeholder?: string;
	loading?: boolean;
	limitText?: (_n: number) => string;
	fullWidth?: boolean;
	isDisabled?: boolean;
	t: TFunction;
	color?: 'primary' | 'secondary';
	size?: 'small' | 'medium';
	multiple?: boolean;
	required?: boolean;
	noOptionsText?: string;
	maxListHeight?: number;
	groupBy?: string;
	optionKey?: string;
	onChangeCallback?: (_value: T) => void;
	renderOption?: (
		_props: React.HTMLAttributes<HTMLLIElement>,
		_option: T,
		_state: AutocompleteRenderOptionState
	) => React.ReactNode;
	filterOptions?: (options: T[], state: { inputValue: string }) => T[];
	helperText?: string;
	noHelperText?: boolean;
	leftAdornment?: TextFieldProps['InputProps']['endAdornment'];
	disableClearable?: boolean;
};

type TProps<F extends FieldValues, T> = UseControllerProps<F> & TextFieldProps & THookedVirtualizedSelect<T>;

export default function HookedVirtualizedSelect<F extends FieldValues, T>({
	options = [],
	optionLabel,
	label,
	placeholder,
	loading = false,
	fullWidth = false,
	isDisabled = false,
	limitText = undefined,
	name,
	size = 'small',
	color = 'secondary',
	t,
	control,
	multiple = false,
	required = false,
	noOptionsText,
	maxListHeight = 300,
	groupBy = undefined,
	optionKey,
	onChangeCallback,
	renderOption,
	filterOptions,
	InputProps,
	noHelperText,
	helperText,
	leftAdornment,
	className,
	disableClearable
}: TProps<F, T>) {
	const {
		field,
		formState: { errors }
	} = useController({
		name,
		control
	});

	const handleChange = (_: SyntheticEvent<Element, Event>, value: string | T | (string | T)[] | null) => {
		field.onChange(value);
		if (onChangeCallback) onChangeCallback(value as T);
	};

	const hasError = _.has(errors, name);
	const error = hasError ? _.get(errors, [name, 'message']) : undefined;
	const helper = hasError && t(error as string);

	return (
		<Autocomplete
			disableClearable={disableClearable}
			className={className}
			color={color}
			ChipProps={{ color }}
			multiple={multiple}
			disablePortal
			openOnFocus
			id={name}
			size={size}
			options={options}
			loading={loading}
			limitTags={0}
			filterOptions={filterOptions}
			renderInput={(params) => (
				<TextField
					className={className}
					{...params}
					disabled={isDisabled}
					variant="outlined"
					label={label}
					placeholder={placeholder}
					fullWidth={fullWidth}
					name={name}
					helperText={noHelperText ? undefined : helper || helperText}
					error={_.has(errors, name)}
					InputLabelProps={{
						required
					}}
					InputProps={{
						...params.InputProps,
						...InputProps,
						startAdornment: <InputAdornment position="start">{leftAdornment}</InputAdornment>,
						endAdornment: (
							<>
								{loading ? (
									<InputAdornment position="start">
										<CircularProgress color="secondary" size={18} />
									</InputAdornment>
								) : (
									params.InputProps.endAdornment
								)}
							</>
						)
					}}
				/>
			)}
			renderOption={
				renderOption ||
				((props, option) => (
					<li {...props} key={option[optionKey] ?? option[optionLabel]}>
						{option[optionLabel]}
					</li>
				))
			}
			value={field.value}
			fullWidth={fullWidth}
			disabled={isDisabled}
			onChange={handleChange}
			onBlur={field.onBlur}
			ref={field.ref}
			isOptionEqualToValue={_.isEqual}
			getLimitTagsText={limitText}
			getOptionLabel={(option) => _.toString(option[optionLabel]) || option._id}
			groupBy={groupBy ? (opt) => opt[groupBy] : undefined}
			noOptionsText={noOptionsText}
			ListboxComponent={ListboxComponent}
			ListboxProps={{
				style: {
					padding: 0,
					overflowY: 'hidden',
					maxHeight: maxListHeight
				}
			}}
		/>
	);
}
