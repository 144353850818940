import { Chip } from '@mui/material';
import { TFunction } from 'react-i18next';
import { TCommandStatus } from '../../types';

type StatusChipProps = {
	t: TFunction<'', string>;
	status: TCommandStatus;
};

function getChipColor(status: TCommandStatus) {
	if (status === 'PENDING' || status === 'IMMEDIATE') {
		return 'info';
	}
	if (status === 'QUEUED' || status === 'RE-QUEUED' || status === 'SCHEDULED') {
		return 'warning';
	}
	if (status === 'SENT' || status === 'CONFIRMED') {
		return 'success';
	}
	if (status === 'CANCELED' || status === 'ERROR' || status === 'EXPIRED') {
		return 'error';
	}

	return 'info';
}

export default function StatusChip({ status, t }: StatusChipProps) {
	if (!status) return null;

	return <Chip variant="outlined" label={t(status)} color={getChipColor(status)} />;
}
