import { EventReportSearchParams } from "app/main/reports/events/types";
import _ from "lodash";
import { createSearchParams } from "react-router-dom";
import { apiSlice } from "./apiSlice";
import { IApiPagination } from "./types";
import { HistorySearchParams, THistoryPosition } from "app/main/reports/historyNew/types";
import getStringQuery from './getStringQuery';
import { IGetVehicleHistory } from "./historySlice";

export function getHistorytStringQuery(paginatedData: EventReportSearchParams) {
	const queryArgs = {
		company: paginatedData.company || null,
		tracker: paginatedData.tracker || null,
		vehicle: paginatedData.vehicle || null,
		eventType: paginatedData.eventType || null,
		start: paginatedData.start || null,
		end: paginatedData.end || null,
		onlyEvents: paginatedData.onlyEvents || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `&${query.toString()}`;
}

const baseUrl = 'report/history';
const cacheKey = 'History';

const newHistorySlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllHistory: builder.query<IApiPagination<THistoryPosition>, EventReportSearchParams | HistorySearchParams>({
			query: (paginatedData: EventReportSearchParams | HistorySearchParams) => ({
				url:
					getStringQuery(paginatedData, baseUrl) +
					getHistorytStringQuery(paginatedData)
			}),
			providesTags: [cacheKey]
		}),
		getVehicleHistory: builder.query<IApiPagination<THistoryPosition>, IGetVehicleHistory>({
			query: ({ vehicle, start, end }) => ({
				url: `report/history?vehicle=${vehicle}&start=${start}&end=${end}&limit=0`,
				method: 'GET'
			}),
			providesTags: [cacheKey]
		})
	})
});

export const { useGetAllHistoryQuery, useLazyGetAllHistoryQuery, useLazyGetVehicleHistoryQuery } = newHistorySlice;

export default newHistorySlice;