const locale = {
	// Common

	EMAIL: 'E-mail',
	PHONE: 'Telefone',
	MOBILE: 'Celular',
	CNH: 'CNH',
	CNH_TYPE: 'Tipo da CNH',
	CNH_DUE_DATE: 'Vencimento da CNH',
	CPF: 'CPF',
	RG: 'RG',
	RG_TYPE: 'Orgão emissor',
	RG_CREATED_DATE: 'Data de emissão do RG',
	BIRTHDAY: 'Data de nascimento',
	NOTES: 'Notas',
	USER: 'Usuário',
	ADDRESS: 'Endereço',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	DATE: 'Data',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	ADD_DRIVER: 'Adicionar motoristas',
	LOADING: 'Carregando',
	CONFIRM: 'Confirmar',

	// Header

	TITLE: 'Motoristas',
	IMPORT_DRIVERS: 'Importar motoristas',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	DOWNLOAD_ALL: 'Baixar todos',

	// Sidebar

	EDIT_INFO: 'Editar motorista',
	DELETE_INFO: 'Deletar motorista',
	DOWNLOAD_INFO: 'Baixar motoristas selecionados',
	EDIT_MULTIPLE_INFO: 'Editar motoristas selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar motoristas selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar motoristas',
	EDIT_MULTIPLE_SUCCESS: 'Motoristas editados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar motoristas',

	// Required Fields

	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	EMAIL_REQUIRED: 'O campo e-mail é obrigatório',
	INVALID_EMAIL: 'E-mail inválido',
	INVALID_COMPANY: 'Empresa inválida',

	// Table

	OF: 'de',
	ROWS_PER_PAGE: 'Itens por página',
	NO_DRIVER_FOUND: 'Nenhum motorista foi encontrado',

	// Request

	CREATE_SUCCESS: 'Motorista adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar reastreador',
	EDIT_SUCCESS: 'Motorista atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar motorista',
	DELETE_SUCCESS: 'Motorista deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar motorista',
	MASSIVE_CREATE_SUCCESS: 'Motoristas adicionados com sucesso',
	MASSIVE_CREATE_ERROR: 'Erro ao adicionar motoristas, verifique os dados e tente novamente',

	// Modal

	DELETE_MODAL_TITLE: 'Deletar motorista',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar esta motorista?',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} motoristas?',

	// Permissions

	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',

	// Bulk

	SHOW_INVALID_ROWS: 'Mostrar linhas inválidas',
	CLEAR_TABLE: 'Limpar tabela',
	CREATING: 'Criando',
	DESCRIPTION_HEADER:
		'Aqui você pode criar motoristas em massa, basta fazer o upload de um arquivo XLSX com os dados dos motoristas, ou baixar o modelo e preencher com os dados dos motoristas que deseja criar, você pode abrir o arquivo XLSX com o Excel ou outro editor de planilhas.',
	DOWNLOAD_TEMPLATE: 'Baixar modelo.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arraste e solte o arquivo XLSX aqui ou clique para selecionar.',
	REMOVE_ROW: 'Remover linhas',

	// Error messages
	USER_ALREADY_REGISTERED_AS_A_DRIVER: 'O usuário selecionado já foi cadastrado como motorista.',

	CREATING_DRIVER: 'Criando motorista',
	VALIDATING_IBUTTON: 'Validando identificador',
	CREATING_IBUTTON: 'Criando identificador',
	DRIVER_CREATED: 'Motorista criado',
	LINK_HEADER:
		"Aqui você pode cadastrar motoristas e seus respectivos identificadores, basta baixar e preencher o modelo .xlsx abaixo com os dados dos motoristas e seus identificadores, e clicar em 'Importar'. Os identificadores serão validados e criados automaticamente se já não existirem na empresa fornecida, caso ja existam, serão apenas vinculados aos mesmos.",
	LINK_IBUTTON_DRIVER_SUCCESS: 'Motoristas e identificador vinculados com sucesso',
	LINK_IBUTTON_DRIVER_ERROR: 'Erro ao vincular motoristas e identificadores',
	LINK_IBUTTON_DRIVER: 'Vincular motoristas e identificadores',

	COMPANY_MUST_BE_NUMBER: 'A empresa deve ser um CNPJ ou CPF sem caracteres especiais',
	PHONE_LENGHT_AND_FORMAT_WARNING: 'O telefone deve ter 11 dígitos e ser um número válido',
	SHOW_FILTERS: 'Mostrar filtros',
	CONFIG_TABLE: 'Configurar tabela',
	CLEAR_FILTERS: 'Limpar filtros'
};

export default locale;
