const locale = {
	TITLE: 'Usuarios',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'no',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Borrar filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfiles',
	PROFILE: 'Empresa',
	USERS: 'Usuarios',
	USER: 'Usuario',
	BIRTHDAY: 'Fecha de nacimiento',
	PASSWORD: 'Contraseña',
	CPASSWORD: 'Confirmar contraseña',
	DATE: 'Fecha',
	OF: 'de',
	DATE_FORMAT: 'es-ES',
	INACESSIBLE_HOSTS: 'Hosts accesibles',
	ADDRESS: 'Dirección',
	EMAIL: 'Correo electrónico',
	PHONE: 'Teléfono',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún artículo seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	ROWS_PER_PAGE: 'Artículos por página',
	NAME_REQUIRED: 'El campo de nombre es obligatorio',
	PHONE_REQUIRED: 'El campo de teléfono es obligatorio',
	EMAIL_REQUIRED: 'El campo de correo electrónico es obligatorio',
	ADDRESS_REQUIRED: 'El campo de dirección es obligatorio',
	TYPE_REQUIRED: 'El campo de tipo de cliente es obligatorio',
	COLUMN_NAME: 'Nombre',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Fecha de nacimiento',
	COLUMN_CREATED_BY: 'Creado por',
	COLUMN_CREATED_AT: 'Creado el',
	COLUMN_EMAIL: 'Correo electrónico',
	COLUMN_ADDRESS: 'Dirección',
	COLUMN_PHONE: 'Teléfono',
	NAME: 'nombre',
	DESC: 'Descripción',
	CREATED_AT: 'Creado el',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar usuario',
	DELETE_CONFIRMATION: '¿Está seguro de que desea eliminar?',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Usuario de edición',
	EDIT_CONFIRMATION: '¿Está seguro de que desea editar este usuario?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Está seguro de que desea editar los usuarios seleccionados?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	FIELD_NAME: 'Nombre',
	FIELD_CODE: 'Código',
	FIELD_ICON: 'Icono',
	FIELD_DESC: 'Descripción',
	FIELD_CREATED_AT: 'Creado el',
	FIELD_CREATED_BY: 'Creado por',
	ERROR_PERMISSION: 'No tiene las autorizaciones necesarias, por favor contacte con el administrador de su sistema',
	ERROR_GETTING_USERS: 'Hubo un error al cargar los usuarios, inténtelo de nuevo más tarde',
	ERROR_RESPONSE_404: 'Error: Datos no encontrados, comuníquese con el administrador de su sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor, inténtalo de nuevo más tarde',
	ERROR_NETWORK: 'Error de red, verifique su conexión e intente nuevamente',
	ADD_USER: 'Añadir Usuario',
	CREATE_SUCCESS: 'Usuario agregado exitosamente',
	CREATE_ERROR: 'Error al agregar usuario',
	EDIT_SUCCESS: 'Usuario actualizado con éxito',
	EDIT_ERROR: 'Error al actualizar usuario',
	ERROR_ADD_EMPTY: 'Complete todos los campos',
	DELETE_SUCCESS: 'Usuario eliminado con éxito',
	DELETE_ERROR: 'Error al eliminar usuario',
	NO_DATA: 'No se encontraron elementos coincidentes',
	NO_RESULTS: 'No se encontraron resultados',
	NO_PERMISSION_list: 'No tienes permiso para ver la lista de usuarios',
	NO_PERMISSION_new: 'No tienes permiso para agregar usuarios',
	NO_PERMISSION_edit: 'No tienes permiso para editar usuarios',
	NO_PERMISSION_view: 'No tienes permiso para ver usuarios',
	NO_PERMISSION_delete: 'No tienes permiso para eliminar usuarios',
	NO_ADDRESS: 'Dirección no encontrada',
	NO_PHONE: 'Teléfono no encontrado',
	NO_EMAIL: 'Correo electrónico no encontrado',
	NO_BIRTHDAY: 'Fecha de nacimiento no encontrada',
	LOADING: 'Cargando',
	UPLOAD_ERROR: 'Error al cargar el archivo',
	PASSWORD_MIN_LENGTH: 'debe tener al menos 8 caracteres',
	PASSWORDS_MUST_MATCH: 'Las contraseñas deben coincidir',
	PASSWORD_REQUIRED: 'el campo de contraseña es obligatorio',
	PASSWORD_MIN_LOWERCASE: 'debe tener al menos 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'debe tener al menos 1 letra mayúscula',
	PASSWORD_MIN_NUMBERS: 'debe tener al menos 1 número',
	PASSWORD_MIN_SYMBOLS: 'debe tener al menos 1 símbolo',
	MORE_THAN: 'más que',
	NO_USER_FOUND: 'Usuario no encontrado',
	DELETE_MODAL_WARNING: '¡Advertencia!',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Está seguro de que desea eliminar {{n}} usuarios?',
	EDIT_INFO: 'Editar usuario',
	DELETE_INFO: 'Eliminar usuario',
	DOWNLOAD_INFO: 'Descargar usuario',
	EDIT_MULTIPLE_INFO: 'Editar usuarios seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar usuarios seleccionados',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar el archivo',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	SELECTED_HOSTS: '{{n}} Host seleccionados',
	PROFILE_REQUIRED: 'El campo Perfil es obligatorio',
	CPASSWORD_REQUIRED: 'El campo Confirmar contraseña es obligatorio',
	EDIT_MULTIPLE_SUCCESS: 'Usuarios actualizados correctamente',
	EDIT_MULTIPLE_ERROR: 'Error al actualizar usuarios',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, elige una imagen de menos de 8MB',
	COMPANY_REQUIRED: 'É necessário seleccionar una empresa',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',
	EMAIL_ERROR: 'El correo electrónico ya está en uso',
	PASSWORDS_NOT_MATCH: 'Las contraseñas no coinciden',
	HOST_MIN_1: 'Debe seleccionar al menos un host',
	HOST_WARNING: 'Seleccione una Empresa para mostrar sus Hosts.',

	/* Content Control */
	VIEW_ALL_FLEETS: 'Todas las flotas',
	VIEW_NONE_FLEETS: 'Sin flota',
	VIEW_SOME_FLEETS: 'Solo flotas:',
	CONTENT_CONTROL_FLEETS: 'Control de acceso a flotas',
	SELECTED_FLEETS: '{{n}} flotas seleccionadas',
	FLEETS: 'Flotas',
	N_FLEETS: '{{n}} Flotas',

	VIEW_ALL_VEHICLES: 'Todos los vehículos',
	VIEW_NONE_VEHICLES: 'Sin vehículos',
	VIEW_SOME_VEHICLES: 'Sólo vehículos:',
	CONTENT_CONTROL_VEHICLES: 'Control de acceso de vehículos',
	SELECTED_VEHICLES: '{{n}} vehículos seleccionados',
	VEHICLES: 'Vehículos',
	N_VEHICLES: '{{n}} Vehículos',
	ERROR_EMAIL_OR_USERNAME_REQUIRED: 'O campo "Email ou Nome de Usuário" é obrigatório',
	EMAIL_OR_USERNAME: 'Email ou Nome de Usuário',
	PLATFORM_DATA: 'Datos de acceso a la plataforma',
	FLEET_WARNING: 'Seleccione una Empresa para mostrar sus Flotas.',
	VEHICLE_WARNING: 'Seleccione una Empresa para mostrar sus Vehículos.',
	APPLY: 'Aplicar',
	CHANGE_PASSWORD: 'Cambiar contraseña',
	CHANGE_PASSWORD_SUCCESS: 'Contraseña alterada con éxito',
	CHANGE_PASSWORD_ERROR: 'Error al cambiar contraseña',
	UNBLOCK_USER_SUCCESS: 'Usuario desbloqueado exitosamente',
	UNBLOCK_USER_ERROR: 'Error al desbloquear usuario',
	BLOCK_USER_SUCCESS: 'Usuario bloqueado exitosamente',
	BLOCK_USER_ERROR: 'Error al bloquear usuario',
	BLOCK_USER: 'Bloquear',
	UNBLOCK_USER: 'Desbloquear',
	BLOCK_USER_PRIMARY: '¿Estás seguro de que deseas bloquear a este usuario?',
	UNBLOCK_USER_PRIMARY: '¿Estás seguro de que deseas desbloquear a este usuario?',
	IS_BLOCKED: 'Bloqueado',
	LOGIN_SETTINGS: 'Horarios de acceso',

	ACCESIBLE_COMPANIES: 'Empresas accesibles',
	USER_COMPANY: 'Empresa',

	ACCESSIBLE_COMPANIES_REQUIRED: 'El campo Empresas accesibles es obligatorio',
	USER_COMPANY_REQUIRED: 'El campo Empresa es obligatorio'
};

export default locale;
