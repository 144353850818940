import { apiSlice } from './apiSlice';
import type { ILoginPayload, ILoginResponse } from './types';

export const authSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		loginUser: builder.mutation<ILoginResponse, ILoginPayload>({
			query(arg) {
				return {
					url: '/auth/login',
					method: 'POST',
					body: arg
				};
			}
		})
	})
});

export const { useLoginUserMutation } = authSlice;
