import { TextField, TextFieldProps } from '@mui/material';
import _ from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { TFunction } from 'react-i18next';

type TFormAndTextField<F> = UseControllerProps<F> &
	TextFieldProps & { t: TFunction; noHelperText?: boolean; onChangeCallback?: (value: string) => void };

function HookedTextfield<F>({
	control,
	name,
	t,
	required = false,
	type,
	noHelperText,
	onChangeCallback,
	...props
}: TFormAndTextField<F>) {
	const {
		field,
		formState: { errors }
	} = useController({
		name,
		control
	});

	const hasError = _.has(errors, name);
	const errorMessage = t(_.get(errors, `${name}.message`) as string);

	return (
		<TextField
			{...props}
			onChange={onChangeCallback || field.onChange}
			onBlur={field.onBlur}
			value={field.value}
			name={field.name}
			inputRef={field.ref}
			helperText={noHelperText ? undefined : hasError && errorMessage}
			error={_.has(errors, name)}
			InputLabelProps={{ required }}
			type={type}
		/>
	);
}

export default HookedTextfield;
