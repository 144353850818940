import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { Stack, Typography } from '@mui/material';
import React from 'react';

type TColumnNameProps = {
	name: string;
	icon?: string;
	materialIcon?: React.ReactNode;
};

export default function ColumnName({ name = '', icon = '', materialIcon = null }: TColumnNameProps) {
	return (
		<Stack spacing={1} direction="row" alignItems="center">
			{materialIcon ?? <FuseSvgIcon size={16}>{icon}</FuseSvgIcon>}
			<Typography
				sx={{
					fontSize: '16px',
					fontWeight: 600
				}}
			>
				{name}
			</Typography>
		</Stack>
	);
}
