import * as yup from 'yup';

const categorySchema = yup
	.object({
		_id: yup.string(),
		name: yup.string()
	})
	.nullable();

const lessonSchema = yup
	.object({
		_id: yup.string(),
		title: yup.string(),
		category: yup.array().of(categorySchema).min(1, 'CATEGORY_REQUIRED'),
		description: yup.string(),
		videoUrl: yup.string().notRequired(),
		videoFile: yup.mixed().notRequired()
	})
	.nullable();

export const validationSchemaCreate = yup.object({
	title: yup.string().required('COURSE_TITLE_REQUIRED'),
	description: yup.string().optional(),
	category: yup.array().of(categorySchema).min(1, 'CATEGORY_REQUIRED'),
	lessons: yup.array().of(lessonSchema).min(1, 'LESSONS_REQUIRED')
});

export const validationSchemaEdit = yup.object({
	title: yup.string().required('COURSE_TITLE_REQUIRED'),
	description: yup.string().optional(),
	category: yup.array().of(categorySchema).min(1, 'CATEGORY_REQUIRED'),
	lessons: yup.array().of(lessonSchema).min(1, 'LESSONS_REQUIRED')
});
