import history from '@history';
import AppContext from 'app/AppContext';
import storageService from 'app/services/storage';
import { selectUser } from 'app/store/user/userSlice';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';
import hasPermission from 'src/utils/hasPermission';

interface RoutesAuthorizationProps {
	children: JSX.Element;
}

interface IRouteObject {
	params: Record<string, unknown>;
	pathname: string;
	pathnameBase: string;
	route: {
		auth: string[];
		element: ReactNode;
		path: string;
		settings: {
			layout: {
				config: unknown;
			};
		};
	};
}

export function RoutesAuthorization({ children }: RoutesAuthorizationProps) {
	const { location } = history;
	const { pathname } = location;
	const { routes } = React.useContext(AppContext);
	const url = useLocation();

	const User = useSelector(selectUser); // User from Redux
	const UserRole: { code: string }[] = User?.permissions; // User Roles from Redux

	// gets the routes that match the current url
	const matchedRoutes = matchRoutes(routes, pathname);
	const matched: IRouteObject = matchedRoutes ? matchedRoutes[0] : null;

	// checks if the current user has permission to access the route
	// 1. if the route has no auth property, then it's public
	// 2. if the route has auth property, then it's private, and the user must have at least one of the roles in the auth property
	// 3. if the user has system.admin role, then it gives all access
	// 4. if the route matches /sign-in, then it's public
	const checkForPermission = useCallback(
		(auth: string[], UserRole: { code: string }[]) => {
			if (!auth || hasPermission(UserRole, 'system.admin')) return true;
			if (!UserRole) return false;
			if (pathname === '/sign-in') return true;
			if (!storageService.get('token') && pathname !== '/sign-in') return false;
			return auth.some((authRole) => hasPermission(UserRole, authRole));
		},
		[pathname]
	);

	// waits until all the user roles are loaded
	const gotUserRoles = useMemo(() => !!User.doneLoadingUser, [User.doneLoadingUser]);

	// checks if the user has permission to access the route and listen to changes in the url
	useEffect(() => {
		if (gotUserRoles) {
			if (matched && !checkForPermission(matched.route.auth, UserRole)) {
				history.push('/permission-not-found');
			}
		}
	}, [gotUserRoles, matched, UserRole, pathname, url, checkForPermission]);
	return <>{children}</>;
}
