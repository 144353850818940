const locale = {
	CODE_REQUIRED: 'O campo código é obrigatório',
	DRIVER: 'Motorista',
	DRIVER_REQUIRED: 'O campo motorista é obrigatório',
	TRACKER: 'Rastreador',
	COMPANY_REQUIRED: 'O campo empresa é obrigatório',
	DID_MUST_BE_NUMBER: 'O campo DID deve ser um número',
	TRACKER_TYPE_REQUIRED: 'O campo tipo de identificador é obrigatório',
	DID_REQUIRED: 'O campo DID é obrigatório',
	ICCID_REQUIRED: 'O campo ICCID é obrigatório',
	NOTES: 'Notas',
	VEHICLE: 'Rastreado',
	TRACKER_TYPE: 'Tipo de identificador',
	TITLE: 'Identificadores',
	ADD: 'Adicionar',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Salvar',
	DELETE: 'Deletar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfis',
	PROFILE: 'Perfil',
	USERS: 'Usuários',
	USER: 'Usuário',
	BIRTHDAY: 'Data de nascimento',
	PASSWORD: 'Senha',
	CPASSWORD: 'Confirmar senha',
	DATE: 'Data',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts acessíveis',
	ADDRESS: 'Endereço',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Telefone',
	SELECTED_ITEMS: 'Itens selecionados',
	NO_ITEM_SELECTED: 'Nenhum item selecionado',
	EXPORT_SELECTED: 'Exportar itens selecionados',
	DELETE_SELECTED: 'Excluir itens selecionados',
	EDIT_SELECTED: 'Editar itens selecionados',
	ROWS_PER_PAGE: 'Itens por página',
	NAME_REQUIRED: 'O campo nome é obrigatório',
	PHONE_REQUIRED: 'O campo telefone é obrigatório',
	EMAIL_REQUIRED: 'O campo email é obrigatório',
	ADDRESS_REQUIRED: 'O campo endereço é obrigatório',
	TYPE_REQUIRED: 'O campo tipo de cliente é obrigatório',
	COLUMN_NAME: 'Nome',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Data de nascimento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Criado por',
	COLUMN_CREATED_AT: 'Criado em',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Endereço',
	COLUMN_PHONE: 'Telefone',
	NAME: 'Nome',
	CODE: 'Código',
	DESC: 'Descrição',
	CREATED_AT: 'Criado em',
	CREATED_BY: 'Criado por',
	DELETE_TITLE: 'Deletar identificador',
	DELETE_CONFIRMATION: 'Você tem certeza que deseja deletar',
	DELETE_CONFIRMATION_2: 'Esta ação não poderá ser desfeita.',
	EDIT_TITLE: 'Editando identificador',
	EDIT_CONFIRMATION: 'Você tem certeza que deseja editar este identificador?',
	EDIT_CONFIRMATION_MULTIPLE: 'Você tem certeza que deseja editar os identificadores selecionados?',
	EDIT_CONFIRMATION_2: 'Esta ação não pode ser desfeita.',
	ERROR_PERMISSION: 'Você não possui as autorizações necessárias, por favor contate o administrador do sistema',
	ERROR_GETTING_USERS: 'Houve um erro ao carregar os identificadores, por favor tente novamente mais tarde',
	ERROR_RESPONSE_404: 'Erro: Dados não encontrados, por favor entre em contato com o administrador do sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Erro interno do servidor, por favor tente novamente mais tarde',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_USER: 'Adicionar identificador',
	CREATE_SUCCESS: 'Identificador adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar identificador',
	EDIT_SUCCESS: 'Identificador atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar identificador',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Identificador deletado com sucesso',
	DELETE_ERROR: 'Erro ao deletar identificador',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não possui permissão para ver a lista de identificadores',
	NO_PERMISSION_new: 'Você não possui permissão para adicionar identificadores',
	NO_PERMISSION_edit: 'Você não possui permissão para editar identificadores',
	NO_PERMISSION_view: 'Você não possui permissão para visualizar identificadores',
	NO_PERMISSION_delete: 'Você não possui permissão para deletar identificadores',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'Email não encontrado',
	NO_BIRTHDAY: 'Data de nascimento não encontrada',
	LOADING: 'Carregando',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
	PASSWORD_MIN_LENGTH: 'A senha deve ter no mínimo 8 caracteres',
	PASSWORDS_MUST_MATCH: 'As senhas devem ser iguais',
	PASSWORD_MIN_LOWERCASE: 'A senha deve ter no mínimo 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'A senha deve ter no mínimo 1 letra maiúscula',
	PASSWORD_MIN_NUMBERS: 'A senha deve ter no mínimo 1 número',
	PASSWORD_MIN_SYMBOLS: 'A senha deve ter no mínimo 1 caractére especial',
	MORE_THAN: 'mais que',
	NO_USER_FOUND: 'Nenhum identificador foi encontrado',
	DELETE_MODAL_TITLE: 'Deletar identificador',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este identificador?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} identificadores?',
	EDIT_INFO: 'Editar identificador',
	DELETE_INFO: 'Deletar identificador',
	DOWNLOAD_INFO: 'Baixar identificadores selecionados',
	EDIT_MULTIPLE_INFO: 'Editar identificadores selecionados',
	DELETE_MULTIPLE_INFO: 'Deletar identificadores selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar identificadores',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar Senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS: 'Identificadores editados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Error ao editar identificadores',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem meno que 8MB',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador',
	DOWNLOAD_ALL: 'Baixar todos',

	// Bulk

	SHOW_INVALID_ROWS: 'Mostrar linhas inválidas',
	CLEAR_TABLE: 'Limpar tabela',
	CREATING: 'Criando',
	DESCRIPTION_HEADER:
		'Aqui você pode criar identifcadores em massa, basta fazer o upload de um arquivo XLSX com os dados dos identifcadores, ou baixar o modelo e preencher com os dados dos identifcadores que deseja criar, você pode abrir o arquivo XLSX com o Excel ou outro editor de planilhas.',
	DOWNLOAD_TEMPLATE: 'Baixar modelo.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arraste e solte o arquivo XLSX aqui ou clique para selecionar.',
	REMOVE_ROW: 'Remover linhas',
	ERROR: 'Erro',
	SUCCESS: 'Sucesso',
	VALIDS: 'Válidos',
	INVALIDS: 'Inválidos',
	MASSIVE_CREATE_SUCCESS: 'Identifcadores criados com sucesso!',
	MASSIVE_CREATE_ERROR: 'Erro ao criar identifcadores',
	FILE_ERROR: 'Erro ao ler arquivo',
	CONFIG_TABLE: 'Configurar tabela',
	SHOW_FILTERS: 'Mostrar filtros',
	CLEAR_FILTERS: 'Limpar filtros'
};

export default locale;
