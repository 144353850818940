const locale = {
	// Columns
	NAME: 'Name',
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
	COMPANY: 'Company',
	FLEETS: 'Fleets',
	VEHICLES: 'Vehicles',
	BLACKLIST: 'Blacklist',
	TO_HIGH_PRIORITY: 'High Priority',
	TO_LOW_PRIORITY: 'Low Priority',
	CREATED_AT: 'Created At',
	CREATED_BY: 'Created By',
	UPDATED_AT: 'Updated At',
	CANCEL: 'Cancel',
	BACK: 'Back',
	FINISH: 'Finish',
	CONTINUE: 'Continue',
	CONFIRM: 'Confirm',
	UPDATE_ERROR: 'Error updating monitoring settings',
	CREATE_ERROR: 'Error creating monitoring settings',

	// Table
	NO_SETTINGS_FOUND: 'No settings found',
	ROWS_PER_PAGE: 'Items per page',
	OF: 'of',
	N_FLEETS: '{{n}} fleets',
	N_VEHICLES: '{{n}} vehicles',
	N_ITEMS: '{{n}} items',

	// Header
	TITLE: 'Settings',
	ADD_NEW_SETTING: 'Add New Setting',

	// Sidebar
	SELECTED_ITEMS: '{{n}} Selected Items',
	DELETE_MULTIPLE_INFO: 'Delete monitoring settings',
	DELETE_MODAL_TITLE: 'Delete monitoring settings',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete these settings?',
	DELETE_MODAL_WARNING: 'This action cannot be undone.',
	DELETE_ERROR: 'Error deleting monitoring settings',
	DOWNLOAD_INFO: 'Download monitoring settings',
	DOWNLOAD_ERROR: 'Error downloading monitoring settings',
	DELETE_SUCCESS: 'Event successfully deleted',

	// DialogForm
	EDIT_MONITORING_SETTINGS: 'Edit Settings',
	MONITORING_SETTINGS: 'Monitoring Settings',
	NAME_AND_COMPANY: 'Name and Company',
	TARGETS: 'Monitored Items',
	REVIEW: 'Review Settings',
	// DialogForm - name and company
	SELECT_COMPANY: 'Select a company',
	SELECT_NAME_AND_COMPANY: 'Add a name and company for the setting',
	// DialogForm - targets
	SELECT_TARGETS_HEADER: 'Select the items to be monitored',
	TARGET_FLEETS: 'Fleets',
	FLEETS_LIMIT_TEXT: '{{n}} selected fleets',
	TARGET_VEHICLES: 'Vehicles',
	VEHICLES_LIMIT_TEXT: '{{n}} selected vehicles',
	HIGH: 'High',
	MEDIUM: 'Medium',
	LOW: 'Low',
	SHOW: 'Show',
	PRIORITY: 'Priority',
	// DialogForm - review
	REVIEW_PAGE: 'Review Settings',

	// DialogView
	BLACKLISTED_ITEMS: 'Blacklisted alerts',
	PRIORITIES: 'Alerts',
	SENT_FROM: 'Sent from',
	FLEET: 'Fleet',
	VEHICLE: 'Vehicle',
	DELETE: 'Delete setting',
	EDIT: 'Edit setting',
	NO_BLACKLISTED_ITEMS: 'No blacklisted alerts',
	SELECT_ALL: 'Select All',
	SELECT_ALL_VEHICLES: 'Select all vehicles',

	PAGE_NOT_ALLOWED: 'You do not have permission to view this screen.',
	MINIMAL_START: 'Request the Administrator to add the following permissions to your profile:',
	MINIMAL_END: 'Then log out and log back into the system.'
};

export default locale;
