import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store/index';
import dayjs, { Dayjs } from 'dayjs';

const endDate = dayjs();
const startDate = endDate.subtract(7, 'days');

export const getPrettyValue = (startDate: Dayjs, endDate: Dayjs) =>
	`${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`;

type TInitialState = {
	period: {
		startDate: Dayjs;
		endDate: Dayjs;
		prettyValue: string;
	};
	// filters: {}
};

export const initialState: TInitialState = {
	period: {
		startDate,
		endDate,
		prettyValue: getPrettyValue(startDate, endDate)
	}
};

const maintenanceReportSlice = createSlice({
	name: 'maintenanceReport',
	initialState,
	reducers: {
		setStartPeriod: (state, action: PayloadAction<Dayjs>) => {
			state.period.startDate = action.payload;
		},
		setEndPeriod: (state, action: PayloadAction<Dayjs>) => {
			state.period.endDate = action.payload;
		},
		setPrettyValue: (state) => {
			state.period.prettyValue = getPrettyValue(state.period.startDate, state.period.endDate);
		},
		setInitialState: () => initialState
	}
});
export const { setEndPeriod, setStartPeriod, setPrettyValue } = maintenanceReportSlice.actions;

export const selectStartPeriod = (state: RootState) => state.maintenanceReport.period.startDate;
export const selectEndPeriod = (state: RootState) => state.maintenanceReport.period.endDate;
export const selectPrettyValue = (state: RootState) => state.maintenanceReport.period.prettyValue;
export const selectReportRequestParams = (state: RootState) => ({
	since: state.maintenanceReport.period.startDate.toISOString(),
	until: state.maintenanceReport.period.endDate.toISOString()
});

export default maintenanceReportSlice.reducer;
