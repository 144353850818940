import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TLinkTableValues } from 'app/main/drivers/components/link-driver-ibutton/tableSchema';
import { TLinkTableColumn } from 'app/main/drivers/components/link-driver-ibutton/types';

interface IInitialState {
	isLoading: boolean;
	isShowingInvalidRows: boolean;
	data: TLinkTableValues[];
	selectedRows: TLinkTableColumn[];
}

const initialState: IInitialState = {
	isLoading: false,
	isShowingInvalidRows: false,
	data: [],
	selectedRows: []
};

const linkIButtonDriverSlice = createSlice({
	name: 'linkIButtonDriver',
	initialState,
	reducers: {
		setData: (state, action: PayloadAction<TLinkTableValues[]>) => {
			state.data = action.payload;
		},
		setSelectedRows: (state, action: PayloadAction<TLinkTableColumn[]>) => {
			state.selectedRows = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setIsShowingInvalidRows: (state, action: PayloadAction<boolean>) => {
			state.isShowingInvalidRows = action.payload;
		},
		clearData: (state) => {
			state.data = [];
			state.selectedRows = [];
		}
	}
});

export const { setData, setSelectedRows, setIsLoading, setIsShowingInvalidRows, clearData } =
	linkIButtonDriverSlice.actions;

export default linkIButtonDriverSlice.reducer;
