import { Parser } from '@json2csv/plainjs';
import i18next from 'i18next';
import { INormaliseProps, IParseToCSVProps } from './types';

export const addTranslation = () => {
	i18next.addResourceBundle('en', 'download', {
		DOWNLOADING: 'Downloading',
		CANCEL: 'Cancel',
		NO_DATA: 'No data to export.',
		CONNECTION_ERROR: 'Connection error, try again.'
	});

	i18next.addResourceBundle('pt', 'download', {
		DOWNLOADING: 'Baixando',
		CANCEL: 'Cancelar',
		NO_DATA: 'Não há dados para exportar.',
		CONNECTION_ERROR: 'Erro de conexão, tente novamente.'
	});

	i18next.addResourceBundle('es', 'download', {
		DOWNLOADING: 'Descargando',
		CANCEL: 'Cancelar',
		NO_DATA: 'No hay datos para exportar.',
		CONNECTION_ERROR: 'Error de conexión, inténtelo de nuevo.'
	});
};

export const parseToCSV = ({ data, isFirstPage = true, csvHeaders }: IParseToCSVProps) => {
	const parser = new Parser({
		fields: csvHeaders,
		header: isFirstPage,
		quote: ' '
	});

	return parser.parse(data);
};

export const createDownloadLink = (csv: string, filename: string) => {
	const blob = new Blob([csv], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.setAttribute('hidden', '');
	a.setAttribute('href', url);
	a.setAttribute('download', `${filename}.csv`);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export const normalise = ({ max, min, value }: INormaliseProps) => {
	const range = max - min;
	return range ? ((value - min) * 100) / range : range;
};
