import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import { Typography } from '@mui/material';

interface ITitleAndQuantityProps {
	quantity: string;
	title: string;
}

export default function TitleAndQuantity({ quantity, title }: ITitleAndQuantityProps) {
	return (
		<div className="flex items-center gap-8">
			<FuseSvgIcon color="action" size={32}>
				heroicons-outline:bell
			</FuseSvgIcon>
			<Typography className="text-24 lg:text-32 font-600" color="text.primary">
				{`${quantity} ${title}`}
			</Typography>
		</div>
	);
}
