export const formatPhoneNumber = (phone: string) => {
	if (!phone) return '';
	const regex = /^(\+55)?\s?(\(?\d{2}\)?)?\s?(9?\d{4})\s?(\d{4})$/;
	const match = RegExp(regex).exec(phone);

	if (!match) return phone;

	const countryCode = match[1] || '+55';
	const areaCode = match[2] ? match[2]?.replace(/[()]/g, '') : '48';
	const firstPart = match[3];
	const secondPart = match[4];

	return `${countryCode} (${areaCode}) ${
		firstPart.startsWith('9') ? firstPart.slice(0, 1) + ' ' + firstPart.slice(1) : firstPart
	}-${secondPart}`;
};
