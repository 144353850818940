import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import i18next from 'i18next';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

interface PrimaryOptionButtonProps {
	text: string;
	icon: string;
	iconColor?: 'inherit' | 'disabled' | 'primary' | 'secondary' | 'action' | 'error' | 'info' | 'success' | 'warning';
	onClick: () => void;
	loading?: boolean;
	disabled?: boolean;
	hasPermission?: boolean;
	missingPermission?: string;
}

i18next.addResourceBundle('en', 'PrimaryOptionButton', {
	NO_PERMISSION: 'No permission',
	NO_PERMISSION_DESCRIPTION: 'Missing permissions: {{permissions}}'
});

i18next.addResourceBundle('es', 'PrimaryOptionButton', {
	NO_PERMISSION: 'Sin permiso',
	NO_PERMISSION_DESCRIPTION: 'Permisos faltantes: {{permissions}}'
});

i18next.addResourceBundle('pt', 'PrimaryOptionButton', {
	NO_PERMISSION: 'Não autorizado',
	NO_PERMISSION_DESCRIPTION: 'Permissões necessárias: {{permissions}}'
});

const NoPermissionComponent = ({ missingPermission }) => {
	const { t } = useTranslation('PrimaryOptionButton');

	return (
		<Tooltip title={t('NO_PERMISSION_DESCRIPTION', { permissions: missingPermission })} arrow>
			<Box
				className="h-[32px] rounded-[8px] py-[6px] w-full flex justify-center gap-4"
				border={1}
				borderColor="text.disabled"
			>
				<LockOutlined color="error" fontSize="small" />
				{t('NO_PERMISSION')}
			</Box>
		</Tooltip>
	);
};

const PrimaryOptionButton: React.FC<PrimaryOptionButtonProps> = ({
	loading = false,
	onClick,
	text,
	icon,
	iconColor = 'secondary',
	disabled,
	hasPermission = true,
	missingPermission
}) => {
	if (!hasPermission) {
		return <NoPermissionComponent missingPermission={missingPermission} />;
	}

	return (
		<LoadingButton
			className="h-[32px] rounded-[8px] py-[6px] w-full"
			variant="outlined"
			color="primary"
			size="small"
			onClick={onClick}
			loading={loading}
			disabled={disabled}
		>
			{!loading ? (
				<Fragment>
					<FuseSvgIcon size={22} color={iconColor} className="mr-[5px]">
						{icon}
					</FuseSvgIcon>
					<Typography
						sx={{
							color: (theme) => theme.palette.getContrastText(theme.palette.background.default)
						}}
						className="text-[14px] font-500"
					>
						{text}
					</Typography>
				</Fragment>
			) : undefined}
		</LoadingButton>
	);
};

export default memo(PrimaryOptionButton);
