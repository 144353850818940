const locale = {
	FAILED_TO_LOGIN: 'Failed to login, invalid email or password',
	NOT_UNDER_ALLOWED_DOMAIN: 'Failed to login, domain not allowed',
	LOGIN_SUCESS: 'Login sucess',
	FORGOT_PASSWORD_1: 'Forgot Password?',
	FORGOT_PASSWORD_2: 'Click here',
	SIGN_IN: 'Sign In',
	ERROR_EMAIL_INVALID: 'You must enter a valid email',
	SIGN_IN_WITH_EMAIL_AND_PASSWORD: 'Sign in with email and password',
	SIGN_IN_WITH_EMAIL_AND_NAME: 'Sign in with name and password',
	ERROR_EMAIL_REQUIRED: 'You must enter an email',
	ERROR_PASSWORD_REQUIRED: 'You must enter a password',
	ERROR_PASSWORD_MIN: 'Password must be at least 6 characters long',
	ERROR_EMAIL_OR_USERNAME_REQUIRED: 'You must enter an Email or Username',
	EMAIL_OR_USERNAME: 'Email or Username',
	PASSWORD: 'Password'
};

export default locale;
