import { TFunction } from 'react-i18next';

import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import { formatPhoneNumber } from 'react-phone-number-input';
import { isColumnOmitted } from './helper';
import { TColumnsNames, TSimcardColumn } from './types';

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('ICCID'), columnId: 'iccid' },
	{ name: t('BRAND'), columnId: 'brand.name' },
	{ name: t('PHONE'), columnId: 'phone' },
	{ name: t('CURRENT_USAGE'), columnId: 'currentUsage' },
	{ name: t('CURRENT_USAGE_RX'), columnId: 'currentUsageRX' },
	{ name: t('CURRENT_USAGE_TX'), columnId: 'currentUsageTX' },
	{ name: t('NOTES'), columnId: 'notes' },
	{ name: t('TRACKER'), columnId: 'tracker.did' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('SUPPLIER'), columnId: 'supplier' },
];

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ICCID'), columnId: 'iccid' },
	{ name: t('PHONE'), columnId: 'phone' },
	{ name: t('NOTES'), columnId: 'notes' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const defaultVisibleColumns = [
	'iccid',
	'brand.name',
	'phone',
	'currentUsage',
	'currentUsageRX',
	'currentUsageTX',
	'notes',
	'tracker.did',
	'company.name',
	'createdBy.name',
	'createdAt',
	'supplier'
];

export const columnsSearchKeys = [
	'iccid',
	'phone',
	'notes',
	'createdBy.name',
];

export const columnsSortKeys = [
	'iccid',
	'currentUsage',
	'currentUsageRX',
	'currentUsageTX',
	'notes',
	'createdAt',
	'supplier'
];

export const getColumns = (t: TFunction, filteredColumns: string[]): TSimcardColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'iccid',
		name: <ColumnName name={t('ICCID')} icon="material-outline:dns" />,
		selector: (row) => row.iccid,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('iccid', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'brand.name',
		name: <ColumnName name={t('BRAND')} icon="material-outline:api" />,
		selector: (row) => row.brand?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('brand.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'phone',
		name: <ColumnName name={t('PHONE')} icon="material-outline:phone" />,
		selector: (row) => row.phone && formatPhoneNumber(row.phoneCountryCode + row.phone),
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('phone', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'currentUsage',
		name: <ColumnName name={t('CURRENT_USAGE')} icon="material-outline:wifi" />,
		selector: (row) => row.currentUsage !== undefined && `${row.currentUsage} Mb`,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('currentUsage', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'currentUsageRX',
		name: <ColumnName name={t('CURRENT_USAGE_RX')} icon="material-outline:file_download" />,
		selector: (row) => row.currentUsageRX !== undefined && `${row.currentUsageRX} Mb`,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('currentUsageRX', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'currentUsageTX',
		name: <ColumnName name={t('CURRENT_USAGE_TX')} icon="material-outline:file_upload" />,
		selector: (row) => row.currentUsageTX !== undefined && `${row.currentUsageTX} Mb`,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('currentUsageTX', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'supplier',
		name: <ColumnName name={t('SUPPLIER')} icon="material-outline:business_center" />,
		selector: (row) => row?.supplier,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('supplier', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'notes',
		name: <ColumnName name={t('NOTES')} icon="material-outline:notes" />,
		selector: (row) => row.notes,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('notes', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'tracker.did',
		name: <ColumnName name={t('TRACKER')} icon="material-outline:track_changes" />,
		selector: (row) => row?.tracker?.did,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('tracker.did', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row?.company?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row) => row?.createdBy?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:date_range" />,
		selector: (row) => row.createdAt && dayjs(row.createdAt).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	}
];
