import { LockOpenOutlined, LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import _ from 'lodash';
import { TFunction } from 'react-i18next';

interface ISlideToConfirmProps {
	onClick: () => void;
	isBlocked: boolean;
	t: TFunction;
	isWaiting: boolean;
	isLoading: boolean;
}

const getIcon = (isWaiting: boolean, isBlocked: boolean) => {
	if (isWaiting && isBlocked) return LockOpenOutlined;
	if (isWaiting && !isBlocked) return LockOutlined;
	if (isBlocked) return LockOpenOutlined;
	return LockOutlined;
};

const checkText = (isBlocked: boolean, t: TFunction) => {
	return isBlocked ? t('UNBLOCK') : t('BLOCK');
};

export default function BlockButton({ onClick, isBlocked, t, isWaiting, isLoading }: ISlideToConfirmProps) {
	const text = checkText(isBlocked, t);
	const Icon = getIcon(isWaiting, isBlocked);

	return (
		<LoadingButton
			disabled={isLoading}
			color="secondary"
			variant="contained"
			onClick={onClick}
			startIcon={<Icon />}
			sx={{ ml: 'auto' }}
		>
			{_.capitalize(text)}
		</LoadingButton>
	);
}
