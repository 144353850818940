import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './manage/i18n/en';
import pt from './manage/i18n/pt';
import sp from './manage/i18n/sp';

const TrackersPage = lazyRetry(() => import('./manage/Trackers'));
const SendCommandPage = lazyRetry(() => import('./send-command/SendCommandPage'));

i18next.addResourceBundle('en', 'trackersPage', en);
i18next.addResourceBundle('sp', 'trackersPage', sp);
i18next.addResourceBundle('pt', 'trackersPage', pt);

export const TRACKER_PERMISSIONS = {
	edit: 'fleet.tracker.edit',
	view: 'fleet.tracker.view',
	list: 'fleet.tracker.list',
	new: 'fleet.tracker.new',
	delete: 'fleet.tracker.delete',
	minimal: ['fleet.vehicle.list', 'admin.company.list']
};

const TrackersConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.tracker.list'],
			path: 'fleet/trackers',
			element: (
				<LazyLoaderSuspense>
					<TrackersPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<TrackersPage />
						</LazyLoaderSuspense>
					)
				}
			]
		},
		{
			auth: [
				'fleet.tracker.view',
				'admin.tracker-command.view',
				'admin.tracker-command.list',
				'command.history.view',
				'command.history.list',
				'command.schedule.list',
				'command.schedule.view',
				'command.schedule.new'
			],
			path: 'fleet/trackers/send-command',
			element: (
				<LazyLoaderSuspense>
					<SendCommandPage />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default TrackersConfig;
