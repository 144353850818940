import { AbilityBuilder, MatchConditions, PureAbility } from "@casl/ability";
import { SYSTEM_ADMIN_PERMISSION } from "src/constants";
import { defineAdminAbility } from "src/utils/define-admin-ability";
import userHasPermission from "src/utils/useHasPermission";
import { getMissingPermissions } from "../helper";
import { AbilityTuple, MissingPermissions } from "../types";

type RulerAbility = PureAbility<AbilityTuple<'eventsReport'>, MatchConditions>;

const LIST_EVENTS_REPORT_PERMISSIONS = ['reports.event.list'];

const VIEW_EVENTS_REPORT_PERMISSIONS = ['reports.event.view'];

export const defineEventsReportAbility = () => {
    const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

    const missingPermissions: MissingPermissions = {
        list: getMissingPermissions(LIST_EVENTS_REPORT_PERMISSIONS),
        view: getMissingPermissions(VIEW_EVENTS_REPORT_PERMISSIONS),
        create: [],
        edit: [],
        delete: []
    };

    if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
        defineAdminAbility('eventsReport', can);
        return [build(), missingPermissions] as const;
    }

    if (userHasPermission(LIST_EVENTS_REPORT_PERMISSIONS)) can('list', 'eventsReport');
    if (userHasPermission(VIEW_EVENTS_REPORT_PERMISSIONS)) can('view', 'eventsReport');

    return [build(), missingPermissions] as const;
}