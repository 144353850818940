import { selectUserPermissions } from 'app/store/user/userSlice';
import React, { createContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import hasPermission from 'src/utils/hasPermission';

type WidgetState = {
	children: React.ReactNode;
};

export const WidgetContext = createContext<WidgetState | undefined>(undefined);

export default function WidgetProvider({ children }: WidgetState) {
	const userPermissions = useSelector(selectUserPermissions);
	const isUserLoggedIn = userPermissions.length > 0;
	const hasPermissions = hasPermission(userPermissions, 'system.ticket-widget');
	const removeWidget = (hasPermission: boolean, id: string) => {
		const element = document.getElementById(id);
		element && hasPermission && element.remove();
	};
	removeWidget(false, 'jsd-widget');
	useEffect(() => {
		if (!isUserLoggedIn) {
			return;
		}
		removeWidget(!hasPermissions, 'jsd-widget');
	}, [isUserLoggedIn, hasPermissions]);

	return <>{children}</>;
}
