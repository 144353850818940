import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	generalFormValidationSchema,
	TGeneralFormValidationValues
} from 'app/main/commands/scheduleCommands/components/Modal/DialogForm/formValidationSchema';
import { TModalMode, TScheduleCommand } from 'app/main/commands/scheduleCommands/types';
import { InferType } from 'yup';
import { RootState } from '..';

export type TFormData = Partial<InferType<typeof generalFormValidationSchema>>;
type TInitialState = {
	isModalOpen: boolean;
	modalMode: TModalMode;
	selectedCommand: TScheduleCommand | null;
	form: {
		currentStep: number;
		data: TFormData;
	};
};

export const initialState: TInitialState = {
	isModalOpen: false,
	modalMode: 'new',
	selectedCommand: null,
	form: {
		currentStep: 0,
		data: null
	}
};

const scheduleCommandsSlice = createSlice({
	name: 'scheduleCommands',
	initialState,
	reducers: {
		setModalMode: (state, action: PayloadAction<TModalMode>) => {
			state.isModalOpen = true;
			state.modalMode = action.payload;
		},

		setSelectedCommand: (state, action: PayloadAction<TScheduleCommand>) => {
			state.selectedCommand = action.payload;
		},

		nextFormStep: (state) => {
			state.form.currentStep += 1;
		},

		prevFormStep: (state) => {
			state.form.currentStep -= 1;
		},

		addFormData: (state, { payload }: PayloadAction<Partial<TGeneralFormValidationValues>>) => {
			state.form.data = {
				...state.form.data,
				...payload
			};
		},

		clearFormData: (state) => {
			state.form.data = null;
		},

		clearSelectCommandStep: (state) => {
			state.form.data = {
				...state.form.data,
				name: '',
				command: null,
				trackerType: null,
				values: [],
				sendSMS: false
			};
		},
		clearSelectTargetStep: (state) => {
			state.form.data = {
				...state.form.data,
				targetCompanies: [],
				targetFleets: [],
				targetVehicles: [],
				targetTrackers: []
			};
		},

		clearSelectFrequencyStep: (state) => {
			state.form.data = {
				...state.form.data,
				cronString: ''
			};
		},

		goToStep: (state, action: PayloadAction<number>) => {
			state.form.currentStep = action.payload;
		}
	}
});

export const {
	setModalMode,
	nextFormStep,
	prevFormStep,
	addFormData,
	clearFormData,
	clearSelectCommandStep,
	clearSelectTargetStep,
	clearSelectFrequencyStep,
	setSelectedCommand,
	goToStep
} = scheduleCommandsSlice.actions;

export const selectModalMode = (state: RootState) => state.scheduleCommands.modalMode as TModalMode;

export const selectCurrentFormStep = (state: RootState) => state.scheduleCommands.form.currentStep as number;

export const selectCurrentFormData = (state: RootState) => state.scheduleCommands.form.data as TFormData;

export const selectSelectedCommand = (state: RootState) => state.scheduleCommands.selectedCommand as TScheduleCommand;

export default scheduleCommandsSlice.reducer;
