import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'geoFence'>, MatchConditions>;

const LIST_GEO_FENCE_PERMISSIONS = ['geofence.list'];

const VIEW_GEO_FENCE_PERMISSIONS = ['geofence.view'];

const CREATE_GEO_FENCE_PERMISSIONS = ['geofence.new'];

const UPDATE_GEO_FENCE_PERMISSIONS = ['geofence.edit'];

const DELETE_GEO_FENCE_PERMISSIONS = ['geofence.delete'];

export const defineGeoFenceAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(LIST_GEO_FENCE_PERMISSIONS),
		create: getMissingPermissions(CREATE_GEO_FENCE_PERMISSIONS),
		edit: getMissingPermissions(UPDATE_GEO_FENCE_PERMISSIONS),
		delete: getMissingPermissions(DELETE_GEO_FENCE_PERMISSIONS),
		list: getMissingPermissions(LIST_GEO_FENCE_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('geoFence', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(VIEW_GEO_FENCE_PERMISSIONS)) can('view', 'geoFence');
	if (userHasPermission(CREATE_GEO_FENCE_PERMISSIONS)) can('create', 'geoFence');
	if (userHasPermission(UPDATE_GEO_FENCE_PERMISSIONS)) can('edit', 'geoFence');
	if (userHasPermission(DELETE_GEO_FENCE_PERMISSIONS)) can('delete', 'geoFence');
	if (userHasPermission(LIST_GEO_FENCE_PERMISSIONS)) can('list', 'geoFence');

	return [build(), missingPermissions] as const;
};
