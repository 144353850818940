import { createSlice } from '@reduxjs/toolkit';
import { TFilters } from 'src/app/main/map/types';
import { RootState } from '..';

type TInitialState = {
	selectedFilters?: TFilters;
	selectedUserFilter: string;
	newFilterName: string;
};

export const initialState: TInitialState = {
	selectedUserFilter: '',
	newFilterName: '',
	selectedFilters: {
		company: [],
		fleet: [],
		vehicle: [],
		vehicleType: [],
		state: [],
		ignitionOn: '',
		velocity: { operator: '==', value: '' },
		isDefaultFilter: false
	}
};

const advanceFilterSlice = createSlice({
	name: 'advanceFilter',
	initialState,
	reducers: {
		setSelectedFilter: (state, action) => {
			const { filter, value } = action.payload;
			state.selectedFilters[filter] = value;
		},
		setVelocityOperator: (state, action) => {
			state.selectedFilters.velocity.operator = action.payload;
		},
		setVelocityValue: (state, action) => {
			state.selectedFilters.velocity.value = action.payload;
		},
		setIgnitionOn: (state, action) => {
			state.selectedFilters.ignitionOn = action.payload;
		},
		setNewFilterName: (state, action) => {
			state.newFilterName = action.payload;
		},
		setAllFilters: (state, action) => {
			state.selectedFilters = action.payload;
		},
		setSelectedUserFilter: (state, action) => {
			state.selectedUserFilter = action.payload;
		},
		setIsDefaultFilter: (state, action) => {
			state.selectedFilters.isDefaultFilter = action.payload;
		},
		setInitialState: () => initialState
	}
});

export const {
	setVelocityOperator,
	setVelocityValue,
	setIgnitionOn,
	setNewFilterName,
	setAllFilters,
	setSelectedUserFilter,
	setSelectedFilter,
	setIsDefaultFilter,
	setInitialState
} = advanceFilterSlice.actions;

export const selectFilters = (state: RootState) => state.advanceFilter.selectedFilters;
export const selectNewFilterName = (state: RootState) => state.advanceFilter.newFilterName;
export const selectSelectedUserFilter = (state: RootState) => state.advanceFilter.selectedUserFilter;
export const selectUserDefaultFilter = (state: RootState) => state.user.defaultFilter;

export default advanceFilterSlice.reducer;
