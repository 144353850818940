const locale = {
	TITLE: 'Registros de Instalações',
	YES: 'Sim',
	NO: 'Não',
	SEARCH: 'Pesquisar',
	CLEAR_SEARCH: 'Limpar filtros',
	CANCEL: 'Cancelar',
	CONFIRM: 'Confirmar',
	SELECTED_ITENS: '{{n}} itens selecionados',

	// Table
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	NAME: 'Nome',
	BRAND: 'Marca',
	MODEL: 'Modelo',
	YEAR: 'Ano',
	INSTALLATION_DATE: 'Data de instalação',
	MAINTENANCE_DATE: 'Data de manutenção',
	NO_MAINTENANCE: 'Sem registro de manutenção',

	// Delete
	DELETE: 'Deletar',
	DELETE_MULTIPLE_INFO: 'Deletar registros de instalação',
	DELETE_SUCCESS: 'Registro de instalação deletado com sucesso!',
	DELETE_ERROR: 'Erro ao deletar registro de instalação.',
	DELETE_MODAL_TITLE: 'Deletar registro de instalação',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Você tem certeza que deseja deletar {{n}} registros de instalação?',
	DELETE_MODAL_DESCRIPTION: 'Você tem certeza que deseja deletar este registro de instalação?',
	DELETE_MODAL_WARNING: 'Esta ação não poderá ser desfeita.',

	// Download
	DOWNLOAD_INFO: 'Baixar registros de instalação',
	DOWNLOAD_ERROR: 'Erro ao baixar registros de instalação.',

	// Drivers
	THROTTLE: 'Acelerador',
	MM_MIN: 'mínimo',
	MM_MAX: 'máximo',
	GEAR: 'Câmbio',
	MM_FORWARD: 'frente',
	MM_NEUTRAL: 'neutro',
	MM_BACKWARD: 'ré',
};

export default locale;
