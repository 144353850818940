import * as Plot from '@observablehq/plot';
import { ReactNode, useContext, useEffect, useRef } from 'react';

import { ChartContext } from './ChartContainer';

type StackedBarChartProps = {
	data: object[];
	loading?: boolean;
	barOptions?: Plot.BarYOptions;
	textOptions?: Plot.TextOptions;
	noDataComp?: ReactNode;
};

function StackedBarChart({
	data = [],
	barOptions = {},
	textOptions = {},
	noDataComp,
	loading = false
}: StackedBarChartProps) {
	const containerRef = useRef<HTMLDivElement>();
	const { dimensions } = useContext(ChartContext);

	useEffect(() => {
		if (!data.length || !containerRef.current) return;
		const plot = Plot.plot({
			...dimensions,
			marginLeft: 100,
			y: { label: null, tickPadding: 40, line: true, tickSize: 0, tickSpacing: 180 },
			x: { axis: null },
			marks: [
				Plot.barX(data, {
					x: 'value',
					y: 'label',
					sort: { y: 'x', reverse: true, limit: 10 },
					rx: 8,
					insetLeft: 20,
					...barOptions
				}),
				Plot.text(data, {
					textAnchor: 'end',
					dx: -18,
					fill: 'black',
					...textOptions
				})
			]
		});
		containerRef.current.append(plot);
		return () => plot.remove();
	}, [data, barOptions, textOptions, dimensions]);

	return !data.length || loading ? noDataComp : <div ref={containerRef} className="h-full" />;
}

export { StackedBarChart };
