import i18next from 'i18next';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const SimcardsPage = lazyRetry(() => import('./Simcards'));

i18next.addResourceBundle('en', 'simcardsPage', en);
i18next.addResourceBundle('sp', 'simcardsPage', sp);
i18next.addResourceBundle('pt', 'simcardsPage', pt);

export const SIMCARDS_PERMISSIONS = {
	edit: 'fleet.simcard.edit',
	view: 'fleet.simcard.view',
	list: 'fleet.simcard.list',
	new: 'fleet.simcard.new',
	delete: 'fleet.simcard.delete',
	minimal: ['admin.company.list', 'fleet.tracker.list']
};

const SimcardsConfig = {
	routes: [
		{
			auth: ['fleet.simcard.list'],
			path: 'fleet/simcards',
			element: (
				<LazyLoaderSuspense>
					<SimcardsPage />
				</LazyLoaderSuspense>
			),
			children: [
				{
					path: ':id',
					element: (
						<LazyLoaderSuspense>
							<SimcardsPage />
						</LazyLoaderSuspense>
					)
				}
			]
		}
	]
};

export default SimcardsConfig;
