import { Stack, Typography } from '@mui/material';
import React from 'react';

interface InfoItemProps {
	label: string;
	value: string | number;
	icon: React.ReactNode;
}

export default function InfoItem({ icon, label, value }: InfoItemProps) {
	return (
		<Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
			<Typography>{label}</Typography>

			<Stack direction="row" alignItems="center" spacing={1}>
				<Typography>{value}</Typography>
				{icon}
			</Stack>
		</Stack>
	);
}
