import { TFunction } from 'react-i18next';

import ColumnName from 'app/shared-components/Table/ColumnName';
import dayjs from 'dayjs';
import { isColumnOmitted } from './helper';
import { TColumnsNames, TFleetColumn } from './types';

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('DESCRIPTION'), columnId: 'notes' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('VEHICLES'), columnId: 'vehicle.name' },
	{ name: t('DESCRIPTION'), columnId: 'notes' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const columnsSearchKeys = ['name', 'notes, createdBy.name'];

export const defaultVisibleColumns = ['name', 'vehicle.name', 'notes', 'company.name', 'createdAt', 'createdBy.name'];

export const columnsSortKeys = ['_id', 'name', 'notes', 'createdAt'];

export const getColumns = (t: TFunction, filteredColumns: string[]): TFleetColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:sell" />,
		selector: (row) => row.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'vehicle.name',
		name: <ColumnName name={t('VEHICLES')} icon="material-outline:directions_car" />,
		selector: (row) =>
			Array.isArray(row.vehicle) && row.vehicle.length > 1
				? `${row.vehicle?.length} ${t('VEHICLES')}`
				: row.vehicle?.[0]?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('vehicle.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row.company?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'notes',
		name: <ColumnName name={t('DESCRIPTION')} icon="material-outline:notes" />,
		selector: (row) => row.notes,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('notes', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:calendar_today" />,
		selector: (row) => (row.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY') : ''),
		sortable: true,
		reorder: true,
		center: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row) => row.createdBy?.name,
		sortable: false,
		reorder: true,
		center: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	}
];
