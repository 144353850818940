import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TDateInputProps } from './types';

export const useGetDateFilterValue = ({ name, initialValue, form, defaultValues }: TDateInputProps) => {
	const [searchParams] = useSearchParams();

	const getUrlDate = (param: string, initialDate: dayjs.Dayjs) => {
		const date = searchParams.get(param);
		return date ? dayjs(date) : initialDate;
	};

	const initialDate = useMemo(() => getUrlDate(name, initialValue || dayjs()), [name, initialValue, searchParams]);

	useEffect(() => {
		if (defaultValues) return;
		form.setValue(name, initialDate);
	}, [form, name, initialDate]);

	if (defaultValues) return initialValue;

	return initialDate;
};
