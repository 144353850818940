import _ from 'lodash';

export const TRANSLATE_KEY = 'viewVehiclePage';

export const VIEW_VEHICLE_PERMISSIONS = {
	edit: 'fleet.vehicle.edit',
	view: 'fleet.vehicle.view',
	list: 'fleet.vehicle.list',
	new: 'fleet.vehicle.new',
	delete: 'fleet.vehicle.delete',
	minimal: ['admin.company.list', 'fleet.tracker.list', 'fleet.fleet.list']
};

export const getVehicleTypeIcon = (type: string) => {
	if (!type) return;
	const _type = _.replace(type, 'mat_outline', 'material-outline');
	return _type;
};
