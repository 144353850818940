import * as yup from 'yup';

const tableSchema = yup.object({
	code: yup.string().required('CODE_REQUIRED'),
	company: yup.string().required('COMPANY_REQUIRED'),
	driver: yup.string().optional(),
	notes: yup.string().optional()
});

export default tableSchema;
