import { WarningOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import i18next from 'i18next';
import { TFunction, useTranslation } from 'react-i18next';

type TNoPermission = {
	imageSrc?: string;
	permissionCodes?: string[];
	messageType?: 'view' | 'minimal';
	t?: TFunction;
};

i18next.addResourceBundle('en', 'noPermission', {
	PAGE_NOT_ALLOWED: 'Page not allowed',
	PAGE_NOT_ALLOWED_DESCRIPTION: 'You do not have permission to access this page',
	MINIMAL_START: 'You are missing the following permissions:',
	MINIMAL_END: 'Please contact your administrator'
});

i18next.addResourceBundle('pt', 'noPermission', {
	PAGE_NOT_ALLOWED: 'Recurso não autorizado',
	PAGE_NOT_ALLOWED_DESCRIPTION: 'Você não tem permissão para acessar esta página.',
	MINIMAL_START: 'As seguintes permissões estão faltando:',
	MINIMAL_END: 'Por favor, entre em contato com o seu administrador'
});

i18next.addResourceBundle('es', 'noPermission', {
	PAGE_NOT_ALLOWED: 'Recurso no permitido',
	PAGE_NOT_ALLOWED_DESCRIPTION: 'No tienes permiso para acceder a esta página',
	MINIMAL_START: 'Te faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador'
});

const ViewMessage = ({ message = '' }) => (
	<Stack spacing={1} alignItems="center">
		<WarningOutlined fontSize="large" color="secondary" />
		<Typography
			sx={{
				fontWeight: 600,
				fontSize: 18,
				color: (theme) => theme.palette.text.primary
			}}
		>
			{message}
		</Typography>
	</Stack>
);

const MinimalMessage = ({ startMsg = '', codes = [], endMsg = '' }) => (
	<Stack spacing={1} alignItems="center">
		<WarningOutlined fontSize="large" color="secondary" />
		<Typography
			sx={{
				fontWeight: 600,
				fontSize: 14,
				color: (theme) => theme.palette.text.primary
			}}
		>
			{startMsg}
		</Typography>
		<ul>
			{codes.map((c) => (
				<li key={c}>
					<Typography
						sx={{
							fontWeight: 400,
							color: (theme) => theme.palette.text.primary,
							fontStyle: 'italic'
						}}
					>
						{c}
					</Typography>
				</li>
			))}
		</ul>
		<Typography
			sx={{
				fontWeight: 600,
				fontSize: 14,
				color: (theme) => theme.palette.text.primary
			}}
		>
			{endMsg}
		</Typography>
	</Stack>
);

export default function NoPermission({ permissionCodes = [], messageType = 'minimal' }: TNoPermission) {
	const { t } = useTranslation('noPermission');

	return (
		<Box
			sx={{
				display: 'flex',
				width: '100%',
				height: '100%'
			}}
		>
			<Box
				sx={{
					width: '100%',
					flex: 1
				}}
			/>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					textAlign: 'center',
					width: '100%'
				}}
			>
				{messageType === 'view' ? (
					<ViewMessage message={t('PAGE_NOT_ALLOWED')} />
				) : (
					<MinimalMessage codes={permissionCodes} startMsg={t('MINIMAL_START')} endMsg={t('MINIMAL_END')} />
				)}
			</Box>
		</Box>
	);
}
