import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { styled } from '@mui/material/styles';
import AppContext from 'app/AppContext';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import LeftSideLayout2 from './components/LeftSideLayout2';
import NavbarWrapperLayout2 from './components/NavbarWrapperLayout2';
import RightSideLayout2 from './components/RightSideLayout2';
import ToolbarLayout2 from './components/ToolbarLayout2';

import Layout3 from '../layout3/Layout3';

const Root = styled('div')(({ theme, config }) => ({
	...(config.mode === 'container' && {
		'& .container': {
			maxWidth: `80vw`,
			width: '100%',
			margin: '0 auto'
		}
	})
}));

function Layout2(props: any) {
	const config = useSelector(selectFuseCurrentLayoutConfig);
	const { routes: appRoutes } = useContext(AppContext);
	const routes = useRoutes(appRoutes);

	if (window.innerWidth < 640) {
		return <Layout3 {...props} />;
	}

	return (
		<Root id="fuse-layout" className="w-full flex" config={config}>
			<LeftSideLayout2 />
			<div className="flex flex-col flex-auto min-w-0">
				<main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative">
					{config.toolbar.display && <ToolbarLayout2 />}
					{config.navbar.display && <NavbarWrapperLayout2 className="sticky top-0 z-50" />}
					<div className="flex flex-col flex-auto min-h-0 h-[84vh] relative z-10">
						<FuseDialog />
						<FuseSuspense>{routes}</FuseSuspense>
						{props.children}
					</div>
				</main>
			</div>
			<RightSideLayout2 />
			<FuseMessage />
		</Root>
	);
}

export default memo(Layout2);
