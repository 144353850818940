import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { styled } from '@mui/material/styles';
import AppContext from 'app/AppContext';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import { memo, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import RightSideLayout3 from './components/RightSideLayout3';
import ToolbarLayout3 from './components/ToolbarLayout3';

const Root = styled('div')(() => ({
	'& .container': {
		maxWidth: `100vw`,
		width: '100%',
		margin: '0 auto'
	},
	'& .boxed': {
		maxWidth: `100vw`,
		width: '100%',
		margin: '0 auto'
	}
}));

function Layout3(props: any) {
	const config = useSelector(selectFuseCurrentLayoutConfig);
	const appContext = useContext(AppContext);
	const { routes } = appContext;
	return (
		<Root id="fuse-layout" className="w-full flex">
			<div className="flex flex-col flex-auto min-w-0">
				<main id="fuse-main" className="flex flex-col flex-auto h-[100vh] min-w-0 relative">
					{config.toolbar.display && <ToolbarLayout3 />}
					<div className="flex flex-col flex-auto min-h-0 relative z-10">
						<FuseDialog />
						<FuseSuspense>{useRoutes(routes)}</FuseSuspense>
						{props.children}
					</div>
				</main>
			</div>
			<RightSideLayout3 />
			<FuseMessage />
		</Root>
	);
}

export default memo(Layout3);
