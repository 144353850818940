const locale = {
	CODE_REQUIRED: 'El campo de código es obligatorio',
	DRIVER: 'Conductor',
	DRIVER_REQUIRED: 'El campo de conductor es obligatorio',
	TRACKER: 'Rastreador',
	ICCID_REQUIRED: 'O campo ICCID é obrigatório',
	DID_MUST_BE_NUMBER: 'O campo DID deve ser um número',
	TRACKER_TYPE_REQUIRED: 'O campo tipo de rastreador é obrigatório',
	DID_REQUIRED: 'O campo DID é obrigatório',
	NOTES: 'Notas',
	VEHICLE: 'Rastreado',
	TRACKER_TYPE: 'Tipo de rastreador',
	TITLE: 'Ibuttons',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'no',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Borrar filtros',
	EDIT: 'Editar',
	VIEW: 'Ver',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfiles',
	PROFILE: 'Empresa',
	USERS: 'Usuarios',
	USER: 'Usuario',
	BIRTHDAY: 'Fecha de nacimiento',
	PASSWORD: 'Contraseña',
	CPASSWORD: 'Confirmar contraseña',
	DATE: 'Fecha',
	OF: 'de',
	DATE_FORMAT: 'es-ES',
	INACESSIBLE_HOSTS: 'Hosts accesibles',
	ADDRESS: 'Dirección',
	EMAIL: 'Correo electrónico',
	PHONE: 'Teléfono',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún artículo seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	ROWS_PER_PAGE: 'Artículos por página',
	NAME_REQUIRED: 'El campo de nombre es obligatorio',
	PHONE_REQUIRED: 'El campo de teléfono es obligatorio',
	EMAIL_REQUIRED: 'El campo de correo electrónico es obligatorio',
	ADDRESS_REQUIRED: 'El campo de dirección es obligatorio',
	TYPE_REQUIRED: 'El campo de tipo de cliente es obligatorio',
	COLUMN_NAME: 'Nombre',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Fecha de nacimiento',
	COLUMN_CREATED_BY: 'Creado por',
	COLUMN_CREATED_AT: 'Creado el',
	COLUMN_EMAIL: 'Correo electrónico',
	COLUMN_ADDRESS: 'Dirección',
	COLUMN_PHONE: 'Teléfono',
	NAME: 'nombre',
	DESC: 'Descripción',
	CREATED_AT: 'Creado el',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar ibutton',
	DELETE_CONFIRMATION: '¿Está seguro de que desea eliminar?',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Ibuttons de edición',
	EDIT_CONFIRMATION: '¿Está seguro de que desea editar este ibutton?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Está seguro de que desea editar los ibuttons seleccionados?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	FIELD_NAME: 'Nombre',
	FIELD_CODE: 'Código',
	FIELD_ICON: 'Icono',
	FIELD_DESC: 'Descripción',
	FIELD_CREATED_AT: 'Creado el',
	FIELD_CREATED_BY: 'Creado por',
	ERROR_PERMISSION: 'No tiene las autorizaciones necesarias, por favor contacte con el administrador de su sistema',
	ERROR_GETTING_USERS: 'Hubo un error al cargar los ibuttons, inténtelo de nuevo más tarde',
	ERROR_RESPONSE_404: 'Error: Datos no encontrados, comuníquese con el administrador de su sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor, inténtalo de nuevo más tarde',
	ERROR_NETWORK: 'Error de red, verifique su conexión e intente nuevamente',
	ADD_USER: 'Añadir Ibutton',
	CREATE_SUCCESS: 'Ibutton agregado exitosamente',
	CREATE_ERROR: 'Error al agregar ibutton',
	EDIT_SUCCESS: 'Ibutton actualizado con éxito',
	EDIT_ERROR: 'Error al actualizar ibutton',
	ERROR_ADD_EMPTY: 'Complete todos los campos',
	DELETE_SUCCESS: 'Ibutton eliminado con éxito',
	DELETE_ERROR: 'Error al eliminar ibutton',
	NO_DATA: 'No se encontraron elementos coincidentes',
	NO_RESULTS: 'No se encontraron resultados',
	NO_PERMISSION_list: 'No tienes permiso para ver la lista de ibuttons',
	NO_PERMISSION_new: 'No tienes permiso para agregar ibuttons',
	NO_PERMISSION_edit: 'No tienes permiso para editar ibuttons',
	NO_PERMISSION_view: 'No tienes permiso para ver ibuttons',
	NO_PERMISSION_delete: 'No tienes permiso para eliminar ibuttons',
	NO_ADDRESS: 'Dirección no encontrada',
	NO_PHONE: 'Teléfono no encontrado',
	NO_EMAIL: 'Correo electrónico no encontrado',
	NO_BIRTHDAY: 'Fecha de nacimiento no encontrada',
	LOADING: 'Cargando',
	UPLOAD_ERROR: 'Error al cargar el archivo',
	PASSWORD_MIN_LENGTH: 'debe tener al menos 8 caracteres',
	PASSWORDS_MUST_MATCH: 'Las contraseñas deben coincidir',
	PASSWORD_REQUIRED: 'el campo de contraseña es obligatorio',
	PASSWORD_MIN_LOWERCASE: 'debe tener al menos 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'debe tener al menos 1 letra mayúscula',
	PASSWORD_MIN_NUMBERS: 'debe tener al menos 1 número',
	PASSWORD_MIN_SYMBOLS: 'debe tener al menos 1 símbolo',
	MORE_THAN: 'más que',
	NO_USER_FOUND: 'Ibutton no encontrado',
	DELETE_MODAL_WARNING: '¡Advertencia!',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Está seguro de que desea eliminar {{n}} ibuttons?',
	EDIT_INFO: 'Editar ibutton',
	DELETE_INFO: 'Eliminar ibutton',
	DOWNLOAD_INFO: 'Descargar ibutton',
	EDIT_MULTIPLE_INFO: 'Editar ibuttons seleccionados',
	DELETE_MULTIPLE_INFO: 'Eliminar ibuttons seleccionados',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar el archivo',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	SELECTED_HOSTS: '{{n}} Host seleccionados',
	PROFILE_REQUIRED: 'El campo Perfil es obligatorio',
	CPASSWORD_REQUIRED: 'El campo Confirmar contraseña es obligatorio',
	EDIT_MULTIPLE_SUCCESS: 'ibuttons actualizados correctamente',
	EDIT_MULTIPLE_ERROR: 'Error al actualizar ibuttons',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, elige una imagen de menos de 8MB',
	COMPANY_REQUIRED: 'É necessário seleccionar una empresa',
	EMAIL_INVALID: 'Email inválido',
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',
	DOWNLOAD_ALL: 'Descargar todo',

	// Bulk

	SHOW_INVALID_ROWS: 'Mostrar filas inválidas',
	CLEAR_TABLE: 'Limpiar tabla',
	CREATING: 'Creando',
	DESCRIPTION_HEADER:
		'Aquí puedes crear identificadores en granel al cargar un archivo XLSX con datos de los identificadores o descargar la plantilla y completarla con los datos de los identificadores que deseas crear. Puedes abrir el archivo XLSX con Excel u otro editor de hojas de cálculo.',
	DOWNLOAD_TEMPLATE: 'Descargar plantilla.',
	IMPORT: 'Importar',
	DROPZONE_TEXT: 'Arrastra y suelta el archivo XLSX aquí o haz clic para seleccionar.',
	REMOVE_ROW: 'Eliminar filas',
	ERROR: 'Error',
	SUCCESS: 'Éxito',
	VALIDS: 'Válidos',
	INVALIDS: 'Inválidos',
	MASSIVE_CREATE_SUCCESS: 'Identificadores creados exitosamente!',
	MASSIVE_CREATE_ERROR: 'Error al crear identificadores',
	FILE_ERROR: 'Error al leer el archivo',
	CONFIG_TABLE: 'Configurar tabla',
	SHOW_FILTERS: 'Mostrar filtros'
};

export default locale;
