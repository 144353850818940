const locale = {
	// vehicleInformation

	YEAR: 'Año',
	BRAND: 'Marca',
	TYPE: 'Tipo',
	MODEL: 'Modelo',
	COLOR: 'Color',
	PLATE: 'Placa',
	VENDOR: 'Vendedor',

	// header

	EXPORT: 'Exportar',

	// commons

	NOT_PROVIDED: 'No informado',
	LAST_TRACKER_EVENT: 'Último evento del rastreador',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	TRACKERS: 'Rastreadores',
	TRACKERS_LABEL: '{{n}} Rastreadores',
	FLEETS: 'Flotas',
	FLEETS_LABEL: '{{n}} Flotas',
	COMPANY: 'Empresa',
	VIEW_COMPANY: 'Ver empresa',
	VIEW_FLEET: 'Ver flota',
	VEHICLE_INFORMATION: 'Información del vehículo',
	VIEW_SIMCARD: 'Ver simcard',
	SIMCARDS: 'Simcards',
	SIMCARDS_LABEL: '{{n}} Simcards',

	// lastTrackerEvent

	EVENT_TYPE: 'Tipo de evento',
	ADDRESS: 'Dirección',

	COORDINATES: 'Coordenadas',
	LAT: 'Lat',
	LNG: 'Lng',
	ALT: 'Altitud',

	INFO_TRACKER: 'Información del rastreador',
	SATELLITES: 'Satélites',
	RXLEV: 'Intensidad de la señal',
	GPS_FIXED: 'GPS fijado',
	IGNITION_ON: 'Ignición',
	ODOMETER: 'Odómetro',
	HOURMETER: 'Horímetro',
	CLOSE: 'Cerrar',
	BATTERY: 'Batería'
};

export default locale;
