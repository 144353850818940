import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type IntegrationsAbility = PureAbility<AbilityTuple<'integrations'>, MatchConditions>;

const INTEGRATE_PERMISSIONS = ['integrations.integrate'];

export const defineIntegrationsAbility = () => {
	const { can, build } = new AbilityBuilder<IntegrationsAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		view: getMissingPermissions(INTEGRATE_PERMISSIONS),
		create: [],
		edit: [],
		delete: [],
		list: []
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('integrations', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(INTEGRATE_PERMISSIONS)) can('view', 'integrations');

	return [build(), missingPermissions] as const;
};
