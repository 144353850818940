import LinearProgress from '@mui/material/LinearProgress';
import clsx from 'clsx';
import { TLesson } from '../../types/types';
interface LessonInfoProps {
  course: TLesson;
  className: string;
}
function LessonProgress({ lesson, className }) {
  return (
    // TODO IMPLEMENT COURSE PROGRESS
    <LinearProgress
      className={clsx('w-full h-2', className)}
      variant="determinate"
      // value={(lesson.progress.currentStep * 100) / lesson.totalSteps}
      color="secondary"
    />
  );
}

export default LessonProgress;
