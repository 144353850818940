import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';

import ListboxComponent from '../Hooked/ListBoxComponent';

interface ISelectButtonProps<T> {
	options: T[];
	optionLabel: string;
	label: string;
	placeholder?: string;
	loading?: boolean;
	limitText?: (_n: number) => string;
	fullWidth?: boolean;
	value: T[];
	isDisabled?: boolean;
	handleChange?: (_ev: any, _value: T[]) => void;
	optionKey?: string;
	isVirtualized?: boolean;
	multiple?: boolean;
}

// only used when the value of the SelectButton is an array of strings
export function getSelectedOptions(newValue: string, current: string[]) {
	const currentIndex = current.indexOf(newValue);
	const newChecked = [...current];

	if (currentIndex === -1) {
		newChecked.push(newValue);
	} else {
		newChecked.splice(currentIndex, 1);
	}
	return newChecked;
}

export default function SelectButton<T>({
	options = [],
	optionLabel,
	label,
	placeholder,
	loading = false,
	fullWidth = false,
	value = [],
	isDisabled = false,
	limitText = undefined,
	handleChange,
	optionKey = '_id',
	isVirtualized = false,
	multiple = true
}: ISelectButtonProps<T>) {
	return (
		<Autocomplete
			multiple={multiple}
			openOnFocus
			disablePortal
			id="tags-standard"
			size="small"
			options={options}
			loading={loading}
			limitTags={0}
			getOptionLabel={(option) => option[optionLabel]}
			value={value}
			fullWidth={fullWidth}
			disabled={isDisabled}
			onChange={handleChange}
			isOptionEqualToValue={_.isEqual}
			getLimitTagsText={limitText}
			disableCloseOnSelect
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label={label}
					placeholder={placeholder}
					fullWidth={fullWidth}
				/>
			)}
			renderOption={(props, option) => (
				<li {...props} key={option[optionKey] ?? option[optionLabel]}>
					{option[optionLabel]}
				</li>
			)}
			ListboxComponent={isVirtualized ? ListboxComponent : undefined}
			ListboxProps={{
				style: {
					overflowY: isVirtualized ? 'hidden' : 'auto'
				}
			}}
		/>
	);
}
