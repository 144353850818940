const locale = {
	TRACKER_COMMAND_UPDATE_ERROR: 'Error updating tracker command',
	TRACKER_COMMAND_UPDATED: 'Tracker command updated successfully',
	TRACKER_COMMAND_CREATE_SUCCESS: 'Tracker command created successfully',
	TRACKER_COMMAND_CREATE_ERROR: 'Error creating tracker command',
	COMMANDS_REGISTER: 'Commands register',
	COMMANDS: 'Commands',
	TRACKER_COMMAND_FIELD: 'Field',
	TRACKER_COMMAND_VALUE: 'Value',
	TRACKER_COMMAND_TYPE: 'Type',
	TRACKER_COMMAND_LABEL: 'Label',
	REQUIRED_FIELD: 'Required field',
	CONFIRM: 'Confirm',
	CREATE_COMMAND: 'Create command',
	CANCEL: 'Cancel',
	CREATE: 'Create',
	SAVE: 'Save',
	CLOSE: 'Close',
	SELECTED_COMPANIES: '{{n}} Selected Companies',
	TRACKER_COMMAND_COMPANIES: 'Companies',
	COMPANY_COMMANDS: 'Company commands',
	TRACKER_COMMAND_PARAMETERS: 'Parameters',
	TRACKER_COMMAND_ADD_PARAMETER: 'Add parameter',
	TRACKER_COMMAND_ICON: 'Icon',
	DELETE_TRACKER_COMMAND: 'Delete tracker command',
	DELETE_MODAL_TITLE: 'Delete tracker command',
	DELETE_MODAL_DESCRIPTION: 'Are you sure you want to delete this tracker command?',
	DEFAULT_COMMANDS: 'Default commands',
	USER_COMMANDS: 'User commands',
	TRACKER_COMMAND_DESCRIPTION: 'Tracker command description',
	EDIT_TRACKER_COMMAND: 'Edit tracker command',
	TRACKER_COMMAND_NAME: 'Tracker command name',
	TRACKER_COMMAND_PARSER: 'Tracker command parser',
	TRACKER_COMMAND_STRING: 'Tracker command string',
	EXECUTE_COMMAND: 'Execute command',
	SCHEDULE_COMMAND: 'Schedule command',
	EXECUTE_COMMANDS: 'Execute commands',
	SCHEDULE_COMMANDS: 'Schedule commands',
	SELECT_COMMAND: 'Select command',
	VEHICLES: 'Vehicles',
	FLEETS: 'Fleets',
	TRACKERS: 'Trackers',
	COMPANIES: 'Companies',
	EXECUTE_COMMANDS_IN: 'Execute commands in',
	MONTHLY: 'Monthly',
	WEEKLY: 'Weekly',
	DAILY: 'Daily',
	DAY: 'Day',
	WEEEKDAY: 'Weekdays',
	FREQUENCY: 'Frequency',
	EVERYDAY: 'Everyday',
	MONDAY: 'Monday',
	TUESDAY: 'Tuesday',
	WEDNESDAY: 'Wednesday',
	THURSDAY: 'Thursday',
	FRIDAY: 'Friday',
	SATURDAY: 'Saturday',
	SUNDAY: 'Sunday',
	UNIC_TIME: 'Specific time',
	PERIODIC_TIME: 'Periodic time',
	START: 'Start',
	END: 'End',
	TARGET_REQUIRED: 'You must select at least one recipient',
	COMMAND_REQUIRED: 'You must select a command',
	NO_COMMANDS: 'There are no available commands for this protocol',
	PARSER: 'Parser',
	SELECT_PARSER: 'Select parser',
	NO_COMPANIES: 'No companies available for this protocol',
	NO_FLEETS: 'No fleets available for this protocol',
	NO_VEHICLES: 'No vehicles available for this protocol',
	NO_TRACKERS: 'No trackers available for this protocol',
	HOURLY: 'Hour',
	MINUTE: 'Minute',
	MINUTES: 'Every minutes',
	HOURS: 'Every hours',
	SEND_SMS: 'Send SMS',
	PRIORITARY: 'Prioritary',
	ROWS_PER_PAGE: 'Rows per page',
	OF: 'of',
	FILTER: 'Filter',
	COLUMNS_INFO: 'Select which fields you want to appear in the table',
	COLUMNS: 'Columns',
	ITEMS: 'items',
	ADD: 'Add',
	SEARCH: 'Pesquisar',
	SEARCH_INFO: 'Select fields you want to search in the table',
	SCHEDULED_COMMANDS: 'Scheduled commands',
	HISTORY_COMMANDS: 'History commands',
	NAME: 'Name',
	SCHEDULED_COMMAND_NAME: 'Scheduled command name',
	COMMAND_NAME: 'Command name',
	FROM_STATUS: 'From status',
	TO_STATUS: 'To status',
	NEXT_EXECUTION: 'Next execution',
	NO_DATA: 'No data',
	CREATED_AT: 'Created at',
	UPDATED_AT: 'Updated at',
	CREATED_BY: 'Created by',
	VIEW_SUMMARY: 'View summary',
	EDIT_SCHEDULE_COMMAND: 'Edit schedule command',
	STATUS: 'Status',
	VALUES: 'Values',
	NO_SUMMARY: 'No summary available',
	ERROR: 'Error',
	SENT: 'Sent',
	CONFIRMED: 'Confirmed',
	QUEUED: 'Queued',
	SUMMARY_INFO: 'Command summaries are displayed by execution dates',
	EDIT: 'Edit',
	DELETE: 'Delete',
	EXECUTE_FAIL: 'Failed to execute command',
	EXECUTE_SUCCESS: 'Command executed successfully',
	SCHEDULE_FAIL: 'Failed to schedule command',
	SCHEDULE_SUCCESS: 'Command scheduled successfully',
	NAME_REQUIRED: 'Name is required',
	SCHEDULED_NAME: 'Scheduled command name',
	SCHEDULED_TO: 'Scheduled to',
	YES: 'Yes',
	NO: 'No',
	COMMAND_DELETED: 'Command deleted successfully',
	COMMAND_NOT_DELETED: 'Failed to delete command',
	TARGETS: 'Sent to',
	SUMMARY: 'Summary',
	DELETE_COMMAND_MESSAGE: 'Are you sure you want to delete this command?',
	DELETE_COMMAND_MESSAGE_2: 'This action cannot be undone',
	DELETE_COMMAND: 'Delete command',
	INFO_COMMAND: 'Command information',
	PERMISSIONS_NOT_ALLOWED: 'You do not have permission to do this action',

	IS_BLOCK: 'Is this command a block?',
	IS_UNBLOCK: 'Is this command an unblock?'
};

export default locale;
