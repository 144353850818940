import { Box, Typography } from '@mui/material';
import { ReactNode, createContext, useMemo, useRef } from 'react';

import { useSize } from '../hooks';

type TChartContainerProps = { title: string; children: ReactNode };

export const ChartContext = createContext({ dimensions: { width: 0, height: 0 } });

function ChartContainer({ children, title }: TChartContainerProps) {
	const chartWrapper = useRef<HTMLDivElement>();
	const size = useSize(chartWrapper);

	const value = useMemo(
		() => ({
			dimensions: { height: size?.height ?? 0, width: size?.width ?? 0 }
		}),
		[size?.width, size?.height]
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				border: '1px solid #D9D9D9',
				width: '100%',
				borderRadius: 1,
				padding: 2,
				minHeight: ['420px', '480px']
			}}
		>
			<Typography sx={{ fontWeight: 700, fontSize: '14px', paddingBottom: 2 }}>{title}</Typography>
			<ChartContext.Provider value={value}>
				<Box ref={chartWrapper} sx={{ flex: 1 }}>
					{children}
				</Box>
			</ChartContext.Provider>
		</Box>
	);
}

export { ChartContainer };
