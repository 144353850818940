import { PureAbility } from "@casl/ability";
import { yupResolver } from "@hookform/resolvers/yup";
import { filterSchemaEventsAndHistory, TFormData } from "app/main/reports/common/filters/filterSchema";
import { CompaniesSelect } from "app/main/reports/common/filters/selects/CompaniesSelect";
import { EventTypeSelect } from "app/main/reports/common/filters/selects/EventTypesSelect";
import { TrackersSelect } from "app/main/reports/common/filters/selects/TrackersSelect";
import { VehiclesSelect } from "app/main/reports/common/filters/selects/VehiclesSelect";
import { AppAbility } from "app/permissions/types";
import { EndFilter } from "app/shared-components/CRUDs/NewHeader/CommonFilters/EndFilter";
import { EndTimeFilter } from "app/shared-components/CRUDs/NewHeader/CommonFilters/EndTimeFilter";
import { Filters } from "app/shared-components/CRUDs/NewHeader/CommonFilters/Filter";
import { StartFilter } from "app/shared-components/CRUDs/NewHeader/CommonFilters/StartFIlter";
import { StartTimerFilter } from "app/shared-components/CRUDs/NewHeader/CommonFilters/StartTimeFilter";
import { showMessage } from "app/store/fuse/messageSlice";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { EventReportSearchParamsFilters } from "../../events/types";
import { HistorySearchParamsFilters } from "../../historyNew/types";
import { parseFormValuesToFilter } from "../helper";

interface FiltersProps {
  onApplyFilters: (values: HistorySearchParamsFilters | EventReportSearchParamsFilters) => void;
  defaultValues: HistorySearchParamsFilters | EventReportSearchParamsFilters;
  userAbility: PureAbility<AppAbility, unknown>;
  isEventReport?: boolean;
}

const initialFilters: TFormData = {
  search: '',
  start: dayjs().subtract(1, 'month'),
  end: dayjs(),
  company: [],
  tracker: [],
  vehicle: [],
  eventType: []
};

export const EventAndHistoryFilters: React.FC<FiltersProps> = ({ onApplyFilters, defaultValues, userAbility, isEventReport }) => {
  const { t } = useTranslation('historyPage');
  const dispatch = useDispatch();

  const form = useForm<TFormData>({
    defaultValues: {
      ...initialFilters,
      search: defaultValues?.search || ''
    },
    resolver: yupResolver(filterSchemaEventsAndHistory)
  });

  const handleApplyFilters = form.handleSubmit((values) => {
    const parsed = parseFormValuesToFilter(values);

    const sixMonthsAgo = dayjs().diff(dayjs(parsed.start), 'months');

    if (sixMonthsAgo > 6) {
      dispatch(showMessage({
        message: t('SIX_MONTHS_AGO_INFO'),
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        variant: 'info'
      }));
    }
    onApplyFilters(parsed);
  });

  const endDate = form.watch('end');
  const startDate = form.watch('start');

  return (
    <Filters handleApplyFilters={handleApplyFilters} form={form} t={t}>
      <div className="flex flex-col gap-16 flex-wrap w-full sm:w-auto">
        <div className="flex gap-16 flex-wrap w-full sm:w-auto">
          <CompaniesSelect
            className='min-w-[250px]'
            fullWidth={false}
            initialValue={defaultValues?.company}
            sx={{ maxWidth: 250 }}
          />

          <TrackersSelect
            className='min-w-[250px]'
            fullWidth={false}
            initialValue={defaultValues?.tracker}
            sx={{ maxWidth: 250 }}
          />

          <VehiclesSelect
            className='min-w-[250px]'
            fullWidth={false}
            initialValue={defaultValues?.vehicle}
            sx={{ maxWidth: 250 }}
          />
        </div>

        <div className="flex gap-16 flex-wrap w-full sm:w-auto">
          <EventTypeSelect
            className='min-w-[250px]'
            fullWidth={false}
            initialValue={defaultValues?.eventType}
            sx={{ maxWidth: 250 }}
          />

          {isEventReport ? (
            <>
              <StartFilter 
                name="start" 
                t={t} 
                form={form} 
                endDate={endDate} 
                noHelperText={false} 
                initialValue={initialFilters.start}
              />

              <EndFilter 
                name="end" 
                t={t} 
                form={form} 
                startDate={startDate} 
                noHelperText={false} 
                initialValue={initialFilters.end}
              />
            </>
          ) : (
            <>
              <StartTimerFilter 
                name="start" 
                t={t} 
                form={form} 
                endDate={endDate} 
                initialValue={initialFilters.start} 
              />

              <EndTimeFilter 
                name="end" 
                t={t} 
                form={form} 
                startDate={startDate} 
                initialValue={initialFilters.end}
              />
            </>
          )}

        </div>
      </div>
    </Filters>
  );
}