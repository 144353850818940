import { addTranslation } from 'app/contexts/DownloadContext/helper';
import i18next from 'i18next';
import { TRANSLATE_KEY } from './helper';

import LazyLoaderSuspense from 'app/shared-components/LazyLoaderSuspense';
import { lazyRetry } from 'src/utils/lazyRetry';
import en from './i18n/en';
import pt from './i18n/pt';
import sp from './i18n/sp';

const ViewVehiclePage = lazyRetry(() => import('../vehicle-view/ViewVehiclePage'));

i18next.addResourceBundle('en', TRANSLATE_KEY, en);
i18next.addResourceBundle('sp', TRANSLATE_KEY, sp);
i18next.addResourceBundle('pt', TRANSLATE_KEY, pt);
addTranslation();

const ViewVehicleConfig = {
	settings: {
		layout: {
			config: {
				footer: {
					display: false
				}
			}
		}
	},
	routes: [
		{
			auth: ['fleet.vehicle.view'],
			path: 'fleet/vehicle/:id',
			element: (
				<LazyLoaderSuspense>
					<ViewVehiclePage />
				</LazyLoaderSuspense>
			)
		}
	]
};

export default ViewVehicleConfig;
