import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import { formatPhoneNumber } from 'react-phone-number-input';

import ColumnName from 'app/shared-components/Table/ColumnName';
import TableArrayCell from 'app/shared-components/Table/TableArrayCell';
import _ from 'lodash';
import { mask } from 'src/utils/CpfCnpjMask';
import { TColumnsNames, TCompany, TCompanyColumn } from './types';

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('DESCRIPTION'), columnId: 'description' },
	{ name: t('EMAIL'), columnId: 'email' },
	{ name: t('CUSTOMER_TYPE'), columnId: 'type' },
	{ name: t('PHONE'), columnId: 'phone' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('DOC'), columnId: 'doc' },
	{ name: 'Host', columnId: 'host' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('BILLABLE_COMPANY'), columnId: 'billableCompany.name' }
	//	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('DESCRIPTION'), columnId: 'description' },
	{ name: t('EMAIL'), columnId: 'email' },
	{ name: t('CUSTOMER_TYPE'), columnId: 'type' },
	{ name: t('PHONE'), columnId: 'phone' },
	{ name: t('ADDRESS'), columnId: 'address' },
	{ name: t('DOC'), columnId: 'doc' },
	{ name: 'Host', columnId: 'host' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('BILLABLE_COMPANY'), columnId: 'billableCompany.name' }
	//	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const columnsSearchKeys = [
	'name',
	'description',
	'email',
	'type',
	'phone',
	'address',
	'doc',
	'host',
	'createdAt',
	'billableCompany.name'
	// 'createdBy.name'
];

export const defaultVisibleColumns = [
	'name',
	'description',
	'email',
	'type',
	'phone',
	'address',
	'doc',
	'host',
	'createdAt',
	'billableCompany.name'
	// 'createdBy.name'
];

export const columnsSortKeys = [
	'_id',
	'name',
	'description',
	'email',
	'type',
	'phone',
	'address',
	'doc',
	'host',
	'createdAt',
	'billableCompany.name'
	// 'createdBy.name'
];

export const isColumnOmitted = (columnId: string, allColumns: string[]) =>
	allColumns ? !allColumns.includes(columnId) : false;

export const getColumns = (t: TFunction, filteredColumns: string[]): TCompanyColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row: TCompany) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:apartment" />,
		selector: (row: TCompany) => row.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'description',
		name: <ColumnName name={t('DESCRIPTION')} icon="material-outline:notes" />,
		selector: (row: TCompany) => row.description,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('description', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'email',
		name: <ColumnName name={t('EMAIL')} icon="material-outline:alternate_email" />,
		selector: (row: TCompany) => row.email,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('email', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'type',
		name: <ColumnName name={t('CUSTOMER_TYPE')} icon="material-outline:badge" />,
		selector: (row: TCompany) => row.type,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('type', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'phone',
		name: <ColumnName name={t('PHONE')} icon="material-outline:phone" />,
		selector: (row) => formatPhoneNumber(row.phoneCountryCode + row.phone),
		sortable: false,
		reorder: false,
		omit: isColumnOmitted('phone', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'address',
		name: <ColumnName name={t('ADDRESS')} icon="material-outline:location_on" />,
		selector: (row: TCompany) => row.address,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('address', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'doc',
		name: <ColumnName name={t('DOC')} icon="material-outline:badge" />,
		selector: (row: TCompany) => mask(row.doc).value,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('doc', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'billableCompany.name',
		name: <ColumnName name={t('BILLABLE_COMPANY')} icon="material-outline:bussiness" />,
		selector: (row: TCompany) => _.get(row, 'billableCompany.name', ''),
		sortable: false,
		reorder: false,
		omit: isColumnOmitted('billableCompany.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'host',
		name: <ColumnName name={t('HOSTS')} icon="material-outline:link" />,
		cell: (row: TCompany) => <TableArrayCell data={row.host} displayProp="host" columnDisplayName={t('HOSTS')} />,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('host', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:calendar_today" />,
		selector: (row: TCompany) => (row.createdAt ? dayjs(row.createdAt).format('DD/MM/YYYY') : ''),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	}
	// this prop doesn't exists or is not returned by the api endpoint
	/* 	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person" />,
		selector: (row: TCompany) => row.createdBy?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	} */
];
