import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useLazyUserThemeQuery } from 'app/store/api/settingsSlice';
import { useLazyGetUserQuery } from 'app/store/api/userSlice';
import { selectCompanySettings } from 'app/store/company/companySettingsSlice';
import { changeFuseSettings } from 'app/store/fuse/settingsSlice';
import { useAppDispatch } from 'app/store/index';
import { updateUserData } from 'app/store/user/userSlice';
import { parseUserData } from 'src/utils/utils';

export function useUserData() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const companySettings = useSelector(selectCompanySettings);
	const [getUserTheme, { isLoading: themeLoading }] = useLazyUserThemeQuery();
	const [getUserMe, { isLoading: userMeLoading }] = useLazyGetUserQuery();
	const loading = themeLoading || userMeLoading;

	const getUserData = useCallback(
		async (userId: string) => {
			try {
				const userMeResponse = await getUserMe(userId).unwrap();
				return userMeResponse;
			} catch {
				throw new Error('USER_ME_ERROR');
			}
		},
		[getUserMe]
	);

	const getUserThemeData = useCallback(async () => {
		try {
			const userThemeResponse = await getUserTheme().unwrap();
			return userThemeResponse;
		} catch {
			throw new Error('USER_THEME_ERROR');
		}
	}, [getUserTheme]);

	const setupUser = useCallback(
		async (userId: string) => {
			const [userMe, userTheme] = await Promise.all([getUserData(userId), getUserThemeData()]);
			const parsedUser = parseUserData(userMe);
			const userThemeSettings = _.get(userTheme, 'settings', null);

			if (userThemeSettings) {
				dispatch(changeFuseSettings(userThemeSettings));
			}

			dispatch(updateUserData(parsedUser));
			navigate(_.get(companySettings, 'companyLogo.home', '/realtime'));
		},
		[getUserData, getUserThemeData, dispatch, navigate, companySettings]
	);

	return {
		setupUser,
		loading
	};
}
