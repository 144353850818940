import { createSlice } from '@reduxjs/toolkit';
import { FuelSupply } from 'app/types/fuel-supply.types';

interface IInitialState {
	establishmentInMap?: {
		id?: string;
		latitude: number;
		longitude: number;
		name: string;
		company: {
			_id: string;
			name: string;
		} | null;
	};
	displayPopup?: boolean;
	mode: 'create' | 'update';
	selectedSupply?: FuelSupply;
}

const initialState: IInitialState = {
	establishmentInMap: null,
	displayPopup: false,
	mode: 'create',
	selectedSupply: null
};

const registerFuelSupply = createSlice({
	name: 'register-fuel-supply',
	initialState,
	reducers: {
		setEstablishmentInMap: (state, action) => {
			state.establishmentInMap = action.payload;
		},
		removeEstablishmentInMap: (state) => {
			state.establishmentInMap = null;
		},
		setDisplayPopup: (state, action) => {
			state.displayPopup = action.payload;
		},
		setMode: (state, action) => {
			state.mode = action.payload;
		},
		setSelectedSupply: (state, action) => {
			state.selectedSupply = action.payload;
		}
	}
});

export const { setEstablishmentInMap, removeEstablishmentInMap, setDisplayPopup, setMode, setSelectedSupply } =
	registerFuelSupply.actions;

export default registerFuelSupply.reducer;
