import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { tableSchema } from 'app/main/drivers/components/MassiveCreate/tableSchema';

import { defaultVisibleColumns } from 'app/main/drivers/tableColumns';
import { TDriver, TSidebarMode } from 'app/main/drivers/types';
import storageService from 'app/services/storage';
import _ from 'lodash';
import { RootState } from '../..';
import { IPayload } from '../../api/driversSlice';

type TInitialState = {
	selectedRows: TDriver[];
	isSidebarOpen: boolean;
	sidebarMode: TSidebarMode;
	visibleColumns: string[];
	massiveCreateTableData: IPayload[];
	isMassiveUploading: boolean;
	isShowingInvalidRows: boolean;
	selectedMassiveCreateRows: IPayload[];
};

const initialState: TInitialState = {
	massiveCreateTableData: [],
	selectedMassiveCreateRows: [],
	selectedRows: [],
	isSidebarOpen: false,
	sidebarMode: 'view',
	visibleColumns: storageService.get('driversVisibleColumns') || defaultVisibleColumns,
	isMassiveUploading: false,
	isShowingInvalidRows: false
};

const crudSlice = createSlice({
	name: 'driversCrud',
	initialState,
	reducers: {
		setSelectedRows: (state, action: PayloadAction<TDriver[]>) => {
			state.selectedRows = action.payload;
		},
		setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload;
		},
		setSidebarMode: (state, action: PayloadAction<TSidebarMode>) => {
			state.sidebarMode = action.payload;
			state.isSidebarOpen = true;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('driversVisibleColumns', action.payload);
		},
		resetSidebarState: (state) => {
			state.isSidebarOpen = false;
			state.selectedRows = [];
		},
		setMassiveCreateTableData: (state, action: PayloadAction<IPayload[]>) => {
			state.massiveCreateTableData = action.payload;
		},
		resetMassiveCreateTableData: (state) => {
			state.massiveCreateTableData = [];
			state.selectedMassiveCreateRows = [];
		},
		setIsMassiveUploading: (state, action: PayloadAction<boolean>) => {
			state.isMassiveUploading = action.payload;
		},
		setIsShowingInvalidRows: (state, action: PayloadAction<boolean>) => {
			state.isShowingInvalidRows = action.payload;
		},
		setSelectedMassiveCreateRows: (state, action: PayloadAction<IPayload[]>) => {
			state.selectedMassiveCreateRows = action.payload;
		},
		removeRowsFromData: (state, action: PayloadAction<IPayload[]>) => {
			state.massiveCreateTableData = _.filter(
				state.massiveCreateTableData,
				(row) => !_.some(action.payload, (rowToRemove) => _.isEqual(row, rowToRemove))
			);
		}
	}
});

export const {
	setIsSidebarOpen,
	setSidebarMode,
	setSelectedRows,
	setVisibleColumns,
	resetSidebarState,
	setMassiveCreateTableData,
	resetMassiveCreateTableData,
	setIsMassiveUploading,
	setIsShowingInvalidRows,
	setSelectedMassiveCreateRows,
	removeRowsFromData
} = crudSlice.actions;
export const selectIsSidebarOpen = (state: RootState) => state.driversCrud.isSidebarOpen as boolean;
export const selectSidebarMode = (state: RootState) => state.driversCrud.sidebarMode as TSidebarMode;
export const selectSelectedRows = (state: RootState) => state.driversCrud.selectedRows as TDriver[];

export const selectVisibleColumns = (state: RootState) => state.driversCrud.visibleColumns as string[];

export const selectMassiveCreateTableData = createSelector(
	(state: RootState) => state.driversCrud.isShowingInvalidRows,
	(state: RootState) => state.driversCrud.massiveCreateTableData,
	(isShowingInvalidRows, massiveCreateTableData) => {
		if (isShowingInvalidRows) {
			return massiveCreateTableData.filter((row) => !tableSchema.isValidSync(row));
		} else {
			return massiveCreateTableData;
		}
	}
);

export const selectInvalidRowsCount = createSelector(
	(state: RootState) => state.driversCrud.massiveCreateTableData,
	(massiveCreateTableData) => {
		return massiveCreateTableData.filter((row) => !tableSchema.isValidSync(row)).length;
	}
);

export const selectSelectedMassiveCreateRows = (state: RootState) =>
	state.driversCrud.selectedMassiveCreateRows as IPayload[];

export const selectIsMassiveUploading = (state: RootState) => state.driversCrud.isMassiveUploading as boolean;

export const selectIsShowingInvalidRows = (state: RootState) => state.driversCrud.isShowingInvalidRows as boolean;

export default crudSlice.reducer;
