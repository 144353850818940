import { Divider } from '@mui/material';
import HookedTextfield from 'app/shared-components/Hooked/HookedTextField';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatCpfCnpj } from 'src/utils/dynamicCpfCnpjMask';
import { FindEntitySFormValues } from '../form-schema';
import { IntegrateTitle } from './IntegrateTitle';
import { SubmitButton } from './SubmitButton';

export const Header: React.FC = () => {
	const { t } = useTranslation('integrationsPage');
	const navigate = useNavigate();

	const onClose = () => {
		navigate('/integrations');
	};

	const handleChange = (e) => {
		const inputValue = e.target.value;
		const formattedValue = formatCpfCnpj(inputValue);
		setValue('search', formattedValue);
	};

	const { control, setValue } = useFormContext<FindEntitySFormValues>();
	return (
		<div className="flex flex-col gap-20 w-full">
			<button onClick={onClose} className="btn btn-secondary flex items-center gap-2">
				<span className="material-icons">arrow_back</span>
				{t('BACK')}
			</button>
			<div className="flex gap-8 items-center h-[56px] ">
				<IntegrateTitle />

				<div className="flex ml-auto gap-8">
					<HookedTextfield
						noHelperText
						control={control}
						name="search"
						label={t('CPF_CNPJ')}
						required
						fullWidth
						t={t}
						size="medium"
						onChangeCallback={handleChange}
					/>

					<SubmitButton className="w-full" />
				</div>
			</div>
			<Divider flexItem />
		</div>
	);
};
