import store from 'app/store/index';
import _ from 'lodash';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';

const userHasPermission = (code: string | string[]) => {
	const permissions = store.getState().user?.permissions || [];
	const userPermissions = permissions.map((permission) => permission.code);

	if (userPermissions.includes(SYSTEM_ADMIN_PERMISSION)) {
		return true;
	}

	if (_.isArray(code)) {
		return code.every((permission) => userPermissions.includes(permission));
	}

	return userPermissions.includes(code);
};

export default userHasPermission;
