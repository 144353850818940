import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from 'maplibre-gl';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { RootState } from 'app/store/index';
import { MapRef } from 'react-map-gl/maplibre';

export type TFeatures = {
	[id: string]: Feature;
};
type TInitialState = {
	isDrawerOpen: boolean;
	features?: TFeatures;
	mapInstance: MapRef;
	drawControlInstance: any;
	currentData: any;
	isDeleteModalOpen: boolean;
};

const initialState: TInitialState = {
	isDrawerOpen: false,
	features: null,
	mapInstance: null,
	drawControlInstance: null,
	currentData: null,
	isDeleteModalOpen: false
};

const fenceSlice = createSlice({
	name: 'fence',
	initialState,
	reducers: {
		setFenceMapInstance: (state, action: PayloadAction<MapRef>) => {
			state.mapInstance = action.payload;
		},
		setDrawControlIstance: (state, action: PayloadAction<MapboxDraw>) => {
			state.drawControlInstance = action.payload;
		},
		setFenceDrawerState: (state, action: PayloadAction<boolean>) => {
			state.isDrawerOpen = action.payload;
		},
		setFenceFeatures: (state, action: PayloadAction<TFeatures>) => {
			state.features = action.payload;
		},
		deleteFenceFeatures: (state, action: PayloadAction<Feature[]>) => {
			action.payload.forEach((f) => {
				delete state.features[f.id];
			});
		},
		updateFenceFeatures: (state, action: PayloadAction<Feature[]>) => {
			action.payload.forEach((f) => {
				state.features[f.id] = {
					type: f.type,
					properties: f.properties,
					geometry: f.geometry
				};
			});
		},
		setCurrentData: (state, action: PayloadAction<any>) => {
			state.currentData = action.payload;
		},
		setDeleteModalState: (state, action: PayloadAction<boolean>) => {
			state.isDeleteModalOpen = action.payload;
		}
	}
});

export const {
	setFenceDrawerState,
	setFenceFeatures,
	deleteFenceFeatures,
	setDrawControlIstance,
	setFenceMapInstance,
	updateFenceFeatures,
	setCurrentData,
	setDeleteModalState
} = fenceSlice.actions;

export const selectFenceDrawerState = (state: RootState) => state.fence.isDrawerOpen;
export const selectFenceFeatures = (state: RootState) => state.fence.features;

export const selectFenceMapInstance = (state: RootState) => state.fence.mapInstance as MapRef;
export const selectDrawControlInstance = (state: RootState) => state.fence.drawControlInstance;
export const selectCurrentData = (state: RootState) => state.fence.currentData;

export const selectDeleteModalState = (state: RootState) => state.fence.isDeleteModalOpen;
export default fenceSlice.reducer;
