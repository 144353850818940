import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { usePrevious } from 'app/services/hooks';
import AppButton from 'app/shared-components/Buttons/AppButton';
import { ViewIcon } from 'app/shared-components/CRUDs/Sidebar/ViewItem';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import HookedTextfield from 'app/shared-components/Hooked/HookedTextField';
import HookedVirtualizedChipSelect from 'app/shared-components/Hooked/HookedVirtualizedChipSelect';

import usePermission from 'app/services/hooks/usePermission';
import { useGetCourseCategoryQuery } from 'app/store/api/courseCategorySlice';
import { IPayload, useCreateCoursesMutation, useEditCoursesMutation } from 'app/store/api/coursesSlice';
import { useGetLessonsQuery } from 'app/store/api/lessonsSlice';
import {
	selectSidebarMode,
	setCurrentRegisterDrawerForm,
	setIsSidebarOpen,
	toggleRegisterDrawer
} from 'app/store/crud/coursesSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useNavigate } from 'react-router-dom';
import { ACADEMY_COURSECATEGORY_PERMISSIONS, ACADEMY_LESSONS_PERMISSIONS } from '../../AcademyAppConfig';
import { TCategory, TLesson } from '../../types/types';
import LessonsDragger from '../LessonsDragger';
import { REQUEST_MESSAGES_COURSE } from '../helper';
import { CourseType, TFormMode } from '../types';
import { validationSchemaCreate, validationSchemaEdit } from './formSchema';

type TFormData = yup.InferType<typeof validationSchemaCreate> | yup.InferType<typeof validationSchemaEdit>;

const emptyArr = [];

const initialForm: TFormData = {
	title: '',
	description: '',
	category: [],
	lessons: []
};

const parseCourseDataForApi = (data: TFormData, id = '') => {
	const dataObj = _.chain({
		...(id ? { id } : {}),
		title: data.title,
		description: data.description,
		category: _.map(data.category, '_id'),
		lessons: _.map(data.lessons, '_id')
	})
		.omitBy((v) => _.isNull(v) || _.isUndefined(v))
		.value() as IPayload;

	return dataObj;
};

const parseCourseInitialData = (data: CourseType) => ({
	title: data.title,
	description: data.description,
	category: data.category,
	lessons: data.lessons
});

export default function FormMode({ course: selectedCourse }: TFormMode) {
	const mode = useSelector(selectSidebarMode);
	const prevMode = usePrevious(mode);
	const { t } = useTranslation('academyPage');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const lessonPermissions = usePermission({ pagePermissions: ACADEMY_LESSONS_PERMISSIONS });
	const courseCategoryPermissions = usePermission({ pagePermissions: ACADEMY_COURSECATEGORY_PERMISSIONS });

	const { control, handleSubmit, reset, watch, setValue } = useForm<TFormData>({
		resolver: yupResolver(mode !== 'create' ? validationSchemaEdit : validationSchemaCreate),
		defaultValues:
			mode === 'edit' ? parseCourseInitialData(_.omitBy(selectedCourse, _.isNull) as CourseType) : initialForm,
		mode: 'all'
	});

	const [selectedLessons, setSelectedLessons] = useState<TLesson[]>([]);
	const watchedLessons = watch('lessons', []);

	const [createCourse, { isLoading: isCreateLoading }] = useCreateCoursesMutation();
	const [updateCourse, { isLoading: isUpdateLoading }] = useEditCoursesMutation();
	const isLoading = isCreateLoading || isUpdateLoading;

	const handleToggleRegisterSidebar = (form: string) => {
		dispatch(toggleRegisterDrawer());
		dispatch(setCurrentRegisterDrawerForm(form));
	};

	const handleCancel = () => {
		navigate(`/academy/courses`, { replace: true });
		dispatch(setIsSidebarOpen(false));
	};

	const onSubmit = async (data: TFormData) => {
		try {
			const parsed = parseCourseDataForApi(data, selectedCourse?._id);
			if (mode === 'create') {
				await createCourse(parsed).unwrap();
			} else {
				await updateCourse(parsed).unwrap();
			}
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_COURSE[mode].success),
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					},
					variant: 'success'
				})
			);
			reset(initialForm);
			if (mode === 'edit') {
				dispatch(setIsSidebarOpen(false));
			}
		} catch (err) {
			dispatch(
				showMessage({
					message: t(REQUEST_MESSAGES_COURSE[mode].error),
					autoHideDuration: 6000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center'
					},
					variant: 'error'
				})
			);
		}
	};

	const { CourseCategories, isCourseCategoriesLoading } = useGetCourseCategoryQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			CourseCategories: data?.docs || emptyArr,
			isCourseCategoriesLoading: isLoading
		})
	});

	const { lessons, isLessonsLoading } = useGetLessonsQuery('', {
		selectFromResult: ({ data, isLoading }) => ({
			lessons: data?.docs || emptyArr,
			isLessonsLoading: isLoading
		})
	});

	useEffect(() => {
		if (!prevMode) return;
		if (prevMode !== 'create' && mode === 'create') {
			reset(initialForm);
		}
		setSelectedLessons(watchedLessons);
	}, [mode, prevMode, reset, watchedLessons]);

	useEffect(() => {
		if (!_.isEmpty(selectedCourse)) {
			reset(parseCourseInitialData(_.omitBy(selectedCourse, _.isNull) as CourseType));
		}
		// eslint-disable-next-line
	}, [selectedCourse]);

	return (
		<Stack component="form" direction="column" onSubmit={handleSubmit(onSubmit)} spacing={3} paddingX={3}>
			<Stack direction="row" spacing={1}>
				<ViewIcon icon="school" />
				<HookedTextfield<TFormData>
					control={control}
					required
					label={t('COURSE_TITLE')}
					t={t}
					variant="outlined"
					name="title"
					size="small"
					fullWidth
				/>
			</Stack>

			<Stack direction="row" spacing={1}>
				<ViewIcon icon="description" />
				<HookedTextfield<TFormData>
					control={control}
					label={t('DESCRIPTION')}
					t={t}
					variant="outlined"
					name="description"
					size="medium"
					fullWidth
				/>
			</Stack>

			<Stack direction="row" spacing={1}>
				<ViewIcon icon="category" />
				<HookedVirtualizedChipSelect<TFormData, TCategory>
					optionLabel="name"
					label={t('CATEGORY')}
					required
					options={CourseCategories}
					loading={isCourseCategoriesLoading}
					limitText={(n) => t('SELECTED_CATEGORIES', { n })}
					fullWidth
					size="small"
					control={control}
					name="category"
					t={t}
				/>
				{courseCategoryPermissions.hasNew ? (
					<ViewIcon icon="add" onClick={() => handleToggleRegisterSidebar('category')} button />
				) : undefined}
			</Stack>

			<Stack direction="row" spacing={1}>
				<ViewIcon icon="class" />
				<HookedVirtualizedChipSelect<TFormData, TLesson>
					optionLabel="title"
					label={t('LESSONS')}
					required
					options={lessons}
					loading={isLessonsLoading}
					limitText={(n) => t('SELECTED_LESSONS', { n })}
					fullWidth
					size="small"
					control={control}
					name="lessons"
					t={t}
				/>
				{lessonPermissions.hasNew ? (
					<ViewIcon icon="add" onClick={() => handleToggleRegisterSidebar('lesson')} button />
				) : undefined}
			</Stack>

			<LessonsDragger selectedLessons={selectedLessons} setValue={setValue} />

			<Box
				sx={{
					display: 'flex',
					paddingTop: 2,
					paddingBottom: 3,
					gap: 1
				}}
			>
				<AppButton variant="outlined" color="secondary" onClick={handleCancel} fullWidth>
					{t('CANCEL')}
				</AppButton>
				<AppButton
					variant="contained"
					color="secondary"
					fullWidth
					type="submit"
					loading={isLoading}
					disabled={isLoading}
				>
					{t('SAVE')}
				</AppButton>
			</Box>
		</Stack>
	);
}
