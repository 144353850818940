const locale = {
	TITLE: 'Relatório de Operadores',
	YES: 'Sim',
	NO: 'Não',
	OPERATOR: 'Operador: {{name}}',
	ALERTS: 'Alertas',

	// Buttons
	DOWNLOAD_REPORT: 'Baixar Relatório',
	SELECT_FILTERS: 'Selecionar Filtros',
	SIMPLIFIED_VIEW: 'Simplificado',
	LIST_VIEW: 'Lista',

	// FIlter
	CLEAR_ALL: 'Limpar Todos',
	USER: 'Usuário',
	SINCE: 'De',
	UNTIL: 'Até',
	GROUP_BY: 'Agrupar por',
	USER_REQUIRED: 'O campo "Usuário" é obrigatório',
	SINCE_REQUIRED: 'O campo "Apartir de" é obrigatório',
	GROUP_BY_REQUIRED: 'O campo "Agrupar por" é obrigatório',
	DAY_SHALL_BE_LESS_THAN_31: 'O intervalo máximo para o agrupamento por dia é de 31 dias',
	WEEK_SHALL_BE_LESS_THAN_12: 'O intervalo máximo para o agrupamento por semana é de 12 semanas',
	MONTH_SHALL_BE_LESS_THAN_12: 'O intervalo máximo para o agrupamento por mês é de 12 meses',

	// Widgets
	DONE_ALERTS: 'Alertas Atendidos',
	MONTLY_AVERAGE: 'Média Mensal',
	TOTAL_TIME: 'Tempo Total',
	HOURS: 'Horas',
	MAX_TIME: 'Tempo Máximo',
	AT_DAY: 'No Dia',
	VIEW_DETAILS: 'Ver Detalhes',
	AVERAGE_TIME_PER_ALERT: 'Tempo Médio por Alerta',

	// Chart
	ALERTS_BY_DAY: 'Alertas por dia:',
	ALERTS_BY_WEEK: 'Alertas por semana:',
	ALERTS_BY_MONTH: 'Alertas por mês:',
	DAY: 'Dia',
	WEEK: 'Semana',
	MONTH: 'Mês',
	ALERTS_AT_DAY: 'Alertas no Dia',

	// Simplified view
	TOTAL_ALARMS: 'Total de Alertas',
	DONE_AT_DAY: 'Atendidos no dia',
	DONE_AT_WEEK: 'Atendidos na semana',
	DONE_AT_MONTH: 'Atendidos no mês',
	ALARMS_AT_DAY: 'Alertas no dia',
	ALARMS_AT_WEEK: 'Alertas na semana',
	ALARMS_AT_MONTH: 'Alertas no mês',
	TIME_AT_DAY: 'Tempo no dia',
	TIME_AT_WEEK: 'Tempo na semana',
	TIME_AT_MONTH: 'Tempo no mês',
	AVERAGE_PER_ALERT: 'Média por Alerta',
	MONTHLY_AVERAGE_TIME: 'Média Mensal de Tempo',
	VEHICLE: 'Veículo',
	TRACKER: 'Rastreador',
	UPDATED_AT: 'Atualizado em',
	ALERT_EVENT: 'Evento do Alerta',

	// List view
	NO_ALERTS_THIS_DAY: 'Nenhum alerta atendido neste dia',

	// No data
	NO_FILTERS: 'Nenhum filtro aplicado',
	NO_FILTERS_SUBTITLE: 'Selecione um usuário e um período para gerar o relatório',
	OPERATOR_HAS_NO_REPORT_DATA: 'Operador selecionado não possuí histórico para esta data.',

	// Months
	JANUARY: "Janeiro",
	FEBRUARY: "Fevereiro",
	MARCH: "Março",
	APRIL: "Abril",
	MAY: "Maio",
	JUNE: "Junho",
	JULY: "Julho",
	AUGUST: "Agosto",
	SEPTEMBER: "Setembro",
	OCTOBER: "Outubro",
	NOVEMBER: "Novembro",
	DECEMBER: "Dezembro"
};

export default locale;
