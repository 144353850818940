import { AbilityBuilder, MatchConditions, PureAbility } from '@casl/ability';
import { SYSTEM_ADMIN_PERMISSION } from 'src/constants';
import { defineAdminAbility } from 'src/utils/define-admin-ability';
import userHasPermission from 'src/utils/useHasPermission';
import { getMissingPermissions } from '../helper';
import { AbilityTuple, MissingPermissions } from '../types';

type RulerAbility = PureAbility<AbilityTuple<'command'>, MatchConditions>;

const EXECUTE_COMMAND_PERMISSIONS = ['command.execute'];

export const defineCommandAbility = () => {
	const { can, build } = new AbilityBuilder<RulerAbility>(PureAbility);

	const missingPermissions: MissingPermissions = {
		execute: getMissingPermissions(EXECUTE_COMMAND_PERMISSIONS)
	};

	if (userHasPermission(SYSTEM_ADMIN_PERMISSION)) {
		defineAdminAbility('command', can);
		return [build(), missingPermissions] as const;
	}

	if (userHasPermission(EXECUTE_COMMAND_PERMISSIONS)) can('execute', 'command');

	return [build(), missingPermissions] as const;
};