import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortOrder } from 'react-data-table-component';

import { RootState } from '..';
import { TMonitoringPaginatedQuery } from 'app/services/hooks/usePaginationQueryMonitoring';
import { TGroupedAlarms, TKanbanColumns, TMonitoringAlert, TMonitoringGroup } from 'app/main/monitoring/types';

export type TSelectedAlert = { id: string; column: TKanbanColumns };

type TInitialState = {
	isDragging: boolean;
	selectedAlert?: TSelectedAlert;
	filters: {
		toDo: Partial<TMonitoringPaginatedQuery>;
		onGoing: Partial<TMonitoringPaginatedQuery>;
	};
	groups: {
		toDo?: TMonitoringGroup;
		onGoing?: TMonitoringGroup;
	};
	draggedAlert?: TMonitoringAlert;
};

export const initialFilters: Partial<TMonitoringPaginatedQuery> = {
	page: 1,
	limit: 500,
	sortKey: 'updatedAt',
	sortValue: 'desc' as SortOrder
};

const initialState: TInitialState = {
	isDragging: false,
	selectedAlert: null,
	draggedAlert: undefined,
	filters: {
		toDo: { ...initialFilters, status: 'toDo' },
		onGoing: { ...initialFilters, status: 'onGoing' }
	},
	groups: {
		toDo: null,
		onGoing: null
	}
};

const monitoringSlice = createSlice({
	name: 'monitoring',
	initialState,
	reducers: {
		setSelectedAlert: (state, action: PayloadAction<TSelectedAlert>) => {
			state.selectedAlert = action.payload;
		},
		setIsDragging: (state, action: PayloadAction<boolean>) => {
			state.isDragging = action.payload;
		},
		setDraggedAlert: (state, action: PayloadAction<TMonitoringAlert>) => {
			state.draggedAlert = action.payload;
		},
		updateOnGoingFilters: (state, action: PayloadAction<Partial<TMonitoringPaginatedQuery>>) => {
			state.filters.onGoing = { ...state.filters.onGoing, ...action.payload };
		},
		updateToDoFilters: (state, action: PayloadAction<Partial<TMonitoringPaginatedQuery>>) => {
			state.filters.toDo = { ...state.filters.toDo, ...action.payload };
		},
		updateToDoGroup: (state, action: PayloadAction<TMonitoringGroup>) => {
			state.groups.toDo = action.payload;
		},
		updateOnGoingGroup: (state, action: PayloadAction<TMonitoringGroup>) => {
			state.groups.onGoing = action.payload;
		}
	}
});

export const {
	setSelectedAlert,
	setIsDragging,
	updateOnGoingFilters,
	updateToDoFilters,
	setDraggedAlert,
	updateOnGoingGroup,
	updateToDoGroup
} = monitoringSlice.actions;

export const selectIsDragging = (state: RootState) => state.monitoring.isDragging as boolean;
export const selectSelectedAlert = (state: RootState) => state.monitoring.selectedAlert as TSelectedAlert;
export const selectOnGoingFilters = (state: RootState) =>
	state.monitoring.filters.onGoing as Partial<TMonitoringPaginatedQuery>;
export const selectToDoFilters = (state: RootState) =>
	state.monitoring.filters.toDo as Partial<TMonitoringPaginatedQuery>;
export const selectDraggedAlert = (state: RootState) =>
	state.monitoring.draggedAlert as TMonitoringAlert | TGroupedAlarms;
export const selectBothFilters = (state: RootState) => ({
	toDo: state.monitoring.filters.toDo,
	onGoing: state.monitoring.filters.onGoing
});
export const selectToDoGroup = (state: RootState) => state.monitoring.groups.toDo as Partial<TMonitoringGroup>;
export const selectOnGoingGroup = (state: RootState) => state.monitoring.groups.onGoing as Partial<TMonitoringGroup>;

export default monitoringSlice.reducer;
