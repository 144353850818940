const spItem = {
	ROWS_PER_PAGE: 'Elementos por página',
	TITLE: 'Mantenimiento',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	OF: 'de',
	EDIT: 'Editar',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	CREATED_AT: 'Creado el',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar mantenimiento',
	DELETE_CONFIRMATION: '¿Está seguro de que desea eliminar?',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Mantenimiento de edición',
	EDIT_CONFIRMATION: '¿Está seguro de que desea editar este mantenimiento?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Está seguro de que desea editar los mantenimientos seleccionados?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	ERROR_PERMISSION: 'No tiene las autorizaciones necesarias, por favor contacte con el administrador de su sistema',
	ERROR_GETTING_USERS: 'Hubo un error al cargar los usuarios, inténtelo de nuevo más tarde',
	ERROR_RESPONSE_404: 'Error: Datos no encontrados, comuníquese con el administrador de su sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor, inténtalo de nuevo más tarde',
	ERROR_NETWORK: 'Error de red, verifique su conexión e intente nuevamente',
	ADD_MAINTENANCE: 'Añadir Mantenimiento',
	CREATE_SUCCESS: 'Mantenimiento agregado exitosamente',
	CREATE_ERROR: 'Error al agregar mantenimiento',
	EDIT_SUCCESS: 'Mantenimiento actualizado con éxito',
	EDIT_ERROR: 'Error al actualizar mantenimiento',
	DELETE_SUCCESS: 'Mantenimiento eliminado con éxito',
	DELETE_ERROR: 'Error al eliminar mantenimiento',
	NO_DATA: 'No se encontraron elementos coincidentes',
	NO_RESULTS: 'No se encontraron resultados',
	NO_PERMISSION_list: 'No tienes permiso para ver la lista de usuarios',
	NO_PERMISSION_new: 'No tienes permiso para agregar usuarios',
	NO_PERMISSION_edit: 'No tienes permiso para editar usuarios',
	NO_PERMISSION_view: 'No tienes permiso para ver usuarios',
	NO_PERMISSION_delete: 'No tienes permiso para eliminar usuarios',
	MAINTENANCE_TYPE: 'Tipo de manutenção',
	STATUS: 'Status',
	NOTIFICATIONS: 'Notificações',
	SCHEDULE: 'Realização',
	OPTIONAL: 'Opcional',
	EDIT_MAINTENANCE: 'Editar manutenção',
	SCHEDULE_MAINTENANCE: 'Cadastrar nova manutenção',
	ITEM: 'Artículo',
	TYPE: 'Tipo',
	LOCATION: 'Ubicación',
	PRICE: 'Precio',
	RESPONSIBLE: 'Responsable',
	FREQUENCY: 'Frecuencia',
	NAME: 'Nombre',
	TITLE_ITEM: 'Artículo de mantenimiento',
	ADD_MAINTENANCE_ITEM: 'Agregar elemento de mantenimiento',
	CURRENT_ODOMETER: 'Odómetro actual del vehículo',
	CONFIRM: 'Confirmar',
	MAINTENANCE_REPORT: 'Informe de mantenimiento',
	EXPORT_DATA: 'Exportar datos',
	MAINTENANCE_TOTAL_EXPENSES: 'Total gastado en mantenimiento',
	SCHEDULE_MAINTENANCE_EXPENSES_FORECAST: 'Pronóstico de gastos en mantenimiento programado',
	MAINTENANCE_ITEMS_RANKING: 'Clasificación de los artículos con más mantenimiento',
	MAINTENANCE_FLEET_RANKING: 'Clasificación de las flotas con más mantenimiento',
	FLEET_PERIOD_MEDIAN: 'Promedio de período entre flotas',
	YEARLY_MEDIAN: 'Promedio del año pasado',
	FOUR_WEEKS_MEDIAN: 'Media de las últimas 4 semanas',
	NO_CHART_DATA: 'No se encontraron datos',
	SELECT: 'Seleccionar',
	period: 'Período',
	FROM: 'En',
	TO: 'Hasta',
	GET_REPORT_ERROR: 'Error al recuperar el informe',
	SCHEDULED_MAINTENANCES: 'Mantenimientos programados',
	EXECUTED_MAINTENANCES: 'Mantenimientos ejecutados',
	SELECT_MAINTENANCE_TARGET:
		'Seleccione un vehículo o flota para registrar el mantenimiento, si selecciona un grupo de vehículos o un grupo de flotas, el mantenimiento se registrará para todos los vehículos del grupo.',
	VEHICLES: 'Vehículos',
	FLEETS: 'Flotas',
	COMPANIES: 'Empresas',
	FLEETS_SELECTED: '{{n}} flotas seleccionadas',
	VEHICLES_SELECTED: '{{n}} vehículos seleccionados',
	COMPANY: 'Empresa',
	EXECUTE: 'Ejecutar',
	VIEW: 'Para ver',
	MAINTENANCE: 'Maintenance',
	EXECUTE_MAINTENANCE: 'Ejecutar Mantenimiento',
	ODOMOTER_LIMIT: 'Límite de kilometraje',
	LIMIT_DATE: 'Fecha límite',
	ATTACHMENTS: 'Adjuntos',
	MAINTENANCE_DETAILS: 'Detalles de mantenimiento',
	SELECTED_ITEMS: '{{n}} elementos seleccionados',
	MAX_ITEM_ERROR: 'Sólo puedes seleccionar un máximo de 3 artículos.',
	MIN_ITEM_ERROR: 'Seleccione al menos 1 artículo.',
	APP: 'Aplicativo',
	MAINTENANCE_STATUS_LABEL: '¿Cuál es el estado del mantenimiento?',
	INFORMATIONS: 'Informaciones',
	MAINTENANCE_INFORMATIONS: 'Informaciones de mantenimiento',
	KEEP_FREQUENCY_TOOLTIP_TEXT:
		'Cuando esta opción está marcada, las próximas mantenimientos generadas automáticamente usarán los mismos plazos',
	FREQUENCY_INTERVAL_TOOLTIP_TEXT: 'Selecione el intervalo en el que se debe realizar el mantenimiento',
	MILEAGE_TOOLTIP_TEXT:
		'Ingrese el límite de kilometraje hasta que se realice este mantenimiento, por ejemplo, si necesita que el mantenimiento se realice cada 1000 km, ingrese 1000',
	DEADLINE_DATE_TOOLTIP_TEXT: 'Seleccione la fecha límite para este mantenimiento',
	MONTHS: 'Meses',
	DAYS: 'Días',
	YEARS: 'Años',
	WEEKS: 'Semanas',
	NOTIFICATION_INTERVAL_INFO: '{{n}} {{t}} antes de {{v}}',
	NOTIFICATION_MILEAGE_INFO: 'El vehículo recorrerá {{n}} km desde el kilometraje actual',
	NOTIFICATION_INFO: 'Se le notificará:',
	OR: 'O \n {{n}}. Lo que ocurra primero',
	LIMIT_TYPE_MISSING_ERROR: 'Debe seleccionar al menos un tipo de límite',
	DEADLINE_DATE_REQUIRED: 'La fecha límite es obligatoria',
	DEADLINE_MILEAGE_REQUIRED: 'El límite de kilometraje es obligatorio',
	KEEP_FREQUENCY_NEXT: '¿Esta mantenimiento ocurrirá regularmente?',
	FREQUENCY_REQUIRED: 'Seleccione la frecuencia del mantenimiento',
	SELECT_USERS: 'Seleccionar usuarios',
	NOTIFICATION_TYPE_MISSING_ERROR: 'Debe seleccionar al menos un tipo de notificación',
	EMAIL_REQUIRED: 'Correo electrónico es obligatorio',
	PHONE_REQUIRED: 'Teléfono es obligatorio',
	SELECTED_USERS: '{{n}} seleccionados',
	PERIOD: 'Período',
	COMPANY_REQUIRED: 'Empresa es obligatoria',
	VEHICLE_REQUIRED: 'Vehículo es obligatorio',
	FLEET_REQUIRED: 'Flota es obligatoria',
	EXECUTION_DATE_REQUIRED: 'La fecha de ejecución es obligatoria',
	NOTIFICATION_PERIOD_TOOLTIP_TEXT:
		'Seleccione el periodo en el que desea ser notificado antes de la fecha configurada anteriormente.',
	NOTIFICATION_MILEAGE_TOOLTIP_TEXT:
		'Ingrese la cantidad de kilometraje en la que desea ser notificado antes de la fecha configurada anteriormente.',
	PERIOD_AMOUNT: 'Cantidad de períodos',
	CONCLUDE: 'Concluir',
	VEHICLE: 'Vehículo',
	NO_LOCATION: 'No hay ubicación informada',
	NO_RESPONSIBLE: 'No hay responsable informado'
};

export default spItem;
