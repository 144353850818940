import { Box, Skeleton, useTheme } from '@mui/material';

const SkeletonItem = () => {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';

	return (
		<Box
			sx={{
				paddingX: '16px',
				paddingY: '14px',
				width: ['100%', '520px'],
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				background: isDarkMode ? '#323C4D' : '#fff',
				borderRadius: '12px',
				border: '1px solid #E0E0E0',
				margin: 0,
				height: '115px',
				gap: 1,
				paddingLeft: 3
			}}
		>
			<Skeleton variant="rectangular" height={60} />
			<Skeleton variant="rectangular" height={60} />
		</Box>
	);
};

export default function SkeletonColumn({ column = 'toDo' }) {
	return Array.from({ length: 10 }).map((_v, i) => <SkeletonItem key={`${column}-${i}`} />);
}
