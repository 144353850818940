import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { amber, blue, green } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { hideMessage, selectFuseMessageOptions, selectFuseMessageState } from 'app/store/fuse/messageSlice';
import { useAppDispatch, useAppSelector } from 'app/store/index';
import { memo } from 'react';
import FuseSvgIcon from '../FuseSvgIcon';

export type SnackbarVariant = 'success' | 'error' | 'info' | 'warning' | 'download';
type TSnackbar = {
	variant: SnackbarVariant;
};
export const StyledSnackbar = styled(Snackbar)<TSnackbar>(({ theme, variant }) => ({
	zIndex: 999999999999999,

	'& .FuseMessage-content': {
		zIndex: 999999999999,

		...(variant === 'success' && {
			backgroundColor: green[600],
			color: '#FFFFFF'
		}),

		...(variant === 'error' && {
			backgroundColor: theme.palette.error.dark,
			color: theme.palette.getContrastText(theme.palette.error.dark)
		}),

		...(variant === 'info' && {
			backgroundColor: blue[600],
			color: '#FFFFFF'
		}),

		...(variant === 'warning' && {
			backgroundColor: amber[600],
			color: '#FFFFFF'
		}),

		...(variant === 'download' && {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.getContrastText(theme.palette.background.paper)
		})
	}
}));

const variantIcon = {
	success: 'check_circle',
	warning: 'warning',
	error: 'error_outline',
	info: 'info'
};

function FuseMessage() {
	const dispatch = useAppDispatch();
	const state = useAppSelector(selectFuseMessageState);
	const options = useAppSelector(selectFuseMessageOptions);

	return (
		<StyledSnackbar {...options} open={state} onClose={() => dispatch(hideMessage())}>
			<SnackbarContent
				className="FuseMessage-content"
				message={
					<div className="flex items-center">
						{variantIcon[options.variant] && (
							<FuseSvgIcon color="inherit">{variantIcon[options.variant]}</FuseSvgIcon>
						)}
						<Typography className="mx-8">{options.message}</Typography>
					</div>
				}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={() => dispatch(hideMessage())}
						size="large"
					>
						<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
					</IconButton>
				]}
			/>
		</StyledSnackbar>
	);
}

export default memo(FuseMessage);
