import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';

import { apiSlice } from './apiSlice';

import type { TMaintenance } from 'app/main/maintenance-manager/types';
import type {
	IApiPagination,
	IMaintenanceReportResponse,
	MaintenanceReportFilter,
	TMaintenanceFileUpload,
	TPaginatedQuery,
	TUploadFileResponse
} from './types';

export function getStringQuery(paginatedData: TPaginatedQuery, route: string) {
	const queryArgs = {
		page: `${paginatedData.page || 1}`,
		limit: `${paginatedData.limit || 100}`,
		search: paginatedData.search || null,
		searchKey: paginatedData.searchKey || null,
		sortKey: paginatedData.sortKey || null,
		sortValue: paginatedData.sortValue || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${route}?${query.toString()}`;
}

const baseUrl = '/maintenance';
const cacheKey = 'Maintenance';

type TMaintenancePayload = {
	_id: string;
	items: string[];
	status: string;
	type: string;
	price?: string;
	executionDate?: string;
	deadlineDate: string;
	vehicles?: string[];
	vehicle?: string;
	company?: string;
	fleet?: string;
	serviceNoteUrls: string[];
	observation: string;
	itemImageUrls: string[];
	executionLocation: string;
	executionResponsible: string;
};

export const maintenancesSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllMaintenances: builder.query<IApiPagination<TMaintenance>, TPaginatedQuery>({
			query: (paginatedData: TPaginatedQuery) => ({
				url: getStringQuery(paginatedData, baseUrl)
			}),
			providesTags: [cacheKey]
		}),

		getMaintenance: builder.query({
			query: (id: string) => `${baseUrl}/${id}`,
			providesTags: (_, __, payload) => [{ type: 'Maintenance', id: payload }]
		}),

		updateMaintenance: builder.mutation<TMaintenancePayload, TMaintenance>({
			query: (data) => ({
				url: `${baseUrl}/${data._id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: (_, __, payload) => [{ type: 'Maintenance', id: payload._id }, cacheKey]
		}),

		createMaintenance: builder.mutation<string, TMaintenance>({
			query: (data) => ({
				url: baseUrl,
				method: 'POST',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),

		bulkCreateMaintenance: builder.mutation<TMaintenancePayload, TMaintenance>({
			query: (data) => ({
				url: `${baseUrl}/bulk`,
				method: 'POST',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),

		deleteMaintenance: builder.mutation({
			query: (id: string) => ({
				url: `${baseUrl}/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: [cacheKey]
		}),
		getMaintenanceReport: builder.query<IMaintenanceReportResponse, MaintenanceReportFilter>({
			query: (params) => `${baseUrl}/report?since=${params.since}&until=${params.until}&company=${params.company}`
		}),
		uploadFile: builder.mutation<TUploadFileResponse, TMaintenanceFileUpload>({
			query: (data) => ({
				url: `${baseUrl}/upload`,
				method: 'POST',
				body: data
			})
		})
	})
});

export const {
	useGetAllMaintenancesQuery,
	useDeleteMaintenanceMutation,
	useCreateMaintenanceMutation,
	useUpdateMaintenanceMutation,
	useGetMaintenanceQuery,
	useLazyGetMaintenanceReportQuery,
	useUploadFileMutation,
	useBulkCreateMaintenanceMutation
} = maintenancesSlice;
