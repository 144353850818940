import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';

import { Box, Grid, Stack, Typography, alpha, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import { MouseEventHandler, ReactNode } from 'react';

const LabelText = styled(Typography)(({ theme }) => ({
	fontSize: '10px',
	fontWeight: 600,
	color: theme.palette.getContrastText(theme.palette.background.paper),
	textTransform: 'uppercase',
	lineHeight: '12px'
}));

const ViewGridContainer = styled(Stack)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: 4
}));

type TViewItem = {
	label: string;
	value: string | number;
	icon: string;
	link?: boolean;
	href?: string;
};

type TViewIcon = {
	icon: string;
	button?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
};

type TViewGrid<T> = {
	label: string;
	options: T[];
	optionComponent: (_props: T) => JSX.Element;
	action?: ReactNode;
	alternativeComponent?: ReactNode;
};

export function ViewGrid<T>({ label, options, optionComponent, action, alternativeComponent }: TViewGrid<T>) {
	return (
		<ViewGridContainer direction="column" spacing={2} padding={2}>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<LabelText>{label}</LabelText>
				{action}
			</Stack>
			<Grid rowGap={2} container columns={2}>
				{options?.map((option, index) => (
					<Grid item key={`${option}-${index}`} xs={1}>
						{optionComponent(option)}
					</Grid>
				))}
			</Grid>
			{alternativeComponent}
		</ViewGridContainer>
	);
}

export function ViewIcon({ icon, button, onClick }: TViewIcon) {
	return (
		<Box
			onClick={onClick}
			sx={{
				backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.13),
				borderRadius: 1,
				height: '39px',
				width: '37px',
				padding: '10px',
				...(button && {
					cursor: 'pointer',
					'&:hover': {
						backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.2)
					},
					'&:active': {
						backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.27)
					}
				})
			}}
		>
			<FuseSvgIcon size={18} color="secondary">
				{`material-outline:${icon}`}
			</FuseSvgIcon>
		</Box>
	);
}

export default function ViewItem({ icon, label, value, link, href }: TViewItem) {
	if (isEmpty(value)) return undefined;

	return (
		<Stack direction="row" spacing={1}>
			<ViewIcon icon={icon} />
			<Stack direction="column" spacing={1}>
				<Typography
					sx={(theme) => ({
						fontSize: '10px',
						fontWeight: 600,
						color: theme.palette.getContrastText(theme.palette.background.paper),
						textTransform: 'uppercase',
						lineHeight: '12px'
					})}
				>
					{label}
				</Typography>
				{link ? (
					<Typography
						onClick={() => window.open(href, '_blank')}
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							lineHeight: '17px',
							cursor: 'pointer',
							textDecoration: 'none',
							'&:hover': {
								textDecoration: 'underline'
							}
						}}
					>
						{value}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: '14px',
							fontWeight: 400,
							lineHeight: '17px'
						}}
					>
						{value}
					</Typography>
				)}
			</Stack>
		</Stack>
	);
}
