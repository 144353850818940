import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Settings } from '@mui/icons-material';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';

function IntegrationsCard({ integrations, t }) {
	const container = {
		show: {
			transition: {
				staggerChildren: 0.04
			}
		}
	};

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 }
	};
	const dispatch = useDispatch();

	const handleConfigureOpen = (configureDialog) =>
		dispatch(
			openDialog({
				children: configureDialog
			})
		);

	return (
		<motion.div
			variants={container}
			initial="hidden"
			animate="show"
			className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-24 w-full min-w-0"
		>
			{integrations.map((integration) => (
				<Paper
					component={motion.div}
					variants={item}
					className="flex flex-col flex-auto items-center shadow rounded-2xl overflow-hidden"
					key={integration.id}
				>
					<div className="flex flex-col flex-auto w-full p-32 text-center">
						<div className="w-128 h-128 mx-auto rounded-full overflow-hidden">
							<img className="w-full h-full object-cover" src={integration.image} alt="integration" />
						</div>
						<Typography className="mt-24 font-medium">{integration.name}</Typography>
						<Typography color="text.secondary">{integration.description}</Typography>
					</div>

					<div className="flex items-center w-full border-t divide-x">
						<Button
							className="flex flex-auto items-center justify-center py-16 hover:bg-hover"
							onClick={() => handleConfigureOpen(integration.configureDialog)}
						>
							<Settings className="w-20" />

							<Typography className="ml-8">{t('CONFIGURE')}</Typography>
						</Button>
						<Button
							className="flex flex-auto items-center justify-center py-16 hover:bg-hover"
							onClick={integration.handleConnect}
							disabled={integration?.disabled}
						>
							<FuseSvgIcon size={20} color="action">
								material-outline:sync
							</FuseSvgIcon>
							<Typography className="ml-8">{t('SYNC')}</Typography>
						</Button>
					</div>
				</Paper>
			))}
		</motion.div>
	);
}

export default IntegrationsCard;
