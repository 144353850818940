import { THIRTY_ONE_DAY_ERROR_MESSAGE_PT } from "src/constants";

export default {
	COMPANY: 'Empresa',
	TRACKER: 'Rastreador',
	TRACKER_TYPE: 'Tipo do rastreador',
	STATUS: 'Ultimo status',
	CREATED_AT: 'Enviado em',
	SENDED_BY: 'Enviado por',
	UPDATED_AT: 'Última atualização',
	LAST_STATUS_UPDATED: 'Útima atualização de status',
	TITLE: 'Relatório de Bloqueio/Desbloqueio',
	VIEW_SCHEDULED_COMMAND: 'Visualizar comando agendado',
	VIEW_HISTORY_COMMAND: 'Visualizar histórico de comandos',
	COMMAND_TIMELINE: 'Linha do tempo',
	COMMAND: 'Comando',
	NO_TIMELINE: 'Nenhuma linha do tempo encontrada para este comando',
	CONFIG_TABLE: 'Configurar tabela',
	SHOW_FILTERS: 'Mostrar filtros',
	CLEAR_FILTERS: 'Limpar filtros',
	NO_DATA: 'Nenhum item correspondente foi encontrado',
	NO_FILTERS: 'Nenhum filtro aplicado',
	NO_FILTERS_SUBTITLE: 'Selecione os filtros que deseja para ver os resultados',
	CREATED_BY: 'Criado por',
	START_DATE: 'Data Inicial',
	END_DATE: 'Data Final',
	SEARCH: 'Pesquisar',
	ERROR_SELECTED_COMPANY: 'Selecione ao menos um rastreador ou um enviado por',
	THIRTY_DAY_ERROR: THIRTY_ONE_DAY_ERROR_MESSAGE_PT,
	NO_OPTIONS_FOR_COMPANY: 'Nenhuma opção encontrada para a empresa {{company}}',

	PENDING: 'Pendente',
	QUEUED: 'Agendado',
	'RE-QUEUED': 'Re-enfileirado',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	CANCELED: 'Cancelado',
	ERROR: 'Erro',
	EXPIRED: 'Expirado',
	SCHEDULING: 'Agendando'
};
