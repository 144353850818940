export default {
	TITLE: 'Horários permitidos para acesso',
	NAME: 'Nome',
	DESCRIPTION: 'Descrição',
	COMPANY: 'Empresa',
	CREATED_AT: 'Criado em',
	UPDATED_AT: 'Atualizado em',

	ADD_MORE: 'Adicionar mais um período',

	USERS: 'Usuários',
	COMPANIES: 'Empresas',
	ACCESS_PERIOD: 'Horário de acesso',
	VINCULATE: 'Vincular',

	VINCULATE_MODAL_DESCRIPTION:
		'Você está prestes a vincular este horário de acesso para {{selectedUsers}} usuários e {{selectedCompanies}} Empresas.',

	COMPANIES_LIMIT: '{{limit}} Empresas selecionadas',
	USERS_LIMIT: '{{limit}} Usuários selecionados',

	COMPANIES_OR_USERS_REQUIRED: 'Selecione pelo menos uma empresa ou um usuário',
	ACCESS_PERIOD_REQUIRED: 'Selecione um horário de acesso',

	VINCULATE_ERROR: 'Erro ao vincular usuários e empresas',
	VINCULATING_USERS: 'Vinculando usuários selecionados',
	VINCULATING_USERS_IN_COMPANIES: 'Vinculando usuários nas empresas escolhidas',
	VINCULATE_SUCCESS: 'Usuários e empresas vinculados com sucesso',

	COMPANIES_INFO: 'Ao atualizar os periodos de acesso de uma empresa, seu usuário não sera alterado.'
};
