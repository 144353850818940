import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AppButton from 'app/shared-components/Buttons/AppButton';
import HookedSelect from 'app/shared-components/Hooked/HookedSelect';
import HookedVirtualizedSelect from 'app/shared-components/Hooked/HookedVirtualizedSelect';
import { useGetAllTrackersQuery } from 'app/store/api/trackersSlice';
import { useGetAllUsersQuery } from 'app/store/api/userSlice';
import { selectOnGoingFilters, updateOnGoingFilters } from 'app/store/monitoring';
import { closeDialog } from 'app/store/fuse/dialogSlice';

type TSeverity = { name: string; value: 1 | 2 | 3 };

const query = { limit: 1000, page: 1 };

const IdAndNameYup = yup
	.object()
	.shape({ _id: yup.string(), name: yup.string(), did: yup.number().optional(), value: yup.number().optional() })
	.nullable();

const formSchema = yup.object({
	selectedSeverity: IdAndNameYup.optional(),
	selectedTracker: IdAndNameYup.optional(),
	selectedOperator: IdAndNameYup.optional()
});
type TFormModal = yup.InferType<typeof formSchema>;

export default function OnGoingFilters() {
	const { t } = useTranslation('monitoringPage');
	const dispatch = useDispatch();
	const selectedFilters = useSelector(selectOnGoingFilters);
	const severitiesValues: TSeverity[] = useMemo(
		() => [
			{ name: t('LOW_SEVERITY'), value: 1 },
			{ name: t('MEDIUM_SEVERITY'), value: 2 },
			{ name: t('HIGH_SEVERITY'), value: 3 }
		],
		[t]
	);

	const { trackers, isLoadingTrackers } = useGetAllTrackersQuery(query, {
		selectFromResult: ({ data, isLoading }) => ({
			trackers: data?.docs ?? [],
			isLoadingTrackers: isLoading
		})
	});

	const { users, isLoadingUsers } = useGetAllUsersQuery(query, {
		selectFromResult: ({ data, isLoading }) => ({
			users: data?.docs ?? [],
			isLoadingUsers: isLoading
		})
	});

	const { control, handleSubmit } = useForm<TFormModal>({
		resolver: yupResolver(formSchema),
		defaultValues: {
			selectedOperator: selectedFilters.search ? _.find(users, { _id: selectedFilters.search }) : null,
			selectedSeverity: selectedFilters.severity
				? _.find(severitiesValues, { value: selectedFilters.severity })
				: null,
			selectedTracker: selectedFilters.did ? _.find(trackers, { did: selectedFilters.did }) : null
		},
		mode: 'all'
	});

	const handleApplyFilters = (data: TFormModal) => {
		const search = data.selectedOperator?._id;
		dispatch(
			updateOnGoingFilters({
				severity: data.selectedSeverity?.value,
				did: data.selectedTracker?.did,
				search,
				searchKey: search ? 'user._id' : undefined
			})
		);
		dispatch(closeDialog());
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				gap: 1,
				width: '100%'
			}}
			component="form"
			onSubmit={handleSubmit(handleApplyFilters)}
		>
			<HookedSelect<TFormModal, TSeverity>
				control={control}
				name="selectedSeverity"
				label={t('SEVERITY')}
				options={severitiesValues}
				optionLabel="name"
				placeholder={t('NO_FILTER')}
				fullWidth
				t={t}
				size="small"
				maxListHeight={150}
			/>
			<HookedVirtualizedSelect
				control={control}
				options={trackers}
				optionLabel="name"
				name="selectedTracker"
				label={t('TRACKER')}
				loading={isLoadingTrackers}
				fullWidth
				t={t}
				size="small"
				maxListHeight={150}
			/>
			<HookedVirtualizedSelect
				control={control}
				options={users}
				optionLabel="name"
				name="selectedOperator"
				label={t('OPERATOR')}
				loading={isLoadingUsers}
				fullWidth
				t={t}
				size="small"
				maxListHeight={150}
			/>
			<Box
				sx={{
					width: '100%',
					marginTop: 'auto'
				}}
			>
				<AppButton variant="contained" color="secondary" type="submit" fullWidth>
					{t('APPLY')}
				</AppButton>
			</Box>
		</Box>
	);
}
