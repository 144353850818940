const locale = {
	TRACKER_COMMAND_UPDATE_ERROR: 'Error al actualizar comando de rastreador',
	TRACKER_COMMAND_UPDATED: 'Comando atualizado com sucesso',
	TRACKER_COMMAND_CREATE_SUCCESS: 'Comando registrado com sucesso',
	TRACKER_COMMAND_CREATE_ERROR: 'Erro ao registrar comando',
	COMMANDS_REGISTER: 'Registro de comandos',
	COMMANDS: 'Comandos',
	REQUIRED_FIELD: 'Campo obligatorio',
	CREATE: 'Crear',
	SAVE: 'Guardar',
	CREATE_COMMAND: 'Crear comando',
	CLOSE: 'Cerrar',
	CONFIRM: 'Confirmar',
	CANCEL: 'Cancelar',
	PERMISSIONS_NOT_ALLOWED: 'No tienes permiso para realizar esta acción',
	TRACKER_COMMAND_PARAMETERS: 'Parámetros',
	TRACKER_COMMAND_ADD_PARAMETER: 'Agregar parámetro',
	TRACKER_COMMAND_ICON: 'Icono',
	DELETE_TRACKER_COMMAND: 'Excluir comando de rastreador',
	TRACKER_COMMAND_FIELD: 'Campo',
	TRACKER_COMMAND_VALUE: 'Valores',
	DELETE_MODAL_DESCRIPTION: 'Tem certeza que deseja excluir este comando?',
	DELETE_MODAL_TITLE: 'Excluir comando',
	TRACKER_COMMAND_TYPE: 'Tipo',
	TRACKER_COMMAND_LABEL: 'Legenda',
	REQUIRED_FIELDS: 'Campos obligatorios',
	TRACKER_COMMAND_DESCRIPTION: 'Descripción',
	TRACKER_COMMAND_PARSER: 'Protocolo',
	EDIT_TRACKER_COMMAND: 'Editar comando de rastreador',
	TRACKER_COMMAND_STRING: 'Comando',
	TRACKER_COMMAND_NAME: 'Nombre del comando de rastreador',
	EXECUTE_COMMAND: 'Ejecutar comando',
	SCHEDULE_COMMAND: 'Agendar comando',
	EXECUTE_COMMANDS: 'Ejecutar comandos',
	SCHEDULE_COMMANDS: 'Agendar comandos',
	SELECT_COMMAND: 'Seleccionar comando',
	VEHICLES: 'Vehículos',
	FLEETS: 'Flotas',
	TRACKERS: 'Rastreadores',
	COMPANIES: 'Empresas',
	EXECUTE_COMMANDS_IN: 'Ejecutar comandos en',
	MONTHLY: 'Mensual',
	WEEKLY: 'Semanal',
	DAILY: 'Diario',
	DAY: 'Día',
	FREQUENCY: 'Frecuencia',
	EVERYDAY: 'Todos los días',
	WEEKDAY: 'Días de la semana',
	MONDAY: 'Lunes',
	TUESDAY: 'Martes',
	WEDNESDAY: 'Miércoles',
	THURSDAY: 'Jueves',
	FRIDAY: 'Viernes',
	SATURDAY: 'Sábado',
	SUNDAY: 'Domingo',
	UNIC_TIME: 'Hora específica',
	PERIODIC_TIME: 'Hora periódica',
	START: 'Inicio',
	END: 'Fin',
	TARGET_REQUIRED: 'Debe seleccionar al menos un destinatario',
	COMMAND_REQUIRED: 'Debe seleccionar un comando',
	NO_COMMANDS: 'No hay comandos disponibles para este protocolo',
	PARSER: 'Protocolo',
	SELECT_PARSER: 'Seleccionar protocolo',
	NO_COMPANIES: 'No hay empresas disponibles para este protocolo',
	NO_FLEETS: 'No hay flotas disponibles para este protocolo',
	NO_VEHICLES: 'No hay vehículos disponibles para este protocolo',
	NO_TRACKERS: 'No hay rastreadores disponibles para este protocolo',
	HOURLY: 'Hora',
	MINUTE: 'Minuto',
	MINUTES: 'De minutos em minutos',
	HOURS: 'De horas em horas',
	SEND_SMS: 'Enviar SMS',
	PRIORITARY: 'Prioritario',
	ROWS_PER_PAGE: 'Itens por página',
	OF: 'de',
	FILTER: 'Filtro',
	COLUMNS_INFO: 'Selecione que campos desea que aparezcan en la tabla',
	COLUMNS: 'Colunas',
	ITEMS: 'itens',
	ADD: 'Adicionar',
	SEARCH: 'Pesquisar',
	SEARCH_INFO: 'Selecione campos que deseja pesquisar na tabela',
	SCHEDULED_COMMANDS: 'Comandos agendados',
	HISTORY_COMMANDS: 'Histórico de comandos',
	NAME: 'Nome',
	SCHEDULED_COMMAND_NAME: 'Nome do comando agendado',
	COMMAND_NAME: 'Nome do comando',
	COMPANY_COMMANDS: 'Comandos da empresa',
	FROM_STATUS: 'De status',
	TO_STATUS: 'Para status',
	NEXT_EXECUTION: 'Próxima execução',
	NO_DATA: 'Nenhum dado encontrado',
	CREATED_AT: 'Criado em',
	UPDATED_AT: 'Atualizado em',
	DEFAULT_COMMANDS: 'Comandos padrão',
	USER_COMMANDS: 'Comandos do usuário',
	CREATED_BY: 'Criado por',
	VIEW_SUMMARY: 'Ver resumo',
	EDIT_SCHEDULE_COMMAND: 'Editar comando agendado',
	STATUS: 'Status',
	VALUES: 'Valores',
	NO_SUMMARY: 'Não há resumo para este comando',
	ERROR: 'Erro',
	SENT: 'Enviado',
	CONFIRMED: 'Confirmado',
	QUEUED: 'Agendado',
	SUMMARY_INFO: 'Os resumos do comandos são exibidos por datas de execução',
	EDIT: 'Editar',
	DELETE: 'Excluir',
	EXECUTE_FAIL: 'Falha ao executar comando',
	EXECUTE_SUCCESS: 'Comando executado com sucesso',
	SCHEDULE_FAIL: 'Falha ao agendar comando',
	SCHEDULE_SUCCESS: 'Comando agendado com sucesso',
	NAME_REQUIRED: 'O nome é obrigatório',
	SCHEDULED_NAME: 'Nome do comando agendado',
	SCHEDULED_TO: 'Agendado para',
	YES: 'Sim',
	NO: 'Não',
	COMMAND_DELETED: 'Comando excluído com sucesso',
	COMMAND_NOT_DELETED: 'Falha ao excluir comando',
	TARGETS: 'Destinatários',
	SUMMARY: 'Resumo',
	DELETE_COMMAND_MESSAGE: 'Tem certeza que deseja excluir o comando',
	DELETE_COMMAND_MESSAGE_2: 'Esta ação não pode ser desfeita',
	DELETE_COMMAND: 'Excluir comando',
	INFO_COMMAND: 'Informações do comando',

	IS_BLOCK: 'Este comando é um bloqueio?',
	IS_UNBLOCK: 'Este comando é um desbloqueio?'
};

export default locale;
