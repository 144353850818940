
const locale = {
  TITLE: "Eventos de Monitoreo",
  ADD: "Agregar",
  YES: "Sí",
  NO: "No",
  SEARCH: "Buscar",
  CLEAR_SEARCH: "Limpiar filtros",
  EDIT: "Editar",
  VIEW: "Ver",
  SAVE: "Guardar",
  DELETE: "Eliminar",
  CANCEL: "Cancelar",
  CONFIRM: "Confirmar",
  DATE: "Fecha",
  OF: "de",
  
  SELECTED_ITEMS: "Elementos seleccionados",
  NO_ITEM_SELECTED: "Ningún elemento seleccionado",
  EXPORT_SELECTED: "Exportar elementos seleccionados",
  DELETE_SELECTED: "Eliminar elementos seleccionados",
  EDIT_SELECTED: "Editar elementos seleccionados",
  ROWS_PER_PAGE: "Elementos por página",
  
  NAME_REQUIRED: "El campo nombre es obligatorio",
  PARSERS_REQUIRED: "El campo parsers es obligatorio",
  EVENT_CODE_REQUIRED: "El campo código del evento es obligatorio",
  
  NO_EVENT_FOUND: "No se encontraron eventos",
  
  NAME: "Nombre",
  UPDATED_AT: "Actualizado en",
  CREATED_AT: "Creado en",
  CREATED_BY: "Creado por",
  PARSERS: "Parsers",
  PARSER: "Parser",
  ADD_EVENT: "Agregar evento",
  EVENT_CODE: "Código del evento",
  ICON: "Ícono",
  N_PARSERS: "{{n}} Parsers",
  DESCRIPTION: "Descripción",
  
  CREATE_SUCCESS: "Evento agregado exitosamente",
  CREATE_ERROR: "Error al agregar el evento",
  EDIT_SUCCESS: "Evento actualizado exitosamente",
  EDIT_ERROR: "Error al actualizar el evento",
  ERROR_ADD_EMPTY: "Complete todos los campos",
  DELETE_SUCCESS: "Evento eliminado exitosamente",
  DELETE_ERROR: "Error al eliminar el evento",
  MORE_THAN: "más que",
  
  DELETE_MODAL_TITLE: "Eliminar evento",
  DELETE_MODAL_DESCRIPTION: "¿Estás seguro de que deseas eliminar este evento?",
  DELETE_MODAL_WARNING: "Esta acción no se puede deshacer.",
  DELETE_MODAL_MULTIPLE_DESCRIPTION: "¿Estás seguro de que deseas eliminar {{n}} eventos?",
  
  EDIT_INFO: 'Editar evento',
  DELETE_INFO: 'Eliminar evento',
  DOWNLOAD_INFO: 'Descargar eventos seleccionados',
  EDIT_MULTIPLE_INFO: 'Editar eventos seleccionados',
  DELETE_MULTIPLE_INFO: 'Eliminar eventos seleccionados',
  
  SELECTED_ITENS: '{{n}} elementos seleccionados',
  DOWNLOAD_ERROR: 'Error al descargar eventos',
  
  EDIT_MULTIPLE_SUCCESS: "Eventos editados exitosamente",
  EDIT_MULTIPLE_ERROR: "Error al editar los eventos",
  
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',
  };

export default locale;
