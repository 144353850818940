import { Box, Skeleton, Typography } from '@mui/material';

export type TNoChartDataProps = {
	loading?: boolean;
	skeletonDirection?: 'up' | 'stack';
	skeletonLen?: number;
	children: string;
};

const SKELETON_DIM = {
	up: {
		width: ['30px', '54px'],
		height: '100%'
	},
	stack: {
		width: '100%',
		height: ['30px', '54px']
	}
};

function NoChartData({ children, loading = false, skeletonDirection = 'up', skeletonLen = 4 }: TNoChartDataProps) {
	return (
		<Box width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
			{loading ? (
				<Box
					sx={{
						display: 'flex',
						gap: [1, 4],
						flexDirection: skeletonDirection === 'up' ? 'row' : 'column',
						width: '100%',
						height: '100%'
					}}
				>
					{Array.from({ length: skeletonLen }).map((_, i) => (
						<Skeleton
							variant="rectangular"
							key={`Skeleton-${i}`}
							sx={{
								borderRadius: 2,
								width: SKELETON_DIM[skeletonDirection].width,
								height: SKELETON_DIM[skeletonDirection].height
							}}
						/>
					))}
				</Box>
			) : (
				<Typography
					sx={{
						fontSize: ['16px', '18px'],
						fontWeight: 600,
						textAlign: 'center'
					}}
				>
					{children}
				</Typography>
			)}
		</Box>
	);
}

export { NoChartData };
