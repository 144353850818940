import { TPaginatedQuery } from 'app/store/api/types';
import _ from 'lodash';
import { createSearchParams } from 'react-router-dom';

export function getStringQuery(paginatedData: TPaginatedQuery, route: string) {
	const queryArgs = {
		page: `${paginatedData.page || 1}`,
		limit: `${paginatedData.limit || 100}`,
		search: paginatedData.search || null,
		sortKey: paginatedData.sortKey || null,
		sortValue: paginatedData.sortValue || null
	};
	const query = createSearchParams(_.omitBy(queryArgs, _.isNull));

	return `${route}?${query.toString()}`;
}
