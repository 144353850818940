const locale = {
  TITLE: "Perfiles",
  ADD: "Agregar",
  SAVE: "Guardar",
  DELETE: "Eliminar",
  CANCEL: "Cancelar",
  COMPANY: "Empresa",
  COMPANIES: "Empresas",
  OF: "de",
  ROWS_PER_PAGE: "Elementos por página",
  NAME_REQUIRED: "El campo de nombre es obligatorio",
  NAME: "Nombre",
  CREATED_AT: "Creado el",
  CREATED_BY: "Creado por",
  DELETE_TITLE: "Eliminar perfil",
  ADD_PROFILE: "Agregar perfil",
  CREATE_SUCCESS: "Perfil agregado exitosamente",
  CREATE_ERROR: "Error al agregar el perfil",
  EDIT_SUCCESS: "Perfil actualizado exitosamente",
  EDIT_ERROR: "Error al actualizar el perfil",
  DELETE_SUCCESS: "Perfil eliminado exitosamente",
  DELETE_ERROR: "Error al eliminar el perfil",
  UPLOAD_ERROR: "Error al cargar el archivo",
  NO_PROFILE_FOUND: 'No se encontró ningún perfil',
  DELETE_MODAL_TITLE: "Eliminar perfil",
  DELETE_MODAL_DESCRIPTION: "¿Estás seguro de que deseas eliminar este perfil?",
  CONFIRMAR: "Confirmar",
  DELETE_MODAL_WARNING: "Esta acción no se puede deshacer.",
  DELETE_MODAL_MULTIPLE_DESCRIPTION: "¿Estás seguro de que deseas eliminar {{n}} perfiles?",
  EDIT_INFO: 'Editar perfil',
  DOWNLOAD_INFO: 'Descargar perfiles seleccionados',
  EDIT_MULTIPLE_INFO: 'Editar perfiles seleccionados',
  DELETE_MULTIPLE_INFO: 'Eliminar perfiles seleccionados',
  SELECTED_ITENS: '{{n}} elementos seleccionados',
  DOWNLOAD_ERROR: 'Error al descargar perfiles',
  SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
  EDIT_MULTIPLE_SUCCESS: "Perfiles editados exitosamente",
  COMPANY_REQUIRED: 'El campo empresa es obligatorio',
  PERMISSIONS_REQUIRED: "El campo Permisos es obligatorio",
  PERMISSIONS: 'Permisos'
};

export default locale;
