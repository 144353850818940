const locale = {
	NOTES: 'Notas',
	VEHICLE: 'Rastreado',
	TRACKER_TYPE: 'Tipo de rastreador',
	TITLE: 'Vehículos',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	PROFILES: 'Perfiles',
	PROFILE: 'Perfil',
	USERS: 'Usuarios',
	USER: 'Usuario',
	BIRTHDAY: 'Fecha de nacimiento',
	PASSWORD: 'Contraseña',
	CPASSWORD: 'Confirmar contraseña',
	DATE: 'Fecha',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	INACESSIBLE_HOSTS: 'Hosts accesibles',
	ADDRESS: 'Dirección',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Correo electrónico',
	PHONE: 'Teléfono',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún elemento seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	ROWS_PER_PAGE: 'Elementos por página',
	NAME_REQUIRED: 'El campo nombre es obligatorio',
	PHONE_REQUIRED: 'El campo teléfono es obligatorio',
	EMAIL_REQUIRED: 'El campo correo electrónico es obligatorio',
	ADDRESS_REQUIRED: 'El campo dirección es obligatorio',
	COLUMN_NAME: 'Nombre',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Fecha de nacimiento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Creado por',
	COLUMN_CREATED_AT: 'Creado en',
	COLUMN_EMAIL: 'Correo electrónico',
	COLUMN_ADDRESS: 'Dirección',
	COLUMN_PHONE: 'Teléfono',
	NAME: 'Nombre',
	CODE: 'Código',
	DESC: 'Descripción',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar vehículo',
	DELETE_CONFIRMATION: '¿Estás seguro de que quieres eliminar',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Editando vehículo',
	EDIT_CONFIRMATION: '¿Estás seguro de que deseas editar este vehículo?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Estás seguro de que quieres editar los rastreadors seleccionados?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	ERROR_PERMISSION: 'No tienes los permisos necesarios, por favor contacta al administrador del sistema',
	ERROR_GETTING_USERS: 'Hubo un error al cargar los vehículos, por favor inténtalo de nuevo más tarde',
	ERROR_RESPONSE_404: 'Error: datos no encontrados, por favor contacta al administrador del sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor, por favor contacta al administrador del sistema',
	ERROR_NETWORK: 'Erro de rede, verifique sua conexão e tente novamente',
	ADD_USER: 'Adicionar Rastreador',
	CREATE_SUCCESS: 'Rastreador adicionado com sucesso',
	CREATE_ERROR: 'Erro ao adicionar rastreador',
	EDIT_SUCCESS: 'Rastreador atualizado com sucesso',
	EDIT_ERROR: 'Erro ao atualizar rastreador',
	ERROR_ADD_EMPTY: 'Preencha todos os campos',
	DELETE_SUCCESS: 'Rastreador excluído com sucesso',
	DELETE_ERROR: 'Erro ao excluir rastreador',
	NO_DATA: 'Nenhum item correspondente encontrado',
	NO_RESULTS: 'Nenhum resultado encontrado',
	NO_PERMISSION_list: 'Você não tem permissão para ver a lista de rastreadores',
	NO_PERMISSION_new: 'Você não tem permissão para adicionar rastreadores',
	NO_PERMISSION_edit: 'Você não tem permissão para editar rastreadores',
	NO_PERMISSION_view: 'Você não tem permissão para visualizar rastreadores',
	NO_PERMISSION_delete: 'Você não tem permissão para excluir rastreadores',
	NO_ADDRESS: 'Endereço não encontrado',
	NO_PHONE: 'Telefone não encontrado',
	NO_EMAIL: 'E-mail não encontrado',
	NO_BIRTHDAY: 'Data de nascimento não encontrada',
	LOADING: 'Carregando',
	UPLOAD_ERROR: 'Erro ao fazer upload do arquivo',
	PASSWORD_MIN_LENGTH: 'deve ter pelo menos 8 caracteres',
	PASSWORDS_MUST_MATCH: 'As senhas devem coincidir',
	PASSWORD_REQUIRED: 'o campo de senha é obrigatório',
	PASSWORD_MIN_LOWERCASE: 'deve ter pelo menos 1 letra minúscula',
	PASSWORD_MIN_UPPERCASE: 'deve ter pelo menos 1 letra maiúscula',
	PASSWORD_MIN_NUMBERS: 'deve ter pelo menos 1 número',
	PASSWORD_MIN_SYMBOLS: 'deve ter pelo menos 1 símbolo',
	MORE_THAN: 'mais que',
	NO_USER_FOUND: 'Rastreador não encontrado',
	DELETE_MODAL_WARNING: 'Atenção!',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: 'Tem certeza que deseja excluir {{n}} rastreadores?',
	EDIT_INFO: 'Editar rastreador',
	DELETE_INFO: 'Excluir rastreador',
	DOWNLOAD_INFO: 'Baixar rastreador',
	EDIT_MULTIPLE_INFO: 'Editar rastreadores selecionados',
	DELETE_MULTIPLE_INFO: 'Excluir rastreadores selecionados',
	SELECTED_ITENS: '{{n}} itens selecionados',
	DOWNLOAD_ERROR: 'Erro ao baixar arquivo',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas selecionadas',
	SELECTED_HOSTS: '{{n}} Hosts selecionados',
	PROFILE_REQUIRED: 'O campo Perfil é obrigatório',
	CPASSWORD_REQUIRED: 'O campo Confirmar senha é obrigatório',
	EDIT_MULTIPLE_SUCCESS: 'Rastreadores atualizados com sucesso',
	EDIT_MULTIPLE_ERROR: 'Erro ao atualizar rastreadores',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, escolha uma imagem menor que 8MB',
	EMAIL_INVALID: 'E-mail inválido',
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',	
	PLATE_INFO: 'Dependiendo del tipo de vehículo, la placa puede no ser obligatoria.',

	MORE_INFO: 'Ver más información',

	// Header

	DOWNLOAD_ALL: 'Descargar todo',

	LESSON_TITLE: "Título de la Lección",
	COURSE_TITLE: "Título del Curso",
	DESCRIPTION: "Descripción",
	CATEGORY: "Categoría",
	CATEGORY_NAME: "Nombre de la Categoría",
	CONTINUE: "Continuar",
	LESSONS: "Lecciones",
	SELECTED_LESSONS: "{{n}} Lecciones Seleccionadas",
	SELECTED_CATEGORIES: "{{n}} Categorías Seleccionadas",
	ADD_COURSE: "Crear Curso",
	SEARCH_COURSE_LABEL: "Buscar Curso",
	ALL: "Todos",
	NO_COURSE_FOUND: "No se encontró ningún curso",
	DELETE_COURSE_MODAL_TITLE: "¿Eliminar Curso?",
	DELETE_COURSE_MODAL_DESCRIPTION: "¿Estás seguro de que deseas eliminar este curso?",
	CREATE_SUCCESS_COURSE: "Curso añadido con éxito",
	CREATE_ERROR_COURSE: "Error al añadir curso",
	EDIT_SUCCESS_COURSE: "Curso actualizado con éxito",
	DELETE_SUCCESS_COURSE: "Curso eliminado con éxito",
	EDIT_MULTIPLE_SUCCESS_COURSE: "Cursos editados con éxito",
	BACK_TO_COURSES: "Volver a Cursos",
	BACK: "Volver", 
	NEXT: "Próximo",
	CREATE_SUCCESS_LESSON: "Lección añadida con éxito",
	EDIT_SUCCESS_LESSON: "Lección actualizada con éxito",
	DELETE_SUCCESS_LESSON: "Lección eliminada con éxito",
	EDIT_MULTIPLE_SUCCESS_LESSON: "Lecciones editadas con éxito", 
	CREATE_ERRROR_LESSON: "Error al añadir lección",
	EDIT_ERROR_LESSON: "Error al actualizar lección",
	DELETE_ERROR_LESSON: "Error al eliminar lección",
	EDIT_MULTIPLE_ERROR_LESSON: "Error al editar lecciones",
	CATEGORY_NAME_REQUIRED: "El campo Nombre de la Categoría es obligatorio",
	TITLE_REQUIRED: "El campo Título es obligatorio",
	VIDEO_REQUIRED: "El campo Vídeo es obligatorio",
	CATEGORY_REQUIRED: "El campo Categoría es obligatorio",
	LESSONS_REQUIRED: "El campo Lecciones es obligatorio",
	COURSE_TITLE_REQUIRED: "El campo Título del Curso es obligatorio",
	SEARCH_LESSON_LABEL: "Buscar Lección",
	ADD_LESSON: "Añadir Lección",
	WATCH_LESSON: "Ver Lección",
	DELETE_LESSON_MODAL_TITLE: "¿Eliminar Lección?",
	DELETE_LESSON_MODAL_DESCRIPTION: "¿Estás seguro de que deseas eliminar esta lección?",
	COURSES: "Cursos",
	COURSE_HEADER_DESCRIPTION: "Rutas de conocimiento para que conozcas mejor nuestros productos y servicios.",
	LESSONS_HEADER_DESCRIPTION: "Todas las clases disponibles para ti.",
	BACK_TO_LESSONS: "Volver a Lecciones",
};

export default locale;
