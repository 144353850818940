import { TFunction } from 'react-i18next';

import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Stack } from '@mui/system';
import ColumnName from 'app/shared-components/Table/ColumnName';
import DoubleDataCell from 'app/shared-components/Table/DoubleDataCell';
import dayjs from 'dayjs';
import _ from 'lodash';
import { VehicleType } from '../commands/commands(old)/types/types';
import { formatCarPlate } from '../trackers/manage/helper';
import { isColumnOmitted } from '../users/tableColumns';
import { TColumnsNames, TVehicleColumn } from './types';

export const getColumnsNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('ID'), columnId: '_id' },
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('PLATE'), columnId: 'plate' },
	{ name: t('COMPANY'), columnId: 'company.name' },
	{ name: t('COLOR'), columnId: 'color' },
	{ name: t('BRAND'), columnId: 'brand' },
	{ name: t('YEAR'), columnId: 'year' },
	{ name: t('VENDOR'), columnId: 'vendor' },
	{ name: t('MODEL'), columnId: 'model' },
	{ name: t('TRACKERS'), columnId: 'trackers.name' },
	{ name: t('TYPE'), columnId: 'type.name' },
	{ name: t('CREATED_AT'), columnId: 'createdAt' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const getSearchKeysNames = (t: TFunction): TColumnsNames[] => [
	{ name: t('NAME'), columnId: 'name' },
	{ name: t('PLATE'), columnId: 'plate' },
	{ name: t('COLOR'), columnId: 'color' },
	{ name: t('BRAND'), columnId: 'brand' },
	{ name: t('YEAR'), columnId: 'year' },
	{ name: t('VENDOR'), columnId: 'vendor' },
	{ name: t('MODEL'), columnId: 'model' },
	{ name: t('CREATED_BY'), columnId: 'createdBy.name' }
];

export const defaultVisibleColumns = [
	'_id',
	'name',
	'plate',
	'company.name',
	'color',
	'brand',
	'year',
	'vendor',
	'model',
	'trackers.name',
	'type.name',
	'createdAt',
	'createdBy.name'
];
export const columnsSearchKeys = ['name', 'plate', 'color', 'brand', 'year', 'vendor', 'model', 'createdBy.name'];

export const columnsSortKeys = ['_id', 'name', 'plate', 'year', 'createdAt'];

const getVehicleTypeIcon = (type: VehicleType) => {
	const parsedIcon = _.replace(type?.icon, 'mat_outline:', 'material-outline:');

	return (
		<Stack direction="row" gap={1} alignItems="center">
			<FuseSvgIcon color="secondary" fontSize="small">
				{parsedIcon}
			</FuseSvgIcon>
			{_.capitalize(type?.name)}
		</Stack>
	);
};

export const getColumns = (t: TFunction, filteredColumns: string[]): TVehicleColumn[] => [
	{
		minWidth: '256px',
		id: '_id',
		name: <ColumnName name={t('ID')} icon="material-outline:dns" />,
		selector: (row) => row._id,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('_id', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'name',
		name: <ColumnName name={t('NAME')} icon="material-outline:directions_car" />,
		selector: (row) => row?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'plate',
		name: <ColumnName name={t('PLATE')} icon="material-outline:badge" />,
		selector: (row) => row?.plate && formatCarPlate(row.plate),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('plate', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'company.name',
		name: <ColumnName name={t('COMPANY')} icon="material-outline:business" />,
		selector: (row) => row?.company?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('company.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'color',
		name: <ColumnName name={t('COLOR')} icon="material-outline:color_lens" />,
		selector: (row) => row?.color,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('color', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'brand',
		name: <ColumnName name={t('BRAND')} icon="material-outline:sell" />,
		selector: (row) => row?.brand,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('brand', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'year',
		name: <ColumnName name={t('YEAR')} icon="material-outline:access_time" />,
		selector: (row) => row?.year,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('year', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'vendor',
		name: <ColumnName name={t('VENDOR')} icon="material-outline:api" />,
		selector: (row) => row?.vendor,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('vendor', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'model',
		name: <ColumnName name={t('MODEL')} icon="material-outline:dock" />,
		selector: (row) => row?.model,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('model', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'trackers.name',
		name: <ColumnName name={t('TRACKERS')} icon="material-outline:device_hub" />,
		cell: (row) =>
			Array.isArray(row?.trackers) && row?.trackers?.length > 1 ? (
				`${row?.trackers?.length} ${t('TRACKERS')}`
			) : (
				<DoubleDataCell title={row?.trackers?.[0]?.did.toString()} subtitle={row?.trackers?.[0]?.name} />
			),
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('trackers.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'type.name',
		name: <ColumnName name={t('TYPE')} icon="material-outline:category" />,
		cell: (row) => row?.type && getVehicleTypeIcon(row.type),
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('type.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'fleets.name',
		name: <ColumnName name={t('FLEETS')} icon="material-outline:emoji_transportation" />,
		selector: (row) =>
			Array.isArray(row?.fleets) && row?.fleets?.length > 1
				? `${row?.fleets?.length} ${t('FLEETS')}`
				: row?.fleets?.[0]?.name,
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('fleets.name', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdAt',
		name: <ColumnName name={t('CREATED_AT')} icon="material-outline:calendar_today" />,
		selector: (row) => row?.createdAt && dayjs(row.createdAt).format('DD/MM/YYYY'),
		sortable: true,
		reorder: true,
		omit: isColumnOmitted('createdAt', filteredColumns)
	},
	{
		minWidth: '200px',
		id: 'createdBy.name',
		name: <ColumnName name={t('CREATED_BY')} icon="material-outline:person_add" />,
		selector: (row) => row?.createdBy?.name,
		sortable: false,
		reorder: true,
		omit: isColumnOmitted('createdBy.name', filteredColumns)
	}
];
