import { TUrl } from 'app/main/urls/types';
import { getStringQuery } from 'src/utils/getStringQuery';
import { FuseSettingsProps } from '../../../@fuse/core/FuseSettings';
import { apiSlice } from './apiSlice';
import { IApiPagination, TPaginatedQuery } from './types';

export type TCompanyLogo = {
	appName: string;
	home: string;
	logoUrl: string;
	logoDark40x40: string;
	logoDark200x40: string;
	logoLight40x40: string;
	logoLight200x40: string;
};

export type IPayload = {
	id: string;
	host: string;
	settings: FuseSettingsProps;
	companies: string[];
	companyLogo: TCompanyLogo;
	layout?: 'layout1' | 'layout2' | 'layout3';
	home: string;
};

export type IEditMultiplePayload = {
	ids: string;
};

const baseUrl = '/settings/theme/company';
const cacheKey = 'Urls';

export const urlsSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllUrls: builder.query<IApiPagination<TUrl>, TPaginatedQuery>({
			query: (paginatedData: TPaginatedQuery) => ({
				url: getStringQuery(paginatedData, baseUrl)
			}),
			providesTags: [cacheKey]
		}),
		getUrl: builder.query({
			query: (id: string) => ({
				url: `${baseUrl}/${id}`
			})
		}),
		updateUrl: builder.mutation({
			query: (data) => ({
				url: `${baseUrl}/${data.id}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),
		updateMultiple: builder.mutation<string, IEditMultiplePayload>({
			query: (data) => ({
				url: `${baseUrl}/list?ids=${data.ids}`,
				method: 'PUT',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),

		createUrl: builder.mutation<string, IPayload>({
			query: (data) => ({
				url: baseUrl,
				method: 'POST',
				body: data
			}),
			invalidatesTags: [cacheKey]
		}),
		deleteUrls: builder.mutation({
			query: (ids?: string) => ({
				url: `${baseUrl}/list?ids=${ids}`,
				method: 'DELETE'
			}),
			invalidatesTags: [cacheKey]
		})
	})
});

export const {
	useGetAllUrlsQuery,
	useGetUrlQuery,
	useUpdateUrlMutation,
	useCreateUrlMutation,
	useUpdateMultipleMutation,
	useDeleteUrlsMutation
} = urlsSlice;
