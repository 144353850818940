import { InfoOutlined } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	Stack,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DateIcon } from 'app/main/map/components/tracker-card-layouts/ControlledIcons';
import usePermission from 'app/services/hooks/usePermission';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { GROUP_ITEM_TITLE } from '../../helpers';
import { MONITORING_PERMISSIONS } from '../../MonitoringConfig';
import { TMonitoringAlert, TMonitoringGroup } from '../../types';
import AlarmDetailDialog from '../shared/AlarmDetailDialog';

type TGroupedListItem = {
	data: TMonitoringAlert;
	checked: boolean;
	groupedBy: TMonitoringGroup;
	handleCheck: (_value: string) => void;
};

export default function GroupedListItem({ data, handleCheck, checked = false, groupedBy }: TGroupedListItem) {
	const { t } = useTranslation('monitoringPage');
	const dispatch = useDispatch();
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	const mainTextColor = isDarkMode ? '#FFF' : '#000';
	const normalTextColor = isDarkMode ? '#9FA5A1' : '#79747E';
	const permissions = usePermission({ pagePermissions: MONITORING_PERMISSIONS });
	const date = {
		time: dayjs(data.updatedAt).format('HH:mm:ss'),
		day: dayjs(data.updatedAt).format('DD/MM/YYYY')
	};

	const handleDetail = () =>
		dispatch(
			openDialog({
				children: <AlarmDetailDialog data={data} />
			})
		);

	return (
		<ListItem
			sx={{
				paddingLeft: 0
			}}
			secondaryAction={
				permissions.hasView && (
					<Tooltip title={t('DETAILS')}>
						<IconButton size="small" color="secondary" onClick={handleDetail}>
							<InfoOutlined />
						</IconButton>
					</Tooltip>
				)
			}
			divider
		>
			<ListItemButton
				sx={{
					paddingLeft: 1
				}}
				onClick={() => handleCheck(data._id)}
			>
				<ListItemIcon sx={{ minWidth: '32px' }}>
					<Checkbox
						disableRipple
						edge="start"
						inputProps={{ 'aria-labelledby': `grouped-item-${data._id}` }}
						tabIndex={-1}
						checked={checked}
					/>
				</ListItemIcon>
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						flexDirection: ['column', 'row']
					}}
				>
					<Stack direction="row" alignItems="center" flex={1} spacing={1}>
						<DateIcon isDarkMode={isDarkMode} />
						<Stack direction={['row', 'column']} spacing={[2, 0]}>
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: '12px',
									color: normalTextColor
								}}
							>
								{date.day}
							</Typography>
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: '12px',
									color: normalTextColor
								}}
							>
								{date.time}
							</Typography>
						</Stack>
					</Stack>
					<Stack direction="row-reverse" justifyContent="end" alignItems="center" flex={1} spacing={1}>
						<Stack direction="column">
							<Typography
								sx={{
									fontWeight: 600,
									fontSize: '14px',
									color: mainTextColor,
									width: '180px'
								}}
								noWrap
							>
								{t(_.get(data, GROUP_ITEM_TITLE[groupedBy], ''))}
							</Typography>
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: '12px',
									color: mainTextColor
								}}
							>
								{_.get(data, 'trackerMessage.DID', '')}
							</Typography>
						</Stack>
					</Stack>
				</Box>
			</ListItemButton>
		</ListItem>
	);
}
