import { Box, Typography } from '@mui/material';

interface CustomDescriptionTagProps {
	title: string;
}

export default function CustomDescriptionTag({ title }: CustomDescriptionTagProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: ['space-between', 'flex-start'],
				alignItems: ['center', 'flex-start'],
				flexDirection: ['row', 'column'],
				gap: ['0px', '8px'],
				width: ['fit-content'],
				flexWrap: 'wrap'
			}}
		>
			<Typography
				sx={{
					fontSize: '24px',
					fontWeight: 700
				}}
			>
				{title}
			</Typography>
		</Box>
	);
}
