import { createContext } from 'react';
import { TRoutes } from './configs/routesConfig';

interface AppContextInterface {
	routes?: TRoutes;
}

const AppContext = createContext<AppContextInterface>({} as AppContextInterface);

export default AppContext;
