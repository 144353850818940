import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { TGroupType, TOperatorReport, TOperatorReportFilters } from 'app/main/reports/monitoring-operators/types';
import type { TUser } from 'app/main/users/types';
import type { Dayjs } from 'dayjs';
import type { RootState } from '..';

type TInitialState = {
	selectedDataIndex?: number;
	operator: TUser;
	since: Dayjs;
	until: Dayjs;

	groupBy?: TGroupType;
	content?: TOperatorReport;
};

const initialState: TInitialState = {
	selectedDataIndex: 0,
	operator: null,
	since: null,
	until: null,
	groupBy: 'day',
	content: null
};

const monitoringOperatorSlice = createSlice({
	name: 'monitoringOperator',
	initialState,
	reducers: {
		setSelectedDataIndex: (state, action: PayloadAction<number>) => {
			state.selectedDataIndex = action.payload;
		},
		setSelectedFilters: (state, action: PayloadAction<TOperatorReportFilters>) => {
			state.operator = action.payload.operator;
			state.since = action.payload.since;
			state.until = action.payload.until;
			state.groupBy = action.payload.groupBy;
		},
		setContent: (state, action: PayloadAction<TOperatorReport>) => {
			state.content = action.payload;
		},
		wipeContent: (state) => {
			state.content = null;
		},
		setGroupBy: (state, action: PayloadAction<'day' | 'week' | 'month'>) => {
			state.groupBy = action.payload;
		}
	}
});

export const { setSelectedDataIndex, setSelectedFilters, setContent, wipeContent, setGroupBy } =
	monitoringOperatorSlice.actions;
export const selectSelectedDataIndex = (state: RootState) => state.monitoringOperator.selectedDataIndex as number;
export const selectContent = (state: RootState) => state.monitoringOperator.content as TOperatorReport;
export const selectGroupBy = (state: RootState) => state.monitoringOperator.groupBy as TGroupType;

export const selectSelectedFilters = (state: RootState) => ({
	operator: state.monitoringOperator.operator as TUser,
	since: state.monitoringOperator.since as Dayjs,
	until: state.monitoringOperator.until as Dayjs,
	groupBy: state.monitoringOperator.groupBy as TGroupType
});

export default monitoringOperatorSlice.reducer;
