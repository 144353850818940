const locale = {
	TITLE: 'Empresas',
	ADD: 'Agregar',
	YES: 'Sí',
	NO: 'No',
	SEARCH: 'Buscar',
	CLEAR_SEARCH: 'Limpiar filtros',
	EDIT: 'Editar',
	VIEW: 'Visualizar',
	SAVE: 'Guardar',
	DELETE: 'Eliminar',
	CANCEL: 'Cancelar',
	COMPANY: 'Empresa',
	COMPANIES: 'Empresas',
	DESCRIPTION: 'Descripción',
	CUSTOMER_TYPE: 'Tipo de cliente',
	PJ: 'Persona jurídica',
	PF: 'Persona física',
	DATE: 'Fecha',
	OF: 'de',
	DATE_FORMAT: 'en-GB',
	ADDRESS: 'Dirección',
	DOC: 'CPF/CNPJ',
	EMAIL: 'Email',
	PHONE: 'Teléfono',
	SELECTED_ITEMS: 'Elementos seleccionados',
	NO_ITEM_SELECTED: 'Ningún elemento seleccionado',
	EXPORT_SELECTED: 'Exportar elementos seleccionados',
	DELETE_SELECTED: 'Eliminar elementos seleccionados',
	EDIT_SELECTED: 'Editar elementos seleccionados',
	ROWS_PER_PAGE: 'Elementos por página',
	NAME_REQUIRED: 'El campo nombre es obligatorio',
	PHONE_REQUIRED: 'El campo teléfono es obligatorio',
	EMAIL_REQUIRED: 'El campo email es obligatorio',
	ADDRESS_REQUIRED: 'El campo dirección es obligatorio',
	TYPE_REQUIRED: 'El campo tipo de cliente es obligatorio',
	CONFIG_TABLE: 'Configurar tabla',
	COLUMN_NAME: 'Nombre',
	COLUMN_COMPANY: 'Empresa',
	COLUMN_BIRTHDAY: 'Fecha de nacimiento',
	COLUMN_ID: 'id',
	COLUMN_CREATED_BY: 'Creado por',
	COLUMN_CREATED_AT: 'Creado en',
	COLUMN_EMAIL: 'Email',
	COLUMN_ADDRESS: 'Dirección',
	COLUMN_PHONE: 'Teléfono',
	NAME: 'Nombre',
	CODE: 'Código',
	CREATED_AT: 'Creado en',
	CREATED_BY: 'Creado por',
	DELETE_TITLE: 'Eliminar empresa',
	DELETE_CONFIRMATION: '¿Estás seguro/a de que quieres eliminar',
	DELETE_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	EDIT_TITLE: 'Editando empresa',
	EDIT_CONFIRMATION: '¿Estás seguro/a de que quieres editar esta empresa?',
	EDIT_CONFIRMATION_MULTIPLE: '¿Estás seguro/a de que quieres editar las empresas seleccionadas?',
	EDIT_CONFIRMATION_2: 'Esta acción no se puede deshacer.',
	FIELD_NAME: 'Nombre',
	FIELD_CODE: 'Código',
	FIELD_ICON: 'Ícono',
	FIELD_DESC: 'Descripción',
	FIELD_CREATED_AT: 'Creado en',
	FIELD_CREATED_BY: 'Creado por',
	ERROR_PERMISSION: 'No tienes los permisos necesarios, por favor contacta al administrador del sistema',
	ERROR_GETTING_USERS: 'Hubo un error al cargar las empresas, por favor inténtalo nuevamente más tarde',
	ERROR_RESPONSE_404: 'Error: Datos no encontrados, por favor contacta al administrador del sistema',
	ERROR_INTERNAL_SERVER_ERROR: 'Error interno del servidor, por favor inténtalo nuevamente más tarde',
	ERROR_NETWORK: 'Error de red, verifica tu conexión e inténtalo nuevamente',
	ADD_COMPANY: 'Agregar empresa',
	CREATE_SUCCESS: 'Empresa agregada correctamente',
	CREATE_ERROR: 'Error al agregar empresa',
	EDIT_SUCCESS: 'Empresa actualizada correctamente',
	EDIT_ERROR: 'Error al actualizar empresa',
	ERROR_ADD_EMPTY: 'Completa todos los campos',
	DELETE_SUCCESS: 'Empresa eliminada correctamente',
	NO_DATA: 'No se encontraron elementos correspondientes',
	NO_RESULTS: 'No se encontraron resultados',
	NO_PERMISSION_list: 'No tienes permiso para ver la lista de empresas',
	NO_PERMISSION_new: 'No tienes permiso para agregar empresas',
	NO_PERMISSION_edit: 'No tienes permiso para editar empresas',
	NO_PERMISSION_view: 'No tienes permiso para ver empresas',
	NO_PERMISSION_delete: 'No tienes permiso para eliminar empresas',
	NO_ADDRESS: 'Dirección no encontrada',
	NO_PHONE: 'Teléfono no encontrado',
	NO_EMAIL: 'Email no encontrado',
	LOADING: 'Cargando',
	MORE_THAN: 'más de',
	NO_COMPANIES_FOUND: 'No se encontraron empresas',
	DELETE_MODAL_TITLE: 'Eliminar empresa',
	DELETE_MODAL_DESCRIPTION: '¿Estás seguro/a de que quieres eliminar esta empresa?',
	CONFIRM: 'Confirmar',
	DELETE_MODAL_WARNING: 'Esta acción no se puede deshacer.',
	DELETE_MODAL_MULTIPLE_DESCRIPTION: '¿Estás seguro/a de que quieres eliminar {{n}} empresas?',
	EDIT_INFO: 'Editar empresa',
	DELETE_INFO: 'Eliminar empresa',
	DOWNLOAD_INFO: 'Descargar empresas seleccionadas',
	DOWNLOAD_ALL: 'Descargar todo',
	EDIT_MULTIPLE_INFO: 'Editar empresas seleccionadas',
	DELETE_MULTIPLE_INFO: 'Eliminar empresas seleccionadas',
	SELECTED_ITENS: '{{n}} elementos seleccionados',
	DOWNLOAD_ERROR: 'Error al descargar empresas',
	HOSTS: 'Hosts',
	SELECTED_COMPANIES: '{{n}} Empresas seleccionadas',
	SELECTED_HOSTS: '{{n}} Hosts seleccionados',
	PROFILE_REQUIRED: 'El campo Perfil es obligatorio',
	CPASSWORD_REQUIRED: 'El campo Confirmar Contraseña es obligatorio',
	EDIT_MULTIPLE_SUCCESS: 'Empresas editadas correctamente',
	EDIT_MULTIPLE_ERROR: 'Error al editar empresas',
	PROFILE_IMAGE_TO_LARGE: 'Por favor, elige una imagen menor a 8MB',
	COMPANY_REQUIRED: 'El campo Empresa es obligatorio',
	EMAIL_INVALID: 'Email inválido',
	HOST_REQUIRED: 'El campo Host es obligatorio',
	PAGE_NOT_ALLOWED: 'No tienes permiso para acceder a esta página.',
	MINIMAL_START: 'Faltan los siguientes permisos:',
	MINIMAL_END: 'Por favor, contacta a tu administrador.',
	START_DATE: 'Fecha de inicio',
	END_DATE: 'Fecha final',
	CLEAR_FILTERS: 'Limpiar filtros',
	SHOW_FILTERS: 'Mostrar filtros',

	BILLABLE: 'Financiera',
	NON_BILLABLE: 'No Financiera',
	BILLABLE_COMPANY: 'Empresa Financiera',
	BILLABLE_COMPANY_REQUIRED: 'El campo Empresa Financiera es obligatorio',
	BILLABLE_TOOLTIP: 'Si la empresa no es financiera, seleccione la empresa financiera correspondiente',

	ACTIVE: 'Ativos',
	INACTIVE: 'Desativados',
	LOST: 'Perdidos',
	DAMAGED: 'Danificados',
	STOCK: 'Estoque',
	TRACKERS: 'Rastreadores',
	VEHICLES: 'Veículos'
};

export default locale;
