import { Paper, TextField } from '@mui/material';
import CopyButton from 'app/shared-components/Buttons/CopyButton';
import ChevronButton from 'app/shared-components/ChevronButton/ChevronButton';
import CountDown from 'app/shared-components/count-down/CountDown';
import clsx from 'clsx';
import dayjs from 'dayjs';
import maplibreGl from 'maplibre-gl';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map } from 'react-map-gl/maplibre';
import { tripMapStyle } from '../reports/trips/advanced/components/content/mapStyle';
import { Marker } from './components/Marker';
import { PublicVehicle } from './components/PublicVehicle';
import usePublicToken from './hooks/use-public.token';

export default function PublicLinkPage() {
	const { t } = useTranslation('PublicLinkPage');
	const { vehicle, settings, isVehicleFetching, isVehicleLoading } = usePublicToken();
	const [open, setOpen] = useState(true);

	const toggleOpen = () => {
		setOpen((prev) => !prev);
	};

	return (
		<div className="w-full h-full overflow-auto">
			<Map mapLib={maplibreGl} style={{ width: '100%', height: '100%' }} mapStyle={tripMapStyle} fadeDuration={0}>
				<div
					className={clsx(
						'absolute left-[16px] flex flex-col gap-10 md:w-[520px] w-[calc(100%-32px)] transition-all duration-300',
						open ? 'bottom-[16px]' : 'bottom-[-224px]'
					)}
				>
					<div className="flex items-end">
						{settings?.expirationDate && (
							<CountDown title={t('EXPIRES_IN')} endDate={dayjs(settings?.expirationDate).toDate()} />
						)}
						<ChevronButton
							className="w-40 h-40 ml-auto"
							onClick={toggleOpen}
							expand={open}
							sx={(theme) => ({
								backgroundColor: theme.palette.background.paper,
								boxShadow: theme.shadows[2],
								'&:hover': {
									backgroundColor: theme.palette.background.paper,
									boxShadow: theme.shadows[4]
								},
								'&:active': {
									backgroundColor: theme.palette.background.paper,
									boxShadow: theme.shadows[8]
								},
								transition: 'background-color 0.3s, box-shadow 0.3s'
							})}
						/>
					</div>

					<Paper elevation={2} className="bg-white rounded-md w-full flex" sx={{ p: 1, gap: 1 }}>
						<CopyButton
							variant="outlined"
							mode="text"
							data={window.location.href}
							className="rounded-md aspect-square w-[40px] h-[40px]"
						/>
						<TextField disabled value={window.location.href} fullWidth size="small" />
					</Paper>
					<PublicVehicle vehicle={vehicle} isFetching={isVehicleFetching} isLoading={isVehicleLoading} />
				</div>

				<Marker tracker={vehicle} />
			</Map>
		</div>
	);
}
