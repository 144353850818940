import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AppButton from 'app/shared-components/Buttons/AppButton';
import DialogWrapper from 'app/shared-components/DialogWrapper/DialogWrapper';
import HookedDatePicker from 'app/shared-components/Hooked/HookedDatePicker';
import { closeDialog } from 'app/store/fuse/dialogSlice';

function DateRangeDialog({ control }) {
	const { t } = useTranslation('maintenancePage');
	const dispatch = useDispatch();

	const handleClose = () => dispatch(closeDialog());

	return (
		<DialogWrapper
			isSmall
			title={t('PERIOD')}
			onClose={handleClose}
			content={
				<Stack spacing={2} direction={'column'} flex={1}>
					<Stack direction="row" spacing={1} alignItems="center">
						<Typography variant="caption" width={'60px'}>
							{t('FROM')}
						</Typography>
						<HookedDatePicker
							name={'since'}
							format="DD/MM/YYYY"
							disableFuture
							slotProps={{
								textField: { size: 'small' }
							}}
							control={control}
							t={t}
						/>
					</Stack>
					<Stack direction="row" spacing={1} alignItems="center">
						<Typography variant="caption" width={'60px'}>
							{t('TO')}
						</Typography>
						<HookedDatePicker
							name={'until'}
							format="DD/MM/YYYY"
							disableFuture
							slotProps={{
								textField: { size: 'small' }
							}}
							control={control}
							t={t}
						/>
					</Stack>
					<AppButton onClick={handleClose} variant="contained">
						{t('OK')}
					</AppButton>
				</Stack>
			}
		/>
	);
}

export { DateRangeDialog };
