import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THistoryCommand } from 'app/main/commands/historyCommands/types';
import storageService from 'app/services/storage';
import { RootState } from '..';
import { defaultVisibleColumns } from '../../main/commands/historyCommands/tableColumns';

interface InitialState {
	selectedRows: THistoryCommand[];
	currentPage: {
		scheduled: number;
		ruler: number;
	};
	searchKey: {
		scheduled: string;
		ruler: string;
	};
	visibleColumns: string[];
	tableMode: 'scheduled' | 'ruler'
}

const initialState: InitialState = {
	selectedRows: [],
	currentPage: {
		scheduled: 1,
		ruler: 1,
	},
	searchKey: {
		scheduled: '',
		ruler: '',
	},
	visibleColumns: storageService.get('historyCommandsVisibleColumns') || defaultVisibleColumns,
	tableMode: 'scheduled'
};

export const historyCommandsSlice = createSlice({
	name: 'historyCommands',
	initialState,
	reducers: {
		setSelectedRows: (state, { payload }: PayloadAction<THistoryCommand[]>) => {
			state.selectedRows = payload;
		},
		setCurrentPage: (state, { payload }: PayloadAction<THistoryCommand[]>) => {
			state.selectedRows = payload;
		},
		setCurrentSearchKey: (state, { payload }: PayloadAction<InitialState['searchKey']>) => {
			state.searchKey = payload;
		},
		setVisibleColumns: (state, action: PayloadAction<string[]>) => {
			state.visibleColumns = action.payload;
			storageService.set('historyCommandsVisibleColumns', action.payload);
		},
		setTableMode: (state, action: PayloadAction<'scheduled' | 'ruler'>) => {
			state.tableMode = action.payload;
		},
	}
});

export const { setSelectedRows, setCurrentPage, setCurrentSearchKey, setVisibleColumns, setTableMode } = historyCommandsSlice.actions;

export const selectSelectedRows = (state: RootState) => state.historyCommands.selectedRows;
export const selectCurrentPage = (state: RootState) => state.historyCommands.currentPage;
export const selectCurrentSearchKey = (state: RootState) => state.historyCommands.searchKey;
export const selectVisibleColumns = (state: RootState) => state.historyCommands.visibleColumns;
export const selectTableMode = (state: RootState) => state.historyCommands.tableMode;
export default historyCommandsSlice.reducer;
